import React from 'react'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  RollingType
} from 'types/GlobalKpiOption'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import {
  ComparativePeriod,
  DataType,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'

import { TRANSLATE_ROLLING } from 'utils/enumTranslator'

import Grid from 'components_new/atoms/Grid'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import { getRollingOptions } from './utils'

interface RollingSectionProps {
  kpi: KpiOptionObject
  kpiTemplates: KpiTemplateRedux
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const RollingSection = (props: RollingSectionProps) => {
  const { kpi, kpiTemplates, updateKpiOption, widget } = props

  if (!kpi || !kpiTemplates[kpi.kpi_template_id].allow_rolling) return null

  // dont allow last year with compare and over time (on calendar)
  const disabled =
    widget.settings.date_filter.selected.value === PeriodFilter.LAST_YEAR &&
    widget.settings.comparative_period.selected.value ===
      ComparativePeriod.LAST_YEAR &&
    widget.settings.segment_by.type === DataType.DATE

  const handleToggle = () => {
    if (kpi.active_show_per.selected === null) {
      updateKpiOption({
        data: {
          active_show_per: RollingType.R12
        }
      })
    } else {
      updateKpiOption({
        data: {
          active_show_per: null
        }
      })
    }
  }

  return (
    <>
      <SettingsGroup
        title="Ackumulerat"
        helperText={
          <>
            Genom att ackumulera värden (t.ex. de senaste 12 månaderna eller 365
            dagarna) kan man få en dynamisk årsrapportering av ett värde. Detta
            kallas för <i>rullande beräkning</i>.
          </>
        }
      >
        <SettingsItem>
          <Grid container={true} columns={5}>
            {getRollingOptions().map((option) => {
              const selected = option === kpi.rolling_active

              return (
                <Grid item={true} key={option} xs={1}>
                  <SettingsToggleOption
                    disabled={disabled}
                    iconName={TRANSLATE_ROLLING[option].iconName}
                    onClick={() => {
                      updateKpiOption({
                        data: {
                          rolling_active: selected ? null : option
                        }
                      })
                    }}
                    selected={selected}
                    title={TRANSLATE_ROLLING[option].abbreviation}
                    tooltip={
                      disabled ? (
                        <>
                          Kan inte kombineras med historisk period{' '}
                          <b>Förra året</b> eller jämförelser.
                        </>
                      ) : (
                        TRANSLATE_ROLLING[option].title
                      )
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        </SettingsItem>
        {kpi.active_show_per.selected !== null ? (
          <SettingsItem
            actions={
              <Switch
                checked={kpi.active_show_per.selected !== null}
                onChange={handleToggle}
                size="small"
              />
            }
            title="Aktiv"
          />
        ) : null}
      </SettingsGroup>
    </>
  )
}

export default RollingSection
