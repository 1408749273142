import React from 'react'

import { getUnitLabel } from 'components_new/organisms/Widget/types/utils'
import { ReactNode } from 'react'
import { KpiOptionObject, UnitPrefix } from 'types/GlobalKpiOption'

export const getUnitPrefixOptions = (
  unit: string | null,
  unitPrefix: {
    selected: UnitPrefix | null
    options: { value: UnitPrefix; label: string }[]
  }
) => {
  const newOptions = unitPrefix.options.map((option) => {
    return {
      label: `${option.label}${unit ? ' ' + unit : ''}`,
      value: option.value
    }
  })

  const defaultOptions =
    unit === '%'
      ? []
      : [
          {
            label: unit ?? 'Ingen',
            value: ''
          }
        ]

  return [...defaultOptions, ...newOptions]
}

export const getHelpText = (kpi: KpiOptionObject): ReactNode | null => {
  let previewText = ''

  const kpiUnit = getUnitLabel(kpi.unit, kpi.unit_prefix)

  const currentUnit = `${kpiUnit ? ' ' : ''}${kpiUnit}`
  const newUnit = `${kpi.equivalent.unit ? ' ' : ''}${kpi.equivalent.unit}`

  if (kpi.equivalent.factor && kpi.equivalent.unit) {
    previewText = `1${currentUnit} kommer motsvara ${kpi.equivalent.factor}${newUnit}.`
  } else if (kpi.equivalent.factor && !kpi.equivalent.unit) {
    previewText = `1${currentUnit} kommer motsvara ${kpi.equivalent.factor}${currentUnit}.`
  } else if (!kpi.equivalent.factor && kpi.equivalent.unit) {
    previewText = `1${currentUnit} kommer motsvara 1${newUnit}.`
  }

  return (
    <>
      Multiplicerar dina värden med en faktor och/eller byter ut den nyvarande
      enheten mot en ny. På så sätt kan du skapa en <i>ekvivalent</i>.
      {previewText ? (
        <>
          <br />
          <b>{previewText}</b>
        </>
      ) : null}
    </>
  )
}

export const getPreviewValue = (kpi: KpiOptionObject): string | null => {
  const kpiUnit = getUnitLabel(kpi.unit, kpi.unit_prefix)

  const currentUnit = `${kpiUnit ? ' ' : ''}${kpiUnit}`
  const newUnit = `${kpi.equivalent.unit ? ' ' : ''}${kpi.equivalent.unit}`

  if (kpi.equivalent.factor && kpi.equivalent.unit) {
    return `1${currentUnit} = ${kpi.equivalent.factor}${newUnit}`
  } else if (kpi.equivalent.factor && !kpi.equivalent.unit) {
    return `1${currentUnit} = ${kpi.equivalent.factor}${currentUnit}`
  } else if (!kpi.equivalent.factor && kpi.equivalent.unit) {
    return `1${currentUnit} = 1${newUnit}`
  } else {
    return null
  }
}
