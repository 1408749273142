import { getParsedDate } from 'utils/dateParser'

export const toObjectArray = (labels: string[], values: number[]) => {
  const arr: { label: string; value: number }[] = []

  labels.forEach((label, i) => arr.push({ label: label, value: values[i] }))

  return arr
}

export const parseDatetime = (datetime: string) => {
  const date = getParsedDate(datetime)
  const time = datetime.split('T')[1]

  return `${date} kl ${time}`
}
