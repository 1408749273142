import { nanoid } from 'nanoid'
import {
  KpiTemplateCalculationBlock,
  KpiTemplateCalculationBlockType,
  KpiTemplateCalculationOperation,
  PutKpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

export const getEmptyBlock = () => ({
  id: nanoid(),
  left_value: null,
  right_value: null,
  left_kpi_template: null,
  right_kpi_template: null,
  left_kpi_variable: null,
  right_kpi_variable: null,
  left_kpi_calculation_block: null,
  right_kpi_calculation_block: null,
  left_type: null,
  right_type: null,
  operation: KpiTemplateCalculationOperation.PLUS
})

/**
 * Get the value of a property (of a KPI block) by id.
 *
 * @param block is the full block (formula) of the KPI,
 * @param id is the id of the block which should be returned,
 * @param property is the property of the block which should be returned,
 * @returns the value of the property.
 */
export const getPropertyById = (
  block: KpiTemplateCalculationBlock,
  id: string,
  property: string
): any | undefined => {
  const traverse = (obj: any): any => {
    if (!obj || typeof obj !== 'object') {
      return undefined
    }

    // If the current object has the target id, return the value of
    // the specified property.
    if ('id' in obj && obj.id === id) {
      return obj[property]
    }

    // If the current object has properties, recursively traverse them.
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const result = traverse(obj[key])

        if (result !== undefined) {
          return result
        }
      }
    }

    return undefined
  }

  return traverse(block)
}

/**
 * Update the value of a property (of a KPI block) by id.
 *
 * @param block is the full block (formula) of the KPI,
 * @param id is the id of the block which should be updated,
 * @param property is the property of the block which should be updated,
 * @param value is the value that the property should be updated to.
 * @returns the full edited block (formula) of the KPI.
 */
export const updatePropertyById = (
  block: KpiTemplateCalculationBlock,
  id: string,
  property: string,
  value: any
): KpiTemplateCalculationBlock => {
  const traverse = (
    obj: KpiTemplateCalculationBlock
  ): KpiTemplateCalculationBlock => {
    if (!obj || typeof obj !== 'object') {
      return obj
    }

    // If the current object has the target id, update the specified
    // property with the provided value.
    if ('id' in obj && obj.id === id) {
      return { ...obj, [property]: value }
    }

    // If the current object has properties, recursively traverse them.
    if (obj.left_kpi_calculation_block) {
      obj.left_kpi_calculation_block = traverse(obj.left_kpi_calculation_block)
    }
    if (obj.right_kpi_calculation_block) {
      obj.right_kpi_calculation_block = traverse(
        obj.right_kpi_calculation_block
      )
    }

    return obj
  }

  const temp: KpiTemplateCalculationBlock = { ...block }

  return traverse(temp)
}

/**
 * Edit type of a KPI block.
 *
 * @param block is the full block (formula) of the KPI,
 * @param id is the id of the block which type should be edited,
 * @param value is the value that the type should be edited to.
 * @returns the full edited block (formula) of the KPI.
 */
export const handleEditType = (
  block: KpiTemplateCalculationBlock,
  id: string,
  side: 'left' | 'right',
  value: KpiTemplateCalculationBlockType
) => {
  let newState

  newState = updatePropertyById(block, id, `${side}_value`, null)
  newState = updatePropertyById(newState, id, `${side}_kpi_template`, null)
  newState = updatePropertyById(newState, id, `${side}_kpi_variable`, null)
  newState = updatePropertyById(
    newState,
    id,
    `${side}_kpi_calculation_block`,
    null
  )
  newState = updatePropertyById(newState, id, `${side}_type`, value)

  if (value === KpiTemplateCalculationBlockType.BLOCK) {
    newState = updatePropertyById(
      newState,
      id,
      `${side}_kpi_calculation_block`,
      getEmptyBlock()
    )
  }

  return newState
}

/**
 * Edit value of a KPI block.
 *
 * @param block is the full block (formula) of the KPI,
 * @param id is the id of the block that should be edited,
 * @param value is the value that the value should be edited to.
 * @returns the full edited block (formula) of the KPI.
 */
export const handleEditValue = (
  block: KpiTemplateCalculationBlock,
  id: string,
  key: any,
  value: any
) => {
  const newState = updatePropertyById(block, id, key, value)

  return newState
}

/**
 * Delete a KPI block (set the value and type to null).
 *
 * @param block is the full block (formula) of the KPI,
 * @param id is the id of the block that should be deleted,
 * @returns the full edited block (formula) of the KPI.
 */
export const handleDeleteBlock = (
  block: KpiTemplateCalculationBlock,
  id: string,
  side: 'left' | 'right'
) => {
  const newState = updatePropertyById(
    block,
    id,
    `${side}_kpi_calculation_block`,
    null
  )

  return updatePropertyById(newState, id, `${side}_type`, null)
}

export const formatBlock = (
  block: KpiTemplateCalculationBlock
): PutKpiTemplateCalculationBlock => {
  return {
    left_value: block.left_value,
    right_value: block.right_value,
    left_kpi_template_id: block.left_kpi_template?.id || null,
    right_kpi_template_id: block.right_kpi_template?.id || null,
    left_kpi_variable_id: block.left_kpi_variable?.id || null,
    right_kpi_variable_id: block.right_kpi_variable?.id || null,
    left_kpi_calculation_block: block.left_kpi_calculation_block
      ? formatBlock(block.left_kpi_calculation_block)
      : null,
    right_kpi_calculation_block: block.right_kpi_calculation_block
      ? formatBlock(block.right_kpi_calculation_block)
      : null,
    operation: block.operation as KpiTemplateCalculationOperation
  }
}

export const isValidBlock = (block: KpiTemplateCalculationBlock): boolean => {
  let hasValidLeft = false
  let hasValidRight = false
  const hasOperation = !!block.operation

  if (block.left_value || block.left_kpi_variable || block.left_kpi_template) {
    hasValidLeft = true
  }
  if (
    block.right_value ||
    block.right_kpi_variable ||
    block.right_kpi_template
  ) {
    hasValidRight = true
  }

  if (block.left_kpi_calculation_block) {
    hasValidLeft = isValidBlock(block.left_kpi_calculation_block)
  }
  if (block.right_kpi_calculation_block) {
    hasValidRight = isValidBlock(block.right_kpi_calculation_block)
  }

  return hasValidLeft && hasValidRight && hasOperation
}
