import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const my_homepal_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/cmdb/operations`

export async function getAll(tableId) {
  const header = await Functions.parseAuthenticationHeader()

  return Axios.get(`${URL}/${tableId}`, header)
}

export async function update(tableId, body) {
  const header = await Functions.parseAuthenticationHeader()

  return Axios.patch(`${URL}/${tableId}`, body, header)
}

export async function exportFile(tableId, type) {
  const url = `${URL}/${tableId}/${type}`
  const options = await Functions.parseAuthenticationHeader()

  options.responseType = 'blob'

  return Axios.get(url, options)
}
