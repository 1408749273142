import React, { useState } from 'react'
import ShortId from 'shortid'

import { Mentions as AntdMentions } from 'antd'

import './styles.css'

const { Option } = AntdMentions

/**
 *
 * @param {string} value - The current value.
 * @param {Array<object>} options - The options you can mention.
 * @param {string} placeholder - The placeholder for the component.
 * @param {function} onChange - The onChange function.
 * @param {string} className - Class name for the wrapper component.
 * @param {boolean} autoSize - Adjust height based on input.
 *
 * @returns a mentions component
 */
const Mentions = ({
  value,
  options,
  placeholder,
  onChange,
  className,
  autoSize
}) => {
  const [uniqueId] = useState(ShortId.generate())

  return (
    <div className={`width-100 ${className}`}>
      <AntdMentions
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoSize={autoSize}
      >
        {options.map((option) => (
          <Option key={`${option.value}-${uniqueId}`} value={option.value}>
            {option.label}
          </Option>
        ))}
      </AntdMentions>
    </div>
  )
}

export default Mentions
