import React, { useEffect, useRef } from 'react'
import { message } from 'antd'
import ShortId from 'shortid'

import Icon from 'components_new/atoms/Icon'

export function formatDateString(dateString) {
  const tSplit = dateString.split('T')

  return `${tSplit[0]} ${tSplit[1].split('.')[0]}`
}

export function messageSuccess(text) {
  message.success({
    content: text,
    icon: <Icon color="success" name="CheckCircleOutlined" />
  })
}

export function messageWarning(text) {
  message.success({
    content: text,
    icon: <Icon color="warning" name="ErrorOutlineOutlined" />
  })
}

/**
 * Parses object to array.
 *
 * @param {object} o - the object to parse
 * @return {array} - the array created from the object.
 */
export function objectToArray(o) {
  let a = []

  if (o) {
    Object.keys(o).forEach((k) => {
      a = [...a, o[k]]
    })
  }

  return a
}

/**
 * Parses array to object which uses the id as key.
 *
 * @param {array} a - the array to parse. Must contain object with the id key.
 * @return {object} - the object created from the array.
 */
export function arrayToObject(a, key = 'id') {
  const o = {}

  a.forEach((e) => {
    o[e[key]] = { ...e, key: ShortId.generate() }
  })

  return o
}

export function parseAuthenticationHeader() {
  return {
    withCredentials: true
  }
}

// custom hook for getting previous value
export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export async function validateForm(form) {
  let validateResponse = null

  try {
    validateResponse = await form.current.validateFields()
  } catch (e) {
    validateResponse = e
  }

  if (validateResponse.errorFields && validateResponse.errorFields.length > 0) {
    return false
  }

  return true
}

export function sort(rows, attribute, asc) {
  if (attribute) {
    return rows.sort((o1, o2) => {
      const row1 = (o1.attributes[attribute] || '').toString().toLowerCase()
      const row2 = (o2.attributes[attribute] || '').toString().toLowerCase()

      return asc ? (row1 > row2 ? 1 : -1) : row1 < row2 ? 1 : -1
    })
  }

  return rows
}

/*
  Returns the total validity for an ontology dataset
*/
export function getTotalValidityCount(
  datasetValidCounts,
  datasetId,
  attributeId = undefined
) {
  let hasCount = datasetValidCounts && datasetId in datasetValidCounts

  let totalValids = 0
  let total = 0
  let percent = 0

  if (hasCount && attributeId && attributeId in datasetValidCounts[datasetId]) {
    // Has attribute and that attribute has valid counts

    totalValids = datasetValidCounts[datasetId][attributeId].valid
    total =
      datasetValidCounts[datasetId][attributeId].valid +
      datasetValidCounts[datasetId][attributeId].invalid
    percent = total === 0 ? 0 : Math.floor((totalValids / total) * 100)
  } else if (
    hasCount &&
    attributeId &&
    !(attributeId in datasetValidCounts[datasetId])
  ) {
    // Has attribute and that attribute does not have valid counts

    hasCount = false
  } else if (hasCount && !attributeId) {
    // Total valid count for a dataset

    totalValids = hasCount
      ? Object.values(datasetValidCounts[datasetId]).reduce(
        (a, b) => a + b.valid,
        0
      )
      : 0
    const totalInvalids = hasCount
      ? Object.values(datasetValidCounts[datasetId]).reduce(
        (a, b) => a + b.invalid,
        0
      )
      : 0

    total = totalValids + totalInvalids

    percent = total === 0 ? 0 : Math.floor((totalValids / total) * 100)
  }

  return { hasCount, totalValids, total, percent }
}

export function awsFriendlyName(value) {
  return value
    .replace(/å|ä|Ä|Å/g, 'a')
    .replace(/ö|Ö/g, 'o')
    .replace(/[\W_]+/g, '_')
    .replace(/\s/g, '')
    .toLowerCase()
}

export function getTimeAgoString(date) {
  const now = new Date()

  const diffMs = now - date

  const diffDays = Math.floor(diffMs / 86400000)
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000)
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
  const diffMonths = Math.floor(diffDays / 30)
  const diffYears = Math.floor(diffMonths / 12)

  if (diffHrs < 1) {
    return `${diffMins} ${diffMins === 1 ? 'minut' : 'minuter'} sedan`
  } else if (diffDays < 1) {
    return `${diffHrs} ${diffHrs === 1 ? 'timme' : 'timmar'} sedan`
  } else if (diffMonths < 1) {
    return `${diffDays} ${diffDays === 1 ? 'dag' : 'dagar'} sedan`
  } else if (diffYears < 1) {
    return `${diffMonths} ${diffMonths === 1 ? 'månad' : 'månader'} sedan`
  } else {
    return `${diffYears} år sedan`
  }
}

/**
 * Parse out name information from a user. If the user is missing,
 * return the default value Homepal.
 *
 * @param {string} userId - A given id for a user
 * @param {object} UserStore - ReduxStore containing all Users
 * @returns the users initials, first name and last name
 */
export function getUserNameInformation(userId, UserStore) {
  let initials = 'H'
  let firstName = 'Homepal'
  let lastName = ''

  if (UserStore.data[userId]) {
    firstName = UserStore.data[userId].attributes.first_name
    lastName = UserStore.data[userId].attributes.last_name
    initials = `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`
  }

  return { initials, firstName, lastName }
}

export const capitalizeFirstLetter = (input) => {
  return `${input[0].toUpperCase()}${input.substring(1)}`
}

export const isNumberDataType = (dtype) => ['BIGINT', 'DOUBLE'].includes(dtype)

/**
 * Check if WidgetMetric is of period type.
 * @param {string} wtype - WidgetMetric.type
 * @returns {boolean} WidgetMetric is of period type.
 */
export const isPeriodMetric = (wtype) =>
  wtype &&
  [
    'COUNT_LAST_PERIOD',
    'COUNT_PERIOD_CHANGE',
    'COUNT_LAST_YEAR',
    'COUNT_YEAR_CHANGE',
    'COUNT_DISTINCT_LAST_PERIOD',
    'COUNT_DISTINCT_PERIOD_CHANGE',
    'COUNT_DISTINCT_LAST_YEAR',
    'COUNT_DISTINCT_YEAR_CHANGE',
    'SUM_LAST_PERIOD',
    'SUM_PERIOD_CHANGE',
    'SUM_LAST_YEAR',
    'SUM_YEAR_CHANGE'
  ].includes(wtype)

/**
 * Formats a number to a more readable value
 *
 * @param {number} value The number to format
 * @param {number} decimals Number of decimals to display
 * @returns {string} Formatted number
 */
export const formatNumber = (value, decimals = 2) => {
  if (typeof value !== 'number') {
    return value
  }

  return new Intl.NumberFormat('sv-SE', {
    maximumFractionDigits: decimals
  }).format(value)
}
