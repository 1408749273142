import _ from 'lodash'
import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  type: undefined,
  fetched: false,
  currentFetchOne: undefined,
  isCreating: false,
  isUpdating: false,

  // creating from library
  placeholder: null,
  createdDashboard: null,
  createdId: null
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let data

  switch (type) {
  case Types.GET_DATA_PRODUCTS_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload.data),
      type: payload.type,
      fetched: true
    }

  case Types.GET_ONE_DATA_PRODUCT_SUCCESS:
    return {
      ...state,
      currentFetchOne: payload.id,
      createdId: null,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.CREATE_DATA_PRODUCT:
    return {
      ...state,
      placeholder: null,
      createdDashboard: null,
      createdId: null
    }

  case Types.CREATE_DATA_PRODUCT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.UPDATE_DATA_PRODUCT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_DATA_PRODUCT_SUCCESS:
    data = _.cloneDeep(state.data)
    delete data[payload]

    return {
      ...state,
      data
    }

  case Types.CREATE_API_KEY_SUCCESS:
    // add new api key to included api keys
    return {
      ...state,
      data: {
        ...state.data,
        [payload.attributes.data_product_id]: {
          ...state.data[payload.attributes.data_product_id],
          included: {
            ...state.data[payload.attributes.data_product_id].included,
            api_keys: [
              ...(state.data[payload.attributes.data_product_id].included
                .api_keys || []),
              payload
            ]
          }
        }
      }
    }

  case Types.DELETE_DATA_PRODUCT_KEY_SUCCESS:
    // remove api key from included api keys

    return {
      ...state,
      data: {
        ...state.data,
        [payload.data_product_id]: {
          ...state.data[payload.data_product_id],
          included: {
            ...state.data[payload.data_product_id].included,
            api_keys: _.cloneDeep(
              state.data[payload.data_product_id].included.api_keys
            ).filter((item) => item.id !== payload.id)
          }
        }
      }
    }
  case Types.UPDATE_DATA_PRODUCT_KEY_SUCCESS:
    // replace updated api key in included api keys

    return {
      ...state,
      data: {
        ...state.data,
        [payload.attributes.data_product_id]: {
          ...state.data[payload.attributes.data_product_id],
          included: {
            ...state.data[payload.attributes.data_product_id].included,
            api_keys: _.cloneDeep(
              state.data[payload.attributes.data_product_id].included.api_keys
            ).map((apiKey) => (apiKey.id === payload.id ? payload : apiKey))
          }
        }
      }
    }

  case Types.UPDATE_ENDPOINT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.included.endpoint.attributes.data_product_id]: {
          ...state.data[payload.included.endpoint.attributes.data_product_id],
          attributes: {
            ...state.data[
              payload.included.endpoint.attributes.data_product_id
            ].attributes,
            tables: _.cloneDeep(
              state.data[payload.included.endpoint.attributes.data_product_id]
                .attributes.tables
            ).map((table) => (table.id === payload.id ? payload : table))
          }
        }
      }
    }

  case Types.CREATE_APPS_DATASET:
    return {
      ...state,
      isCreating: true
    }

  case Types.CREATE_APPS_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data_product_id]: {
          ...state.data[payload.data_product_id],
          attributes: {
            ...state.data[payload.data_product_id].attributes,
            tables: [
              ...state.data[payload.data_product_id].attributes.tables,
              payload
            ]
          }
        }
      },
      isCreating: false
    }

  case Types.CREATE_APPS_DATASET_FAILED:
    return {
      ...state,
      isCreating: false
    }

  case Types.UPDATE_APPS_DATASET:
    return {
      ...state,
      isUpdating: true
    }

  case Types.UPDATE_APPS_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data_product_id]: {
          ...state.data[payload.data_product_id],
          attributes: {
            ...state.data[payload.data_product_id].attributes,
            tables: _.cloneDeep(
              state.data[payload.data_product_id].attributes.tables
            ).map((table) => (table.id === payload.id ? payload : table))
          }
        }
      },
      isUpdating: false
    }

  case Types.UPDATE_APPS_DATASET_FAILED:
    return {
      ...state,
      isUpdating: false
    }

  case Types.DELETE_APPS_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data_product_id]: {
          ...state.data[payload.data_product_id],
          attributes: {
            ...state.data[payload.data_product_id].attributes,
            tables: _.cloneDeep(
              state.data[payload.data_product_id].attributes.tables
            ).filter((table) => table.id !== payload.id)
          }
        }
      }
    }

  case Types.START_JOB_RUN_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: {
          ...state.data[payload.id],
          attributes: {
            ...state.data[payload.id].attributes,
            latest_run: payload.data
          }
        }
      }
    }

  case Types.POLL_JOB_RUN_SUCCESS:
    if (state.createdDashboard && payload.id === state.createdDashboard.id) {
      if (
        payload.data.state === 'SUCCEEDED' ||
          payload.data.state === 'FAILED'
      ) {
        return {
          ...state,
          data: {
            ...state.data,
            [state.createdDashboard.id]: {
              ...state.createdDashboard,
              attributes: {
                ...state.createdDashboard.attributes,
                latest_run: payload.data
              }
            }
          },
          createdDashboard: null,
          placeholder: null,
          createdId: payload.id,
          isCreating: false
        }
      } else {
        return {
          ...state,
          createdDashboard: {
            ...state.createdDashboard,
            latest_run: payload.data
          }
        }
      }
    }

    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: {
          ...state.data[payload.id],
          attributes: {
            ...state.data[payload.id].attributes,
            latest_run: payload.data
          }
        }
      }
    }

  case Types.CREATE_TAB_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.dataProductId]: {
          ...state.data[payload.dataProductId],
          attributes: {
            ...state.data[payload.dataProductId].attributes,
            tables: _.cloneDeep(
              state.data[payload.dataProductId].attributes.tables
            ).map((table) => {
              if (table.id === payload.data.attributes.table_id) {
                return {
                  ...table,
                  included: {
                    ...table.included,
                    dashboard_tabs: [
                      ...table.included.dashboard_tabs,
                      payload.data
                    ]
                  }
                }
              }

              return table
            })
          }
        }
      }
    }

  case Types.DELETE_TAB_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.dataProductId]: {
          ...state.data[payload.dataProductId],
          attributes: {
            ...state.data[payload.dataProductId].attributes,
            tables: _.cloneDeep(
              state.data[payload.dataProductId].attributes.tables
            ).map((table) => {
              if (table.id === payload.tableId) {
                return {
                  ...table,
                  included: {
                    ...table.included,
                    dashboard_tabs: table.included.dashboard_tabs.filter(
                      (tab) => tab.id !== payload.tabId
                    )
                  }
                }
              }

              return table
            })
          }
        }
      }
    }

  case Types.UPDATE_TAB_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.dataProductId]: {
          ...state.data[payload.dataProductId],
          attributes: {
            ...state.data[payload.dataProductId].attributes,
            tables: _.cloneDeep(
              state.data[payload.dataProductId].attributes.tables
            ).map((table) => {
              if (table.id === payload.data.attributes.table_id) {
                return {
                  ...table,
                  included: {
                    ...table.included,
                    dashboard_tabs: table.included.dashboard_tabs.map(
                      (tab) => {
                        if (tab.id === payload.data.id) {
                          return payload.data
                        }

                        return tab
                      }
                    )
                  }
                }
              }

              return table
            })
          }
        }
      }
    }

  case Types.CREATE_TABLE_FILTER_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.dataProductId]: {
          ...state.data[payload.dataProductId],
          attributes: {
            ...state.data[payload.dataProductId].attributes,
            tables: _.cloneDeep(
              state.data[payload.dataProductId].attributes.tables
            ).map((table) => {
              if (table.id === payload.data.attributes.table_id) {
                return {
                  ...table,
                  included: {
                    ...table.included,
                    table_filters: [
                      ...(table.included?.table_filters || []),
                      payload.data
                    ]
                  }
                }
              }

              return table
            })
          }
        }
      }
    }

  case Types.DELETE_TABLE_FILTER_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.dataProductId]: {
          ...state.data[payload.dataProductId],
          attributes: {
            ...state.data[payload.dataProductId].attributes,
            tables: _.cloneDeep(
              state.data[payload.dataProductId].attributes.tables
            ).map((table) => {
              if (table.id === payload.tableId) {
                return {
                  ...table,
                  included: {
                    ...table.included,
                    table_filters: _.cloneDeep(
                      table.included.table_filters
                    ).filter((item) => item.id !== payload.id)
                  }
                }
              }

              return table
            })
          }
        }
      }
    }

  case Types.ACTIVATE_FROM_LIBRARY:
    return {
      ...state,
      isCreating: true,
      placeholder: payload,
      createdDashboard: null
    }

  case Types.ACTIVATE_FROM_LIBRARY_SUCCESS:
    return {
      ...state,
      createdDashboard: payload
    }

  case Types.ACTIVATE_FROM_LIBRARY_FAILED:
    return {
      ...state,
      isCreating: false,
      placeholder: null
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
