import React, { useState, useEffect } from 'react'
import { sendMessageToSlack } from 'components/common/Auth/AuthModule'
import { Option } from 'components_new/molecules/SelectSearch'

import Button from 'components_new/atoms/Button'
import Tooltip from 'components_new/atoms/Tooltip'

import GuideDialog from 'components_new/molecules/GuideDialog'

import DashboardView from './DashboardView'
import RoleView from './RoleView'

interface WelcomeDialogProps {
  onClose: (role: string, preference: string) => void
  open: boolean
  updating: boolean
  user: {
    first_name: string
    last_name: string
    email: string
  }
}

const WelcomeDialog = (props: WelcomeDialogProps) => {
  const { onClose, open, updating, user } = props

  const handleSubmit = () => {
    const role = customRole || selectedRoles?.map((opt) => opt.label).join(', ')
    const preference =
      customCategory || selectedCategories?.map((opt) => opt.label).join(', ')

    onClose(role, preference)
  }

  const [selectedRoles, setSelectedRoles] = useState<Option[]>([])
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])

  const [customRole, setCustomRole] = useState<string>('')
  const [customCategory, setCustomCategory] = useState<string>('')
  const [sentMessage, setSentMessage] = useState<boolean>(false)

  useEffect(() => {
    if (open && !sentMessage) {
      setSentMessage(true)

      sendMessageToSlack(
        'Någon har fått upp välkomstformuläret',
        `
      Inväntar svar från ${user.first_name} ${user.last_name}
      `,
        user.email
      )
    }
  }, [open])

  const [step, setStep] = useState(1)

  const valid =
    (selectedRoles.length > 0 || customRole) &&
    (selectedCategories.length > 0 || customCategory)

  return (
    <GuideDialog
      maxWidth={step === 1 ? 'lg' : 'sm'}
      open={open}
      title={
        step === 1
          ? 'Välkommen till Homepal 🎉'
          : 'Vi vill hjälpa dig att komma igång!'
      }
      subtitle={
        step === 1 ? (
          <>
            Nu kan du enklare än någonsin komma åt verksamhetens data via
            automatiserade <b>dashboards*</b> på det sätt som du vill.
          </>
        ) : (
          <>
            Ange lite kort information om dig själv så ska Homepals team göra
            allt de kan för att du ska få tillgång till en dashboard som är
            relevant för dig.
          </>
        )
      }
      imageSrc={step === 1 ? '/assets/illustrations/dashboards.svg' : undefined}
      actions={
        step === 1 ? (
          <Button onClick={() => setStep(2)} size="large">
            Nästa
          </Button>
        ) : (
          <Tooltip
            title={
              !valid
                ? 'Du måste ange minst en roll och ett område för att komma igång.'
                : undefined
            }
          >
            <span>
              <Button
                onClick={handleSubmit}
                loading={updating}
                size="large"
                disabled={!valid || updating}
                loadingTitle={'Förbereder Homepal'}
              >
                Kom igång
              </Button>
            </span>
          </Tooltip>
        )
      }
      step={step}
      steps={2}
    >
      {step === 1 ? (
        <DashboardView />
      ) : (
        <RoleView
          customCategory={customCategory}
          customRole={customRole}
          selectedCategories={selectedCategories}
          selectedRoles={selectedRoles}
          setCustomCategory={setCustomCategory}
          setCustomRole={setCustomRole}
          setSelectedCategories={setSelectedCategories}
          setSelectedRoles={setSelectedRoles}
        />
      )}
    </GuideDialog>
  )
}

export default WelcomeDialog
