import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// Access Hub pages
import AccessHubOverview from 'components/pages/Applications/AccessHub'
import AccessHubSpecific from 'components/pages/Applications/AccessHub/Specific'

import AuthRoute from './AuthRoute'

const Routes = ({ AuthStore }) => {
  return (
    <Switch>
      {/* Access Hub */}
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubOverview}
        path={'/access-hub'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubSpecific}
        path={'/access-hub/:id/connection'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubSpecific}
        path={'/access-hub/:id/dataset'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubSpecific}
        path={'/access-hub/:id/dataset/new'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubSpecific}
        path={'/access-hub/:id/connection/new'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={AccessHubSpecific}
        path={'/access-hub/:id/dataset/edit/:tableId'}
        exact
      />
      <Route path={'/access-hub/*'}>
        <Redirect to="/access-hub" />
      </Route>
      <Route path={'/vacancy-reports/*'}>
        <Redirect to="/vacancy-reports/create" />
      </Route>
      <Route path={'/vacancy-reports'}>
        <Redirect to="/vacancy-reports/create" />
      </Route>
    </Switch>
  )
}

export default Routes
