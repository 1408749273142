import React, { useState } from 'react'

import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import CreateListDialog from './dialogs/CreateListDialog'

import { SIMTable } from 'redux/reducers/SIM'

interface ListsTemplate {
  lists: SIMTable[]
}

const ListsTemplate = (props: ListsTemplate) => {
  const [selectListIsOpen, setSelectListIsOpen] = useState<boolean>(false)

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: 8
          }}
          variant="outlined"
        >
          <Stack alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Text variant="h5" align="center">
              Listor
            </Text>
            <Chip label="Beta" color="primary" />
          </Stack>
          <Text color="text.secondary" align="center" sx={{ mb: 2 }}>
            Vi ger dig möjligheten att konfigurera och exportera olika listor
            baserat på den data som finns hos oss.
          </Text>
          <Button
            onClick={() => setSelectListIsOpen(true)}
            size="large"
            startIcon={<Icon name="PlayCircleFilledWhite" />}
            variant="contained"
            loadingTitle={'Exporterar lista'}
          >
            Påbörja en export
          </Button>
        </Paper>
      </Paper>

      {/* ----- Dialogs ----- */}
      <CreateListDialog
        onClose={() => setSelectListIsOpen(false)}
        open={selectListIsOpen}
        lists={props.lists}
      />
    </>
  )
}

export default ListsTemplate
