import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

import {
  FilterConditionFormatBody,
  FilterPatchBody
} from 'types/GlobalKpiOption'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import FilterEditItem from '../FilterEditItem'

import {
  formatUpdateFilterBody,
  getFilterBody,
  getFilterTitle,
  getFilterValue
} from '../utils'

interface FilterPreviewProps {
  allFilters: FilterConditionFormatBody[] // all filters in order to add,
  // delete or edit.
  filter: FilterConditionFormatBody
  filterOptions: KpiTemplateFilterOptionsBody | null
  onClose: () => void
  updateFilters: (filters: FilterPatchBody[]) => void
}

const FilterItem = (props: FilterPreviewProps) => {
  const { allFilters, filter, filterOptions, onClose, updateFilters } = props

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  // handleUpdateDrawer
  useEffect(() => {
    if (drawer.open) {
      handleOpenDrawer()
    }
  }, [allFilters])

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: 'Filter'
    })
  }

  const handleDelete = () => {
    const newFilters = cloneDeep(allFilters).filter(
      (f) =>
        f.attribute_option.selected !== filter?.attribute_option.selected ||
        f.condition.selected !== filter?.condition.selected ||
        f.type.selected !== filter?.type.selected ||
        f.filter_group_id !== filter?.filter_group_id
    )

    updateFilters(formatUpdateFilterBody(newFilters))
    handleBackDrawer()
  }

  return (
    <>
      <SettingsItem
        onClick={handleOpenDrawer}
        body={getFilterBody(filter) ?? undefined}
        title={getFilterTitle(filter)}
        value={getFilterValue(filter)}
      />

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        <FilterEditItem
          allFilters={allFilters}
          filter={filter}
          filterOptions={filterOptions}
          updateFilters={updateFilters}
        />
        <SettingsGroup>
          <SettingsItem
            color="error"
            onClick={handleDelete}
            title="Radera"
            variant="action"
          />
        </SettingsGroup>
      </SettingsDrawer>
    </>
  )
}

export default FilterItem
