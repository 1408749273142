import React, { ReactNode } from 'react'
import { Portal as MUIPortal } from '@mui/material'

interface PortalProps {
  children?: ReactNode
  disablePortal?: boolean
}

/**
 * The Portal component provides a first-class way
 * to render children into a DOM node that exists
 * outside the DOM hierarchy of the parent
 * component.
 *
 * Portal is a utility component built around
 * React's createPortal() API.
 */

const Portal = (props: PortalProps) => {
  const { children, ...rest } = props

  return <MUIPortal {...rest}>{children}</MUIPortal>
}

Portal.displayName = 'Portal'
export default Portal
