import React, { MouseEvent, ReactNode } from 'react'

import { CustomColors } from 'types/GlobalCustomization'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface FullSizeDialogProps {
  children?: ReactNode
  colors?: CustomColors | null
  onClose: () => void
  open: boolean
}

const FullSizeDialog = (props: FullSizeDialogProps) => {
  const { children, colors, onClose, open } = props

  return (
    <SimpleDialog
      actions={<Button onClick={onClose}>Stäng</Button>}
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundImage: 'none',
          height: '100%',
          backgroundColor: colors?.widget_background_color
            ? colors.widget_background_color
            : undefined
        }
      }}
      // In order to stop the user from dragging around the underlying widget.
      onMouseDown={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          minWidth: 0
        }}
      >
        {children}
      </Box>
    </SimpleDialog>
  )
}

export default FullSizeDialog
