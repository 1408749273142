import * as Api from 'redux/api/DataPlatform/dataQuality/rules/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAllRules() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_RULES
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_RULES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_RULES_FAILED
        })
      })
  }
}

export function tryPutRules(body, tableId) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_RULES
    })

    Api.put(body)
      .then((response) => {
        dispatch({
          type: Types.PUT_RULES_SUCCESS,
          payload: { data: response.data.data, tableId }
        })

        messageSuccess('Reglerna har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_RULES_FAILED
        })

        messageWarning('Reglerna kunde inte uppdateras')
      })
  }
}
