import React, { useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import * as WidgetActions from 'redux/actions/Applications/InsightStudio/Widgets'

import Broken from 'assets/insight_studio/widget/broken_grey.svg'
import NoData from 'assets/insight_studio/widget/no_data_key_figure.svg'

import { ErrorComponent } from './conf'
import { formatNumber } from 'helpers/Functions'

const parseSuffix = (value, widgetMetrics) => {
  if (widgetMetrics && widgetMetrics.length > 0) {
    let suffix = widgetMetrics[0].suffix_plural

    if (value === 1 || value === '1') {
      suffix = widgetMetrics[0].suffix_singular
    }

    return suffix
  }

  return ''
}

const getValue = (data, metrics) => {
  if (data.length > 0 && metrics.length > 0) {
    return data[0][metrics[0]?.attributeId]
  }

  return null
}

const KeyFigure = ({ widgetData }) => {
  const ref = useRef()

  const {
    metrics = [],
    widget_metrics = [],
    title,
    data = [],
    broken
  } = widgetData

  const widgetMetricType = widget_metrics?.[0]?.type
  const percentageType =
    widgetMetricType && widgetMetricType?.[0] === 'PERCENTAGE'

  const value = formatNumber(getValue(data, metrics), percentageType ? 1 : 0)

  const suffix = parseSuffix(value, widget_metrics)
  const error = broken || !value

  const errorComponent = (
    <ErrorComponent
      img={broken ? Broken : NoData}
      text={broken ? 'Sektionen är trasig' : 'Ingen data att visa'}
      className={'secondary'}
    />
  )

  const valueComponent = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <Text color="text.primary" variant="h1" sx={{ lineHeight: 1 }}>
        {value}
      </Text>
      <Text color="text.secondary" variant="h6" sx={{ lineHeight: 1 }}>
        {suffix}
      </Text>
    </Box>
  )

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        gap: 0.5
      }}
      ref={ref}
    >
      {error ? errorComponent : valueComponent}
      <Text color="text.primary" variant="subtitle1">
        {title}
      </Text>
    </Box>
  )
}

function mapStateToProps({ DashboardWidgetStore }) {
  return { DashboardWidgetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(WidgetActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyFigure)
