import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'

import {
  DashboardFolder,
  DashboardFolderReduxData,
  DashboardFolderStore
} from 'types/GlobalDashboardFolder'

// Initial state
const INITIAL_STATE: DashboardFolderStore = {
  data: {},
  fetched: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false
}

// Create action
const createDashboardFolder = createAction(Types.CREATE_DASHBOARD_FOLDER)
const createDashboardFolderSuccess = createAction<DashboardFolder>(
  Types.CREATE_DASHBOARD_FOLDER_SUCCESS
)
const createDashboardFolderFailed = createAction(
  Types.CREATE_DASHBOARD_FOLDER_FAILED
)

// Get all actions
const getAllDashboardFoldersSuccess = createAction<DashboardFolder[]>(
  Types.GET_ALL_DASHBOARD_FOLDERS_SUCCESS
)

// Update action
const updateDashboardFolder = createAction(Types.UPDATE_DASHBOARD_FOLDER)
const updateDashboardFolderSuccess = createAction<DashboardFolder>(
  Types.UPDATE_DASHBOARD_FOLDER_SUCCESS
)
const updateDashboardFolderFailed = createAction(
  Types.UPDATE_DASHBOARD_FOLDER_FAILED
)

// Delete action
const deleteDashboardFolder = createAction(Types.DELETE_DASHBOARD_FOLDER)
const deleteDashboardFolderSuccess = createAction<string>(
  Types.DELETE_DASHBOARD_FOLDER_SUCCESS
)
const deleteDashboardFolderFailed = createAction(
  Types.DELETE_DASHBOARD_FOLDER_FAILED
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const CustomersAndLicensesReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(createDashboardFolder, (state) => {
      return {
        ...state,
        isCreating: true
      }
    })
    .addCase(createDashboardFolderSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        isCreating: false,
        data: {
          ...state.data,
          [payload.id]: payload
        }
      }
    })
    .addCase(createDashboardFolderFailed, (state) => {
      return {
        ...state,
        isCreating: false
      }
    })
    .addCase(getAllDashboardFoldersSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        fetched: true,
        data: arrayToObject(payload) as DashboardFolderReduxData
      }
    })
    .addCase(updateDashboardFolder, (state) => {
      return {
        ...state,
        isUpdating: true
      }
    })
    .addCase(updateDashboardFolderSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          [payload.id]: payload
        }
      }
    })
    .addCase(updateDashboardFolderFailed, (state) => {
      return {
        ...state,
        isUpdating: false
      }
    })
    .addCase(deleteDashboardFolder, (state) => {
      return {
        ...state,
        isDeleting: true
      }
    })
    .addCase(deleteDashboardFolderSuccess, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)

      delete newData[payload]

      return {
        ...state,
        isDeleting: false,
        data: newData
      }
    })
    .addCase(deleteDashboardFolderFailed, (state) => {
      return {
        ...state,
        isDeleting: false
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default CustomersAndLicensesReducer
