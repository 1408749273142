import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'

import { WidgetObject } from 'types/GlobalWidget'

interface ImageProps {
  setEdit: (arg0: boolean) => void
  widget: WidgetObject
}

const Image = (props: ImageProps) => {
  const { setEdit, widget } = props

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        backgroundImage: widget.image ? `url(${widget.image.url})` : undefined,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center 50%'
      }}
    >
      <EmptyPlaceholder
        empty={!widget.image}
        icon="Image"
        actions={
          <Button variant="text" onClick={() => setEdit(true)}>
            Ladda upp
          </Button>
        }
      />
    </Box>
  )
}

export default Image
