import React, { ReactNode } from 'react'
import { ListItemAvatar as MUIListItemAvatar } from '@mui/material'

interface ListItemAvatarProps {
  children: ReactNode
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItemAvatar = React.forwardRef(
  (props: ListItemAvatarProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUIListItemAvatar {...rest} ref={ref}>
        {children}
      </MUIListItemAvatar>
    )
  }
)

ListItemAvatar.displayName = 'ListItemAvatar'
export default ListItemAvatar
