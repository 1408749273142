import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const API_URL =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? API_URL
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const TAG_URL = `${BASE_URL}v1/tags`
const TAG_LINKER_URL = `${BASE_URL}v1/tag-linkers`

export async function getAll() {
  return Axios.get(TAG_URL, await Functions.parseAuthenticationHeader())
}

export async function put(body) {
  return Axios.put(
    `${TAG_LINKER_URL}`,
    body,
    await Functions.parseAuthenticationHeader()
  )
}

export async function remove(id) {
  return Axios.delete(
    `${TAG_LINKER_URL}/${id}`,
    await Functions.parseAuthenticationHeader()
  )
}
