import React, { ElementType, ReactNode } from 'react'
import { ListItem as MUIListItem } from '@mui/material'

interface ListItemProps {
  alignItems?: 'center' | 'flex-start'
  children: ReactNode
  component?: ElementType<any>
  dense?: boolean
  disableGutters?: boolean
  disablePadding?: boolean
  divider?: boolean
  secondaryAction?: ReactNode
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItem = React.forwardRef((props: ListItemProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIListItem {...rest} ref={ref}>
      {children}
    </MUIListItem>
  )
})

ListItem.displayName = 'ListItem'
export default ListItem
