import * as Api from 'redux/api/DataPlatform/cmdb/apis/v1'
import * as Types from 'redux/Types'

export function tryGetAllCmdbApis(token) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_CMDB_APIS
    })

    Api.getAll(token)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_CMDB_APIS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_CMDB_APIS_FAILED
        })
      })
  }
}
