import React, { useEffect, useRef, useState } from 'react'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import {
  PermissionPatchType,
  UserType
} from 'components_new/pages/Dashboard/utils'
import { WhoCanView } from 'redux/reducers/Dashboards'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Divider from 'components_new/atoms/Divider'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'

import SectionHeader from 'components_new/molecules/SectionHeader'
import SidebarDialog from 'components_new/molecules/SidebarDialog'

import Actions from './Actions'
import CustomersSection from './CustomersSection'
import EmbedSection from './EmbedSection'
import DisabledWrapper from './EmbedSection/DisabledWrapper'
import Filter, { TFilter } from './EmbedSection/Filter'
import InviteSection from './InviteSection'
import PermissionSection from './PermissionSection'
import Title from './Title'
import WhoCanViewSection from './WhoCanViewSection'
import PublicStatus from './PublicStatus'
import TabSwitch from 'components_new/molecules/TabSwitch'
import { CompanyGroupWithPermission } from 'types/GlobalCompanyGroups'

enum SwitchTabValues {
  USERS = 'USERS',
  CUSTOMERS = 'CUSTOMERS'
}

const translateSwitchTabValues: Record<SwitchTabValues, string> = {
  [SwitchTabValues.USERS]: 'Användare',
  [SwitchTabValues.CUSTOMERS]: 'Koncerner'
}

interface ShareDialogProps {
  allowEmbed: boolean
  customerOptions: CompanyGroupWithPermission[]
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  dashboardTitle: string
  fullRights: boolean
  groupTitle: string
  isManagementCompany: boolean
  isPublic: boolean
  link: string
  onClose: () => void
  open: boolean
  inviteUser: (user: PermissionPatchType) => void
  publicLink: string
  revokeUser: (accountId?: string, companyGroupId?: string) => void
  setIsPublic: (is_public: boolean) => void
  setWhoCanView: (type: WhoCanView) => void
  userOptions: UserType[]
  userPermissions: UserType[]
  whoCanView: WhoCanView
}

const ShareDialog = (props: ShareDialogProps) => {
  const {
    allowEmbed,
    customerOptions,
    dashboardFilterOptions,
    dashboardTitle,
    fullRights,
    groupTitle,
    inviteUser,
    isManagementCompany,
    isPublic,
    // link,
    onClose,
    open,
    publicLink,
    revokeUser,
    setIsPublic,
    setWhoCanView,
    userPermissions,
    userOptions,
    whoCanView
  } = props

  const handleClose = () => {
    onClose()
  }

  // Uncomment when implementing share tab or dashboard:
  //
  // const [share, setShare] = useState<'tab' | 'dashboard'>('tab')
  // const handleToggleShare = () => {
  //   if (share === 'tab') {
  //     setShare('dashboard')
  //   }
  //   if (share === 'dashboard') {
  //     setShare('tab')
  //   }
  // }

  const [filter, setFilter] = useState<TFilter>({})

  const [tab, setTab] = useState<'default' | 'embed' | 'email'>('default')
  const [switchTab, setSwitchTab] = useState<SwitchTabValues>(
    SwitchTabValues.USERS
  )

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(publicLink)
    setShowCopiedMessage(true)
  }

  const addUserInputRef = useRef<HTMLElement | null>(null)

  const focusAddUser = () => {
    setTimeout(() => {
      if (addUserInputRef.current != null) {
        addUserInputRef.current.focus()
      }
    }, 500)
  }

  useEffect(() => {
    if (!open) {
      setFilter({})
      setTab('default')
    }
  }, [open])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SidebarDialog
        actions={
          tab === 'default' ? (
            <Actions
              isPublic={isPublic}
              onClose={handleClose}
              onCopy={copyLinkToClipboard}
              setTab={(value) => setTab(value)}
              tab={tab}
              showActions={switchTab === SwitchTabValues.USERS}
            />
          ) : undefined
        }
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: tab === 'embed' ? 'md' : '512px!important',
            transition: 'max-width 0.5s ease'
          }
        }}
        rightSidebar={
          tab === 'embed' ? (
            <Box sx={{ p: 2 }}>
              <DisabledWrapper isPublic={isPublic}>
                <Filter
                  embedType={'dashboard'}
                  filter={filter}
                  filterOptions={dashboardFilterOptions}
                  setFilter={setFilter}
                />
              </DisabledWrapper>
            </Box>
          ) : undefined
        }
        title={
          <Title
            dashboardTitle={dashboardTitle}
            groupTitle={groupTitle}
            setTab={setTab}
            tab={tab}
          />
        }
      >
        <Collapse in={tab === 'default' && isManagementCompany}>
          <TabSwitch
            options={Object.values(SwitchTabValues).map((value) => ({
              value,
              label: translateSwitchTabValues[value]
            }))}
            value={switchTab}
            onChange={(value) => setSwitchTab(value as SwitchTabValues)}
            color={'primary'}
            sx={{ mt: -2, mb: 2 }}
          />
        </Collapse>
        <Collapse in={switchTab === SwitchTabValues.CUSTOMERS}>
          <CustomersSection
            fullRights={fullRights}
            customerOptions={customerOptions}
            onInvite={(companyGroupId: string) =>
              inviteUser({ companyGroupId })
            }
            onRemove={(companyGroupId: string) =>
              revokeUser(undefined, companyGroupId)
            }
            userOptions={userOptions}
          />
        </Collapse>
        <Collapse in={switchTab === SwitchTabValues.USERS}>
          <Box>
            <Collapse in={tab === 'default'}>
              <Box sx={{ mb: 2 }}>
                <PermissionSection
                  addUserInputRef={addUserInputRef}
                  permissions={userPermissions}
                  fullRights={fullRights}
                  onInvite={inviteUser}
                  onRemove={revokeUser}
                  userOptions={userOptions}
                  whoCanView={whoCanView}
                />
              </Box>
              <Box>
                <SectionHeader title="Allmän åtkomst" />
                <WhoCanViewSection
                  editable={fullRights}
                  setWhoCanView={setWhoCanView}
                  whoCanView={whoCanView}
                />
              </Box>
              {/* Uncomment when implementing share tab or dashboard:
                
                <Collapse in={share === 'tab'}> */}
              <Divider sx={{ my: 3 }} />
              <PublicStatus
                editable={fullRights}
                embedType="dashboard"
                isPublic={isPublic}
                onRedirect={() => setTab('embed')}
              />
            </Collapse>
            <Collapse in={tab === 'email'}>
              <InviteSection
                onInvite={(email: string, message: string) =>
                  inviteUser({ message, newUserEmail: email })
                }
                onGoBack={() => {
                  setTab('default')
                  focusAddUser()
                }}
              />
            </Collapse>
            <Collapse in={tab === 'embed'}>
              <EmbedSection
                allowEmbed={allowEmbed}
                controlledFilter={filter}
                editable={fullRights}
                embedType="dashboard"
                filterOptions={dashboardFilterOptions}
                isPublic={isPublic}
                link={publicLink}
                setIsPublic={setIsPublic}
              />
            </Collapse>
          </Box>
        </Collapse>
      </SidebarDialog>

      {/* ----- Feedback ----- */}
      <Portal>
        <Snackbar
          open={showCopiedMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowCopiedMessage(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </ThemeProvider>
  )
}

export default ShareDialog
