import React, { useEffect, useMemo, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemSecondaryAction from 'components_new/atoms/List/ListItemSecondaryAction'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import UploadFile from 'components_new/molecules/UploadFile'

import EditEconomySetsDialog from 'components_new/organisms/dialogs/EditEconomySetsDialog'

import { CompanyGroup } from 'types/GlobalCompanyGroups'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { EconomySet, ParsedEconomySet } from 'types/GlobalEconomySets'

interface EconomySetsTemplateProps {
  AuthStore: AuthenticationState
  companyGroups: CompanyGroup[]
  createEconomyAccount: (
    data: {
      name: string
      number: string
      economy_set_id: string
    },
    callback?: (err?: boolean) => void
  ) => void
  createEconomySet: (
    name: string,
    csvFile: Blob,
    callback?: (err?: boolean) => void
  ) => void
  deleteEconomyAccount: (
    id: string,
    economySetId: string,
    callback?: (err?: boolean) => void
  ) => void
  deleteEconomySet: (id: string, callback?: (err?: boolean) => void) => void
  economySets: EconomySet[]
  updateCompanyGroup: (
    id: string,
    name?: string,
    economySetId?: string | null,
    callback?: (err?: boolean) => void
  ) => void
  updateEconomyAccount: (
    id: string,
    name: string,
    economySetId: string,
    callback?: (err?: boolean) => void
  ) => void
  updateEconomySet: (
    id: string,
    name: string,
    callback?: (err?: boolean) => void
  ) => void
  uploadEconomySet: (
    id: string,
    csvFile: Blob,
    callback?: (err?: boolean) => void
  ) => void
}

const EconomySetsTemplate = (props: EconomySetsTemplateProps) => {
  const {
    companyGroups,
    createEconomyAccount,
    createEconomySet,
    deleteEconomyAccount,
    deleteEconomySet,
    economySets,
    updateCompanyGroup,
    updateEconomyAccount,
    updateEconomySet,
    uploadEconomySet
  } = props

  // Loaders
  const [creatingSet, setCreatingSet] = useState<boolean>(false)
  const [deletingSet, setDeletingSet] = useState<boolean>(false)

  const [economySetToDelete, setEconomySetsToDelete] =
    useState<ParsedEconomySet | null>(null)
  const [economySetToEdit, setEconomySetsToEdit] =
    useState<ParsedEconomySet | null>(null)
  const [createEconomySets, setCreateEconomySets] = useState(false)
  const [newEconomySetsName, setNewEconomySetsName] = useState('')
  const [
    showCompanyGroupsWithoutEconomySets,
    setShowCompanyGroupsWithoutEconomySets
  ] = useState(false)
  const [newEconomySetsFile, setNewEconomySetsFile] = useState<{
    file: Blob
    name: string
  } | null>(null)

  const parsedEconomySets: ParsedEconomySet[] = useMemo(() => {
    return economySets.map((set) => {
      return {
        ...set,
        companyGroups: companyGroups.filter(
          (group) => group.economy_set_id === set.id
        )
      }
    })
  }, [economySets, companyGroups])

  const companyGroupsWithoutEconomySets = useMemo(() => {
    return companyGroups.filter((group) => !Boolean(group.economy_set_id))
  }, [economySets, companyGroups])

  useEffect(() => {
    if (economySetToEdit) {
      setEconomySetsToEdit(
        parsedEconomySets.find((set) => set.id === economySetToEdit.id) || null
      )
    }
  }, [parsedEconomySets])

  const handleCloseCreateEconomySets = () => {
    setCreateEconomySets(false)

    setNewEconomySetsFile(null)
    setNewEconomySetsName('')
  }

  return (
    <>
      <CollapsibleSection
        title="Kontoplaner"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
        actions={
          props.AuthStore.customer?.is_management_company ? (
            <Button
              onClick={() => setCreateEconomySets(true)}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till
            </Button>
          ) : null
        }
      >
        {props.AuthStore.customer?.is_management_company &&
        companyGroupsWithoutEconomySets.length > 0 ? (
              <Alert severity="warning" icon={<Icon name="WarningAmberOutlined" />}>
                <strong>
                  {companyGroupsWithoutEconomySets.length} koncerner saknar
              kontoplan.
                </strong>{' '}
            Dessa koncerner kommer inte kunna visualisera ekonomiska nyckeltal.{' '}
                <Link
                  // eslint-disable-next-line max-len
                  onClick={() => setShowCompanyGroupsWithoutEconomySets(true)}
                >
              Tryck här för att se vilka koncerner som saknar kontoplan.
                </Link>{' '}
            Om du behöver hjälp kontakta supporten på{' '}
                <Link href={'mailto:support@homepal.se'}>support@homepal.se</Link>{' '}
            eller skriv ett meddelande i chatten.
              </Alert>
            ) : null}

        <List>
          {parsedEconomySets
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((set) => (
              <ListItem
                key={set.id}
                secondaryAction={
                  <>
                    <Button
                      onClick={() => setEconomySetsToEdit(set)}
                      variant="text"
                    >
                      Redigera
                    </Button>
                    {props.AuthStore.customer?.is_management_company ? (
                      <Button
                        onClick={() => setEconomySetsToDelete(set)}
                        variant="text"
                      >
                        Ta bort
                      </Button>
                    ) : null}
                  </>
                }
              >
                <Icon color="disabled" name="AccountTreeOutlined" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={set.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                  secondary={
                    props.AuthStore.customer?.is_management_company
                      ? `Används av ${set.companyGroups.length} ${
                        set.companyGroups.length === 1
                          ? 'koncern'
                          : 'koncerner'
                      }`
                      : undefined
                  }
                />
              </ListItem>
            ))}
        </List>
      </CollapsibleSection>
      <SimpleDialog
        title={'Radera kontoplan?'}
        open={Boolean(economySetToDelete)}
        onClose={() => setEconomySetsToDelete(null)}
        actions={
          <>
            <Button variant="text" onClick={() => setEconomySetsToDelete(null)}>
              Stäng
            </Button>
            <Button
              color="error"
              loading={deletingSet}
              onClick={() => {
                setDeletingSet(true)
                deleteEconomySet(
                  (economySetToDelete as ParsedEconomySet).id,
                  (err) => {
                    if (!err) {
                      setEconomySetsToDelete(null)
                    }

                    setDeletingSet(false)
                  }
                )
              }}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Kontoplanen <code>{economySetToDelete?.name}</code> kommer att
            raderas permanent.{' '}
            <b>
              Koncerner som använder kontoplanen kommer läggas under saknar
              kontoplan.
            </b>{' '}
            Är du säker?
          </>
        }
      />

      <AdvancedDialog
        actions={
          <Button
            variant="text"
            onClick={() => setShowCompanyGroupsWithoutEconomySets(false)}
          >
            Stäng
          </Button>
        }
        onClose={() => setShowCompanyGroupsWithoutEconomySets(false)}
        open={showCompanyGroupsWithoutEconomySets}
        title={'Bolag utan koncerntillhörighet'}
      >
        <List>
          {companyGroupsWithoutEconomySets
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => (
              <ListItem key={group.id}>
                <Icon color="disabled" name="Business" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={group.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
        </List>
      </AdvancedDialog>

      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseCreateEconomySets}>
            Stäng
          </Button>
        }
        onClose={handleCloseCreateEconomySets}
        open={createEconomySets}
        title={'Skapa en kontoplan'}
      >
        <Alert severity="info" icon={<Icon name="InfoOutlined" />}>
          Kontoplanen ska laddas in på csv format. Första kolumnen ska innehålla
          det 4-siffriga kontonumret och den andra kolumnen kontonamnet.
        </Alert>
        <TextField
          label="Kontoplan"
          required={true}
          autoComplete="off"
          value={newEconomySetsName}
          onChange={(event) => setNewEconomySetsName(event.target.value)}
          sx={{ mt: 2, mb: 2 }}
        />
        <UploadFile
          accept={['text/csv']}
          maxSize={2}
          uploadFile={(file) =>
            setNewEconomySetsFile({ file, name: file.name })
          }
        />
        {newEconomySetsFile ? (
          <ListItem component="div" sx={{ bgcolor: 'action.hover', mt: 1 }}>
            <ListItemAvatar>
              <Box
                sx={{
                  position: 'relative',
                  height: '48px',
                  width: '48px',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon name={'AttachmentOutlined'} />
              </Box>
            </ListItemAvatar>
            <ListItemText primary={newEconomySetsFile.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => setNewEconomySetsFile(null)}>
                <Icon name="DeleteOutline" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ) : null}
        <Button
          fullWidth
          disabled={
            !Boolean(newEconomySetsName) || !Boolean(newEconomySetsFile)
          }
          loading={creatingSet}
          variant="contained"
          size="large"
          sx={{ mt: 2 }}
          onClick={() => {
            setCreatingSet(true)
            createEconomySet(
              newEconomySetsName,
              newEconomySetsFile?.file as Blob,
              (err) => {
                if (!err) {
                  handleCloseCreateEconomySets()
                }

                setCreatingSet(false)
              }
            )
          }}
        >
          Skapa
        </Button>
      </SimpleDialog>
      <EditEconomySetsDialog
        AuthStore={props.AuthStore}
        economySet={economySetToEdit}
        companyGroupsWithoutEconomySets={companyGroupsWithoutEconomySets}
        createEconomyAccount={createEconomyAccount}
        deleteEconomyAccount={deleteEconomyAccount}
        open={Boolean(economySetToEdit)}
        onClose={() => setEconomySetsToEdit(null)}
        updateCompanyGroup={updateCompanyGroup}
        updateEconomyAccount={updateEconomyAccount}
        updateEconomySet={updateEconomySet}
        uploadEconomySet={uploadEconomySet}
      />
    </>
  )
}

export default EconomySetsTemplate
