import React from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Text from 'components_new/atoms/Text'

interface ShemEmailHintProps {
  open: boolean
}

const ShemEmailHint = (props: ShemEmailHintProps) => {
  const { open } = props

  return (
    <>
      <Collapse in={open} unmountOnExit={true}>
        <Alert
          severity={'info'}
          variant={'outlined'}
          sx={{
            mt: -1,
            mb: 2,
            borderTop: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }}
        >
          <Text
            fontSize="inherit"
            fontWeight="bold"
            color="inherit"
            gutterBottom={true}
          >
            Använd din email från <code>@stockholm.se</code>
          </Text>
          <Text fontSize="inherit" color="inherit" gutterBottom={true}>
            Du försöker logga in med en email från{' '}
            <code>@stockholmshem.se</code>. Stockholmshem använder emails på
            följande format för att logga in i Homepal:
          </Text>
          <Box sx={{ py: 1 }}>
            <Text color="inherit" component="span" variant="h6">
              xx12345@stockholm.se
            </Text>
          </Box>
        </Alert>
      </Collapse>
    </>
  )
}

ShemEmailHint.displayName = 'ShemEmailHint'

export default ShemEmailHint
