import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { KpiVariableOption } from 'types/GlobalCompanyGroups'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/kpi-variable-options`

export async function getAll() {
  return axios.get<{ data: KpiVariableOption[] }>(
    URL,
    parseAuthenticationHeader()
  )
}

export async function put(
  kpiVariableId: string,
  data: { data: { accounts: string[]; economy_set_id: string } }
) {
  const url = `${URL}/${kpiVariableId}`

  return axios.put<{ data: KpiVariableOption[] }>(
    url,
    data,
    parseAuthenticationHeader()
  )
}
