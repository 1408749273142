import React, { ReactNode } from 'react'
import { useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'

/**
 * Handles the outmost global layout.
 */

interface MainTemplateProps {
  children?: ReactNode
  signedIn?: boolean
}

const MainTemplate = (props: MainTemplateProps) => {
  const { children, signedIn } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        minHeight: 0,
        minWidth: signedIn ? theme.breakpoints.values.xs : 0
      }}
    >
      <Box
        component="main"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          minWidth: 0,
          height: '100vh',
          minHeight: 0
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default MainTemplate
