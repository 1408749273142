import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Category,
  DashboardGroup,
  DashboardType
} from 'redux/reducers/Dashboards'
import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import { DashboardStatus } from 'redux/reducers/DashboardTemplates'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardTemplateActions from 'redux/actions/DashboardTemplates'

import Button from 'components_new/atoms/Button'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

import SidebarDialog from 'components_new/molecules/SidebarDialog'

import DetailedView from './DetailedView'
import SortimentView from './SortimentView'
import ListSubheader from 'components_new/atoms/List/ListSubheader'
import List from 'components_new/atoms/List'
import SidebarNavItem from 'components_new/molecules/SidebarNavItem'
import { getCategoryArray } from './utils'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { translateCategory } from 'utils/enumTranslator'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Text from 'components_new/atoms/Text'
import Paper from 'components_new/atoms/Paper'
import LinearProgress from 'components_new/atoms/LinearProgress'
import SortimentItem from 'components_new/organisms/SortimentItem'

interface CreateDashboardDialogProps {
  isHomepal: boolean
  onClose: () => void
  open: boolean
  numberOfDashboardsInUse: number
  numberOfDashboardsInLicense: number | null
}

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  TemplateStore: state.TemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...DashboardTemplateActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
type ComponentProps = ConnectedProps<typeof connector> &
  CreateDashboardDialogProps

const CreateDashboardDialog = (props: ComponentProps) => {
  const {
    AuthStore,
    isHomepal,
    onClose,
    open,
    TemplateStore,
    tryCreateDashboard,
    tryOrderDashboardTemplate,
    numberOfDashboardsInUse,
    numberOfDashboardsInLicense
  } = props

  useEffect(() => {
    const { tryGetAllDashboardTemplates } = props

    if (!TemplateStore.fetched) {
      tryGetAllDashboardTemplates()
    }
  }, [])

  const [showTemplate, setShowTemplate] = useState<DashboardGroup | null>(null)

  const params = useParams<{ folderId?: string }>()
  const history = useHistory()

  const handleCreateDashboard = (templateId?: string) => {
    tryCreateDashboard(
      {
        data: {
          type: DashboardType.DEFAULT,
          template_id: templateId,
          folder_id: params.folderId
        }
      },
      (id) => {
        onClose()
        history.push(`/dashboards/${id}`)
      }
    )
  }

  const [tab, setTab] = useState<'ALL' | Category>('ALL')

  const templates = Object.values(TemplateStore.groups).filter(
    (item) => item.status === DashboardStatus.PUBLISHED
  )
  const categories = getCategoryArray(templates)

  const limitReached =
    numberOfDashboardsInLicense && !isHomepal
      ? numberOfDashboardsInUse >= numberOfDashboardsInLicense
      : false

  return (
    <>
      <SidebarDialog
        actions={
          <Button variant="text" onClick={onClose}>
            Stäng
          </Button>
        }
        fullHeight={true}
        maxWidth={false}
        onClose={() => {
          setShowTemplate(null)
          onClose()
        }}
        open={open}
        scrollToTop={Boolean(showTemplate)}
        leftSidebar={
          /** ----- SIDEBAR ----- */
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <List sx={{ flex: 1 }}>
              <ListSubheader
                component="div"
                disableSticky={false}
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: 'divider',
                  mb: 1,
                  mx: 2
                }}
              >
                Exempel
              </ListSubheader>
              <SidebarNavItem
                number={templates.length}
                onClick={() => setTab('ALL')}
                selected={tab === 'ALL'}
                sx={{ px: 5 }}
                title={'Alla'}
              />
              {categories
                .sort((a, b) =>
                  sortAlphabeticalAsc(
                    translateCategory[a].title,
                    translateCategory[b].title
                  )
                )
                .map((category, i) => {
                  return (
                    <SidebarNavItem
                      key={i}
                      number={
                        templates.filter(
                          (template) => template.category.selected === category
                        ).length
                      }
                      onClick={() => {
                        setShowTemplate(null)
                        setTab(category)
                      }}
                      selected={tab === category}
                      sx={{ px: 5 }}
                      title={translateCategory[category].title}
                    />
                  )
                })}
            </List>
            {numberOfDashboardsInLicense ? (
              <ThemeProvider theme={getTheme('dark')}>
                <Box sx={{ p: 2 }}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        color: 'text.primary',
                        alignItems: 'center'
                      }}
                    >
                      <Icon color="inherit" name="PrivacyTip" />
                      <Text color="text.primary">
                        Antal dashboards i din organisations licens:
                      </Text>
                    </Box>
                    <LinearProgress
                      color={
                        numberOfDashboardsInUse > numberOfDashboardsInLicense
                          ? 'error'
                          : numberOfDashboardsInUse ===
                            numberOfDashboardsInLicense
                            ? 'warning'
                            : 'success'
                      }
                      customLabel={`${numberOfDashboardsInUse} av ${numberOfDashboardsInLicense}`}
                      label={true}
                      sx={{ height: 12, borderRadius: 6 }}
                      value={
                        (numberOfDashboardsInUse /
                          numberOfDashboardsInLicense) *
                        100
                      }
                      variant="determinate"
                    />
                  </Paper>
                </Box>
              </ThemeProvider>
            ) : null}
          </Box>
        }
        title={'Lägg till dashboard'}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: 2
          }}
        >
          {showTemplate ? (
            <DetailedView
              onClose={() => setShowTemplate(null)}
              createDashboard={handleCreateDashboard}
              isHomepal={isHomepal}
              limitReached={limitReached}
              onOrder={(id: string) => {
                tryOrderDashboardTemplate(id, AuthStore.user?.email)
              }}
              template={showTemplate}
            />
          ) : (
            <SortimentView
              createDashboard={handleCreateDashboard}
              isHomepal={isHomepal}
              limitReached={limitReached}
              onOrder={(id: string) => {
                tryOrderDashboardTemplate(id, AuthStore.user?.email)
              }}
              setTemplate={setShowTemplate}
              templates={templates
                .filter(
                  tab === 'ALL' ? (a) => a : (a) => a.category.selected === tab
                )
                .sort((a, b) => (a.title > b.title ? 1 : -1))}
            />
          )}
          <Box sx={{ mt: 4 }}>
            <SortimentItem
              category={null}
              custom={true}
              isHomepal={isHomepal}
              onCreate={() => {}}
              onOrder={() =>
                tryOrderDashboardTemplate(
                  'Anpassad dashboard',
                  AuthStore.user?.email
                )
              }
              onShow={() => {}}
              src={'/'}
              title={'Saknar du en dashboard inom ett visst område?'}
              body={
                'Skicka en förfrågan till oss så tar vi ett helt kostnadsfritt samtal och ser vad vi kan göra.'
              }
            />
          </Box>
        </Box>
      </SidebarDialog>
    </>
  )
}

export default connector(CreateDashboardDialog)
