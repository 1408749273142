import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'

import { useResizeDetector } from 'react-resize-detector'

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl, {
  GeoJSONSource,
  MapboxGeoJSONFeature,
  Marker
} from 'mapbox-gl'
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Tooltip from 'components_new/atoms/Tooltip'

import { IconNames } from 'components_new/atoms/Icon'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  TViewport
} from 'types/GlobalWidget'

import { useTheme } from '@mui/material'
import { getColorMode } from 'themes'
import './index.css'

import { TFeature, getFeatureByIndex, mapBetaTooltip, styleLink } from './utils'

import Cluster from './Cluster'
import Single from './Single'

import MissingChip from './MissingChip'
import MissingDialog from './MissingDialog'

interface MapProps {
  formattedData: FormattedWidgetData
  isComparativeButIllegal: boolean
  onClickSegment: {
    title: (segments: ParsedSegmentPath[], link?: string | null) => string
    onClick: (segments: ParsedSegmentPath[], link?: string | null) => void
    iconName: IconNames
  }[]
  scaleFactor: number
  setViewport: (value: TViewport) => void
  viewport: TViewport
}

mapboxgl.accessToken =
  'pk.eyJ1IjoiYXhlbGhvbWVwYWwiLCJhIjoiY2x0c2c2cjF4MHJ1MTJucDVqYTBhbmN4eSJ9.u2GogCjxUECII466PFmOug'

const Map = (props: MapProps) => {
  const {
    formattedData,
    // isComparativeButIllegal,
    onClickSegment,
    // scaleFactor,
    setViewport,
    viewport
  } = props

  const theme = useTheme()

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const mapContainer = useRef(null)
  const [map, setMap] = useState<mapboxgl.Map>()

  // const [style, setStyle] = useState<TStyle>('street')
  const style = 'street'
  const mode = useMemo(() => {
    return getColorMode(
      theme.palette.background.widget ?? theme.palette.background.paper
    )
  }, [theme.palette.background.widget])

  // format data
  const [
    geojson,
    increaseIsPositives,
    kpis,
    noCoordinatesFeatures,
    numberOfDecimals,
    units
  ] = useMemo(() => {
    const tempCoordinatesFeatures: TFeature[] = []
    const tempNoCoordinatesFeatures: TFeature[] = []

    formattedData.labels.forEach((_, i) => {
      if (formattedData.coordinates[i][0] && formattedData.coordinates[i][1]) {
        tempCoordinatesFeatures.push(getFeatureByIndex(formattedData, i))
      } else {
        tempNoCoordinatesFeatures.push(getFeatureByIndex(formattedData, i))
      }
    })

    const tempGeojson: FeatureCollection<Geometry> = {
      type: 'FeatureCollection',
      features: tempCoordinatesFeatures
    }

    const tempIncreaseIsPositives: boolean[] = formattedData.datasets.map(
      (dataset) => dataset.increaseIsPositive
    )

    const tempKpis: string[] = formattedData.datasets.map(
      (dataset) => dataset.label
    )

    const tempNumberOfDecimals: number[] = formattedData.datasets.map(
      (dataset) => dataset.numberOfDecimals
    )

    const tempUnits: (string | null)[] = formattedData.datasets.map(
      (dataset) => dataset.unit
    )

    return [
      tempGeojson,
      tempIncreaseIsPositives,
      tempKpis,
      tempNoCoordinatesFeatures,
      tempNumberOfDecimals,
      tempUnits
    ]
  }, [formattedData])

  // caching and keeping track of custom markers
  // (object for performance)
  const markers: { [key: string]: any } = {}
  let markersOnScreen: { [key: string]: any } = {}

  const updateMarkers = () => {
    // return if component is rendered on server
    if (
      typeof window === 'undefined' ||
      !mapContainer.current ||
      !map ||
      !map.isSourceLoaded('markers')
    )
      return

    const newMarkers: { [key: string]: any } = {}
    const sourceFeatures: MapboxGeoJSONFeature[] =
      map.querySourceFeatures('markers')

    for (const feature of sourceFeatures) {
      let coords: [number, number] = [0, 0] // longitude, latitude

      if (feature.geometry.type === 'Point') {
        coords = feature.geometry.coordinates as [number, number]
      }
      const props = feature.properties as GeoJsonProperties

      if (!props) continue

      // if marker is a cluster
      if (props.cluster) {
        const id = props.cluster_id ?? 0
        let marker = markers[id]

        if (!marker) {
          const el = document.createElement('div')
          const root = createRoot(el)

          root.render(
            <Cluster
              theme={theme}
              count={props.point_count ?? 0}
              kpis={kpis}
              sums={[
                Number(props.sum_0),
                Number(props.sum_1),
                Number(props.sum_2),
                Number(props.sum_3),
                Number(props.sum_4),
                Number(props.sum_5),
                Number(props.sum_6),
                Number(props.sum_7),
                Number(props.sum_8),
                Number(props.sum_9)
              ]}
              units={units}
              onClick={() => {
                const markersSource = map.getSource('markers') as GeoJSONSource

                if (markersSource) {
                  markersSource.getClusterExpansionZoom(
                    id,
                    (error: any, zoom: number) => {
                      if (error) return

                      map.easeTo({
                        center: coords,
                        zoom: zoom + 0.01 // zoom slightly past cluster
                      })
                    }
                  )
                }
              }}
            />
          )

          marker = markers[id] = new Marker(el).setLngLat(coords)
        }

        newMarkers[id] = marker

        if (!markersOnScreen[id]) marker.addTo(map)
      } else {
        // if marker is a single
        const id = props.title
        let marker = markers[id]

        if (!marker) {
          const el = document.createElement('div')
          const root = createRoot(el)

          root.render(
            <Single
              // JSON.parse due to no array support in
              // GeoJsonFeature's props.
              differences={JSON.parse(props.differences)}
              increaseIsPositives={increaseIsPositives}
              kpis={kpis}
              menuOptions={onClickSegment}
              percentages={JSON.parse(props.percentages)}
              theme={theme}
              label={JSON.parse(props.label)}
              units={units}
              values={JSON.parse(props.values)}
            />
          )

          marker = markers[id] = new mapboxgl.Marker(el).setLngLat(coords)
        }

        newMarkers[id] = marker

        if (!markersOnScreen[id]) marker.addTo(map)
      }
    }

    for (const id in markersOnScreen) {
      if (!newMarkers[id]) {
        markersOnScreen[id].remove()
      }
    }

    markersOnScreen = newMarkers
  }

  // initialize map
  useEffect(() => {
    // return if component is rendered on server
    if (typeof window === 'undefined' || !mapContainer.current) return

    // create a new map instance
    const initMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: styleLink[mode][style],
      center: { lng: viewport.longitude, lat: viewport.latitude },
      pitch: viewport.pitch,
      bearing: 0,
      zoom: viewport.zoom,
      maxZoom: 20
    })

    initMap.on('load', () => {
      // add source data
      initMap.addSource('markers', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterRadius: 80,
        clusterProperties: {
          sum_0: ['+', ['get', 'value_0']],
          sum_1: ['+', ['get', 'value_1']],
          sum_2: ['+', ['get', 'value_2']],
          sum_3: ['+', ['get', 'value_3']],
          sum_4: ['+', ['get', 'value_4']],
          sum_5: ['+', ['get', 'value_5']],
          sum_6: ['+', ['get', 'value_6']],
          sum_7: ['+', ['get', 'value_7']],
          sum_8: ['+', ['get', 'value_8']],
          sum_9: ['+', ['get', 'value_9']]
        }
      })

      // add markers
      initMap.addLayer({
        id: 'marker',
        type: 'symbol',
        source: 'markers',
        filter: ['!=', 'cluster', true]
      })

      // add controls
      initMap.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
        'top-left'
      )

      // disable rotation
      initMap.dragRotate.disable()

      // store viewport
      initMap.on('move', () => {
        setViewport({
          longitude: initMap.getCenter().lng,
          latitude: initMap.getCenter().lat,
          pitch: viewport.pitch,
          zoom: initMap.getZoom()
        })
      })

      // update markers
      updateMarkers()
    })

    setMap(initMap)

    return () => initMap.remove()
  }, [])

  // render markers
  useEffect(() => {
    if (typeof window === 'undefined' || !mapContainer.current || !map) return

    // update markers on every frame
    map.on('render', () => {
      if (!map.isSourceLoaded('markers')) return
      updateMarkers()
    })
  }, [Boolean(map)])

  // Done by fake loading in WidgetContent during beta:
  //
  // update map data when source data has changed
  // useEffect(() => {
  //   if (!map || !map.isSourceLoaded('markers')) return
  //   updateMarkers()
  // }, [geojson])

  // trigger a window resize on map container resize
  const onResize = useCallback(() => {
    window.dispatchEvent(new Event('resize'))
  }, [])

  const { ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize
  })

  // render map
  return (
    <>
      <Box
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
        onMouseDown={(event) => {
          event.stopPropagation()
        }}
        ref={ref}
        sx={{
          position: 'relative',
          flexGrow: 1,
          minHeight: 0
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            p: '10px',
            zIndex: 2
          }}
        >
          <Tooltip title={mapBetaTooltip}>
            <Chip color="info" label="Beta" />
          </Tooltip>
        </Box>
        {/* <SelectStyle onChange={(e, value) => 
        setStyle(value)} value={style} /> */}
        <Box
          ref={mapContainer}
          sx={{
            width: '100%',
            height: '100%',
            '& .mapboxgl-ctrl-logo': { display: 'none' }
          }}
        />
        {noCoordinatesFeatures.length > 0 ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              p: '10px',
              zIndex: 2
            }}
          >
            <MissingChip
              noCoordinatesFeatures={noCoordinatesFeatures.length}
              onClick={() => setDialogOpen(true)}
              totalFeatures={geojson.features.length}
            />
          </Box>
        ) : null}
      </Box>

      {/*-- dialog --*/}
      <MissingDialog
        features={noCoordinatesFeatures}
        increaseIsPositives={increaseIsPositives}
        kpis={kpis}
        numberOfDecimals={numberOfDecimals}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        units={units}
      />
    </>
  )
}

export default Map
