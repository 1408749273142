import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: [],
  count: 0,
  fetching: false,
  currentTableId: null,
  fetched: false,
  error: false,
  offset: 0,
  limit: 10
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_LOGS:
    return {
      ...state,
      currentTableId: payload.id,
      fetching: true,
      fetched: false,
      error: false
    }

  case Types.GET_LOGS_SUCCESS:
    return {
      ...state,
      data: payload.data.data,
      count: payload.data.meta.count,
      fetching: false,
      fetched: true,
      error: false,
      limit: payload.limit,
      offset: payload.offset
    }

  case Types.GET_LOGS_FAILED:
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: true
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
