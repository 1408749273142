import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/connections/active-sources`

export async function create(body) {
  const options = Functions.parseAuthenticationHeader()

  options.contentType = 'multipart/form-data'

  return Axios.post(URL, body, options)
}

export async function update(id, body) {
  const url = `${URL}/${id}`

  return Axios.patch(url, body, Functions.parseAuthenticationHeader())
}

export async function remove(id) {
  const url = `${URL}/${id}`

  return Axios.delete(url, Functions.parseAuthenticationHeader())
}

export async function upload(id, body) {
  const url = `${URL}/${id}/upload`

  const options = Functions.parseAuthenticationHeader()

  options.contentType = 'multipart/form-data'

  return Axios.post(url, body, options)
}

export async function resetPassword(id, body) {
  const url = `${URL}/${id}/reset-password`

  return Axios.patch(url, body, Functions.parseAuthenticationHeader())
}

export async function activate(id) {
  const url = `${URL}/${id}/activate`

  return Axios.post(url, {}, Functions.parseAuthenticationHeader())
}

export async function createSFTPSettings(id) {
  const url = `${URL}/${id}/create-sftp-settings`

  return Axios.post(url, {}, Functions.parseAuthenticationHeader())
}

export async function scan(id, body) {
  const url = `${URL}/${id}/scan`

  return Axios.post(url, body, Functions.parseAuthenticationHeader())
}
