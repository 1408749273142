import React from 'react'

import Alert from 'components_new/atoms/Alert'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import List from 'components_new/atoms/List'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

const DashboardView = () => {
  return (
    <>
      <List dense={true}>
        <ListItem>
          <ListItemIcon>
            <Icon name="DashboardCustomizeOutlined" />
          </ListItemIcon>
          <ListItemText
            primary="Anpassade dashboards"
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondary="Det är du och din organisation som väljer vad era dashboards ska
              innehålla. Sätt upp det själv enkelt i vårt gränssnitt eller be någon från Homepals team om hjälp."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon name="CloudSyncOutlined" />
          </ListItemIcon>
          <ListItemText
            primary="Alltid uppdaterad data"
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondary={
              <>
                De dashboards som finns i Homepal synkroniserar data från
                anslutna system <u>minst</u> en gång per dag för att möjliggöra
                tillgång till dagsfärsk information.
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Icon name="GroupAddOutlined" />
          </ListItemIcon>
          <ListItemText
            primary="Dela med kollegor"
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondary="Sprid kunskap och insikt vidare i organisationen genom att bjuda in kollegor till dashboards (eller skicka publika länkar för åtkomst utan inloggning)."
          />
        </ListItem>
      </List>
      <Divider sx={{ my: 2 }} />
      <Alert severity="info">
        <Text color="inherit" fontSize="inherit" gutterBottom={true}>
          En <b>*dashboard</b> är en dynamisk vy där du (eller din organisation)
          kan samla och visualisera intressant data på olika vis.
        </Text>
        <Text color="inherit" fontSize="inherit">
          Ofta handlar det om att följa upp olika mätvärden och nyckeltal på ett
          enkelt sätt, men det kan även röra sig om att komma åt data som annars
          är svårtåtkomlig i befintliga system.
        </Text>
      </Alert>
    </>
  )
}

export default DashboardView
