import React, { useState } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import { getData, getOptions } from './utils'

import { translateWidgetType } from 'utils/enumTranslator'

import { EXPLORE_DISABLED_TOOLTIP, getExploreLabel } from 'utils/texts'

import NoValue from '../../errors/NoValue'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetType
} from 'types/GlobalWidget'

interface PieChartProps {
  formattedData: FormattedWidgetData
  onClick: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  onClickValue: {
    [kpiOptionId: string]:
      | ((
          segmentLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  }
  scaleFactor: number
}

const PieChart = (props: PieChartProps) => {
  const { formattedData, onClick, onClickValue, scaleFactor } = props

  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegmentLabel, setSelectedSegmentLabel] =
    useState<ParsedWidgetDataLabel | null>(null)

  const open = Boolean(anchorPosition)
  const allowOnClick = onClick.length > 0

  const theme = useTheme()

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BAR_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const handleClick = (
    event: PointerEvent,
    dataLabel: ParsedWidgetDataLabel
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegmentLabel(dataLabel)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegmentLabel(null)
  }

  const data = getData(theme, formattedData)
  const kpiOptionId = formattedData.datasets[0].kpiOptionId

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          type="pie"
          data={data}
          options={getOptions(
            allowOnClick,
            data,
            handleClick,
            theme,
            scaleFactor
          )}
          plugins={[ChartDataLabels]}
        />
      </Box>
      {allowOnClick && selectedSegmentLabel && (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            anchorPosition
              ? { top: anchorPosition.top, left: anchorPosition.left }
              : undefined
          }
          open={open}
          onClose={handleClose}
          disableScrollLock
        >
          {onClick.map((option, i) => {
            const parsedSegmentPath = {
              ...selectedSegmentLabel,
              attribute_option_id:
                selectedSegmentLabel.attribute_option_id as string
            }

            return (
              <MenuItem
                key={i}
                onClick={(event) => {
                  handleClose(event)

                  option.onClick([parsedSegmentPath])
                }}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <ListItemIcon>
                  <Icon name={option.iconName} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={option.title([parsedSegmentPath])} />
              </MenuItem>
            )
          })}
          {kpiOptionId ? (
            <Tooltip
              title={
                !onClickValue[kpiOptionId] ? EXPLORE_DISABLED_TOOLTIP : null
              }
            >
              <Box>
                <MenuItem
                  disabled={!onClickValue[kpiOptionId]}
                  onClick={(event) => {
                    handleClose(event)
                    const func = onClickValue[kpiOptionId as string]

                    if (func) {
                      func(selectedSegmentLabel, [], null)
                    }
                  }}
                  onMouseDown={(event) => event.stopPropagation()}
                >
                  <ListItemIcon>
                    <Icon name={'SearchOutlined'} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={getExploreLabel(
                      selectedSegmentLabel.display_label
                    )}
                  />
                  <Chip
                    label={'Beta'}
                    color={'info'}
                    size={'small'}
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              </Box>
            </Tooltip>
          ) : null}
        </Menu>
      )}
    </>
  )
}

export default PieChart
