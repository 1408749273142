import * as Constants from 'helpers/Constants'

export const parseColumns = (columns) =>
  columns
    .map((item, index) => ({
      id: item.id,
      regex: Constants.BUILD_FORMATS[item.attributes.type].regex,
      typeLabel: Constants.BUILD_FORMATS[item.attributes.type].label,
      input: Constants.BUILD_FORMATS[item.attributes.type].input,
      attributes: {
        key: index,
        label: item.attributes.label,
        column_name: item.attributes.column_name,
        column_index:
          item.attributes.column_index === null
            ? index
            : item.attributes.column_index
      }
    }))
    .sort((c1, c2) =>
      c1.attributes.column_index > c2.attributes.column_index ? 1 : -1
    )

export const save = (rows, cols, columns, saveRegister) => {
  // remove temporary id for created rows
  const rowPuts = [...rows.created, ...rows.updated]

  rowPuts.forEach((row) => (row.created ? delete row.id : null))

  // remove temporary id for created columns
  const colPuts = [...cols.created, ...cols.updated]

  colPuts.forEach((col) => (col.created ? delete col.id : null))

  const data = {
    data: {
      rows: {
        deleted: rows.deleted,
        puts: rowPuts
      },
      columns: {
        deleted: cols.deleted,
        puts: colPuts
      }
    }
  }

  saveRegister(data)
}

export const sortColumns = (cols) => {
  return cols.sort((c1, c2) =>
    c1.attributes.column_index > c2.attributes.column_index ? 1 : -1
  )
}
