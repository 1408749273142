/* eslint-disable max-len */
export const DETAILS = {
  settings: {
    title: 'Inställningar',
    description: null
  },
  catalog: {
    title: 'Katalog',
    description: null
  },
  // products
  build: {
    title: 'Build',
    description:
      'Build låter dig skapa och lagra egen data som existerar utanför dina system.'
  },
  collect: {
    title: 'Collect',
    description:
      'Anslut era interna system och låt Collect samla in önskad data till ert datalager.'
  },
  shape: {
    title: 'Shape',
    description:
      'Shape användas till att modellera och forma tillgänglig data i ditt lager. Shape gör datan till information som går att använda i analys eller applikation.'
  },
  flow: {
    title: 'Flow',
    description:
      'Flow låter dig förmedla vidare och leverera strukturerad live-data och information till tredjeparter.'
  },
  cast: {
    title: 'Cast',
    description:
      'Cast låter dig skapa destinationer till analysverktyg för att skicka din modellerade data. På så sätt kan du visualisera information för analys i tredjepartslösningar.'
  }
}
