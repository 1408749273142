import React from 'react'

import { Checkbox } from 'antd'

import DropdownButton from 'components/common/DropdownButton'
import ProgressBar from 'components/common/ProgressBar'
import QualityHover from 'components/common/ProgressBar/QualityHover'
import Cascader from 'components/common/Cascader'

import Box from 'components_new/atoms/Box'
import OverflowText from 'components_new/molecules/OverflowText'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import * as Functions from 'helpers/Functions'

import Styles from './styles.module.css'

const updateData = (setData, id, updateData) => {
  setData((data) => ({
    ...data,
    [id]: {
      ...data[id],
      ...updateData
    }
  }))
}

const parseFormatOptions = (formats) => {
  const options = {}

  formats.forEach((format) => {
    if (format.attributes.group in options) {
      options[format.attributes.group].children.push({
        value: format.id,
        label: format.attributes.name
      })
    } else {
      options[format.attributes.group] = {
        value: format.attributes.group,
        label: format.attributes.group,
        children: [{ value: format.id, label: format.attributes.name }]
      }
    }
  })

  return Object.values(options)
}

export const TABLE_HEADERS = (
  editMode,
  setData,
  tableId,
  history,
  formats,
  formatObj,
  datasetValidCounts
) => [
  {
    label: 'ATTRIBUT',
    key: 'attribute',
    render: function renderColumn(attribute) {
      return (
        <Box sx={{ maxWidth: '20vw' }}>
          <OverflowText variant="body1">{attribute}</OverflowText>
        </Box>
      )
    }
  },
  {
    label: 'KVALITET',
    key: 'quality',
    render: function renderColumn(id, row) {
      const hasRule = row.exists || row.unique || row.format

      const { hasCount, totalValids, total, percent } =
        Functions.getTotalValidityCount(datasetValidCounts, tableId, id)

      return (
        hasRule && (
          <Box
            sx={{
              width: '64px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0.25,
              p: 0.5
            }}
          >
            <Text variant="body2">{hasCount ? `${percent}%` : '-'}</Text>
            <ProgressBar
              size={'medium'}
              percent={percent}
              hideText
              leftoverColor={hasCount ? 'var(--red5)' : 'var(--grey3)'}
              hoverBackground={'var(--grey10)'}
              hoverContent={
                <QualityHover
                  valid={totalValids}
                  total={total}
                  hasCount={hasCount}
                />
              }
              hideHoverTitle
            />
          </Box>
        )
      )
    }
  },
  {
    label: 'FINNS',
    key: 'exists',
    render: function renderColumn(exists, row) {
      return (
        <Checkbox
          onChange={(val) => {
            updateData(setData, row.id, { exists: val.target.checked })
          }}
          checked={exists}
          disabled={!editMode}
        />
      )
    }
  },
  {
    label: 'UNIKT',
    key: 'unique',
    render: function renderColumn(unique, row) {
      return (
        <Checkbox
          onChange={(val) => {
            updateData(setData, row.id, { unique: val.target.checked })
          }}
          checked={unique}
          disabled={!editMode}
        />
      )
    }
  },
  {
    label: 'FORMAT',
    key: 'format',
    render: function renderColumn(format, row) {
      return (
        <Cascader
          options={parseFormatOptions(formats)}
          disabled={!editMode}
          size={'middle'}
          value={format}
          ignoreLockedMode
          onlyShowLastValue
          onChange={(val) => {
            updateData(setData, row.id, {
              format: val
            })
          }}
        >
          {format ? (
            <Box sx={{ p: 1 }}>
              <Text variant="caption">Exempel</Text>
              <Paper variant="outlined" sx={{ p: 1 }}>
                <Text color="text.secondary" variant="body1">
                  {formatObj[format[1]].attributes.example}
                </Text>
              </Paper>
            </Box>
          ) : null}
        </Cascader>
      )
    }
  },
  {
    title: 'Action',
    fixed: 'right',
    key: 'operation',
    width: 100,
    render: function renderColumn(_, row) {
      return (
        <DropdownButton
          icon={'MoreHorizOutlinedIcon'}
          iconClassName={Styles['ellipsis-icon']}
          items={[
            {
              key: `${row.id}-dropdown`,
              items: [
                {
                  label: 'Se alla brister',
                  onClick: () =>
                    history.push(
                      `/data-platform/ontology/datasets/${tableId}/flaws?attribute=${row.id}`
                    )
                }
              ]
            }
          ]}
          header={false}
        />
      )
    }
  }
]

export const formatPut = (data, tableId) => {
  const rows = Object.values(data)

  const body = {
    data: {
      attributes: {
        table_id: tableId,
        columns: []
      }
    }
  }

  rows.forEach((row) => {
    if (row.exists) {
      body.data.attributes.columns.push({
        type: 'EXISTS',
        attribute_id: row.id
      })
    }

    if (row.unique) {
      body.data.attributes.columns.push({
        type: 'UNIQUE',
        attribute_id: row.id
      })
    }

    if (row.format) {
      body.data.attributes.columns.push({
        type: 'FORMAT',
        attribute_id: row.id,
        format_id: row.format[1]
      })
    }
  })

  return body
}

export const getInitialRows = (attributes, rules, formats) => {
  const data = {}

  attributes.forEach((attr) => {
    let exists = false
    let unique = false
    let format = null

    rules.forEach((req) => {
      if (req.attributes.attribute_id === attr.id) {
        if (req.attributes.type === 'EXISTS') {
          exists = true
        } else if (req.attributes.type === 'UNIQUE') {
          unique = true
        } else if (req.attributes.type === 'FORMAT') {
          const foundFormat = formats.find(
            (f) => f.id === req.attributes.format_id
          )

          format = foundFormat
            ? [foundFormat.attributes.group, foundFormat.id]
            : null
        }
      }
    })

    data[attr.id] = {
      id: attr.id,
      key: attr.id,
      exists: exists,
      unique: unique,
      format,
      attribute: attr.attributes.name,
      quality: attr.id
    }
  })

  return data
}
