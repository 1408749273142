import { Theme } from '@mui/material'
import { ChartOptions, ChartEvent, ActiveElement } from 'chart.js'
import { getLegendStyles, getTooltipLabel } from '../utils'
import {
  DefaultDatasetData,
  FormattedWidgetData,
  ParsedWidgetDataLabel
} from 'types/GlobalWidget'
import { getColorPieChart } from 'utils/quickFixes'
import { getTheme, rgbaStringToRgbaObject } from 'themes'

type PieChartOptions = ChartOptions<'pie'>

export const getLabelColor = (
  value: number | null,
  index: number,
  theme: Theme,
  labels: ParsedWidgetDataLabel[]
) => {
  const backgroundColor =
    getColorPieChart(value, index, theme, labels) || '#000'

  const rgb = rgbaStringToRgbaObject(backgroundColor)

  const black = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

  if (black) {
    return getTheme('light').palette.text.primary
  }

  return getTheme('dark').palette.text.primary
}

export const getOptions = (
  allowOnClick: boolean,
  data: {
    labels: string[]
    datasets: {
      label: string
      data: (string | number | null)[]
      theme: Theme
    }[]
    dataLabels: ParsedWidgetDataLabel[]
  },
  handleClick: (event: PointerEvent, dataLabel: ParsedWidgetDataLabel) => void,
  theme: Theme,
  scaleFactor: number
): PieChartOptions => {
  return {
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor =
        elements.length === 1 && allowOnClick ? 'pointer' : 'default'
    },
    onClick: (event, elements) => {
      if (allowOnClick && elements.length > 0) {
        handleClick(
          event.native as PointerEvent,
          data.dataLabels[elements[0].index]
        )
      }
    },
    plugins: {
      legend: {
        ...getLegendStyles(scaleFactor, theme, 'pie')
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (item) => item[0]?.label,
          label: (item: any) => getTooltipLabel(item, false)
        }
      },
      datalabels: {
        color: function (context) {
          return (
            getLabelColor(null, context.dataIndex, theme, data.dataLabels) ||
            '#fff'
          )
        },
        backgroundColor: function (context) {
          return (
            getColorPieChart(null, context.dataIndex, theme, data.dataLabels) ||
            'rgba(255, 255, 255, 0.26)'
          )
        },
        borderRadius: 4 * scaleFactor,
        padding: {
          top: 6 * scaleFactor,
          bottom: 4 * scaleFactor,
          left: 8 * scaleFactor,
          right: 8 * scaleFactor
        },
        font: function () {
          return {
            weight: 'bold',
            size: 16 * scaleFactor
          }
        },
        clip: true,
        formatter: function (value, context: any) {
          const dataIndex: number = context.dataIndex
          const unit: string = context.dataset.unit
            ? ` ${context.dataset.unit}`
            : ''
          const prettyData: string = context.dataset.prettyData[dataIndex]

          return `${prettyData}${unit}`
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  }
}

export const getData = (
  theme: Theme,
  formattedData: FormattedWidgetData
): {
  labels: string[]
  datasets: {
    label: string
    data: (string | number | null)[]
    theme: Theme
  }[]
  dataLabels: ParsedWidgetDataLabel[]
} => {
  return {
    labels: formattedData.labels.map((item) => item.display_label),
    datasets: formattedData.datasets.map((item) => {
      return {
        backgroundColor: (item.data as DefaultDatasetData).map((value, index) =>
          getColorPieChart(value, index, theme, formattedData.labels)
        ),
        borderColor: theme.palette.background.widget,
        data: item.data as DefaultDatasetData,
        label: item.label,
        prettyData: item.prettyData,
        theme,
        unit: item.unit
      }
    }),
    dataLabels: formattedData.labels
  }
}
