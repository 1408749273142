import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form, Checkbox } from 'antd'
import _ from 'lodash'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import Input from 'components/common/Input'
import Modal from 'components/common/Modal'
import RadioButton from 'components/common/RadioButton'
import Select from 'components/common/Select'
import Table from 'components/common/Table'

import * as OutputAttributeActions from 'redux/actions/DataPlatform/shapes/v2/OutputAttributes'
import * as AlertActions from 'redux/actions/Alert'

import * as Constants from 'helpers/Constants'
import { awsFriendlyName, usePrevious } from 'helpers/Functions'
import { setChangedOntology } from 'helpers/Storage/Ontology'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const EMPTY_ATTRIBUTE = {
  id: undefined,
  name: undefined,
  real_name: undefined,
  type: undefined,
  is_primary: false,
  is_reference: false,
  manual_input: false
}

const Schema = ({
  match,
  DatasetStore,
  AuthStore,
  tryDeleteOutputAttribute,
  tryCreateOutputAttribute,
  tryUpdateOutputAttribute,
  setAlert,
  resetAlert,
  DataProductStore
}) => {
  const [showAttributeModal, setShowAttributeModal] = useState(false)
  const [attribute, setAttribute] = useState(EMPTY_ATTRIBUTE)
  const [forbiddenModal, setForbiddenModal] = useState(false)
  const prevWorking = usePrevious(DatasetStore.working)
  const dataset = DatasetStore.data[match.params.tableId]

  const isStandardTable =
    DataProductStore.standardOntology?.id === dataset.attributes.data_product_id

  // If non Homepal user datasets can not be editted.
  const hideEdit = !AuthStore.user.is_homepal_user || isStandardTable

  const onDelete = (row) =>
    setAlert({
      isOpen: true,
      header: 'Bekräfta borttagning',
      okText: 'Ta bort',
      cancelText: 'Avbryt',
      contentHeader: 'Ta bort attribut',
      faIcon: 'faTrash',
      onOk: () => {
        tryDeleteOutputAttribute(row.id)
        resetAlert()
      },
      content: (
        <Text variant="body1">
          Du kommer att förlora ditt attribut <b>{row.name}</b>.
          <br />
          Tänk på att detta kan påverka de modeller som använder datasetet.
        </Text>
      )
    })

  const onSave = (values) => {
    const data = {
      attributes: {
        ...values,
        table_id: dataset.id
      }
    }

    if (attribute.id) {
      tryUpdateOutputAttribute(attribute.id, { data })
    } else {
      tryCreateOutputAttribute({ data })
    }

    if (dataset.attributes.is_ontology) {
      setChangedOntology()
    }
  }

  const onEdit = (row) => {
    setShowAttributeModal(true)
    setAttribute(row)
  }

  const rows = Conf.getAttributes(dataset)
  const headers = Conf.getHeaders(onDelete, onEdit, hideEdit)

  useEffect(() => {
    if (!DatasetStore.working && prevWorking && DatasetStore.error) {
      setForbiddenModal(true)
    }
  }, [DatasetStore.working])

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2 }}>
        {hideEdit ? null : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={() => {
                setAttribute(EMPTY_ATTRIBUTE)
                setShowAttributeModal(true)
              }}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till attribut
            </Button>
          </Box>
        )}
        <Table
          className={Styles.table}
          rows={rows}
          headers={headers}
          parsedFormat
          scroll={{ x: 200 }}
          ellipsis={false}
          pagination={{ position: 'bottomRight', pageSize: 8 }}
        />
        <AttributeModal
          onSave={onSave}
          onClose={() => {
            setShowAttributeModal(false)
            setAttribute(EMPTY_ATTRIBUTE)
          }}
          visible={showAttributeModal}
          outputAttributes={rows}
          attribute={attribute}
          hasPrimaryKey={rows.find((r) => r.is_primary)}
          setAlert={setAlert}
          resetAlert={resetAlert}
          isLinkTable={dataset.attributes.is_link_table}
        />
        <ForbiddenModal
          visible={forbiddenModal}
          onClose={() => setForbiddenModal(false)}
          DatasetStore={DatasetStore}
        />
      </Paper>
    </Box>
  )
}

const AttributeModal = ({
  onClose,
  onSave,
  visible,
  outputAttributes,
  attribute,
  hasPrimaryKey,
  setAlert,
  resetAlert,
  isLinkTable
}) => {
  const [form] = Form.useForm()

  if (visible && !_.isEqual(form.getFieldsValue(), attribute)) {
    form.setFieldsValue(attribute)
  }

  const header = attribute.id ? 'Inställningar' : 'Lägg till attribut'
  const save = attribute.id ? 'Spara' : 'Lägg till'
  const locked = !!attribute.id

  return (
    <Modal
      visible={visible}
      header={header}
      onClose={onClose}
      actions={
        <Box sx={{ display: 'flex', gap: 1, p: 1 }}>
          <Button
            onClick={() => {
              onClose()
              form.resetFields()
            }}
            variant="text"
          >
            Avbryt
          </Button>
          <Button
            onClick={() => {
              const data = form.getFieldsValue()

              if (data.manual_input && !hasPrimaryKey && !isLinkTable) {
                // Entity has no key
                setAlert({
                  isOpen: true,
                  header: 'Entiteten saknar nyckel',
                  okText: 'OK',
                  cancelText: 'Avbryt',
                  onOk: () => {
                    resetAlert()
                  },
                  content: (
                    <>
                      <Text variant="body1" gutterBottom>
                        För att attributet och dess värde ska kunna förvaltas i
                        Homepal behöver entiteten ha en nyckel.
                      </Text>
                      <Text variant="body1">
                        Välj ett attribut till att vara entitetens nyckel innan
                        du kan sätta attributet till{' '}
                        <b>Förvaltas enbart i Homepal</b>.
                      </Text>
                    </>
                  )
                })
              } else if (data.manual_input && data.is_primary) {
                // Id needs to be synchronized
                setAlert({
                  isOpen: true,
                  header: 'Entitetens nyckel måste vara synkroniserad',
                  okText: 'OK',
                  cancelText: 'Avbryt',
                  onOk: () => {
                    resetAlert()
                  },
                  content: (
                    <Text variant="body1">
                      Entitetens nyckel måste{' '}
                      <b>Synkroniseras från integrerad data</b> för att vara
                      giltig.
                    </Text>
                  )
                })
              } else {
                form.submit()
              }
            }}
          >
            {save}
          </Button>
        </Box>
      }
    >
      <Form
        layout={'vertical'}
        onFinish={(values) => {
          onSave(values)
          onClose()
        }}
        form={form}
        initialValues={attribute}
      >
        <Box sx={{ mb: 2 }}>
          <Form.Item
            label={'Namn'}
            name={'name'}
            className={Styles['attribute-modal-form-item']}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              () =>
                validateUnique(
                  outputAttributes,
                  'name',
                  'Ett attribut med det namnet finns redan',
                  attribute.id
                )
            ]}
          >
            <Input />
          </Form.Item>
          <Text variant="caption" color="text.secondary">
            Ett läsbart namn.
          </Text>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Form.Item
            label={'Attribut ID'}
            name={'real_name'}
            className={Styles['attribute-modal-form-item']}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              () =>
                validateUnique(
                  outputAttributes,
                  'real_name',
                  'Ett attribut med det Attribut ID:et finns redan',
                  attribute.id
                ),
              // validate technical name
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject()
                  }
                  const allowedTechnicalName = awsFriendlyName(value)

                  if (allowedTechnicalName !== value) {
                    return Promise.reject('Det tekniska namnet tillåts ej.')
                  }

                  return Promise.resolve()
                }
              })
            ]}
          >
            <Input addonAfter={locked && <Lock />} disabled={locked} />
          </Form.Item>
          <Text variant="caption" color="text.secondary">
            En teknisk identifierare. Bör vara på engelska.
          </Text>
        </Box>
        <Form.Item
          label={'Typ'}
          name={'type'}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <Select
            placeholder={'Välj datatyp'}
            options={Constants.DATA_TYPES_OPTIONS}
          />
        </Form.Item>
        <Text variant="subtitle1" gutterBottom>
          Övrigt
        </Text>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          <Form.Item name={'is_primary'} valuePropName={'checked'} noStyle>
            <Checkbox>Detta attribut representerar entitetens nyckel</Checkbox>
          </Form.Item>
          <Form.Item name={'is_reference'} valuePropName={'checked'} noStyle>
            <Checkbox>Detta attribut representerar entitetens titel</Checkbox>
          </Form.Item>
        </Box>
        <Form.Item
          label={'Källa'}
          name={'manual_input'}
          className={Styles['manual-input-container']}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <RadioButton
            className={Styles['radio-container']}
            options={[
              {
                key: 'not_manual_input',
                value: false,
                label: 'Synkroniseras från integrerad data'
              },
              {
                key: 'manual_input',
                value: true,
                label: (
                  <span className={Styles['label-with-information-box']}>
                    <p>Förvaltas enbart i Homepal</p>
                    <Tooltip
                      title={
                        // eslint-disable-next-line max-len
                        'Värdet till detta attribut får ingen extern källa, utan kommer enbart förvaltas (lagras och editeras) i Homepal.'
                      }
                    >
                      <IconButton size="small">
                        <Icon name="InfoOutlined" />
                      </IconButton>
                    </Tooltip>
                  </span>
                )
              }
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ForbiddenModal = ({ DatasetStore, visible, onClose }) => {
  if (!DatasetStore.error) {
    return null
  }

  return (
    <Modal
      size={'small'}
      visible={visible}
      header={'Kunde ej ta bort attribut'}
      onClose={onClose}
      actions={
        <Button onClick={() => onClose()} variant="text">
          Stäng
        </Button>
      }
    >
      <Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Icon color="warning" name="ErrorOutlineOutlined" size="large" />
          <Text variant="subtitle1">Attribut används</Text>
        </Box>
        <Text variant="body1">Attributet används i andra dataprodukter:</Text>
        <ul>
          {DatasetStore.error.detail.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </Box>
    </Modal>
  )
}

const Lock = () => (
  <div className={Styles['lock-container']}>
    <Icon name="LockOutlined" />
  </div>
)

/**
 * Validate input value against current output attributes.
 * @param {array} outputAttributes - Current dataset's output attributes.
 * @param {string} key - Which OutputAttribute column to validate.
 * @param {string} reject - Message to give if rejected.
 * @returns {promise} Promise of if value exists or not.
 */
const validateUnique = (outputAttributes, key, reject, id) => ({
  validator(_, value) {
    if (!value) {
      return Promise.reject()
    }

    if (
      outputAttributes.find(
        (item) =>
          item[key]?.toLowerCase() === value.toLowerCase() &&
          (id ? id !== item.id : true)
      )
    ) {
      return Promise.reject(reject)
    }

    return Promise.resolve()
  }
})

function mapStateToProps({ AuthStore, DatasetStore, DataProductStore }) {
  return { AuthStore, DatasetStore, DataProductStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...OutputAttributeActions, ...AlertActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Schema))
