import * as Api from 'redux/api/DataPlatform/shapes/outputAttributes/v2'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryDeleteOutputAttribute(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_OUTPUT_ATTRIBUTE
    })

    Api.remove(id)
      .then((response) => {
        dispatch({
          type: Types.DELETE_OUTPUT_ATTRIBUTE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Attributet har tagits bort.')
      })
      .catch((error) => {
        dispatch({
          type: Types.DELETE_OUTPUT_ATTRIBUTE_FAILED,
          payload: error.response.data.errors?.[0]
        })
      })
  }
}

export function tryCreateOutputAttribute(body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_OUTPUT_ATTRIBUTE
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_OUTPUT_ATTRIBUTE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Ett nytt attribut har skapats.')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_OUTPUT_ATTRIBUTE_FAILED
        })

        messageWarning('Attributet kunde inte skapas.')
      })
  }
}

export function tryUpdateOutputAttribute(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_OUTPUT_ATTRIBUTE
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_OUTPUT_ATTRIBUTE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Attributet har uppdaterats.')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_OUTPUT_ATTRIBUTE_FAILED
        })

        messageWarning('Attributet kunde inte uppdateras.')
      })
  }
}
