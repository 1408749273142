import React, { ReactNode, useState, useMemo, useEffect } from 'react'

import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconTip from 'components_new/molecules/IconTip'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import Stack from 'components_new/atoms/Stack'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TabSwitch from 'components_new/molecules/TabSwitch'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import {
  translateComparativePeriod,
  translateDateFilter,
  translateFilter,
  translateGroupByType,
  translateWidgetType
} from 'utils/enumTranslator'

import {
  AttributeOption,
  ComparativePeriod,
  DateFilterType,
  PeriodFilter,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import { FilterConditionFormatBody } from 'types/GlobalKpiOption'
import { validFilter } from '../../WidgetSettings/FilterSection/NativeFilterSection'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'

interface InformationDialogProps {
  onClose: () => void
  open: boolean
  kpiTemplates: KpiTemplateRedux
  widget: WidgetObject
}

interface InformationRowProps {
  title: ReactNode | string
  children: ReactNode
}

interface InformationTableContentProps {
  content: {
    id: string
    index: number
    breakdownBy: string | null
    breakdownByOptions: AttributeOption[]
    filterAttributeOptions: AttributeOption[]
    filters: FilterConditionFormatBody[]
    increaseIsPositive: boolean
    title: string
    template: {
      title: string
      summary: string
      url: string
    }
    widget: {
      comparativePeriod: ComparativePeriod | null
      comparativePeriodAvailable: boolean | null
      comparativePeriodOptions: ComparativePeriod[]
      dateFilter: PeriodFilter | null
      dateFilterOptions: PeriodFilter[]
      dateFilterType: DateFilterType
      filters: FilterConditionFormatBody[]
      segmentBy: string | null
      segmentByOptions: AttributeOption[]
      type: WidgetType
      typeOptions: {
        value: WidgetType
        disabled: boolean
      }[]
    }
  }[]
  selectedKpi: string
}

const InformationDialog = (props: InformationDialogProps) => {
  const { kpiTemplates, onClose, open, widget } = props

  const kpis: InformationTableContentProps['content'] = useMemo(
    () =>
      widget.settings.kpi_options.map((item) => {
        return {
          id: item.id,
          index: item.index,
          breakdownBy: item.breakdown_by.selected,
          breakdownByOptions: item.breakdown_by.options,
          filterAttributeOptions: item.filters[0].attribute_option.options,
          filters: item.filters,
          increaseIsPositive: item.increase_is_positive,
          title: item.title,
          template: {
            title: kpiTemplates[item.kpi_template_id]?.title || '',
            summary: kpiTemplates[item.kpi_template_id]?.summary || '',
            url: kpiTemplates[item.kpi_template_id]?.url || ''
          },
          widget: {
            comparativePeriod:
              widget.settings.comparative_period.selected?.value,
            comparativePeriodAvailable:
              widget.settings.comparative_period.available,
            comparativePeriodOptions:
              widget.settings.comparative_period.options,
            dateFilter: widget.settings.date_filter.selected?.value ?? null,
            dateFilterType: widget.settings.date_filter.type,
            dateFilterOptions: widget.settings.date_filter.options,
            filters: widget.settings.filters,
            segmentBy: widget.settings.segment_by.selected,
            segmentByOptions: widget.settings.segment_by.options,
            type: widget.settings.type.selected,
            typeOptions: widget.settings.type.options
          }
        }
      }),
    [widget.settings.kpi_options, kpiTemplates.data]
  )

  const [selectedTab, setSelectedTab] = useState<string>(kpis?.[0]?.id)

  // Kpis will be empty list at load
  useEffect(() => {
    if (!selectedTab && kpis.length > 0) {
      setSelectedTab(kpis[0].id)
    }
  }, [kpis])

  return (
    <SimpleDialog
      title={'Visa information'}
      onClose={onClose}
      open={open}
      onMouseDown={(e) => e.stopPropagation()} // In order to stop the user from dragging around the underlying widget.
      actions={
        <Button onClick={onClose} variant="text">
          Stäng
        </Button>
      }
    >
      {kpis.length > 1 ? (
        <TabSwitch
          options={kpis.map((item) => ({
            label: item.title,
            value: item.id
          }))}
          value={selectedTab}
          variant={'fullWidth'}
          onChange={(value) => setSelectedTab(value)}
          color={'secondary'}
        />
      ) : null}
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableBody>
            <InformationTableContent content={kpis} selectedKpi={selectedTab} />
          </TableBody>
        </Table>
      </TableContainer>
    </SimpleDialog>
  )
}

const InformationTableContent = (props: InformationTableContentProps) => {
  const { content, selectedKpi } = props

  const currentContent = content.find((item) => item.id === selectedKpi)

  if (currentContent) {
    const filters = [
      ...currentContent.filters,
      ...currentContent?.widget.filters
    ].filter((filter) =>
      validFilter(
        filter.attribute_option.selected,
        filter.condition.selected,
        filter.value.selected,
        filter?.from_value?.selected,
        filter?.to_value?.selected
      )
    )

    return (
      <>
        <InformationRow title={'Nyckeltal'}>
          <Chip
            color={'default'}
            label={currentContent.template.title}
            size="small"
          />
        </InformationRow>
        <InformationRow title={'Summering'}>
          {currentContent.template.summary}
        </InformationRow>
        <InformationRow title={'Läs mer om nyckeltalet'}>
          <a
            href={currentContent.template.url}
            target="_blank"
            rel="noreferrer"
          >
            {currentContent.template.url}
          </a>
        </InformationRow>
        <InformationRow title={'Typ'}>
          <Stack spacing={1}>
            {currentContent.widget.typeOptions.map((option, i) => (
              <Tooltip title={translateWidgetType[option.value].title} key={i}>
                <Icon
                  color={
                    currentContent.widget.type === option.value
                      ? 'secondary'
                      : 'disabled'
                  }
                  name={translateWidgetType[option.value].iconName}
                />
              </Tooltip>
            ))}
          </Stack>
        </InformationRow>
        <InformationRow title={'Tidsperiod'}>
          <Stack
            sx={{
              flexWrap: 'wrap',
              gap: 1,
              maxHeight: '128px',
              overflowY: 'auto'
            }}
          >
            {currentContent.widget.dateFilterOptions.map((option, i) => (
              <Chip
                color={
                  currentContent.widget.dateFilter === option
                    ? 'secondary'
                    : 'default'
                }
                disabled={currentContent.widget.dateFilter !== option}
                label={translateDateFilter[option]}
                size="small"
                key={i}
              />
            ))}
          </Stack>
        </InformationRow>
        {currentContent.widget.comparativePeriodAvailable ? (
          <InformationRow title={'Jämför med period'}>
            <Stack justifyContent="space-between">
              {currentContent.widget.comparativePeriodAvailable ? (
                <>
                  <Stack
                    sx={{
                      flexWrap: 'wrap',
                      gap: 1,
                      maxHeight: '128px',
                      overflowY: 'auto'
                    }}
                  >
                    {currentContent.widget.comparativePeriodOptions.map(
                      (option, i) => (
                        <Chip
                          color={
                            currentContent.widget.comparativePeriod === option
                              ? 'secondary'
                              : 'default'
                          }
                          disabled={
                            currentContent.widget.comparativePeriod !== option
                          }
                          label={
                            currentContent.widget.dateFilter
                              ? translateComparativePeriod[
                                currentContent.widget.dateFilterType
                              ][currentContent.widget.dateFilter][option]
                              : ''
                          }
                          size="small"
                          key={i}
                        />
                      )
                    )}
                  </Stack>
                  {currentContent.widget.comparativePeriod ? (
                    <Stack>
                      <Tooltip
                        title={
                          currentContent.increaseIsPositive
                            ? 'Ökning är positivt'
                            : 'Minskning är positivt'
                        }
                      >
                        <Icon
                          color="success"
                          name={
                            currentContent.increaseIsPositive
                              ? 'ArrowUpwardOutlined'
                              : 'ArrowDownwardOutlined'
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          currentContent.increaseIsPositive
                            ? 'Minskning är negativt'
                            : 'Ökning är negativt'
                        }
                      >
                        <Icon
                          color="error"
                          name={
                            currentContent.increaseIsPositive
                              ? 'ArrowDownwardOutlined'
                              : 'ArrowUpwardOutlined'
                          }
                        />
                      </Tooltip>
                    </Stack>
                  ) : null}
                </>
              ) : (
                <Text color="text.disabled">
                  Det finns ingen period att jämföra med.
                </Text>
              )}
            </Stack>
          </InformationRow>
        ) : null}
        <InformationRow
          title={
            <>
              {translateGroupByType['SEGMENT_BY']}
              <IconTip
                title={`Endast applicerbart med ${
                  translateWidgetType[WidgetType.BAR_CHART].title
                }.`}
                variant="info"
              />
            </>
          }
        >
          <Stack
            sx={{
              flexWrap: 'wrap',
              gap: 1,
              maxHeight: '128px',
              overflowY: 'auto'
            }}
          >
            {currentContent.widget.segmentByOptions.map((option, i) => (
              <Chip
                color={
                  currentContent.widget.segmentBy === option.id
                    ? 'secondary'
                    : 'default'
                }
                disabled={currentContent.widget.segmentBy !== option.id}
                label={option.name}
                size="small"
                key={i}
              />
            ))}
          </Stack>
        </InformationRow>
        <InformationRow
          title={
            <>
              {translateGroupByType['BREAKDOWN_BY']}
              <IconTip
                title={`Endast applicerbart med ${
                  translateWidgetType[WidgetType.BAR_CHART].title
                } och ${translateWidgetType[WidgetType.LINE_CHART].title}.`}
                variant="info"
              />
            </>
          }
        >
          <Stack
            sx={{
              flexWrap: 'wrap',
              gap: 1,
              maxHeight: '128px',
              overflowY: 'auto'
            }}
          >
            {currentContent.breakdownByOptions.map((option, i) => (
              <Chip
                color={
                  currentContent.breakdownBy === option.id
                    ? 'secondary'
                    : 'default'
                }
                disabled={currentContent.breakdownBy !== option.id}
                label={option.name}
                size="small"
                key={i}
              />
            ))}
          </Stack>
        </InformationRow>
        <InformationRow title={'Aktiva filter'}>
          <Stack
            direction="column"
            sx={{
              maxHeight: '172px',
              overflowY: 'auto'
            }}
            spacing={1}
          >
            {filters.length > 0 ? (
              filters.map((filter, i) => {
                const filterAttribute =
                  currentContent.filterAttributeOptions.find(
                    (option) => filter.attribute_option.selected === option.id
                  )

                let value = ''

                if (
                  filter.value.selected &&
                  typeof filter.value.selected === 'object' &&
                  filter.value.selected.length > 0
                ) {
                  value = filter.value.selected.join(', ')
                } else if (
                  filter.value.selected &&
                  typeof filter.value.selected === 'number'
                ) {
                  value = filter.value.selected.toString()
                }

                return (
                  <Chip
                    key={`filter-${i}`}
                    color={'secondary'}
                    label={
                      filter.condition.selected
                        ? translateFilter(
                          filterAttribute ? filterAttribute.name : '',
                          filter.condition.selected,
                          value,
                          filter.from_value?.selected,
                          filter.to_value?.selected
                        )
                        : ''
                    }
                    size="small"
                    sx={{ width: 'fit-content', justifyContent: 'flex-start' }}
                  />
                )
              })
            ) : (
              <Text color="text.disabled">Det finns inga aktiva filter.</Text>
            )}
          </Stack>
        </InformationRow>
      </>
    )
  }

  return null
}

const InformationRow = (props: InformationRowProps) => {
  const { title, children } = props

  return (
    <TableRow hover={true}>
      <TableCell scope="row">{title}</TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  )
}

export default InformationDialog
