import React from 'react'

import { getTimeAgoString, getUserNameInformation } from 'helpers/Functions'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import OverflowText from 'components_new/molecules/OverflowText'

export const PAGE_LIMIT = 10

export const TABLE_HEADERS = (user) => [
  {
    label: 'Namn',
    key: 'name',
    sorter: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
  },
  {
    label: 'Delningstyp',
    key: 'share_type',
    render: () => {
      return (
        <Chip
          icon={<Icon name="CloudOutlined" />}
          label="Publikt API"
          size="small"
        />
      )
    }
  },
  {
    label: 'Senast uppdaterad',
    key: 'latest_updated',
    render: (latestUpdated) => {
      const date = new Date(latestUpdated)

      return getTimeAgoString(date)
    }
  },
  {
    label: 'Uppdaterad av',
    key: 'updated_by',
    render: (updatedBy, row) => {
      const isUser = user?.id === updatedBy

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Avatar sx={{ width: 24, height: 24, fontSize: '13px' }}>
            {row.initials}
          </Avatar>
          <OverflowText variant="body1">
            {row.first_name} {row.last_name} {isUser && <b>(Du)</b>}
          </OverflowText>
        </Box>
      )
    }
  }
]

export const getRows = (apis, UserStore) => {
  return apis.map((api) => {
    const { initials, firstName, lastName } = getUserNameInformation(
      api.attributes.updated_by,
      UserStore
    )

    return {
      key: api.id,
      name: api.attributes.name,
      latest_updated: api.attributes.updated_at,
      updated_by: api.attributes.updated_by,
      first_name: firstName,
      last_name: lastName,
      initials
    }
  })
}
