import React, { MouseEventHandler, ReactNode } from 'react'
import { TableCell as MUITableCell } from '@mui/material'

export interface TableCellProps {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  children: ReactNode
  padding?: 'checkbox' | 'none' | 'normal'
  scope?: string
  size?: 'medium' | 'small'
  sortDirection?: 'asc' | 'desc' | false
  style?: object
  sx?: object
  variant?: 'body' | 'footer' | 'head'
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  colSpan?: number
}

/**
 * The TableCell component is a...
 */

const TableCell = React.forwardRef((props: TableCellProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUITableCell {...rest} ref={ref}>
      {children}
    </MUITableCell>
  )
})

TableCell.displayName = 'TableCell'
export default TableCell
