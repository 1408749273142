import React, { useEffect, useState, useMemo } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import FormControl from 'components_new/atoms/FormControl'
import Grid from 'components_new/atoms/Grid'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import InputLabel from 'components_new/atoms/InputLabel'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

import Select from 'components_new/atoms/Select'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import Stack from 'components_new/atoms/Stack'
import Switch from 'components_new/atoms/Switch'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { Severity, StatusBannerBody, StatusBannerData } from 'utils/types'
import Banner from 'components_new/molecules/Banner'

interface StatusBannerTemplateProps {
  submit: (data: StatusBannerBody) => void
  customers: object
  data?: StatusBannerData
  onClose: () => void
  open: boolean
}

const INIT_STATE: StatusBannerBody = {
  customer_ids: [],
  description: '',
  icon: 'InfoOutlined',
  link: null,
  link_label: null,
  severity: 'info',
  show: false,
  show_public: false,
  title: ''
}

const StatusBannerTemplate = (props: StatusBannerTemplateProps) => {
  const { customers, data, onClose, open, submit } = props
  const [formData, setFormData] = useState<StatusBannerBody>(INIT_STATE)
  const isEdit = !!data

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(INIT_STATE)
    }
  }, [data])

  const customerOptions = useMemo(
    () =>
      Object.values(customers).map((customer) => ({
        id: customer.id,
        label: customer.name
      })),
    [customers]
  )

  return (
    <SimpleDialog
      actions={
        <>
          <Button variant="text" onClick={() => onClose()}>
            Avbryt
          </Button>
          <Button onClick={() => submit(formData)}>
            {isEdit ? 'Spara' : 'Publicera'}
          </Button>
        </>
      }
      onClose={onClose}
      open={open}
      title={isEdit ? 'Uppdatera banderoll' : 'Skapa banderoll'}
      maxWidth="lg"
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Stack spacing={8} justifyContent="start">
              <FormControl size="small" sx={{ mt: 2 }}>
                <Stack spacing={2}>
                  <Text>Visa i portal</Text>
                  <Switch
                    color="primary"
                    size="small"
                    checked={formData.show}
                    onChange={() =>
                      setFormData({ ...formData, show: !formData.show })
                    }
                  />
                </Stack>
              </FormControl>
              <FormControl size="small" sx={{ mt: 2 }}>
                <Stack spacing={2}>
                  <Tooltip
                    title={
                      <>
                        <i>
                          Om aktiverad kommer banderollen synas på inbäddade
                          widgets och på publika dashboards
                        </i>
                      </>
                    }
                  >
                    <Text>Visa på inbäddningar</Text>
                  </Tooltip>
                  <Switch
                    color="primary"
                    size="small"
                    checked={formData.show_public}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        show_public: !formData.show_public
                      })
                    }
                  />
                </Stack>
              </FormControl>
            </Stack>
            <Alert severity="info" sx={{ my: 2 }}>
              Minst 1 måste vara <b>på</b> för att banderoll ska visas
            </Alert>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Titel"
              onChange={(event) =>
                setFormData({ ...formData, title: event.target.value })
              }
              size="small"
              value={formData.title}
              required
            />

            <TextField
              label="Beskrivning"
              multiline={true}
              minRows={2}
              maxRows={3}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  description: event.target.value
                })
              }
              size="small"
              value={formData.description}
              required
            />
            <SelectSearch
              externalLabel="Visa endast för specifika kunder"
              onChange={(options: Option[]) =>
                setFormData({
                  ...formData,
                  customer_ids: options.map((option) => option.id as string)
                })
              }
              multiple
              selected={customerOptions.filter((option) =>
                formData.customer_ids.includes(option.id)
              )}
              options={Object.values(customers).map((customer) => ({
                id: customer.id,
                label: customer.name
              }))}
            />
            <Alert severity="info" sx={{ my: 2 }}>
              <b>Tänk på!</b> Om ingen kund är vald kommer <b>ALLA</b> att få se
              banderollen.
            </Alert>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth sx={{ mt: 2 }}>
              <InputLabel id="severity-select">Allvarlighetsgrad</InputLabel>
              <Select
                labelId="severity-select"
                label="Allvarlighetsgrad"
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    severity: event.target.value as Severity
                  })
                }
                value={formData.severity}
              >
                <MenuItem value="info">Information</MenuItem>
                <MenuItem value="warning">Varning</MenuItem>
                <MenuItem value="error">Kritisk</MenuItem>
                <MenuItem value="success">Lyckad</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ mt: 2 }}>
              <InputLabel id="icon-select">Ikon</InputLabel>
              <Select
                labelId="icon-select"
                label="Ikon"
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    icon: event.target.value as IconNames
                  })
                }
                value={formData.icon}
              >
                <MenuItem value="InfoOutlined">
                  <Icon name={'InfoOutlined'} fontSize="small" />
                </MenuItem>
                <MenuItem value="Warning">
                  <Icon name={'Warning'} fontSize="small" />
                </MenuItem>
              </Select>
            </FormControl>
            <Stack spacing={1} sx={{ mt: 3 }} direction="column">
              <Text variant="caption">Lägg till en länk i banderollen</Text>
              <TextField
                label="Länk"
                onChange={(event) =>
                  setFormData({ ...formData, link: event.target.value })
                }
                size="small"
                value={formData.link ?? ''}
              />
              <TextField
                label="Text för länk"
                onChange={(event) =>
                  setFormData({ ...formData, link_label: event.target.value })
                }
                size="small"
                value={formData.link_label ?? ''}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Text variant="h5" sx={{ lineHeight: 1, mt: 4, mb: 1 }}>
        Förhandsvisning
      </Text>
      {(formData.show || formData.show_public) && (
        <Banner
          body={formData.description}
          href={formData.link}
          iconName={formData.icon}
          linkLabel={formData.link_label}
          severity={formData.severity}
          title={formData.title}
        />
      )}
    </SimpleDialog>
  )
}

export default StatusBannerTemplate
