import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import UploadFile from 'components_new/molecules/UploadFile'
import UserListItem from 'components_new/molecules/UserListItem'

import { CompanyGroup } from 'types/GlobalCompanyGroups'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { EconomyAccount, ParsedEconomySet } from 'types/GlobalEconomySets'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemSecondaryAction from 'components_new/atoms/List/ListItemSecondaryAction'

enum Tab {
  DEFAULT = 'DEFAULT',
  CHART = 'CHART'
}

interface EditChartOfAccountsDialogProps {
  AuthStore: AuthenticationState
  economySet: ParsedEconomySet | null
  companyGroupsWithoutEconomySets: CompanyGroup[]
  createEconomyAccount: (
    data: {
      name: string
      number: string
      economy_set_id: string
    },
    callback?: (err?: boolean) => void
  ) => void
  deleteEconomyAccount: (
    id: string,
    economySetId: string,
    callback?: (err?: boolean) => void
  ) => void
  onClose: () => void
  open: boolean
  updateCompanyGroup: (
    id: string,
    name?: string,
    economySetId?: string | null,
    callback?: (err?: boolean) => void
  ) => void
  updateEconomyAccount: (
    id: string,
    name: string,
    economySetId: string,
    callback?: (err?: boolean) => void
  ) => void
  updateEconomySet: (
    id: string,
    name: string,
    callback?: (err?: boolean) => void
  ) => void
  uploadEconomySet: (
    id: string,
    csvFile: Blob,
    callback?: (err?: boolean) => void
  ) => void
}

const EditChartOfAccountsDialog = (props: EditChartOfAccountsDialogProps) => {
  const {
    createEconomyAccount,
    deleteEconomyAccount,
    economySet,
    updateCompanyGroup,
    updateEconomySet,
    updateEconomyAccount,
    uploadEconomySet
  } = props

  // Loaders
  const [updatingSet, setUpdatingSet] = useState<boolean>(false)
  const [updatingCompanyGroup, setUpdatingCompanyGroup] =
    useState<boolean>(false)

  const [addCompanyGroupOpen, setAddCompanyGroupOpen] = useState(false)
  const [createAccountOpen, setCreateAccountOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState<EconomyAccount | null>(
    null
  )
  const [editAccount, setEditAccount] = useState<EconomyAccount | null>(null)
  const [editAccountNameInput, setEditAccountNameInput] = useState('')
  const [createAccountNameInput, setCreateAccountNameInput] = useState('')
  const [createAccountNumberInput, setCreateAccountNumberInput] = useState('')
  const [errorAccountNumber, setErrorAccountNumber] = useState<string | null>(
    null
  )
  const [newName, setNewName] = useState<string>(economySet?.name || '')
  const [tab, setTab] = useState<Tab>(Tab.DEFAULT)
  const [newEconomySetFile, setEconomySetFile] = useState<{
    file: Blob
    name: string
  } | null>(null)
  const [uploading, setUploading] = useState<boolean>(false)

  useEffect(() => {
    if (economySet && economySet.name !== newName) {
      setNewName(economySet.name)
    }
  }, [economySet])
  const parsedAccounts = useMemo(
    () => economySet?.accounts.map((account) => account) || [],
    [economySet]
  )

  const allAccounts = useMemo(() => {
    const list = []

    for (let i = 1000; i <= 9999; i++) {
      list.push(i.toString())
    }

    return list
  }, [])

  const handleCloseRename = () => {
    setRenameOpen(false)

    setNewName(economySet?.name || '')
  }

  const handleCloseEditAccount = () => {
    setEditAccount(null)
  }

  const handleCloseCreateAccount = () => {
    setCreateAccountOpen(false)

    setCreateAccountNameInput('')
    setCreateAccountNumberInput('')
    setErrorAccountNumber(null)
  }

  const onChangeAccountNumber = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCreateAccountNumberInput(e.target.value)

    if (parsedAccounts.some((acc) => acc.number === e.target.value)) {
      setErrorAccountNumber(
        `Kontonummer ${e.target.value} används redan i kontoplanen`
      )
    } else if (!allAccounts.includes(e.target.value)) {
      setErrorAccountNumber('Kontonumret måste vara en siffra mellan 1000-9999')
    } else {
      setErrorAccountNumber(null)
    }
  }

  useEffect(() => {
    if (props.open) {
      setNewName(economySet?.name || '')
    } else {
      setTab(Tab.DEFAULT)
    }
  }, [props.open])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <AdvancedDialog
        actions={
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {tab === Tab.DEFAULT &&
            props.AuthStore.customer?.is_management_company ? (
                  <Button
                    disabled={updatingCompanyGroup}
                    startIcon={<Icon name="AccountTreeOutlined" />}
                    variant="outlined"
                    onClick={() => setTab(Tab.CHART)}
                  >
                Ändra i kontoplan
                  </Button>
                ) : (
                  <Box />
                )}
            <Button variant="text" onClick={props.onClose}>
              Stäng
            </Button>
          </Box>
        }
        onClose={props.onClose}
        open={props.open}
        title={
          tab === Tab.DEFAULT ? (
            'Redigera kontoplan'
          ) : (
            <Box
              component="span"
              onClick={() => setTab(Tab.DEFAULT)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary'
                },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              <Icon name="ArrowBackOutlined" sx={{ mr: 0.5 }} />
              Ändra i kontoplan
            </Box>
          )
        }
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth:
              tab === Tab.CHART ||
              !props.AuthStore.customer?.is_management_company
                ? 'md'
                : '512px!important',
            transition: 'max-width 0.5s ease'
          }
        }}
      >
        <Collapse in={tab === Tab.DEFAULT}>
          <UserListItem
            component="div"
            title={economySet?.name || ''}
            type="chart"
            sx={{ bgcolor: 'grey.200', mb: 2 }}
            actions={
              <IconButton
                disabled={updatingCompanyGroup}
                disableRipple={updatingCompanyGroup}
                onClick={() => setRenameOpen(true)}
              >
                <Icon
                  color={'disabled'}
                  fontSize="small"
                  name={'EditOutlined'}
                />
              </IconButton>
            }
          />
        </Collapse>
        <Collapse
          in={
            tab === Tab.DEFAULT &&
            props.AuthStore.customer?.is_management_company
          }
        >
          <CollapsibleSection
            actions={
              <Button
                disabled={updatingCompanyGroup}
                onClick={() => setAddCompanyGroupOpen(true)}
                startIcon={<Icon name="AddCircleOutlineOutlined" />}
              >
                Lägg till
              </Button>
            }
            size="medium"
            title="Koncerner"
            variant="blank"
            sx={{ p: 0 }}
          >
            <List>
              {economySet?.companyGroups
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <ListItem
                    key={group.id}
                    secondaryAction={
                      <Button
                        disabled={updatingCompanyGroup}
                        onClick={() => {
                          setUpdatingCompanyGroup(true)
                          updateCompanyGroup(group.id, undefined, null, () =>
                            setUpdatingCompanyGroup(false)
                          )
                        }}
                        variant="text"
                      >
                        Ta bort
                      </Button>
                    }
                  >
                    <Icon color="disabled" name="Business" />
                    <ListItemText
                      sx={{ ml: 3 }}
                      primary={group.name}
                      primaryTypographyProps={{ fontWeight: 500 }}
                    />
                  </ListItem>
                ))}
            </List>
          </CollapsibleSection>
        </Collapse>
        {tab === Tab.CHART ||
        !props.AuthStore.customer?.is_management_company ? (
              <>
                <Collapse
                  in={
                    tab === Tab.CHART ||
                !props.AuthStore.customer?.is_management_company
                  }
                >
                  <CollapsibleSection
                    actions={
                      <Button
                        onClick={() => setCreateAccountOpen(true)}
                        startIcon={<Icon name="AddCircleOutlineOutlined" />}
                      >
                    Lägg till
                      </Button>
                    }
                    size="medium"
                    title="Konton"
                    variant="blank"
                    sx={{ p: 0 }}
                  >
                    <List>
                      {parsedAccounts
                        .sort((a, b) => a.number.localeCompare(b.number))
                        .map((account) => (
                          <ListItem
                            key={account.number}
                            secondaryAction={
                              <>
                                <Button
                                  onClick={() => {
                                    setEditAccountNameInput(account.name || '')

                                    setEditAccount(account)
                                  }}
                                  variant="text"
                                >
                              Redigera
                                </Button>
                                <Button
                                  onClick={() => setDeleteAccount(account)}
                                  variant="text"
                                >
                              Ta bort
                                </Button>
                              </>
                            }
                          >
                            <ListItemText
                              primary={`${account.number} ${account.name}`}
                              primaryTypographyProps={{ fontWeight: 500 }}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </CollapsibleSection>
                </Collapse>
                <Collapse
                  in={
                    tab === Tab.CHART ||
                !props.AuthStore.customer?.is_management_company
                  }
                  sx={{ mb: 2 }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Alert severity="info" icon={<Icon name="InfoOutlined" />}>
                  Skriv över nuvurande kontoplan med fil. Kontoplanen ska laddas
                  in på csv format. Första kolumnen ska innehålla det 4-siffriga
                  kontonumret och den andra kolumnen kontonamnet.
                    </Alert>
                    <UploadFile
                      accept={['text/csv']}
                      maxSize={2}
                      uploadFile={(file) =>
                        setEconomySetFile({ file, name: file.name })
                      }
                    />
                    {newEconomySetFile ? (
                      <ListItem
                        component="div"
                        sx={{ bgcolor: 'action.hover', mt: 1 }}
                      >
                        <ListItemAvatar>
                          <Box
                            sx={{
                              position: 'relative',
                              height: '48px',
                              width: '48px',
                              borderRadius: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Icon name={'AttachmentOutlined'} />
                          </Box>
                        </ListItemAvatar>
                        <ListItemText primary={newEconomySetFile.name} />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => setEconomySetFile(null)}>
                            <Icon name="DeleteOutline" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ) : null}
                    <Button
                      fullWidth
                      disabled={!newEconomySetFile}
                      loading={uploading}
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      onClick={() => {
                        setUploading(true)
                        uploadEconomySet(
                          (economySet as ParsedEconomySet).id,
                      newEconomySetFile?.file as Blob,
                      (err) => {
                        if (!err) {
                          setEconomySetFile(null)
                        }

                        setUploading(false)
                      }
                        )
                      }}
                    >
                  Ladda upp
                    </Button>
                  </Box>
                </Collapse>
              </>
            ) : null}
      </AdvancedDialog>

      {/* Edit chart of accounts */}
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseRename}>
            Stäng
          </Button>
        }
        onClose={handleCloseRename}
        open={renameOpen}
        title={`Döp om "${economySet?.name}"`}
      >
        <TextField
          fullWidth={true}
          label="Kontoplan"
          autoComplete="off"
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
        <Button
          disabled={newName.length === 0}
          fullWidth
          loading={updatingSet}
          variant="contained"
          size="large"
          onClick={() => {
            setUpdatingSet(true)
            updateEconomySet(
              (economySet as ParsedEconomySet).id,
              newName,
              (err) => {
                if (!err) {
                  handleCloseRename()
                }

                setUpdatingSet(false)
              }
            )
          }}
        >
          Spara
        </Button>
      </SimpleDialog>

      {/* Create account modal */}
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseCreateAccount}>
            Stäng
          </Button>
        }
        onClose={handleCloseCreateAccount}
        open={createAccountOpen}
        title={'Lägg till konto'}
      >
        <TextField
          fullWidth={true}
          label="Kontonummer"
          autoComplete="off"
          value={createAccountNumberInput}
          onChange={onChangeAccountNumber}
          sx={{ mt: 1, mb: 2 }}
          required
          type="number"
          helperText={errorAccountNumber}
          error={Boolean(errorAccountNumber)}
        />
        <TextField
          fullWidth={true}
          label="Kontonamn"
          autoComplete="off"
          value={createAccountNameInput}
          onChange={(event) => setCreateAccountNameInput(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
        <Button
          disabled={
            createAccountNameInput.length === 0 ||
            createAccountNumberInput.length === 0 ||
            Boolean(errorAccountNumber)
          }
          fullWidth
          loading={updatingSet}
          variant="contained"
          size="large"
          onClick={() => {
            setUpdatingSet(true)
            createEconomyAccount(
              {
                name: createAccountNameInput,
                number: createAccountNumberInput,
                economy_set_id: (economySet as ParsedEconomySet).id
              },
              (err) => {
                if (!err) {
                  handleCloseCreateAccount()
                }

                setUpdatingSet(false)
              }
            )
          }}
        >
          Spara
        </Button>
      </SimpleDialog>

      {/* Edit account modal */}
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseEditAccount}>
            Stäng
          </Button>
        }
        onClose={handleCloseEditAccount}
        open={Boolean(editAccount)}
        title={`Döp om konto ${editAccount?.number}`}
      >
        <TextField
          fullWidth={true}
          label="Kontonamn"
          autoComplete="off"
          value={editAccountNameInput}
          onChange={(event) => setEditAccountNameInput(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
        <Button
          disabled={editAccountNameInput.length === 0}
          fullWidth
          loading={updatingSet}
          variant="contained"
          size="large"
          onClick={() => {
            setUpdatingSet(true)
            updateEconomyAccount(
              (editAccount as EconomyAccount).id,
              editAccountNameInput,
              (economySet as ParsedEconomySet).id,
              (err) => {
                if (!err) {
                  handleCloseEditAccount()
                }

                setUpdatingSet(false)
              }
            )
          }}
        >
          Spara
        </Button>
      </SimpleDialog>

      {/* Add company group to chart of accounts modal */}
      <SimpleDialog
        actions={
          <Button variant="text" onClick={() => setAddCompanyGroupOpen(false)}>
            Stäng
          </Button>
        }
        onClose={() => setAddCompanyGroupOpen(false)}
        open={addCompanyGroupOpen}
        title={`Lägg till koncern på ${economySet?.name}`}
      >
        {props.companyGroupsWithoutEconomySets.length > 0 ? (
          <List sx={{ position: 'relative' }}>
            {props.companyGroupsWithoutEconomySets
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((group) => (
                <ListItem
                  key={group.id}
                  secondaryAction={
                    <Button
                      disabled={
                        updatingCompanyGroup || group.companies.length === 0
                      }
                      onClick={() => {
                        setUpdatingCompanyGroup(true)
                        updateCompanyGroup(
                          group.id,
                          undefined,
                          (economySet as ParsedEconomySet).id,
                          () => {
                            setUpdatingCompanyGroup(false)
                          }
                        )
                      }}
                      variant="text"
                    >
                      Lägg till
                    </Button>
                  }
                >
                  <Icon color="disabled" name="Business" />
                  <ListItemText
                    sx={{ ml: 3 }}
                    primary={`${group.name} ${
                      group.companies.length === 0 ? '(Saknar bolag)' : ''
                    }`}
                    primaryTypographyProps={{
                      fontWeight: 500,
                      color:
                        group.companies.length === 0
                          ? 'text.disabled'
                          : undefined
                    }}
                  />
                </ListItem>
              ))}
          </List>
        ) : (
          <Alert severity="warning" icon={<Icon name="Warning" />}>
            <strong>Inga koncerner saknar kontoplan.</strong> Ta bort en koncern
            från en kontoplan för att lägga till det på denna kontoplan.
          </Alert>
        )}
      </SimpleDialog>

      {/* Delete account from chart modal */}
      <SimpleDialog
        title={'Radera konto från kontoplan?'}
        open={Boolean(deleteAccount)}
        onClose={() => setDeleteAccount(null)}
        actions={
          <>
            <Button variant="text" onClick={() => setDeleteAccount(null)}>
              Stäng
            </Button>
            <Button
              color="error"
              loading={updatingSet}
              onClick={() => {
                setUpdatingSet(true)

                deleteEconomyAccount(
                  (deleteAccount as EconomyAccount).id,
                  (economySet as ParsedEconomySet).id,
                  (err) => {
                    if (!err) {
                      setDeleteAccount(null)
                    }

                    setUpdatingSet(false)
                  }
                )
              }}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Kontot{' '}
            <code>
              {deleteAccount?.number} {deleteAccount?.name}
            </code>{' '}
            kommer att raderas permanent. Är du säker?
          </>
        }
      />
    </ThemeProvider>
  )
}

export default EditChartOfAccountsDialog
