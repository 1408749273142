import { UserTitle, UserStatus, UserTag } from 'types/GlobalTracking'

/** Enum translators */
export const translateUserTag: Record<
  UserTag,
  {
    title: string
    body: string
    icon: string
  }
> = {
  [UserTag.DASHBOARD_VIEWS]: {
    title: 'Viewer',
    body: 'Visningar av dashboards',
    icon: '👁️'
  },
  [UserTag.ACTIVE_WEEKS_STREAK]: {
    title: 'On a streak!',
    body: 'Aktiva veckor i rad',
    icon: '🔥'
  },
  [UserTag.INACTIVE_WEEKS_STREAK]: {
    title: 'Fading away...',
    body: 'Inaktiva veckor i rad',
    icon: '🥀'
  },
  [UserTag.INVITED_USERS]: {
    title: 'Influencer',
    body: 'Inbjudna användare',
    icon: '💅'
  },
  [UserTag.NO_ACTIVITY]: {
    title: 'Ghost',
    body: 'Dagar utan en första aktivitet',
    icon: '👻'
  },
  [UserTag.NOT_ACCEPTED]: {
    title: 'Still in mail...',
    body: 'Dagar utan att ha accepterat sin inbjudan',
    icon: '📬'
  },
  [UserTag.CREATED_DASHBOARDS]: {
    title: 'Creator',
    body: 'Skapade dashboards',
    icon: '🎨'
  }
}

export const translateUserStatus: Record<
  UserStatus,
  {
    title: string
    body: string
    severity: 'error' | 'info' | 'success' | 'warning'
  }
> = {
  [UserStatus.ACTIVATION_FAILED]: {
    title: 'Aktivering misslyckades',
    body: 'Användare har inte aktiverats och varit inaktiv i minst 4 veckor i rad',
    severity: 'error'
  },
  [UserStatus.ACTIVATION_IN_PROGRESS]: {
    title: 'Aktivering pågår',
    body: 'Användare har blivit inbjuden',
    severity: 'info'
  },
  [UserStatus.ACTIVE]: {
    title: 'Aktiv',
    body: 'Användare har vid något tillfälle varit aktiv minst 4 veckor i rad',
    severity: 'success'
  },
  [UserStatus.INACTIVE]: {
    title: 'Inaktiv',
    body: 'Användare har varit inaktiv minst 2 veckor i rad',
    severity: 'warning'
  },
  [UserStatus.CHURN]: {
    title: 'Churn',
    body: 'Användare har varit inaktiv minst 6 veckor i rad',
    severity: 'error'
  }
}

export const translateTitle: Record<UserTitle, string> = {
  [UserTitle.CEO]: 'VD',
  [UserTitle.CFO]: 'Ekonomichef',
  [UserTitle.IT]: 'IT',
  [UserTitle.LESSOR]: 'Uthyrare',
  [UserTitle.TECHINCAL_MANAGER]: 'Chef inom teknisk förvaltning',
  [UserTitle.REGION_MANAGER]: 'Regions, distrikts- eller områdeschef',
  [UserTitle.FINANCE_CONTROLLER]: 'Ekonomicontroller',
  [UserTitle.CO_WORKER_TECHINCAL_MANAGEMENT]:
    'Medarbetare inom teknisk förvaltning',
  [UserTitle.MANAGEMENT_MANAGER]: 'Chef inom förvaltning',
  [UserTitle.CEO_ASSISTANT]: 'VD-assistent',
  [UserTitle.LESSOR_MANAGER]: 'Uthyrningschef'
}
