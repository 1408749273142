import React, { ReactNode } from 'react'

import { useHistory } from 'react-router-dom'
import { OptionProps } from 'components_new/molecules/OptionsMenu'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import TabSwitch from 'components_new/molecules/TabSwitch'

interface DashboardTabsProps {
  dashboard: string // ID
  dashboards: {
    id: string
    title: string
    hasAccess: boolean
  }[]
  editable: boolean
  onAddTab: () => void
  onDelete: (id: string) => void
  onDuplicate: (id: string) => void
  onEditTitle: (id: string) => void
  onMove: (id: string, dir: number) => void
}

const DashboardTabs = (props: DashboardTabsProps) => {
  const {
    dashboard,
    dashboards,
    editable,
    onAddTab,
    onDelete,
    onDuplicate,
    onEditTitle,
    onMove
  } = props

  const history = useHistory()

  const tabs: {
    value: string
    label: string | ReactNode
    disabled: boolean
    disabledTooltip: string
    menuOptions: OptionProps[] | undefined
  }[] = dashboards.map((dashboard, i) => ({
    value: dashboard.id,
    label: !dashboard.hasAccess ? (
      <>
        <Icon name="VisibilityOffOutlined" sx={{ fontSize: '12px', mr: 1 }} />
        {dashboard.title}
      </>
    ) : (
      dashboard.title
    ),
    disabled: !dashboard.hasAccess,
    disabledTooltip: 'Du saknar tillgång till den här fliken.',
    menuOptions: editable
      ? [
          {
            iconName: 'MoveDownOutlined',
            iconRotation: '90deg',
            onClick: () => onMove(dashboard.id, -1),
            title: 'Flytta vänster',
            disabled: i === 0
          },
          {
            iconName: 'MoveUpOutlined',
            iconRotation: '90deg',
            onClick: () => onMove(dashboard.id, 1),
            title: 'Flytta höger',
            divider: true,
            disabled: i === dashboards.length - 1
          },
          {
            iconName: 'EditOutlined',
            onClick: () => onEditTitle(dashboard.id),
            title: 'Redigera namn'
          },
          {
            iconName: 'ContentCopyOutlined',
            onClick: () => onDuplicate(dashboard.id),
            title: 'Duplicera'
          },
          {
            iconName: 'DeleteOutlined',
            onClick: () => onDelete(dashboard.id),
            title: 'Radera',
            disabled: dashboards.length === 1
          }
        ]
      : undefined
  }))

  return (
    <Box sx={{ display: 'block' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 0 }}>
          <TabSwitch
            color="primary"
            options={tabs}
            value={dashboard}
            onChange={(id) => {
              if (window.location.href.includes('templates')) {
                history.push(`/templates/${id}`)
              } else {
                history.push(`/dashboards/${id}`)
              }
            }}
            noGutter={true}
            maxHeight="56px"
          />
        </Box>
        {editable ? (
          <Box>
            <Tooltip title="Lägg till flik">
              <IconButton onClick={() => onAddTab()} size="small">
                <Icon name="AddCircleOutlineOutlined" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
        <Divider flexItem={true} orientation="vertical" sx={{ mx: 1 }} />
      </Box>
    </Box>
  )
}

export default DashboardTabs
