import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: [],
  invalidCount: 0,
  totalCount: 0,
  tableId: false,
  fetching: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, tableId } = action

  switch (type) {
  case Types.GET_FLAWS:
    return {
      ...state,
      fetching: true
    }
  case Types.GET_FLAWS_SUCCESS:
    return {
      ...state,
      data: payload.data,
      invalidCount: payload.meta.invalid_count,
      totalCount: payload.meta.total_count,
      tableId,
      fetching: false
    }
  case Types.GET_FLAWS_FAILED:
    return {
      ...INITIAL_STATE
    }
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
