import React, { useMemo, useState } from 'react'

import { TreeView } from '@mui/x-tree-view'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import {
  EconomyAccountMap,
  getRangeGroups,
  getRangeSelectionStatus,
  handleToggle
} from './utils'

import AccountTreeItem from './AccountTreeItem'

interface EditVariableAccountsDialogProps {
  accountsMap: EconomyAccountMap
  economySetId: string
  limitToAccounts?: string[]
  onClose: () => void
  onSave: (accounts: string[], callback: () => void) => void
  open: boolean
  selectedAccounts: string[]
}

const EditVariableAccountsDialog = (props: EditVariableAccountsDialogProps) => {
  const {
    accountsMap,
    economySetId,
    limitToAccounts,
    onClose,
    onSave,
    open,
    selectedAccounts
  } = props

  const [selected, setSelected] = useState<string[]>([...selectedAccounts])
  const [loading, setLoading] = useState(false)

  const rangeGroups = useMemo(
    () => getRangeGroups(accountsMap[economySetId], limitToAccounts),
    [limitToAccounts, accountsMap]
  )

  const parsedOnSave = (selected: string[]) => {
    setLoading(true)
    onSave(selected, () => setLoading(false))
  }

  return (
    <AdvancedDialog
      title="Redigera kontoreferenser"
      fullHeight={true}
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            loading={loading}
            onClick={() => parsedOnSave(selected)}
            variant="contained"
          >
            Spara
          </Button>
        </>
      }
    >
      <Box sx={{ position: 'relative', flexGrow: 1 }}>
        <TreeView
          defaultCollapseIcon={<Icon name="ExpandMore" />}
          defaultExpandIcon={<Icon name="ChevronRight" />}
          multiSelect={true}
        >
          {rangeGroups.options.map((i) => (
            <AccountTreeItem
              disabled={i.disabled}
              key={i.range.start}
              nodeId={i.range.label}
              onToggle={() => {
                const newSelected = handleToggle(selected, i.accounts)

                setSelected(newSelected)
              }}
              label={i.range.label}
              selected={getRangeSelectionStatus(selected, i.accounts)}
            >
              {i.options.map((j) => (
                <AccountTreeItem
                  disabled={j.disabled}
                  key={j.range.label}
                  nodeId={j.range.label}
                  onToggle={() => {
                    const newSelected = handleToggle(selected, j.accounts)

                    setSelected(newSelected)
                  }}
                  label={j.range.label}
                  selected={getRangeSelectionStatus(selected, j.accounts)}
                >
                  {j.options.map((k) => (
                    <AccountTreeItem
                      disabled={k.disabled}
                      key={k.range.label}
                      nodeId={k.range.label}
                      onToggle={() => {
                        const newSelected = handleToggle(selected, k.accounts)

                        setSelected(newSelected)
                      }}
                      label={k.range.label}
                      selected={getRangeSelectionStatus(selected, k.accounts)}
                    >
                      {k.accounts.map((fAccount) => (
                        <AccountTreeItem
                          disabled={fAccount.disabled}
                          key={fAccount.number}
                          nodeId={fAccount.number}
                          onToggle={() => {
                            const newSelected = handleToggle(selected, [
                              fAccount
                            ])

                            setSelected(newSelected)
                          }}
                          label={
                            <Box component="span" sx={{ fontWeight: 500 }}>
                              {fAccount.number}
                              {fAccount.name ? ` - ${fAccount.name}` : ''}
                            </Box>
                          }
                          selected={getRangeSelectionStatus(selected, [
                            fAccount
                          ])}
                        />
                      ))}
                    </AccountTreeItem>
                  ))}
                </AccountTreeItem>
              ))}
            </AccountTreeItem>
          ))}
        </TreeView>
      </Box>
    </AdvancedDialog>
  )
}

export default EditVariableAccountsDialog
