import React, { ReactNode, SyntheticEvent } from 'react'
import { Tabs as MUITabs } from '@mui/material'

interface TabsProps {
  allowScrollButtonsMobile?: boolean
  children?: ReactNode
  color?: 'primary' | 'secondary' | 'brand'
  onChange?: (event: SyntheticEvent, newValue: number) => void
  orientation?: 'horizontal' | 'vertical'
  value?: number
  variant?: 'fullWidth' | 'scrollable' | 'standard'
  scrollButtons?: boolean
  sx?: object
  TabScrollButtonProps?: object
}

const Tabs = React.forwardRef((props: TabsProps, ref: any) => {
  const { color, sx, ...rest } = props

  return (
    <MUITabs
      {...rest}
      ref={ref}
      TabIndicatorProps={
        color
          ? {
              sx: { bgcolor: `${color}.main`, color: 'text.primary' }
            }
          : undefined
      }
      sx={{ color: 'text.primary', ...sx }}
      textColor="inherit" // @TODO: Implement color to text as well.
    />
  )
})

Tabs.displayName = 'Tabs'
export default Tabs
