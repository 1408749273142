import React from 'react'
import { Radio } from 'antd'

import Icon from 'components_new/atoms/Icon'

import Styles from './styles.module.css'

const RadioButton = ({
  onChange = () => {},
  className = '',
  options = [],
  value,
  icon,
  defaultValue
}) => {
  return (
    <Radio.Group
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      className={className}
    >
      {options.map((item) => (
        <Radio value={item.value} key={item.key}>
          {item.label}
          {item.value === value && icon ? (
            <Icon name={icon} className={Styles.icon} />
          ) : null}
        </Radio>
      ))}
    </Radio.Group>
  )
}

export default RadioButton
