import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import ShortId from 'shortid'

import { useNodesState, useEdgesState } from 'reactflow'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Spin from 'components_new/atoms/Spin'
import Switch from 'components_new/atoms/Switch'
import Text from 'components_new/atoms/Text'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Tooltip from 'components_new/atoms/Tooltip'

import DrawingBoard from 'components/common/DrawingBoard'
import EditModeBar from 'components/common/EditModeBar'
import Sider from 'components/common/Sider'

import DatasetModal from 'components/containers/DataPlatform/shape/DatasetModal'
import { InfoMessage } from 'components/containers/Applications/DataProducts/ManageDatasetModal/Operations/ManageOperation/conf'

import * as LockedModeActions from 'redux/actions/LockedMode'
import * as AlertActions from 'redux/actions/Alert'
import * as DataProductActions from 'redux/actions/DataPlatform/DataProducts'
import * as StandardModelActions from 'redux/actions/DataPlatform/StandardModels'

import { setChangedOntology } from 'helpers/Storage/Ontology'
import { parseDateString } from 'helpers/Functions/Date'

import ManageRelations from './ManageRelations'
import RelationModal from './RelationModal'

import * as Conf from './conf.js'
import Styles from './styles.module.css'

const OntologyBoard = ({
  setLockedMode,
  setAlert,
  resetAlert,
  DataProductStore,
  tryPutOntology,
  tryStartOntology,
  tryPollOntology,
  AuthStore,
  history,
  QualityStore,
  tryActivateStandardModel,
  tryGetOntology,
  match
}) => {
  const isHomepalUser = AuthStore.user.is_homepal_user

  // Edit mode state handlers
  const [editMode, setEditMode] = useState(false)

  // Data state handlers
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [relationData, setRelationData] = useState({
    target: undefined,
    source: undefined,
    type: 'one_to_one',
    sourceAttributes: [],
    targetAttributes: []
  })
  const [relations, setRelations] = useState({
    linkRelations: [],
    actualRelations: []
  })

  // Modal state handlers
  const [showAddDatasetModal, setShowAddDatasetModal] = useState(false)
  const [manageRelations, setManageRelations] = useState(false)
  const [putRelations, setPutRelations] = useState(false)
  const [editRelation, setEditRelation] = useState(false)

  // View toggle state handlers
  const [showDetails, setShowDetails] = useState(true)
  const [showRelations, setShowRelations] = useState(true)
  const [showQuality, setShowQuality] = useState(true)
  const [showLinkTables, setShowLinkTables] = useState(true)
  const showObject = { showDetails, showRelations, showQuality, showLinkTables }
  const [board, setBoard] = useState(null)

  // Other state handlers
  const [loaded, setLoaded] = useState(false)
  const [pollingIntervalId, setPollingIntervalId] = useState(null)

  const currentOntology = match.params.type

  const onEdgeClick = (params) => {
    setEditRelation(true)
    setPutRelations(true)
    setRelationData(params)
  }

  useEffect(() => {
    // Fetch models if they're not already fetched
    if (
      currentOntology === 'custom' &&
      !DataProductStore.fetchedCustomOntology
    ) {
      tryGetOntology(currentOntology)
    } else if (
      currentOntology === 'standard' &&
      !DataProductStore.fetchedStandardOntology
    ) {
      tryGetOntology(currentOntology)
    }

    // Load data into board
    if (
      ((currentOntology === 'custom' &&
        DataProductStore.fetchedCustomOntology) ||
        (currentOntology === 'standard' &&
          DataProductStore.fetchedStandardOntology)) &&
      board
    ) {
      Conf.getInitialElements(
        getOntology(currentOntology, DataProductStore),
        history,
        QualityStore.data,
        onEdgeClick,
        showObject,
        setRelations,
        setNodes,
        setEdges,
        board,
        setLoaded
      )
    } else if (
      currentOntology === 'standard' &&
      !DataProductStore.hasStandard
    ) {
      setNodes([])
      setEdges([])
    }
  }, [
    currentOntology,
    DataProductStore.customOntology,
    DataProductStore.standardOntology,
    board,
    DataProductStore.activateStandard,
    DataProductStore.hasStandard
  ])

  useEffect(() => {
    if (loaded) {
      board.fitView()
    }
  }, [loaded])

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((e) => ({
        ...e,
        data: {
          ...e.data,
          ...showObject,
          selectedRelationAttribute: !showRelations
            ? undefined
            : e.data.selectedRelationAttribute
        }
      }))
    )

    setEdges((edges) =>
      edges.map((e) => ({
        ...e,
        data: {
          ...e.data,
          ...showObject,
          selectedRelationAttribute: !showRelations
            ? undefined
            : e.data.selectedRelationAttribute
        }
      }))
    )
  }, [showDetails, showRelations, showQuality])

  useEffect(() => {
    setNodes((els) => {
      return els.map((e) => ({
        ...e,
        data: {
          ...e.data,
          ...showObject,
          selectedRelationAttribute: undefined
        }
      }))
    })

    setEdges((els) => {
      let newElements = els

      if (showLinkTables) {
        newElements = newElements.filter(
          (el) => !(el.data.type === 'many_to_many')
        )
        newElements.push(...relations.linkRelations)
      } else {
        const edgeRelationIds = relations.linkRelations.map((lr) => lr.id)

        newElements = newElements.filter(
          (el) => !edgeRelationIds.includes(el.id)
        )
        newElements.push(
          ...relations.actualRelations.filter(
            (rel) => rel.data.type === 'many_to_many'
          )
        )
      }

      return newElements.map((e) => ({
        ...e,
        data: {
          ...e.data,
          ...showObject,
          selectedRelationAttribute: undefined
        }
      }))
    })
  }, [showLinkTables])

  const currentOntologyFetched =
    currentOntology === 'custom'
      ? DataProductStore.fetchedCustomOntology
      : DataProductStore.fetchedStandardOntology

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%'
      }}
    >
      <Box
        id={'edit-container'}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <EditModeBar
          disabledEditMode={currentOntology === 'standard'}
          hideEditOptions={!isHomepalUser}
          addButtonText={'Lägg till entitet'}
          actions={
            <>
              <Button
                disabled={!editMode}
                onClick={() => setManageRelations(true)}
                startIcon={<Icon name="LinkOutlined" />}
                variant="text"
              >
                Hantera relationer
              </Button>
              <ImportSection
                tryStartOntology={tryStartOntology}
                editMode={editMode}
                ontology={getOntology(currentOntology, DataProductStore)}
                tryPollOntology={tryPollOntology}
                pollingIntervalId={pollingIntervalId}
                setPollingIntervalId={setPollingIntervalId}
                currentOntology={currentOntology}
                isDemo={AuthStore.isDemo}
              />
            </>
          }
          onOk={() =>
            Conf.getInitialElements(
              getOntology(currentOntology, DataProductStore),
              history,
              QualityStore.data,
              onEdgeClick,
              showObject,
              setRelations,
              setNodes,
              setEdges,
              board
            )
          }
          editMode={editMode}
          setEditMode={setEditMode}
          setAlert={setAlert}
          resetAlert={resetAlert}
          setLockedMode={setLockedMode}
          useCallbackOnSave
          onSave={(callbackOnOk) =>
            setAlert({
              isOpen: true,
              header: 'Spara ändringar',
              content: 'Är du säker på att du vill spara dina ändringar?',
              cancelText: 'Avbryt',
              okText: 'Spara',
              onOk: () => {
                const tables = nodes.map((el) => ({
                  id: el.data.id,
                  attributes: {
                    name: el.data.name,
                    technical_name: el.data.technicalName,
                    plural: el.data.plural,
                    is_ontology: true,
                    locked: el.data.locked || false,
                    location_x: el.position.x,
                    location_y: el.position.y,
                    short_id: el.id,
                    output_attributes: el.data.columns.map((col) => ({
                      name: col.name,
                      real_name: col.realName,
                      type: col.type
                    }))
                  }
                }))

                const relationsToAdd = relations.actualRelations.map((rel) => ({
                  type: rel.data.type,
                  left_table_id: rel.data.source,
                  right_table_id: rel.data.target,
                  left_attribute_id: rel.data.leftAttributeId,
                  right_attribute_id: rel.data.rightAttributeId,
                  link_table_id: rel.data.linkTableId,
                  is_primary: rel.data.isPrimary
                }))

                const data = {
                  attributes: {
                    tables,
                    relations: relationsToAdd
                  }
                }

                tryPutOntology({ data }, currentOntology)
                resetAlert()
                callbackOnOk()
                setChangedOntology()
              }
            })
          }
          onAdd={() => setShowAddDatasetModal(true)}
          secondaryNavbar={
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Text color="text.secondary" variant="subtitle2">
                  Visa:
                </Text>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Switch
                    checked={showDetails}
                    onChange={() => setShowDetails(!showDetails)}
                    size="small"
                  />
                  Detaljer
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Switch
                    checked={showRelations}
                    onChange={() => setShowRelations(!showRelations)}
                    size="small"
                  />
                  Relationer
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Switch
                    checked={showQuality}
                    onChange={() => setShowQuality(!showQuality)}
                    size="small"
                  />
                  Kvalitet
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Switch
                    checked={showLinkTables}
                    onChange={() => setShowLinkTables(!showLinkTables)}
                    size="small"
                  />
                  Hjälptabeller
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button onClick={() => board.fitView()} variant="text">
                  Centrera
                </Button>
                {isHomepalUser ? (
                  <Button
                    disabled={!editMode}
                    onClick={() => Conf.layout(setNodes, 'TB', showDetails)}
                    variant="text"
                  >
                    Vertikalt
                  </Button>
                ) : null}
                {isHomepalUser ? (
                  <Button
                    disabled={!editMode}
                    onClick={() => Conf.layout(setNodes, 'LR', showDetails)}
                    variant="text"
                  >
                    Horisontellt
                  </Button>
                ) : null}
              </Box>
            </Box>
          }
        />
        {currentOntologyFetched ? (
          <Box sx={{ flex: 1 }}>
            <DrawingBoard
              onLoad={(board) => setBoard(board)}
              onNodeDragStop={(_, node) => Conf.updatePosition(node, setNodes)}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              className={editMode ? Styles['edit-container'] : null}
              editMode={editMode || currentOntology === 'standard'}
            />
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spin size="large" className={Styles.spin} />
          </Box>
        )}
        <DatasetModal
          visible={showAddDatasetModal}
          onClose={() => setShowAddDatasetModal(false)}
          onFinish={(values) => {
            const newNodes = _.cloneDeep(nodes)
            const nodeId = ShortId.generate()

            const position = { x: 0, y: 0 }

            nodes.forEach((n) => {
              position.x += n.position.x / nodes.length
              position.y += n.position.y / nodes.length
            })

            newNodes.push({
              type: 'ontology',
              id: nodeId,
              data: {
                name: values.name,
                technicalName: values.technicalName,
                plural: values.plural,
                primaryKey: undefined,
                columns: [],
                onClick: () => {}
              },
              position
            })

            setNodes(newNodes)
            setShowAddDatasetModal(false)
          }}
          datasets={nodes.map((item) => ({ ...item.data, isOntology: true }))}
          withRedux={false}
          initialValues={{
            id: undefined,
            name: undefined,
            technicalName: undefined,
            plural: undefined,
            isOntology: true
          }}
        />
        <ManageRelations
          visible={manageRelations}
          onClose={() => setManageRelations(false)}
          shapeRelations={relations.actualRelations}
          onEdit={(params) => {
            setRelationData(params.data)

            setEditRelation(true)
            setPutRelations(true)
          }}
          onRemove={(elementIdsToRemove, onOk) => {
            setAlert({
              header: 'Ta bort relation',
              content:
                'Är du säker på att du vill ta bort alla markerade relationer?',
              onCancel: () => resetAlert(),
              onOk: () => {
                const elementsToRemove = elementIdsToRemove
                  .map((el) => {
                    const relation = relations.actualRelations.find(
                      (rel) => rel.id === el
                    )

                    if (relation.data.type === 'many_to_many') {
                      return edges.filter(
                        (e) =>
                          e.id === `${relation.id}-0` ||
                          e.id === `${relation.id}-1` ||
                          e.id === relation.data.linkTableId
                      )
                    } else {
                      return relation
                    }
                  })
                  .flat()

                Conf.onElementsRemove(elementsToRemove, relations, setRelations)

                resetAlert()
                setPutRelations(false)
                onOk()
              },
              cancelText: 'Avbryt',
              okText: 'Ta bort'
            })
          }}
          onAdd={() => {
            setRelationData({
              target: undefined,
              source: undefined,
              type: 'one_to_one',
              sourceAttributes: [],
              targetAttributes: []
            })
            setEditRelation(false)
            setPutRelations(true)
          }}
          currentOntology={currentOntology}
        />
        <RelationModal
          visible={putRelations}
          data={relationData}
          onClose={() => setPutRelations(false)}
          onSave={(params) => {
            const { newNodes, newEdges, newRelations } = Conf.updateBoard(
              nodes,
              edges,
              relations,
              params,
              onEdgeClick,
              showObject
            )

            if (params.isPrimary) {
              Conf.updateRelationsToSecondary(
                params.source,
                params.target,
                params.edgeId,
                newEdges,
                setEdges,
                newRelations,
                setRelations
              )
            } else {
              setNodes(newNodes)
              setEdges(newNodes)
              setRelations(newRelations)
            }

            setPutRelations(false)
          }}
          onRemove={(params) => {
            setAlert({
              header: 'Ta bort relation',
              content: 'Är du säker på att du vill ha bort den här relationen?',
              onCancel: () => resetAlert(),
              onOk: () => {
                const relation = relations.actualRelations.find(
                  (rel) => rel.id === params.edgeId
                )

                if (relation.data.type === 'many_to_many') {
                  Conf.onElementsRemove(
                    edges.filter(
                      (e) =>
                        e.id === `${relation.id}-0` ||
                        e.id === `${relation.id}-1` ||
                        e.id === relation.data.linkTableId
                    ),
                    relations,
                    setRelations
                  )
                } else {
                  Conf.onElementsRemove([relation], relations, setRelations)
                }

                resetAlert()
                setPutRelations(false)
              },
              cancelText: 'Avbryt',
              okText: 'Ta bort'
            })
          }}
          edit={editRelation}
          setAlert={setAlert}
          resetAlert={resetAlert}
          edges={edges}
          currentOntology={currentOntology}
        />
      </Box>

      <Sider
        options={[
          {
            icon: 'AccountTreeOutlined',
            header: 'Ändra informationsmodell',
            index: 0,
            content: (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <InfoMessage
                  infoText={
                    // eslint-disable-next-line max-len
                    'Välj vilken informationsmodell du vill få en överblick av. Om du inte har en standardmodell aktiverad, så kan du göra det under "Standard"-fliken.'
                  }
                />
                <ToggleButton
                  items={[
                    { title: 'Anpassad', value: 'custom' },
                    { title: 'Standard', value: 'standard' }
                  ]}
                  onChange={(event, value) => {
                    history.push(`/data-platform/ontology/${value}`)
                  }}
                  size="small"
                  value={currentOntology}
                />
                {currentOntology === 'standard' &&
                  isHomepalUser &&
                  _.isEmpty(DataProductStore.standardOntology) &&
                  !DataProductStore.hasStandard && (
                  <Button onClick={() => tryActivateStandardModel()}>
                      Aktivera standardmodell
                  </Button>
                )}
              </Box>
            )
          }
        ]}
      />
    </Box>
  )
}

const ImportSection = ({
  editMode,
  ontology,
  tryStartOntology,
  tryPollOntology,
  pollingIntervalId,
  setPollingIntervalId,
  currentOntology,
  isDemo
}) => {
  if (!ontology.attributes?.latest_run) {
    return null
  }

  const { latest_run } = ontology.attributes
  let title = ''
  let icon = undefined
  let disabled = editMode

  useEffect(() => {
    if (latest_run.state === 'RUNNING' || latest_run.state === 'STARTING') {
      if (!pollingIntervalId) {
        let intervalId = null

        intervalId = setInterval(() => {
          tryPollOntology(ontology.id, currentOntology)
        }, 7000)

        setPollingIntervalId(intervalId)
      }
    } else {
      clearInterval(pollingIntervalId)

      setPollingIntervalId(null)
    }
  }, [ontology])

  if (latest_run.state === 'SUCCEEDED') {
    title = parseDateString(latest_run.completed_on)
    icon = <Icon name="CheckCircleOutline" />
  } else if (latest_run.state === 'FAILED') {
    title = parseDateString(latest_run.completed_on)
    icon = <Icon name="WarningAmberOutlined" />
  } else if (
    latest_run.state === 'RUNNING' ||
    latest_run.state === 'STARTING'
  ) {
    disabled = true
    icon = <Spin />
  }

  if (isDemo) {
    disabled = true
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Button
        disabled={disabled}
        onClick={() => tryStartOntology(ontology.id, currentOntology)}
        variant="text"
        startIcon={<Icon name="Sync" />}
      >
        Importera
      </Button>
      <Tooltip title="Senaste import av data">
        <Chip clickable icon={icon} label={title} size="small" />
      </Tooltip>
    </Box>
  )
}

function mapStateToProps({ DataProductStore, AuthStore, QualityStore }) {
  return { DataProductStore, AuthStore, QualityStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...LockedModeActions,
      ...AlertActions,
      ...DataProductActions,
      ...StandardModelActions
    },
    dispatch
  )
}

const getOntology = (currentOntology, DataProductStore) =>
  currentOntology === 'custom'
    ? DataProductStore.customOntology
    : DataProductStore.standardOntology

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OntologyBoard))
