import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/sv'

import { DateCalendar as MUIDateCalendar } from '@mui/x-date-pickers/DateCalendar'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

interface DateCalendarProps {
  className?: string
  maxDate?: string
  minDate?: string
  onChange: (value: string | null) => void
  views?: ('day' | 'month' | 'year')[]
  value: string | null
}

const DateCalendar = React.forwardRef((props: DateCalendarProps, ref: any) => {
  const { maxDate, minDate, onChange, value, ...rest } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sv'}>
      <MUIDateCalendar
        {...rest}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        minDate={minDate ? dayjs(minDate) : undefined}
        onChange={(value: Dayjs | null) =>
          onChange(value?.format('YYYY-MM-DD') ?? null)
        }
        ref={ref}
        value={value ? dayjs(value) : null}
      />
    </LocalizationProvider>
  )
})

DateCalendar.displayName = 'DateCalendar'
export default DateCalendar
