import React, { useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AuthStoreActions from 'redux/actions/Authentication'
import * as SelfActions from 'redux/actions/Self'

import { PatchSelfBody } from 'types/GlobalUser'

import { SxProps, useMediaQuery, useTheme } from '@mui/material'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import EditProfileDialog from './dialogs/EditProfileDialog'

import { getProfileAvatarSrc } from './utils'

import Loading from './loading'

interface ProfileAvatarProps {
  sx?: SxProps
}

const ProfileAvatar = React.forwardRef((props: ComponentProps, ref) => {
  const {
    sx,
    // redux stores
    AuthStore
  } = props

  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false)

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLAnchorElement | null>(
    null
  )
  const menuOpen = Boolean(menuAnchorEl)

  const handleClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  // loading
  if (Boolean(!AuthStore.user)) {
    return <Loading />
  }

  // success
  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', ...sx }}>
      <Tooltip
        title={
          <>
            {`${AuthStore.user?.first_name} ${AuthStore.user?.last_name}`}
            <br />
            {AuthStore.user?.email}
          </>
        }
        arrow
        placement="bottom"
      >
        <Paper ref={ref} sx={{ height: '100%' }} variant="outlined">
          <CardActionArea onClick={handleClick}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                p: 1,
                gap: 1
              }}
            >
              <Avatar
                alt={`${AuthStore.user?.first_name} ${AuthStore.user?.last_name}`}
                src={getProfileAvatarSrc(AuthStore)}
                sx={{
                  width: 30,
                  height: 30,
                  fontSize: 15,
                  WebkitFilter: 'grayscale(100%)',
                  filter: 'grayscale(100%)'
                }}
              />
              <Collapse in={lg} orientation="horizontal" unmountOnExit={true}>
                <Text noWrap={true} variant="subtitle2">
                  {`${AuthStore.user?.first_name} 
                ${AuthStore.user?.last_name}`}
                </Text>
              </Collapse>
            </Box>
          </CardActionArea>
        </Paper>
      </Tooltip>

      {/* ----- Menu ----- */}
      <Menu
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        open={menuOpen}
        disableScrollLock
        PaperProps={{
          sx: {
            width: 300
          }
        }}
      >
        <MenuItem
          onClick={() => {
            setEditProfileDialogOpen(true)
            handleClose()
          }}
        >
          <ListItemIcon>
            <Icon name="EditTwoTone" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Redigera profil" />
        </MenuItem>
        <MenuItem onClick={() => props.signOut()}>
          <ListItemIcon>
            <Icon name="LogoutTwoTone" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logga ut" />
        </MenuItem>
      </Menu>

      {/* ----- Dialogs ----- */}
      <EditProfileDialog
        user={AuthStore.user}
        open={editProfileDialogOpen}
        onClose={() => setEditProfileDialogOpen(false)}
        tryUpdateSelf={props.tryUpdateSelf}
      />
    </Box>
  )
})

/*-- redux --*/
interface ComponentDispatchProps {
  signOut(): (dispatch: Dispatch) => void
  tryUpdateSelf(body: PatchSelfBody): (dispatch: Dispatch) => void
}

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions,
      ...SelfActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ComponentDispatchProps &
  ProfileAvatarProps

export default connector(ProfileAvatar)
