export enum RENTAL_STATUS {
  STOPPED = 'Spärrad',
  RENTABLE = 'Ledig',
  RENTED = 'Uthyrd',
  TERMINATED = 'Uppsagd'
}

export enum ADVERTISING_STATUS {
  NOT_ADVERTISED = 'Ej annonserad',
  ADVERTISED = 'Annonserad',
  SIGNED_LEASE = 'Signerat avtal',
  DRAFT_LEASE = 'Utkast till avtal'
}

export enum KPI_UNIT {
  SEK = 'SEK',
  PERCENTAGE = '%'
}
