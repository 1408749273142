import React, { useEffect, useRef, useState } from 'react'

import ShortId from 'shortid'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Select from 'components/common/Select'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import * as Constants from 'helpers/Constants'

import {
  CARDINALITY_OPTIONS,
  getOntologyLinkTableIds,
  getOntologyTables,
  hasPrimaryRelations,
  RELATION_PRIORITY_OPTIONS
} from './conf'
import Styles from './styles.module.css'

const RelationModal = ({
  visible,
  data,
  onClose,
  onSave,
  edit,
  onRemove,
  DataProductStore,
  setAlert,
  resetAlert,
  edges,
  currentOntology
}) => {
  const form = useRef(null)

  const [sourceAttributes, setSourceAttributes] = useState(
    data.sourceAttributes
  )

  const [targetAttributes, setTargetAttributes] = useState(
    data.targetAttributes
  )

  const linkTableIds = getOntologyLinkTableIds(
    currentOntology,
    DataProductStore
  )

  const tables = getOntologyTables(currentOntology, DataProductStore).filter(
    (table) => !linkTableIds.includes(table.id)
  )

  const sourceTable = edit
    ? tables.find((t) => t.id === data.source)
    : undefined
  const targetTable = edit
    ? tables.find((t) => t.id === data.target)
    : undefined

  useEffect(() => {
    if (visible && form.current) {
      if (!edit) {
        form.current.setFieldsValue({
          leftAttributeId: undefined,
          rightAttributeId: undefined,
          leftTableId: undefined,
          rightTableId: undefined,
          type: 'one_to_one',
          isPrimary: false
        })
      } else {
        form.current.setFieldsValue({
          leftAttributeId: data.leftAttributeId,
          rightAttributeId: data.rightAttributeId,
          type: data.type,
          isPrimary: data.isPrimary
        })
      }

      setSourceAttributes(data.sourceAttributes)
      setTargetAttributes(data.targetAttributes)
    }
  }, [visible])

  return tables.length > 0 ? (
    <Modal
      visible={visible}
      customHeader={
        edit ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Text variant="body1">Redigera relation</Text>
            <Button color="error" onClick={() => onRemove(data)} variant="text">
              Ta bort
            </Button>
          </Box>
        ) : (
          <Text variant="body1">Lägg till relation</Text>
        )
      }
      onClose={() => {
        onClose()
        form.current.resetFields()
      }}
      zIndex={1001}
      size={'small'}
      contentClassName={Styles['relation-modal-content']}
      footerClassName={Styles['relation-modal-footer-container']}
      actions={
        <>
          <Button
            onClick={() => {
              onClose()
              form.current.resetFields()
            }}
            variant="text"
          >
            Avbryt
          </Button>
          <Button onClick={() => form.current.submit()}>Spara</Button>
        </>
      }
    >
      <Form
        layout={'vertical'}
        ref={form}
        initialValues={data}
        onValuesChange={(values) => {
          if ('leftTableId' in values) {
            if (values.leftTableId) {
              const table = tables.find((t) => t.id == values.leftTableId)

              setSourceAttributes(table.attributes.output_attributes)
            } else {
              setSourceAttributes([])
            }
          }

          if ('rightTableId' in values) {
            if (values.rightTableId) {
              const table = tables.find((t) => t.id == values.rightTableId)

              setTargetAttributes(table.attributes.output_attributes)
            } else {
              setTargetAttributes([])
            }
          }
        }}
        onFinish={(values) => {
          if (edit) {
            onSave({
              ...data,
              ...values
            })
          } else {
            let calculatedOffset = 1

            edges.forEach((elem) => {
              if (
                (elem.source === values.leftTableId &&
                  elem.target === values.rightTableId) ||
                (elem.target === values.leftTableId &&
                  elem.source === values.rightTableId)
              ) {
                calculatedOffset += elem.data.isPrimary ? 0 : 1
              }
            })

            onSave({
              ...values,
              sourceAttributes,
              targetAttributes,
              source: values.leftTableId,
              target: values.rightTableId,
              edgeId: ShortId.generate(),
              offset: calculatedOffset
            })

            setSourceAttributes([])
            setTargetAttributes([])
          }

          form.current.resetFields()
        }}
      >
        <Text variant="body1">
          {edit
            ? 'Välj attribut som relaterar till varandra'
            : 'Välj entitet och attribut som relaterar till varandra'}
        </Text>

        <Box sx={{ display: 'flex', width: '100%', py: 2 }}>
          <Box sx={{ flex: 1 }}>
            {edit ? (
              <Text variant="body1">{sourceTable.attributes.name}</Text>
            ) : (
              <Form.Item
                name={'leftTableId'}
                rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                className={'width-100'}
              >
                <Select
                  size={'small'}
                  placeholder="Välj entitet..."
                  options={tables
                    .filter(
                      (t) =>
                        form.current &&
                        t.id !== form.current.getFieldsValue().rightTableId
                    )
                    .map((t) => ({
                      value: t.id,
                      label: t.attributes.name
                    }))}
                />
              </Form.Item>
            )}
            <Form.Item
              name={'leftAttributeId'}
              rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
              className={'width-100'}
            >
              <Select
                size={'small'}
                placeholder="Välj attribut..."
                options={sourceAttributes.map((attr) => ({
                  value: attr.id,
                  label: attr.attributes.name
                }))}
              />
            </Form.Item>
          </Box>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          <Box sx={{ flex: 1 }}>
            {edit ? (
              <Text variant="body1">{targetTable.attributes.name}</Text>
            ) : (
              <Form.Item
                name={'rightTableId'}
                rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                className={'width-100'}
              >
                <Select
                  size={'small'}
                  placeholder="Välj entitet..."
                  options={tables
                    .filter(
                      (t) =>
                        form.current &&
                        t.id !== form.current.getFieldsValue().leftTableId
                    )
                    .map((t) => ({
                      value: t.id,
                      label: t.attributes.name
                    }))}
                />
              </Form.Item>
            )}
            <Form.Item
              name={'rightAttributeId'}
              rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
              className={'width-100'}
            >
              <Select
                size={'small'}
                placeholder="Välj attribut..."
                options={targetAttributes.map((attr) => ({
                  value: attr.id,
                  label: attr.attributes.name
                }))}
                ignoreLockedMode
              />
            </Form.Item>
          </Box>
        </Box>

        <Box>
          <Form.Item
            name={'type'}
            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          >
            <Select label={'Kardinalitet'} options={CARDINALITY_OPTIONS} />
          </Form.Item>

          <Form.Item
            name={'isPrimary'}
            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          >
            <Select
              label={'Prioritet'}
              options={RELATION_PRIORITY_OPTIONS}
              onChange={(isPrimary) => {
                const formValues = form.current.getFieldsValue()

                if (
                  isPrimary &&
                  hasPrimaryRelations(
                    sourceTable?.id || formValues.leftTableId,
                    targetTable?.id || formValues.rightTableId,
                    edges
                  )
                ) {
                  setAlert({
                    isOpen: true,
                    header: 'Ändra till primär relation',
                    content: (
                      <Text variant="body1">
                        Vill du verkligen ändra relationens prioritet till{' '}
                        <b>primär relation</b>? Den relation som är primär just
                        nu kommer då att ändras till sekundär.
                      </Text>
                    ),
                    cancelText: 'Avbryt',
                    okText: 'Ändra',
                    onOk: () => {
                      form.current.setFieldValue('isPrimary', true)
                      resetAlert()
                    },
                    onCancel: () => {
                      form.current.setFieldValue('isPrimary', false)
                      resetAlert()
                    }
                  })
                }
              }}
            />
          </Form.Item>
        </Box>
      </Form>
    </Modal>
  ) : null
}

function mapStateToProps({ DataProductStore }) {
  return { DataProductStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RelationModal)
