import React from 'react'

import { Menu } from 'antd'

import Icon from 'components_new/atoms/Icon'

import Styles from './styles.module.css'

export const menu = ({
  items = [],
  header,
  iconItems,
  subHeader,
  ignoreLockedMode,
  setVisible
}) => (
  <AntdMenu>
    {header ? (
      <div>
        <Menu.ItemGroup
          title={
            <p className={`${iconItems ? 'secondary uppercase' : ''}`}>
              {header}
            </p>
          }
          className={Styles['dropdown-button-header']}
        />
        <Menu.ItemGroup
          title={<small className={'bold'}>{subHeader}</small>}
          className={Styles['dropdown-button-sub-header']}
        />
        <Menu.Divider />
      </div>
    ) : null}
    {items.map((item, i) => (
      <Menu.ItemGroup
        key={item.key}
        title={
          <small className={'secondary uppercase font-weight-700'}>
            {item.header}
          </small>
        }
        className={item.header || header ? '' : Styles['no-header']}
      >
        {i > 0 && <Menu.Divider />}
        {item.items.map((i, index) => (
          <Menu.Item
            className={`${ignoreLockedMode ? 'ignore-locked-mode' : ''} ${
              Styles.item
            } ${i.disabled ? 'disabled' : ''} ${
              i.remove
                ? i.disabled
                  ? Styles['disabled-remove']
                  : Styles.remove
                : ''
            } ${iconItems ? Styles['item-icon-container'] : ''}`}
            key={`${item.key}-sub-${index}`}
            onClick={() => {
              i.onClick()
              if (setVisible) {
                setVisible(false)
              }
            }}
            disabled={i.disabled}
          >
            <div>
              <div
                className={`${iconItems ? Styles['item-icon-wrapper'] : ''} ${
                  i.faIcon ? Styles['fa-icon-wrapper'] : ''
                }`}
              >
                {i.icon && (
                  <Icon
                    name={i.icon}
                    selected={i.selected}
                    className={Styles['item-icon']}
                  />
                )}
                {i.faIcon && <Icon name={i.faIcon} className={'primary'} />}
                <p
                  className={`${
                    i.remove
                      ? i.disabled
                        ? Styles['disabled-remove']
                        : Styles.remove
                      : i.disabled
                        ? 'secondary'
                        : ''
                  } ${i.remove} ${iconItems ? 'font-weight-500' : ''}`}
                >
                  {i.label}
                </p>
              </div>
              {i.sublabel && (
                <small
                  className={`secondary ${
                    iconItems ? Styles['item-icon-sublabel'] : ''
                  }`}
                >
                  {i.sublabel}
                </small>
              )}
            </div>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    ))}
  </AntdMenu>
)

const AntdMenu = (props) => {
  const { children } = props

  return (
    <Menu selectedKeys={[]} onClick={() => {}}>
      {children}
    </Menu>
  )
}
