import React, { MouseEventHandler, ReactNode } from 'react'
import { TableSortLabel as MUITableSortLabel } from '@mui/material'

export interface TableSortLabelProps {
  active?: boolean
  children?: ReactNode
  direction?: 'asc' | 'desc'
  hideSortIcon?: boolean
  onClick?: (e: React.MouseEvent<unknown>) => void
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  sx?: object
}

const TableSortLabel = React.forwardRef(
  (props: TableSortLabelProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUITableSortLabel {...rest} ref={ref}>
        {children}
      </MUITableSortLabel>
    )
  }
)

TableSortLabel.displayName = 'TableSortLabel'
export default TableSortLabel
