import React from 'react'

import Styles from './styles.module.css'
import Tooltip from 'components_new/atoms/Tooltip'

// sizing large, small
const ProgressBar = ({
  percent,
  hoverTitle,
  hoverContent,
  size = 'large',
  color,
  leftoverColor,
  hideText,
  hideHover,
  hideHoverTitle
}) => {
  let fillClassName = Styles.fill
  const outerText = percent < 10

  if (percent === 100) {
    fillClassName += ` ${Styles['fill-100']}`
  }

  if (outerText) {
    fillClassName += ` ${Styles['fill-text-outer']}`
  }

  const content = (
    <div
      className={
        size === 'large'
          ? Styles.container
          : size === 'small'
            ? Styles['small-container']
            : Styles['medium-container']
      }
      style={{ background: leftoverColor }}
    >
      <div
        className={fillClassName}
        style={{ width: `${percent}%`, background: color }}
      >
        {outerText || hideText ? null : (
          <small className={Styles.text}>{percent}%</small>
        )}
      </div>
      {outerText && !hideText ? (
        <small className={Styles['text-outer']}>{percent}%</small>
      ) : null}
    </div>
  )

  return hideHover ? (
    content
  ) : (
    <Tooltip
      title={
        <>
          {hideHoverTitle ? undefined : hoverTitle || `${percent}%`}
          {hoverContent}
        </>
      }
    >
      {content}
    </Tooltip>
  )
}

export default ProgressBar
