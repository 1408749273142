import React, { FC } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import LicenseTemplate from 'components_new/templates/settings/License'

import * as KPITemplateActions from 'redux/actions/KPITemplates'

import { ApplicationState } from 'redux/Stores/types'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...KPITemplateActions }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type LicensePageProps = ConnectedProps<typeof connector>

const LicensePage: FC<LicensePageProps> = (props: LicensePageProps) => {
  const { AuthStore, KPITemplateStore, tryGetAllKPIs } = props

  if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
    tryGetAllKPIs()
  }

  return (
    <SettingsTemplate>
      {AuthStore.customer && KPITemplateStore.fetchedKpis ? (
        <LicenseTemplate
          customer={AuthStore.customer}
          kpiTemplates={KPITemplateStore.data}
          kpiTemplatesFetched={KPITemplateStore.fetchedKpis}
        />
      ) : (
        <LoadingSettings
          titles={[
            'Hämtar information om din licens',
            'Kontrollerar dina inställningar'
          ]}
        />
      )}
    </SettingsTemplate>
  )
}

export default connector(LicensePage)
