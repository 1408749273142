export const LOADING_WIDGET_TITLES = [
  'Samlar in data från dina system',
  'Synkroniserar och bearbetar din data',
  'Utför de beräkningar som krävs',
  'Gör din data enkel och begriplig',
  'Försöker presentera din data'
]

export const CHECKING_KPI_DATA_TITLES = [
  'Analyserar din uppkopplade data',
  'Ser om all data finns',
  'Undersöker om du kan skapa detta nyckeltal'
]

export const LOADING_KPI_TITLES = [
  'Samlar in data från dina system',
  'Laddar in nyckeltal',
  'Analyserar vilka nyckeltal du kan skapa'
]

export const LOADING_FILTERS_TITLES = [
  'Analyserar vilka filter du kan använda',
  'Laddar in tillgängliga filter',
  'Synkroniserar med din data'
]
