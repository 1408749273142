import * as Types from 'redux/Types'

const INITIAL_STATE = {
  locked: false,
  ignoreId: null,
  allowedSubPaths: [],
  mainPath: null,
  alert: {
    header: 'Överge data',
    content:
      // eslint-disable-next-line max-len
      'Du har osparade ändringar och kommer att förlora alla nya ändringar genom att fortsätta.',
    okText: 'Fortsätt',
    onOk: () => {},
    cancelText: 'Avbryt',
    contentHeader: 'Osparade ändringar',
    faIcon: 'faExclamationTriangle'
  }
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, onOk } = action

  switch (type) {
  case Types.SET_LOCKED_MODE:
    return {
      ...state,
      ...payload,
      alert: {
        ...state.alert,
        ...payload.alert,
        onOk: () => {
          if (payload.alert && payload.alert.onOk) {
            payload.alert.onOk()
          }

          onOk()
        }
      }
    }
  case Types.SIGN_OUT:
    return INITIAL_STATE
  default:
    return state
  }
}
