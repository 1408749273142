import React from 'react'

import Alert from 'components_new/atoms/Alert'
import Link from 'components_new/atoms/Link'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import { TEmbedType } from './EmbedSection'

interface PublicStatusProps {
  editable: boolean
  embedType: TEmbedType
  isPublic: boolean
  onRedirect?: () => void
}

const PublicStatus = (props: PublicStatusProps) => {
  const { editable, embedType, isPublic, onRedirect } = props

  return (
    <>
      <Alert
        severity={isPublic ? 'success' : 'info'}
        icon={<Icon name={isPublic ? 'PublicOutlined' : 'PublicOffOutlined'} />}
      >
        {isPublic ? (
          <>
            <strong>Publik {embedType}.</strong> Alla som har tillgång till den
            publika länken kan se den.{' '}
          </>
        ) : (
          <>
            <strong>Privat {embedType}.</strong> Bara inloggade användare med
            åtkomst kan se den.{' '}
          </>
        )}
        Ändra publik åtkomst under{' '}
        <Tooltip
          title={
            'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
          }
          disableHoverListener={editable}
        >
          <Link color="inherit" disabled={!editable} onClick={onRedirect}>
            inbäddningssektionen
          </Link>
        </Tooltip>
        .
      </Alert>
    </>
  )
}

export default PublicStatus
