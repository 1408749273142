import React, { useEffect, useMemo } from 'react'

import ShortId from 'shortid'

import Node from '../index.js'

import Box from 'components_new/atoms/Box'

import InnerFilterContent from './InnerFilterContent.js'

const FilterNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )
  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  useEffect(() => {
    if (leftAttributes.length > 0) {
      const realNamesInOutAttributes = data.outputAttributes
        .filter((attr) => attr.shortId !== data.newAttribute)
        .map((attr) => attr.realName)

      const diffLeftAttributes = leftAttributes
        .filter((attr) => !realNamesInOutAttributes.includes(attr.realName))
        .map((attr) => attr)

      if (diffLeftAttributes.length > 0) {
        setData(id, {
          outputAttributes: leftAttributes.map((attr) => ({
            ...attr,
            leftAttribute: null,
            rightAttribute: null,
            shortId: ShortId.generate()
          })),
          filterConditions: data.filterConditions.map((cond) => ({
            ...cond,
            leftAttribute: null,
            rightAttribute: null
          }))
        })
      }
    }
  }, [leftAttributes])

  return (
    <Node icon="FilterAltOutlined" title={'Filter'} selected={selected}>
      <Box sx={{ width: '272px' }}>
        <InnerFilterContent id={id} data={data} setData={setData} />
      </Box>
    </Node>
  )
}

export default FilterNode
