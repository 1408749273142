import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InviteMessages from 'components_new/pages/internal/InviteMessages'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <InviteMessages />}
        path={'/invite-messages'}
      />
      <Route path={'/invite-messages/*'}>
        <Redirect to="/invite-messages" />
      </Route>
    </Switch>
  )
}

export default Routes
