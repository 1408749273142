import { cloneDeep } from 'lodash'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  fetching: false,
  endpoint: null,
  transformations: {},
  // only for insight studio datasets
  filterVariables: {}
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let newTransformations
  let newFilterVariables

  switch (type) {
  case Types.GET_APPS_DATASET_PREVIEW:
    return {
      ...state,
      fetching: true
    }
  case Types.GET_APPS_DATASET_PREVIEW_SUCCESS:
    return {
      ...state,
      data: {
        [payload.key]: payload.data
      },
      fetching: false,
      endpoint: payload.endpoint
    }

  case Types.GET_APPS_DATASET_PREVIEW_FAILED:
    return {
      ...state,
      fetching: false
    }

  case Types.GET_DATASET_TRANSFORMATION_SUCCESS:
    return {
      ...state,
      transformations: {
        ...state.transformations,
        [payload.id]: payload.data
      }
    }

  case Types.UPDATE_APPS_DATASET_SUCCESS:
    newTransformations = cloneDeep(state.transformations)
    delete newTransformations[payload.id]

    newFilterVariables = cloneDeep(state.filterVariables)
    delete newFilterVariables[payload.id]

    return {
      ...state,
      transformations: newTransformations,

      // Trigger a new fetch of filter variables
      filterVariables: newFilterVariables
    }

  case Types.GET_DATASET_FILTER_VARIABLES_SUCCESS:
    return {
      ...state,
      filterVariables: {
        ...state.filterVariables,
        [payload.id]: payload.data
      }
    }

  case Types.PUT_TABLE_FILTER_VARIABLES_SUCCESS:
    return {
      ...state,
      filterVariables: {
        ...state.filterVariables,
        [payload.id]: payload.data
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
