import React, { useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import OverlayStepper from 'components/common/OverlayStepper'
import Input from 'components/common/Input'
import Table from 'components/common/Table'
import Dropzone from 'components/common/Dropzone'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'

import File from 'assets/collect/file.svg'

import * as Functions from 'helpers/Functions'

const NewFile = ({ match, history, SourceStore, tryCreateActiveSource }) => {
  const open = match.params.createType === 'files'

  const [selectedWriteMode, setSelectedWriteMode] = useState(['append'])
  const [uploadedFile, setUploadedFile] = useState([])

  const nameForm = useRef(null)

  const source = Object.values(SourceStore.data).find(
    (src) => src.attributes.type === 'File'
  )

  return (
    <OverlayStepper
      open={open}
      onClose={() => {
        history.goBack()
        setSelectedWriteMode(['append'])
        setUploadedFile([])
        nameForm.current.resetFields()
      }}
      onFinish={async () => {
        const data = {
          attributes: {
            source_id: source.id,
            display_name: nameForm.current.getFieldValue('name'),
            write_mode: selectedWriteMode[0]
          }
        }

        const json = JSON.stringify(data)
        const blob = new Blob([json], {
          type: 'application/json'
        })

        const formData = new FormData()

        formData.append('file', uploadedFile[0])
        formData.append('body', blob)

        tryCreateActiveSource(formData)

        setSelectedWriteMode(['append'])
        setUploadedFile([])
        nameForm.current.resetFields()

        history.push('/data-platform/collect/files')
      }}
      title={'Collect'}
      service={'collect'}
      steps={[
        {
          title: 'Välj filuppdatering',
          description: 'Lägg till eller ersätt fil',
          valid: true,
          content: (
            <WriteModeOption
              instructions={source ? source.attributes.instructions : ''}
              selectedWriteMode={selectedWriteMode}
              setSelectedWriteMode={setSelectedWriteMode}
            />
          )
        },
        {
          title: 'Ladda upp fil',
          description: 'Välj fil',
          valid: true,
          content: (
            <FileUpload
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
            />
          )
        },
        {
          title: 'Namnge systemdata',
          description: 'Ge ditt system ett namn',
          valid: true,
          content: <ProvideName form={nameForm} SourceStore={SourceStore} />,
          onNext: () => Functions.validateForm(nameForm)
        }
      ]}
    />
  )
}

const WriteModeOption = ({ selectedWriteMode, setSelectedWriteMode }) => {
  return (
    <Box>
      <Text gutterBottom variant="h6">
        Filuppdatering
      </Text>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Text color="text.secondary" gutterBottom variant="body1">
            Gör följande vid uppdatering från fil:
          </Text>
          <Table
            hidden
            rowSelectionType={'radio'}
            selectedRows={selectedWriteMode}
            setSelectedRows={(value) => setSelectedWriteMode(value)}
            headers={[{ key: 'label' }]}
            rows={[
              {
                id: 'append',
                attributes: { label: 'Lägg till systemdata' }
              },
              {
                id: 'overwrite',
                attributes: { label: 'Ersätt befintlig systemdata' }
              }
            ]}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Icon name="InfoOutlined" sx={{ mr: 0.5 }} />
            <Text gutterBottom variant="h6">
              Information
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const FileUpload = ({ uploadedFile, setUploadedFile }) => {
  return (
    <Box>
      <Text gutterBottom variant="h6">
        Ladda upp fil
      </Text>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Dropzone
            files={uploadedFile}
            removeFile={() => setUploadedFile([])}
            icon={File}
            accept={['.csv', '.txt']}
            maxFiles={1}
            appendFile={(value) => setUploadedFile(value)}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Icon name="InfoOutlined" />
            <Text gutterBottom variant="h6">
              Information
            </Text>
          </Box>
          <Text color="text.secondary" paragraph>
            Ladda upp din fildata för att sätta strukturen på din systemdata i
            Collect. Filen behöver inte innehålla några värden.
          </Text>
          <Text color="text.secondary" paragraph>
            En SFTP användare kommer att sättas upp i samband med skapande av
            denna datakällan. Om du enbart ska använda SFTP behöver du inte
            lägga till en fil i detta steget.
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

const ProvideName = ({ form, SourceStore }) => {
  return (
    <Box>
      <Text gutterBottom variant="h6">
        Lägg till som
      </Text>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Form ref={form} layout={'vertical'}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Obligatoriskt fält'
                },
                () => ({
                  validator(_, value) {
                    if (
                      value &&
                      Object.values(SourceStore.data)
                        .filter(
                          (item) =>
                            item.included && item.included.active_sources
                        )
                        .some((item) =>
                          item.included.active_sources.some(
                            (activeSource) =>
                              activeSource.attributes.display_name &&
                              // eslint-disable-next-line max-len
                              activeSource.attributes.display_name.toLowerCase() ===
                                value.toLowerCase()
                          )
                        )
                    ) {
                      return Promise.reject(
                        `Ett system med samma namn '${value}' existerar redan`
                      )
                    }

                    return Promise.resolve()
                  }
                })
              ]}
              name={'name'}
              label={'Rubrik'}
            >
              <Input />
            </Form.Item>
          </Form>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Icon name="InfoOutlined" />
            <Text gutterBottom variant="h6">
              Information
            </Text>
          </Box>
          <Text color="text.secondary" paragraph>
            Du kan välja att namnge ditt system efter dina preferenser. Att
            namnge ett system i portalen påverkar inte dess funktionalitet.
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

function mapStateToProps({ SourceStore }) {
  return {
    SourceStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActiveSourceActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewFile))
