import React, { useEffect, useState } from 'react'

import { FillSpinner } from 'react-spinners-kit'
import { useTheme } from '@mui/material/styles'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

interface LoadingSectionProps {
  direction?: 'row' | 'column'
  loading: boolean
  titles: string[]
  scaleFactor?: number
  sx?: object
}

/**
 * The LoadingSection component is used
 * to show a loading indicator together
 * with a short descriptive text.
 *
 * It expands, and fills up, all
 * available space.
 */

const LoadingSection = React.forwardRef(
  (props: LoadingSectionProps, ref: any) => {
    const theme = useTheme()

    const [loadingTitle, setLoadingTitle] = useState(props.titles[0])

    if (props.titles) {
      const [current, setCurrent] = useState(0)

      useEffect(() => {
        const titleSwapper = setInterval(() => {
          setLoadingTitle(props.titles[current])
          if (current === props.titles.length - 1) {
            setCurrent(0)
          } else {
            setCurrent(current + 1)
          }
        }, 3000)

        return () => {
          clearInterval(titleSwapper)
        }
      })
    }

    return (
      <Box
        ref={ref}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: props.direction,
          justifyContent: 'center',
          alignItems: 'center',
          gap: props.scaleFactor ? 2 * props.scaleFactor : 2,
          fontSize: props.scaleFactor ? 16 * props.scaleFactor : undefined,
          ...props.sx
        }}
      >
        <FillSpinner
          size={
            props.scaleFactor
              ? props.scaleFactor * 40
              : props.direction === 'row'
                ? 24
                : 40
          }
          color={theme.palette.action.focus}
          loading={props.loading}
        />
        <Text
          align="center"
          color="text.disabled"
          variant="body2"
          fontWeight="medium"
          fontSize={props.scaleFactor ? 'inherit' : undefined}
        >
          {loadingTitle}
          {loadingTitle ? '...' : null}
        </Text>
      </Box>
    )
  }
)

LoadingSection.defaultProps = {
  direction: 'column'
}

LoadingSection.displayName = 'LoadingSection'
export default LoadingSection
