import React from 'react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

const InformationHeader = (props) => {
  const { details } = props

  return (
    <Box>
      <Divider />
      <Box sx={{ display: 'flex' }}>
        {details.map((item, index) => (
          <Box key={item.key} sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 1
              }}
            >
              <Text color="text.secondary" gutterBottom variant="overline">
                {item.header}
              </Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 0.25
                }}
              >
                {item.icon ? <Box sx={{ mr: 1 }}>{item.icon}</Box> : null}
                {item.description ? <small>{item.description}</small> : null}
              </Box>
            </Box>
            {index === details.length - 1 ? null : (
              <Divider orientation="vertical" />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
// details - lista av objekt:
// {header: 'text', icon: hela ikonen alternativt en div, description: 'text'}

export default InformationHeader
