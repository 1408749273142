import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/data-products`

export async function putOntology(data, type) {
  return Axios.put(
    `${URL}/ontology?type=${type}`,
    data,
    await Functions.parseAuthenticationHeader()
  )
}

export async function getOntology(type) {
  return Axios.get(
    `${URL}/ontology?type=${type}`,
    await Functions.parseAuthenticationHeader()
  )
}
