import React from 'react'

import Styles from './styles.module.css'
import DataTypeIcon from 'components/common/DataTypeIcon'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

export const TITLE_REQUIRED = {
  BAR_CHART: false,
  KEY_FIGURE: true,
  PIVOT_TABLE: false,
  COMBINATION_CHART: false
}

export const FORMAT_COLORS = {
  GREEN: { label: 'Grön', code: '#22c55e' },
  RED: { label: 'Röd', code: '#ef4444' },
  YELLOW: { label: 'Gul', code: '#f59e0b' },
  BLUE: { label: 'Blå', code: '#3b82f6' }
}

export const getFormatColors = () => {
  return Object.keys(FORMAT_COLORS)
    .sort((c1, c2) =>
      FORMAT_COLORS[c1].label < FORMAT_COLORS[c2].label ? -1 : 1
    )
    .map((colorObjKey) => ({
      label: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <div
            className={Styles['format-color-circle']}
            style={{ background: FORMAT_COLORS[colorObjKey].code }}
          />
          <Text variant="body1">{FORMAT_COLORS[colorObjKey].label}</Text>
        </Box>
      ),
      value: colorObjKey
    }))
}

/**
 * Format needs to be limited to only use the attributes in the dataset
 * that has been picked in the widget.
 */
export const getFormatAttributes = (attributes, formRefValues) => {
  const widgetOptions =
    formRefValues.widget_metrics?.filter(Boolean)?.map((metric) => ({
      label: metric.title,
      name: metric.title,
      value: metric.id,
      type: 'DOUBLE'
    })) || []

  const segmentBy = attributes.find(
    (attr) => attr.value == formRefValues.segment_by
  )

  if (!segmentBy) {
    return widgetOptions
  }

  return [
    {
      ...segmentBy,
      label: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <DataTypeIcon type={segmentBy.type} />
          <Text variant="body1">{formRefValues.segment_title}</Text>
        </Box>
      ),
      name: formRefValues.segment_title
    },
    ...widgetOptions
  ]
}
