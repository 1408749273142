import React from 'react'

import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Box from 'components_new/atoms/Box'
import LoadingSection from 'components_new/molecules/LoadingSection'

interface DegreeOfActivationKpiBoxProps {
  title: string
  value: number
  goal?: number
  totalUsers: number
  activatedUsers: number
  loading: boolean
}

const DegreeOfActivationKpiBox = (props: DegreeOfActivationKpiBoxProps) => {
  const PaperSx = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minWidth: 0,
    position: 'relative',
    gap: 2,
    bgcolor: 'grey.50'
  }

  let goalColor = 'transparent'

  if (props.goal) {
    if (props.value < props.goal) {
      goalColor = 'error.main'
    } else {
      goalColor = 'success.main'
    }
  }

  return (
    <Paper elevation={0} sx={PaperSx}>
      <Box sx={{ flex: '0 0 100px' }}>
        <Text
          component="div"
          fontSize={18}
          fontWeight={'bold'}
          lineHeight={1.1}
          sx={{
            color: 'text.primary'
          }}
        >
          {props.title}
        </Text>
        {props.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <>
            <Text
              component="div"
              fontSize={60}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: 'text.primary',
                mt: 2
              }}
            >
              {`${props.value}%`}
            </Text>
            <Text
              component="div"
              fontSize={18}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: goalColor,
                mt: 1
              }}
            >
              {props.goal ? `Mål: ${props.goal}%` : 'NaN%'}
            </Text>
            <Text
              component="div"
              fontSize={14}
              lineHeight={1.1}
              sx={{
                color: 'text.secondary',
                mt: 2
              }}
            >
              {`Antal användare: ${props.totalUsers}`}
            </Text>
            <Text
              component="div"
              fontSize={14}
              lineHeight={1.1}
              sx={{
                color: 'text.secondary',
                mt: 0.5
              }}
              fontWeight={'bold'}
            >
              {`Varav aktiverade: ${props.activatedUsers}`}
            </Text>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default DegreeOfActivationKpiBox
