import React, { useEffect, useRef } from 'react'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Input from 'components/common/Input'
import Button from 'components_new/atoms/Button'

import { FORM_VALIDATIONS } from 'helpers/Constants'
import { validateForm } from 'helpers/Functions'

const ManageApiKey = ({ value, onClose, onSave, apiKeys = {} }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.setFieldsValue({
        name: value?.name
      })
    }
  }, [value])

  return (
    <Modal
      header={value && value.name ? 'Redigera nyckel' : 'Generera nyckel'}
      visible={value}
      onClose={() => onClose()}
      actions={
        <>
          <Button onClick={() => onClose()} variant="text">
            Avbryt
          </Button>
          <Button
            colored
            onClick={async () => {
              const validated = await validateForm(ref)

              if (validated) {
                onSave(ref.current.getFieldValue('name'))

                ref.current.resetFields()
                onClose()
              }
            }}
          >
            {value && value.name ? 'Spara' : 'Generera'}
          </Button>
        </>
      }
    >
      <Form
        layout={'vertical'}
        ref={ref}
        initialValues={{
          name: undefined
        }}
      >
        <Form.Item
          name={'name'}
          rules={[
            FORM_VALIDATIONS.REQUIRED,
            FORM_VALIDATIONS.DUPLICATE(
              apiKeys,
              'name',
              'En API-nyckel med samma namn existerar redan',
              value?.id
            )
          ]}
        >
          <Input size="large" label={'Namnge nyckel'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ManageApiKey
