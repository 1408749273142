import React from 'react'

import StatusBanner from 'components/common/StatusBanner'

import { getTimeAgoString, getUserNameInformation } from 'helpers/Functions'
import { DESCRIPTOR } from 'helpers/Constants/DataProducts'

export const getMetaInformation = (dataProduct, UserStore) => {
  const { firstName, lastName } = getUserNameInformation(
    dataProduct.attributes.updated_by,
    UserStore
  )

  return [
    {
      header: 'Skapad',
      label: dataProduct.attributes.created_at.split('T')[0]
    },
    {
      header: 'Uppdaterad',
      label: getTimeAgoString(new Date(dataProduct.attributes.updated_at))
    },
    {
      header: 'Uppdaterad av',
      label: `${firstName} ${lastName}`
    }
  ]
}

export const toggleItems = (history, id, setActiveToggle, type) => {
  const connection = {
    value: 'connection',
    label: 'Anslutning',
    onClick: () => {}
  }

  const dataset = {
    value: 'dataset',
    label: 'Dataset',
    onClick: () => {}
  }

  if (type === 'API') {
    return [
      {
        ...connection,
        onClick: () => {
          setActiveToggle('connection')
          history.replace(`/access-hub/${id}/connection`)
        }
      },
      {
        ...dataset,
        onClick: () => {
          setActiveToggle('dataset')

          history.replace(`/access-hub/${id}/dataset`)
        }
      }
    ]
  }

  return [dataset]
}

export const getActions = (
  setAlert,
  resetAlert,
  tryDeleteDataProduct,
  setEditName,
  type,
  onlyReadAccess = false,
  exportItem = undefined
) => {
  let actionItems = type === 'INSIGHT_STUDIO' ? [exportItem] : []

  if (!onlyReadAccess) {
    actionItems = [
      ...actionItems,
      {
        label: 'Redigera namn',
        onClick: () => setEditName(true)
      },
      {
        label: 'Ta bort',
        remove: true,
        onClick: () =>
          setAlert({
            header: `Ta bort ${DESCRIPTOR[type]}`,
            color: 'warning',
            content: (
              <div className={'flex-vertical-gap'}>
                {type === 'API' && (
                  <StatusBanner
                    title={'Tänk på!'}
                    message={
                      // eslint-disable-next-line max-len
                      'Alla tjänster som använder din delade data kan sluta fungera då du tar bort det.'
                    }
                    type={'warning'}
                    showIcon
                  />
                )}
                <p>Vill du verkligen ta bort aktuell {DESCRIPTOR[type]}?</p>
              </div>
            ),
            onCancel: () => resetAlert(),
            onOk: () => {
              tryDeleteDataProduct()
              resetAlert()
            },
            cancelText: 'Avbryt',
            okText: 'Ta bort'
          })
      }
    ]
  }

  return [
    {
      key: 'action-items-0',
      items: actionItems
    }
  ]
}
