import * as Api from 'redux/api/DataPlatform/dataQuality/formats/v1'
import * as Types from 'redux/Types'

export function tryGetAllFormats() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_FORMATS
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_FORMATS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_FORMATS_FAILED
        })
      })
  }
}
