import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: [],
  fetching: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_WIDGETS:
    return {
      ...state,
      fetching: true
    }
  case Types.GET_WIDGETS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.tabId]: payload.data
      },
      fetching: false
    }
  case Types.GET_WIDGETS_FAILED:
    return {
      ...state,
      fetching: false
    }
  case Types.PUT_WIDGETS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.tabId]: payload.data
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
