import { getCustomers, updateCustomer } from 'redux/api/internal/OurCustomers'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { PatchInternalCustomerBody } from 'types/GlobalCustomer'

export function tryGetCustomers() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_OUR_CUSTOMERS
    })

    getCustomers()
      .then((response) => {
        dispatch({
          type: Types.GET_OUR_CUSTOMERS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_OUR_CUSTOMERS_FAILED
        })
      })
  }
}

export function tryUpdateCustomer(id: string, body: PatchInternalCustomerBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ONE_CUSTOMER
    })

    updateCustomer(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ONE_CUSTOMER_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunden har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ONE_CUSTOMER_FAILED
        })
      })
  }
}
