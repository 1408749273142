import React, { ChangeEventHandler, useState } from 'react'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Link from 'components_new/atoms/Link'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import {
  TInputOption,
  TInputType,
  createChangeEvent,
  isResetable
} from './utils'

interface SettingsEditDialogProps {
  clearValue?: string
  disabled?: boolean
  onChange:
    | (() => void)
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onClose: () => void
  onSubmit: () => void
  open: boolean
  options?: TInputOption[]
  required?: boolean
  title: string
  type: TInputType
  value: string
  variant?: 'add' | 'edit'
}

const SettingsEditDialog = (props: SettingsEditDialogProps) => {
  const {
    clearValue = '',
    disabled,
    onChange,
    onClose,
    onSubmit,
    options,
    open,
    required,
    title,
    type,
    value,
    variant
  } = props

  const handleSubmit = () => {
    onSubmit()
    onClose()
  }

  const handleReset = () => {
    const event = createChangeEvent(clearValue)

    onChange(event)
  }

  const [selectOpen, setSelectOpen] = useState<boolean>(false)

  // Helper text for when we implement catch Enter in full dialog:
  /* 
    <span>
      {`Tips! Tryck Enter ⏎ för att ${
        variant === 'add' ? 'lägga till' : 'spara'
      }.`}
    </span> 
  */

  const helperText = isResetable(type, options) ? (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Link disabled={value === ''} onClick={handleReset}>
        Rensa
      </Link>
    </Box>
  ) : undefined

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <>
            <Button onClick={onClose} variant="text">
              Avbryt
            </Button>
            <Button
              disabled={
                disabled || ((variant === 'add' || required) && value === '')
              }
              onClick={handleSubmit}
            >
              {variant === 'add' ? 'Lägg till' : 'Spara'}
            </Button>
          </>
        }
        disableRestoreFocus={true}
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 1302 // for fullSize
        }}
        title={`${
          variant === 'add' ? 'Lägg till' : 'Redigera'
        } ${title.toLowerCase()}`}
      >
        {type === 'select' ? (
          <TextField
            autoFocus={true}
            helperText={helperText}
            label={title}
            onChange={(event) => {
              if (event.target.value === 'none') {
                handleReset()
              } else {
                onChange(event)
              }
            }}
            onKeyDown={(event) => {
              if (!selectOpen && event.key === 'Enter') {
                handleSubmit()
                setTimeout(() => {
                  (document.activeElement as HTMLSelectElement).blur()
                }, 0)
              }
            }}
            select={true}
            SelectProps={{
              onClose: () => setSelectOpen(false),
              onOpen: () => setSelectOpen(true)
            }}
            sx={{ mt: 1 }}
            value={value === '' ? 'none' : value}
          >
            {options
              ? options.map((option) => (
                <MenuItem
                  key={option.value}
                  disabled={option.disabled}
                  value={option.value === '' ? 'none' : option.value}
                >
                  {option.label === 'Ingen' ? (
                    <em>{option.label}</em>
                  ) : (
                    option.label
                  )}
                </MenuItem>
              ))
              : null}
          </TextField>
        ) : (
          <TextField
            autoFocus={true}
            helperText={helperText}
            InputProps={
              type === 'decimal'
                ? {
                    inputMode: 'numeric',
                    pattern: '[0-9]+([,][0-9]+)?',
                    step: 'any'
                  }
                : type === 'number'
                  ? {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  : undefined
            }
            label={title}
            onChange={onChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
            type={
              type === 'decimal' || type === 'number' ? 'number' : undefined
            }
            value={value}
          />
        )}
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default SettingsEditDialog
