import React, { useState, useEffect } from 'react'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import Styles from './styles.module.css'

const Pagination = ({ totalItems, perPage, onChange, text = '', page }) => {
  useEffect(() => {
    if (page) {
      setCurrentPage(page)
    }
  }, [page])

  const [currentPage, setCurrentPage] = useState(1)

  const amountOnCurrentPage =
    perPage * currentPage <= totalItems ? perPage : totalItems % perPage
  const lowerSpan =
    currentPage === 1 ? currentPage : (currentPage - 1) * perPage + 1
  const upperSpan =
    currentPage === 1
      ? amountOnCurrentPage
      : lowerSpan + amountOnCurrentPage - 1

  const totalPages = Math.ceil(totalItems / perPage)

  return (
    <div className={Styles['pagination-wrapper']}>
      <small>
        {/* eslint-disable-next-line max-len */}
        Visar {totalItems === 0 ? 0 : lowerSpan}-{upperSpan} av {totalItems}{' '}
        {text}
      </small>

      <div className={Styles['pagination-right-wrapper']}>
        <Button
          disabled={totalItems === 0 || currentPage === 1}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1)
              onChange(currentPage - 1)
            }
          }}
          startIcon={<Icon name="ChevronLeft" />}
        >
          Föregående
        </Button>

        <Button
          disabled={totalItems === 0 || currentPage === totalPages}
          endIcon={<Icon name="ChevronRight" />}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1)
              onChange(currentPage + 1)
            }
          }}
        >
          Nästa
        </Button>
      </div>
    </div>
  )
}

export default Pagination
