import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'
import {
  DashboardBody,
  DashboardAPI,
  DashboardGroup,
  Category,
  updateKpiOption,
  DashboardReducerType,
  deleteKpiOption,
  updateStoreWidgetTag,
  deleteStoreWidgetTag,
  updateStoreWidgetAdditionalSegment,
  deleteStoreWidgetAdditionalSegment
} from '../Dashboards'

import { WidgetBody, WidgetObject } from 'types/GlobalWidget'

import { LayoutUpdate } from 'redux/api/Dashboards'
import {
  KpiOptionPatchResponse,
  KpiOptionReduxResponse
} from 'types/GlobalKpiOption'
import { WidgetTagReduxPayload } from 'types/GlobalWidgetTag'
import { AdditionalSegmentReduxPayload } from 'types/GlobalAdditionalSegment'

export enum DashboardStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED'
}

// Initial state
const INITIAL_STATE: DashboardReducerType = {
  data: {},
  groups: {},
  fetching: false,
  fetched: false,
  activeFetchOne: null,
  errorFetchOne: null,
  fetchIds: {},
  currentWidgetId: null,
  details: {}
}

// Actions
const getAllAction = createAction<{
  data: {
    dashboards: DashboardAPI[]
    groups: { [groupId: string]: DashboardGroup }
  }
}>(Types.GET_ALL_DASHBOARD_TEMPLATES_SUCCESS)
const getOneAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.GET_ONE_DASHBOARD_TEMPLATE_SUCCESS)
const createDashboardTemplateAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.CREATE_DASHBOARD_TEMPLATE_SUCCESS)
const updateDashboardAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.UPDATE_DASHBOARD_TEMPLATE_SUCCESS)
const deleteDashboardTemplateAction = createAction<{ id: string }>(
  Types.DELETE_DASHBOARD_TEMPLATE_SUCCESS
)

const updateDashboardOrderAction = createAction<{
  id: string
  order: string[]
}>(Types.UPDATE_DASHBOARD_TEMPLATE_ORDER_SUCCESS)
const updateDashboardGroupAction = createAction<{
  data: {
    id: string
    title: string
    category: Category
    description: string
    status: DashboardStatus
  }
}>(Types.UPDATE_DASHBOARD_TEMPLATE_GROUP_SUCCESS)
const deleteDashboardGroupAction = createAction<{
  id: string
}>(Types.DELETE_DASHBOARD_TEMPLATE_GROUP_SUCCESS)
const copyDashboardGroupAction = createAction<{
  data: {
    dashboards: DashboardAPI[]
    groups: { [groupId: string]: DashboardGroup }
  }
}>(Types.COPY_DASHBOARD_TEMPLATE_GROUP_SUCCESS)

const getOneWidgetAction = createAction<{
  id: string
  dashboardId: string
  fetchId: string
}>(Types.GET_ONE_TEMPLATE_WIDGET)
const getOneWidgetSuccessAction = createAction<{
  data: WidgetObject
  fetchId: string
}>(Types.GET_ONE_TEMPLATE_WIDGET_SUCCESS)
const createWidgetAction = createAction(Types.CREATE_TEMPLATE_WIDGET)
const createWidgetSuccessAction = createAction<{ data: WidgetObject }>(
  Types.CREATE_TEMPLATE_WIDGET_SUCCESS
)
const updateWidgetAction = createAction(Types.UPDATE_TEMPLATE_WIDGET)
const updateWidgetSuccessAction = createAction<{
  data: WidgetObject
  skipFetchData: boolean
}>(Types.UPDATE_TEMPLATE_WIDGET_SUCCESS)
const deleteWidgetAction = createAction<{ id: string; dashboardId: string }>(
  Types.DELETE_TEMPLATE_WIDGET_SUCCESS
)
const updateWidgetLayoutAction = createAction<{
  id: string
  layout: LayoutUpdate[]
}>(Types.UPDATE_TEMPLATE_WIDGET_LAYOUT_SUCCESS)

// Kpi options
const createKpiOption = createAction(Types.CREATE_KPI_OPTION_TEMPLATE)
const createKpiOptionSuccess = createAction<KpiOptionReduxResponse>(
  Types.CREATE_KPI_OPTION_TEMPLATE_SUCCESS
)
const updateKpiOptionAction = createAction(Types.UPDATE_KPI_OPTION_TEMPLATE)
const updateKpiOptionSuccess = createAction<KpiOptionPatchResponse>(
  Types.UPDATE_KPI_OPTION_TEMPLATE_SUCCESS
)
const deleteKpiOptionAction = createAction(Types.DELETE_KPI_OPTION_TEMPLATE)
const deleteKpiOptionSuccess = createAction<{
  dashboardId: string
  id: string
  widgetId: string
}>(Types.DELETE_KPI_OPTION_TEMPLATE_SUCCESS)

// Widget tags
const createWidgetTag = createAction(Types.CREATE_WIDGET_TAG_TEMPLATE)
const createWidgetTagSuccess = createAction<WidgetTagReduxPayload>(
  Types.CREATE_WIDGET_TAG_TEMPLATE_SUCCESS
)
const updateWidgetTag = createAction(Types.UPDATE_WIDGET_TAG_TEMPLATE)
const updateWidgetTagSuccess = createAction<WidgetTagReduxPayload>(
  Types.UPDATE_WIDGET_TAG_TEMPLATE_SUCCESS
)
const deleteWidgetTag = createAction(Types.DELETE_WIDGET_TAG_TEMPLATE)
const deleteWidgetTagSuccess = createAction<{
  dashboardId: string
  id: string
  widgetId: string
}>(Types.DELETE_WIDGET_TAG_TEMPLATE_SUCCESS)

// widget additional segments
const createAdditionalSegment = createAction(
  Types.CREATE_ADDITIONAL_SEGMENT_TEMPLATE
)
const createAdditionalSegmentSuccess =
  createAction<AdditionalSegmentReduxPayload>(
    Types.CREATE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS
  )

const updateAdditionalSegment = createAction(
  Types.UPDATE_ADDITIONAL_SEGMENT_TEMPLATE
)
const updateAdditionalSegmentSuccess =
  createAction<AdditionalSegmentReduxPayload>(
    Types.UPDATE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS
  )

const deleteAdditionalSegment = createAction(
  Types.DELETE_ADDITIONAL_SEGMENT_TEMPLATE
)
const deleteAdditionalSegmentSuccess = createAction<{
  id: string
  widgetId: string
  dashboardId: string
}>(Types.DELETE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

const dashboardTemplateReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      const data: DashboardBody = {}

      payload.data.dashboards.forEach((dashboard) => {
        data[dashboard.id] = {
          ...dashboard,
          widgets: arrayToObject(dashboard.widgets) as WidgetBody
        }
      })

      return {
        data,
        groups: payload.data.groups,
        fetching: false,
        fetched: true,
        activeFetchOne: state.activeFetchOne,
        errorFetchOne: null,
        fetchIds: {},
        currentWidgetId: null,
        details: {}
      }
    })
    .addCase(getOneAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard.id]: {
            ...payload.data.dashboard,
            widgets: arrayToObject(payload.data.dashboard.widgets) as WidgetBody
          }
        },
        activeFetchOne: payload.data.dashboard.id
      }
    })
    .addCase(createDashboardTemplateAction, (state, action) => {
      const { payload } = action

      let newGroup = payload.data.group

      if (payload.data.dashboard.dashboard_group_id in state.groups) {
        newGroup = cloneDeep(
          state.groups[payload.data.dashboard.dashboard_group_id]
        )
        newGroup.dashboards.push(payload.data.dashboard.id)
      }

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard.id]: {
            ...payload.data.dashboard,
            widgets: arrayToObject(payload.data.dashboard.widgets) as WidgetBody
          }
        },
        groups: {
          ...state.groups,
          [payload.data.dashboard.dashboard_group_id]: newGroup
        }
      }
    })
    .addCase(deleteDashboardTemplateAction, (state, action) => {
      const { payload } = action

      const data = cloneDeep(state.data)
      const groups = cloneDeep(state.groups)
      const groupId = data[payload.id].dashboard_group_id

      delete data[payload.id]

      if (groups[groupId].dashboards.length === 1) {
        delete groups[groupId]
      } else {
        groups[groupId].dashboards = groups[groupId].dashboards.filter(
          (d) => d !== payload.id
        )
      }

      return {
        ...state,
        data,
        groups
      }
    })
    .addCase(updateDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard.id]: {
            ...state.data[payload.data.dashboard.id],
            title: payload.data.dashboard.title,
            subtitle: payload.data.dashboard.subtitle,
            description: payload.data.dashboard.description,
            status: payload.data.dashboard.status
          }
        },
        groups: {
          ...state.groups,
          [payload.data.dashboard.dashboard_group_id]: {
            ...state.groups[payload.data.dashboard.dashboard_group_id],
            status: payload.data.group.status
          }
        }
      }
    })
    .addCase(updateDashboardGroupAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.data.id]: {
            ...state.groups[payload.data.id],
            title: payload.data.title,
            category: {
              selected: payload.data.category,
              options: state.groups[payload.data.id].category.options
            },
            description: payload.data.description,
            status: payload.data.status
          }
        }
      }
    })
    .addCase(deleteDashboardGroupAction, (state, action) => {
      const { payload } = action

      const newGroups = cloneDeep(state.groups)

      delete newGroups[payload.id]

      return {
        ...state,
        groups: newGroups
      }
    })
    .addCase(updateDashboardOrderAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.id]: {
            ...state.groups[payload.id],
            dashboards: payload.order
          }
        }
      }
    })
    .addCase(copyDashboardGroupAction, (state, action) => {
      const { payload } = action

      const data = cloneDeep(state.data)

      payload.data.dashboards.forEach((dashboard) => {
        data[dashboard.id] = {
          ...dashboard,
          widgets: arrayToObject(dashboard.widgets) as WidgetBody
        }
      })

      return {
        ...state,
        data,
        groups: {
          ...state.groups,
          ...payload.data.groups
        }
      }
    })

    // Widget actions
    .addCase(getOneWidgetAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            widgets: {
              ...state.data[payload.dashboardId].widgets,
              [payload.id]: {
                ...state.data[payload.dashboardId].widgets[payload.id],
                data: null
              }
            }
          }
        },
        fetchIds: {
          ...state.fetchIds,
          [payload.id]: payload.fetchId
        }
      }
    })
    .addCase(getOneWidgetSuccessAction, (state, action) => {
      const { payload } = action

      if (
        state.fetchIds[payload.data.id] &&
        state.fetchIds[payload.data.id] !== payload.fetchId
      ) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: payload.data
            }
          }
        },
        fetchIds: { ...state.fetchIds, [payload.data.id]: null },
        currentWidgetId: null
      }
    })
    .addCase(createWidgetAction, (state) => {
      return {
        ...state,
        currentWidgetId: null
      }
    })
    .addCase(createWidgetSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: payload.data
            }
          }
        },
        currentWidgetId: payload.data.id
      }
    })
    .addCase(updateWidgetAction, (state) => {
      return {
        ...state,
        currentWidgetId: null
      }
    })
    .addCase(updateWidgetSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: {
                ...payload.data,
                data: payload.skipFetchData
                  ? state.data[payload.data.dashboard_id].widgets[
                    payload.data.id
                  ].data
                  : null
              }
            }
          }
        },
        currentWidgetId: payload.skipFetchData ? null : payload.data.id
      }
    })
    .addCase(deleteWidgetAction, (state, action) => {
      const { payload } = action

      const widgets = cloneDeep(state.data[payload.dashboardId].widgets)

      delete widgets[payload.id]

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            widgets
          }
        }
      }
    })
    .addCase(updateWidgetLayoutAction, (state, action) => {
      const { payload } = action

      const widgets = cloneDeep(state.data[payload.id].widgets)

      payload.layout.forEach((layout: LayoutUpdate) => {
        widgets[layout.id] = {
          ...state.data[payload.id].widgets[layout.id],
          layout: layout
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: {
            ...state.data[payload.id],
            widgets
          }
        }
      }
    })
    // Kpi options
    .addCase(createKpiOption, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createKpiOptionSuccess, (state, action) => {
      const { payload } = action

      return updateKpiOption(state, payload)
    })
    .addCase(updateKpiOptionAction, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateKpiOptionSuccess, (state, action) => {
      const { payload } = action

      let newState = cloneDeep(state)

      payload.data.forEach((option) => {
        newState = updateKpiOption(newState, {
          option,
          dashboardId: payload.dashboardId,
          skipFetchData: payload.skipFetchData
        })
      })

      return newState
    })
    .addCase(deleteKpiOptionAction, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteKpiOptionSuccess, (state, action) => {
      const { payload } = action

      return deleteKpiOption(state, payload)
    })
    // Widget tag
    .addCase(createWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTag(state, payload)
    })
    .addCase(updateWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTag(state, payload)
    })
    .addCase(deleteWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return deleteStoreWidgetTag(state, payload)
    })
    // Widget additional segment
    .addCase(createAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(updateAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(deleteAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return deleteStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default dashboardTemplateReducer
