import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Tooltip from 'components_new/atoms/Tooltip'

interface DisabledWrapperProps {
  children: ReactNode
  isPublic: boolean
}

const DisabledWrapper = (props: DisabledWrapperProps) => {
  const { children, isPublic } = props

  return (
    <>
      {!isPublic ? (
        <Tooltip
          followCursor={true}
          placement="top"
          title={
            <>
              Aktivera <b>Publik åtkomst</b> för att börja bädda in.
            </>
          }
        >
          <Box
            sx={{
              opacity: 0.5,
              cursor: 'not-allowed'
            }}
          >
            <Box
              sx={{
                pointerEvents: 'none'
              }}
            >
              {children}
            </Box>
          </Box>
        </Tooltip>
      ) : (
        children
      )}
    </>
  )
}

export default DisabledWrapper
