import React, { ElementType, MouseEventHandler, ReactNode } from 'react'

import { DialogContent } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Dialog from 'components_new/atoms/Dialog'
import Divider from 'components_new/atoms/Divider'
import Grid from 'components_new/atoms/Grid'
import LinearProgress from 'components_new/atoms/LinearProgress'
import Text from 'components_new/atoms/Text'

interface GuideDialogProps {
  actions?: ReactNode
  children?: ReactNode
  component?: ElementType<any>
  disableOverflow?: boolean
  disablePortal?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  imageSrc?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  sx?: object
  step: number
  steps: number
  subtitle?: ReactNode
  title?: ReactNode
}

/**
 * The GuideDialog component is a
 * variant of a Dialog that is designed
 * to be used when guiding a user.
 */

const GuideDialog = React.forwardRef((props: GuideDialogProps, ref: any) => {
  const {
    actions,
    children,
    disableOverflow,
    fullHeight,
    imageSrc,
    step,
    steps,
    title,
    subtitle,
    ...rest
  } = props

  return (
    <Dialog {...rest} ref={ref}>
      <DialogContent
        sx={{
          overflow: disableOverflow ? 'hidden' : undefined,
          height: fullHeight ? '90vh' : undefined,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 2
        }}
      >
        <Grid container={true} spacing={4} sx={{ mb: 2 }}>
          <Grid item={true} xs={imageSrc ? 6 : 12}>
            <Box
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <Box sx={{ flex: 1 }}>
                <Box sx={{ maxWidth: 'title' }}>
                  <Text variant="h3" gutterBottom={true}>
                    {title}
                  </Text>
                  <Text variant="subtitle2" gutterBottom={true}>
                    {subtitle}
                  </Text>
                </Box>
                <Divider sx={{ mt: 4 }} />
                <Box sx={{ py: 4, flex: 1, maxWidth: 'content' }}>
                  {children}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={(step / steps) * 100}
                    customLabel={`Steg ${step} av ${steps}`}
                    label={true}
                  />
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}
                >
                  {actions}
                </Box>
              </Box>
            </Box>
          </Grid>
          {imageSrc ? (
            <Grid item={true} xs={6}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  borderRadius: 4,
                  overflow: 'hidden'
                }}
              >
                <img
                  src={imageSrc}
                  width="100%"
                  height="100%"
                  style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  )
})

GuideDialog.defaultProps = {
  maxWidth: 'sm',
  fullWidth: true,
  onMouseDown: (e) => e.stopPropagation()
}

GuideDialog.displayName = 'GuideDialog'
export default GuideDialog
