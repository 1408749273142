import React, { useEffect, useState } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import { AccountRole } from 'types/GlobalUser'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import AddAccount from 'components_new/organisms/AddAccount'

import * as AccountActions from 'redux/actions/Accounts'
import * as AccountRoleActions from 'redux/actions/AccountRoles'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import Loading from './loading'

interface InviteButtonProps {
  sx?: object
}

const InviteButton = (props: ComponentProps) => {
  const {
    sx,
    // redux stores
    AuthStore,
    AccountStore,
    CompanyGroupStore,
    // redux actions
    tryCreateUser,
    tryGetAllAccountRoles
  } = props

  useEffect(() => {
    const { tryGetAllCompanyGroups } = props

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }
  }, [])

  const [dialog, setDialog] = useState<{
    open: boolean
    data: { email: string }
  }>({
    open: false,
    data: {
      email: ''
    }
  })

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)

  // A customer should never be able to create anything:
  if (userIsCustomer) return null

  const handleOpenDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: true }))
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  // loading
  if (!CompanyGroupStore.fetched || !AccountStore.rolesFetched) {
    return <Loading />
  }

  // success
  return (
    <>
      <Button
        color="primary"
        onClick={handleOpenDialog}
        size="large"
        startIcon={<Icon name="GroupAddOutlined" />}
        sx={sx}
        variant="contained"
      >
        Bjud in
      </Button>
      {/*-- dialog --*/}
      <SimpleDialog
        onClose={handleCloseDialog}
        open={dialog.open}
        title={'Bjud in'}
      >
        <AddAccount
          companyGroups={Object.values(CompanyGroupStore.data)}
          hideRole
          onInvite={(
            email,
            message,
            firstName,
            lastName,
            _,
            companyGroupId
          ) => {
            const role = AccountStore.roles[AccountRole.VIEW]

            tryCreateUser({
              data: {
                email: email,
                first_name: firstName,
                last_name: lastName,
                message: message,
                role: role.id,
                company_group_id: companyGroupId
              }
            })
          }}
          showInviteCompanyGroup={!!AuthStore.customer?.is_management_company}
          sx={{ mt: 1 }}
        />
      </SimpleDialog>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AccountRoleActions,
      ...CompanyGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & InviteButtonProps

export default connector(InviteButton)
