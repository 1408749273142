import { Paper as MUIPaper } from '@mui/material'
import React, { ElementType, MouseEventHandler, ReactNode } from 'react'

interface PaperProps {
  children?: ReactNode
  className?: string
  component?: ElementType<any>
  elevation?: number
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOver?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOut?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseEnter?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseLeave?: (() => void) | MouseEventHandler<HTMLElement>
  square?: boolean
  sx?: object
  variant?: 'elevation' | 'outlined'
}

/**
 * The Paper component component serves as a wrapper
 * component.
 *
 * In contrast to Box it resembles the flat, opaque
 * texture of a sheet of paper, and an application's
 * behavior mimics paper's ability to be re-sized,
 * shuffled, and bound together in multiple sheets.
 */

const Paper = React.forwardRef((props: PaperProps, ref: any) => {
  return (
    <MUIPaper {...props} ref={ref}>
      {props.children}
    </MUIPaper>
  )
})

Paper.displayName = 'Paper'
export default Paper
