import React from 'react'

import { getTypeData } from './conf'

import Box from 'components_new/atoms/Box'
import Tooltip from 'components_new/atoms/Tooltip'

const DataTypeIcon = ({ type }) => {
  const typeData = getTypeData(type)

  return (
    <Tooltip title={typeData.label}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '14px',
          width: '14px',
          bgcolor: 'primary.transparent',
          color: 'primary.main',
          borderRadius: '2px'
        }}
      >
        {typeData.icon ? typeData.icon : typeData.altIcon}
      </Box>
    </Tooltip>
  )
}

export default DataTypeIcon
