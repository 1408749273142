import React from 'react'

import { LinearProgress as MUILinearProgress } from '@mui/material'
import { Color } from 'themes'

import Box from '../Box'
import Text from '../Text'

interface LinearProgressProps {
  color?: Color | 'brand' | 'error' | 'info' | 'success' | 'warning'
  customLabel?: string
  label?: boolean
  sx?: object
  value: number
  valueBuffer?: number
  variant?: 'buffer' | 'determinate' | 'indeterminate' | 'query'
}

const LinearProgress = React.forwardRef(
  (props: LinearProgressProps, ref: any) => {
    const { value, ...rest } = props
    const limitedValue = value > 100 ? 100 : value

    if (props.label) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ flex: 1 }}>
            <MUILinearProgress value={limitedValue} {...rest} ref={ref} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Text
              color="text.secondary"
              fontWeight="600"
              sx={{ whiteSpace: 'nowrap' }}
              variant="body2"
            >
              {props.customLabel ?? `${Math.round(props.value)}%`}
            </Text>
          </Box>
        </Box>
      )
    }

    return <MUILinearProgress value={limitedValue} {...rest} ref={ref} />
  }
)

LinearProgress.displayName = 'LinearProgress'
export default LinearProgress
