import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from 'components_new/atoms/Box'

import Wrapper from 'components/containers/DataPlatform/shape/Wrapper'

import * as SourceSchemaActions from 'redux/actions/DataPlatform/shapes/SourceSchemas'
import * as ShapeDatasetActions from 'redux/actions/DataPlatform/shapes/v2/Datasets'
import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'
import InternalPage from 'components_new/templates/InternalTemplate'

class Overview extends Component {
  componentDidMount() {
    const { DatasetStore, CmdbTableStore } = this.props

    if (!DatasetStore.sourcesFetched && !DatasetStore.fetchingSources) {
      this.props.tryGetSourceSchemas()
    }

    if (!CmdbTableStore.fetched) {
      this.props.tryGetCmdbTables()
    }
  }

  render() {
    return (
      <InternalPage
        AuthStore={this.props.AuthStore}
        NavigationMenuStore={this.props.NavigationMenuStore}
      >
        <Box sx={{ height: '100%', width: '100%' }}>
          <Wrapper />
        </Box>
      </InternalPage>
    )
  }
}

function mapStateToProps({
  AuthStore,
  DatasetStore,
  CmdbTableStore,
  NavigationMenuStore
}) {
  return {
    AuthStore,
    DatasetStore,
    CmdbTableStore,
    NavigationMenuStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SourceSchemaActions,
      ...ShapeDatasetActions,
      ...CmdbTableActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview))
