import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

interface TitleProps {
  dashboardTitle: string
  groupTitle: string
  setTab: (value: 'embed' | 'email' | 'default') => void
  tab: 'embed' | 'email' | 'default'
}

const Title = (props: TitleProps) => {
  const {
    dashboardTitle,
    // Uncomment when implementing share tab or dashboard:
    // groupTitle,
    setTab,
    tab
  } = props

  const translateView: Record<'embed' | 'email', string> = {
    email: 'Bjud in',
    embed: 'Bädda in'
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {tab !== 'default' ? (
        <Box
          component="span"
          onClick={() => setTab('default')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary'
            },
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <Icon name="ArrowBackOutlined" sx={{ mr: 0.5 }} />
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {translateView[tab]}
            {tab === 'email' ? ' till ' : ' '}
            {dashboardTitle}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            Dela {dashboardTitle}
          </Box>

          {/* Uncomment when implementing share tab or dashboard:
          
          <Tooltip
            title={
              share === 'tab' ? (
                <>
                  Klicka för att dela <b>hela dashboarden</b> istället
                </>
              ) : (
                <>
                  Klicka för att endast dela <b>den här fliken</b>
                </>
              )
            }
          >
            <Box
              onClick={handleToggleShare}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary'
                }
              }}
            >
              <Box>{share === 'tab' ? dashboardTitle : groupTitle}</Box>
              <Icon name="ExpandMore" />
            </Box>
          </Tooltip> */}
        </>
      )}
    </Box>
  )
}

export default Title
