import React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import NavigationMenu from 'components_new/molecules/NavigationMenu'

import { getNavigationItems } from './utils'

const GlobalNavigation = (props: ComponentProps) => {
  const { AuthStore, NavigationMenuStore } = props

  return (
    <NavigationMenu
      items={getNavigationItems(AuthStore)}
      open={NavigationMenuStore.open}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const connector = connect(mapStateToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(GlobalNavigation)
