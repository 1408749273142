import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.CREATE_CMDB_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.SAVE_REGISTER_CHANGES_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.table.id]: payload.table
      }
    }

  case Types.UPDATE_CMDB_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.GET_ALL_CMDB_TABLES_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload),
      fetched: true
    }

  case Types.DELETE_CMDB_TABLE_SUCCESS:
    delete state.data[payload]

    return {
      ...state,
      data: {
        ...state.data
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
