import React, { ElementType, ReactNode } from 'react'
import { ListItemText as MUIListItemText } from '@mui/material'

interface ListItemTextProps {
  children?: ReactNode
  component?: ElementType<any>
  disableTypography?: boolean
  inset?: boolean
  primary?: ReactNode
  primaryTypographyProps?: object
  secondary?: ReactNode
  secondaryTypographyProps?: object
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItemText = React.forwardRef((props: ListItemTextProps, ref: any) => {
  const {
    children,
    primaryTypographyProps,
    secondaryTypographyProps,
    ...rest
  } = props

  return (
    <MUIListItemText
      primaryTypographyProps={{
        color: 'text.primary',
        ...primaryTypographyProps
      }}
      secondaryTypographyProps={{
        color: 'text.primary',
        ...secondaryTypographyProps
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MUIListItemText>
  )
})

ListItemText.displayName = 'ListItemText'
export default ListItemText
