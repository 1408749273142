import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

export type NavigationMenuStore = {
  open: boolean
  expandedFolders: string[]
}

// Initial state
const INITIAL_STATE: NavigationMenuStore = {
  open: true,
  expandedFolders: ['/dashboards', '/dashboards/favorites']
}

// Toggle navigation menu
const toggleNavigationMenu = createAction(Types.TOGGLE_NAVIGATION)
const toggleNavigationFolder = createAction<string>(
  Types.TOGGLE_NAVIGATION_FOLDER
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const NavigationMenuReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(toggleNavigationMenu, (state) => {
      return {
        ...state,
        open: !state.open
      }
    })
    .addCase(toggleNavigationFolder, (state, action) => {
      const { payload } = action

      if (state.expandedFolders.includes(payload)) {
        return {
          ...state,
          expandedFolders: state.expandedFolders.filter(
            (folder) => folder !== payload
          )
        }
      }

      return {
        ...state,
        expandedFolders: [...state.expandedFolders, payload]
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default NavigationMenuReducer
