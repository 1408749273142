import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return <Skeleton variant="rectangular" height={40} width={120} />
}

Loading.displayName = 'Loading'

export default Loading
