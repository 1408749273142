import React from 'react'

import Tag from 'components_new/atoms/Tag'

import { formatPercentageDiffValue } from 'components_new/organisms/Widget/types/utils'

interface TrendTagProps {
  increaseIsPositive: boolean
  sx?: object
  value: number
}

const TrendTag = (props: TrendTagProps) => {
  const { increaseIsPositive, sx, value } = props

  const status: 'success' | 'error' =
    increaseIsPositive && value > 0
      ? 'success'
      : !increaseIsPositive && value < 0
          ? 'success'
          : 'error'

  const label = `${value < 0 ? '▼ ' : '▲ '}${
    value > 0 ? '+' : ''
  }${formatPercentageDiffValue(value)}%`

  return <Tag color={status} label={label} sx={sx} />
}

export default TrendTag
