import React, { ReactNode } from 'react'

import NavigationHeader from 'components_new/molecules/NavigationHeader'
import PageContainer from 'components_new/organisms/PageContainer'
import SettingsNavigation from 'components_new/organisms/SettingsNavigation'

interface SettingsTemplateProps {
  children?: ReactNode
}

const SettingsTemplate = (props: SettingsTemplateProps) => {
  const { children } = props

  return (
    <PageContainer
      navHeader={<NavigationHeader title="Inställningar" />}
      navMenu={<SettingsNavigation />}
    >
      {children}
    </PageContainer>
  )
}

export default SettingsTemplate
