import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import Input from 'components/common/Input'
import Mentions from 'components/common/Mentions'
import DataTypeIcon from 'components/common/DataTypeIcon'
import IconHeaderFlag from 'components/common/IconHeaderFlag'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'

import Filters from './Filters'

import * as Constants from 'helpers/Constants'

import {
  getDuplicateColumnValidation,
  getAllAttributesWithinTypes
} from './conf'

import { OPERATION_TYPES } from '../conf'

import Styles from './styles.module.css'
import Tooltip from 'components_new/atoms/Tooltip'

const ALLOWED_CHARACTERS = ['+', '-', '*', '/', '(', ')']

const CalculateOperation = ({ chosenAttributes, formRef, type }) => {
  const [stateChosenAttributes, setStateChosenAttributes] = useState(
    _.cloneDeep(chosenAttributes)
  )
  const newAttributeName = formRef.getFieldValue('newAttribute')

  useEffect(() => {
    if (newAttributeName in stateChosenAttributes) {
      const copyState = _.cloneDeep(stateChosenAttributes)

      delete copyState[newAttributeName]
      setStateChosenAttributes(copyState)
    }
  }, [stateChosenAttributes])

  const options = getAllAttributesWithinTypes(stateChosenAttributes, [
    'DOUBLE',
    'BIGINT'
  ]).map((attr) => ({
    value: attr.outputAttributeName,
    label: (
      <p>
        {attr.outputAttributeName}
        <span className="secondary">{` - ${attr.tableName}`}</span>
      </p>
    )
  }))

  return (
    <>
      <Form layout="vertical" form={formRef}>
        <IconHeaderFlag
          icon={OPERATION_TYPES[type].icon}
          header={OPERATION_TYPES[type].label}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <Form.Item name={'newAttributeInit'} noStyle />
          <Form.Item
            name={'newAttribute'}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              getDuplicateColumnValidation(stateChosenAttributes)
            ]}
            className={Styles['form-item']}
          >
            <Input
              prefix={<DataTypeIcon type={'DOUBLE'} />}
              suffix={
                // eslint-disable-next-line max-len
                <Tooltip title="Resultatet av din beräkning kommer att lagras i en ny kolumn. Döp denna kolumn till ett lämpligt namn.">
                  <Icon name="HelpOutline" fontSize="small" />
                </Tooltip>
              }
              label={'Kolumn'}
            />
          </Form.Item>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <p>Beräkning</p>
          <Form.Item
            name={'value'}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              Constants.FORM_VALIDATIONS.MATHEMATICAL_EXPRESSION(options)
            ]}
            className={Styles['form-item']}
          >
            <Mentions label={'Uttryck'} options={options} />
          </Form.Item>
          <small className={'secondary'}>
            Välj en kolumn genom: <span className={'primary'}>@</span>
          </small>
          <small className={'secondary'}>
            Tillåtna karaktärer:{' '}
            {ALLOWED_CHARACTERS.map((char, i) => (
              <React.Fragment key={`allowed-characters-${i}-${char}`}>
                <span className={'black'}>{char}</span>
                {i === ALLOWED_CHARACTERS.length - 1 ? '' : ','}
              </React.Fragment>
            ))}
            .
          </small>
          <small className={'secondary'}>
            Notera att: Alla beräkningar med tomma värden (NULL) kommer
            resultera i ett tomt värde (NULL).
          </small>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Filters chosenAttributes={stateChosenAttributes} formRef={formRef} />
      </Form>
    </>
  )
}

export default CalculateOperation
