import * as Api from 'redux/api/Applications/InsightStudio/Widgets'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryPutWidgets(tableId, data, query) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_WIDGETS
    })

    Api.putWidgets(tableId, data, query)
      .then((response) => {
        dispatch({
          type: Types.PUT_WIDGETS_SUCCESS,
          payload: {
            data: response.data.data,
            tabId: data.data.attributes.dashboard_tab_id
          }
        })

        messageSuccess('Dashboarden har uppdaterats.')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_WIDGETS_FAILED
        })

        messageWarning('Det gick inte att uppdatera dashboarden.')
      })
  }
}

export function tryGetOneWidget(id, query, drillThrough = false) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_DASHBOARD_WIDGET,
      payload: id
    })

    Api.getOne(id, query)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_WIDGET_SUCCESS,
          payload: { data: response.data.data, drillThrough }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_WIDGET_FAILED,
          payload: { id }
        })
      })
  }
}

/**
 * Internal widget handling functions (edit mode)
 */

export function loadWidgetsToEdit(ids) {
  return (dispatch) => {
    dispatch({
      type: Types.LOAD_WIDGETS_TO_EDIT,
      payload: ids
    })
  }
}

export function removeWidget(id) {
  return (dispatch) => {
    dispatch({
      type: Types.REMOVE_WIDGET,
      payload: id
    })
  }
}

export function putWidget(data) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_WIDGET,
      payload: data
    })
  }
}

export function updateWidgetPositions(data) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET_POSITIONS,
      payload: data
    })
  }
}

export function resetWidgetsFetched() {
  return (dispatch) => {
    dispatch({
      type: Types.RESET_WIDGETS_FETCHED
    })
  }
}
