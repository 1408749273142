import React from 'react'

import _ from 'lodash'

import Box from 'components_new/atoms/Box'
import ListItemText from 'components_new/atoms/List/ListItemText'

interface TitleProps {
  disabled: boolean
  overline?: string
  query: string
  title: string
}

const Title = (props: TitleProps) => {
  const { disabled, overline, query, title } = props

  const getHighlighted = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return <Box component="span">{text}</Box>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)

    return (
      <Box component="span">
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <Box
                component="mark"
                sx={{
                  bgcolor: 'brand.main',
                  p: 0
                }}
                key={i}
              >
                {part}
              </Box>
            ) : (
              <Box component="span" key={i}>
                {part}
              </Box>
            )
          )}
      </Box>
    )
  }

  return (
    <ListItemText
      component="div"
      primary={overline}
      primaryTypographyProps={{ color: 'text.secondary', variant: 'caption' }}
      secondary={!overline ? getHighlighted(title, query) : title}
      secondaryTypographyProps={{
        color: disabled ? 'text.secondary' : undefined,
        fontWeight: 'medium',
        variant: 'body1'
      }}
      sx={{ my: 0 }}
    />
  )
}

export default Title
