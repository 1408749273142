import * as TagApi from 'redux/api/DataPlatform/tags/v1'
import * as Types from 'redux/Types'

export function tryGetAllTags() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_TAGS
    })

    TagApi.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_TAGS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_TAGS_FAILED
        })
      })
  }
}

export function tryPutTagLinker(data) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_TAG_LINKER
    })

    TagApi.put(data)
      .then((response) => {
        dispatch({
          type: Types.PUT_TAG_LINKER_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_TAG_LINKER_FAILED
        })
      })
  }
}

export function tryDeleteTagLinker(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_TAG_LINKER
    })

    TagApi.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_TAG_LINKER_SUCCESS,
          payload: id
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_TAG_LINKER_FAILED
        })
      })
  }
}
