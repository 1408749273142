import React, { ReactNode } from 'react'

import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Tooltip from 'components_new/atoms/Tooltip'

interface OptionMenuItemProps {
  children?: ReactNode
  disabled?: boolean
  name: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  selected: boolean
  value?: string
}

const OptionMenuItem = (props: OptionMenuItemProps) => {
  const { disabled, name, onClick, selected } = props

  if (!disabled) {
    return (
      <MenuItem
        disabled={disabled}
        onClick={
          onClick
            ? (e) => {
                if (!selected) {
                  onClick(e)
                }
              }
            : undefined
        }
        selected={selected}
      >
        {name}
      </MenuItem>
    )
  }

  return (
    <Tooltip title={'Saknar data'}>
      <div>
        <MenuItem disabled={disabled}>{name}</MenuItem>
      </div>
    </Tooltip>
  )
}

export default OptionMenuItem
