import React from 'react'

import IconDropdown from 'components/common/DropdownButton/IconDropdown'
import StatusBanner from 'components/common/StatusBanner'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

export const API_KEY_HEADERS = (
  setManageApiKey,
  setAlert,
  resetAlert,
  deleteApiKey,
  show,
  setShow
) => [
  {
    label: 'API-nycklar',
    sorter: (a, b) => (a.name > b.name ? -1 : 1),
    render: function renderColumn(r) {
      return (
        <Box key={r.key} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box>
            <b>{r.name}</b>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flex: 1 }}
          >
            {show[r.key] ? r.value : '•'.repeat(r.value.length)}
            <IconButton
              onClick={() => setShow({ ...show, [r.key]: !show[r.key] })}
              size="small"
            >
              <Icon
                fontSize="small"
                name={
                  show[r.key] ? 'VisibilityOutlined' : 'VisibilityOffOutlined'
                }
              />
            </IconButton>
          </Box>
        </Box>
      )
    }
  },
  {
    label: '',
    align: 'right',
    width: '3rem',
    render: function renderColumn(r) {
      return (
        <IconDropdown
          icon={'MoreHorizOutlined'}
          iconClassName={Styles['action-dropdown-icon']}
          items={[
            {
              key: 'option-dropdown-0',
              items: [
                {
                  label: 'Redigera namn',
                  onClick: () => setManageApiKey({ id: r.key, name: r.name })
                },
                {
                  label: 'Ta bort',
                  remove: true,
                  onClick: () =>
                    setAlert({
                      header: 'Ta bort API-nyckel',
                      color: 'warning',
                      content: (
                        <Box>
                          <StatusBanner
                            title={'Tänk på!'}
                            message={
                              // eslint-disable-next-line max-len
                              'Alla tjänster som använder din delade data kan sluta fungera då du tar bort det.'
                            }
                            type={'warning'}
                            showIcon
                          />
                          <Text variant="body1">
                            Vill du verkligen ta bort aktuell API-nyckel?
                          </Text>
                        </Box>
                      ),
                      onCancel: () => resetAlert(),
                      onOk: () => {
                        deleteApiKey(r.key)
                        resetAlert()
                      },
                      cancelText: 'Avbryt',
                      okText: 'Ta bort'
                    })
                }
              ]
            }
          ]}
        />
      )
    }
  }
]

export const getApiKeys = (apiKeys) => {
  if (!apiKeys) {
    return []
  }

  return apiKeys.map((api) => ({
    key: api.id,
    name: api.attributes.name,
    value: api.attributes.value
  }))
}

export const getEndpointHeaders = (getEndpointInformation) => [
  {
    label: 'Objekt',
    key: 'slug',
    sorter: (a, b) => (a?.slug > b?.slug ? -1 : 1)
  },
  {
    label: 'Dataset',
    key: 'dataset',
    sorter: (a, b) => (a?.dataset > b?.dataset ? -1 : 1)
  },
  {
    label: '',
    align: 'right',
    render: function renderColumn(r) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            onClick={() =>
              getEndpointInformation(r.endpointId, r.slug, r.table)
            }
            variant="text"
          >
            Redigera
          </Button>
        </Box>
      )
    }
  }
]

export const getEndpointRows = (tables) => {
  if (!tables) {
    return []
  }

  return tables.map((table) => ({
    key: table.id,
    endpointId: table.included.endpoint.id,
    slug: table.included.endpoint.attributes.slug,
    dataset: table.attributes.plural,
    primaryKey: table.attributes.primary_key_attribute,
    table
  }))
}

export const expandableTable = () => {
  return {
    expandedRowRender: (record) => {
      let primaryKeyString = 'id'

      if (record.primaryKey) {
        const pk = record.table.attributes.output_attributes.find(
          (item) => item.id === record.primaryKey
        )

        primaryKeyString = pk?.attributes?.name
      }

      return (
        <div>
          {expandedRow('get', `/${record.slug}`, null, 'Listar alla')}
          {record.primaryKey
            ? expandedRow(
              'get',
              `/${record.slug}/`,
              `:${primaryKeyString}`,
              'Hämtar en'
            )
            : null}
        </div>
      )
    },
    defaultExpandAllRows: true,
    expandIcon: ({ record, expanded, onExpand }) => (
      <IconButton onClick={(e) => onExpand(record, e)}>
        <Icon name={expanded ? 'ExpandMore' : 'ChevronRight'} />
      </IconButton>
    )
  }
}

const expandedRow = (method, endpoint, param, information) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          gap: 1
        }}
      >
        <Box>
          <Chip label={method.toUpperCase()} size="small" />
        </Box>
        <Box>
          <Text variant="body1">
            <b>{endpoint}</b> {param}
          </Text>
        </Box>
        <Box>({information})</Box>
      </Box>
      <Divider />
    </>
  )
}
