import React, { useState, useEffect } from 'react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'

import Input from 'components/common/Input'

import * as Conf from './conf'

const SearchWrapper = ({
  data,
  filterKey,
  DataComponent,
  condition = () => true,
  placeholder = 'Sök...',
  className = '',
  inputSize = 'large'
}) => {
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (search === '') {
      setFilteredData(data)
    } else {
      const newData = Conf.filterData(data, filterKey, search, condition)

      setFilteredData(newData)
    }
  }, [search])

  return (
    <Box className={className}>
      <Box sx={{ p: 1 }}>
        <Input
          prefix={<Icon name="SearchOutlined" />}
          placeholder={placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size={inputSize}
        />
      </Box>
      <Divider />
      <Box sx={{ py: 1, height: '25rem', overflowY: 'scroll' }}>
        <DataComponent data={filteredData} search={search} />
      </Box>
    </Box>
  )
}

export default SearchWrapper
