import React from 'react'

import { Row, Col } from 'antd'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import Styles from './styles.module.css'

const HeaderBar = ({
  steps,
  currentStep,
  setCurrentStep,
  headerTitle,
  onSave,
  onClose,
  loading
}) => {
  return (
    <div className={Styles['header-bar']}>
      <Row className="width-100">
        {!loading ? (
          <Col span={8} className={Styles['close-button']}>
            <IconButton edge="start" onClick={onClose}>
              <Icon name="Close" />
            </IconButton>
          </Col>
        ) : (
          <Col span={8}></Col>
        )}

        <Col span={8} className={Styles['text-area']}>
          <p>
            {headerTitle} &gt;{' '}
            <span className="primary">{steps[currentStep]?.title}</span>
          </p>
        </Col>

        <Col span={8} className={Styles['button-stepper-container']}>
          {currentStep > 0 && !loading && (
            <Button
              onClick={() => setCurrentStep(currentStep - 1)}
              startIcon={<Icon name="ChevronLeft" />}
              variant="text"
            >
              Föregående
            </Button>
          )}
          {currentStep === steps.length - 1 ? (
            <Button
              disabled={loading}
              loading={loading}
              onClick={() => onSave()}
              startIcon={<Icon name="Save" />}
            >
              Spara
            </Button>
          ) : (
            <Button
              endIcon={<Icon name="ChevronRight" />}
              onClick={async () => {
                if (await steps[currentStep]?.onNext()) {
                  setCurrentStep(currentStep + 1)
                }
              }}
            >
              Nästa
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default HeaderBar
