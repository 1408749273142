import React, { MouseEvent, useState } from 'react'
import { Theme } from '@mui/material'

import { ParsedSegmentPath, ParsedWidgetDataLabel } from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import Text from 'components_new/atoms/Text'

import TrendText from 'components_new/molecules/TrendText'

import Marker from '../Marker'

interface SingleProps {
  differences: (number | null)[]
  kpis: string[]
  menuOptions: {
    title: (segments: ParsedSegmentPath[], link?: string | null) => string
    onClick: (segments: ParsedSegmentPath[], link?: string | null) => void
    iconName: IconNames
  }[]
  theme: Theme
  label: ParsedWidgetDataLabel
  units: (string | null)[]
  values: number[]
  percentages: (number | null)[]
  increaseIsPositives: boolean[]
}

const Single = (props: SingleProps) => {
  const {
    differences,
    increaseIsPositives,
    kpis,
    label,
    menuOptions,
    percentages,
    theme,
    units,
    values
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const showComparative =
    percentages.length > 0 && percentages.some((value) => value !== null)

  return (
    <>
      <Marker
        body={
          <Table noDividers={true} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ py: 0, pl: 0.5 }}>
                  {''}
                </TableCell>
                <TableCell align="right" sx={{ py: 0, pl: 0.5 }}>
                  <Text
                    color="text.primary"
                    component="span"
                    noWrap={true}
                    variant="caption"
                  >
                    Totalt
                  </Text>
                </TableCell>
                {showComparative ? (
                  <>
                    <TableCell align="right" sx={{ py: 0 }}>
                      <Text
                        color="text.primary"
                        component="span"
                        noWrap={true}
                        variant="caption"
                      >
                        jfr.
                      </Text>
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {values !== undefined &&
                kpis.map((kpi, i) => (
                  <TableRow key={kpi}>
                    <TableCell align="left" sx={{ py: 0, pl: 0.5 }}>
                      <Text
                        color="text.primary"
                        component="span"
                        fontWeight="medium"
                        noWrap={true}
                        variant="body2"
                      >
                        {kpi}
                      </Text>
                    </TableCell>
                    <TableCell align="right" sx={{ py: 0, pl: 0.5 }}>
                      <Text
                        color="text.primary"
                        component="span"
                        fontWeight="medium"
                        variant="body2"
                      >
                        {values[i]}
                      </Text>
                      {units[i] ? (
                        <Text
                          color="text.secondary"
                          component="span"
                          sx={{
                            ml: '0.25em'
                          }}
                          variant="caption"
                        >
                          {units[i]}
                        </Text>
                      ) : null}
                    </TableCell>
                    {showComparative ? (
                      <>
                        <TableCell align="right" sx={{ py: 0 }}>
                          {percentages[i] !== null ? (
                            <TrendText
                              difference={differences[i] as number}
                              increaseIsPositive={increaseIsPositives[i]}
                              // numberOfDecimals={numberOfDecimals[i]} // @TODO
                              numberOfDecimals={0}
                              percentage={percentages[i] as number}
                            />
                          ) : (
                            ''
                          )}
                        </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        }
        theme={theme}
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Icon color="inherit" fontSize="small" name="DomainOutlined" />
            <Text
              onClick={(event) => handleClick(event)}
              variant="body2"
              noWrap={true}
              sx={{
                color: 'common.white',
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'none'
                }
              }}
            >
              {label.display_label}
            </Text>
          </Box>
        }
      />

      {/* ----- Menu ----- */}
      <Menu
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={() => setAnchorEl(null)}
        open={open}
      >
        {menuOptions
          ? menuOptions.map((option, i) => (
            <MenuItem
              key={i}
              onClick={(event) => {
                handleClose(event)

                option.onClick([label], null)
              }}
              onMouseDown={(event) => event.stopPropagation()}
            >
              <ListItemIcon>
                <Icon name={option.iconName} fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={option.title([label], null)} />
            </MenuItem>
          ))
          : null}
      </Menu>
    </>
  )
}

export default Single
