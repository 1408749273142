import React from 'react'

import TextField from 'components/common/TextField'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import Styles from './styles.module.css'

const ApiSection = ({
  table,
  primaryKey,
  api,
  customerName,
  setPutApiModal,
  setAlert,
  resetAlert,
  onRemove
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button
          onClick={() =>
            setPutApiModal({
              cmdb_api_id: api.id,
              slug: table.attributes.slug
            })
          }
          variant="text"
        >
          Uppdatera
        </Button>
        <Button
          onClick={() =>
            setAlert({
              header: 'Ta bort API-koppling',
              content: (
                <p>
                  Är du säker på att du vill ta bort kopplingen till{' '}
                  {api.attributes.title}?
                </p>
              ),
              onOk: () => {
                onRemove()
                resetAlert()
              },
              cancelText: 'Avbryt',
              okText: 'Ta bort'
            })
          }
          variant="text"
        >
          Ta bort
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField text={api.attributes.title} label={'API-referens'} />
        <TextField
          text={primaryKey.attributes.column_name}
          label={'Primär nyckel'}
        />
        <TextField text={api.attributes.api_key} label={'API-nyckel'} hidden />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <p
          className={Styles['slug-prefix']}
        >{`${process.env.REACT_APP_FLOW_API}${customerName}/build/${api.attributes.slug}/`}</p>
        <TextField
          className={Styles['slug-text-field']}
          text={table.attributes.slug}
          label={'Slug'}
        />
      </Box>
    </Box>
  )
}

export default ApiSection
