import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Skeleton from 'components_new/atoms/Skeleton'
import Text from 'components_new/atoms/Text'

import TrendText from 'components_new/molecules/TrendText'

import {
  CustomPeriodFilter,
  DataType,
  DateFilterType,
  PeriodFilter,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import { getDifference, getPercentage } from '../../types/KeyFigure/utils'
import {
  getComparativePeriodLabel,
  getDisplayedPeriod,
  getPeriodLabel
} from './utils'

import DetailsDialog from '../../dialogs/DateDetailsDialog'
import Lock from './Lock'
import { getParsedDate } from 'utils/dateParser'

interface DateFilterSectionProps {
  loading: boolean
  dashboardCustomPeriodFilter: CustomPeriodFilter | null
  dashboardPeriodFilter: PeriodFilter | null
  scaleFactor: number
  widget: WidgetObject
}

const DateFilterSection = (props: DateFilterSectionProps) => {
  const {
    loading,
    dashboardCustomPeriodFilter,
    dashboardPeriodFilter,
    scaleFactor,
    widget
  } = props

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const widgetType = widget.settings.type.selected

  // Settings
  const isLocked = widget.settings.date_filter_is_locked
  const dateFilterType = widget.settings.date_filter.type
  const isPoint =
    dateFilterType === DateFilterType.POINT &&
    widgetType !== WidgetType.LINE_CHART &&
    widget.settings.segment_by.type !== DataType.DATE
  const isKeyFigure = widgetType === WidgetType.KEY_FIGURE
  const rollingActive = widget.settings.date_filter.rolling_active
  const showDates = widget.settings.show_date
  const widgetPeriodFilter = widget.settings.date_filter.selected?.value

  if (
    !dashboardCustomPeriodFilter &&
    !dashboardPeriodFilter &&
    !widgetPeriodFilter
  ) {
    return null
  }

  const displayedPeriod = getDisplayedPeriod(
    dashboardCustomPeriodFilter,
    dashboardPeriodFilter,
    isLocked,
    widgetPeriodFilter
  )

  // Forecast
  const forecastPeriod = widget.settings.forecast_period.selected

  // Period
  const periodFromDate = widget.settings.date_filter.selected.from_date
  const periodToDate = widget.settings.date_filter.selected.to_date
  const restrictToPeriod =
    widget.settings.date_filter.restrict_to_period.selected

  const periodLabel = isPoint
    ? getParsedDate(periodFromDate)
    : getPeriodLabel(
      displayedPeriod.filter,
      displayedPeriod.type,
      forecastPeriod,
      isPoint,
      rollingActive,
      restrictToPeriod
    )

  if (!periodFromDate) {
    return null
  }

  // Comparative period
  const comparativePeriod = widget.settings.comparative_period.selected.value
  const comparativePeriodFromDate =
    widget.settings.comparative_period.selected.from_date
  const comparativePeriodToDate =
    widget.settings.comparative_period.selected.to_date

  const comparativePeriodLabel = getComparativePeriodLabel(
    displayedPeriod.filter,
    displayedPeriod.type,
    comparativePeriod,
    comparativePeriodFromDate,
    comparativePeriodToDate,
    isPoint,
    forecastPeriod
  )

  const showComparativePeriod = Boolean(comparativePeriod)

  // Trend
  const comparativeValue = widget.data?.comparative_value || 0
  const increaseIsPositive =
    widget.settings.kpi_options[0].increase_is_positive ?? true
  const numberOfDecimals =
    widget.settings.kpi_options[0].number_of_decimals || 0
  const unitPrefixSelected =
    widget.settings.kpi_options[0].unit_prefix.selected || null
  const value = widget.data?.value || 0
  const equivalentFactor = widget.settings.kpi_options[0].equivalent.factor

  const difference = getDifference(value, comparativeValue)
  const percentage = getPercentage(value, comparativeValue)

  if (loading) {
    return (
      <>
        {showDates ? (
          <>
            <Skeleton width="50%" height={14 * 1.5 * scaleFactor} />
            {showComparativePeriod ? (
              <Skeleton width="75%" height={14 * 1.5 * scaleFactor} />
            ) : null}
          </>
        ) : null}
      </>
    )
  }

  return showDates || (isKeyFigure && comparativePeriod) ? (
    <>
      <Box
        onClick={() => setDialogOpen(true)}
        sx={{
          color: 'text.secondary',
          cursor: 'pointer',
          fontSize: 14 * scaleFactor,
          '&:hover': {
            color: 'text.primary',
            '& .date-filter-section-arrow': {
              display: 'flex'
            },
            '& .date-filter-section-bg': {
              display: 'block'
            },
            paddingRight: `calc(${4 * scaleFactor}px + 1.5em)`
          },
          mt: scaleFactor,
          position: 'relative'
        }}
      >
        {/*-- Selected period + Forecast period --*/}
        {showDates ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              position: 'relative',
              zIndex: 1
            }}
          >
            <Text
              color="inherit"
              fontSize="inherit"
              fontWeight="medium"
              noWrap={true}
            >
              {periodLabel}
            </Text>

            {/*-- Lock --*/}
            {isLocked ? <Lock /> : null}
          </Box>
        ) : null}

        {/*-- Comparative period --*/}
        {showComparativePeriod ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              position: 'relative',
              zIndex: 1
            }}
          >
            <Text color="inherit" fontSize="inherit" noWrap={true}>
              {/*-- Optional key figure trend --*/}
              {isKeyFigure ? (
                <>
                  <TrendText
                    equivalentFactor={equivalentFactor}
                    difference={difference}
                    increaseIsPositive={increaseIsPositive}
                    numberOfDecimals={numberOfDecimals}
                    percentage={percentage}
                    unitPrefix={unitPrefixSelected}
                  />{' '}
                </>
              ) : null}
              {showDates ? (
                <>
                  <Text
                    color="inherit"
                    component="span"
                    fontSize="inherit"
                    fontWeight="regular"
                  >
                    jfr.{' '}
                  </Text>
                  <Text
                    color="inherit"
                    component="span"
                    fontSize="inherit"
                    fontWeight="medium"
                  >
                    {comparativePeriodLabel?.toLowerCase()}
                  </Text>
                </>
              ) : null}
            </Text>

            {/*-- Lock --*/}
            {isLocked ? <Lock /> : null}
          </Box>
        ) : null}

        {/*-- Hover arrow --*/}
        <Box
          className="date-filter-section-arrow"
          sx={{
            alignItems: 'center',
            display: 'none',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            pr: `${4 * scaleFactor}px`
          }}
        >
          <Icon
            color="inherit"
            name="ArrowForward"
            sx={{ fontSize: '1.5em' }}
          />
        </Box>

        {/*-- Hover background --*/}
        <Box
          className="date-filter-section-bg"
          sx={{
            display: 'none',
            height: `calc(100% + ${4 * scaleFactor}px)`,
            width: `calc(100% + ${8 * scaleFactor}px)`,
            position: 'absolute',
            top: `-${2 * scaleFactor}px`,
            left: `-${4 * scaleFactor}px`,
            bgcolor: 'action.hover',
            zIndex: 0
          }}
        />
      </Box>

      {/*-- Dialog --*/}
      <DetailsDialog
        comparativePeriodFromDate={comparativePeriodFromDate}
        comparativePeriodLabel={comparativePeriodLabel}
        comparativePeriodToDate={comparativePeriodToDate}
        dateFilter={displayedPeriod.filter}
        isLocked={isLocked}
        isPoint={isPoint}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        periodFromDate={periodFromDate}
        periodLabel={periodLabel}
        periodToDate={periodToDate}
        showComparativePeriod={showComparativePeriod}
      />
    </>
  ) : (
    <></>
  )
}

export default DateFilterSection
