import React, { useEffect, useState, useMemo } from 'react'

import ShortId from 'shortid'
import _ from 'lodash'

import { Row, Col } from 'antd'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import Select from 'components/common/Select'
import Input from 'components/common/Input'

import Node from './index.js'

import Styles from './styles.module.css'
import {
  DATE_PARSER_FORMAT_OPTIONS,
  DATE_FORMAT_OPTIONS,
  TIMESTAMP_FORMAT_OPTIONS,
  parseAttributeOptions
} from './conf.js'

const DateParserNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const [expanded, setExpanded] = useState(false)
  const [newAttribute, setNewAttribute] = useState(undefined)

  useEffect(() => {
    const tmpNewAttribute = data.outputAttributes.find(
      (attr) => attr.shortId === data.newAttribute
    )

    setNewAttribute(tmpNewAttribute)
  }, [])

  useEffect(() => {
    if (leftAttributes.length > 0) {
      const realNamesInOutAttributes = data.outputAttributes
        .filter((attr) => attr.shortId !== data.newAttribute)
        .map((attr) => attr.realName)

      const diffLeftAttributes = leftAttributes
        .filter((attr) => !realNamesInOutAttributes.includes(attr.realName))
        .map((attr) => attr)

      if (diffLeftAttributes.length > 0) {
        setData(id, {
          outputAttributes: [
            ...leftAttributes.map((attr) => ({
              ...attr,
              leftAttribute: null,
              rightAttribute: null,
              shortId: ShortId.generate()
            })),
            newAttribute
          ],
          leftAttribute: null
        })
      }
    }
  }, [leftAttributes])

  return (
    <Node
      icon="EventAvailableOutlined"
      title={'Date parser'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box
        sx={{
          width: '240px',
          display: 'flex',
          flexDirection: 'column',
          gap: 0.25
        }}
      >
        <Select
          options={parseAttributeOptions(leftAttributes, data.nodeNumberMapper)}
          search
          ignoreLockedMode
          inNode
          size={'small'}
          containerClassName={'width-100 nodrag'}
          placeholder={<small className="secondary tiny">Attribut...</small>}
          value={data.leftAttribute}
          onChange={(value) => {
            const tmpOutput = _.cloneDeep(data.outputAttributes)
            const newAttributeIndex = tmpOutput.findIndex(
              (attr) => attr.shortId === data.newAttribute
            )

            if (newAttributeIndex > -1) {
              const leftAttribute = leftAttributes.find(
                (oa) => oa.shortId === value
              )

              tmpOutput[newAttributeIndex].sensitiveRefColumnId =
                leftAttribute?.sensitiveRefColumnId

              setNewAttribute({
                ...newAttribute,
                sensitiveRefColumnId: leftAttribute?.sensitiveRefColumnId
              })
            }

            setData(id, { leftAttribute: value, outputAttributes: tmpOutput })
          }}
        />
        <Input
          className={'nodrag'}
          size={'small'}
          placeholder={'Format...'}
          value={data.value}
          onChange={(val) => {
            setData(id, { value: val.target.value })
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Icon name="ArrowDownward" fontSize="small" />
        </Box>
        <Input
          size={'small'}
          containerClassName={'width-100 nodrag'}
          placeholder={'Ny kolumn...'}
          value={newAttribute?.name}
          onChange={(val) => {
            const tmpOutput = _.cloneDeep(data.outputAttributes)
            const newAttributeIndex = tmpOutput.findIndex(
              (attr) => attr.shortId === data.newAttribute
            )

            if (newAttributeIndex > -1) {
              tmpOutput[newAttributeIndex].name = val.target.value
              tmpOutput[newAttributeIndex].realName = val.target.value

              setNewAttribute({
                ...newAttribute,
                name: val.target.value,
                realName: val.target.value
              })

              setData(id, { outputAttributes: tmpOutput })
            }
          }}
        />
        <Select
          className={'nodrag'}
          ignoreLockedMode
          inNode
          size={'small'}
          clearable={false}
          options={DATE_PARSER_FORMAT_OPTIONS}
          value={newAttribute?.type}
          onChange={(value) => {
            const tmpOutput = _.cloneDeep(data.outputAttributes)
            const newAttributeIndex = tmpOutput.findIndex(
              (attr) => attr.shortId === data.newAttribute
            )

            if (newAttributeIndex > -1) {
              tmpOutput[newAttributeIndex].type = value

              setNewAttribute({
                ...newAttribute,
                type: value
              })

              setData(id, { outputAttributes: tmpOutput })
            }
          }}
        />

        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title={
                  // eslint-disable-next-line max-len
                  'Expandera sektionen för att få mer information om vilka format som stöds och hur de ska skrivas.'
                }
              >
                <Icon fontSize="small" name="InfoOutlined" sx={{ mr: 0.5 }} />
              </Tooltip>
              <small className={'tiny'}>Format</small>
            </Box>
            <IconButton
              className={'nodrag'}
              edge="end"
              onClick={() => setExpanded(!expanded)}
              size="small"
            >
              <Icon
                fontSize="small"
                name={expanded ? 'ExpandLess' : 'ExpandMore'}
              />
            </IconButton>
          </Box>
          <Divider />
          {expanded && (
            <Row
              className={Styles['date-parser-format-information']}
              gutter={4}
            >
              <Col span={12}>
                <div className={Styles['date-parser-format-header']}>
                  <small className="inherit">DATE</small>
                </div>
                {DATE_FORMAT_OPTIONS.map((opt, i) => (
                  <Row
                    key={`date-format-${i}`}
                    className={Styles['date-parser-format-option-container']}
                  >
                    <Col span={12}>
                      <small className="secondary tiny font-weight-700">
                        {opt.title}
                      </small>
                    </Col>
                    <Col span={12}>
                      <small className="secondary tiny">{opt.label}</small>
                    </Col>
                  </Row>
                ))}
                <div className={Styles['date-parser-tooltip']}>
                  <Tooltip
                    title={
                      // eslint-disable-next-line max-len
                      'Använd ett wildcard i början eller slutet av datumformatet för att ignorera karaktärer som är ointressanta.'
                    }
                  >
                    <Icon name="InfoOutlined" fontSize="small" />
                  </Tooltip>
                  <small className="tiny inherit">Wildcard?</small>
                </div>
              </Col>

              <Col span={12}>
                <div className={Styles['date-parser-format-header']}>
                  <small className="inherit">TIMESTAMP</small>
                </div>
                {TIMESTAMP_FORMAT_OPTIONS.map((opt, i) => (
                  <Row
                    key={`date-format-${i}`}
                    className={Styles['date-parser-format-option-container']}
                  >
                    <Col span={12}>
                      <small className="secondary tiny font-weight-700">
                        {opt.title}
                      </small>
                    </Col>
                    <Col span={12}>
                      <small className="secondary tiny">{opt.label}</small>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}
        </Box>
      </Box>
    </Node>
  )
}

export default DateParserNode
