import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Fab as MUIFab } from '@mui/material'

interface FabProps {
  children?: ReactNode
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'quintary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'black'
    | 'white'
    | 'neutral'
  component?: ElementType<any>
  disabled?: boolean
  disableRipple?: boolean
  href?: string
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large'
  sx?: object
  variant?: 'circular' | 'extended'
}

/**
 * The Fab component (Floating Action Button) performs
 * the primary, or most common, action on a screen.
 *
 * A floating action button appears in front of all
 * screen content, typically as a circular shape.
 *
 * Only one component per screen is recommended.
 */

const Fab = React.forwardRef((props: FabProps, ref: any) => {
  return (
    <MUIFab {...props} ref={ref}>
      {props.children}
    </MUIFab>
  )
})

Fab.displayName = 'Fab'
export default Fab
