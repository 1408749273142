import React from 'react'

import { Form } from 'antd'

import DataTypeIcon from 'components/common/DataTypeIcon'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Text from 'components_new/atoms/Text'

import Condition from './Condition'

import Styles from '../styles.module.css'

const Filters = ({ chosenAttributes, formRef, title, addText }) => {
  const attributeOptions = Object.values(chosenAttributes)
    .sort((a, b) =>
      a.outputAttributeName?.toLowerCase() <
      b.outputAttributeName?.toLowerCase()
        ? -1
        : 1
    )
    .map((attribute) => ({
      label: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <DataTypeIcon type={attribute.type} />
          <Text>{attribute.outputAttributeName}</Text>
          <Text color="text.secondary">{attribute.tableName}</Text>
        </Box>
      ),
      value: attribute.outputAttributeId,
      type: attribute.type
    }))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {title ? title : <p className={'bold'}>Villkor</p>}
      <Form.List name={'conditions'}>
        {(fields, { add, remove }) => {
          const elements = fields.map(({ name, restField }) => (
            <>
              <Condition
                remove={() => remove(name)}
                restField={restField}
                formRef={formRef}
                attributeOptions={attributeOptions}
                name={name}
                formListPath={'conditions'}
                namePrepend={[name]}
                key={`element-${name}`}
              />

              {name !== fields.length - 1 && (
                <Form.Item
                  name={[name, 'conjunctiveOperator']}
                  {...restField}
                  className={Styles['form-item']}
                  key={`form-item-${name}`}
                >
                  <ToggleButton
                    items={[
                      { title: 'OCH', value: 'AND' },
                      { title: 'ELLER', value: 'OR' }
                    ]}
                    size="small"
                  />
                </Form.Item>
              )}
            </>
          ))

          elements.push(
            <Button
              fullWidth
              key={'add-area'}
              onClick={() => {
                add({ type: null, conjunctiveOperator: 'AND' })

                const values = formRef.getFieldsValue()

                if (elements.length > 1) {
                  values.conditions[elements.length - 2].conjunctiveOperator =
                    'AND'
                  formRef.setFieldsValue(values)
                }
              }}
              size="large"
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
              variant="text"
            >
              {addText ?? 'Lägg till'}
            </Button>
          )

          return elements
        }}
      </Form.List>
    </Box>
  )
}

export default Filters
