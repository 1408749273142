import React from 'react'
import { getBezierPath, getMarkerEnd } from 'reactflow'

import { useTheme } from '@mui/material'

const Edge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  arrowHeadType,
  markerEndId,
  selected
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })[0]
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
  const theme = useTheme()

  const style = {
    stroke: selected ? theme.palette.primary.dark : theme.palette.primary.main,
    strokeWidth: 4
  }

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
    />
  )
}

export default Edge
