import React, { useMemo } from 'react'

import TableRow from 'components_new/atoms/Table/TableRow'

import FooterCell from '../FooterCell'
import { FormattedWidgetDataDataset } from 'types/GlobalWidget'
import { useTheme } from '@mui/material'

interface FixedFooterContentProps {
  datasets: FormattedWidgetDataDataset[]
  scaleFactor: number
}

const FooterContent = (props: FixedFooterContentProps) => {
  const { datasets, scaleFactor } = props

  const theme = useTheme()

  const footerCells = useMemo(
    () =>
      datasets.map((dataset) => (
        <FooterCell
          key={dataset.index}
          scaleFactor={scaleFactor}
          value={
            dataset.total !== null ? `${dataset.total} ${dataset.unit}` : ''
          }
        />
      )),
    [datasets, scaleFactor]
  )

  return (
    <TableRow
      sx={{
        position: 'relative',
        bgcolor: theme.palette.background.widget,
        backgroundImage: `linear-gradient(${theme.palette.background.overlay}, ${theme.palette.background.overlay})`
      }}
    >
      <FooterCell align={'start'} scaleFactor={scaleFactor} value={'Totalt'} />
      {footerCells}
    </TableRow>
  )
}

export default FooterContent
