import React, { ChangeEvent, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'

import {
  getValidateItems,
  handleEnter,
  validateNewPasswordCapsCharacter,
  validateNewPasswordLength,
  validateNewPasswordNumber,
  validateNewPasswordSmallCharacter,
  validateRequired
} from '../utils'

interface ResetPasswordProps {
  confirmationCode: string
  errors: { message: string }[]
  loading: boolean
  onBackToSignIn: () => void
  onResetPassword: (code: string, password: string) => void
  setErrors: (errors: { message: string }[]) => void
}

const ResetPassword = (props: ResetPasswordProps) => {
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false)

  const validLength = validateNewPasswordLength(newPassword)
  const validSmallChar = validateNewPasswordSmallCharacter(newPassword)
  const validCapsChar = validateNewPasswordCapsCharacter(newPassword)
  const validNumber = validateNewPasswordNumber(newPassword)
  const validPassword =
    validLength && validSmallChar && validCapsChar && validNumber

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setNewPassword(e.target.value)
  }

  const handleChangeRepeatNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setRepeatNewPassword(e.target.value)
  }

  const handleResetPassword = () => {
    const validCode = validateRequired(props.confirmationCode)
    const passwordMatch = newPassword === repeatNewPassword

    if (validCode && passwordMatch) {
      props.onResetPassword(props.confirmationCode, newPassword)
    } else {
      if (!passwordMatch) {
        setErrorRepeatPassword(true)
      }
    }
  }

  return (
    <>
      <AuthHero
        title="Återställ lösenord"
        subtitle="Välj ett nytt lösenord till ditt konto."
      />
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box>
            <TextField
              error={errorRepeatPassword}
              fullWidth
              helperText={
                errorRepeatPassword
                  ? 'Lösenorden som du angivit matchar inte.'
                  : undefined
              }
              label={'Nytt lösenord'}
              onChange={handleChangeNewPassword}
              onKeyDown={(e) => handleEnter(e, handleResetPassword)}
              value={newPassword}
              type={'password'}
            />
            <TextField
              error={errorRepeatPassword}
              fullWidth
              label={'Återupprepa nytt lösenord'}
              helperText={
                errorRepeatPassword
                  ? 'Lösenorden som du angivit matchar inte.'
                  : undefined
              }
              onChange={handleChangeRepeatNewPassword}
              onKeyDown={(e) => handleEnter(e, handleResetPassword)}
              value={repeatNewPassword}
              type={'password'}
              sx={{ mb: 4 }}
            />
          </Box>
          <List dense={true}>
            {getValidateItems(newPassword).map((item, i) => {
              const color = !newPassword
                ? undefined
                : item.valid
                  ? 'success'
                  : 'error'

              return (
                <ListItem key={i}>
                  <ListItemIcon>
                    <Icon
                      color={color}
                      name={
                        item.valid ? 'CheckCircleOutlined' : 'CancelOutlined'
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      color: color ? `${color}.text` : 'text.primary',
                      noWrap: true
                    }}
                  />
                </ListItem>
              )
            })}
          </List>
        </Box>
        <Button
          color="brand"
          disabled={!validPassword || !repeatNewPassword}
          fullWidth={true}
          loading={props.loading}
          loadingTitle="Återställer ditt lösenord"
          onClick={handleResetPassword}
          size="large"
          sx={{ mb: 4 }}
        >
          Återställ lösenord
        </Button>
        {props.errors.length > 0 ? (
          <Alert severity={'error'} variant={'filled'}>
            {props.errors[0].message}
          </Alert>
        ) : null}
        <Button
          fullWidth={true}
          onClick={() => {
            props.setErrors([])

            props.onBackToSignIn()
          }}
          startIcon={<Icon name="ArrowBackOutlined" />}
          variant="text"
        >
          Tillbaka till inloggning
        </Button>
      </Box>
    </>
  )
}

ResetPassword.displayName = 'ResetPassword'

export default ResetPassword
