import React, { MouseEventHandler, ReactNode } from 'react'
import { ListItemIcon as MUIListItemIcon } from '@mui/material'

interface ListItemIconProps {
  children: ReactNode
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  position?: 'start' | 'end'
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItemIcon = React.forwardRef((props: ListItemIconProps, ref: any) => {
  const { children, position, sx, ...rest } = props

  return (
    <MUIListItemIcon
      {...rest}
      ref={ref}
      sx={{
        justifyContent: position === 'end' ? 'flex-end' : undefined,
        ...sx
      }}
    >
      {children}
    </MUIListItemIcon>
  )
})

ListItemIcon.displayName = 'ListItemIcon'
export default ListItemIcon
