import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'
import Paper from 'components_new/atoms/Paper'
import List from 'components_new/atoms/List'

interface SettingsGroupProps {
  actions?: ReactNode
  children: ReactNode
  helperText?: ReactNode
  title?: string
  sx?: object
}

const SettingsGroup = React.forwardRef(
  (props: SettingsGroupProps, ref: any) => {
    const { actions, children, helperText, sx, title } = props

    return (
      <Box ref={ref} sx={{ mb: 1, ...sx }}>
        {title || actions ? (
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            {title ? (
              <Text color="text.secondary" component="div" variant="overline">
                {title}
              </Text>
            ) : null}
            {actions ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {actions}
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Paper variant="outlined">
          <List sx={{ p: 0 }}>{children}</List>
        </Paper>
        <Box sx={{ px: 2, py: 0.75 }}>
          <Text color="text.secondary" component="div" variant="caption">
            {helperText}
          </Text>
        </Box>
      </Box>
    )
  }
)

SettingsGroup.displayName = 'SettingsGroup'

export default SettingsGroup
