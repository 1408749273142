import React, { useLayoutEffect } from 'react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import DateCalendar from 'components_new/atoms/DateCalendar'
import Text from 'components_new/atoms/Text'

import SectionHeader from '../SectionHeader'

import { useTheme } from '@mui/material'
import { fillRangeDates } from './utils'
import { getParsedDate } from 'utils/dateParser'

interface DateRangePickerProps {
  maxDate?: string
  minDate?: string
  onChange: (value: { from: string | null; to: string | null }) => void
  sx?: object
  value: { from: string | null; to: string | null }
}

const DateRangePicker = React.forwardRef((props: DateRangePickerProps) => {
  const { maxDate, minDate, sx, onChange, value } = props

  const theme = useTheme()

  useLayoutEffect(() => {
    updateVisuals()
  }, [value])

  const updateVisuals = () => {
    /** Time out in order for the animation to happen before finding
     * the elements. */
    setTimeout(() => fillRangeDates(theme, value), 100)
  }

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          ...sx
        }}
      >
        <Box>
          <SectionHeader
            divider={true}
            title={'Från'}
            actions={
              <Text variant="h5">
                {value.from ? getParsedDate(value.from) : '–––'}
              </Text>
            }
            size="large"
            sx={{ mb: 2 }}
          />
          <DateCalendar
            className="fromDate"
            maxDate={value.to || maxDate}
            minDate={minDate}
            onChange={(fromValue) => {
              updateVisuals()
              onChange({ from: fromValue, to: value.to })
            }}
            value={value.from}
          />
        </Box>
        <Divider flexItem={true} orientation="vertical" />
        <Box>
          <SectionHeader
            divider={true}
            title={'Till'}
            actions={
              <Text variant="h5">
                {value.to ? getParsedDate(value.to) : '–––'}
              </Text>
            }
            size="large"
            sx={{ mb: 2 }}
          />
          <DateCalendar
            className="toDate"
            maxDate={maxDate}
            minDate={value.from || minDate}
            onChange={(toValue) => {
              updateVisuals()
              onChange({ from: value.from, to: toValue })
            }}
            value={value.to}
          />
        </Box>
      </Box>
    </>
  )
})

DateRangePicker.displayName = 'DateRangePicker'
export default DateRangePicker
