import { createAction, createReducer } from '@reduxjs/toolkit'

import { InviteMessage } from 'types/GlobalInviteMessages'

import * as Types from 'redux/Types'

export interface InviteMessagesReducerType {
  data: InviteMessage[]
  fetched: boolean
}

// Initial state
const INITIAL_STATE: InviteMessagesReducerType = {
  data: [],
  fetched: false
}

// Actions
const getInviteMessages = createAction<{ data: InviteMessage[] }>(
  Types.GET_ALL_INVITE_MESSAGES_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const CustomersAndLicensesReducer = createReducer(
  INITIAL_STATE,
  (builder: any) => {
    builder
      .addCase(getInviteMessages, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          data: payload.data,
          fetched: true
        }
      })
      .addCase(signOutAction, () => INITIAL_STATE)
      .addDefaultCase((state: any) => state)
  }
)

export default CustomersAndLicensesReducer
