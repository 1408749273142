import React, { useMemo } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import * as DashboardActions from 'redux/actions/Dashboards'

import Icon from 'components_new/atoms/Icon'

import SimpleSearch from 'components_new/molecules/SimpleSearch'

import { parseElapsedTimeSince } from 'helpers/Functions/Date'
import { getAvailableGroups, getThumbnail } from 'utils/functions'

import Loading from './loading'

const FinderSearch = (props: ComponentsProps) => {
  const { KPIDashboardStore } = props

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)

  const thumbnailMemo = useMemo(() => {
    const thumbnails: { [id: string]: string } = {}

    availableGroups.forEach((item) => {
      thumbnails[item.id] = getThumbnail(item.thumbnail)
    })

    return thumbnails
  }, [])

  // loading
  if (!KPIDashboardStore.fetched) {
    return <Loading />
  }

  // success
  return (
    <SimpleSearch
      options={availableGroups.map((group) => {
        return {
          id: group.id,
          title: group.title,
          subtitle: `Ändrades för ${parseElapsedTimeSince(
            group.updated_at
          )} sedan`,
          href: `/dashboards/${group.dashboards[0]}`,
          src: thumbnailMemo[group.id],
          group: 'Dashboards',
          adornment: group.favorite.active ? (
            <Icon name="Star" color="brand" sx={{ fontSize: 'inherit' }} />
          ) : undefined
        }
      })}
      placeholder="Sök efter dashboard"
      size={'small'}
      sx={{ maxWidth: 'content' }}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentsProps = ConnectedProps<typeof connector>

FinderSearch.displayName = 'FinderSearch'
export default connector(FinderSearch)
