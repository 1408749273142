import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

import { getAll } from 'redux/api/Surveys'

export function tryGetAllSurveys() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_SURVEYS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_SURVEYS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_SURVEYS_FAILED
        })
      })
  }
}
