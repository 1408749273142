import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import List from 'components_new/pages/internal/List'
import Lists from 'components_new/pages/internal/Lists'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user?.is_homepal_user
        }
        Component={() => <List />}
        path={'/lists/:id'}
      />
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user?.is_homepal_user
        }
        Component={() => <Lists />}
        path={'/lists'}
      />
      <Route path={'/lists/*'}>
        <Redirect to="/lists" />
      </Route>
    </Switch>
  )
}

export default Routes
