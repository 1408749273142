import React from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconTip from 'components_new/molecules/IconTip'
import Tag from 'components_new/atoms/Tag'

import PeriodMenu from './PeriodMenu'

import { PeriodFilterOption } from 'components_new/organisms/DashboardUserFilter/utils'
import { getDatesOfPeriod } from '../utils'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { getParsedDateSpan } from 'utils/dateParser'
import { translateDateFilter } from 'utils/enumTranslator'
import { isCustomPeriodFilter, isPeriodFilterEnum } from 'utils/functions'
import IconButton from 'components_new/atoms/IconButton'

interface PeriodSelectorProps {
  onSubmit: (periodFilter: PeriodFilter | CustomPeriodFilter | null) => void
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  periodFilterOptions: PeriodFilterOption[]
}

const PeriodSelector = (props: PeriodSelectorProps) => {
  const { onSubmit, periodFilter, periodFilterOptions } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isCustom = periodFilter && isCustomPeriodFilter(periodFilter)
  const isPreset = periodFilter && isPeriodFilterEnum(periodFilter)

  return (
    <>
      <Chip
        sx={{
          height: 'auto',
          py: 1,
          width: '100%',
          justifyContent: 'space-between',
          '& .MuiChip-deleteIcon': {
            mr: 1
          }
        }}
        label={
          <>
            <Tag
              label={
                isPreset
                  ? translateDateFilter[periodFilter]
                  : isCustom
                    ? 'Anpassad'
                    : 'Standard'
              }
              sx={{ mb: 0.5 }}
            />
            {!isCustom && !isPreset ? (
              <IconTip
                placement="left"
                size="small"
                title={
                  <>
                    <Box sx={{ mb: 0.5 }}>
                      <Tag label={'Standard'} />: Din dashboard utgår från dina
                      nyckeltals standardlägen när det kommer till tidsperiod.
                    </Box>
                    <Box>
                      Det innebär att den inte har en och samma gemensamma
                      tidsperiod utan den visar data beroende på vad de separata
                      nyckeltalen har ställts in till att visa i sina
                      inställningar.
                    </Box>
                  </>
                }
              />
            ) : null}
            <Box>
              {isPreset ? (
                getParsedDateSpan(
                  getDatesOfPeriod(periodFilter).from,
                  getDatesOfPeriod(periodFilter).to
                )
              ) : isCustom ? (
                getParsedDateSpan(periodFilter.from, periodFilter.to)
              ) : (
                <em>Välj tidsperiod...</em>
              )}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                top: 0,
                right: 0
              }}
            >
              {periodFilter ? (
                <IconButton
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    onSubmit(null)
                  }}
                  size="small"
                >
                  <Icon fontSize="small" name="Clear" />
                </IconButton>
              ) : null}
              <IconButton size="small">
                <Icon fontSize="small" name="ArrowDropDown" />
              </IconButton>
            </Box>
          </>
        }
        clickable={true}
        size="medium"
        onClick={handleClick}
      />
      <PeriodMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSubmit={onSubmit}
        periodFilter={periodFilter}
        periodFilterOptions={periodFilterOptions}
      />
    </>
  )
}

export default PeriodSelector
