import React from 'react'

const BlockDeleteContainer = ({
  deleteType = '',
  dependencyType = '',
  names,
  ending = 'och kan därför ej tas bort.'
}) => {
  return (
    <p>
      <span>
        {deleteType} används i {dependencyType}
      </span>{' '}
      {names.map((name, key) => (
        <span
          key={`block-delete-${key}`}
          style={{ color: 'var(--primary-color)' }}
        >
          {name}
          {names.length - 1 !== key ? ', ' : ''}{' '}
        </span>
      ))}
      <span>{ending}</span>
    </p>
  )
}

export default BlockDeleteContainer
