import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'

const EditModeBar = ({
  addButtonText = 'Lägg till',
  actions,
  onOk,
  editMode,
  setEditMode,
  setAlert,
  resetAlert,
  setLockedMode,
  onAdd,
  onSave,
  ignoreId = 'edit-container',
  useCallbackOnSave,
  secondaryNavbar = null,
  hideEditOptions,
  allowedSubPaths = [],
  hideAddButton = false,
  disabledEditMode = false
}) => {
  const setEditModeLocked = (bool) => {
    setEditMode(bool)

    setLockedMode({
      locked: bool,
      ignoreId: bool ? ignoreId : null,
      allowedSubPaths: allowedSubPaths,
      mainPath: window.location.pathname,
      alert: bool
        ? {
            onOk: () => {
              setEditMode(false)
              resetAlert()
              setLockedMode({
                locked: false,
                ignoreId: null,
                alert: {}
              })
              onOk()
            }
          }
        : {}
    })
  }

  return (
    <Box>
      {hideEditOptions ? null : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 1,
            py: 0.5,
            bgcolor: 'background.paper'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            {hideAddButton ? null : (
              <Button
                disabled={!editMode}
                onClick={() => (editMode ? onAdd() : {})}
                startIcon={<Icon name="AddCircleOutlineOutlined" />}
                variant="text"
              >
                {addButtonText}
              </Button>
            )}
            {actions}
          </Box>
          <Box>
            <Button
              disabled={disabledEditMode}
              onClick={() => {
                if (!editMode) {
                  setEditModeLocked(true)
                } else {
                  setAlert({
                    header: 'Överge data',
                    content:
                      // eslint-disable-next-line max-len
                      'Du har osparade ändringar och kommer att förlora alla nya ändringar genom att fortsätta.',
                    okText: 'Fortsätt',
                    onOk: () => {
                      setEditMode(false)
                      resetAlert()
                      setLockedMode({
                        locked: false,
                        ignoreId: null,
                        alert: {}
                      })
                      onOk()
                    },
                    cancelText: 'Avbryt',
                    contentHeader: 'Osparade ändringar',
                    faIcon: 'faExclamationTriangle'
                  })
                }
              }}
              variant={editMode ? 'contained' : 'text'}
              startIcon={<Icon name="EditOutlined" />}
            >
              Redigeringsläge
            </Button>
            <Button
              disabled={!editMode}
              onClick={() => {
                if (editMode) {
                  if (useCallbackOnSave) {
                    onSave(() => setEditModeLocked(false))
                  } else {
                    onSave()
                    setEditModeLocked(false)
                  }
                }
              }}
              variant="text"
              startIcon={<Icon name="SaveOutlined" />}
              sx={{ ml: 1 }}
            >
              Spara ändringar
            </Button>
          </Box>
        </Box>
      )}
      {secondaryNavbar && (
        <Box sx={{ bgcolor: 'neutral.main', px: 1, py: 0.5 }}>
          {secondaryNavbar}
        </Box>
      )}
      <Divider />
    </Box>
  )
}

export default EditModeBar
