import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return <Skeleton height={32} width={86} />
}

Loading.displayName = 'Loading'
export default Loading
