import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'

import PulsingBadge from 'components_new/molecules/PulsingBadge'
import SectionHeader from 'components_new/molecules/SectionHeader'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { translateUserStatus } from 'components_new/pages/internal/UsersTracking/utils'
import { TrackingUser, UserStatus } from 'types/GlobalTracking'
import {
  buildQuery,
  evaluateLogicalExpression,
  getAccountOptions,
  getCustomers,
  getLicenses,
  getUserTags,
  getTitleOptions
} from './utils'
import UserTrackingCard from './UserTrackingCard'
import Tooltip from 'components_new/atoms/Tooltip'
import Text from 'components_new/atoms/Text'

interface UserTrackingKanbanTemplateProps {
  usersUsage: TrackingUser[]
}

const statuses: UserStatus[] = [
  UserStatus.ACTIVATION_FAILED,
  UserStatus.ACTIVATION_IN_PROGRESS,
  UserStatus.ACTIVE,
  UserStatus.INACTIVE,
  UserStatus.CHURN
]

const UserTrackingKanbanTemplate = (props: UserTrackingKanbanTemplateProps) => {
  const { usersUsage } = props

  /** Options */
  const customers = getCustomers(usersUsage)
  const licenses = getLicenses(usersUsage)
  const tags = getUserTags()
  const accounts = getAccountOptions(usersUsage)
  const titles = getTitleOptions()

  /** Filter */
  const [filter, setFilter] = useState<{
    'customer.id': Option[]
    'customer.license': Option[]
  }>({ 'customer.id': [], 'customer.license': [] })

  const [tagFilter, setTagFilter] = useState<Option[]>([])
  const [accountFilter, setAccountFilter] = useState<Option[]>([])
  const [titleFilter, setTitleFilter] = useState<Option[]>([])

  const query = buildQuery(filter)

  const filteredUsersUsage = usersUsage
    .filter((user) => evaluateLogicalExpression(query, user))
    .filter((user) => {
      if (accountFilter.length === 0) {
        return true
      }

      return !!accountFilter.find((account) => account.id === user.id)
    })
    .filter((user) => {
      if (titleFilter.length === 0) {
        return true
      }

      return !!titleFilter.find((title) => title.id === user.title)
    })
    .filter((user) => {
      for (let i = 0; i < tagFilter.length; i += 1) {
        const currentTag = user.tags.find((item) => item.id === tagFilter[i].id)

        if (currentTag && !currentTag.value) {
          return false
        }
      }

      return true
    })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            p: 2,
            bgcolor: 'background.paper',
            width: '100%'
          }}
        >
          <SelectSearch
            label={'Användare'}
            onChange={(options: Option[]) => setAccountFilter(options)}
            multiple={true}
            options={accounts}
            selected={accountFilter}
            sort={true}
            sx={{ maxWidth: '40ch' }}
          />
          <SelectSearch
            label={'Titel'}
            onChange={(options: Option[]) => setTitleFilter(options)}
            multiple={true}
            options={titles}
            selected={titleFilter}
            sort={true}
            sx={{ maxWidth: '40ch' }}
          />
          <SelectSearch
            label={'Kund'}
            onChange={(options: Option[]) => {
              setFilter({
                ...filter,
                'customer.id': options
              })
            }}
            multiple={true}
            options={customers}
            selected={filter['customer.id']}
            sort={true}
            sx={{ maxWidth: '40ch' }}
          />
          <SelectSearch
            label={'Licens'}
            onChange={(options: Option[]) => {
              setFilter({
                ...filter,
                'customer.license': options
              })
            }}
            multiple={true}
            options={licenses}
            selected={filter['customer.license']}
            sort={true}
            sx={{ maxWidth: '40ch' }}
          />
          <SelectSearch
            label={'Tagg'}
            onChange={(options: Option[]) => setTagFilter(options)}
            multiple={true}
            options={tags}
            selected={tagFilter}
            sort={false}
            sx={{ maxWidth: '40ch' }}
          />
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            position: 'relative',
            width: '100%'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              minHeight: 0,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            {statuses.map((status) => {
              const swimlaneUsers = filteredUsersUsage.filter(
                (user) => user.swimlane === status
              )

              const { body, severity, title } = translateUserStatus[status]

              return (
                <Box
                  key={status}
                  sx={{
                    height: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box
                    sx={{
                      pt: 2,
                      px: 2
                    }}
                  >
                    <SectionHeader
                      actions={
                        <Tooltip title={body}>
                          <Box>
                            <PulsingBadge
                              color={severity}
                              pulsing={true}
                              size={24}
                            />
                          </Box>
                        </Tooltip>
                      }
                      divider
                      size="medium"
                      title={title}
                      titleAdornment={
                        <Text color="text.secondary" variant="subtitle1">
                          {'('}
                          {swimlaneUsers.length}
                          {')'}
                        </Text>
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      height: '100%',
                      overflow: 'auto',
                      p: 2
                    }}
                  >
                    {swimlaneUsers.map((user) => (
                      <UserTrackingCard key={user.id} user={user} />
                    ))}
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default UserTrackingKanbanTemplate
