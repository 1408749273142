import React, { useRef } from 'react'

import Button from 'components_new/atoms/Button'
import Form from 'components_new/atoms/Form'
import Stack from 'components_new/atoms/Stack'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

/**
 * The EditProfileDialog component lets
 * the user edit its profile.
 */

const EditProfileDialog = ({ open, onClose, user, tryUpdateSelf }) => {
  const formRef = useRef(null)

  const onSubmit = (values) => {
    const attributes = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: null
    }

    tryUpdateSelf({ data: { attributes } })
    onClose()
  }

  return (
    <Form
      onSubmit={onSubmit}
      ref={formRef}
      initialValues={{
        firstName: user.first_name || '',
        lastName: user.last_name || ''
      }}
    >
      {({ values, handleChange, submitForm }) => (
        <SimpleDialog
          component="form"
          title="Redigera profil"
          open={open}
          onClose={onClose}
          actions={
            <>
              <Button variant="text" onClick={onClose}>
                Avbryt
              </Button>
              <Button variant="contained" onClick={submitForm}>
                Spara
              </Button>
            </>
          }
        >
          <Stack direction="column" spacing={2} sx={{ py: 1 }}>
            <Stack spacing={1}>
              <TextField
                autoComplete="given-name"
                label="Förnamn"
                id="firstName"
                error={values.firstName?.length === 0}
                helperText={
                  values.firstName?.length === 0
                    ? 'Förnamn är obligatoriskt.'
                    : null
                }
                value={values.firstName}
                margin="none"
                onChange={handleChange}
                required
              />
              <TextField
                autoComplete="family-name"
                label="Efternamn"
                id="lastName"
                error={values.lastName?.length === 0}
                helperText={
                  values.lastName?.length === 0
                    ? 'Efternamn är obligatoriskt.'
                    : null
                }
                value={values.lastName}
                margin="none"
                onChange={handleChange}
                required
              />
            </Stack>
            <Tooltip title={'Det går inte att ändra din email'}>
              <TextField
                autoComplete="email"
                label="Email"
                id="email"
                disabled={true}
                value={user.email}
              />
            </Tooltip>
          </Stack>
        </SimpleDialog>
      )}
    </Form>
  )
}

export default EditProfileDialog
