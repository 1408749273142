export const getTitle = {
  401: 'Åtkomst nekad',
  404: 'Sidan kunde inte hittas',
  500: 'Internt serverfel'
}

export const getBody = {
  401: 'Du verkar inte ha tillåtelse att visa det här.',
  404: 'Vi kunde tyvärr inte hitta sidan du letade efter.',
  500: 'Någonting verkar ha gått fel på vår sida.'
}
