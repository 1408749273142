import * as Api from 'redux/api/DataPlatform/connections/sourceTables/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryUpdateSourceColumn(sourceTableId, columnId, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_SOURCE_COLUMN
    })

    Api.updateSourceColumn(sourceTableId, columnId, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SOURCE_COLUMN_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Kolumnen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_SOURCE_COLUMN_FAILED
        })

        messageWarning('Kolumnen kunde inte uppdateras')
      })
  }
}
