import React, { MouseEventHandler, ReactNode } from 'react'

import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Icon from 'components_new/atoms/Icon'

interface ButtonFieldProps {
  children: ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  sx?: object
}

const ButtonField = (props: ButtonFieldProps) => {
  const { children, fullHeight, fullWidth, onClick, onMouseDown, sx } = props

  return (
    <MenuItem
      component="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      sx={{
        height: fullHeight ? '100%' : undefined,
        width: fullWidth ? '100%' : undefined,
        flex: 1,
        fontWeight: 500,
        justifyContent: 'center',
        gap: 0.5,
        border: '1px dashed',
        borderColor: 'divider',
        borderRadius: 1,
        ...sx
      }}
    >
      <Icon fontSize="small" name="AddCircleOutlineOutlined" />
      {children}
    </MenuItem>
  )
}

export default ButtonField
