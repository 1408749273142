import { ComparativeDisplayType, KpiOptionObject } from 'types/GlobalKpiOption'

export const ORDER = [
  ComparativeDisplayType.VALUE,
  ComparativeDisplayType.PERCENTAGE,
  ComparativeDisplayType.PERCENTAGE_AND_VALUE
]

export const getOptions = (kpi: KpiOptionObject) => {
  const filteredOptions = kpi.comparative_display_type.options.filter(
    (option) => option !== ComparativeDisplayType.HIDDEN
  )

  return filteredOptions.sort((a, b) => ORDER.indexOf(a) - ORDER.indexOf(b))
}
