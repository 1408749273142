import ShortId from 'shortid'

import Manual from 'assets/build/manual.svg'
import Excel from 'assets/build/excel.svg'

export const ADD_REGISTRY_OPTIONS = (history, setAlert, resetAlert) => [
  {
    icon: Manual,
    text: 'Bygg manuellt',
    key: ShortId.generate(),
    button: {
      text: '+ Skapa',
      onClick: () =>
        history.push('/data-platform/build/registers/create/manual'),
      bordered: false
    }
  },
  {
    badge: 'Kommer snart',
    icon: Excel,
    text: 'Importera excel',
    key: ShortId.generate(),
    button: {
      text: 'Kontakta oss',
      onClick: () =>
        setAlert({
          isOpen: true,
          header: 'Importera excel',
          onOk: () => resetAlert(),
          content:
            'Kontakta oss på hej@homepal.se för att importera en excelfil.'
        }),
      bordered: true
    }
  }
]
