import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Collapse from 'components_new/atoms/Collapse'
import Stack from 'components_new/atoms/Stack'

import PageToolbar from 'components_new/molecules/PageToolbar'

import DashboardGrid from 'components_new/organisms/DashboardGrid'
import Banner from 'components_new/molecules/Banner'
import Widget from 'components_new/organisms/Widget'

import {
  CustomPeriodFilter,
  WidgetBody,
  WidgetPatchBody
} from 'types/GlobalWidget'
import { AspectRatio, Dashboard } from 'redux/reducers/Dashboards'
import { StatusBannerData } from 'utils/types'
import { formatWidgetData } from 'components_new/pages/Dashboard/utils'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

export type InnerWidgetPatchBody = WidgetPatchBody['data']

interface Props {
  dashboard: Dashboard
  dashboardFilter: DashboardFilter
  internal: boolean
  periodFilter: CustomPeriodFilter | null
  setDashboardFilter: (
    filter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setPeriodFilter: (periodFilter: CustomPeriodFilter | null) => void
  widgets: WidgetBody
  statusBanner: StatusBannerData[]
}

const PublicDashboardTemplate = (props: Props) => {
  const [dashboardWidgets, setWidgets] = useState([] as string[])
  const {
    dashboard,
    dashboardFilter,
    periodFilter,
    setDashboardFilter,
    setPeriodFilter,
    statusBanner,
    widgets
  } = props

  useEffect(() => {
    setWidgets(Object.values(widgets).map((widget) => widget.id))
  }, [widgets])

  const layout = dashboardWidgets
    .filter((id) => widgets[id])
    .map((id) => {
      const widget = widgets[id]

      return {
        x: widget.layout.x,
        y: widget.layout.y,
        w: widget.layout.width,
        h: widget.layout.height,
        i: id
      }
    })

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Collapse in={dashboardFilter.length > 0 || Boolean(periodFilter)}>
        <PageToolbar
          leftActions={
            <Stack spacing={0.5}>
              {dashboardFilter.map((filter, i) => (
                <Chip
                  key={i}
                  color="primary"
                  label={filter.value}
                  onDelete={() =>
                    setDashboardFilter(
                      dashboardFilter.filter(
                        (f) =>
                          f.relation_key !== filter.relation_key ||
                          f.value !== filter.value
                      )
                    )
                  }
                  size="small"
                />
              ))}
            </Stack>
          }
          rightActions={
            periodFilter ? (
              <Stack spacing={0.5}>
                <Chip
                  color="primary"
                  label={`${periodFilter.from} till ${periodFilter.to}`}
                  onDelete={() => setPeriodFilter(null)}
                  size="small"
                />
              </Stack>
            ) : undefined
          }
        />
      </Collapse>
      {statusBanner.length > 0 &&
        statusBanner.map((banner) => (
          <Banner
            body={banner.description}
            href={banner.link}
            iconName={banner.icon}
            key={banner.id}
            linkLabel={banner.link_label}
            severity={banner.severity}
            title={banner.title}
          />
        ))}
      <Box
        sx={{
          position: 'relative',
          flex: '1 1 auto'
        }}
      >
        <DashboardGrid
          colors={dashboard.white_label_settings}
          editable={false}
          embedded={true}
          gridRef={null}
          layout={layout}
          ratio={AspectRatio['16_9']}
          title={dashboard.title}
          updateLayout={() => {}}
        >
          {({ scaleFactor }) => {
            return dashboardWidgets
              .map((id) => {
                const widget = widgets[id]

                if (!widget) {
                  return null
                }

                const formattedData = formatWidgetData(widget)

                return (
                  <Box
                    onMouseOver={() => {}}
                    onMouseLeave={() => {}}
                    key={id}
                    sx={{ position: 'relative' }}
                  >
                    <Widget
                      colors={dashboard.white_label_settings}
                      customer={null}
                      dashboardFilter={dashboardFilter}
                      dashboardFilterOptions={[]}
                      editable={false}
                      embedded={true}
                      enableFullscreen={true}
                      formattedData={formattedData}
                      isAdmin={false}
                      loading={!widget.data && !widget.status.broken}
                      scaleFactor={scaleFactor}
                      setDashboardFilter={setDashboardFilter}
                      widget={widget}
                    />
                  </Box>
                )
              })
              .filter(Boolean)
          }}
        </DashboardGrid>
      </Box>
    </Box>
  )
}

export default PublicDashboardTemplate
