import React, { useMemo } from 'react'
import ShortId from 'shortid'
import Lodash from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'

import Node from './index.js'

import * as Functions from 'helpers/Functions'
import { isDateType } from 'helpers/Functions/Date'

import { parseAttributeOptions, getSensitiveRefColumn } from './conf.js'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

const getOutputAttributeType = (
  leftAttributeId,
  rightAttributeId,
  childAttributes
) => {
  const leftAttribute = childAttributes.find(
    (oa) => oa.shortId === leftAttributeId
  )
  const rightAttribute = childAttributes.find(
    (oa) => oa.shortId === rightAttributeId
  )

  if (!leftAttribute && !rightAttribute) {
    return null
  }
  if (!leftAttribute) {
    return rightAttribute.type
  }
  if (!rightAttribute) {
    return leftAttribute.type
  }

  // type changed, check for new appropriate type.
  if (leftAttribute.type !== rightAttribute.type) {
    if (
      Functions.isNumberDataType(leftAttribute.type) &&
      Functions.isNumberDataType(rightAttribute.type)
    ) {
      return 'DOUBLE'
    }
    if (isDateType(leftAttribute.type) && isDateType(rightAttribute.type)) {
      return 'TIMESTAMP'
    }

    return 'STRING'
  }

  return leftAttribute.type
}

const UniteNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )
  const rightNode = useMemo(
    () => data?.globalNodeData?.[data.rightNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const rightAttributes = useMemo(() => {
    return rightNode?.data?.outputAttributes || []
  }, [rightNode])

  const selectedAttrs = data.outputAttributes
    .map((attr) => [attr.leftAttribute, attr.rightAttribute])
    .flat()
    .filter(Boolean)

  const childAttributes = [...leftAttributes, ...rightAttributes]

  return (
    <Node
      icon="DeviceHubOutlined"
      title={'Unite'}
      selected={selected}
      twoInputs
      leftInputCharacter={'a'}
      rightInputCharacter={'b'}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box sx={{ width: '320px' }}>
        {data.outputAttributes.map((attribute, i) => (
          <Box key={`unite-attr-${i}`}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.25
                }}
              >
                <Chip color="primary" label="a" size="small" sx={{ mr: 0.5 }} />
                <Chip color="primary" label="b" size="small" sx={{ mr: 0.5 }} />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.25
                }}
              >
                <Select
                  className={'nodrag'}
                  search
                  dropdownMatchSelectWidth={false}
                  ignoreLockedMode
                  inNode
                  size={'small'}
                  value={attribute.leftAttribute}
                  onChange={(val) => {
                    const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

                    tmpOutput[i].leftAttribute = val

                    tmpOutput[i].type = getOutputAttributeType(
                      val,
                      tmpOutput[i].rightAttribute,
                      childAttributes
                    )
                    tmpOutput[i].sensitiveRefColumnId = getSensitiveRefColumn(
                      val,
                      tmpOutput[i].rightAttribute,
                      childAttributes
                    )

                    setData(id, { outputAttributes: tmpOutput })
                  }}
                  options={parseAttributeOptions(
                    leftAttributes,
                    data.nodeNumberMapper
                  ).filter(
                    (attr) =>
                      !selectedAttrs.includes(attr.value) ||
                      attr.value === attribute.leftAttribute
                  )}
                />
                <Select
                  className={'nodrag'}
                  search
                  dropdownMatchSelectWidth={false}
                  ignoreLockedMode
                  inNode
                  size={'small'}
                  value={attribute.rightAttribute}
                  onChange={(val) => {
                    const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

                    tmpOutput[i].rightAttribute = val

                    tmpOutput[i].type = getOutputAttributeType(
                      tmpOutput[i].leftAttribute,
                      val,
                      childAttributes
                    )
                    tmpOutput[i].sensitiveRefColumnId = getSensitiveRefColumn(
                      tmpOutput[i].leftAttribute,
                      val,
                      childAttributes
                    )

                    setData(id, { outputAttributes: tmpOutput })
                  }}
                  options={parseAttributeOptions(
                    rightAttributes,
                    data.nodeNumberMapper
                  ).filter(
                    (attr) =>
                      !selectedAttrs.includes(attr.value) ||
                      attr.value === attribute.rightAttribute
                  )}
                />
              </Box>
              <Icon name="Merge" sx={{ transform: 'rotate(90deg)' }} />
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.25
                }}
              >
                <Input
                  className={'nodrag'}
                  size={'small'}
                  value={attribute.name}
                  onChange={(val) => {
                    const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

                    tmpOutput[i].name = val.target.value
                    tmpOutput[i].realName = val.target.value
                    setData(id, { outputAttributes: tmpOutput })
                  }}
                />
              </Box>
              <IconButton
                className={'nodrag'}
                edge="end"
                onClick={() => {
                  const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

                  tmpOutput.splice(i, 1)
                  setData(id, { outputAttributes: tmpOutput })
                }}
                size="small"
              >
                <Icon fontSize="small" name="Close" />
              </IconButton>
            </Box>
            <Divider sx={{ my: 1 }} />
          </Box>
        ))}
        <Button
          className={'nodrag'}
          fullWidth
          onClick={() => {
            setData(id, {
              outputAttributes: [
                ...data.outputAttributes,
                {
                  leftAttribute: undefined,
                  rightAttribute: undefined,
                  name: undefined,
                  realName: undefined,
                  shortId: ShortId.generate(),
                  prepend: id
                }
              ]
            })
          }}
          size="small"
          startIcon={<Icon name="AddCircleOutline" fontSize="small" />}
          variant="text"
        >
          Lägg till
        </Button>
      </Box>
    </Node>
  )
}

export default UniteNode
