import React from 'react'

import Box from 'components_new/atoms/Box'
import Tag from 'components_new/atoms/Tag'
import Tooltip from 'components_new/atoms/Tooltip'
import { getParsedDateSpan } from 'utils/dateParser'

interface DateTagProps {
  fromDate: string
  locked?: boolean
  toDate: string | null
  tooltip?: string | null
  sx?: object
  wrapInParanthesis?: boolean
}

const DateTag = (props: DateTagProps) => {
  const {
    fromDate,
    locked = false,
    sx,
    toDate,
    tooltip,
    wrapInParanthesis = false
  } = props

  if (!fromDate) {
    return null
  }

  const dateSpan = getParsedDateSpan(fromDate, toDate)

  return (
    <Box sx={sx}>
      <Tooltip title={tooltip}>
        <Box>
          <Tag
            label={wrapInParanthesis ? `(${dateSpan})` : dateSpan}
            startIconName={locked ? 'Lock' : undefined}
            sx={{ cursor: 'default' }}
            TextSx={{
              color: 'text.secondary',
              fontWeight: '500',
              textTransform: 'none'
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default DateTag
