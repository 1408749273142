import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const my_homepal_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

export async function updateSelf(body) {
  return Axios.patch(
    `${BASE_URL}update-self`,
    body,
    await Functions.parseAuthenticationHeader()
  )
}
