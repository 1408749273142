import * as Api from 'redux/api/DataPlatform/dataQuality/quality/v1'
import * as Types from 'redux/Types'

export function tryGetQualityOverview() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_QUALITY_OVERVIEW
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_QUALITY_OVERVIEW_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_QUALITY_OVERVIEW_FAILED
        })
      })
  }
}
