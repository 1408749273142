import React from 'react'
import { withRouter } from 'react-router-dom'

import PageToggle from 'components/common/PageToggle'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

const PageHeader = ({
  header = '',
  actions,
  onBack,
  customOnBack,
  subHeaderChildren,
  path,
  history,
  toggle,
  items = [],
  activeToggle,
  isSubheader
}) => {
  return (
    <Box>
      {onBack || customOnBack ? (
        <Button
          onClick={() => (customOnBack ? customOnBack() : history.goBack())}
          size="small"
          startIcon={<Icon name="ChevronLeft" />}
          variant="text"
        >
          Tillbaka
        </Button>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isSubheader ? (
            <Text color="text.primary" variant="h5">
              {header}
            </Text>
          ) : (
            <>
              {path ? (
                <Text color="text.secondary" variant="h4">{`${path} > `}</Text>
              ) : null}
              <Text color="text.primary" variant="h4">
                {header}
              </Text>
            </>
          )}
          {subHeaderChildren ? subHeaderChildren : null}
        </Box>
        <Box
          sx={{
            height: '2rem',
            display: 'flex',
            alignItems: 'end'
          }}
        >
          {toggle ? <PageToggle items={items} active={activeToggle} /> : null}
          <Box sx={{ display: 'flex', gap: 1 }}>{actions}</Box>
        </Box>
      </Box>
      {isSubheader ? <Divider sx={{ my: 1 }} /> : null}
    </Box>
  )
}

export default withRouter(PageHeader)
