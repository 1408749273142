import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import InviteMessagesTemplate from 'components_new/templates/internal/InviteMessages'

import * as InviteMessageActions from 'redux/actions/internal/InviteMessages'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  InviteMessageStore: state.InviteMessageStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...InviteMessageActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type InviteMessagesPageProps = ConnectedProps<typeof connector>

const InviteMessagesPage = (props: InviteMessagesPageProps) => {
  useEffect(() => {
    if (!props.InviteMessageStore.fetched) {
      props.tryGetAllInviteMessages()
    }
  }, [])

  return (
    <InternalTemplate>
      <InviteMessagesTemplate messages={props.InviteMessageStore.data} />
    </InternalTemplate>
  )
}

export default connector(InviteMessagesPage)
