import React, { ElementType, ReactNode } from 'react'
import { CardMedia as MuiCardMedia } from '@mui/material'

interface CardMediaProps {
  children?: ReactNode
  classes?: object
  component?: ElementType<any>
  image?: string
  src?: string
  sx?: object
}

/**
 * The CardMedia component is used to
 * render an image that is not affected
 * by the CardContent's layout
 *
 * @requires Card component.
 */

const CardMedia = React.forwardRef((props: CardMediaProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MuiCardMedia {...rest} ref={ref}>
      {children}
    </MuiCardMedia>
  )
})

CardMedia.displayName = 'CardMedia'
export default CardMedia
