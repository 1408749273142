import { createAction, createReducer } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'

import * as Types from 'redux/Types'
import { StatusBannerData } from 'utils/types'

export interface StatusBannerReducerType {
  data: StatusBannerData[]
}

// Initial state
const INITIAL_STATE: StatusBannerReducerType = {
  data: []
}

// Actions
const getStatusBannerSuccess = createAction<{ data: StatusBannerData[] }>(
  Types.GET_STATUS_BANNER_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const StatusBannerReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(
      getStatusBannerSuccess,
      (state: any, action: { payload: { data: StatusBannerData[] } }) => {
        const { payload } = action

        if (!isEqual(state.data, payload.data)) {
          return {
            ...state,
            data: payload.data
          }
        }

        return state
      }
    )
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default StatusBannerReducer
