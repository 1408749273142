import * as Types from 'redux/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_RULES_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload),
      fetched: true
    }
  case Types.PUT_RULES_SUCCESS:
    return {
      ...state,
      data: {
        ...filterRules(state.data, payload.tableId),
        ...Functions.arrayToObject(payload.data)
      },
      fetched: true
    }
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}

const filterRules = (data, tableId) => {
  const filteredRules = Object.values(data).filter(
    (req) => req.attributes.table_id !== tableId
  )

  return Functions.arrayToObject(filteredRules)
}
