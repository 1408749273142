export const DOMAIN_VALIDATION = {
  validator(_, value) {
    if (value.includes('@')) {
      return Promise.reject(new Error('Domänen ska ej innehålla @'))
    }

    if (
      value.includes('gmail.com') ||
      value.includes('outlook.com') ||
      value.includes('hotmail.com')
    ) {
      return Promise.reject(new Error('Ogiltig domän'))
    }

    return Promise.resolve()
  }
}
