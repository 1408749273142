import React, { useState, useRef, useMemo } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import {
  boxBorderDashPlugin,
  htmlLegendPlugin,
  horizontalLinePlugin,
  getDatasetFromClick
} from '../utils'

import { getData, getOptions } from './utils'

import { translateWidgetType } from 'utils/enumTranslator'

import { EXPLORE_DISABLED_TOOLTIP, getExploreLabel } from 'utils/texts'

import NoValue from '../../errors/NoValue'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface ComboChartProps {
  formattedData: FormattedWidgetData
  isComparativeButIllegal: boolean
  onClick: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  onClickValue: {
    [kpiOptionId: string]:
      | ((
          segmentLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  }
  scaleFactor: number
  widget: WidgetObject
}

const ComboChart = (props: ComboChartProps) => {
  const {
    formattedData,
    isComparativeButIllegal,
    onClick,
    onClickValue,
    scaleFactor,
    widget
  } = props

  const chartRef = useRef<any>(null)
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<{
    customPeriod?: { fromDate: string; toDate: string }
    breakdownLabel: string | null
    dataLabel: ParsedWidgetDataLabel
    kpiOptionId?: string
  } | null>(null)
  const open = Boolean(anchorPosition)
  const allowOnClick = onClick.length > 0

  const theme = useTheme()

  const hasBreakdown = !!widget.settings.kpi_options.find(
    (option) => !!option.breakdown_by.selected
  )

  const data = getData(widget, theme, hasBreakdown, formattedData, scaleFactor)

  const options = useMemo(
    () =>
      getOptions(
        widget,
        hasBreakdown,
        theme,
        allowOnClick,
        scaleFactor,
        formattedData,
        isComparativeButIllegal
      ),
    [widget, scaleFactor]
  )

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BAR_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    dataLabel: ParsedWidgetDataLabel,
    breakdownLabel: string | null,
    kpiOptionId?: string,
    customPeriod?: { fromDate: string; toDate: string }
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedOptions({
      breakdownLabel,
      dataLabel,
      kpiOptionId: kpiOptionId,
      customPeriod
    })
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedOptions(null)
  }

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          ref={chartRef}
          type={'bar'}
          data={data}
          options={options}
          plugins={[
            boxBorderDashPlugin,
            htmlLegendPlugin,
            horizontalLinePlugin,
            ChartDataLabels,
            ChartjsPluginStacked100
          ]}
          onClick={
            allowOnClick
              ? (event) => {
                  if (chartRef.current) {
                    const { top } = chartRef.current.chartArea

                    // If click is below legend
                    if (event.nativeEvent.offsetY > top) {
                      const index = chartRef.current.scales.x.getValueForPixel(
                        event.nativeEvent.offsetX
                      )

                      const { breakdownLabel, customPeriod, kpiOptionId } =
                        getDatasetFromClick(
                          chartRef,
                          event,
                          formattedData,
                          widget
                        )

                      const segment = data.dataLabels[index]

                      if (segment) {
                        handleClick(
                          event,
                          segment,
                          breakdownLabel || null,
                          kpiOptionId,
                          customPeriod
                        )
                      }
                    }
                  }
                }
              : undefined
          }
        />
      </Box>
      {allowOnClick && selectedOptions && (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            anchorPosition
              ? { top: anchorPosition.top, left: anchorPosition.left }
              : undefined
          }
          open={open}
          onClose={handleClose}
          disableScrollLock
        >
          {onClick.map((option, i) => {
            const kpiOptionSegmentOptionId = selectedOptions.dataLabel
              .segment_options[selectedOptions.kpiOptionId as string] as string

            const parsedSegmentPath = {
              ...selectedOptions.dataLabel,
              attribute_option_id: kpiOptionSegmentOptionId
            }

            return (
              <MenuItem
                key={i}
                onClick={(event) => {
                  handleClose(event)

                  option.onClick([parsedSegmentPath])
                }}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <ListItemIcon>
                  <Icon name={option.iconName} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={option.title([parsedSegmentPath])} />
              </MenuItem>
            )
          })}
          {selectedOptions.kpiOptionId ? (
            <Tooltip
              title={
                !onClickValue[selectedOptions.kpiOptionId]
                  ? EXPLORE_DISABLED_TOOLTIP
                  : null
              }
            >
              <Box>
                <MenuItem
                  disabled={!onClickValue[selectedOptions.kpiOptionId]}
                  onClick={(event) => {
                    handleClose(event)
                    // eslint-disable-next-line max-len
                    const func =
                      onClickValue[selectedOptions.kpiOptionId as string]

                    if (func) {
                      func(
                        selectedOptions.dataLabel,
                        [],
                        selectedOptions.breakdownLabel,
                        selectedOptions.customPeriod
                      )
                    }
                  }}
                  onMouseDown={(event) => event.stopPropagation()}
                >
                  <ListItemIcon>
                    <Icon name={'SearchOutlined'} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={getExploreLabel(
                      selectedOptions.dataLabel.display_label
                    )}
                  />
                  <Chip
                    label={'Beta'}
                    color={'info'}
                    size={'small'}
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              </Box>
            </Tooltip>
          ) : null}
        </Menu>
      )}
    </>
  )
}

export default ComboChart
