import React from 'react'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

interface LoadingSettingsProps {
  titles: string[]
}

const LoadingSettings = (props: LoadingSettingsProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        // bgcolor: 'background.default',
        overflow: 'hidden'
      }}
    >
      <LoadingSection titles={props.titles} loading={true} />
    </Box>
  )
}

LoadingSettings.displayName = 'LoadingSettings'

export default LoadingSettings
