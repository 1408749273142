import React from 'react'

import Box from 'components_new/atoms/Box'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Radio from 'components_new/atoms/Radio'
import TextField from 'components_new/atoms/TextField'
import Text from 'components_new/atoms/Text'

import { TEmbedType } from './'

export type TDimensions = { width: string; height: string }

interface DimensionsProps {
  dimensions: TDimensions
  embedType: TEmbedType
  setDimensions: (value: TDimensions) => void
}

const Dimensions = (props: DimensionsProps) => {
  const { dimensions, embedType, setDimensions } = props

  const responsive = dimensions.width === '100%'

  const handleToggleDimensionMode = () => {
    if (responsive) {
      setDimensions({ width: '640', height: '360' })
    } else {
      setDimensions({ width: '100%', height: '100%' })
    }
  }

  const handleSetHeight = (value: string) => {
    const height = parseInt(value)
    const width = Math.floor((height * 16) / 9)

    setDimensions({ width: width.toString(), height: height.toString() })
  }

  const handleSetWidth = (value: string) => {
    const width = parseInt(value)
    const height = Math.floor((width * 9) / 16)

    setDimensions({ width: width.toString(), height: height.toString() })
  }

  return (
    <>
      <Box
        sx={{
          p: 2,
          bgcolor: 'action.hover',
          borderRadius: 1,
          mb: 2
        }}
      >
        <FormControlLabel
          value="responsive"
          control={<Radio checked={responsive} />}
          label={
            <>
              <Text fontWeight="bold">Responsiv</Text>
              <Text variant="body2">
                Det enklaste alternativet.{' '}
                {embedType.charAt(0).toUpperCase() + embedType.slice(1)}en
                kommer att ta upp den bredd och höjd som finns tillgänglig.
              </Text>
            </>
          }
          labelPlacement="end"
          onChange={handleToggleDimensionMode}
        />
      </Box>
      <Box
        sx={{
          p: 2,
          bgcolor: 'action.hover',
          borderRadius: 1,
          mb: 2
        }}
      >
        <FormControlLabel
          value="fixed"
          control={<Radio checked={!responsive} />}
          label={
            <>
              <Text fontWeight="bold">Bestämd storlek</Text>
              <Text variant="body2">
                Försäkra dig om att {embedType}en alltid är en viss storlek
                genom att ställa in bredd och höjd.
              </Text>
            </>
          }
          labelPlacement="end"
          onChange={handleToggleDimensionMode}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 1
          }}
        >
          <TextField
            disabled={responsive}
            InputProps={{
              endAdornment: <InputAdornment position="end">px</InputAdornment>
            }}
            onChange={(event) => handleSetWidth(event.target.value)}
            size="small"
            type="number"
            value={!responsive ? dimensions.width : '640'}
            sx={{ maxWidth: 104, my: 0 }}
          />
          <Icon
            color={responsive ? 'disabled' : undefined}
            fontSize="small"
            name="ClearOutlined"
          />
          <TextField
            disabled={responsive}
            InputProps={{
              endAdornment: <InputAdornment position="end">px</InputAdornment>
            }}
            onChange={(event) => handleSetHeight(event.target.value)}
            size="small"
            type="number"
            value={!responsive ? dimensions.height : '360'}
            sx={{ maxWidth: 104, my: 0 }}
          />
        </Box>
      </Box>
    </>
  )
}

export default Dimensions
