import { cloneDeep } from 'lodash'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  edit: {}
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  let newEdit
  let newData

  switch (type) {
  case Types.GET_ONE_DASHBOARD_WIDGET:
    return {
      ...state,
      data: {
        ...state.data,
        [payload]: {
          ...(payload in state.data ? state.data[payload] : {}),
          drillThrough: false,
          fetched: false
        }
      }
    }

  case Types.GET_ONE_DASHBOARD_WIDGET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: {
          fetched: true,
          drillThrough: payload.drillThrough,
          ...payload.data,
          attributes: {
            ...payload.data.attributes,

            // Parse types to match cascader
            widget_metrics: payload.data.attributes.widget_metrics.map(
              (wm) => ({
                ...wm,
                type: parseWidgetMetricType(wm.type)
              })
            )
          }
        }
      }
    }

  case Types.GET_ONE_DASHBOARD_WIDGET_FAILED:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: {
          id: payload.id,
          fetched: true,
          attributes: {
            broken: true,
            data: [],
            widget_metrics: [],
            main_class_filters: []
          }
        }
      }
    }

  case Types.PUT_WIDGETS_SUCCESS:
    newData = {}

    payload.data.forEach(
      (item) =>
        (newData[item.id] = {
          id: item.id,
          attributes: {
            ...item.attributes,
            main_class_filters: item.attributes.main_class_filters.map(
              (item) => item.attributes
            )
          },
          fetched: false
        })
    )

    return {
      ...state,
      data: {
        ...state.data,
        ...newData
      }
    }
  case Types.RESET_WIDGETS_FETCHED:
    newData = {}

    Object.entries(state.data).forEach(([key, item]) => {
      newData[key] = {
        ...item,
        fetched: false
      }
    })

    return {
      ...state,
      data: newData
    }

  case Types.LOAD_WIDGETS_TO_EDIT:
    return {
      ...state,
      edit: getWidgetsFromData(payload, state.data)
    }

  case Types.PUT_WIDGET:
    return {
      ...state,
      edit: {
        ...state.edit,
        [payload.id]: payload
      }
    }

  case Types.REMOVE_WIDGET:
    newEdit = cloneDeep(state.edit)

    delete newEdit[payload]

    return {
      ...state,
      edit: newEdit
    }

  case Types.UPDATE_WIDGET_POSITIONS:
    newEdit = state.edit

    payload.forEach((item) => {
      if (item.id in newEdit) {
        newEdit[item.id].attributes = {
          ...newEdit[item.id].attributes,
          x: item.attributes.x,
          y: item.attributes.y,
          width: item.attributes.width,
          height: item.attributes.height
        }
      } else {
        newEdit[item.id] = item
      }
    })

    return {
      ...state,
      edit: newEdit
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}

const getWidgetsFromData = (ids, data) => {
  const edit = {}

  ids.forEach(
    (id) =>
      (edit[id] = {
        id: data[id].id,
        attributes: {
          ...data[id].attributes,
          main_class_filters: data[id].attributes.main_class_filters.map(
            (item) => item.attributes
          ),
          widget_metrics: data[id].attributes.widget_metrics.map((item) => ({
            ...item,
            type: item.type,
            metric_conditions: item.metric_conditions.map((item) => ({
              condition: item.attributes.condition,
              conjunctiveOperator: item.attributes.conjunctive_operator,
              index: item.attributes.index,
              leftAttribute: item.attributes.left_attribute,
              rightAttribute: item.attributes.right_attribute,
              rightValue: item.attributes.right_value,
              type: item.attributes.type
            })),
            widget_metric_formattings: item.widget_metric_formattings
              .sort((f1, f2) => (f1.index < f2.index ? -1 : 1))
              .map((format) => ({
                ...format,
                filter_conditions: format.filter_conditions.map((item) => ({
                  condition: item.attributes.condition,
                  conjunctiveOperator: item.attributes.conjunctive_operator,
                  index: item.attributes.index,
                  leftAttribute: item.attributes.left_attribute,
                  rightAttribute: item.attributes.right_attribute,
                  leftWidgetMetricId: item.attributes.left_widget_metric_id,
                  rightWidgetMetricId: item.attributes.right_widget_metric_id,
                  rightValue: item.attributes.right_value,
                  type: item.attributes.type
                }))
              }))
          }))
        }
      })
  )

  return edit
}

/**
 * Parse widget metric type for cascader
 * @param {string} mtype - WidgetMetric type.
 * @returns {list[str]} Path for MetricType in cascader.
 */
const parseWidgetMetricType = (mtype) => {
  if (!mtype) {
    return null
  }

  if (mtype.includes('COUNT_DISTINCT')) {
    return ['COUNT_DISTINCT_TMP', mtype]
  }

  if (mtype.includes('COUNT')) {
    return ['COUNT_TMP', mtype]
  }

  if (mtype.includes('SUM')) {
    return ['SUM_TMP', mtype]
  }

  return [mtype]
}
