import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
//import { useNodes } from 'reactflow'

import Select from 'components/common/Select'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import Node from './index.js'

import { DATA_TYPES } from 'helpers/Constants'

import * as Conf from './conf.js'
import Styles from './styles.module.css'

const OutputNode = ({ id, selected, data, setData, history }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const outputAttributes = useMemo(() => {
    return _.cloneDeep(data.outputAttributes).sort((a, b) => {
      if (a.id === data.primaryKey) {
        return -1
      }
      if (a.id === data.referenceKey) {
        return b.id === data.primaryKey ? 1 : -1
      }

      return b.id === data.primaryKey || b.id === data.referenceKey
        ? 1
        : a.name.localeCompare(b.name)
    })
  }, [data])

  return (
    <Node
      icon="LogoutOutlined"
      title={'Output'}
      selected={selected}
      locked={data.locked}
      isOutputNode
    >
      <Box sx={{ width: '480px' }}>
        {data.name}
        <Chip label={data.technicalName} size="small" sx={{ ml: 0.5 }} />
        <Divider sx={{ my: 1 }} />
        {outputAttributes.map((attr, i) => (
          <Box
            key={`row-${i}`}
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              mb: 0.25,
              width: '100%'
            }}
          >
            {!attr.manualInput ? (
              <Box sx={{ flex: 1 }}>
                <Select
                  className={'nodrag'}
                  search
                  dropdownMatchSelectWidth={false}
                  ignoreLockedMode
                  inNode
                  disabled={attr.isCalculated}
                  value={attr.leftAttribute}
                  placeholder={
                    <small className="secondary tiny">
                      {attr.isCalculated
                        ? 'Fylls i automatiskt'
                        : 'Attribut...'}
                    </small>
                  }
                  onChange={(val) => {
                    const tmpOutput = _.cloneDeep(outputAttributes)

                    tmpOutput[i].leftAttribute = val

                    tmpOutput[i].sensitiveRefColumnId = val
                      ? leftAttributes.find((attr) => attr.shortId === val)
                        ?.sensitiveRefColumnId
                      : null

                    setData(id, { outputAttributes: tmpOutput })
                  }}
                  size={'small'}
                  options={Conf.parseAttributeOptions(
                    leftAttributes,
                    data.nodeNumberMapper
                  )}
                />
              </Box>
            ) : (
              <Paper
                variant="outlined"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  minHeight: '20px',
                  py: 0.25,
                  px: 0.5,
                  mb: 0.25,
                  flex: 1,
                  overflow: 'hidden'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <small className={`tiny ${Styles['static-input-text']}`}>
                    {attr.name}
                  </small>
                </Box>
                <Button
                  className={'nodrag'}
                  endIcon={<Icon name="ChevronRight" fontSize="small" />}
                  onClick={() =>
                    history.push(
                      `/build/registers/${data.manualInputBuildId}/data`
                    )
                  }
                  size="small"
                  variant="text"
                >
                  {data.buildTableName}
                </Button>
              </Paper>
            )}

            <Icon name="ArrowForward" fontSize="10px" />

            <Chip
              icon={
                data.primaryKey === attr.id ? (
                  <Icon name="KeyOutlined" />
                ) : data.referenceKey === attr.id ? (
                  <Icon name="SellOutlined" />
                ) : null
              }
              label={attr.name}
              size="small"
              sx={{ width: '25%', justifyContent: 'flex-start' }}
            />

            <Chip
              label={DATA_TYPES[attr.type]}
              size="small"
              sx={{ width: '25%', justifyContent: 'flex-start' }}
            />
          </Box>
        ))}
      </Box>
    </Node>
  )
}

export default withRouter(OutputNode)
