import React, { ElementType, MouseEventHandler } from 'react'
import { ReactNode } from 'react'
import { Avatar as MUIAvatar, SxProps } from '@mui/material'

interface AvatarProps {
  alt?: string
  bgcolor?: string
  children?: ReactNode
  component?: ElementType<any>
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  sizes?: string
  src?: string
  sx?: SxProps
  variant?: 'circular' | 'rounded' | 'square'
}

/**
 * An avatar displays an image, icon or initials
 * (most often) representing a user.
 */

const Avatar = React.forwardRef((props: AvatarProps, ref: any) => {
  const { bgcolor, sx, ...rest } = props

  return (
    <MUIAvatar
      sx={{ bgcolor: bgcolor ?? 'grey.200', color: 'text.secondary', ...sx }}
      {...rest}
      ref={ref}
    >
      {props.alt && !props.children ? props.alt.charAt(0) : props.children}
    </MUIAvatar>
  )
})

Avatar.displayName = 'Avatar'
export default Avatar
