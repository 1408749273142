import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

const Lock = () => {
  return (
    <Tooltip title="Tidsperioden är låst och påverkas inte av yttre filter.">
      <Icon
        color="inherit"
        fontSize="inherit"
        name={'Lock'}
        sx={{
          ml: '0.25em'
        }}
      />
    </Tooltip>
  )
}

export default Lock
