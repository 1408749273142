import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import Icon from 'components_new/atoms/Icon'

import TextButton from 'components/common/TextButton'

import Styles from './styles.module.css'

function Dropzone({
  files,
  removeFile,
  size = 'l',
  accept,
  maxFiles,
  appendFile,
  className
}) {
  const onDrop = useCallback((acceptedFiles) => {
    appendFile(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles
  })

  return (
    <div className={className || ''}>
      <div
        {...getRootProps({ className: `${Styles.dropzone} ${Styles[size]}` })}
      >
        <input {...getInputProps()} />
        <Icon name="UploadFileOutlined" />
        <p>Släpp din fil här</p>
        <p>eller</p>
        <TextButton onClick={() => {}} text={'Bläddra'} />
      </div>
      <div className={Styles['information-container']}>
        <p className={'secondary'}>{`Tillåtna filformat ${accept.join(
          ' '
        )}`}</p>
        {maxFiles ? (
          <p
            className={`secondary ${Styles['accepted-files']}`}
          >{`Max ${maxFiles} fil(er)`}</p>
        ) : null}
      </div>
      <div className={Styles['dropzone-uploaded-content']}>
        {files.map((file, i) => (
          <div
            key={`file-input-${file.name}-${i}`}
            className={Styles['dropzone-dropped-item-container']}
          >
            <div className={Styles['dropzone-title']}>
              <Icon name="InsertDriveFileOutlined" />
              {file.name}
            </div>
            <div
              onClick={() => removeFile(files.filter((f) => f !== file))}
              className={Styles['dropdown-remove-file-container']}
            >
              <Icon name="DeleteOutlined" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Dropzone
