import { getAll, put, PutSimRenaming } from 'redux/api/SimRenamings'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryGetSimRenamings() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_SIM_RENAMINGS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_SIM_RENAMINGS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_SIM_RENAMINGS_FAILED
        })
      })
  }
}

export function tryPutSimRenamings(body: PutSimRenaming[]) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_SIM_RENAMINGS
    })

    put(body)
      .then((response) => {
        dispatch({
          type: Types.PUT_SIM_RENAMINGS_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Terminologin har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_SIM_RENAMINGS_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Terminologin kunde inte uppdateras' }
        })
      })
  }
}
