import * as Api from 'redux/api/Applications/ApiKeys/v3'
import * as Types from 'redux/Types'
import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryCreateApiKey(attributes) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_API_KEY
    })

    const body = {
      data: {
        attributes
      }
    }

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_API_KEY_SUCCESS,
          payload: response.data.data
        })
        messageSuccess('API-nyckel har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_API_KEY_FAILED
        })
        messageWarning('API-nyckel kunde inte skapas')
      })
  }
}

export function tryUpdateDataProductKey(id, name) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_DATA_PRODUCT_KEY
    })

    const body = {
      data: {
        attributes: {
          name
        }
      }
    }

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DATA_PRODUCT_KEY_SUCCESS,
          payload: response.data.data
        })
        messageSuccess('API-nyckel har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DATA_PRODUCT_KEY_FAILED
        })
        messageWarning('API-nyckel kunde inte uppdateras')
      })
  }
}

export function tryDeleteDataProductKey(id, dataProductId) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_DATA_PRODUCT_KEY
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_DATA_PRODUCT_KEY_SUCCESS,
          payload: { id, data_product_id: dataProductId }
        })
        messageSuccess('API-nyckel har raderats')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DATA_PRODUCT_KEY_FAILED
        })
        messageWarning('API-nyckel kunde inte raderas')
      })
  }
}
