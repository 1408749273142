import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import * as Api from 'redux/api/AdditionalSegments'
import { DashboardType } from 'redux/reducers/Dashboards'

export function tryCreateAdditionalSegmentBy(
  body: {
    widget_id: string
    attribute_option_id: string
  },
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.CREATE_ADDITIONAL_SEGMENT
  let successAction = Types.CREATE_ADDITIONAL_SEGMENT_SUCCESS
  let failedAction = Types.CREATE_ADDITIONAL_SEGMENT_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.CREATE_ADDITIONAL_SEGMENT_TEMPLATE
    successAction = Types.CREATE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS
    failedAction = Types.CREATE_ADDITIONAL_SEGMENT_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: successAction,
          payload: { data: response.data.data, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })
      })
  }
}

export function tryUpdateAdditionalSegmentBy(
  id: string,
  body: {
    attribute_option_id: string
  },
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.UPDATE_ADDITIONAL_SEGMENT
  let successAction = Types.UPDATE_ADDITIONAL_SEGMENT_SUCCESS
  let failedAction = Types.UPDATE_ADDITIONAL_SEGMENT_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.UPDATE_ADDITIONAL_SEGMENT_TEMPLATE
    successAction = Types.UPDATE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS
    failedAction = Types.UPDATE_ADDITIONAL_SEGMENT_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: successAction,
          payload: { data: response.data.data, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })
      })
  }
}

export function tryDeleteAdditionalSegmentBy(
  id: string,
  widgetId: string,
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.DELETE_ADDITIONAL_SEGMENT
  let successAction = Types.DELETE_ADDITIONAL_SEGMENT_SUCCESS
  let failedAction = Types.DELETE_ADDITIONAL_SEGMENT_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.DELETE_ADDITIONAL_SEGMENT_TEMPLATE
    successAction = Types.DELETE_ADDITIONAL_SEGMENT_TEMPLATE_SUCCESS
    failedAction = Types.DELETE_ADDITIONAL_SEGMENT_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: successAction,
          payload: { id, widgetId, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })
      })
  }
}
