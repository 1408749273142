export const setChangedRules = () => {
  const nextRun = new Date()

  nextRun.setDate(nextRun.getDate() + 1)
  nextRun.setHours(3)
  nextRun.setMinutes(0)

  localStorage.setItem('data_quality_rules_changed', nextRun)
}

export const getChangedRules = () => {
  const nextRun = localStorage.getItem('data_quality_rules_changed')

  if (new Date(nextRun) < new Date()) {
    localStorage.removeItem('data_quality_rules_changed')

    return null
  }

  return nextRun
}
