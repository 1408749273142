import React, { ReactElement, ReactNode } from 'react'
import { Tab as MUITab } from '@mui/material'

interface TabProps {
  disabled?: boolean
  icon?: ReactElement
  iconPosition?: 'bottom' | 'end' | 'start' | 'top'
  label?: string | ReactNode
  sx?: object
}

const Tab = React.forwardRef((props: TabProps, ref: any) => {
  return <MUITab {...props} ref={ref} />
})

Tab.displayName = 'Tab'
export default Tab
