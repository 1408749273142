import React from 'react'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import Box from 'components_new/atoms/Box'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'

import KpiRow from './KpiRow'

interface SubKpisListProps {
  kpiTitle?: string
  onCreate: (performKpiId: string) => void
  subKpis: KpiTemplate[]
  sx?: object
}

const SubKpisList = (props: SubKpisListProps) => {
  const { kpiTitle, onCreate, subKpis, sx } = props

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        bgcolor: 'background.paper',
        overflow: 'hidden',
        ...sx
      }}
    >
      <Table>
        <TableBody>
          {subKpis.map((subKpi) => (
            <KpiRow
              isSubItem={true}
              key={subKpi.id}
              kpi={subKpi}
              onCreate={(id) => onCreate(id as string)}
              parentTitle={kpiTitle}
              query={''}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default SubKpisList
