import React, { ChangeEvent, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'

import { handleEnter, validateEmail } from '../utils'

interface ForgotPasswordProps {
  errors: { message: string }[]
  loading: boolean
  onBackToSignIn: () => void
  onForgotPassword: (
    email: string,
    setPasswordHasBeenReset: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  setErrors: (errors: { message: string }[]) => void
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const [email, setEmail] = useState('')
  const [errorInEmail, setErrorInEmail] = useState(false)
  const [passwordHasBeenReset, setPasswordHasBeenReset] = useState(false)

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorInEmail(false)
    setEmail(e.target.value)
  }

  const handleForgotPassword = () => {
    const validEmail = validateEmail(email)

    if (validEmail) {
      props.onForgotPassword(email, setPasswordHasBeenReset)
    } else {
      setErrorInEmail(true)
    }
  }

  return (
    <>
      <AuthHero
        title="Glömt ditt lösenord?"
        subtitle="Ange din email för att återställa ditt lösenord."
      />
      <Box sx={{ mb: 4 }}>
        {passwordHasBeenReset ? (
          <>
            <Alert severity={'success'} variant={'outlined'} sx={{ mb: 4 }}>
              <Text color="inherit" variant="subtitle1" gutterBottom={true}>
                Återställningslänk har skickats till din mailinkorg.
              </Text>
              Glöm inte att titta i din <b>skräppost!</b> Det hamnar där
              ibland... 🙄
            </Alert>
          </>
        ) : (
          <>
            <TextField
              error={errorInEmail}
              fullWidth
              helperText={errorInEmail ? 'Ange en giltig email' : undefined}
              label={'Email'}
              onChange={handleChangeEmail}
              onKeyDown={(e) => handleEnter(e, handleForgotPassword)}
              value={email}
              sx={{ mb: 4 }}
            />
            <Button
              color="brand"
              fullWidth={true}
              loading={props.loading}
              loadingTitle="Återställer ditt lösenord"
              onClick={handleForgotPassword}
              size="large"
              sx={{ mb: 4 }}
            >
              Återställ lösenord
            </Button>
            {props.errors.length > 0 ? (
              <Alert severity={'error'} variant={'outlined'} sx={{ mb: 4 }}>
                {props.errors[0].message}
              </Alert>
            ) : null}
          </>
        )}
        <Button
          fullWidth={true}
          onClick={() => {
            props.setErrors([])

            props.onBackToSignIn()
          }}
          startIcon={<Icon name="ArrowBackOutlined" />}
          variant="text"
        >
          Tillbaka till inloggning
        </Button>
      </Box>
    </>
  )
}

ForgotPassword.displayName = 'ForgotPassword'

export default ForgotPassword
