/* Fake feature for sales and review */

import { KpiOptionObject } from 'types/GlobalKpiOption'
import {
  translateWidgetNotificationFrequency,
  translateWidgetNotificationFunction,
  WidgetNotification,
  WidgetNotificationType
} from 'types/GlobalWidgetNotification'

export function translateNotificationTitle(
  notification: WidgetNotification,
  kpiOptions: KpiOptionObject[]
) {
  const kpiOption = kpiOptions.find(
    (opt) => opt.id === notification.listener_settings?.kpi_option_id
  )

  if (
    notification.type === WidgetNotificationType.PERIODIC &&
    notification.periodic_settings
  ) {
    return `Notifiera ${translateWidgetNotificationFrequency[
      notification.periodic_settings.frequency
    ].toLowerCase()}`
  }

  if (
    notification.type === WidgetNotificationType.LISTENER &&
    kpiOption &&
    notification.listener_settings
  ) {
    return `Notifiera om ${kpiOption.title.toLowerCase()} ${translateWidgetNotificationFunction[
      notification.listener_settings.function
    ].toLowerCase()} ${notification.listener_settings.value}`
  }

  return 'Notifikationen har gått sönder'
}

export enum Tab {
  DEFAULT = 'DEFAULT',
  CREATE = 'CREATE'
}
