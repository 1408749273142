import React, { useMemo } from 'react'
import ShortId from 'shortid'

import Select from 'components/common/Select'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'

import Node from './index.js'

import * as Conf from './conf.js'

const LeftJoinNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )
  const rightNode = useMemo(
    () => data?.globalNodeData?.[data.rightNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const rightAttributes = useMemo(() => {
    return rightNode?.data?.outputAttributes || []
  }, [rightNode])

  const parseAttributes = (attrs) =>
    attrs.map((r) => ({
      leftAttribute: null,
      name: r.name,
      realName: r.realName,
      shortId: ShortId.generate(),
      type: r.type,
      rightAttribute: null,
      prepend: r.prepend,
      sensitiveRefColumnId: r.sensitiveRefColumnId
    }))

  return (
    <Node
      icon="JoinLeftOutlined"
      title={'Left join'}
      selected={selected}
      twoInputs
      leftInputCharacter={'L'}
      rightInputCharacter={'R'}
    >
      <Box
        sx={{ alignItems: 'center', display: 'flex', mb: 0.25, width: '172px' }}
      >
        <Chip color="primary" label="L" size="small" sx={{ mr: 0.5 }} />
        <Box sx={{ flex: 1 }}>
          <Select
            className={'nodrag'}
            search
            dropdownMatchSelectWidth={false}
            ignoreLockedMode
            inNode
            size={'small'}
            placeholder={<small className="secondary tiny">Attribut</small>}
            value={data.leftAttribute}
            onChange={(val) => {
              // Generate new outputAttributes
              if (
                val &&
                !data.leftAttribute &&
                data.rightAttribute &&
                data.leftNode &&
                data.rightNode
              ) {
                const outputAttributes = [
                  ...parseAttributes(leftAttributes),
                  ...parseAttributes(rightAttributes)
                ]

                setData(id, { leftAttribute: val, outputAttributes })
              } else {
                setData(id, {
                  leftAttribute: val,
                  outputAttributes:
                    !data.rightNode || !val ? [] : data.outputAttributes
                })
              }
            }}
            options={Conf.parseAttributeOptions(
              leftAttributes,
              data.nodeNumberMapper
            )}
          />
        </Box>
      </Box>

      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Chip color="primary" label="R" size="small" sx={{ mr: 0.5 }} />
        <Box sx={{ flex: 1 }}>
          <Select
            className={'nodrag'}
            search
            dropdownMatchSelectWidth={false}
            ignoreLockedMode
            inNode
            size={'small'}
            placeholder={<small className="secondary tiny">Attribut</small>}
            value={data.rightAttribute}
            onChange={(val) => {
              // Generate new outputAttributes
              if (
                val &&
                !data.rightAttribute &&
                data.leftAttribute &&
                data.leftNode &&
                data.rightNode
              ) {
                const outputAttributes = [
                  ...parseAttributes(leftAttributes),
                  ...parseAttributes(rightAttributes)
                ]

                setData(id, { rightAttribute: val, outputAttributes })
              } else {
                setData(id, {
                  rightAttribute: val,
                  outputAttributes:
                    !data.leftNode || !val ? [] : data.outputAttributes
                })
              }
            }}
            options={Conf.parseAttributeOptions(
              rightAttributes,
              data.nodeNumberMapper
            )}
          />
        </Box>
      </Box>
    </Node>
  )
}

export default LeftJoinNode
