import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

import { Survey, SurveyReducerType } from 'types/GlobalSurvey'

// Initial state
const INITIAL_STATE: SurveyReducerType = {
  data: [],
  fetched: false
}

// Actions
const getAllSurveysSuccess = createAction<{ data: Survey[] }>(
  Types.GET_ALL_SURVEYS_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const SurveyReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(getAllSurveysSuccess, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        data: payload,
        fetched: true
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default SurveyReducer
