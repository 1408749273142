import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'

import TabSwitch from 'components_new/molecules/TabSwitch'

import { SurveyReducerType } from 'types/GlobalSurvey'
import { TrackingUser } from 'types/GlobalTracking'

import Roles from './Roles'
import Survey from './Survey'
import UserTrackingKanbanTemplate from './UserTrackingKanban'

interface UsersTrackingTemplateProps {
  SurveyStore: SurveyReducerType
  usersUsage: TrackingUser[]
}

enum Tabs {
  Kanban = 'Kanban',
  Survey = 'Survey',
  Roles = 'Roles'
}

const UsersTrackingTemplate = (props: UsersTrackingTemplateProps) => {
  const [tab, setTab] = useState(Tabs.Kanban)

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}>
          <TabSwitch
            color="primary"
            maxHeight="40px"
            options={[
              {
                value: Tabs.Kanban,
                label: 'Kanban'
              },
              {
                value: Tabs.Survey,
                label: 'Undersökningar'
              },
              {
                value: Tabs.Roles,
                label: 'Roller'
              }
            ]}
            value={tab}
            onChange={(value) => setTab(value)}
            noGutter={true}
          />
        </Box>
        <Box sx={{ flex: '1 1 auto', position: 'relative' }}>
          <CurrentTab
            usersUsage={props.usersUsage}
            selected={tab}
            SurveyStore={props.SurveyStore}
          />
        </Box>
      </Box>
    </>
  )
}

interface CurrentTabProps {
  selected: Tabs
  SurveyStore: SurveyReducerType
  usersUsage: TrackingUser[]
}

const CurrentTab = (props: CurrentTabProps) => {
  if (props.selected === Tabs.Kanban) {
    return <UserTrackingKanbanTemplate usersUsage={props.usersUsage} />
  }

  if (props.selected === Tabs.Survey) {
    return <Survey SurveyStore={props.SurveyStore} />
  }

  if (props.selected === Tabs.Roles) {
    return <Roles usersUsage={props.usersUsage} />
  }

  return <></>
}

export default UsersTrackingTemplate
