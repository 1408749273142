import React from 'react'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

import {
  SurveyReducerType,
  translateSurveyResponseTypeEnum
} from 'types/GlobalSurvey'
import Paper from 'components_new/atoms/Paper'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import { default as AtomTable } from 'components_new/atoms/Table'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableBody from 'components_new/atoms/Table/TableBody'

interface SurveyProps {
  SurveyStore: SurveyReducerType
}

const HEADERS = [
  { label: 'Användare' },
  { label: 'Kund' },
  { label: 'Frågeställning' },
  { label: 'Svar' },
  { label: 'Datum' }
]

const Survey = (props: SurveyProps) => {
  const rows = [...props.SurveyStore.data]
    .sort((o1, o2) => {
      return (
        new Date(o2.created_at).getTime() - new Date(o1.created_at).getTime()
      )
    })
    .map((item) => {
      return {
        label: item.user.name,
        values: [
          item.customer.name,
          'Hittade du informationen du behövde?',
          translateSurveyResponseTypeEnum[item.response],
          new Date(item.created_at).toISOString().split('T')[0]
        ]
      }
    })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            flex: '1 1 auto',
            position: 'relative',
            width: '100%'
          }}
        >
          <Table loading={!props.SurveyStore.fetched} rows={rows} />
        </Box>
      </Box>
    </>
  )
}

interface TableProps {
  loading?: boolean
  rows: {
    label: string
    values: string[]
  }[]
}

const Table = (props: TableProps) => {
  if (props.loading) {
    return (
      <Paper sx={{ mt: 2, width: '100%', height: 520 }}>
        <LoadingSection
          titles={[
            'Hämtar information om våra kunder',
            'Hämtar information om våra kunders nyckeltal'
          ]}
          loading
        />
      </Paper>
    )
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <AtomTable sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            {HEADERS.map((header) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={header.label}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell scope="row">{row.label}</TableCell>
              {row.values.map((value, j) => (
                <TableCell key={`${i}-${j}`}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </AtomTable>
    </TableContainer>
  )
}

export default Survey
