import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

export interface SIMTable {
  attributes: { id: string; name: string }[]
  id: string
  title: string
  plural_title: string
  has_data: boolean
}

export type Preview = {
  data: { [offset: number]: any[] }
  count: number
  offset: number
  currentModel: string
  fetching: boolean
}

export interface SIMReducerType {
  tables: SIMTable[]
  preview: Preview | null
  fetched: boolean
}

const INITIAL_STATE: SIMReducerType = {
  tables: [],
  preview: null,
  fetched: false
}

const getTablesAction = createAction<{ data: SIMTable[] }>(
  Types.GET_SIM_TABLES_SUCCESS
)
const getDataAction = createAction<{
  tableId: string
}>(Types.GET_SIM_DATA)
const getDataSuccessAction = createAction<{
  data: { count: number; rows: object[]; offset: number; tableId: string }
}>(Types.GET_SIM_DATA_SUCCESS)
const getDataFailedAction = createAction(Types.GET_SIM_DATA_FAILED)
const signOutAction = createAction(Types.SIGN_OUT)

const simReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getTablesAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        tables: payload.data
      }
    })
    .addCase(getDataAction, (state, action) => {
      const { payload } = action

      if (state.preview?.currentModel !== payload.tableId) {
        return {
          ...state,
          preview: {
            data: {},
            count: 0,
            offset: 0,
            currentModel: payload.tableId,
            fetching: true
          }
        }
      }
      if (state.preview) {
        return {
          ...state,
          preview: {
            ...state.preview,
            fetching: true
          }
        }
      }

      return state
    })
    .addCase(getDataSuccessAction, (state, action) => {
      const { payload } = action

      let existingData = state.preview?.data ?? {}

      if (
        state.preview?.currentModel !== payload.data.tableId ||
        state.preview?.count !== payload.data.count
      ) {
        existingData = {}
      }

      return {
        ...state,
        preview: {
          data: {
            ...existingData,
            [payload.data.offset]: payload.data.rows
          },
          count: payload.data.count,
          offset: payload.data.offset,
          currentModel: payload.data.tableId,
          fetching: false
        }
      }
    })
    .addCase(getDataFailedAction, (state) => {
      return {
        ...state,
        preview: {
          data: state.preview?.data ?? {},
          count: state.preview?.count ?? 0,
          offset: state.preview?.offset ?? 0,
          currentModel: state.preview?.currentModel ?? '',
          fetching: false
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default simReducer
