import React, { ElementType, ReactElement } from 'react'

// Replace these later with our own atoms.
import { ToggleButtonGroup as MUIToggleButtonGroup } from '@mui/material'
import { ToggleButton as MUIToggleButton } from '@mui/material'

import Tooltip from 'components_new/atoms/Tooltip'

interface TooltipToggleButtonGroupProps {
  component?: ElementType<any>
  color?:
    | 'standard'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  disabled?: boolean
  exclusive?: boolean
  fullWidth?: boolean
  items: {
    disabled?: boolean
    title: ReactElement
    tooltip?: ReactElement | string
    value: any
  }[]
  onChange?: (event: any, value: any) => void
  orientation?: 'horizontal' | 'vertical'
  size?: 'small' | 'medium' | 'large'
  sx?: object
  value?: any
}

/**
 * The TooltipToggleButtonGroup component can be used
 * to group related options and display tooltips for
 * every option.
 *
 * It can be used to display and control one or more
 * states for the user.
 *
 * -> TODO: Replace all instances of ToggleButton with
 * this component – and add ToggleButton +
 * ToggleButtonGroup to atoms.
 */

const TooltipToggleButtonGroup = React.forwardRef(
  (props: TooltipToggleButtonGroupProps, ref: any) => {
    const { items, ...rest } = props

    return (
      <MUIToggleButtonGroup {...rest} ref={ref}>
        {items.map((item, i) => (
          <MUIToggleButton
            disabled={item.disabled}
            key={i}
            value={item.value}
            sx={{
              pointerEvents: 'all!important',
              '&:hover': { bgcolor: item.disabled ? 'transparent' : undefined }
            }}
          >
            <Tooltip title={item.tooltip ?? ''}>{item.title}</Tooltip>
          </MUIToggleButton>
        ))}
      </MUIToggleButtonGroup>
    )
  }
)

TooltipToggleButtonGroup.defaultProps = {
  color: 'primary',
  exclusive: true,
  fullWidth: true
}

TooltipToggleButtonGroup.displayName = 'TooltipToggleButtonGroup'
export default TooltipToggleButtonGroup
