import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import _ from 'lodash'

import Pagination from 'components/common/Pagination'
import ProgressBar from 'components/common/ProgressBar'
import Table from 'components/common/Table'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'
import * as LockedModeActions from 'redux/actions/LockedMode'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Classification = ({
  tryUpdateCmdbTableClassification,
  CmdbTableStore,
  TagStore,
  match,
  setLockedMode,
  LockedModeStore
}) => {
  const table = CmdbTableStore.data[match.params.cmdbId]

  const [pk, ref, desc] = Conf.initData(table)

  const [page, setPage] = useState(1)
  const [descriptions, setDescriptions] = useState(desc)
  const [primaryKey, setPrimaryKey] = useState(pk)
  const [reference, setReference] = useState(ref)

  // check difference effect
  useEffect(() => {
    const samePk = primaryKey === pk
    const sameRef = reference === ref
    const sameDescription = _.isEqual(descriptions, desc)

    if (samePk && sameRef && sameDescription) {
      if (LockedModeStore.locked) {
        setLockedMode({ locked: false })
      }
    } else {
      if (!LockedModeStore.locked) {
        setLockedMode({ locked: true, ignoreId: 'classification-wrapper' })
      }
    }
  }, [primaryKey, reference, descriptions])

  // calculate score
  const hasPrimary = primaryKey ? 1 : 0
  const hasReference = reference ? 1 : 0
  const classifiedAttributes = table.attributes.columns.reduce(
    (a, b) => a + (b.id in TagStore.linkers ? 1 : 0),
    0
  )
  const totalClassified = hasPrimary + hasReference + classifiedAttributes

  let percentage = Math.floor(
    100 * (totalClassified / (table.attributes.columns.length + 2))
  )

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Data</h2>
        <Button
          onClick={() => {
            const data = {
              attributes: {
                description: descriptions[table.id],
                columns: table.attributes.columns.map((col) => ({
                  id: col.id,
                  attributes: {
                    tag_primary_key: primaryKey === col.id,
                    tag_reference: reference === col.id,
                    description: descriptions[col.id]
                  }
                }))
              }
            }

            tryUpdateCmdbTableClassification(table.id, {
              data
            })

            setLockedMode({ locked: false })
          }}
          startIcon={<Icon name="Sync" />}
          variant="text"
        >
          Uppdatera
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Table
        headers={Conf.TABLE_HEADERS(descriptions, setDescriptions)}
        rows={Conf.getTableRows(table, table.id in TagStore.linkers)}
        rowClassName={Styles['row-height-big']}
        scroll={{ x: 200 }}
        ellipsis={false}
        parsedFormat
      />
      <Box sx={{ my: 2 }}>
        <p className={`bold ${Styles['classify-margin-bottom']}`}>
          Klassificera attribut
        </p>
        <ProgressBar
          percent={percentage}
          hoverTitle={'Ej klassificerade'}
          hoverContent={
            <ul>
              <li>
                Attribut:{' '}
                {table.attributes.columns.length - classifiedAttributes}
              </li>
              {hasPrimary ? null : <li>Primär nyckel saknas</li>}
              {hasReference ? null : <li>Referens saknas</li>}
            </ul>
          }
        />
      </Box>
      <Table
        headers={Conf.ATTRIBUTE_HEADERS(
          primaryKey,
          setPrimaryKey,
          reference,
          setReference,
          descriptions,
          setDescriptions
        )}
        rows={Conf.getAttributeRows(
          table.attributes.columns,
          page,
          TagStore.linkers
        )}
        rowClassName={Styles['row-height']}
        scroll={{ x: 200 }}
        ellipsis={false}
        parsedFormat
      />
      <Pagination
        totalItems={table.attributes.columns.length}
        perPage={Conf.PAGE_LIMIT}
        text={'attribut'}
        onChange={(page) => setPage(page)}
      />
    </Paper>
  )
}

function mapStateToProps({ LockedModeStore, CmdbTableStore, TagStore }) {
  return { LockedModeStore, CmdbTableStore, TagStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...CmdbTableActions, ...LockedModeActions },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Classification))
