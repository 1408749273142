import React, {
  ElementType,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef
} from 'react'

import Dialog from 'components_new/atoms/Dialog'

// These are going to be replaced with our own components later.
import { DialogTitle, DialogContent, DialogActions } from '@mui/material'
import Box from 'components_new/atoms/Box'

interface SidebarDialogProps {
  actions?: ReactNode
  children: ReactNode
  component?: ElementType<any>
  contentText?: ReactNode
  disableOverflow?: boolean
  footer?: ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
  hero?: ReactNode
  leftSidebar?: ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  noGutter?: boolean
  onClose?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  PaperProps?: object
  rightSidebar?: ReactNode
  scrollToTop?: boolean
  sx?: object
  title?: ReactNode
}

/**
 * The SidebarDialog component is a
 * variant of a Dialog that is the size
 * of an Advanced, but comes with sidebar
 * features.
 */

const SidebarDialog = React.forwardRef(
  (props: SidebarDialogProps, ref: any) => {
    const {
      actions,
      children,
      disableOverflow,
      footer,
      fullHeight,
      leftSidebar,
      noGutter,
      PaperProps,
      rightSidebar,
      title,
      scrollToTop,
      ...rest
    } = props

    const contentRef = useRef<HTMLElement>(null)

    useEffect(() => {
      if (scrollToTop && contentRef.current) {
        contentRef.current.scrollTo({ top: 0, behavior: 'auto' })
      }
    })

    return (
      <Dialog
        PaperProps={{
          sx: {
            transition: 'all 0.5s ease'
          },
          ...PaperProps
        }}
        {...rest}
        ref={ref}
      >
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent
          ref={contentRef}
          dividers={true}
          sx={{
            p: 0,
            overflow: 'hidden',
            height: fullHeight ? '90vh' : undefined,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            transition: 'all 0.5s ease'
          }}
        >
          {leftSidebar ? (
            <Box
              sx={{
                flex: '0 0 256px',
                overflowY: 'auto',
                // Makes the tab text wrap:
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'divider'
              }}
            >
              {leftSidebar}
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: disableOverflow ? 'hidden' : 'auto',
              p: noGutter ? 0 : 2,
              position: 'relative'
            }}
          >
            {children}
          </Box>
          {rightSidebar ? (
            <Box
              sx={{
                flex: '0 0 256px',
                overflowY: 'auto',
                // Makes the tab text wrap:
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                borderLeftColor: 'divider'
              }}
            >
              {rightSidebar}
            </Box>
          ) : null}
        </DialogContent>
        {footer ? (
          <Box
            sx={{
              flex: '0 0 auto',
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            {footer}
          </Box>
        ) : null}
        {actions ? <DialogActions>{actions}</DialogActions> : null}
      </Dialog>
    )
  }
)

SidebarDialog.defaultProps = {
  maxWidth: 'md',
  fullWidth: true,
  onMouseDown: (e) => e.stopPropagation()
}

SidebarDialog.displayName = 'SidebarDialog'
export default SidebarDialog
