import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, useParams } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'
import * as AdditionalSegmentActions from 'redux/actions/AdditionalSegments'
import * as KpiOptionActions from 'redux/actions/KpiOptions'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as TemplateActions from 'redux/actions/DashboardTemplates'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardTemplateGroupActions from 'redux/actions/DashboardTemplateGroups'
import * as WidgetActions from 'redux/actions/Widgets'
import * as WidgetTagActions from 'redux/actions/WidgetTags'

import { DashboardType } from 'redux/reducers/Dashboards'

import LoadingSection from 'components_new/molecules/LoadingSection'

import DashboardTemplate from 'components_new/templates/DashboardTemplate'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import {
  downloadPNG,
  findAvailableSpace,
  LayoutItem
} from 'components_new/pages/Dashboard/utils'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPITemplateStore: state.KPITemplateStore,
  TemplateStore: state.TemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AdditionalSegmentActions,
      ...DashboardActions,
      ...DashboardTemplateGroupActions,
      ...KpiOptionActions,
      ...KPITemplateActions,
      ...TemplateActions,
      ...WidgetActions,
      ...WidgetTagActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type DashboardPageProps = ConnectedProps<typeof connector>

const TemplatePage: FC<DashboardPageProps> = (props: DashboardPageProps) => {
  const {
    AuthStore,
    KPITemplateStore,
    TemplateStore,
    tryGetAllDashboardTemplates,
    tryGetOneWidget,
    tryUploadDashboardThumbnail
  } = props
  const [initialDataFetched, setInitialDataFetched] = useState<boolean>(false)
  const history = useHistory()

  const params = useParams<{ id: string }>()

  const dashboard = TemplateStore.data[params.id]

  useEffect(() => {
    const { tryGetAllFilterOptions, tryGetAllKPIs } = props

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      tryGetAllKPIs()
    }

    if (
      !KPITemplateStore.fetchedFilterOptions &&
      !KPITemplateStore.fetchingFilterOptions
    ) {
      tryGetAllFilterOptions()
    }

    if (!TemplateStore.fetched) {
      tryGetAllDashboardTemplates()
    }
  }, [])

  useEffect(() => {
    const { tryGetOneDashboardTemplate } = props

    if (params.id !== TemplateStore.activeFetchOne) {
      setInitialDataFetched(false)
      tryGetOneDashboardTemplate(params.id, () => history.push('/templates'))
    }
  }, [params.id])

  useEffect(() => {
    if (TemplateStore.activeFetchOne === params.id && !initialDataFetched) {
      Object.values(dashboard.widgets).forEach((widget) =>
        tryGetOneWidget(
          widget.id,
          widget.settings.type.selected,
          widget.settings.show_total,
          widget.settings.segment_by.additional,
          dashboard.id,
          null,
          DashboardType.TEMPLATE
        )
      )

      setInitialDataFetched(true)
    }
  }, [TemplateStore.data])

  useEffect(() => {
    // Fetch data for new/edited widgets
    if (TemplateStore.currentWidgetId && dashboard) {
      const widget = dashboard.widgets[TemplateStore.currentWidgetId]

      tryGetOneWidget(
        widget.id,
        widget.settings.type.selected,
        widget.settings.show_total,
        widget.settings.segment_by.additional,
        dashboard.id,
        null,
        DashboardType.TEMPLATE
      )
    }
  }, [TemplateStore.currentWidgetId])

  // @TODO: Below should be moved to dashboard grid organism:
  const widgets = dashboard.widgets

  const [dashboardWidgets, setWidgets] = useState<string[]>([])

  useEffect(() => {
    setWidgets(Object.values(widgets).map((widget) => widget.id))

    setAvailableSpace(findAvailableSpace(layout))
  }, [widgets])

  const [availableSpace, setAvailableSpace] = useState<LayoutItem | false>(
    false
  )

  const gridRef = useRef(null)

  // using reference combined with useEffect because
  // useLayoutEffect uses the values of these variables when
  // the useEffect was triggered. But with reference it uses
  // the current value of the variable.
  const allWidgetsFetchedRef = useRef(false)

  useLayoutEffect(() => {
    return () => {
      // required to be an useRef variable
      if (allWidgetsFetchedRef.current && gridRef.current) {
        downloadPNG(gridRef.current, (thumbnail: Blob | null) => {
          if (thumbnail && !AuthStore?.user?.is_company_group) {
            tryUploadDashboardThumbnail(dashboard.id, thumbnail)
          }
        })
      }
    }
  }, [])

  const layout: { x: number; y: number; w: number; h: number; i: string }[] =
    useMemo(() => {
      const newLayout = dashboardWidgets
        .filter((id: string) => widgets[id])
        .map((id: string) => {
          const widget = widgets[id]

          return {
            x: widget.layout.x,
            y: widget.layout.y,
            w: widget.layout.width,
            h: widget.layout.height,
            i: id
          }
        })

      setAvailableSpace(findAvailableSpace(newLayout))

      return newLayout
    }, [dashboardWidgets])

  return (
    <>
      {TemplateStore.activeFetchOne === params.id && TemplateStore.fetched ? (
        <DashboardTemplate
          availableSpace={availableSpace}
          gridRef={gridRef}
          setDashboardFilter={() => {}}
          setPeriodFilter={() => {}}
          dashboardFilter={[]}
          periodFilter={null}
          // internal={true}
        />
      ) : (
        <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
      )}
    </>
  )
}

export default connector(TemplatePage)
