import React, { useState, useEffect } from 'react'

import { Row, Col, Tree, Checkbox, Form } from 'antd'

import ThickHeader from 'components/common/ThickHeader'
import Select from 'components/common/Select'
import Input from 'components/common/Input'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Content = ({
  stream = [],
  activeTable,
  editMode,
  formRef,
  resetValues
}) => {
  const [activeCondition, setActiveCondition] = useState(true)

  useEffect(() => {
    setActiveCondition(
      activeTable && activeTable.included.stream_filters.length > 0
    )
  }, [activeTable, resetValues])

  return (
    <div>
      <Row className={Styles['header-container']}>
        <ThickHeader header={'Innehåll'} />
        <div className={Styles.divider} />

        <Col span={8}>
          <small className={`bold ${Styles['header-margin']}`}>
            INKOMMANDE STRÖM
            <small className="secondary">{` (${stream.length} EVENTS)`} </small>
          </small>
        </Col>

        <Col span={8}>
          <small className={`bold ${Styles['header-margin']}`}>
            VALDA ATTRIBUT
          </small>
        </Col>

        <Col span={8}>
          <small className={`bold ${Styles['header-margin']}`}>TABELL</small>
        </Col>
      </Row>

      <Row className={Styles['content-container']}>
        <Col span={8}>
          <div className={Styles['stream-preview']}>{stream}</div>
        </Col>

        <Col span={8} className={Styles['content-wrapper']}>
          <Tree
            className={'ignore-locked-mode'}
            selectable={false}
            checkable={false}
            treeData={Conf.TREE_DATA(stream)}
            defaultExpandedKeys={['body']}
          />
        </Col>

        <Col span={8} className={Styles['content-wrapper']}>
          <div>
            <Checkbox
              disabled={!editMode}
              className={Styles.checkbox}
              checked={activeCondition}
              onChange={() => {
                setActiveCondition(!activeCondition)
                formRef.current.setFieldsValue({
                  attribute: undefined,
                  condition: undefined,
                  value: undefined
                })
              }}
            >
              Villkor
            </Checkbox>
            <p className={Styles['text-margin']}>
              Lägg till objekt i{' '}
              <b className={'primary'}>{activeTable?.attributes.name}</b> om:
            </p>
            <Form.Item name={'attribute'}>
              <Select
                ignoreLockedMode
                disabled={!activeCondition || !editMode}
                className={Styles['select-margin']}
                options={Conf.INPUT_ATTRIBUTES(stream)}
              />
            </Form.Item>
            <Form.Item name={'condition'}>
              <Select
                ignoreLockedMode
                disabled={!activeCondition || !editMode}
                className={Styles['select-margin']}
                options={Conf.CONDITIONS}
              />
            </Form.Item>
            <Form.Item name={'value'}>
              <Input
                className={'ignore-locked-mode'}
                disabled={!activeCondition || !editMode}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Content
