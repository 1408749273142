import React from 'react'

import Styles from './styles.module.css'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

const SideBar = ({
  title,
  description,
  content,
  actions,
  open,
  overlay = false
}) => {
  return (
    <Box
      className={
        overlay
          ? open
            ? Styles['visible-overlay']
            : Styles['hidden-overlay']
          : ''
      }
      sx={{
        bgcolor: 'white.main',
        display: 'flex',
        flexDirection: 'column',
        width: '30vw'
      }}
    >
      {title ? (
        <>
          <Box
            sx={{
              p: 2
            }}
          >
            <Text gutterBottom variant="h4">
              {title}
            </Text>
            <Text variant="body1">{description}</Text>
          </Box>
          <Divider />
        </>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          p: 2,
          overflowY: 'scroll'
        }}
      >
        {content}
      </Box>
      {actions && (
        <>
          <Divider />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, p: 2 }}
          >
            {actions}
          </Box>
        </>
      )}
    </Box>
  )
}

export default SideBar
