import React, { FC, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Collapse from 'components_new/atoms/Collapse'
import Divider from 'components_new/atoms/Divider'
import FormControl from 'components_new/atoms/FormControl'
import InputLabel from 'components_new/atoms/InputLabel'
import ListItem from 'components_new/atoms/List/ListItem'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Select from 'components_new/atoms/Select'
import Text from 'components_new/atoms/Text'
import Autocomplete from 'components_new/atoms/Autocomplete'
import Checkbox from 'components_new/atoms/Checkbox'
import TextField from 'components_new/atoms/TextField'

import IconTip from 'components_new/molecules/IconTip'

import { translateFilterCondition, translateFilter } from 'utils/enumTranslator'

import { onlyLeftCondition, validFilter } from './utils'
import { Condition, FilterType } from 'types/GlobalKpiOption'
import { FilterBody } from 'utils/types'

interface FilterCascadeMenuProps {
  anchorEl: HTMLElement | null
  filterConditionOptions: Condition[]
  filter: FilterBody | null
  onClose: () => void
  open: boolean
  setFilter: (
    body: {
      condition: Condition
      value: string | null | string[]
      type: FilterType
    } | null
  ) => void
  options?: string[]
  attributeName?: string
}

const FilterCascadeMenu: FC<FilterCascadeMenuProps> = ({
  anchorEl,
  filterConditionOptions,
  filter,
  onClose,
  open,
  setFilter,
  options,
  attributeName = ''
}) => {
  const initValue = options ? [] : ''
  const [condition, setCondition] = useState<Condition | null>(null)
  // Value state-handlers
  const [value, setValue] = useState<string | string[]>(initValue)

  const clearAll = () => {
    setCondition(null)
    setValue(initValue)

    setFilter(null)
  }

  const handleClose = () => {
    if (!validFilter(condition, value)) {
      setCondition(null)
      setValue(initValue)
    }
    onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      disableScrollLock
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onMouseDown={(e) => e.stopPropagation()}
      PaperProps={{
        sx: { minWidth: 196, maxWidth: '50%' }
      }}
    >
      <Box sx={{ px: 1, display: 'flex', justifyContent: 'space-between' }}>
        <IconTip
          title={
            <>
              <Text variant="body2" color="inherit" gutterBottom>
                <b>Hur var det nu igen... 🤔</b>
              </Text>
              <Text variant="body2" color="inherit">
                Genom att sätta ett filter väljer du vad som ska{' '}
                <b>inkluderas</b>. Du filtrerar alltså bort den data som inte
                uppfyller ditt uppsatta villkor.
              </Text>
            </>
          }
          variant="note"
          sx={{ ml: 0.5 }}
        />
        <Button onClick={() => clearAll()} variant="text" size="small">
          Rensa alla
        </Button>
      </Box>

      <ListItem>
        <FormControl fullWidth size="small">
          <InputLabel id="condition-label">Välj villkor</InputLabel>
          <Select
            labelId="condition-label"
            label="Välj villkor"
            value={condition ?? ''}
            onChange={(event) => {
              if (
                !event.target.value ||
                onlyLeftCondition(event.target.value as Condition)
              ) {
                setValue(initValue)
              }

              setCondition(event.target.value as Condition)
            }}
          >
            <MenuItem value="">
              <em>Ingen</em>
            </MenuItem>
            {filterConditionOptions.map((option, i) => (
              <MenuItem key={i} value={option}>
                {translateFilterCondition[option].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
      <Collapse in={Boolean(condition) && !onlyLeftCondition(condition)}>
        <ListItem>
          <FormControl fullWidth size="small">
            {options ? (
              <Autocomplete
                multiple
                options={options}
                value={value}
                onChange={(event, options) => setValue(options)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Välj ${attributeName}`}
                    placeholder="Sök..."
                  />
                )}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option}>
                    <Checkbox sx={{ mr: 1 }} checked={selected} />
                    {option}
                  </li>
                )}
                size={'small'}
              />
            ) : (
              <TextField
                label="Värde"
                onChange={(event) => setValue(event.target.value)}
                size="small"
                value={value}
                sx={{ my: 0 }}
              />
            )}
          </FormControl>
        </ListItem>
      </Collapse>

      <ListItem>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={() => {
            if (validFilter(condition, value)) {
              const type = onlyLeftCondition(condition)
                ? FilterType.ONLY_LEFT
                : FilterType.INPUT_VALUE
              const newFilter = {
                condition: condition as Condition,
                value: value,
                type: type
              }

              setFilter(newFilter)
              setCondition(null)
              setValue(initValue)
            }
          }}
        >
          Lägg till
        </Button>
      </ListItem>

      <Divider />

      <ListItem alignItems="flex-start" sx={{ flexDirection: 'column' }}>
        {filter && validFilter(filter.condition, filter.value) ? (
          <Chip
            label={translateFilter(
              attributeName,
              filter.condition,
              Array.isArray(filter.value)
                ? filter.value.join(',')
                : (filter.value as string)
            )}
            size="small"
            sx={{
              width: 'fit-content',
              justifyContent: 'flex-start',
              my: 0.25
            }}
            onDelete={() => setFilter(null)}
          />
        ) : (
          <Text color="text.disabled">Det finns inga aktiva filter.</Text>
        )}
      </ListItem>
    </Menu>
  )
}

export default FilterCascadeMenu
