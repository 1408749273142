import React from 'react'
import ShortId from 'shortid'

import Icon from 'components_new/atoms/Icon'
import Chip from 'components_new/atoms/Chip'

export const getTextType = (type) => {
  switch (type) {
  case 'API':
    return { plural: 'System', singular: 'System', path: 'systems' }
  case 'OnPremiseDirect':
    return { plural: 'Databaser', singular: 'Databas', path: 'databases' }
  case 'File':
    return { plural: 'Filer', singular: 'Fil', path: 'files' }
  case 'OnPremise':
    return { plural: 'Anpassade', singular: 'Anpassad', path: 'custom' }
  case 'Stream':
    return { plural: 'Strömmar', singular: 'Ström', path: 'streams' }
  default:
    return { plural: 'Datakällor', singular: 'Datakälla', path: 'systems' }
  }
}

export const getType = () => {
  if (window.location.pathname.includes('systems')) {
    return 'API'
  } else if (window.location.pathname.includes('database')) {
    return 'OnPremiseDirect'
  } else if (window.location.pathname.includes('files')) {
    return 'File'
  } else if (window.location.pathname.includes('custom')) {
    return 'OnPremise'
  } else if (window.location.pathname.includes('streams')) {
    return 'Stream'
  }
}

export const SOURCE_HEADERS = [
  { label: 'NAMN', key: 'name' },
  {
    label: 'TYP',
    key: 'type',
    render: function renderColumn(type) {
      let text = 'System'
      let icon = <Icon name="StorageOutlined" />

      if (type === 'OnPremise') {
        icon = <Icon name="SettingsSuggestOutlined" />
        text = 'Anpassad'
      } else if (type === 'OnPremiseDirect') {
        icon = <Icon name="DnsOutlined" />
        text = 'Databas'
      } else if (type === 'File') {
        icon = <Icon name="InsertDriveFileOutlined" />
        text = 'Fil'
      } else if (type === 'Stream') {
        icon = <Icon name="RssFeed" />
        text = 'Ström'
      }

      return <Chip icon={icon} label={text} size="small" />
    }
  },
  { label: 'TABELLER', key: 'table_count' },
  { label: 'ATTRIBUT', key: 'column_count' },
  { label: 'RADER', key: 'row_count' },
  {
    label: 'ANVÄNDNING',
    key: 'in_use',
    render: function renderColumn(inUse) {
      return (
        <Chip
          color={inUse ? 'success' : 'warning'}
          label={inUse ? 'Används' : 'Används ej'}
          size="small"
        />
      )
    }
  }
  // {
  //   label: 'STATUS',
  //   key: 'status',
  //   render: function renderColumn(installed) {
  //     return (
  //       <Tag
  //         text={installed === 'true' ? 'Används' : 'Pausad'}
  //         className={installed === 'true' ? 'success' : 'warning'}
  //         icon={installed === 'true' ? faCheck : faPause}
  //       />
  //     )
  //   }
  // }

  // {
  //   label: 'SENASTE HÄMTNING',
  //   key: 'latest_run_timestamp'
  // }
]

export const SOURCE_HEADERS_STREAM = [
  { label: 'NAMN', key: 'name' },
  {
    label: 'TYP',
    key: 'type',
    render: function renderColumn() {
      return (
        <Chip icon={<Icon name="RssFeed" />} label={'Ström'} size="small" />
      )
    }
  },
  {
    label: 'ANVÄNDNING',
    key: 'in_use',
    render: function renderColumn(usage) {
      return (
        <Chip
          color={usage ? 'success' : 'warning'}
          label={usage ? 'Används' : 'Används ej'}
          size="small"
        />
      )
    }
  }
]

export const TOGGLE_ITEMS = [
  {
    label: 'Alla',
    value: null,
    key: ShortId.generate()
  },
  {
    label: 'Används',
    value: true,
    key: ShortId.generate()
  },
  {
    label: 'Används ej',
    value: false,
    key: ShortId.generate()
  }
]

export const PAGE_LIMIT = 10

export const getRows = (page, toggle, SourceStore) => {
  const type = getType()

  const data = Object.values(SourceStore.data)
    .filter(
      (item) =>
        item.included &&
        item.included.active_sources &&
        (item.attributes.type === type ||
          !type ||
          (type === 'API' && item.attributes.type === 'HIDDEN'))
    )
    .map((source) =>
      source.included.active_sources.map((activeSource) => ({
        id: activeSource.id,
        attributes: {
          name: activeSource.attributes.display_name || source.attributes.name,
          status: activeSource.attributes.installed,
          type: source.attributes.type,
          in_use: activeSource.included.active_shapes,
          column_count: activeSource.attributes.column_count,
          row_count: activeSource.attributes.row_count,
          table_count: activeSource.attributes.table_count
        }
      }))
    )
    .flat()
    .filter((item) =>
      toggle.value === null
        ? true
        : (item.attributes.in_use !== undefined) === toggle.value
    )

  const start = PAGE_LIMIT * (page - 1)

  return [data.slice(start, start + PAGE_LIMIT), data.length]
}
