import React from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Table from 'components/common/Table'
import { HEADERS, ReadTableToggle } from './conf'

import * as SourceColumnActions from 'redux/actions/DataPlatform/connections/SourceColumns'
import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'

import Styles from './styles.module.css'

const Import = ({
  activeTable,
  SourceTableStore,
  tryUpdateSourceColumn,
  tryUpdateSourceTable
}) => {
  const sourceTable = SourceTableStore.data[activeTable?.id]

  return (
    <>
      <div className={Styles['read-table-wrapper']}>
        <h3>Läs in tabellen?</h3>
        <ReadTableToggle
          tryUpdateSourceTable={tryUpdateSourceTable}
          activeTable={activeTable}
        />
      </div>
      <Table
        size="middle"
        rows={
          sourceTable
            ? sourceTable?.data.attributes.columns.map((col) => ({
              column: col.name,
              isSensitive: col.is_sensitive,
              id: col.id
            }))
            : []
        }
        headers={HEADERS(activeTable?.id, tryUpdateSourceColumn)}
        parsedFormat
      />
    </>
  )
}

function mapStateToProps({ AuthStore, SourceTableStore }) {
  return { AuthStore, SourceTableStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...SourceColumnActions, ...SourceTableActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Import))
