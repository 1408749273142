import * as Api from 'redux/api/Applications/DataProducts/v3'
import * as Types from 'redux/Types'

import {
  messageSuccess,
  messageWarning,
  capitalizeFirstLetter
} from 'helpers/Functions'

export function tryGetDataProducts(type) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_DATA_PRODUCTS
    })

    Api.getAll(type)
      .then((response) => {
        dispatch({
          type: Types.GET_DATA_PRODUCTS_SUCCESS,
          payload: { data: response.data.data, type }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DATA_PRODUCTS_FAILED
        })
      })
  }
}

export function tryGetOneDataProduct(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_DATA_PRODUCT
    })

    Api.getOne(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DATA_PRODUCT_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DATA_PRODUCT_FAILED
        })
      })
  }
}

export function tryCreateDataproduct(body, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_DATA_PRODUCT
    })

    const typeTranslation =
      body.data.attributes.type === 'INSIGHT_STUDIO' ? 'dashboard' : 'delning'

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_DATA_PRODUCT_SUCCESS,
          payload: response.data.data
        })

        onSuccess(response.data.data.id)

        messageSuccess(`Ny ${typeTranslation} skapad`)
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DATA_PRODUCT_FAILED
        })

        messageWarning(`Det gick inte att skapa ${typeTranslation}`)
      })
  }
}

export function tryUpdateDataProduct(id, attributes) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_DATA_PRODUCT
    })

    const body = {
      data: {
        attributes
      }
    }

    const typeTranslation = window.location.pathname.includes('/insight-studio')
      ? 'dashboarden'
      : 'delning'

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DATA_PRODUCT_SUCCESS,
          payload: response.data.data
        })

        messageSuccess(
          `${capitalizeFirstLetter(typeTranslation)} har uppdaterats`
        )
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DATA_PRODUCT_FAILED
        })

        messageWarning(`Det gick inte att uppdatera ${typeTranslation}`)
      })
  }
}

export function tryDeleteDataProduct(id, type) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_DATA_PRODUCT
    })

    const typeTranslation =
      type === 'INSIGHT_STUDIO' ? 'dashboarden' : 'delning'

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_DATA_PRODUCT_SUCCESS,
          payload: id
        })

        messageSuccess(
          `${capitalizeFirstLetter(typeTranslation)} har tagits bort.`
        )
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DATA_PRODUCT_FAILED
        })

        messageWarning(`Det gick inte att ta bort ${typeTranslation}.`)
      })
  }
}

export function tryDownloadApiDocumentation(id, name) {
  return () => {
    Api.downloadApiDocumentation(id)
      .then((response) => {
        const fileName = `${name}_swagger.json`
        const data = JSON.stringify(response.data)
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', fileName) //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(() => {
        messageWarning('Det gick inte att ladda ner dokumentationen')
      })
  }
}

export function tryStartJobRun(id) {
  return (dispatch) => {
    dispatch({
      type: Types.START_JOB_RUN
    })

    Api.startJobRun(id)
      .then((response) => {
        dispatch({
          type: Types.START_JOB_RUN_SUCCESS,
          payload: { id, data: response.data.data }
        })

        messageSuccess('En import har startats.')
      })
      .catch(() => {
        dispatch({
          type: Types.START_JOB_RUN_FAILED
        })

        messageWarning('Det gick inte att starta en import.')
      })
  }
}

export function tryPollJobRun(id) {
  return (dispatch) => {
    dispatch({
      type: Types.POLL_JOB_RUN
    })

    Api.pollJobRun(id)
      .then((response) => {
        dispatch({
          type: Types.POLL_JOB_RUN_SUCCESS,
          payload: { id, data: response.data.data }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.POLL_JOB_RUN_FAILED
        })
      })
  }
}
