import React from 'react'

import { withRouter } from 'react-router-dom'
import { Empty } from 'antd'

import Modal from 'components/common/Modal'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

const GetStartedModal = ({ visible, onClose, description, history, match }) => (
  <Modal visible={visible} onClose={() => onClose()}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        textAlign: 'center',
        padding: 1
      }}
    >
      <Empty
        description={null}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        className={Styles['get-started-image']}
      />
      <Text variant="h4">Kom igång!</Text>
      <Box sx={{ textAlign: 'center' }}>{description}</Box>
      <Button
        onClick={() => {
          onClose()
          history.push(`${match.url}/new`)
        }}
        startIcon={<Icon name="AddCircleOutlineOutlined" />}
      >
        Lägg till dataset
      </Button>
      <Button onClick={onClose} variant="text">
        Inte nu
      </Button>
    </Box>
  </Modal>
)

export default withRouter(GetStartedModal)
