import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'

// This will only be used in this file, no need to store in Redux.
import * as ActivationApi from 'redux/api/internal/Activation'

import { ActivationDashboard } from 'types/GlobalTracking'
import DegreeOfActivationKpiBox from './DegreeOfActivationKpiBox'
import { calculateComboChart, calculateKpiBoxes } from './utils'
import DegreeOfActivationComboChart from './DegreeOfActivationComboChart'

const ActivationDashboardTemplate = () => {
  const [activationData, setActivationData] = useState<{
    loading: boolean
    data: ActivationDashboard | null
  }>({
    loading: true,
    data: null
  })

  useEffect(() => {
    ActivationApi.getActivationDashboard()
      .then((response: { data: ActivationDashboard }) =>
        setActivationData({ data: response.data, loading: false })
      )
      .catch(() => {})
  }, [])

  const kpiBoxData = calculateKpiBoxes(activationData.data)
  const comboChartData = calculateComboChart(activationData.data)

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'default.main'
      }}
    >
      <Grid container={true} spacing={2} sx={{ height: 240 }}>
        <Grid item={true} xs={4} sx={{ height: '100%' }}>
          <DegreeOfActivationKpiBox
            title={'Alla användare'}
            value={kpiBoxData.all.degreeOfActivation}
            totalUsers={kpiBoxData.all.total}
            activatedUsers={kpiBoxData.all.nrOfActivatedUsers}
            loading={activationData.loading}
          />
        </Grid>
        <Grid item={true} xs={4} sx={{ height: '100%' }}>
          <DegreeOfActivationKpiBox
            title={'Användare skapade innan sista Aug 2024'}
            value={kpiBoxData.beforeSep.degreeOfActivation}
            goal={52}
            totalUsers={kpiBoxData.beforeSep.total}
            activatedUsers={kpiBoxData.beforeSep.nrOfActivatedUsers}
            loading={activationData.loading}
          />
        </Grid>
        <Grid item={true} xs={4} sx={{ height: '100%' }}>
          <DegreeOfActivationKpiBox
            title={'Användare skapade efter sista Aug 2024'}
            value={kpiBoxData.afterSep.degreeOfActivation}
            goal={72}
            totalUsers={kpiBoxData.afterSep.total}
            activatedUsers={kpiBoxData.afterSep.nrOfActivatedUsers}
            loading={activationData.loading}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} sx={{ flex: 1 }}>
        <Grid item={true} xs={12} sx={{ height: '100%', pb: 2 }}>
          <DegreeOfActivationComboChart
            loading={activationData.loading}
            total={comboChartData.total}
            nrOfActivatedUsers={comboChartData.nrOfActivatedUsers}
            degreeOfActivation={comboChartData.degreeOfActivation}
            labels={comboChartData.labels}
            title={
              'Aktiveringsgrad (%) per månad som en användare blev inbjuden'
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivationDashboardTemplate
