import React from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

const AuthHero = (props: { title: string; subtitle: string }) => {
  const { title, subtitle } = props

  return (
    <Box sx={{ mb: 4 }}>
      <Text
        gutterBottom={true}
        variant="h2"
        align="center"
        sx={{
          fontFamily: 'DM Sans',
          lineHeight: 1.1,
          letterSpacing: '-0.05em'
        }}
      >
        {title}
      </Text>
      <Text variant="subtitle2" align="center">
        {subtitle}
      </Text>
    </Box>
  )
}

export default AuthHero
