import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import DataQualityTemplate from 'components_new/templates/internal/DataQuality'

import * as OurCustomerActions from 'redux/actions/internal/OurCustomers'
import * as SimFlawActions from 'redux/actions/internal/SimFlaws'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  CustomersAndLicensesStore: state.CustomersAndLicensesStore,
  InternalSimFlawStore: state.InternalSimFlawStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    { ...OurCustomerActions, ...SimFlawActions },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type DataQualityProps = ConnectedProps<typeof connector>

const DataQuality = (props: DataQualityProps) => {
  const { CustomersAndLicensesStore, InternalSimFlawStore } = props

  useEffect(() => {
    if (!CustomersAndLicensesStore.customersFetched) {
      props.tryGetCustomers()
    }

    if (!InternalSimFlawStore.settingsFetched) {
      props.tryGetFlawSettings()
    }
  }, [])

  return (
    <InternalTemplate>
      <DataQualityTemplate
        customers={CustomersAndLicensesStore.customers}
        data={InternalSimFlawStore.data}
        fetchFlaws={(customer, tables, offset, flawType) =>
          props.tryGetSimFlaws(customer, tables, offset, flawType)
        }
        fetching={InternalSimFlawStore.dataFetching}
        settings={InternalSimFlawStore.settings}
        totalCount={InternalSimFlawStore.totalCount}
      />
    </InternalTemplate>
  )
}

export default connector(DataQuality)
