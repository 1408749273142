import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { translateWidgetType } from 'utils/enumTranslator'

import {
  htmlLegendPlugin,
  horizontalLinePlugin,
  verticalLinePlugin,
  statusAreaPlugin,
  getLegendBoxSize,
  getLegendFontSize
} from '../utils'

import Box from 'components_new/atoms/Box'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Divider from 'components_new/atoms/Divider'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

import NoValue from '../../errors/NoValue'

import * as BubbleChartUtils from './utils'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface BubbleChartProps {
  formattedData: FormattedWidgetData
  onClick?: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  scaleFactor: number
  widget: WidgetObject
}

const BubbleChart = (props: BubbleChartProps) => {
  const { formattedData, onClick = [], scaleFactor, widget } = props

  const chartRef = useRef()
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegmentLabels, setSelectedSegmentLabels] = useState<
    ParsedWidgetDataLabel[]
  >([])
  const open = Boolean(anchorPosition)
  const allowOnClick = onClick.length > 0

  const theme = useTheme()

  const handleClick = (
    event: PointerEvent,
    dataLabels: ParsedWidgetDataLabel[]
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegmentLabels(dataLabels)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegmentLabels([])
  }

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BUBBLE_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const bubbles = BubbleChartUtils.getBubbles(formattedData)

  const rAxis = formattedData.datasets.find((item) => item.index === 2)
  const rAxisUnit = rAxis?.unit || null
  const rAxisLabel = rAxis?.label || ''

  return (
    <>
      {rAxisLabel ? (
        <Box
          sx={{
            height: 32 * scaleFactor,
            overflowY: 'auto'
          }}
        >
          <RLabel
            scaleFactor={scaleFactor}
            title={rAxisLabel}
            unit={rAxisUnit}
          />
        </Box>
      ) : null}
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          type="bubble"
          ref={chartRef}
          data={bubbles}
          options={BubbleChartUtils.getOptions(
            allowOnClick,
            bubbles,
            formattedData,
            handleClick,
            theme,
            widget,
            scaleFactor
          )}
          plugins={[
            htmlLegendPlugin,
            horizontalLinePlugin,
            verticalLinePlugin,
            statusAreaPlugin
          ]}
        />
      </Box>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition
            ? { top: anchorPosition.top, left: anchorPosition.left }
            : undefined
        }
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {allowOnClick
          ? selectedSegmentLabels
            .sort((a, b) =>
              a.display_label.localeCompare(b.display_label, 'sv')
            )
            .map((selectedSegmentLabel, i) => {
              return (
                <Box key={i}>
                  {onClick.map((option, j) => {
                    return (
                      <MenuItem
                        key={j}
                        onClick={(event) => {
                          handleClose(event)

                          option.onClick([selectedSegmentLabel])
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                      >
                        <ListItemIcon>
                          <Icon name={option.iconName} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={option.title([selectedSegmentLabel])}
                        />
                      </MenuItem>
                    )
                  })}
                  {i < selectedSegmentLabels.length - 1 ? <Divider /> : null}
                </Box>
              )
            })
          : null}
      </Menu>
    </>
  )
}

const RLabel = (props: {
  scaleFactor: number
  title: string
  unit: string | null
}) => {
  const theme = useTheme()

  const boxSize = getLegendBoxSize(props.scaleFactor)
  const fontSize = getLegendFontSize(props.scaleFactor)

  // styled manually since this is not actually a legend
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ml: `${boxSize}px`
      }}
    >
      <Box
        sx={{
          height: boxSize,
          width: boxSize,
          bgcolor: theme.palette.primary.main,
          borderRadius: boxSize / 2,
          mr: `${boxSize / 2}px`
        }}
      />
      <Text
        sx={{
          fontSize: fontSize,
          color: theme.palette.text.secondary
        }}
      >
        {props.title}
        {props.unit ? ` (${props.unit})` : ''}
      </Text>
    </Box>
  )
}

export default BubbleChart
