import React, { ElementType, MouseEvent, ReactNode, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { Color, getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Menu from 'components_new/atoms/Menu/'
import Tooltip from 'components_new/atoms/Tooltip'

import ButtonField from 'components_new/molecules/ButtonField'
import NestedOptionsMenuItem from 'components_new/molecules/NestedOptionsMenuItem'
import OptionsMenuItem from 'components_new/molecules/OptionsMenuItem'

export interface OptionProps {
  disabled?: boolean
  divider?: boolean
  iconName: IconNames
  iconRotation?: string
  infoTip?: ReactNode
  onClick: () => void
  nestedOptions?: OptionProps[]
  title: string
  tooltip?: string
}

interface OptionsMenuProps {
  buttonComponent?: ElementType<any>
  ButtonProps?: object
  color?: Color | 'default' | 'disabled' | 'inherit'
  disabled?: boolean
  iconName?: IconNames
  MenuSx?: object
  options: OptionProps[]
  size?: 'small'
  sx?: object
  tooltip?: string
  variant?: 'button' | 'buttonField' | 'icon'
}

/**
 * The OptionMenu component is used for
 * displaying one or more options (Icon
 * + Text) in a list when clicking a
 * 'More' icon.
 */

const OptionsMenu = React.forwardRef((props: OptionsMenuProps, ref: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      {props.variant === 'icon' ? (
        <Tooltip title={props.tooltip ?? ''}>
          <IconButton
            color={props.color ?? 'inherit'}
            component={props.buttonComponent}
            disabled={props.disabled}
            onClick={handleClick}
            onMouseDown={(event) => event.stopPropagation()}
            ref={ref}
            size="small"
            sx={props.sx}
          >
            <Icon
              name={props.iconName ?? 'MoreVertOutlined'}
              sx={props.size === 'small' ? { fontSize: 12 } : undefined}
            />
          </IconButton>
        </Tooltip>
      ) : props.variant === 'button' ? (
        <Button
          {...props.ButtonProps}
          disabled={props.disabled}
          onClick={handleClick}
          onMouseDown={(event) => event.stopPropagation()}
          ref={ref}
          sx={props.sx}
        >
          {props.tooltip}
        </Button>
      ) : (
        <ButtonField
          onClick={handleClick}
          onMouseDown={(event) => event.stopPropagation()}
          sx={props.sx}
        >
          {props.tooltip}
        </ButtonField>
      )}
      <ThemeProvider theme={getTheme('light')}>
        <Menu
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          open={open}
          sx={props.MenuSx}
        >
          {props.options.map((option: OptionProps, i: number) => [
            option.nestedOptions ? (
              <NestedOptionsMenuItem
                closeParentMenu={() => {
                  setAnchorEl(null)
                }}
                divider={option.divider}
                icon={<Icon name={option.iconName} fontSize="small" />}
                key={i}
                parentMenuOpen={open}
                title={option.title}
                MenuSx={props.MenuSx}
              >
                {option.nestedOptions.map(
                  (nestedOption: OptionProps, i: number) => [
                    <OptionsMenuItem
                      iconName={nestedOption.iconName}
                      iconRotation={nestedOption.iconRotation}
                      infoTip={nestedOption.infoTip}
                      key={`nested-${i}`}
                      onClick={(event) => {
                        handleClose(event)
                        nestedOption.onClick()
                      }}
                      title={nestedOption.title}
                    />,
                    nestedOption.divider ? (
                      <Divider key={`divider-${i}`} />
                    ) : null
                  ]
                )}
              </NestedOptionsMenuItem>
            ) : (
              <OptionsMenuItem
                disabled={option.disabled}
                iconName={option.iconName}
                iconRotation={option.iconRotation}
                infoTip={option.infoTip}
                key={i}
                onClick={(event) => {
                  handleClose(event)
                  option.onClick()
                }}
                title={option.title}
                tooltip={option.tooltip}
              />
            ),
            option.divider ? <Divider /> : null
          ])}
        </Menu>
      </ThemeProvider>
    </>
  )
})

OptionsMenu.defaultProps = {
  variant: 'icon'
}

OptionsMenu.displayName = 'OptionsMenu'
export default OptionsMenu
