import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import PageHeader from 'components/common/PageHeader'

import Pagination from 'components/common/Pagination'
import Table from 'components/common/Table'

import * as SourceActions from 'redux/actions/DataPlatform/connections/Sources'
import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Sources = ({ SourceStore, history }) => {
  const [toggle, setToggle] = useState(Conf.TOGGLE_ITEMS[0])
  const [page, setPage] = useState(1)
  const [rows, count] = Conf.getRows(page, toggle, SourceStore)
  const type = Conf.getType()
  const textType = Conf.getTextType(type)

  return (
    <Paper sx={{ p: 2 }}>
      <PageHeader
        header={textType.plural}
        actions={
          type !== 'OnPremise' && type ? (
            <Button
              onClick={() =>
                history.push(`/data-platform/collect/${textType.path}/create`)
              }
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till {textType.singular.toLowerCase()}
            </Button>
          ) : null
        }
        className={Styles['page-header']}
      />
      <Table
        rows={rows}
        headers={
          type === 'Stream' ? Conf.SOURCE_HEADERS_STREAM : Conf.SOURCE_HEADERS
        }
        onClick={(id, record) =>
          history.push(
            `/data-platform/collect/${
              Conf.getTextType(record.type).path
            }/${id}/data`
          )
        }
        toggle={{
          items: Conf.TOGGLE_ITEMS,
          onClick: (item) => setToggle(item),
          selected: toggle
        }}
      />
      <Pagination
        perPage={Conf.PAGE_LIMIT}
        text={textType.plural.toLowerCase()}
        onChange={(page) => setPage(page)}
        totalItems={count}
      />
    </Paper>
  )
}

function mapStateToProps({ SourceStore }) {
  return {
    SourceStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SourceActions,
      ...ActiveSourceActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sources))
