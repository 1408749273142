import * as Api from 'redux/api/DataPlatform/cmdb/tables/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetCmdbTables() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_CMDB_TABLES
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_CMDB_TABLES_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_CMDB_TABLES_FAILED
        })
      })
  }
}

export function tryCreateCmdbTable(body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_CMDB_TABLE
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_CMDB_TABLE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Ett nytt register har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_CMDB_TABLE_FAILED
        })
        messageWarning('Registret kunde inte skapas')
      })
  }
}

export function tryDeleteCmdbTable(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_CMDB_TABLE
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_CMDB_TABLE_SUCCESS,
          payload: id
        })

        messageSuccess('Registret har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_CMDB_TABLE_FAILED
        })

        messageWarning('Registret kunde inte tas bort')
      })
  }
}

export function tryUpdateCmdbTable(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_CMDB_TABLE
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_CMDB_TABLE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Registret har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_CMDB_TABLE_FAILED
        })
        messageWarning('Registret kunde inte uppdateras')
      })
  }
}

export function tryUpdateCmdbTableClassification(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_CMDB_TABLE
    })

    Api.updateClassification(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_CMDB_TABLE_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Registret har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_CMDB_TABLE_FAILED
        })
        messageWarning('Registret kunde inte uppdateras')
      })
  }
}
