import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import { getBody, getTitle } from './utils'

interface ErrorPlaceholderProps {
  actions?: ReactNode
  code: 401 | 404 | 500
  sx?: object
}

/**
 * The ErrorPlaceholder component is used
 * to show an error code together with
 * information when something has gone wrong.
 *
 * It expands, and fills up, all available
 * space.
 */

const ErrorPlaceholder = React.forwardRef(
  (props: ErrorPlaceholderProps, ref: any) => {
    const { actions, code, sx } = props

    return (
      <Box
        ref={ref}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...sx
        }}
      >
        <Stack alignItems="center" direction="column" sx={{ gap: 2 }}>
          <Text color="primary" variant="h2">
            {code}
          </Text>
          <Text color="text.disabled" variant="h5">
            {getTitle[code]}
          </Text>
          <Text color="text.disabled" variant="body1">
            {getBody[code]}
          </Text>
          {actions ? (
            <Stack alignItems="center" sx={{ p: 1, gap: 1 }}>
              {actions}
            </Stack>
          ) : null}
        </Stack>
      </Box>
    )
  }
)

ErrorPlaceholder.displayName = 'ErrorPlaceholder'
export default ErrorPlaceholder
