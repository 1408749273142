import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PageMenu from 'components/common/PageMenu'

import NewSource from 'components/containers/DataPlatform/collect/New'
import NewFile from 'components/containers/DataPlatform/collect/New/NewFile'

import * as SourceActions from 'redux/actions/DataPlatform/connections/Sources'
import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'
import * as TagActions from 'redux/actions/DataPlatform/Tags'
import * as NavigationActions from 'redux/actions/Navigation'

import * as Conf from './Conf'
import InternalPage from 'components_new/templates/InternalTemplate'

class Overview extends Component {
  componentDidMount() {
    const { SourceStore, AuthStore, TagStore, setNavigation, history } =
      this.props

    setNavigation({ paths: [], labels: [] })

    if (!AuthStore.user.is_homepal_user) {
      history.replace('/')
    }

    if (!SourceStore.fetched) {
      this.props.tryGetAllSources()
    }

    if (!TagStore.fetched) {
      this.props.tryGetAllTags()
    }
  }

  findActiveSource() {
    const { SourceStore, match } = this.props
    const sources = Object.values(SourceStore.data)

    for (let i = 0; i < sources.length; i++) {
      if (sources[i].included && sources[i].included.active_sources) {
        const activeSource = sources[i].included.active_sources.find(
          (item) => item.id === match.params.id
        )

        if (activeSource) {
          return { source: sources[i], activeSource }
        }
      }
    }

    return { source: null, activeSource: null }
  }

  render() {
    const { SourceStore, match } = this.props

    if (!SourceStore.fetched) {
      return null
    }

    const { source, activeSource } = this.findActiveSource()

    return (
      <InternalPage
        AuthStore={this.props.AuthStore}
        NavigationMenuStore={this.props.NavigationMenuStore}
      >
        <PageMenu
          page={'collect'}
          items={Conf.PAGE_MENU_ITEMS(match, activeSource, source)}
        />

        <NewSource />
        <NewFile />
      </InternalPage>
    )
  }
}

function mapStateToProps({
  SourceStore,
  AuthStore,
  TagStore,
  NavigationMenuStore
}) {
  return {
    SourceStore,
    AuthStore,
    TagStore,
    NavigationMenuStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SourceActions,
      ...ActiveSourceActions,
      ...TagActions,
      ...NavigationActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview))
