import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, destroy, update } from 'redux/api/WidgetTags'

import { DashboardType } from 'redux/reducers/Dashboards'

import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'

export function tryCreateWidgetTag(
  body: WidgetTagPostAttributes,
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.CREATE_WIDGET_TAG
  let successAction = Types.CREATE_WIDGET_TAG_SUCCESS
  let failedAction = Types.CREATE_WIDGET_TAG_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.CREATE_WIDGET_TAG_TEMPLATE
    successAction = Types.CREATE_WIDGET_TAG_TEMPLATE_SUCCESS
    failedAction = Types.CREATE_WIDGET_TAG_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    create(body)
      .then((response) => {
        dispatch({
          type: successAction,
          payload: {
            dashboardId: dashboardId,
            tag: response.data.data
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har lagts till' }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte läggas till' }
        })
      })
  }
}

export function tryUpdateWidgetTag(
  id: string,
  body: WidgetTagPatchAttributes,
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.UPDATE_WIDGET_TAG
  let successAction = Types.UPDATE_WIDGET_TAG_SUCCESS
  let failedAction = Types.UPDATE_WIDGET_TAG_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.UPDATE_WIDGET_TAG_TEMPLATE
    successAction = Types.UPDATE_WIDGET_TAG_TEMPLATE_SUCCESS
    failedAction = Types.UPDATE_WIDGET_TAG_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: successAction,
          payload: {
            dashboardId: dashboardId,
            tag: response.data.data,
            skipFetchData: false
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteWidgetTag(
  id: string,
  widgetId: string,
  dashboardId: string,
  type: DashboardType = DashboardType.DEFAULT
) {
  let action = Types.DELETE_WIDGET_TAG
  let successAction = Types.DELETE_WIDGET_TAG_SUCCESS
  let failedAction = Types.DELETE_WIDGET_TAG_FAILED

  switch (type) {
  case DashboardType.TEMPLATE:
    action = Types.DELETE_WIDGET_TAG_TEMPLATE
    successAction = Types.DELETE_WIDGET_TAG_TEMPLATE_SUCCESS
    failedAction = Types.DELETE_WIDGET_TAG_TEMPLATE_FAILED
    break
  }

  return (dispatch: Dispatch) => {
    dispatch({
      type: action
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: successAction,
          payload: {
            dashboardId: dashboardId,
            id,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: failedAction
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte tas bort' }
        })
      })
  }
}
