import {
  ActivationDashboard,
  ActivationDashboardAccount
} from 'types/GlobalTracking'

const EMPTY = {
  total: 0,
  nrOfActivatedUsers: 0,
  nrOfNotActivatedUsers: 0,
  degreeOfActivation: 0
}

export function calculateComboChart(input: ActivationDashboard | null) {
  const labels: string[] = []
  const degreeOfActivation: (number | string)[] = []
  const total: (number | string)[] = []
  const nrOfActivatedUsers: (number | string)[] = []

  if (!input) {
    return { labels, degreeOfActivation, total, nrOfActivatedUsers }
  }

  input.activation_over_time.forEach((item) => {
    const calculations = calculate(item.accounts)

    labels.push(item.label)
    degreeOfActivation.push(calculations.degreeOfActivation)
    total.push(calculations.total)
    nrOfActivatedUsers.push(calculations.nrOfActivatedUsers)
  })

  return { labels, degreeOfActivation, total, nrOfActivatedUsers }
}

export function calculateKpiBoxes(data: ActivationDashboard | null) {
  if (!data) {
    return { all: EMPTY, beforeSep: EMPTY, afterSep: EMPTY }
  }

  const all = data.accounts

  return {
    all: calculate(all),
    beforeSep: calculate(
      all.filter((account) => account.created_before_end_of_aug)
    ),
    afterSep: calculate(
      all.filter((account) => !account.created_before_end_of_aug)
    )
  }
}

function calculate(accounts: ActivationDashboardAccount[]) {
  const total = accounts.length
  const nrOfActivatedUsers = accounts.filter(
    (account) => account.is_activated
  ).length

  return {
    total,
    nrOfActivatedUsers,
    degreeOfActivation:
      total === 0
        ? 0
        : parseInt(((nrOfActivatedUsers / total) * 100).toFixed(0))
  }
}
