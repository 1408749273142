export const TREE_DATA = (json) => {
  if (json.length === 0) {
    return []
  }

  const keys = Object.keys(json[0])

  return [
    {
      title: 'body',
      key: 'body',
      children: keys.map((key, i) => ({
        title: key,
        key: `${key.toLowerCase()}-${i}`
      }))
    }
  ]
}

export const INPUT_ATTRIBUTES = (json) => {
  if (json.length === 0) {
    return []
  }

  const keys = Object.keys(json[0])

  return keys.map((key) => ({
    label: key,
    value: key
  }))
}

export const CONDITIONS = [
  { label: 'innehåller', value: 'includes' },
  { label: 'är lika med', value: 'equals' }
]
