import React, { ReactNode, useMemo } from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Tab from 'components_new/atoms/Tab'
import Tabs from 'components_new/atoms/Tabs'
import Tooltip from 'components_new/atoms/Tooltip'

import OptionsMenu, { OptionProps } from 'components_new/molecules/OptionsMenu'

interface TabSwitchProps {
  color?: 'primary' | 'secondary' | 'brand'
  maxHeight?: string | number
  noGutter?: boolean
  onChange: (value: any) => void
  orientation?: 'horizontal' | 'vertical'
  options: {
    disabled?: boolean
    disabledTooltip?: string
    label: string | ReactNode
    menuOptions?: OptionProps[]
    number?: number
    value: any
  }[]
  variant?: 'scrollable' | 'fullWidth' | 'standard'
  value?: any
  sx?: object
}

const TabSwitch = React.forwardRef((props: TabSwitchProps, ref: any) => {
  const {
    color,
    onChange,
    maxHeight,
    noGutter,
    orientation,
    options,
    value,
    variant = 'scrollable',
    sx,
    ...rest
  } = props

  const selectedValue = useMemo(() => {
    if (value) {
      const chosen = options.findIndex((option) => option.value === props.value)

      if (chosen !== -1) {
        return chosen
      }
    }

    return 0 // Defaults to first tab.
  }, [value, options])

  return (
    <Box ref={ref} sx={{ minWidth: 0, ...sx }}>
      <Tabs
        {...rest}
        color={color}
        value={selectedValue}
        onChange={(event, value) => onChange(props.options[value].value)}
        orientation={orientation}
        variant={variant}
        scrollButtons={true}
        allowScrollButtonsMobile={true}
        TabScrollButtonProps={{
          sx: {
            color: 'text.secondary',
            '&.Mui-disabled': {
              width: 0,
              height: 0
            },
            overflow: 'hidden',
            transition: 'width 0.5s',
            width: 28
          }
        }}
        sx={{
          minHeight: noGutter ? 0 : undefined,
          maxHeight: maxHeight ?? undefined
        }}
      >
        {options.map((option) => (
          <Tab
            disabled={option.disabled}
            icon={
              option.number != undefined ? (
                <Chip
                  color={value === option.value ? color : 'default'}
                  disabled={value !== option.value}
                  label={option.number}
                  size="small"
                />
              ) : option.menuOptions ? (
                <OptionsMenu
                  buttonComponent="div"
                  disabled={option.disabled}
                  iconName="ExpandMore"
                  options={option.menuOptions}
                  size="small"
                  sx={{ ml: 0.5 }}
                />
              ) : undefined
            }
            iconPosition="end"
            label={
              option.disabled && option.disabledTooltip ? (
                <Tooltip title={option.disabledTooltip}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {option.label}
                  </Box>
                </Tooltip>
              ) : (
                option.label
              )
            }
            key={option.value}
            sx={{
              minHeight: noGutter || orientation === 'vertical' ? 0 : undefined,
              maxHeight: maxHeight ?? undefined,
              pointerEvents:
                option.disabled && option.disabledTooltip
                  ? 'all!important'
                  : undefined,
              textAlign: orientation === 'vertical' ? 'left' : undefined,
              justifyContent:
                orientation === 'vertical' ? 'space-between' : undefined
            }}
          />
        ))}
      </Tabs>
    </Box>
  )
})

TabSwitch.displayName = 'TabSwitch'
export default TabSwitch
