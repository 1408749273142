import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const my_homepal_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/cmdb/apis`

export function getAll(token) {
  return Axios.get(URL, Functions.parseAuthenticationHeader(token))
}
