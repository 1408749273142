import React, { useMemo, useState } from 'react'

import { cloneDeep } from 'lodash'

import { KpiStatus, KpiTemplate } from 'types/GlobalKpiTemplates'

import { sortAlphabeticalAsc } from 'utils/sortHelper'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'

import Status from '../Status'
import SubKpisDropdown from './SubKpisDropdown'
import Title from './Title'
import Actions from './Actions'
import SubKpisDialog from './SubKpisDialog'

interface KpiRowProps {
  hideCategory?: boolean
  isSubItem?: boolean
  kpi: KpiTemplate
  parentTitle?: string
  onCreate: (kpiId: string | null, performKpiId?: string) => void
  setShowDetails?: (kpi: KpiTemplate) => void
  query: string
}

const KpiRow = (props: KpiRowProps) => {
  const { isSubItem, kpi, onCreate, parentTitle, setShowDetails, query } = props

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [subKpisDialogOpen, setSubKpisDialogOpen] = useState<boolean>(false)

  const status = kpi.status as KpiStatus

  const disabled =
    status &&
    (!status.modelled ||
      !status.available_in_license ||
      status.required_target_missing)

  const columns = 5

  const subKpis = useMemo(() => {
    return cloneDeep(kpi.sub_kpis).sort((a, b) =>
      sortAlphabeticalAsc(a.title, b.title)
    )
  }, [kpi.sub_kpis])

  return (
    <>
      <TableRow
        hover={true}
        key={kpi.id}
        sx={{
          bgcolor: disabled ? 'action.hover' : undefined
        }}
      >
        {!isSubItem ? (
          <TableCell sx={{ width: 42, maxWidth: 42, pr: 0, pl: 1 }}>
            {kpi.sub_kpis.length > 0 ? (
              <IconButton
                size="small"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {dropdownOpen ? (
                  <Icon name="KeyboardArrowUp" />
                ) : (
                  <Icon name="KeyboardArrowDown" />
                )}
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}

        {/*-- Title --*/}
        <TableCell align="left">
          <Title
            disabled={disabled}
            overline={parentTitle}
            query={query}
            title={kpi.title}
          />
        </TableCell>

        {/*-- Status --*/}
        <TableCell
          align="right"
          sx={{
            width: 186,
            maxWidth: 186
          }}
        >
          <Status kpi={kpi} variant="short" />
        </TableCell>

        {/*-- Actions --*/}
        <TableCell
          align="right"
          sx={{
            width: isSubItem ? 100 : 128,
            maxWidth: isSubItem ? 100 : 128
          }}
        >
          <Actions
            disabled={disabled}
            isSubItem={isSubItem}
            onCreate={() => {
              if (kpi.sub_kpis.length > 0) {
                setSubKpisDialogOpen(true)
              } else {
                onCreate(kpi.id, undefined)
              }
            }}
            onShowDetails={() => {
              if (setShowDetails) {
                setShowDetails(kpi)
              }
            }}
          />
        </TableCell>
      </TableRow>

      {/*-- Dropdown --*/}
      {subKpis.length > 0 ? (
        <SubKpisDropdown
          columns={columns}
          disabled={disabled}
          kpiTitle={kpi.title}
          onCreate={(performKpiId) => onCreate(kpi.id, performKpiId)}
          open={dropdownOpen}
          subKpis={subKpis}
        />
      ) : null}

      {/*-- Dialog --*/}
      {subKpis.length > 0 ? (
        <SubKpisDialog
          kpiTitle={kpi.title}
          onClose={() => setSubKpisDialogOpen(false)}
          onCreate={(performKpiId) => {
            onCreate(kpi.id, performKpiId)
            setSubKpisDialogOpen(false)
          }}
          open={subKpisDialogOpen}
          subKpis={subKpis}
        />
      ) : null}
    </>
  )
}

export default KpiRow
