import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { cloneDeep } from 'lodash'

import { arrayToObject } from 'helpers/Functions'
import { EconomyAccount, EconomySet } from 'types/GlobalEconomySets'

export type EconomySetBody = {
  [key: string]: EconomySet
}
export interface EconomySetReducerType {
  data: EconomySetBody
  fetched: boolean
  fetching: boolean
}

// Initial state
const INITIAL_STATE: EconomySetReducerType = {
  data: {},
  fetched: false,
  fetching: false
}

// Actions
const startGetAllAction = createAction(Types.GET_ALL_ECONOMY_SETS)
const getAllAction = createAction<{ data: EconomySet[] }>(
  Types.GET_ALL_ECONOMY_SETS_SUCCESS
)
const getAllFailedAction = createAction(Types.GET_ALL_ECONOMY_SETS_FAILED)
const createSetAction = createAction<{ data: EconomySet }>(
  Types.CREATE_ECONOMY_SET_SUCCESS
)
const updateAction = createAction<{ data: EconomySet }>(
  Types.UPDATE_ECONOMY_SET_SUCCESS
)
const deleteAction = createAction<{ id: string }>(
  Types.DELETE_ECONOMY_SET_SUCCESS
)
const putSetAction = createAction<{ data: EconomySet }>(
  Types.UPLOAD_ECONOMY_SET_SUCCESS
)

const createAccountAction = createAction<{
  data: EconomyAccount
  economySetId: string
}>(Types.CREATE_ECONOMY_ACCOUNT_SUCCESS)
const updateAccountAction = createAction<{
  data: EconomyAccount
  economySetId: string
}>(Types.UPDATE_ECONOMY_ACCOUNT_SUCCESS)
const deleteAccountAction = createAction<{ id: string; economySetId: string }>(
  Types.DELETE_ECONOMY_ACCOUNT_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

const setNewEconomySet = (
  state: EconomySetReducerType,
  action: {
    payload: {
      data: EconomySet
    }
    type: string
  }
) => {
  const { payload } = action

  return {
    ...state,
    data: {
      ...state.data,
      [payload.data.id]: payload.data
    }
  }
}

// Reducer
const economySetReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(startGetAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as EconomySetBody,
        fetched: true,
        fetching: false
      }
    })
    .addCase(getAllFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(createSetAction, setNewEconomySet)
    .addCase(updateAction, setNewEconomySet)
    .addCase(putSetAction, setNewEconomySet)
    .addCase(deleteAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      delete newData[payload.id]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(createAccountAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.economySetId]: {
            ...state.data[payload.economySetId],
            accounts: [
              ...state.data[payload.economySetId].accounts,
              payload.data
            ]
          }
        }
      }
    })
    .addCase(updateAccountAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.economySetId]: {
            ...state.data[payload.economySetId],
            accounts: state.data[payload.economySetId].accounts.map((acc) =>
              acc.id === payload.data.id ? payload.data : acc
            )
          }
        }
      }
    })
    .addCase(deleteAccountAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.economySetId]: {
            ...state.data[payload.economySetId],
            accounts: state.data[payload.economySetId].accounts.filter(
              (acc) => acc.id !== payload.id
            )
          }
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default economySetReducer
