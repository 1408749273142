import React from 'react'

import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import { KpiVariable } from 'redux/reducers/KpiVariables'
import { KpiVariableOption } from 'types/GlobalCompanyGroups'

import Row from './Row'
import { getAllAccountNumbers } from './utils'
import { EconomyAccountMap } from '../account_dialogs/utils'

interface VariablesAccountsSectionProps {
  accountsMap: EconomyAccountMap
  allVariables: KpiVariable[]
  economySetId: string
  editVariable: {
    economySetId: string
    variableId: string
  } | null
  kpiVariableOptions: KpiVariableOption[]
  level: number
  limitToAccounts?: string[]
  onUpdateVariableAccounts: (
    accounts: string[],
    economySetId: string,
    variableId: string,
    callback: (err?: boolean) => void
  ) => void
  setEditVariable: (
    body: {
      economySetId: string
      variableId: string
      selectedAccounts: string[]
      limitToAccounts?: string[]
    } | null
  ) => void
  variables: KpiVariable[]
}

const VariablesAccountsSection = (props: VariablesAccountsSectionProps) => {
  const {
    accountsMap,
    allVariables,
    economySetId,
    editVariable,
    kpiVariableOptions,
    level,
    limitToAccounts,
    onUpdateVariableAccounts,
    setEditVariable,
    variables
  } = props

  return (
    <>
      <TableContainer>
        <Table size="small">
          {level === 0 ? (
            <TableHead sx={{ color: 'text.secondary' }}>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Kontoreferenser</TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {variables
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((variable) => (
                <Row
                  accountsMap={accountsMap}
                  allVariables={allVariables}
                  economySetId={economySetId}
                  editVariable={editVariable}
                  kpiVariableOptions={kpiVariableOptions}
                  level={variable.level}
                  onEdit={(setId) => {
                    const economySetOptions = kpiVariableOptions.filter(
                      (option) => option.economy_set_id === setId
                    )

                    const selectedAccounts =
                      economySetOptions.find(
                        (option) => option.kpi_variable_id === variable.id
                      )?.selected || []

                    // Get all accounts being used in the
                    // same level except current variable.
                    const accountsOnSameLevel = economySetOptions
                      .filter(
                        (option) =>
                          option.kpi_variable_id !== variable.id &&
                          // Find all accounts with same parent
                          // or all without parent (top level)
                          (variable.parent_kpi_variable_id
                            ? option.parent_kpi_variable_id ===
                              variable.parent_kpi_variable_id
                            : !option.parent_kpi_variable_id)
                      )
                      .map((option) => option.selected)
                      .flat()

                    // Remove accounts that are used on different
                    // variable hierarchies.
                    const availableAccounts = (
                      limitToAccounts ?? getAllAccountNumbers()
                    ).filter((acc) => !accountsOnSameLevel.includes(acc))

                    setEditVariable({
                      economySetId,
                      variableId: variable.id,
                      selectedAccounts: selectedAccounts,
                      limitToAccounts: [
                        ...availableAccounts,
                        ...selectedAccounts
                      ]
                    })
                  }}
                  onUpdateVariableAccounts={onUpdateVariableAccounts}
                  setEditVariable={setEditVariable}
                  variable={variable}
                  key={variable.id}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default VariablesAccountsSection
