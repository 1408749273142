import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PageHeader from 'components/common/PageHeader/v2'

import Box from 'components_new/atoms/Box'

import DatasetModal from 'components/containers/DataPlatform/shape/DatasetModal'

import * as DatasetActions from 'redux/actions/DataPlatform/shapes/v2/Datasets'
import * as AlertActions from 'redux/actions/Alert'
import * as NavigationActions from 'redux/actions/Navigation'
import * as DataProductActions from 'redux/actions/DataPlatform/DataProducts'

import * as Conf from './conf.js'

const Wrapper = ({
  history,
  setAlert,
  resetAlert,
  tryDeleteDataset,
  AuthStore,
  DatasetStore,
  match,
  tryGetOneDataset,
  setNavigation,
  location,
  DataProductStore,
  tryGetOntology
}) => {
  const [showDatasetModal, setShowDatasetModal] = useState(false)

  const table = DatasetStore.data[match.params.tableId]

  const user = table && table.included ? table.included.user : null

  const splitPath = location.pathname.split('/')
  const activeToggle = splitPath[splitPath.length - 1]

  useEffect(() => {
    if (DatasetStore.currentFetchOne !== match.params.tableId) {
      tryGetOneDataset(match.params.tableId)
    }

    // Fetch standard ontology to make sure the current table is not connected
    // to the standard model
    if (!DataProductStore.fetchedStandardOntology) {
      tryGetOntology('standard')
    }
  }, [])

  useEffect(() => {
    if (table) {
      setNavigation({
        paths: [
          '/data-platform/ontology',
          `/data-platform/ontology/datasets/${table.id}/transformation`
        ],
        labels: ['Informationsmodell', table.attributes.name]
      })
    }
  }, [table])

  const isStandardTable =
    DataProductStore.standardOntology?.id === table?.attributes.data_product_id

  return table ? (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <PageHeader
          header={table.attributes.name}
          meta={Conf.getMetaInformation(table, user)}
          toggleItems={Conf.PAGE_HEADER_TOGGLE(
            history,
            table.id,
            AuthStore.user.is_homepal_user
          )}
          activeToggle={activeToggle}
          actions={Conf.getActions(
            AuthStore.user.is_homepal_user && !isStandardTable,
            table,
            () => setShowDatasetModal(true),
            () => tryDeleteDataset(match.params.tableId),
            (alertInformation) => setAlert(alertInformation),
            () => resetAlert(),
            history
          )}
        />
        <Box sx={{ flex: 1 }}>{Conf.PARSE_CONTENT(activeToggle)}</Box>
      </Box>
      <DatasetModal
        visible={showDatasetModal}
        onClose={() => setShowDatasetModal(false)}
        initialValues={{
          id: table.id,
          name: table.attributes.name,
          plural: table.attributes.plural,
          technicalName: table.attributes.technical_name,
          isOntology: table.attributes.is_ontology
        }}
      />
    </>
  ) : null
}

function mapStateToProps({ AuthStore, DatasetStore, DataProductStore }) {
  return {
    AuthStore,
    DatasetStore,
    DataProductStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertActions,
      ...DatasetActions,
      ...NavigationActions,
      ...DataProductActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wrapper))
