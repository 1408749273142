import React, { useEffect, useMemo } from 'react'

import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form } from 'antd'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import Modal from 'components/common/Modal'
import Input from 'components/common/Input'

import * as DatasetActions from 'redux/actions/DataPlatform/shapes/v2/Datasets'

import * as Constants from 'helpers/Constants'

import Styles from './styles.module.css'

const DatasetModal = ({
  visible,
  onClose,
  tryCreateDataset,
  tryUpdateDataset,
  DatasetStore,
  initialValues = {
    id: undefined,
    name: undefined,
    technicalName: undefined,
    plural: undefined,
    isOntology: true
  },
  withRedux = true,
  // params only used without redux (ontology board)
  onFinish,
  datasets
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues)
    }
  }, [visible])

  const edit = !!initialValues.id
  const header = edit ? 'Redigera entitet' : 'Lägg till entitet'
  const save = edit ? 'Spara' : 'Lägg till'

  const rows = useMemo(() => {
    if (withRedux) {
      return Object.values(DatasetStore.data).map((item) => ({
        id: item.id,
        name: item.attributes.name,
        plural: item.attributes.plural,
        technicalName: item.attributes.technical_name,
        isOntology: item.attributes.is_ontology
      }))
    } else {
      return datasets
    }
  }, [withRedux, datasets])

  return (
    <Modal
      visible={visible}
      header={header}
      onClose={onClose}
      size={'small'}
      actions={
        <>
          <Button onClick={() => onClose()} variant="text">
            Avbryt
          </Button>
          <Button onClick={form.submit}>{save}</Button>
        </>
      }
    >
      <Form
        layout={'vertical'}
        initialValues={initialValues}
        form={form}
        onFinish={(values) => {
          if (withRedux) {
            const data = {
              attributes: {
                name: values.name,
                plural: values.plural,
                technical_name: values.technicalName,
                is_ontology: initialValues.isOntology
              }
            }

            if (edit) {
              //update dataset
              tryUpdateDataset(initialValues.id, { data })
            } else {
              // create dataset with redux
              tryCreateDataset({ data })
            }
          } else {
            // create dataset with edit mode
            onFinish(values)
          }

          onClose()
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Form.Item
            label={<FormItemLabel label={'Namn'} secondary={'Singular'} />}
            name={'name'}
            className={Styles['form-item']}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              validate(
                rows,
                'name',
                'Ett dataset med det här namnet existerar redan.',
                initialValues
              )
            ]}
          >
            <Input />
          </Form.Item>
          <Text color="text.secondary" variant="caption">
            Ett läsbart namn i singular. Exempel: Fastighet.
          </Text>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Form.Item
            label={<FormItemLabel label={'Namn'} secondary={'Plural'} />}
            name={'plural'}
            className={Styles['form-item']}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              validate(
                rows,
                'plural',
                'Ett dataset med det här namnet existerar redan.',
                initialValues
              )
            ]}
          >
            <Input />
          </Form.Item>
          <Text color="text.secondary" variant="caption">
            Ett läsbart namn i plural. Exempel: Fastigheter.
          </Text>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Form.Item
            label={'Entitet ID'}
            name={'technicalName'}
            className={Styles['form-item']}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              validate(
                rows,
                'technicalName',
                'Ett dataset med det här entitets id:t existerar redan.',
                initialValues
              )
            ]}
          >
            <Input
              addonAfter={
                edit ? <Icon name="LockOutlined" sx={{ ml: 0.5 }} /> : null
              }
              disabled={edit}
            />
          </Form.Item>
          <Text color="text.secondary" variant="caption">
            En teknisk identifierare. Bör vara på engelska och i plural.
          </Text>
        </Box>
      </Form>
    </Modal>
  )
}

const FormItemLabel = ({ label, secondary }) => (
  <Text variant="body1">
    {label}
    {secondary ? ` (${secondary})` : null}
  </Text>
)

const validate = (data, key, message, initialValues) => ({
  validator(_, value) {
    if (!value) {
      return Promise.resolve()
    }

    const exists = data.find(
      (item) =>
        item[key]?.toLowerCase() === value?.toLowerCase() &&
        initialValues.isOntology === item.isOntology &&
        (initialValues.id ? item.id !== initialValues.id : true)
    )

    if (exists) {
      return Promise.reject(message)
    }

    return Promise.resolve()
  }
})

function mapStateToProps({ DatasetStore }) {
  return {
    DatasetStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(DatasetActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetModal))
