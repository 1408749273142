import { createAction, createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { arrayToObject } from 'helpers/Functions'
import * as Types from 'redux/Types'

export type KpiVariable = {
  id: string
  name: string
  level: number
  parent_kpi_variable_id: string | null
  editable: boolean
}

export type KpiVariableWithChildren = {
  id: string
  name: string
  level: number
  parent_kpi_variable_id: string | null
  editable: boolean
  children: KpiVariableWithChildren[]
}

export type KpiVariableBody = { [key: string]: KpiVariable }

export interface KpiVariableReducerType {
  data: KpiVariableBody
  fetched: boolean
}

// Initial state
const INITIAL_STATE: KpiVariableReducerType = {
  data: {},
  fetched: false
}

// Actions
const getAll = createAction<{
  data: KpiVariable[]
}>(Types.GET_KPI_VARIABLES_SUCCESS)

const create = createAction<{
  data: KpiVariable
}>(Types.CREATE_KPI_VARIABLE_SUCCESS)

const update = createAction<{
  data: KpiVariable
}>(Types.UPDATE_KPI_VARIABLE_SUCCESS)

const destroy = createAction<{
  id: string
}>(Types.DELETE_KPI_VARIABLE_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const kpiTemplateReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAll, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as KpiVariableBody,
        fetched: true
      }
    })
    .addCase(create, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(update, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(destroy, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)

      delete newData[payload.id]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default kpiTemplateReducer
