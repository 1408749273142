import { IconNames } from 'components_new/atoms/Icon'
import { Condition, FilterType } from 'types/GlobalKpiOption'

export type Order = 'asc' | 'desc'

export type Sort = {
  attribute: string
  direction: Order
}

export enum FileExtension {
  CSV = 'csv',
  XLSX = 'xlsx'
}

export interface FilterOption {
  id: string
  type: 'DEFAULT' | 'FROM_TO'
  values?: string[]
  from_values?: string[]
  to_values?: string[]
}

export type FilterBody = {
  attributeId: string
  condition: Condition
  value: string | null | (string | number | null)[]
  type: FilterType
}

export type Filters = { [id: string]: FilterBody }

export type Severity = 'info' | 'error' | 'success' | 'warning'

export interface StatusBannerBody {
  customer_ids: string[]
  description: string
  icon: IconNames
  link: string | null
  link_label: string | null
  severity: Severity
  show: boolean
  show_public: boolean
  title: string
}

export interface StatusBannerData extends StatusBannerBody {
  id: string
}

export interface SimRenaming {
  id: string
  category: string
  original_name: string
  modified_name: string | null
  attribute_id: string
  fact_dimension_linker_id: string
}
