import React from 'react'

import ProgressBar from 'components/common/ProgressBar'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import { useTheme } from '@mui/material'

const Header = ({ validCount, totalCount }) => {
  const theme = useTheme()

  const errorCount = totalCount - validCount
  const validPercent =
    totalCount > 0 ? Math.floor((validCount / totalCount) * 100) : false
  const errorPercent =
    totalCount > 0 ? Math.floor((errorCount / totalCount) * 100) : false

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Text color="text.primary" variant="h6">
          Övergripande kvalitet
        </Text>
      </Box>
      <Divider />
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 2 }}>
          <Text color="text.primary" variant="h2">
            {totalCount === 0 ? '-' : `${validPercent}%`}
          </Text>
          <ValididitySection
            validPercent={validPercent}
            errorPercent={errorPercent}
            validCount={validCount}
            errorCount={errorCount}
          />
        </Box>
        <ProgressBar
          hideText
          hideHover
          percent={totalCount === 0 ? 0 : validPercent}
          leftoverColor={
            totalCount === 0
              ? theme.palette.grey[300]
              : theme.palette.error.main
          }
        />
      </Box>
    </>
  )
}

const ValididitySection = ({
  validPercent,
  errorPercent,
  validCount,
  errorCount
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          gap: 2
        }}
      >
        <Chip
          color="success"
          icon={<Icon name="CheckCircleOutline" />}
          label="Giltiga"
          size="small"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Text color="text.primary" align="right" variant="subtitle2">
            {validCount}
          </Text>
          <Text color="text.primary" align="right" variant="subtitle2">
            {validPercent ? `${validPercent}%` : '-'}
          </Text>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          gap: 2
        }}
      >
        <Chip
          color="error"
          icon={<Icon name="ErrorOutlineOutlined" />}
          label="Ogiltiga"
          size="small"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Text color="text.primary" align="right" variant="subtitle2">
            {errorCount}
          </Text>
          <Text color="text.primary" align="right" variant="subtitle2">
            {errorPercent ? `${errorPercent}%` : '-'}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default Header
