import React, { useState } from 'react'

import { DashboardGroup } from 'redux/reducers/Dashboards'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'
import { translateCategory } from 'utils/enumTranslator'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Paper from 'components_new/atoms/Paper'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import DashboardPreview from 'components_new/molecules/DashboardPreview'

interface DetailedViewProps {
  createDashboard: (id?: string) => void
  isHomepal: boolean
  limitReached: boolean
  onClose: () => void
  onOrder: (id: string) => void
  template: DashboardGroup
}

const DetailedView = (props: DetailedViewProps) => {
  const {
    createDashboard,
    isHomepal,
    limitReached,
    onClose,
    onOrder,
    template
  } = props

  const [ordered, setOrdered] = useState<boolean>(false)

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        p: 6,
        maxWidth: '100%'
      }}
    >
      <Box sx={{ flex: 1, maxWidth: 'content', mr: 8 }}>
        <Button
          color="primary"
          onClick={onClose}
          startIcon={<Icon name="West" />}
          variant="text"
          sx={{ mb: 2 }}
        >
          Tillbaka till sortiment
        </Button>
        <Box>
          <Box sx={{ mb: 4 }}>
            {template.category.selected ? (
              <Chip
                label={translateCategory[template.category.selected].title}
                size="small"
              />
            ) : null}
            <Text variant="h3">{template.title}</Text>
            <Stack spacing={1} sx={{ my: 4 }}>
              {isHomepal ? (
                <Button
                  disabled={limitReached}
                  onClick={() => createDashboard(template.id)}
                  size="large"
                >
                  Aktivera
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setOrdered(true)
                    onOrder(template.title)
                  }}
                  disabled={ordered}
                  size="large"
                >
                  {ordered ? 'Förfrågan skickad' : 'Skicka förfrågan'}
                </Button>
              )}
              <Button
                onClick={() => {
                  window.open(
                    `https://homepal.se/dashboards/${template.cms_data?.fields.category.fields.slug}/${template.cms_data?.fields.slug}`,
                    '_blank'
                  )
                }}
                size="large"
                endIcon={<Icon name="OpenInNewOutlined" />}
                sx={{ my: 4 }}
                variant="text"
              >
                Öppna på vår hemsida
              </Button>
            </Stack>
            <Text
              variant="subtitle1"
              color="text.primary"
              component="div"
              paragraph
              gutterBottom
            >
              {documentToReactComponents(template.cms_data?.fields.summary)}
            </Text>
            <Text
              variant="body1"
              color="text.primary"
              component="div"
              paragraph
              gutterBottom
            >
              {documentToReactComponents(template.cms_data?.fields.body)}
            </Text>
          </Box>
          <Box sx={{ bgcolor: 'action.hover', borderRadius: 1, p: 2 }}>
            <List>
              {[
                {
                  title: 'Fokusområde',
                  body: template.cms_data?.fields.focus
                },
                {
                  title: 'Vem tittar på den?',
                  body: template.cms_data?.fields.roles
                },
                {
                  title: 'Hur ofta?',
                  body: template.cms_data?.fields.frequency
                }
              ].map((item, i) => (
                <ListItem key={i}>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      variant: 'h6'
                    }}
                    secondary={item.body}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: 'text.primary'
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ px: 2, py: 1 }}>
              <Divider sx={{ mb: 4 }} />
              <Text variant="subtitle2" color="text.secondary" gutterBottom>
                Exempel på system som kan anslutas för att aktivera den här
                dashboarden:
              </Text>
              <Stack
                direction="row"
                spacing={0}
                sx={{ mt: 1, flexWrap: 'wrap', gap: 1 }}
              >
                {template.cms_data?.fields.systems.map(
                  (system: any, i: number) => (
                    <Chip
                      key={i}
                      icon={
                        <img
                          src={'https:' + system.fields.icon.fields.file.url}
                          style={{ width: 18, height: 18 }}
                        />
                      }
                      label={system.fields.title}
                    />
                  )
                )}
                <Chip label={'...eller liknande'} />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1
        }}
      >
        {template.thumbnail && template.cms_data ? (
          <>
            <DashboardPreview
              showroomId={template.cms_data.fields.id}
              thumbnail={template.thumbnail}
              color={
                template.category.selected
                  ? translateCategory[template.category.selected].color
                  : undefined
              }
            />

            <ThemeProvider theme={getTheme('dark')}>
              <Paper
                sx={{
                  py: 1,
                  px: 2
                }}
              >
                <Text variant="body2" color="inherit">
                  Vi har fyllt den här dashboarden med påhittad live-data.{' '}
                  <strong>Klicka</strong> för att öppna och skapa dig en
                  uppfattning om hur den hade kunnat hjälpa dig och ditt team.
                </Text>
              </Paper>
            </ThemeProvider>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default DetailedView
