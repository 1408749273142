import React, { useEffect, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Button from 'components_new/atoms/Button'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface EditLogotypeDialogProps {
  onClose: () => void
  onSave: (value: string | null) => void
  open: boolean
  value: string | null
}

const EditLogotypeDialog = (props: EditLogotypeDialogProps) => {
  const { onClose, onSave, open, value } = props

  const [editValue, setEditValue] = useState<string>('')

  useEffect(() => {
    if (value) {
      setEditValue(value)
    } else {
      setEditValue('')
    }
  }, [])

  return (
    <SimpleDialog
      title="Redigera logotyp"
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text">
            Avbryt
          </Button>
          <Button
            onClick={() => {
              if (editValue) {
                onSave(editValue)
              } else {
                onSave(null)
              }
              onClose()
            }}
            disabled={
              !(
                editValue === '' ||
                editValue?.endsWith('.png') ||
                editValue?.endsWith('.jpg') ||
                editValue?.endsWith('.jpeg')
              )
            }
          >
            Spara
          </Button>
        </>
      }
    >
      <TextField
        label="Ange länk"
        helperText="Länken måste avslutas med .png, .jpg eller .jpeg"
        value={editValue}
        onChange={(e) => {
          setEditValue(e.target.value)
        }}
      />
      <Alert severity="info" sx={{ mt: 2 }}>
        <strong>Tänk på!</strong> Logotypen måste finnas <u>publikt</u>{' '}
        tillgänglig via länken du anger.
      </Alert>
    </SimpleDialog>
  )
}

export default EditLogotypeDialog
