import React from 'react'

import {
  TreeItem,
  TreeItemProps,
  TreeItemContentProps,
  useTreeItem
} from '@mui/x-tree-view'

import Box from 'components_new/atoms/Box'
import Checkbox from 'components_new/atoms/Checkbox'

type AccountTreeItemProps = TreeItemProps & {
  onToggle: () => void
  selected: 'checked' | 'indeterminate' | false
}

// Note: Comments are left on purpose for clarity in what is possible to modify
// in the future.

const AccountTreeItem = React.forwardRef(function AccountTreeItem(
  props: AccountTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    // children,
    label,
    onToggle,
    selected,
    ...rest
  } = props

  return (
    <TreeItem
      ContentComponent={CustomContent}
      label={
        <Box onClick={() => (props.disabled ? {} : onToggle())}>
          <Checkbox
            size="small"
            sx={{ height: 24, width: 24, mr: 0.5 }}
            checked={selected === 'checked'}
            indeterminate={selected === 'indeterminate'}
            disabled={rest.disabled}
          />
          {label}
        </Box>
      }
      {...rest}
      ref={ref}
    />
  )
})

export default AccountTreeItem

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon
  } = props

  const {
    disabled,
    expanded,
    // selected,
    // focused,
    handleExpansion,
    // handleSelection,
    preventSelection
  } = useTreeItem(nodeId)

  const icon = iconProp || expansionIcon || displayIcon

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    preventSelection(event)
  }

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleExpansion(event)
  }

  //   const handleSelectionClick = (
  //     event: React.MouseEvent<HTMLDivElement, MouseEvent>
  //   ) => {
  //     handleSelection(event)
  //   }

  return (
    <Box
      className={`${className} ${classes.root} ${
        expanded ? classes.expanded : ''
      } ${disabled ? classes.disabled : ''}`}
      //   ${selected ? classes.selected : ''}
      //   ${focused ? classes.focused : ''}
      onMouseDown={handleMouseDown}
      ref={ref /* as React.Ref<HTMLDivElement>  .tsx*/}
      sx={{
        height: 32,
        cursor: 'default',
        '&&:hover, &&.Mui-disabled, &&.Mui-focused, &&.Mui-selected, &&.Mui-selected.Mui-focused, &&.Mui-selected:hover':
          {
            backgroundColor: 'transparent'
          },
        '&:hover .MuiTreeItem-contentBar': {
          bgcolor: 'primary.transparent'
        }
      }}
    >
      <Box
        onClick={handleExpansionClick}
        className={classes.iconContainer}
        sx={{ zIndex: 1 }}
      >
        {icon}
      </Box>
      <Box
        onClick={handleMouseDown}
        className={classes.label}
        sx={{ zIndex: 1 }}
      >
        {label}
      </Box>
      <Box
        className="MuiTreeItem-contentBar"
        sx={{
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: 32,
          left: 0,
          cursor: 'default'
        }}
      />
    </Box>
  )
})
