import React, { useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as DataProductActions from 'redux/actions/Applications/DataProducts'
import * as UserActions from 'redux/actions/Applications/Users'
import * as OntologyActions from 'redux/actions/Applications/Ontology'

import SpecificAPI from 'components/containers/Applications/AccessHub/Specific'

const SpecificAPIPage = ({
  AppsDataProductStore,
  UserStore,
  tryGetOneDataProduct,
  tryGetAllUsers,
  match,
  tryGetOntology,
  OntologyStore,
  history
}) => {
  useEffect(() => {
    tryGetOneDataProduct(match.params.id)

    if (!UserStore.fetched) {
      tryGetAllUsers()
    }

    if (!OntologyStore.fetched) {
      tryGetOntology()
    }
  }, [])

  useEffect(() => {
    const dp = AppsDataProductStore.data[AppsDataProductStore.currentFetchOne]

    if (dp && dp.attributes.type !== 'API') {
      history.push('/access-hub')
    }
  }, [AppsDataProductStore.currentFetchOne])

  return AppsDataProductStore.currentFetchOne === match.params.id &&
    UserStore.fetched &&
    OntologyStore.fetched ? (
        <SpecificAPI />
      ) : null
}

function mapStateToProps({
  AppsDataProductStore,
  AuthStore,
  UserStore,
  OntologyStore
}) {
  return { AppsDataProductStore, AuthStore, UserStore, OntologyStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...DataProductActions,
      ...UserActions,
      ...OntologyActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SpecificAPIPage))
