import React, { ElementType, ReactNode } from 'react'
import { ListSubheader as MUIListSubheader } from '@mui/material'

interface ListSubheaderProps {
  children: ReactNode
  color?: 'default' | 'inherit' | 'primary'
  component?: ElementType<any>
  disableGutters?: boolean
  disableSticky?: boolean
  inset?: boolean
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListSubheader = React.forwardRef(
  (props: ListSubheaderProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUIListSubheader {...rest} ref={ref}>
        {children}
      </MUIListSubheader>
    )
  }
)

ListSubheader.displayName = 'ListSubheader'
export default ListSubheader
