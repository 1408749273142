import {
  create,
  getAll,
  DashboardTemplatePatchBody,
  update,
  order
} from 'redux/api/DashboardTemplates'
import {
  DashboardPostBody,
  destroy,
  getOne,
  updateLayout,
  WidgetLayoutPatchBody
} from 'redux/api/Dashboards'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryUpdateDashboardTemplateLayout(
  id: string,
  body: WidgetLayoutPatchBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_TEMPLATE_WIDGET_LAYOUT
    })

    updateLayout(id, body)
      .then(() => {
        dispatch({
          type: Types.UPDATE_TEMPLATE_WIDGET_LAYOUT_SUCCESS,
          payload: {
            id,
            layout: body.data.layout
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_TEMPLATE_WIDGET_LAYOUT_FAILED
        })
      })
  }
}

export function tryGetAllDashboardTemplates() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_DASHBOARD_TEMPLATES
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_DASHBOARD_TEMPLATES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_DASHBOARD_TEMPLATES_FAILED
        })
      })
  }
}

export function tryGetOneDashboardTemplate(id: string, fallback: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ONE_DASHBOARD_TEMPLATE
    })

    getOne(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_TEMPLATE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_TEMPLATE_FAILED
        })

        fallback()
      })
  }
}

// eslint-disable-next-line no-unused-vars
type NavigateCallbackFunction = (id: string) => void

export function tryCreateDashboardTemplate(
  body: DashboardPostBody,
  navigate: NavigateCallbackFunction
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_DASHBOARD_TEMPLATE
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_DASHBOARD_TEMPLATE_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'En ny mall har skapats' }
        })

        navigate(response.data.data.dashboard.id)
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DASHBOARD_TEMPLATE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunde inte skapa ny mall' }
        })
      })
  }
}

export function tryDeleteDashboardTemplate(id: string, navigate: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_DASHBOARD_TEMPLATE
    })

    destroy(id)
      .then(() => {
        navigate()

        dispatch({
          type: Types.DELETE_DASHBOARD_TEMPLATE_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mallen har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_TEMPLATE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mallen kunde inte tas bort' }
        })
      })
  }
}

export function tryUpdateDashboardTemplate(
  id: string,
  body: DashboardTemplatePatchBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_TEMPLATE
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mallen har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mallen kunde inte uppdateras' }
        })
      })
  }
}

export function tryOrderDashboardTemplate(
  title: string,
  email: string | undefined
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.ORDER_DASHBOARD_TEMPLATE
    })

    order(title, email)
      .then(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message:
              'Förfrågan skickad!\nVi återkopplar till dig så snabbt vi kan.'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Förfrågan kunde inte skickas' }
        })
      })
  }
}
