import React, { useState, useRef, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ShortId from 'shortid'

import Styles from './styles.module.css'
import { Dropdown, Menu } from 'antd'
import Icon from 'components_new/atoms/Icon'

import SearchWrapper from 'components/common/SearchWrapper'

import * as TagActions from 'redux/actions/DataPlatform/Tags'
import Chip from 'components_new/atoms/Chip'

const AddTag = ({
  ignoreLockedMode,
  TagStore,
  tryPutTagLinker,
  tryDeleteTagLinker,
  referenceId
}) => {
  const [open, setOpen] = useState(false)
  let ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const productTag = window.location.pathname.split('/')?.[1]
  const tags = {}

  Object.values(TagStore.data).map((item) => {
    tags[item.attributes.name.toLowerCase()] = {
      name: item.attributes.name
    }
  })

  if (TagStore.linkers[referenceId] && !open) {
    return (
      <ActiveTagContent
        open={open}
        setOpen={setOpen}
        TagStore={TagStore}
        referenceId={referenceId}
        onDelete={() => tryDeleteTagLinker(referenceId)}
      />
    )
  }

  return (
    <Dropdown
      visible={open}
      overlay={
        <div ref={ref}>
          <AddTagContent
            ignoreLockedMode={ignoreLockedMode}
            tags={tags}
            put={(tag) => {
              tryPutTagLinker({
                data: {
                  attributes: {
                    tag,
                    reference_id: referenceId,
                    product_tag: productTag
                  }
                }
              })
              setOpen(false)
            }}
          />
        </div>
      }
      overlayClassName={Styles['dropdown-item-container']}
      placement={'bottomCenter'}
      trigger={['click']}
    >
      {TagStore.linkers[referenceId] ? (
        <ActiveTagContent
          open={open}
          setOpen={setOpen}
          TagStore={TagStore}
          referenceId={referenceId}
          onDelete={() => tryDeleteTagLinker(referenceId)}
        />
      ) : (
        <Chip
          icon={<Icon name="AddCircleOutlineOutlined" />}
          label="Lägg till tagg"
          onClick={() => setOpen(!open)}
          size="small"
        />
      )}
    </Dropdown>
  )
}

const ActiveTagContent = ({
  open,
  setOpen,
  TagStore,
  referenceId,
  onDelete
}) => (
  <>
    <Chip
      color="primary"
      label={TagStore.linkers[referenceId]}
      onClick={() => setOpen(!open)}
      onDelete={() => onDelete()}
      size="small"
    />
  </>
)

const AddTagContent = ({ tags, ignoreLockedMode, put }) => {
  const [key] = useState(ShortId.generate())

  return (
    <Menu>
      <SearchWrapper
        inputSize={'small'}
        inputContainerClassName={`ignore-locked-mode ${Styles['search-input']}`}
        placeholder={'Välj tagg'}
        data={Object.values(tags)}
        filterKey={'name'}
        DataComponent={({ data, search }) => {
          const tagExists = search.toLowerCase() in tags

          return (
            <div className={Styles['search-data']}>
              <div className={Styles['search-data-container']}>
                {search && !tagExists && (
                  <div
                    className={`${Styles['new-tag-container']} ${
                      ignoreLockedMode ? 'ignore-locked-mode' : ''
                    }`}
                    onClick={() => put(search)}
                    key={`${key}_new_tag`}
                  >
                    <p className={Styles['item-text']}>{search} </p>
                    <p className={`secondary ${Styles['item-text']}`}>
                      (ny tagg)
                    </p>
                  </div>
                )}
                {data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((tag) => (
                    <div
                      className={`${Styles['item-container']} ${
                        ignoreLockedMode ? 'ignore-locked-mode' : ''
                      }`}
                      onClick={() => put(tag.name)}
                      key={`${key}_${tag.name}`}
                    >
                      <p className={Styles['item-text']}>{tag.name}</p>
                    </div>
                  ))}
              </div>
            </div>
          )
        }}
      />
    </Menu>
  )
}

function mapStateToProps({ TagStore }) {
  return { TagStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(TagActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTag)
