import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import Dimensions, { TDimensions } from './Dimensions'
import DisabledWrapper from './DisabledWrapper'
import Filter, { TFilter } from './Filter'
import NotInLicense from './NotInLicense'
import PublicToggle from './PublicToggle'
import Output, { TOutputMode } from './Output'

import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'

export type TEmbedType = 'dashboard' | 'widget'

interface EmbedSectionProps {
  allowEmbed: boolean
  controlledFilter?: TFilter
  editable: boolean
  embedType: TEmbedType
  filterOptions: ParsedDashboardFilterOptions[]
  isPublic: boolean
  link: string
  setIsPublic: (value: boolean) => void
}

const EmbedSection = (props: EmbedSectionProps) => {
  const {
    allowEmbed,
    controlledFilter,
    editable,
    embedType,
    filterOptions,
    isPublic,
    link,
    setIsPublic
  } = props

  const [dimensions, setDimensions] = useState<TDimensions>({
    width: '100%',
    height: '100%'
  })

  const [filter, setFilter] = useState<TFilter>({})

  const [outputMode, setOutputMode] = useState<TOutputMode>('iframe')

  return (
    <>
      {allowEmbed ? (
        <>
          <Box sx={{ mb: 2 }}>
            <PublicToggle
              editable={editable}
              embedType={embedType}
              isPublic={isPublic}
              setIsPublic={setIsPublic}
            />
          </Box>
          <DisabledWrapper isPublic={isPublic}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Output
                  dimensions={dimensions}
                  embedType={embedType}
                  filter={controlledFilter ?? filter}
                  isPublic={isPublic}
                  link={link}
                  outputMode={outputMode}
                  setOutputMode={setOutputMode}
                />
                <Collapse in={outputMode === 'iframe'}>
                  <Dimensions
                    dimensions={dimensions}
                    embedType={embedType}
                    setDimensions={setDimensions}
                  />
                </Collapse>
              </Box>
              {!controlledFilter ? (
                <Box sx={{ width: 320, px: 2 }}>
                  <Filter
                    embedType={embedType}
                    filter={filter}
                    filterOptions={filterOptions}
                    setFilter={setFilter}
                  />
                </Box>
              ) : null}
            </Box>
          </DisabledWrapper>
        </>
      ) : (
        <NotInLicense />
      )}
    </>
  )
}

export default EmbedSection
