import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Card from 'components_new/atoms/Card'
import CardContent from 'components_new/atoms/Card/CardContent'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Text from 'components_new/atoms/Text'
import CardActions from 'components_new/atoms/Card/CardActions'

interface StandardThumbnailProps {
  actions?: ReactNode
  body: string
  content?: ReactNode
  disabled?: boolean
  onClick: () => void
  sx?: object
  title: string
}

const StandardThumbnail = (props: StandardThumbnailProps) => {
  const { actions, body, content, disabled, onClick, sx, title } = props

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 1,
        ...sx
      }}
    >
      <CardActionArea
        onClick={!disabled ? () => onClick() : () => {}}
        disabled={disabled}
        sx={{
          width: '100%',
          height: '100%',
          pointerEvents: 'all!important',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <CardContent sx={{ width: '100%', flex: 1 }}>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <Text variant="h6" color={'text.primary'}>
              {title}
            </Text>
            <Text
              variant="body1"
              color="text.secondary"
              sx={{
                mt: 1,
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {body}
            </Text>
            {content}
          </Box>
        </CardContent>
        {actions ? (
          <CardActions sx={{ width: '100%' }}>{actions}</CardActions>
        ) : null}
      </CardActionArea>
    </Card>
  )
}

export default StandardThumbnail
