import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface SettingsSelectDialogProps {
  onChange: (params: Option[]) => void
  onClose: () => void
  onSubmit: () => void
  open: boolean
  options: Option[]
  title: string
  value: Option[]
  variant?: 'add' | 'edit'
}

export type TInputOption = { disabled?: boolean; label: string; value: any }

const SettingsSelectDialog = (props: SettingsSelectDialogProps) => {
  const { onChange, onClose, onSubmit, options, open, title, value, variant } =
    props

  const handleSubmit = () => {
    onSubmit()
    onClose()
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <>
            <Button onClick={onClose} variant="text">
              Avbryt
            </Button>
            <Button onClick={handleSubmit} disabled={value.length === 0}>
              {variant === 'add' ? 'Lägg till' : 'Spara'}
            </Button>
          </>
        }
        disableRestoreFocus={true}
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 1302 // for fullSize
        }}
        title={`${
          variant === 'add' ? 'Lägg till' : 'Redigera'
        } ${title.toLowerCase()}`}
      >
        <SelectSearch
          onChange={(options: Option[]) => onChange(options)}
          multiple={true}
          options={options}
          selected={value}
          sort={true}
        />
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default SettingsSelectDialog
