import { createAction, createReducer } from '@reduxjs/toolkit'

import { arrayToObject } from 'helpers/Functions'

import { InternalCustomerRedux } from 'types/GlobalCustomer'
import { OrderedKpiTemplate } from 'types/GlobalOrderedKpiTemplate'

import * as Types from 'redux/Types'

export interface OurCustomersReducerType {
  customers: InternalCustomerRedux
  customersFetched: boolean
}

// Initial state
const INITIAL_STATE: OurCustomersReducerType = {
  customers: {},
  customersFetched: false
}

// Actions
const getCustomers = createAction<{ data: any[] }>(
  Types.GET_OUR_CUSTOMERS_SUCCESS
)
const updateCustomer = createAction<{ data: any[] }>(
  Types.UPDATE_ONE_CUSTOMER_SUCCESS
)
const createOrderedKpiTemplate = createAction<{ data: any[] }>(
  Types.CREATE_ORDERED_KPI_TEMPLATE_SUCCESS
)
const updateOrderedKpiTemplate = createAction<{ data: any[] }>(
  Types.UPDATE_ORDERED_KPI_TEMPLATE_SUCCESS
)
const deleteOrderedKpiTemplate = createAction<{ data: any[] }>(
  Types.DELETE_ORDERED_KPI_TEMPLATE_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const CustomersAndLicensesReducer = createReducer(
  INITIAL_STATE,
  (builder: any) => {
    builder
      .addCase(getCustomers, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          customers: arrayToObject(payload.data),
          customersFetched: true
        }
      })
      .addCase(updateCustomer, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          customers: {
            ...state.customers,
            [payload.data.id]: payload.data
          }
        }
      })
      .addCase(createOrderedKpiTemplate, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          customers: {
            ...state.customers,
            [payload.customerId]: {
              ...state.customers[payload.customerId],
              ordered_kpi_templates: [
                ...state.customers[payload.customerId].ordered_kpi_templates,
                payload.response.data
              ]
            }
          }
        }
      })
      .addCase(updateOrderedKpiTemplate, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          customers: {
            ...state.customers,
            [payload.customerId]: {
              ...state.customers[payload.customerId],
              ordered_kpi_templates: state.customers[
                payload.customerId
              ].ordered_kpi_templates.map((item: OrderedKpiTemplate) => {
                if (item.id === payload.id) {
                  return payload.response.data
                }

                return item
              })
            }
          }
        }
      })
      .addCase(deleteOrderedKpiTemplate, (state: any, action: any) => {
        const { payload } = action

        return {
          ...state,
          customers: {
            ...state.customers,
            [payload.customerId]: {
              ...state.customers[payload.customerId],
              ordered_kpi_templates: state.customers[
                payload.customerId
              ].ordered_kpi_templates.filter(
                (item: OrderedKpiTemplate) => item.id !== payload.id
              )
            }
          }
        }
      })
      .addCase(signOutAction, () => INITIAL_STATE)
      .addDefaultCase((state: any) => state)
  }
)

export default CustomersAndLicensesReducer
