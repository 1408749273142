import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Spin from 'components_new/atoms/Spin'

import * as DataProductActions from 'redux/actions/Applications/DataProducts'
import * as UserActions from 'redux/actions/Applications/Users'

import Overview from 'components/containers/Applications/AccessHub'
import Styles from './styles.module.css'

const OverviewPage = ({
  tryGetDataProducts,
  AppsDataProductStore,
  tryGetAllUsers,
  UserStore
}) => {
  useEffect(() => {
    if (!AppsDataProductStore.fetched || AppsDataProductStore.type !== 'API') {
      tryGetDataProducts('API')
    }

    if (!UserStore.fetched) {
      tryGetAllUsers()
    }
  }, [])

  return AppsDataProductStore.type === 'API' && UserStore.fetched ? (
    <Overview />
  ) : (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin size="large" className={Styles.spin} />
    </Box>
  )
}

function mapStateToProps({ AppsDataProductStore, UserStore }) {
  return { AppsDataProductStore, UserStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...DataProductActions,
      ...UserActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage)
