import React from 'react'
import { Link } from 'react-router-dom'

import Box from 'components_new/atoms/Box'

import EditableTitle from 'components_new/molecules/EditableTitle'
import OverflowText from 'components_new/molecules/OverflowText'

import { DashboardType } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'

import { translateDashboardTypePlural } from 'utils/enumTranslator'
import { useMediaQuery, useTheme } from '@mui/material'

const getNavigationPath = {
  [DashboardType.DEFAULT]: '/dashboards',
  [DashboardType.TEMPLATE]: '/templates'
}

const getTranslation = (type: DashboardType) => {
  if (type === DashboardType.DEFAULT) {
    return 'Alla dashboards'
  }

  return translateDashboardTypePlural[type]
}

interface PageTitleProps {
  editable: boolean
  folder?: DashboardFolder | null
  onSubmit: (value: string) => void
  title: string
  type: DashboardType
}

const PageTitle = (props: PageTitleProps) => {
  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box sx={{ display: 'flex', gap: 0.5, minWidth: 0 }}>
      {lg ? (
        <Box sx={{ display: 'flex', gap: 0.5, minWidth: 0 }}>
          <OverflowText variant="button" color="text.secondary">
            <Link to={getNavigationPath[props.type]}>
              {`${getTranslation(props.type)} /`}
            </Link>
          </OverflowText>
          {props.folder ? (
            <OverflowText variant="button" color="text.secondary">
              <Link
                to={`/dashboards/folders/${props.folder.id}`}
              >{`${props.folder.name} /`}</Link>
            </OverflowText>
          ) : null}
        </Box>
      ) : null}
      <EditableTitle
        variant="button"
        color="text.primary"
        value={props.title}
        onSubmit={props.onSubmit}
        disabled={!props.editable}
      />
    </Box>
  )
}

export default PageTitle
