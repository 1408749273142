import React from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Stack from 'components_new/atoms/Stack'
import Tooltip from 'components_new/atoms/Tooltip'

import { TRANSLATE_ROLES } from 'utils/enumTranslator'

import { AccountRole } from 'types/GlobalUser'

interface PermissionItemProps {
  body?: string
  editable: boolean
  fullRights: boolean
  isOwner: boolean
  onDelete?: () => void
  role: AccountRole
  title: string
  variant?: 'customer' | 'user'
}

const PermissionItem = (props: PermissionItemProps) => {
  const {
    body,
    editable,
    fullRights,
    isOwner,
    onDelete,
    role,
    title,
    variant
  } = props

  return (
    <ListItem
      dense={true}
      secondaryAction={
        <Stack direction="row" alignItems="center" spacing={1}>
          {
            <>
              {isOwner ? (
                <Tooltip title={`${title} är ägare av denna dashboard`}>
                  <Chip label={'Ägare'} size="small" />
                </Tooltip>
              ) : (
                <Chip
                  label={
                    variant === 'customer' ? 'Kund' : TRANSLATE_ROLES[role]
                  }
                  size="small"
                />
              )}
              {fullRights && !editable ? <Box sx={{ width: 36 }} /> : null}
              {editable ? (
                <Box sx={{ width: 36 }}>
                  <Tooltip
                    title={
                      variant === 'customer'
                        ? 'Ta bort tillagd koncern'
                        : 'Ta bort tillagd användare'
                    }
                  >
                    <IconButton onClick={onDelete}>
                      <Icon name="Clear" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : null}
            </>
          }
        </Stack>
      }
      sx={{
        '&:hover': {
          bgcolor: 'action.hover'
        },
        '&:last-child': {
          borderBottom: 0
        }
      }}
    >
      <ListItemAvatar>
        {variant === 'customer' ? (
          <Icon color="disabled" name="BusinessOutlined" />
        ) : (
          <Avatar alt={title} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ fontWeight: 'medium' }}
        secondary={body}
      />
    </ListItem>
  )
}

export default PermissionItem
