import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import Modal from 'components/common/Modal'
import Card from 'components/common/Card'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const AddNode = ({ visible, onClose, onCreate }) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box>
          <Text gutterBottom variant="h6">
            Input
          </Text>
          <Card
            className={Styles['option-card']}
            onClick={() => onCreate('INPUT')}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  mb: 0.25
                }}
              >
                <Icon color="primary" fontSize="small" name="LoginOutlined" />
                <Text variant="subtitle2">Input</Text>
              </Box>
              <Text color="text.secondary" variant="body2">
                Laddar in data från en tabell.
              </Text>
            </Box>
          </Card>
        </Box>
        <Box>
          <Text gutterBottom variant="h6">
            Transformering
          </Text>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Conf.TRANSFORM_NODES.map((node) => (
              <Card
                className={Styles['option-card']}
                key={node.title}
                onClick={() =>
                  node.active
                    ? onCreate(node.enum)
                    : console.log('Not yet implemented')
                }
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      mb: 0.25
                    }}
                  >
                    <Icon color="primary" fontSize="small" name={node.icon} />
                    <Text variant="subtitle2">{node.title}</Text>
                  </Box>
                  <Text color="text.secondary" variant="body2">
                    {node.description}
                  </Text>
                </Box>
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddNode
