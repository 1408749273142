import React from 'react'
import Moment from 'moment'

import { DatePicker as AntdDatePicker } from 'antd'

import 'moment/locale/sv'
import locale from 'antd/es/locale/sv_SE'

// Local files.
import Styles from './styles.module.css'

// Overrite default antd.
import './styles.css'

const DatePicker = ({
  value,
  onChange,
  picker,
  className,
  label,
  labelBackground,
  size = 'large',
  ignoreLockedMode = false
}) => {
  const parsedValue = typeof value === 'string' ? Moment(value) : value

  return (
    <div className={label ? Styles['label-container'] : ''}>
      {label && (
        <label className={labelBackground ? Styles['colored-label'] : ''}>
          {label}
        </label>
      )}
      <AntdDatePicker
        size={size}
        locale={locale}
        picker={picker}
        className={`${Styles['date-picker']} ${className} ${
          ignoreLockedMode ? 'ignore-locked-mode' : ''
        }`}
        value={parsedValue}
        onChange={(newValue) => onChange(newValue)}
        allowClear={false}
        placeholder={'Välj datum'}
        popupClassName={`${ignoreLockedMode ? 'ignore-locked-mode' : ''}`}
      />
    </div>
  )
}

export default DatePicker
