import { Layout } from 'react-grid-layout'
import { History } from 'history'

import { DashboardBody, DashboardType } from 'redux/reducers/Dashboards'

import { ComponentProps as DashboardPageProps } from 'components_new/organisms/Dashboard'
import { QueryParams } from 'redux/api/Widgets'
import { DashboardPatchBody } from 'redux/api/Dashboards'
import { FileExtension, FilterBody, Filters, Sort } from 'utils/types'
import { AccountBody, AccountType } from 'redux/reducers/Accounts'
import { DashboardsPageProps } from '../Dashboards'

import { isCustomPeriodFilter, isPeriodFilterEnum } from 'utils/functions'

import { Condition, FilterType } from 'types/GlobalKpiOption'
import {
  CustomPeriodFilter,
  PeriodFilter,
  WidgetObject,
  WidgetPatchBody,
  WidgetType
} from 'types/GlobalWidget'
import { AccountRole, User } from 'types/GlobalUser'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

const DIM_COMPANY_GROUP_ID_ATTRIBUTE_ID = 'cd6d38a5-365e-4276-80f3-70443ea7a756'

export interface UserType {
  accountId: string
  companyGroupId: string | null
  name: string
  email: string
  role: AccountRole
  allowRevoke: boolean
  owner?: boolean
}

export interface PermissionPatchType {
  companyGroupId?: string
  accountId?: string
  message?: string
  newUserEmail?: string
  allowRevoke?: boolean
}

/* ---- REDUX UPDATES ---- */
// Update dashboard.
export const updateDashboard = (
  props: DashboardPageProps,
  id: string,
  body: DashboardPatchBody
) => {
  const { tryUpdateDashboard } = props

  tryUpdateDashboard(id, body)
}

export const deleteDashboard = (
  props: DashboardPageProps,
  id: string,
  navigateBack: () => void
) => {
  const { tryDeleteDashboard } = props

  tryDeleteDashboard(id, navigateBack)
}

export const duplicateDashboard = (
  props: DashboardPageProps,
  id: string,
  history: History
) => {
  const { tryCreateDashboard } = props

  tryCreateDashboard(
    {
      data: {
        type: DashboardType.DEFAULT,
        dashboard_id: id
      }
    },
    (createdId) => history.push(`/dashboards/${createdId}`)
  )
}

const formatUser = (
  account: AccountType,
  allowRevoke = false,
  owner = false
): UserType => {
  return {
    accountId: account.id,
    companyGroupId: account.company_group_id,
    name:
      account.first_name || account.last_name
        ? `${account.first_name} ${account.last_name}`
        : 'Nytt konto',
    email: account.email,
    role: account.role,
    allowRevoke,
    owner
  }
}

export const getPermissions = (
  props: DashboardPageProps | DashboardsPageProps,
  dashboardData: DashboardBody,
  id: string,
  signedInUser: User | null,
  ownerId: string | null
): UserType[] => {
  const { AccountStore } = props

  if (dashboardData[id]?.share_settings && AccountStore.data && signedInUser) {
    const owner = getOwner(AccountStore.data, ownerId)

    const admins = Object.values(AccountStore.data)
      .filter(
        (account) =>
          account.role === AccountRole.ADMIN && account.id !== ownerId
      )
      .map((account) => formatUser(account))

    const viewers = dashboardData[id]?.share_settings.permissions
      .filter(
        (permission) =>
          permission.account_id && AccountStore.data[permission.account_id]
      )
      .map((permission) => {
        const account = AccountStore.data[permission.account_id as string]

        return formatUser(account, account.id !== signedInUser.id)
      })

    return [...(owner ? [owner] : []), ...admins, ...viewers]
  }

  return []
}

export const getUserOptions = (
  props: DashboardPageProps,
  signedInUser: User | null,
  ownerId: string | null
): UserType[] => {
  const { AccountStore } = props

  if (!signedInUser) {
    return []
  }

  return Object.values(AccountStore.data)
    .filter(
      (account) =>
        account.role === AccountRole.VIEW &&
        account.id !== signedInUser.id &&
        // remove owner of dashboard from user options
        (ownerId ? account.id !== ownerId : true)
    )
    .map((account) => formatUser(account, false))
}

export const deleteWidget = (
  props: DashboardPageProps,
  widgetId: string,
  dashboardId: string
) => {
  const { tryDeleteWidget } = props

  tryDeleteWidget(widgetId, dashboardId)
}

export const createWidget = (
  props: DashboardPageProps,
  dashboardId: string,
  kpiId: string | null,
  layout: Layout,
  performKpiId?: string,
  type?: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
) => {
  const { tryCreateWidget } = props

  tryCreateWidget({
    data: {
      dashboard_id: dashboardId,
      kpi_id: kpiId,
      perform_kpi_id: performKpiId,
      width: layout.w,
      height: type === WidgetType.TEXT ? 1 : layout.h,
      type,
      x: layout.x,
      y: layout.y
    }
  })
}

export const updateWidget = (
  props: DashboardPageProps,
  widgetId: string,
  body: WidgetPatchBody,
  periodFilter: PeriodFilter | CustomPeriodFilter | null
) => {
  const { tryUpdateWidget } = props

  tryUpdateWidget(widgetId, body, !!periodFilter)
}

export const getWidgetQueryParams = (
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  showAs: string | null,
  ignoreValidateFilterAttributes = false
) => {
  let queryParam: QueryParams | null = getDashboardFilterToQueryParams(
    dashboardFilter,
    widget,
    showAs,
    ignoreValidateFilterAttributes
  )

  if (
    periodFilter &&
    isCustomPeriodFilter(periodFilter) &&
    periodFilter.from &&
    periodFilter.to
  ) {
    queryParam = {
      ...(queryParam || {}),
      from_date: periodFilter.from,
      to_date: periodFilter.to
    }
  } else if (periodFilter && isPeriodFilterEnum(periodFilter)) {
    queryParam = {
      ...(queryParam || {}),
      period_filter: periodFilter
    }
  }

  return queryParam
}

export const getDetailsQueryParams = (
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  sort: Sort | null,
  filters: Filters,
  showAs: string | null
) => {
  const queryParams: QueryParams = {
    sort,
    filter: Object.values(filters)
  }

  const dashboardQueryParams = getWidgetQueryParams(
    widget,
    dashboardFilter,
    periodFilter,
    showAs
  )

  // combine underlying data query params with dashboard
  if (dashboardQueryParams) {
    if (dashboardQueryParams.period_filter) {
      queryParams.period_filter = dashboardQueryParams.period_filter
    }

    if (dashboardQueryParams.filter) {
      queryParams.filter = [
        ...(queryParams.filter || []),
        ...dashboardQueryParams.filter
      ]
    }

    if (dashboardQueryParams.from_date && dashboardQueryParams.to_date) {
      queryParams.from_date = dashboardQueryParams.from_date
      queryParams.to_date = dashboardQueryParams.to_date
    }
  }

  return queryParams
}

export const getDetails = (
  props: DashboardPageProps,
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  offset: number,
  sort: Sort | null,
  filters: Filters,
  kpiOptionId: string,
  showAs: string | null
) => {
  const { tryGetDetails } = props

  const queryParams = getDetailsQueryParams(
    widget,
    dashboardFilter,
    periodFilter,
    sort,
    filters,
    showAs
  )

  tryGetDetails(kpiOptionId, offset, queryParams)
}

export const exportDetails = (
  props: DashboardPageProps,
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  ext: FileExtension,
  sort: Sort | null,
  filters: Filters,
  kpiOptionId: string,
  showAs: string | null
) => {
  const { tryExportDetails } = props
  const fileName = widget.title

  const queryParams = getDetailsQueryParams(
    widget,
    dashboardFilter,
    periodFilter,
    sort,
    filters,
    showAs
  )

  tryExportDetails(kpiOptionId, fileName, ext, queryParams)
}

export const parseFilterQueryParams = (
  dashboardFilter: DashboardFilter,
  widget: WidgetObject,
  showAs: string | null,
  ignoreValidateFilterAttributes = false
): QueryParams | null => {
  let allowedFilterAttributes: string[] = []

  widget.settings.kpi_options.forEach((kpi) => {
    allowedFilterAttributes = allowedFilterAttributes
      .concat(kpi.allowed_filter_attributes)
      .concat(
        Object.values(widget.settings.kpi_options[0].attribute_options).map(
          (ao) => ao.relation_key
        )
      )
  })

  const dashboardFilterParams = dashboardFilter
    .filter(
      (filter) =>
        ignoreValidateFilterAttributes ||
        allowedFilterAttributes.includes(filter.relation_key)
    )
    .map((filter) => ({
      attributeId: filter.relation_key,
      condition: Condition.EQ,
      value: [
        typeof filter.value === 'string' &&
        filter.value.includes('Saknar värde')
          ? null
          : filter.value
      ],
      type: FilterType.INPUT_VALUE
    }))

  if (showAs) {
    dashboardFilterParams.push({
      attributeId: DIM_COMPANY_GROUP_ID_ATTRIBUTE_ID,
      condition: Condition.EQ,
      value: [showAs],
      type: FilterType.INPUT_VALUE
    })
  }

  if (dashboardFilterParams.length === 0) {
    return null
  }

  return { filter: dashboardFilterParams }
}

export const getDashboardFilterToQueryParams = (
  dashboardFilter: DashboardFilter,
  widget: WidgetObject,
  showAs: string | null,
  ignoreValidateFilterAttributes = false
): { filter: FilterBody[] } | null => {
  if (dashboardFilter.length === 0 && !showAs) {
    return null
  }

  const dashboardFilterParams = parseFilterQueryParams(
    dashboardFilter,
    widget,
    showAs,
    ignoreValidateFilterAttributes
  )

  return { filter: dashboardFilterParams?.filter || [] }
}

export function getOwner(
  accounts: AccountBody,
  ownerId: string | null
): UserType | null {
  if (!ownerId || !accounts[ownerId]) {
    return null
  }

  return formatUser(accounts[ownerId], false, true)
}

import html2canvas from 'html2canvas'
import { IGNORE_ON_ALL_EXPORT } from 'components_new/organisms/dialogs/ExportDialog'
import {
  Dataset,
  DatasetType,
  DataType,
  DefaultDatasetData,
  FormattedWidgetData,
  FormattedWidgetDataDataset,
  ListDatasetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetDataLabel
} from 'types/GlobalWidget'
import { ComparativeDisplayType, UnitPrefix } from 'types/GlobalKpiOption'
import { getParsedDate, getParsedPartOfDate } from 'utils/dateParser'

export interface LayoutItem {
  x: number
  y: number
  w: number
  h: number
  i: string
}

/**
 * Returns an object with all the strings that where in all of
 * the lists.
 *
 * @param lists A list of lists with string
 * @returns An object containing the strings that where in all of the lists.
 */
export function itemsIncludedInAllLists(lists: string[][]) {
  const memo: { [item: string]: number } = {}
  const nrOfLists = lists.length

  lists.forEach((list) => {
    list.forEach((item) => {
      if (item in memo) {
        memo[item] += 1
      } else {
        memo[item] = 1
      }
    })
  })

  const returnObj: { [item: string]: string } = {}

  Object.keys(memo).forEach((item) => {
    if (memo[item] === nrOfLists) {
      returnObj[item] = item
    }
  })

  return returnObj
}

export function findAvailableSpace(items: LayoutItem[]): LayoutItem | false {
  const gridSize = 12

  for (let width = 3; width >= 1; width--) {
    for (let height = 3; height >= 1; height--) {
      for (let x = 0; x <= gridSize - width; x++) {
        for (let y = 0; y <= gridSize - height; y++) {
          const potentialSpace: LayoutItem = {
            x,
            y,
            w: width,
            h: height,
            i: 'TEMP_ID'
          }
          const isAvailable = isSpaceAvailable(items, potentialSpace)

          if (isAvailable) {
            return potentialSpace
          }
        }
      }
    }
  }

  return false
}

function isSpaceAvailable(items: LayoutItem[], item: LayoutItem): boolean {
  for (const existingItem of items) {
    const isOverlap =
      item.x < existingItem.x + existingItem.w &&
      item.x + item.w > existingItem.x &&
      item.y < existingItem.y + existingItem.h &&
      item.y + item.h > existingItem.y

    if (isOverlap) {
      return false
    }
  }

  return true
}

export function formatDataset(
  dataset: Dataset,
  widget: WidgetObject,
  topNLimit: number | null
): FormattedWidgetDataDataset {
  const kpi = widget.settings.kpi_options.find(
    (kpi) => kpi.id === dataset.kpi_option_id
  )
  const tag = widget.settings.tags.selected.find(
    (t) => t.tag_option_id === dataset.tag_option_id
  )

  const id = kpi ? kpi.id : (tag?.id as string)
  const settingIndex = kpi ? kpi.index : (tag?.index as number)

  const comparativeDisplayTypeSelected =
    kpi?.comparative_display_type.selected || null
  const equivalentFactor = kpi?.equivalent.factor || null
  const equivalentUnit = kpi?.equivalent.unit || null
  const numberOfDecimals = kpi?.number_of_decimals || 0
  const hidden = kpi?.hidden || false
  const increaseIsPositive = kpi?.increase_is_positive ?? true
  const lowerBoundThreshold = kpi?.lower_bound_threshold ?? null
  const unit = dataset.unit
  const unitPrefix = kpi?.unit_prefix || {
    selected: null,
    options: []
  }
  const upperBoundThreshold = kpi?.upper_bound_threshold ?? null

  let totalValue = null

  if (widget.data?.totals && dataset.kpi_option_id) {
    const total =
      widget.data.totals.find(
        (t) =>
          t.kpi_option_id === dataset.kpi_option_id && t.label === dataset.label
      ) || null

    totalValue = total
      ? (formatValue(
          total.data.value,
          dataset.type,
          unitPrefix.selected,
          numberOfDecimals,
          true,
          equivalentFactor
        ) as string | number | null)
      : 0
  }

  return {
    id,
    breakdownLabel: dataset.breakdown_label,
    data: filterTopN(topNLimit, dataset.data).map((value) =>
      formatValue(
        value,
        dataset.type,
        unitPrefix.selected,
        numberOfDecimals,
        false,
        equivalentFactor
      )
    ) as DefaultDatasetData | ListDatasetData,
    prettyData: filterTopN(topNLimit, dataset.data).map((value) =>
      formatValue(
        value,
        dataset.type,
        unitPrefix.selected,
        numberOfDecimals,
        true,
        equivalentFactor
      )
    ),
    percentages: dataset.percentages
      ? filterTopN(topNLimit, dataset.percentages).map(
        (value) =>
            formatValue(value, dataset.type, null, 0, false, null) as
              | number
              | null
      )
      : undefined,
    comparativeDisplayTypeSelected,
    numberOfDecimals,
    hidden,
    increaseIsPositive,
    index: dataset.index,
    settingIndex,
    isComparativeData: dataset.is_comparative_data,
    kpiOptionId: kpi?.id,
    label: dataset.label,
    lowerBoundThreshold,
    showComparativeValue:
      !!widget.settings.comparative_period.selected.value &&
      !!kpi &&
      kpi.comparative_display_type.selected === ComparativeDisplayType.VALUE,
    total: totalValue,
    type: dataset.type,
    unit:
      dataset.type === DatasetType.NUMBER
        ? getUnitLabel(unit, unitPrefix, equivalentUnit)
        : dataset.unit,
    upperBoundThreshold
  }
}

function formatDatasets(
  data: Dataset[],
  widget: WidgetObject,
  topNLimit: number | null
) {
  return data
    .filter(
      (dataset) =>
        widget.settings.kpi_options.find(
          (kpi) => kpi.id === dataset.kpi_option_id
        ) ||
        widget.settings.tags.selected.find(
          (t) => t.tag_option_id === dataset.tag_option_id
        )
    )
    .map((dataset) => formatDataset(dataset, widget, topNLimit))
}

export async function downloadPNG(
  element: HTMLElement,
  callback: (img: Blob | null) => void
) {
  const canvas = await html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    logging: false,
    ignoreElements: (element) => {
      return element.id === IGNORE_ON_ALL_EXPORT
    }
  })

  canvas.toBlob(callback)
}

export function formatWidgetData(widget: WidgetObject): FormattedWidgetData {
  if (
    widget.data &&
    widget.settings.type.selected !== WidgetType.KEY_FIGURE &&
    widget.settings.type.selected !== WidgetType.FUNNEL
  ) {
    const topNLimit = widget.settings.top_n_limit
    let topLevelCounter = 0

    // find index of toplevel label
    const labelIndex =
      topNLimit !== null
        ? widget.data.labels.findIndex((item) => {
          if (item.path.length === 0) {
            topLevelCounter += 1

            return topLevelCounter === topNLimit + 1
          }

          return false
        })
        : null

    const limit = labelIndex === -1 ? null : labelIndex

    const labels: WidgetDataLabel[] = filterTopN(limit, widget.data.labels)
    const links: (string | null)[] = filterTopN(
      limit,
      widget.data.external_links
    )
    const coordinates = filterTopN(limit, widget.data.coordinates)

    return {
      coordinates,
      labels: labels.map(parseWidgetDataLabel),
      links: links,
      datasets: formatDatasets(widget.data.data, widget, limit)
    }
  }

  return {
    coordinates: [],
    labels: [],
    links: [],
    datasets: []
  }
}

const getPrefixValue = (
  value: number | null,
  unitPrefix: UnitPrefix | null,
  equivalentFactor?: number | null
) => {
  if (value === null) {
    return null
  }

  let divideBy = 1

  switch (unitPrefix) {
  case UnitPrefix.THOUSAND:
    divideBy = 1000
    break
  case UnitPrefix.MILLION:
    divideBy = 1000000
    break
  case UnitPrefix.BILLION:
    divideBy = 1000000000
    break
  default:
    break
  }

  const newValue = value / divideBy

  if (equivalentFactor) {
    return newValue * equivalentFactor
  } else {
    return newValue
  }
}

export const parseLabel = (dataLabel: {
  label: string | number | null
  technical_name: string
  type: DataType
}): string => {
  if (emptyCheck(dataLabel.label)) {
    return 'Saknar värde'
  }

  if (dataLabel.type === DataType.DATE) {
    const isNotISODate =
      dataLabel.technical_name &&
      ['month', 'weekday', 'year', 'year_quarter'].includes(
        dataLabel.technical_name
      )

    return isNotISODate
      ? getParsedPartOfDate(dataLabel.label as number, dataLabel.technical_name)
      : getParsedDate(dataLabel.label as string | null)
  }

  return dataLabel.label ? dataLabel.label.toString() : ''
}

export const parseWidgetDataLabel = (
  dataLabel: WidgetDataLabel
): ParsedWidgetDataLabel => {
  const parsedPath: ParsedSegmentPath[] = dataLabel.path.map((path) => ({
    ...path,
    display_label: parseLabel(path)
  }))

  return {
    ...dataLabel,
    display_label: parseLabel(dataLabel),
    path: parsedPath
  }
}

const emptyCheck = (value: string | number | null | undefined) => {
  return value == null || value === 'null' || value === ''
}

const formatValue = (
  value: number | string[] | number[] | null,
  datasetType: DatasetType,
  unitPrefix: UnitPrefix | null,
  numberOfDecimals: number,
  toLocalString = true,
  equivalentFactor?: number | null
): string | number | string[] | number[] | null => {
  if (datasetType !== DatasetType.NUMBER) {
    // no parsing for non number dataset types.
    return value
  }

  const prefixValue: number | null = getPrefixValue(
    value as number | null,
    unitPrefix,
    equivalentFactor
  )

  if (prefixValue !== null) {
    if (toLocalString) {
      return prefixValue.toLocaleString('sv-SE', {
        maximumFractionDigits: numberOfDecimals
      })
    }

    return parseFloat(prefixValue.toFixed(numberOfDecimals))
  }

  return null
}

const filterTopN = (topNLimit: number | null, data: any[]): any[] => {
  if (topNLimit) {
    return data.slice(0, topNLimit)
  }

  return data
}

const getUnitLabel = (
  unit: string | null,
  unitPrefix: {
    selected: UnitPrefix | null
    options: { value: UnitPrefix; label: string }[]
  },
  equivalentUnit?: string | null
): string => {
  if (unit === '%') {
    return unit
  }

  if (equivalentUnit || equivalentUnit === '') {
    return equivalentUnit
  }

  let parsedUnit = ''

  if (unitPrefix.selected) {
    parsedUnit =
      unitPrefix.options.find((item) => item.value === unitPrefix.selected)
        ?.label || ''
  }

  if (unit) {
    parsedUnit = parsedUnit + unit
  }

  return parsedUnit
}

export function convertWidgetDataToExport(
  widget: WidgetObject,
  formattedData: FormattedWidgetData
) {
  const { labels, datasets } = formattedData
  let segmentByDisplayHeader = widget.settings.segment_by.display_name

  if (!segmentByDisplayHeader) {
    segmentByDisplayHeader =
      widget.settings.segment_by.options.find(
        (op) => op.id === widget.settings.segment_by.selected
      )?.name || ''
  }

  const segmentByHeader =
    widget.settings.type.selected === WidgetType.LINE_CHART
      ? 'Datum'
      : segmentByDisplayHeader

  if (segmentByHeader) {
    const headers = [
      segmentByHeader,
      ...datasets.map((dataset) => {
        const label = dataset.label
        const unit = dataset.unit ? ` (${dataset.unit})` : ''

        return label + unit
      })
    ]

    const rows: { [key: string]: string | number }[] = []

    labels.forEach((label, index) => {
      const currentRow: { [key: string]: string | number } = {
        [segmentByHeader]: label.display_label
      }

      datasets.forEach((dataset) => {
        const value = dataset.data[index]

        currentRow[dataset.label] = Array.isArray(value)
          ? value.join(',')
          : value ?? 0
      })

      rows.push(currentRow)
    })

    return { rows, headers }
  }

  return { rows: [], headers: [] }
}
