import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'
import { VitecView } from 'types/GlobalVitecView'

export interface VitecViewReducerType {
  data: VitecView[] // TODO
  fetching: boolean
}

const INITIAL_STATE: VitecViewReducerType = {
  data: [],
  fetching: false
}

const getVitecViewsAction = createAction(Types.GET_VITEC_VIEWS)
const getVitecViewsSuccessAction = createAction<{ data: VitecView[] }>(
  Types.GET_VITEC_VIEWS_SUCCESS
)
const createVitecViewSuccessAction = createAction<{ data: VitecView }>(
  Types.CREATE_VITEC_VIEW_SUCCESS
)
const deleteVitecViewSuccessAction = createAction<string>(
  Types.DELETE_VITEC_VIEW_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

const snackbarReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getVitecViewsAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getVitecViewsSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: payload.data.sort((a, b) =>
          a.attributes.output_name.localeCompare(b.attributes.output_name, 'sv')
        ),
        fetching: false
      }
    })
    .addCase(createVitecViewSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: [...state.data, payload.data].sort((a, b) =>
          a.attributes.output_name.localeCompare(b.attributes.output_name, 'sv')
        )
      }
    })
    .addCase(deleteVitecViewSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload)
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default snackbarReducer
