import React, { useState } from 'react'

import ShortId from 'shortid'

import Box from 'components_new/atoms/Box'

import Text from 'components_new/atoms/Text'

const PageToggle = ({ items, active }) => {
  const [shortid] = useState(ShortId.generate())

  return (
    <Box
      sx={{
        display: 'flex',
        mr: 1
      }}
    >
      {items.map((item, i) => (
        <Box
          key={`${shortid}-${i}`}
          onClick={() => item.onClick()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            px: 2,
            color: active === item.value ? 'primary.main' : 'text.secondary',
            bgcolor: active === item.value ? 'primary.transparent' : null,
            cursor: 'pointer'
          }}
        >
          <Text color="inherit" variant="subtitle2">
            {item.label}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default PageToggle
