import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'

export interface SnackbarType {
  open: boolean
  message: string | null
  duration?: number
  link?: () => void
  linkText?: string
}

const INITIAL_STATE: SnackbarType = {
  open: false,
  message: null,
  duration: undefined,
  link: undefined
}

const toggleSnackbarOpenAction = createAction<{
  message: string
  duration?: number
  link?: () => void
  linkText?: string
    }>(Types.TOGGLE_SNACKBAR_OPEN)
const toggleSnackbarCloseAction = createAction(Types.TOGGLE_SNACKBAR_CLOSED)
const signOutAction = createAction(Types.SIGN_OUT)

const snackbarReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(toggleSnackbarOpenAction, (state, action) => {
      const { payload } = action

      return {
        open: true,
        message: payload.message,
        duration: payload.duration,
        link: payload.link,
        linkText: payload.linkText
      }
    })
    .addCase(toggleSnackbarCloseAction, () => INITIAL_STATE)
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default snackbarReducer
