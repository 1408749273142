import React from 'react'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  CustomPeriodFilter,
  FormattedWidgetData,
  PeriodFilter,
  ParsedSegmentPath,
  TViewport,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import { ExploreDialogType } from '../dialogs/ExploreDialog'
import LoadingSection from 'components_new/molecules/LoadingSection'
import Broken from '../errors/Broken'
import NoData from '../errors/NoData'
import NoTarget from '../errors/NoTarget'
import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import BarChart from '../types/BarChart'
import BubbleChart from '../types/BubbleChart'
import KeyFigure from '../types/KeyFigure'
import Image from '../types/Image'
import ComboChart from '../types/ComboChart'
import LineChart from '../types/LineChart'
import Table from '../types/Table'
import Map from '../types/Map'
import Funnel from '../types/Funnel'
import PieChart from '../types/PieChart'
import {
  getDashboardFilterOptions,
  getExploreOnClick,
  getExternalLinkOptions,
  getShowDetailsOptions
} from './utils'
import { getIsComparativeButIllegal } from './utils'
import Box from 'components_new/atoms/Box'

interface WidgetBodyProps {
  dashboardFilter: DashboardFilter
  fullSize: boolean
  embedded: boolean
  fakeLoading: boolean
  formattedData: FormattedWidgetData
  isAdmin: boolean
  loading: boolean
  periodFilter: PeriodFilter | null
  scaleFactor: number
  setEdit: (arg0: boolean) => void
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setDetailsDialog: (arg0: boolean, arg1: ParsedSegmentPath[]) => void
  setExploreDialog: React.Dispatch<React.SetStateAction<ExploreDialogType>>
  setViewport: (value: TViewport) => void
  standaloneWidget: boolean
  viewport: TViewport
  widget: WidgetObject
}

const WidgetBody = (props: WidgetBodyProps) => {
  const {
    dashboardFilter,
    embedded,
    fakeLoading,
    formattedData,
    fullSize,
    isAdmin,
    loading,
    periodFilter,
    scaleFactor,
    setEdit,
    setDashboardFilter,
    setDetailsDialog,
    setExploreDialog,
    setViewport,
    standaloneWidget,
    viewport,
    widget
  } = props

  const type = widget.settings.type.selected

  if (
    type !== WidgetType.TEXT &&
    type !== WidgetType.COMMENT &&
    type !== WidgetType.IMAGE
  ) {
    if (loading || fakeLoading) {
      return (
        <LoadingSection
          titles={LOADING_WIDGET_TITLES}
          loading={loading || fakeLoading}
          scaleFactor={scaleFactor}
        />
      )
    }

    if (widget.status.broken) {
      return <Broken scaleFactor={scaleFactor} />
    }

    if (widget.status.required_data_modelling_missing) {
      return <NoData embedded={embedded} scaleFactor={scaleFactor} />
    }

    if (widget.status.required_target_missing) {
      return (
        <NoTarget
          embedded={embedded}
          isAdmin={isAdmin}
          scaleFactor={scaleFactor}
        />
      )
    }
  }

  const isComparativeButIllegal = getIsComparativeButIllegal(
    periodFilter ? periodFilter : widget.settings.date_filter.selected?.value,
    widget.settings.comparative_period.selected?.value,
    widget.settings.date_filter.type
  )

  const getWidgetType = () => {
    switch (widget.settings.type.selected) {
    case WidgetType.BAR_CHART:
      return (
        <BarChart
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          onClick={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded)
          ]}
          onClickValue={getExploreOnClick(widget, setExploreDialog, embedded)}
          scaleFactor={scaleFactor}
          widget={widget}
        />
      )
    case WidgetType.BUBBLE_CHART:
      return (
        <BubbleChart
          formattedData={formattedData}
          onClick={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded)
          ]}
          scaleFactor={scaleFactor}
          widget={widget}
        />
      )
    case WidgetType.KEY_FIGURE:
      return <KeyFigure scaleFactor={scaleFactor} widget={widget} />
    case WidgetType.COMMENT:
      return null
    case WidgetType.TEXT:
      return null
    case WidgetType.IMAGE:
      return <Image widget={widget} setEdit={setEdit} />
    case WidgetType.COMBO_CHART:
      return (
        <ComboChart
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          onClick={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded)
          ]}
          onClickValue={getExploreOnClick(widget, setExploreDialog, embedded)}
          scaleFactor={scaleFactor}
          widget={widget}
        />
      )
    case WidgetType.LINE_CHART:
      return (
        <LineChart
          formattedData={formattedData}
          onClick={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded)
          ]}
          onClickValue={getExploreOnClick(widget, setExploreDialog, embedded)}
          scaleFactor={scaleFactor}
          widget={widget}
        />
      )
    case WidgetType.LIST:
      return (
        <Table
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          onClickSegment={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded),
            ...getExternalLinkOptions(widget, embedded)
          ]}
          onClickValue={getExploreOnClick(widget, setExploreDialog, embedded)}
          scaleFactor={scaleFactor}
          widget={widget}
        />
      )
    case WidgetType.MAP:
      return (
        <Map
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          onClickSegment={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded),
            ...getExternalLinkOptions(widget, embedded)
          ]}
          scaleFactor={scaleFactor}
          setViewport={setViewport}
          viewport={viewport}
        />
      )
    case WidgetType.FUNNEL:
      return (
        <Funnel
          clipContent={fullSize}
          embedded={embedded}
          scaleFactor={scaleFactor}
          setDetailsDialog={(segments) => setDetailsDialog(true, segments)}
          widget={widget}
        />
      )
    case WidgetType.PIE_CHART:
      return (
        <PieChart
          formattedData={formattedData}
          onClick={[
            ...getDashboardFilterOptions(
              widget,
              setDashboardFilter,
              dashboardFilter,
              standaloneWidget
            ),
            ...getShowDetailsOptions(widget, setDetailsDialog, embedded)
          ]}
          onClickValue={getExploreOnClick(widget, setExploreDialog, embedded)}
          scaleFactor={scaleFactor}
        />
      )
    default:
      return null
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        userSelect: !fullSize ? 'none' : undefined,
        cursor: !fullSize ? 'default' : undefined
      }}
    >
      {getWidgetType()}
    </Box>
  )
}

export default WidgetBody
