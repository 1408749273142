import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ProductTag from 'components/common/ProductTag'
import InputCascader from 'components/containers/InputCascader'

import Box from 'components_new/atoms/Box'

import Node from './index.js'

import * as Conf from './conf.js'
import Styles from './styles.module.css'

const InputNode = ({
  id,
  DatasetStore,
  data,
  selected,
  setData,
  showOntology
}) => {
  const [cascader, setCascader] = useState(
    data.productTag
      ? Conf.parseInitiationCascader(
        data.productTag,
        data.refTableId,
        DatasetStore
      )
      : []
  )

  return (
    <Node
      icon="LoginOutlined"
      title={'Input'}
      hasInput={false}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box sx={{ width: '172px' }}>
        <InputCascader
          className={'nodrag'}
          ignoreLockedMode
          onlyTables
          value={cascader}
          onChange={(val) => {
            setCascader(val)
            const extraUpdate = Conf.findAffected(data?.globalNodeData, id, {})

            setData(
              id,
              {
                refTableId: val ? val[val.length - 1] : undefined,
                productTag: val ? val[0] : undefined,
                outputAttributes: Conf.parseOutputAttributes(
                  val,
                  DatasetStore,
                  id
                )
              },
              extraUpdate
            )
          }}
          size={'small'}
          onlyShowLastValue
          dropdownClassName={Styles['input-dropdown']}
          showOntology={showOntology}
        />
        {data.productTag ? (
          <ProductTag
            className={`${Styles['input-margin-top']} ${Styles['node-padding']}`}
            product={data.productTag}
            path={Conf.parseCascader(cascader, DatasetStore)}
            smallTag={false}
          />
        ) : null}
      </Box>
    </Node>
  )
}

function mapStateToProps({ DatasetStore }) {
  return { DatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InputNode)
