import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { categories, roles } from './utils'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

interface RoleViewProps {
  customCategory: string
  customRole: string
  selectedRoles: Option[]
  selectedCategories: Option[]
  setCustomCategory: (value: string) => void
  setCustomRole: (value: string) => void
  setSelectedCategories: (value: Option[]) => void
  setSelectedRoles: (value: Option[]) => void
}

const RoleView = (props: RoleViewProps) => {
  const {
    customCategory,
    customRole,
    selectedCategories,
    selectedRoles,
    setCustomCategory,
    setCustomRole,
    setSelectedCategories,
    setSelectedRoles
  } = props

  const [showCustomCategory, setShowCustomCategory] = useState<boolean>(false)
  const [showCustomRole, setShowCustomRole] = useState<boolean>(false)

  const handleToggleCustomRole = () => {
    setShowCustomRole(!showCustomRole)

    if (showCustomRole) {
      setCustomRole('')
    } else {
      setSelectedRoles([])
    }
  }

  const handleToggleCustomCategory = () => {
    setShowCustomCategory(!showCustomCategory)

    if (showCustomCategory) {
      setCustomCategory('')
    } else {
      setSelectedCategories([])
    }
  }

  return (
    <>
      <Box>
        <Text variant="subtitle1" gutterBottom={true}>
          Vad arbetar du som?
        </Text>
        {showCustomRole ? (
          <TextField
            autoFocus={true}
            onChange={(e) => setCustomRole(e.target.value)}
            value={customRole}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Stäng fritextfält">
                    <IconButton onClick={handleToggleCustomRole}>
                      <Icon name="CloseOutlined" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            placeholder="Skriv din roll..."
            sx={{ mt: 0 }}
          />
        ) : (
          <SelectSearch
            onChange={(options: Option[]) => setSelectedRoles(options)}
            options={roles
              .sort((a, b) => sortAlphabeticalAsc(a, b))
              .map((role) => {
                return { id: role, label: role }
              })}
            placeholder="Välj en eller flera roller..."
            selected={selectedRoles}
            size="medium"
            lastOption={<CustomOption onClick={handleToggleCustomRole} />}
          />
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        <Text variant="subtitle1" gutterBottom={true}>
          Vad är du intresserad av att följa upp?
        </Text>
        {showCustomCategory ? (
          <TextField
            autoFocus={true}
            onChange={(e) => setCustomCategory(e.target.value)}
            value={customCategory}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Stäng fritextfält">
                    <IconButton onClick={handleToggleCustomCategory}>
                      <Icon name="CloseOutlined" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            placeholder="Skriv ett område..."
            sx={{ mt: 0 }}
          />
        ) : (
          <SelectSearch
            onChange={(options: Option[]) => setSelectedCategories(options)}
            options={categories
              .sort((a, b) => sortAlphabeticalAsc(a, b))
              .map((category) => {
                return { id: category, label: category }
              })}
            placeholder="Välj ett eller flera områden..."
            selected={selectedCategories}
            size="medium"
            lastOption={<CustomOption onClick={handleToggleCustomCategory} />}
          />
        )}
      </Box>
    </>
  )
}

const CustomOption = (props: { onClick: () => void }) => {
  return (
    <MenuItem
      component="li"
      onClick={props.onClick}
      sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
        color: 'text.secondary',
        py: 2,
        '&:hover': {
          color: 'text.primary'
        }
      }}
    >
      <ListItemText
        primary={
          <>
            Hittar du inte någonting som känns rätt? <b>Klicka här</b> för att
            skriva fritext.
          </>
        }
        primaryTypographyProps={{
          color: 'inherit',
          sx: { color: 'inherit' }
        }}
      />
    </MenuItem>
  )
}

export default RoleView
