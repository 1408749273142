import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import SidebarDialog from 'components_new/molecules/SidebarDialog'

import DisabledWrapper from 'components_new/organisms/dialogs/ShareDialog/EmbedSection/DisabledWrapper'
import EmbedSection from 'components_new/organisms/dialogs/ShareDialog/EmbedSection'

import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { WidgetObject } from 'types/GlobalWidget'

import Filter, {
  TFilter
} from 'components_new/organisms/dialogs/ShareDialog/EmbedSection/Filter'

const URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://portal.homepal.se'

interface EmbeddedDialogProps {
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  loading: boolean
  onClose: () => void
  onChange: (embeddable: boolean) => void
  open: boolean
  widget: WidgetObject
}

const EmbeddedDialog = (props: EmbeddedDialogProps) => {
  const { dashboardFilterOptions, onClose, onChange, open, widget } = props

  const [filter, setFilter] = useState<TFilter>({})

  useEffect(() => {
    if (!open) {
      setFilter({})
    }
  }, [open])

  return (
    <SidebarDialog
      actions={
        <Button variant="text" onClick={onClose}>
          Stäng
        </Button>
      }
      open={open}
      onClose={onClose}
      title={'Bädda in ' + widget.title}
      maxWidth="md"
      rightSidebar={
        <Box sx={{ p: 2 }}>
          <DisabledWrapper isPublic={widget.settings.embeddable}>
            <Filter
              embedType={'widget'}
              filter={filter}
              filterOptions={dashboardFilterOptions}
              setFilter={setFilter}
            />
          </DisabledWrapper>
        </Box>
      }
    >
      <EmbedSection
        allowEmbed={true}
        controlledFilter={filter}
        editable={true}
        embedType="widget"
        isPublic={widget.settings.embeddable}
        link={`${URL}/public/widgets/${widget.id}`}
        setIsPublic={(value) => onChange(value)}
        filterOptions={dashboardFilterOptions}
      />
    </SidebarDialog>
  )
}

export default EmbeddedDialog
