import React, { ReactNode } from 'react'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import { SxProps, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

interface BannerProps {
  severity: 'info' | 'error' | 'success' | 'warning'
  title: string
  body: ReactNode
  iconName?: IconNames
  href?: string | null
  linkLabel?: string | null
  sx?: SxProps
}

const Banner = React.forwardRef((props: BannerProps, ref: any) => {
  const { severity, title, body, iconName, href, linkLabel, sx } = props

  return (
    <ThemeProvider theme={getTheme('dark')}>
      <Box
        ref={ref}
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 1100,
          bgcolor: `${severity}.main`,
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2,
          ...sx
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Icon color="primary" name={iconName ?? 'InfoOutlined'} />
          <Text color="text.primary" fontWeight="bold" variant="body1">
            {title}
          </Text>
          <Text color="text.primary" variant="body1">
            {body}
          </Text>
        </Box>
        {href ? (
          <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            underline="always"
            variant="button"
          >
            {linkLabel || href}
          </Link>
        ) : undefined}
      </Box>
    </ThemeProvider>
  )
})

Banner.displayName = 'Banner'

export default Banner
