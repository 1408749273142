import { ElementType, WidgetObject } from 'types/GlobalWidget'

export type StandardizedElement = {
  id: string
  tag_option_id: string | null
  type: ElementType
  title: string
  index: number
  hidden: boolean
}

export const getElements = (widget: WidgetObject) => {
  const kpiElements = widget.settings.kpi_options
    .filter((kpi) => kpi.index !== 0) // Don't include the main KPI.
    .map((kpi) => ({
      id: kpi.id,
      tag_option_id: null,
      type: ElementType.KPI,
      title: kpi.title,
      index: kpi.index,
      hidden: kpi.hidden
    }))

  const tagElements = widget.settings.tags.selected.map((tag) => {
    return {
      id: tag.id,
      tag_option_id: tag.tag_option_id,
      type: ElementType.TAG,
      title: tag.title,
      index: tag.index,
      hidden: false
    }
  })

  const elems: StandardizedElement[] = [...kpiElements, ...tagElements]

  return elems.sort((a, b) => a.index - b.index)
}
