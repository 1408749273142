import React, { ElementType, ReactNode } from 'react'
import { Grid as MUIGrid } from '@mui/material'

interface GridProps {
  children?: ReactNode
  columns?: Array<number> | number
  columnSpacing?: Array<number | string> | number | string
  component?: ElementType<any>
  container?: boolean
  direction?:
    | 'column-reverse'
    | 'column'
    | 'row-reverse'
    | 'row'
    | Array<'column-reverse' | 'column' | 'row-reverse' | 'row'>
  item?: boolean
  lg?: 'auto' | number | boolean
  md?: 'auto' | number | boolean
  rowSpacing?: Array<number | string> | number | string
  sm?: 'auto' | number | boolean
  spacing?: Array<number | string> | number | string
  sx?: object
  wrap?: 'nowrap' | 'wrap-reverse' | 'wrap'
  xl?: 'auto' | number | boolean
  xs?: 'auto' | number | boolean
}

/**
 * The Grid component creates visual consistency
 * between layouts while allowing a responsive
 * flexibility across a wide variety of designs.
 *
 * Apart from layout, the component is useful for
 * displaying Cards (i.e. thumbnails).
 *
 * The responsive grid is based on a 12-column grid
 * layout.
 */

const Grid = React.forwardRef((props: GridProps, ref: any) => {
  return (
    <MUIGrid {...props} ref={ref}>
      {props.children}
    </MUIGrid>
  )
})

Grid.displayName = 'Grid'
export default Grid
