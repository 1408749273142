import React from 'react'

import { Progress } from 'antd'

import './styles.css'

const ProgressBar = ({
  percent,
  strokeLinecap = 'square',
  showInfo = false,
  strokeWidth,
  strokeColor = 'var(--green6)',
  trailColor = 'var(--grey2)'
}) => {
  return (
    <Progress
      percent={percent}
      strokeLinecap={strokeLinecap}
      showInfo={showInfo}
      strokeWidth={strokeWidth}
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
  )
}

export default ProgressBar
