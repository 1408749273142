import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Card from 'components_new/atoms/Card'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import TextField from 'components_new/atoms/TextField'

import OverflowText from 'components_new/molecules/OverflowText'

import * as DashboardFolderActions from 'redux/actions/DashboardFolders'

import {
  DashboardFolder,
  DashboardFolderPatchBody
} from 'types/GlobalDashboardFolder'

/**
 * The DashboardFolderThumbnail component is used
 * to display a short summary of a dashboard folder
 * and let a user navigate to it.
 */

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...DashboardFolderActions }, dispatch)
}

interface ComponentDispatchProps {
  tryUpdateDashboardFolder(
    id: string,
    body: DashboardFolderPatchBody
  ): (dispatch: Dispatch) => void
  tryDeleteDashboardFolder(id: string): (dispatch: Dispatch) => void
}

interface ComponentOwnProps {
  folder: DashboardFolder
  userCanEditFolder: boolean
}

type ComponentProps = ComponentDispatchProps & ComponentOwnProps

const DashboardFolderThumbnail = (props: ComponentProps) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [editNameInput, setEditNameInput] = useState(props.folder.name)

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLAnchorElement | null>(
    null
  )

  const handleOpenMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const handleDelete = () => {
    props.tryDeleteDashboardFolder(props.folder.id)

    setDeleteOpen(false)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
    setEditNameInput(props.folder.name)
  }

  return (
    <>
      <Box>
        <Card
          variant="outlined"
          sx={{
            position: 'relative',
            '.MuiCardActionArea-focusHighlight': {
              background: 'transparent'
            },
            '&:hover': {
              borderColor: 'primary.main',
              borderWidth: '1px'
            },
            bgcolor: 'grey.100'
          }}
        >
          <CardActionArea
            component={Link}
            to={`/dashboards/folders/${props.folder.id}`}
          >
            <Box
              sx={{
                py: 1,
                px: 1.5,
                flexGrow: 1,
                minWidth: 0,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Icon name="FolderOutlined" sx={{ mr: 1 }} />
                <OverflowText variant="subtitle2" color="text.primary">
                  {props.folder.name}
                </OverflowText>
              </Box>
              {props.userCanEditFolder ? (
                <IconButton
                  edge="end"
                  onClick={(e) => {
                    handleOpenMenu(e)

                    e.preventDefault()
                  }}
                  size="small"
                >
                  <Icon fontSize="small" name="MoreVertOutlined" />
                </IconButton>
              ) : null}
            </Box>
          </CardActionArea>
        </Card>
      </Box>

      {/*-- menu --*/}
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        disableScrollLock
        onClose={handleCloseMenu}
        onMouseDown={(e) => e.stopPropagation()}
        open={Boolean(menuAnchorEl)}
        PaperProps={{ sx: { mt: 12, width: 360 } }}
        MenuListProps={{ pointerEvents: 'auto' }}
        sx={{ width: 360 }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu()

            setEditOpen(true)
          }}
        >
          <ListItemIcon>
            <Icon name="EditOutlined" />
          </ListItemIcon>
          <ListItemText primary="Byt namn" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCloseMenu()

            setDeleteOpen(true)
          }}
        >
          <ListItemIcon>
            <Icon name="DeleteOutlined" />
          </ListItemIcon>
          <ListItemText primary="Radera" />
        </MenuItem>
      </Menu>

      {/*-- dialogs --*/}
      <SimpleDialog
        title="Radera mapp?"
        contentText={
          <>
            Din mapp <code>{props.folder.name}</code> kommer att raderas
            permanent. Dashboards i mappen kommer läggas under{' '}
            <code>Saknar mapp</code>. Är du säker?
          </>
        }
        onClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        actions={
          <>
            <Button onClick={() => setDeleteOpen(false)} variant="text">
              Avbryt
            </Button>
            <Button onClick={handleDelete} color="error" variant="contained">
              Radera
            </Button>
          </>
        }
      />

      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={handleCloseEdit}>
              Avbryt
            </Button>
            <Button
              disabled={editNameInput.length === 0}
              variant="contained"
              onClick={() => {
                props.tryUpdateDashboardFolder(props.folder.id, {
                  data: { name: editNameInput }
                })

                setEditOpen(false)
              }}
            >
              Spara
            </Button>
          </>
        }
        onClose={handleCloseEdit}
        open={editOpen}
        title="Byt namn"
      >
        <TextField
          label="Namn"
          required={true}
          autoComplete="off"
          value={editNameInput}
          onChange={(event) => setEditNameInput(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
        />
      </SimpleDialog>
    </>
  )
}

export default connect<null, ComponentDispatchProps, ComponentOwnProps>(
  null,
  mapDispatchToProps
)(DashboardFolderThumbnail)
