import React, { ReactNode } from 'react'
import { InputLabel as MUIInputLabel } from '@mui/material'

interface InputLabelProps {
  children?: ReactNode
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  disabled?: boolean
  error?: boolean
  focused?: boolean
  id?: string
  margin?: 'dense'
  required?: boolean
  shrink?: boolean
  size?: 'normal' | 'small'
  sx?: object
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * The InputLabel is the label used in a
 * FormControl.
 *
 * It should be passed as a child to
 * FormControl.
 */

const InputLabel = React.forwardRef((props: InputLabelProps, ref: any) => (
  <MUIInputLabel {...props} ref={ref}>
    {props.children}
  </MUIInputLabel>
))

InputLabel.displayName = 'InputLabel'
export default InputLabel
