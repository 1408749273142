import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as NavigationActions from 'redux/actions/Navigation'
import * as DataProductActions from 'redux/actions/Applications/DataProducts'

import SearchInput from 'components/common/SearchInput'
import Table from 'components/common/Table'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import NavigationHeader from 'components_new/molecules/NavigationHeader'

import PageContainer from 'components_new/organisms/PageContainer'

import CreateDataProduct from 'components/containers/Applications/DataProducts/modals/CreateDataProduct'

import * as Conf from './conf.js'

const Overview = ({
  AuthStore,
  AppsDataProductStore,
  UserStore,
  history,
  setNavigation,
  tryCreateDataproduct
}) => {
  const [search, setSearch] = useState(undefined)
  const [page, setPage] = useState(1)
  const [apis, setApis] = useState(Object.values(AppsDataProductStore.data))
  const [createApi, setCreateApi] = useState(false)

  const count = apis.length
  const upperLimit =
    count < page * Conf.PAGE_LIMIT ? count : page * Conf.PAGE_LIMIT

  useEffect(() => {
    setNavigation({
      paths: [],
      labels: []
    })
  }, [])

  useEffect(() => {
    if (AppsDataProductStore.data) {
      setApis(Object.values(AppsDataProductStore.data))
    }
  }, [AppsDataProductStore.data])

  return (
    <PageContainer navHeader={<NavigationHeader title="API" />}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box>
          <Box sx={{ p: 2 }}>
            <SearchInput
              inputValue={search}
              onInputChange={(value) => setSearch(value)}
              onSearch={() =>
                setApis(
                  Object.values(AppsDataProductStore.data).filter((dp) =>
                    dp.attributes.name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                )
              }
              placeholder={'Sök bland delningar'}
            />
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  mb: 1
                }}
              >
                <Text color="text.secondary" variant="caption">
                  <b>
                    {count === 0 ? 0 : Conf.PAGE_LIMIT * (page - 1) + 1}-
                    {upperLimit}
                  </b>{' '}
                  av <b>{count}</b> delningar
                </Text>

                <Button
                  onClick={() => setCreateApi(true)}
                  startIcon={<Icon name="AddCircleOutlineOutlined" />}
                >
                  Skapa delning
                </Button>
              </Box>
              <Table
                size={'middle'}
                headers={Conf.TABLE_HEADERS(AuthStore?.user)}
                rows={Conf.getRows(apis, UserStore)}
                parsedFormat
                onClick={(id) => history.push(`/access-hub/${id}/connection`)}
                pagination={{
                  position: 'bottomRight',
                  pageSize: Conf.PAGE_LIMIT,
                  onChange: (p) => setPage(p),
                  total: count
                }}
              />
            </Paper>
          </Box>

          <CreateDataProduct
            visible={createApi}
            onClose={() => setCreateApi(false)}
            tryCreateDataproduct={(values) => {
              tryCreateDataproduct(values, (id) => {
                setCreateApi(false)

                history.push(`/access-hub/${id}/connection`)
              })
            }}
            dataProducts={AppsDataProductStore.data}
            type={'API'}
          />
        </Box>
      </Box>
    </PageContainer>
  )
}

function mapStateToProps({
  AuthStore,
  AppsDataProductStore,
  UserStore,
  NavigationMenuStore
}) {
  return { AuthStore, AppsDataProductStore, UserStore, NavigationMenuStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...NavigationActions, ...DataProductActions },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview))
