import React, { ElementType, ReactNode } from 'react'
import { CardActions as MuiCardActions } from '@mui/material'

interface CardActionsProps {
  children: ReactNode
  component?: ElementType<any>
  disableSpacing?: boolean
  sx?: object
}

/**
 * The CardActions component render action
 * elements at the bottom of the card.
 *
 * @requires Card component.
 */

const CardActions = React.forwardRef((props: CardActionsProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MuiCardActions {...rest} ref={ref}>
      {children}
    </MuiCardActions>
  )
})

CardActions.displayName = 'CardActions'
export default CardActions
