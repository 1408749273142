import React, { useState } from 'react'
import { Form } from 'antd'

import Table from 'components/common/Table'

import Styles from './styles.module.css'
import * as Conf from './conf'

const Columns = ({ chosenAttributes, mapping, setMapping, formRef }) => {
  const [selectedRows, setSelectedRows] = useState(
    Object.keys(chosenAttributes).filter((key) =>
      key in mapping ? mapping[key].keep : false
    )
  )

  return (
    <Form
      layout={'vertical'}
      form={formRef}
      onValuesChange={async (changedValues) => {
        const key = Object.keys(changedValues)[0]

        setMapping({
          ...mapping,
          [key]: { ...mapping[key], name: changedValues[key] }
        })
      }}
      initialValues={Conf.getInitialValues(chosenAttributes, mapping)}
      className={Styles.form}
    >
      <Table
        headers={Conf.COLUMN_OPERATION_HEADERS(formRef, mapping)}
        rows={Conf.getColumnOperationRows(chosenAttributes, mapping)}
        selectedRows={selectedRows}
        setSelectedRows={(selected) =>
          Conf.setSelectedAttributes(
            mapping,
            selected,
            setMapping,
            setSelectedRows
          )
        }
        parsedFormat
      />
    </Form>
  )
}

export default Columns
