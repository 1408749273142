import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { PatchSurveyBody, PostSurveyBody } from 'types/GlobalSurvey'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/surveys`

export async function create(body: PostSurveyBody) {
  return axios.post(URL, { data: body }, parseAuthenticationHeader())
}

export async function getAll() {
  return axios.get(URL, parseAuthenticationHeader())
}

export async function update(id: string, body: PatchSurveyBody) {
  const url = `${URL}/${id}`

  return axios.patch(url, { data: body }, parseAuthenticationHeader())
}
