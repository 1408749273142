import { ReactNode } from 'react'
import { PeriodFilter } from 'types/GlobalWidget'
import { translateDateFilter } from 'utils/enumTranslator'

import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

export type PeriodFilterOption = {
  value: PeriodFilter | null
  label: ReactNode | string
}

export const getPeriodFilterOptions = (): PeriodFilterOption[] => [
  ...Object.values(PeriodFilter)
    .filter((period) => period !== PeriodFilter.LATEST_MONTH_WITH_DATA)
    .map((value) => ({
      value: value,
      label: translateDateFilter[value]
    }))
]

/**
 * Get min date for specific dates.
 * @param lowerLimit - Optional lower limit for date.
 * @returns 1 year ago from today.
 */
export const getMinDate = (lowerLimit?: string | null): string => {
  if (lowerLimit) {
    const minDate = dayjs(lowerLimit)

    return minDate.format('YYYY-MM-DD')
  }
  const today = dayjs()

  return today.subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
}

/**
 * Get max date for specific dates.
 * @param upperLimit - Optional upper limit for date.
 * @returns End of current year.
 */
export const getMaxDate = (upperLimit?: string | null): string => {
  if (upperLimit) {
    const maxDate = dayjs(upperLimit)

    return maxDate.format('YYYY-MM-DD')
  }
  const today = dayjs()

  return today.endOf('year').format('YYYY-MM-DD')
}

/**
 * Get from and to date based on a predfined period filter.
 * @param filter - Period filter enum.
 * @returns from date and to date on YYYY-MM-DD format.
 */
export const getDatesOfPeriod = (
  filter: PeriodFilter
): { from: string | null; to: string | null } => {
  const today = dayjs()

  switch (filter) {
  case PeriodFilter.THIS_WEEK_TO_TODAY:
    return {
      from: today.startOf('week').add(1, 'day').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_MONTH_TO_TODAY:
    return {
      from: today.startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_QUARTER_TO_TODAY:
    return {
      from: today.startOf('quarter').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.THIS_YEAR_TO_TODAY:
    return {
      from: today.startOf('year').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.TODAY:
    return {
      from: today.format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.YESTERDAY:
    return {
      from: today.subtract(1, 'day').format('YYYY-MM-DD'),
      to: today.subtract(1, 'day').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_WEEK:
    return {
      from: today
        .subtract(1, 'week')
        .startOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      to: today
        .subtract(1, 'week')
        .endOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_MONTH:
    return {
      from: today.subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_QUARTER:
    return {
      from: today
        .subtract(1, 'quarter')
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      to: today.subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_YEAR:
    return {
      from: today.subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      to: today.subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_7_DAYS:
    return {
      from: today.subtract(7, 'days').format('YYYY-MM-DD'),
      to: today.subtract(1, 'days').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_30_DAYS:
    return {
      from: today.subtract(30, 'days').format('YYYY-MM-DD'),
      to: today.subtract(1, 'days').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_90_DAYS:
    return {
      from: today.subtract(90, 'days').format('YYYY-MM-DD'),
      to: today.subtract(1, 'days').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_365_DAYS:
    return {
      from: today.subtract(365, 'days').format('YYYY-MM-DD'),
      to: today.subtract(1, 'days').format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_3_MONTHS_TO_TODAY:
    return {
      from: today.subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_6_MONTHS_TO_TODAY:
    return {
      from: today.subtract(5, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  case PeriodFilter.LAST_12_MONTHS_TO_TODAY:
    return {
      from: today.subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
      to: today.format('YYYY-MM-DD')
    }
  default:
    return {
      from: null,
      to: null
    }
  }
}
