import React, { useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import OntologyContainer from 'components/containers/DataPlatform/Ontology'

import * as QualityActions from 'redux/actions/DataPlatform/dataQuality/Quality'
import * as NavigationActions from 'redux/actions/Navigation'

import InternalPage from 'components_new/templates/InternalTemplate'

const Ontology = ({
  AuthStore,
  NavigationMenuStore,
  QualityStore,
  tryGetQualityOverview,
  setNavigation
}) => {
  useEffect(() => {
    setNavigation({ paths: [], labels: [] })

    if (!QualityStore.fetched) {
      tryGetQualityOverview()
    }
  }, [])

  return (
    <InternalPage
      AuthStore={AuthStore}
      NavigationMenuStore={NavigationMenuStore}
    >
      <OntologyContainer />
    </InternalPage>
  )
}

function mapStateToProps({
  AuthStore,
  DatasetStore,
  QualityStore,
  NavigationMenuStore
}) {
  return { AuthStore, DatasetStore, QualityStore, NavigationMenuStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...QualityActions,
      ...NavigationActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Ontology))
