import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import OurCustomersTemplate from 'components_new/templates/internal/OurCustomers'

import * as CustomersAndLicensesActions from 'redux/actions/internal/OurCustomers'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  CustomersAndLicensesStore: state.CustomersAndLicensesStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ ...CustomersAndLicensesActions }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type OurCustomersPageProps = ConnectedProps<typeof connector>

const OurCustomersPage = (props: OurCustomersPageProps) => {
  const { CustomersAndLicensesStore } = props

  useEffect(() => {
    if (!CustomersAndLicensesStore.customersFetched) {
      props.tryGetCustomers()
    }
  }, [])

  return (
    <InternalTemplate>
      <OurCustomersTemplate customers={CustomersAndLicensesStore.customers} />
    </InternalTemplate>
  )
}

export default connector(OurCustomersPage)
