import React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import NavigationMenu from 'components_new/molecules/NavigationMenu'

import { getAvailableFolders, getAvailableGroups } from 'utils/functions'
import { getFolderTree, groupDashboards } from './utils'

import Loading from './loading'

const FinderNavigation = (props: ComponentProps) => {
  const {
    // redux stores
    AuthStore,
    DashboardFolderStore,
    KPIDashboardStore,
    NavigationMenuStore,
    // redux actions
    toggleNavigationFolder
  } = props

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )
  const groupedDashboards = groupDashboards(availableGroups, folders)
  const items = getFolderTree(groupedDashboards, folders)

  // loading
  if (!DashboardFolderStore.fetched) {
    return <Loading />
  }

  // success
  return (
    <NavigationMenu
      expandedFolders={NavigationMenuStore.expandedFolders}
      items={items}
      open={NavigationMenuStore.open}
      toggleFolder={(folder) => {
        toggleNavigationFolder(folder)
      }}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(FinderNavigation)
