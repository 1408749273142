import React from 'react'

import { parseDateString } from 'helpers/Functions/Date'

import DataTypeIcon from 'components/common/DataTypeIcon'
import StatusBanner from 'components/common/StatusBanner'
import Spin from 'components_new/atoms/Spin'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

export const parseBanner = (dataProduct, tryStartJobRun, datasets = []) => {
  if (datasets.length === 0) {
    return null
  }

  const state = dataProduct.attributes?.latest_run?.state
  let title = ''
  let message = ''
  let type = 'info'
  let icon = undefined
  let extraBanner = null

  if (state === 'SUCCEEDED') {
    title = `Importerad ${parseDateString(
      dataProduct.attributes.latest_run.completed_on
    )}.`
    message = 'Följande data baseras på senaste import.'

    if (datasets.length > 0) {
      const latestUpdate = datasets.sort((t1, t2) =>
        t1.updatedAt > t2.updatedAt ? -1 : 1
      )[0].updatedAt

      if (
        latestUpdate > dataProduct.attributes.latest_run.completed_on &&
        datasets.length > 0
      ) {
        extraBanner = (
          <StatusBanner
            title={'Det finns ändringar som inte har importerats.'}
            message={
              <Box
                sx={{
                  display: 'flex',
                  gap: 1
                }}
              >
                <Box>Tryck på Importera för att starta en import.</Box>
                <Button
                  color="warning"
                  onClick={() => tryStartJobRun(dataProduct.id)}
                  variant="text"
                >
                  Importera
                </Button>
              </Box>
            }
            type={'warning'}
            showIcon
          />
        )
      }
    }
  } else if (state === 'FAILED') {
    title = `Misslyckades att importera ${parseDateString(
      dataProduct.attributes.latest_run.completed_on
    )}.`
    type = 'error'
    message = (
      <Box
        sx={{
          display: 'flex',
          gap: 1
        }}
      >
        <Box>Testa att importera igen för att få den senaste datan.</Box>
        <Button
          color="error"
          onClick={() => tryStartJobRun(dataProduct.id)}
          variant="text"
        >
          Importera
        </Button>
      </Box>
    )
    icon = <Icon name="WarningAmberOutlined" color="warning" />
  } else if (!state) {
    title = 'Data har inte importerats än.'
    message = (
      <Box
        sx={{
          display: 'flex',
          gap: 1
        }}
      >
        <Box>Testa att starta en import.</Box>
        <Button
          color="warning"
          onClick={() => tryStartJobRun(dataProduct.id)}
          variant="text"
        >
          Importera
        </Button>
      </Box>
    )
    type = 'warning'
  } else {
    title = 'Data importeras just nu'
    message = `Importen startades ${parseDateString(
      dataProduct.attributes.latest_run.started_on
    )}.`
    icon = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '-3px'
        }}
      >
        <Spin />
      </Box>
    )
  }

  return (
    <>
      <StatusBanner
        title={title}
        message={message}
        type={type}
        showIcon
        icon={icon}
      />
      {extraBanner ? <Box sx={{ mt: 1 }}>{extraBanner}</Box> : null}
    </>
  )
}

export const onDeleteDataset = (setAlert, resetAlert, deleteDataset) => {
  setAlert({
    header: 'Ta bort dataset',
    color: 'warning',
    content: (
      <>
        <Text paragraph>
          När du tar bort ett dataset försvinner det permanent och kan inte
          återställas.
        </Text>
        <Text paragraph>Vill du verkligen ta bort aktuellt dataset?</Text>
        <StatusBanner
          title={'Tänk på!'}
          message={
            // eslint-disable-next-line max-len
            'Alla tjänster som använder din delade data kan sluta fungera då du tar bort det.'
          }
          type={'warning'}
          showIcon
        />
      </>
    ),
    onCancel: () => resetAlert(),
    onOk: () => {
      deleteDataset()
      resetAlert()
    },
    cancelText: 'Avbryt',
    okText: 'Ta bort'
  })
}

export const getDatasets = (tables) =>
  tables.map((table) => ({
    id: table.id,
    slug: table.included.endpoint.attributes.slug,
    name: table.attributes.plural,
    outputAttributes: table.attributes.output_attributes.map((item) => ({
      id: item.id,
      ...item.attributes
    })),
    updatedAt: table.attributes.updated_at
  }))

export const getHeaders = (dataset, transformation) => {
  if (dataset && transformation) {
    return transformation.output_attributes
      .map((ontologyAttribute, i) => ({
        ...ontologyAttribute,
        index:
          ontologyAttribute.index || ontologyAttribute.index === 0
            ? ontologyAttribute.index
            : i
      }))
      .sort((a, b) => (a.index < b.index ? -1 : 1))
      .map((ontologyAttribute) => {
        const attribute = dataset.outputAttributes.find(
          (oa) => oa.id === ontologyAttribute.output_attribute_id
        )

        return attribute
          ? {
              label: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <DataTypeIcon type={attribute.type} />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      // newAttributes references their own dataset as origin.
                      ontologyAttribute.origin_table_id !== dataset.id && (
                        <small className={'secondary tiny'}>
                          {ontologyAttribute.origin_table_name}
                        </small>
                      )
                    }
                    <small>{attribute.name}</small>
                  </Box>
                </Box>
              ),
              key: attribute.name,
              render: function renderItem(value) {
                if (attribute.type === 'BOOLEAN') {
                  return value ? 'Sant' : 'Falskt'
                }

                return value
              }
            }
          : null
      })
      .filter(Boolean)
  }

  return []
}
