import { Condition } from 'types/GlobalKpiOption'
import { FilterBody, Filters } from 'utils/types'

/**
 * Helper function to parse the API operation based on the filter condition.
 * @param condition - The filter condition.
 * @returns The corresponding API operation.
 */
function parseApiOp(condition: Condition): 'eq' | 'ne' {
  if ([Condition.IS_NOT_NULL, Condition.NE].includes(condition)) {
    return 'ne'
  }

  return 'eq'
}

/**
 * Helper function to parse the filter value based on the filter condition.
 * @param condition - The filter condition.
 * @param value - The value associated with the filter.
 * @returns The parsed filter value.
 */
function parseFilterValue(
  condition: Condition,
  value: string | null | (string | number | null)[]
): string | 'NULL' {
  if ([Condition.IS_NOT_NULL, Condition.IS_NULL].includes(condition)) {
    return 'NULL'
  }
  if (Array.isArray(value)) {
    return value
      .map((item) => (item === null ? 'null' : item.toString()))
      .join(',')
  }

  return value as string
}

export function parseFilterBody(name: string, filter: FilterBody) {
  const apiOp = parseApiOp(filter.condition)
  const value = parseFilterValue(filter.condition, filter.value)

  return `filter[${encodeURIComponent(name)}][${apiOp}]=${encodeURIComponent(
    value
  )}`
}

/**
 * Parses the filters object into a query string for API requests.
 * @param filters - The filters object to be parsed.
 * @returns The query string representing the filters.
 */
export function parseFilterParams(filters: Filters): string {
  const queryString = Object.keys(filters)
    .filter((attributeId) => filters[attributeId] !== null)
    .map((attributeId) => {
      const filter = filters[attributeId] as FilterBody

      return parseFilterBody(attributeId, filter)
    })
    .join('&')

  return queryString
}
