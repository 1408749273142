import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'

import * as Types from 'redux/Types'
import { FlawRow, SettingRow } from 'types/GlobalSimFlaw'

export interface SimFlawReducerType {
  data: FlawRow[]
  dataFetching: boolean
  settings: { [key: string]: SettingRow }
  settingsFetched: boolean
  totalCount: number
}

// Initial state
const INITIAL_STATE: SimFlawReducerType = {
  data: [],
  dataFetching: false,
  settings: {},
  settingsFetched: false,
  totalCount: 0
}

// Actions
const getSettingsSuccess = createAction<{ data: FlawRow[] }>(
  Types.INTERNAL_GET_FLAW_SETTINGS_SUCCESS
)

const getFlaws = createAction<void>(Types.INTERNAL_GET_SIM_FLAWS)

const getFlawsSuccess = createAction<{
  data: { flaws: FlawRow[]; count: number }
}>(Types.INTERNAL_GET_SIM_FLAWS_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const StatusBannerReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(
      getSettingsSuccess,
      (state: any, action: { payload: { data: FlawRow[] } }) => {
        const { payload } = action

        return {
          ...state,
          settings: arrayToObject(payload.data, 'value'),
          settingsFetched: true
        }
      }
    )
    .addCase(getFlaws, (state: any) => {
      return {
        ...state,
        data: [],
        dataFetching: true
      }
    })
    .addCase(
      getFlawsSuccess,
      (
        state: any,
        action: { payload: { data: { flaws: FlawRow[]; count: number } } }
      ) => {
        const { payload } = action

        return {
          ...state,
          data: payload.data.flaws,
          dataFetching: false,
          totalCount: payload.data.count
        }
      }
    )
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default StatusBannerReducer
