import { Category, DashboardGroup } from 'redux/reducers/Dashboards'

/**
 * Gets the distinct categories from dashboard templates.
 * @param dashboards The dashboards used for identifying distinct categories.
 * @returns An array of categories.
 */
export const getCategoryArray = (dashboards: DashboardGroup[]): Category[] => {
  return dashboards.reduce((categories: Category[], dashboard) => {
    if (
      dashboard.category.selected &&
      !categories.includes(dashboard.category.selected)
    ) {
      categories.push(dashboard.category.selected)
    }

    return categories
  }, [])
}
