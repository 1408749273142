import React, { ReactNode, useState } from 'react'

import { translateCategory } from 'utils/enumTranslator'
import { Category } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Card from 'components_new/atoms/Card'
import CardActions from 'components_new/atoms/Card/CardActions'
import CardMedia from 'components_new/atoms/Card/CardMedia'
import CardContent from 'components_new/atoms/Card/CardContent'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import OverflowText from 'components_new/molecules/OverflowText'
import UserListItem from 'components_new/molecules/UserListItem'

interface SortimentItemProps {
  body?: ReactNode
  category: Category | null
  custom?: boolean
  limitReached?: boolean
  mediaAdornments?: ReactNode
  isHomepal: boolean
  onCreate: () => void
  onOrder: () => void
  onShow: () => void
  title: string
  src: string | null
}

const SortimentItem = (props: SortimentItemProps) => {
  const {
    body,
    category,
    custom,
    isHomepal,
    limitReached = false,
    mediaAdornments,
    onCreate,
    onOrder,
    onShow,
    title,
    src
  } = props

  const [hover, setHover] = useState(false)
  const [ordered, setOrdered] = useState(false)

  const sendOrder = () => {
    onOrder()
    setOrdered(true)
  }

  return custom ? (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'paper.background'
      }}
      variant="outlined"
    >
      <Box
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{ flex: 1 }}
      >
        <CardContent>
          <Text variant="h6" color="text.primary">
            {title}
          </Text>
          <Text
            variant="body1"
            color="text.primary"
            sx={{
              mt: 1,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {body}
          </Text>
        </CardContent>
      </Box>
      <CardActions>
        <Button
          color="inherit"
          disabled={ordered}
          onClick={sendOrder}
          variant="text"
        >
          {ordered ? 'Förfrågan skickad' : 'Skicka förfrågan'}
        </Button>
      </CardActions>
    </Card>
  ) : (
    <Card
      variant="outlined"
      sx={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{ flex: 1 }}
      >
        <CardActionArea
          component="div"
          onClick={onShow}
          sx={{ height: '100%' }}
        >
          <CardMedia
            image={src ?? '/assets/media/dashboard_thumbnail.svg'}
            sx={{
              height: 0,
              paddingTop: '56.25%',
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: 'black.transparent',
              position: 'relative',
              objectFit: 'contain'
            }}
          >
            <Stack sx={{ position: 'absolute', right: 4, top: 6 }}>
              {mediaAdornments ?? null}
            </Stack>
          </CardMedia>
          <CardContent>
            <Stack>
              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}
                >
                  {category ? (
                    <Chip
                      color={'default'}
                      label={translateCategory[category].title}
                      size="small"
                    />
                  ) : null}
                  <UserListItem
                    component="div"
                    size="small"
                    title="Homepal"
                    type="homepal"
                    sx={{ p: 0, width: 'fit-content' }}
                  />
                </Box>
                <OverflowText variant="h6" color="text.primary">
                  {title}
                </OverflowText>
                <Text
                  variant="body2"
                  color="text.secondary"
                  component="div"
                  sx={{
                    mt: 1,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {body}
                </Text>
              </Box>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Box>
      <CardActions>
        {isHomepal ? (
          <Button
            disabled={limitReached}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onCreate()
            }}
          >
            Aktivera
          </Button>
        ) : (
          <Button
            disabled={ordered}
            onClick={(event) => {
              sendOrder()
              event.stopPropagation()
              event.preventDefault()
            }}
          >
            {ordered ? 'Förfrågan skickad' : 'Skicka förfrågan'}
          </Button>
        )}
        <Button
          endIcon={
            <Icon
              name="East"
              sx={{
                transition: '0.2s',
                transform: hover ? 'translateX(4px)' : undefined
              }}
            />
          }
          onClick={onShow}
          variant="text"
        >
          Läs mer
        </Button>
      </CardActions>
    </Card>
  )
}

export default SortimentItem
