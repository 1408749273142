import React, { useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface DeleteDialogProps {
  handleClose: () => void
  handleDelete: (callback: () => void) => void
  open: boolean
  title?: string
  type: string
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const { handleClose, handleDelete, open, title = '', type } = props
  const [loading, setLoading] = useState(false)

  const parsedHandleDelete = () => {
    setLoading(true)
    handleDelete(() => setLoading(false))
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        title={`Radera ${type}?`}
        open={open}
        onClose={handleClose}
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Stäng
            </Button>
            <Button
              color="error"
              loading={loading}
              onClick={parsedHandleDelete}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Din {type} <code>{title}</code> kommer att raderas permanent. Är du
            säker?
          </>
        }
      />
    </ThemeProvider>
  )
}

export default DeleteDialog
