import React, { useMemo } from 'react'
import {
  getMarkerEnd,
  useNodes,
  getSmoothStepPath,
  getBezierPath
} from 'reactflow'
import { useTheme } from '@mui/material'

import { getEdgeParams, relationCoordinates } from './utils'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

const foreignObjectSize = 18

const FloatingEdge = ({
  id,
  source,
  target,
  arrowHeadType,
  markerEndId,
  selected,
  data
}) => {
  let x1
  let y1
  const nodes = useNodes()
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

  const sourceNode = useMemo(
    () => nodes?.find((n) => n.id === source),
    [source, nodes]
  )
  const targetNode = useMemo(
    () => nodes?.find((n) => n.id === target),
    [target, nodes]
  )

  if (!sourceNode || !targetNode) {
    return null
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
    data.offset ? data.offset : 0
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty
  })

  const d = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty
  })[0]

  if (!(d.includes(edgeCenterX) || d.includes(edgeCenterY))) {
    // Q in the SVG is where the curve is
    // Parse out the x, y value for the curve point
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
    const curve = d
      .substr(d.indexOf('Q') + 2)
      .split('L')[0]
      .split(' ')[0]
      .split(',')

    x1 = curve[0]
    y1 = curve[1]
  }

  const theme = useTheme()

  const style = {
    stroke: selected ? theme.palette.grey[700] : theme.palette.grey[600],
    strokeWidth: 4
  }

  const isPrimaryStyle = {
    stroke: selected ? theme.palette.primary.dark : theme.palette.primary.main,
    strokeWidth: 4
  }

  const {
    relationX: sourceRelationX,
    relationY: sourceRelationY,
    width: sourceWidth,
    height: sourceHeight
  } = relationCoordinates(sx, sy, sourcePos)

  const {
    relationX: targetRelationX,
    relationY: targetRelationY,
    width: targetWidth,
    height: targetHeight
  } = relationCoordinates(tx, ty, targetPos)

  const cardinalities = data.type.split('_')
  const sourceCardinality = cardinalities[0]
  const targetCardinality = cardinalities[cardinalities.length - 1]

  return data.showRelations === false ? null : (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={d}
        markerEnd={markerEnd}
        style={data.isPrimary ? isPrimaryStyle : style}
      />

      {/* source handle */}
      <foreignObject
        width={sourceWidth}
        height={sourceHeight}
        x={sourceRelationX}
        y={sourceRelationY}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            cursor: 'pointer',
            bgcolor: 'background.paper',
            pl: sourcePos === 'right' ? 1 : null,
            pr: sourcePos === 'left' ? 1 : null,
            pt: sourcePos === 'bottom' ? 1 : null,
            pb: sourcePos === 'top' ? 1 : null
          }}
        >
          {sourceCardinality === 'one' ? <small>1</small> : <small>*</small>}
        </Box>
      </foreignObject>

      {/* target handle */}
      <foreignObject
        width={targetWidth}
        height={targetHeight}
        x={targetRelationX}
        y={targetRelationY}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            cursor: 'pointer',
            bgcolor: 'background.paper',
            pl: targetPos === 'right' ? 1 : null,
            pr: targetPos === 'left' ? 1 : null,
            pt: targetPos === 'bottom' ? 1 : null,
            pb: targetPos === 'top' ? 1 : null
          }}
        >
          {targetCardinality === 'one' ? <small>1</small> : <small>*</small>}
        </Box>
      </foreignObject>

      {/* link */}
      {data.onClick && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={
            !(d.includes(edgeCenterX) || d.includes(edgeCenterY))
              ? x1 - foreignObjectSize / 2
              : edgeCenterX - foreignObjectSize / 2
          }
          y={
            !(d.includes(edgeCenterX) || d.includes(edgeCenterY))
              ? y1 - foreignObjectSize / 2
              : edgeCenterY - foreignObjectSize / 2
          }
        >
          <Box
            onClick={(e) => {
              e.stopPropagation()
              data.onClick(e)
            }}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              cursor: 'pointer',
              bgcolor: 'background.paper'
            }}
          >
            <Icon name="Link" fontSize="12px" />
          </Box>
        </foreignObject>
      )}
    </>
  )
}

export default FloatingEdge
