import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  count: 0,
  fetching: false,
  fetched: false,
  error: false,
  offset: 0,
  limit: 10
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ONE_SOURCE_TABLE:
    return {
      ...state,
      fetching: true,
      fetched: false,
      error: false
    }

  case Types.UPDATE_SOURCE_COLUMN_SUCCESS:
    return {
      ...state,
      data: {
        [payload.attributes.source_table_id]: {
          ...state.data[payload.attributes.source_table_id],
          data: {
            ...state.data[payload.attributes.source_table_id].data,
            attributes: {
              ...state.data[payload.attributes.source_table_id].data
                .attributes,
              columns: state.data[
                payload.attributes.source_table_id
              ].data.attributes.columns.map((c) =>
                c.id === payload.id
                  ? { ...payload.attributes, id: payload.id }
                  : c
              )
            }
          }
        }
      }
    }

  case Types.GET_ONE_SOURCE_TABLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.data.id]: payload.data
      },
      fetching: false,
      fetched: true,
      error: false,
      limit: payload.limit,
      offset: payload.offset
    }

  case Types.GET_ONE_SOURCE_TABLE_FAILED:
    return {
      ...state,
      fetching: false,
      fetched: false,
      error: true
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
