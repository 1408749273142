import React from 'react'

import PageToggle from './PageToggle'
import IconDropdown from 'components/common/DropdownButton/IconDropdown'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

const PageHeader = ({
  header = '',
  meta = [],
  toggleItems = [],
  activeToggle,
  actions
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text color="text.primary" variant="h5">
            {header}
          </Text>
          <Box sx={{ display: 'flex', ml: 2 }}>
            <Divider flexItem orientation="vertical" />
            {meta.map((m, i) => (
              <React.Fragment key={`meta-${i}`}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Text color="text.secondary" variant="overline">
                    {m.header}
                  </Text>
                  <Text color="text.secondary" variant="body2">
                    {m.label}
                  </Text>
                </Box>
                <Divider flexItem orientation="vertical" />
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {toggleItems.length > 0 && (
            <PageToggle items={toggleItems} active={activeToggle} />
          )}
          {actions ? (
            <IconDropdown
              icon={'MoreHorizOutlined'}
              iconClassName={Styles.icon}
              items={actions}
            />
          ) : null}
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

export default PageHeader
