import React, { MouseEvent, useEffect, useState } from 'react'
import html2canvas from 'html2canvas'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Icon from 'components_new/atoms/Icon'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'

interface ExportDialogProps {
  gridRef: any | null
  onClose: () => void
  open: boolean
  title: string
}

export const IGNORE_ON_ALL_EXPORT = 'IGNORE_ON_ALL_EXPORT'
export const IGNORE_ON_TRANSPARENT_EXPORT = 'IGNORE_ON_TRANSPARENT_EXPORT'

const ExportDialog = (props: ExportDialogProps) => {
  const { gridRef, onClose, open, title } = props

  const [fileType, setFileType] = useState<'png' | 'jpeg'>('png')
  const [scale, setScale] = useState<number>(1)

  const [showDownloadMessage, setShowDownloadMessage] = useState<boolean>(false)

  const handleClose = () => {
    onClose()
  }

  const downloadImage = (element: HTMLElement) => {
    html2canvas(element, {
      allowTaint: true,
      backgroundColor: fileType === 'png' ? null : '#f8fafc', // grey.100
      useCORS: true,
      logging: false,
      scale: scale,
      ignoreElements:
        fileType === 'png'
          ? (element) => {
              return (
                element.id === IGNORE_ON_ALL_EXPORT ||
                element.id === IGNORE_ON_TRANSPARENT_EXPORT
              )
            }
          : fileType === 'jpeg'
            ? (element) => {
                return element.id === IGNORE_ON_ALL_EXPORT
              }
            : undefined
    }).then((canvas) => {
      const downloadUrl = canvas.toDataURL(`image/${fileType}`)
      const link = document.createElement('a')

      link.href = downloadUrl
      link.download = `${title}.${fileType}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setShowDownloadMessage(true)
    })
  }

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (gridRef.current) setWidth(gridRef.current.offsetWidth * scale)
    if (gridRef.current) setHeight(gridRef.current.offsetWidth * scale)
  }, [scale, gridRef.current])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <AdvancedDialog
        actions={
          <Button variant="text" onClick={handleClose}>
            Stäng
          </Button>
        }
        onClose={handleClose}
        open={open}
        title="Exportera"
      >
        <Box>
          <Text variant="subtitle2" gutterBottom>
            Variant
          </Text>
          <ToggleButton
            color={'primary'}
            exclusive={true}
            fullWidth={true}
            items={[
              {
                title: 'Transparent',
                iconName: 'PhotoLibraryOutlined',
                value: 'png'
              },
              {
                title: 'Bakgrund',
                iconName: 'PhotoLibrary',
                value: 'jpeg'
              }
            ]}
            value={fileType}
            onChange={(
              event: MouseEvent<HTMLElement>,
              value: 'png' | 'jpeg'
            ) => {
              if (!value) {
                return
              }
              setFileType(value)
            }}
          />
          <Text color="text.secondary" variant="caption">
            Format: .{fileType}
          </Text>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Text variant="subtitle2" gutterBottom>
            Kvalitet
          </Text>
          <ToggleButton
            color={'primary'}
            exclusive={true}
            fullWidth={true}
            items={[
              { title: 'Låg', value: 0.5 },
              { title: 'Mellan', value: 1 },
              { title: 'Hög', value: 2 }
            ]}
            value={scale}
            onChange={(event: MouseEvent<HTMLElement>, value: number) => {
              if (!value) {
                return
              }
              setScale(value)
            }}
          />
          <Text color="text.secondary" variant="caption">
            Upplösning: {width} x {height} pixlar
          </Text>
        </Box>
        <Button
          fullWidth={true}
          onClick={() => {
            downloadImage(gridRef.current)
          }}
          startIcon={<Icon name="FileDownload" />}
          sx={{ mt: 2 }}
        >
          Exportera
        </Button>
      </AdvancedDialog>

      {/* --- FEEDBACK --- */}
      <Portal>
        <Snackbar
          open={showDownloadMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowDownloadMessage(false)
            }
          }}
          message={`${title}.${fileType} har exporterats`}
        />
      </Portal>
    </ThemeProvider>
  )
}

export default ExportDialog
