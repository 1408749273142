import React, { useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Modal from 'components/common/Modal'
import Table from 'components/common/Table'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import * as Conf from './conf.js'

const ManageRelations = ({
  visible,
  onClose,
  shapeRelations,
  DataProductStore,
  onEdit,
  onRemove,
  onAdd,
  currentOntology
}) => {
  const [selectedRows, setSelectedRows] = useState([])

  const tables = Conf.getOntologyTables(currentOntology, DataProductStore)

  const disableEdit =
    selectedRows.length !== 1 ||
    shapeRelations.find((rel) => rel.id === selectedRows[0]).data.type ===
      'many_to_many'

  return (
    <Modal
      visible={visible}
      header={'Hantera relationer'}
      onClose={() => {
        onClose()
        setSelectedRows([])
      }}
      actions={
        <Button
          onClick={() => {
            onClose()
            setSelectedRows([])
          }}
          variant="text"
        >
          Stäng
        </Button>
      }
    >
      <Table
        parsedFormat
        headers={Conf.RELATION_HEADERS}
        rows={Conf.getRelationRows(shapeRelations, tables)}
        selectedRows={selectedRows}
        setSelectedRows={(rows) => setSelectedRows(rows)}
      />
      <Box sx={{ display: 'flex', gap: 1, p: 1 }}>
        <Button onClick={() => onAdd()}>Lägg till</Button>
        <Button
          disabled={!!disableEdit}
          onClick={() => {
            const relation = shapeRelations.find(
              (rel) => rel.id === selectedRows[0]
            )

            onEdit(relation)
            setSelectedRows([])
          }}
          variant="text"
        >
          Redigera
        </Button>
        <Button
          disabled={selectedRows.length === 0}
          onClick={() => {
            const relationIds = selectedRows.map(
              (row) => shapeRelations.find((rel) => rel.id === row).id
            )

            onRemove(relationIds, () => setSelectedRows([]))
          }}
          variant="text"
        >
          Ta bort
        </Button>
      </Box>
    </Modal>
  )
}

function mapStateToProps({ DataProductStore }) {
  return { DataProductStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageRelations)
