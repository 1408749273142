import React, { useState } from 'react'
import { cloneDeep } from 'lodash'

import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import {
  FilterConditionFormatBody,
  FilterPatchBody
} from 'types/GlobalKpiOption'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'

import FilterEditItem from '../FilterEditItem'
import Divider from 'components_new/atoms/Divider'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import {
  formatUpdateFilterBody,
  getFilterTitle,
  getFilterValue
} from '../utils'

interface FilterGroupProps {
  allFilters: FilterConditionFormatBody[] // all filters in order to add,
  // delete or edit.
  filterOptions: KpiTemplateFilterOptionsBody | null
  filters: FilterConditionFormatBody[] // filters in group
  groupId: string
  onClose: () => void
  updateFilters: (filters: FilterPatchBody[]) => void
}

const FilterGroup = (props: FilterGroupProps) => {
  const {
    allFilters,
    filterOptions,
    filters,
    groupId,
    onClose,
    updateFilters
  } = props

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: 'Filtergrupp'
    })
  }

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleDelete = () => {
    const newFilters = cloneDeep(allFilters).filter(
      (f) => f.filter_group_id !== groupId
    )

    updateFilters(formatUpdateFilterBody(newFilters))
    handleBackDrawer()
  }

  return (
    <>
      <SettingsItem onClick={handleOpenDrawer}>
        <Box>
          {filters.map((filter, i) => (
            <SettingsItem
              dividerTitle={<Chip label="eller" size="small" />}
              key={`filter-${i}`}
              title={getFilterTitle(filter)}
              value={getFilterValue(filter)}
            />
          ))}
        </Box>
      </SettingsItem>

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        {filters.map((filter, i) => (
          <>
            <FilterEditItem
              allFilters={allFilters}
              filter={filter}
              filterOptions={filterOptions}
              key={`filter-${i}`}
              noHelperText={true}
              updateFilters={updateFilters}
            />
            {i + 1 < filters.length ? (
              <Divider sx={{ mb: 2 }}>
                <Chip label="eller" size="small" />
              </Divider>
            ) : null}
          </>
        ))}

        <SettingsGroup helperText="Du kan redigera värdena för filter i en filtergrupp. Ifall du däremot vill ändra strukturen på filtergruppen behöver du radera den och skapa en ny.">
          <SettingsItem
            color="error"
            onClick={handleDelete}
            title="Radera"
            variant="action"
          />
        </SettingsGroup>
      </SettingsDrawer>
    </>
  )
}

export default FilterGroup
