import * as Api from 'redux/api/DataPlatform/cmdb/rows/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function trySaveRegisterChanges(tableId, body) {
  return (dispatch) => {
    dispatch({
      type: Types.SAVE_REGISTER_CHANGES
    })

    Api.update(tableId, body)
      .then((response) => {
        dispatch({
          type: Types.SAVE_REGISTER_CHANGES_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Alla ändringar i registret har genomförts')
      })
      .catch(() => {
        dispatch({
          type: Types.SAVE_REGISTER_CHANGES_FAILED
        })

        messageWarning('Ändringarna i registret kunde inte genomföras')
      })
  }
}

export function tryGetCmdbRows(tableId) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_CMDB_ROWS
    })

    Api.getAll(tableId)
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_CMDB_ROWS_SUCCESS,
          payload: { data: response.data.data, tableId: tableId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_CMDB_ROWS_FAILED
        })
      })
  }
}

export function tryExportData(tableId, exportType, tableName) {
  return (dispatch) => {
    dispatch({
      type: Types.EXPORT_CMDB_ROWS
    })

    Api.exportFile(tableId, exportType)
      .then((response) => {
        const fileName = `build-${tableName}-${Date.now()}.${exportType}`
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', fileName) //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.EXPORT_CMDB_ROWS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.EXPORT_CMDB_ROWS_FAILED
        })
      })
  }
}
