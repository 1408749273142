import React, { ElementType, ReactNode } from 'react'
import { TableBody as MUITableBody } from '@mui/material'

export interface TableBodyProps {
  children?: ReactNode
  component?: ElementType<any>
  sx?: object
}

/**
 * The TableBody component is a...
 */

const TableBody = React.forwardRef((props: TableBodyProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUITableBody {...rest} ref={ref}>
      {children}
    </MUITableBody>
  )
})

TableBody.displayName = 'TableBody'
export default TableBody
