import { Position } from 'reactflow'

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // eslint-disable-next-line max-len
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const w = intersectionNode.width / 2
  const h = intersectionNode.height / 2

  const x2 = intersectionNode.position.x + w
  const y2 = intersectionNode.position.y + h
  const x1 = targetNode.position.x + w
  const y1 = targetNode.position.y + h

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h)
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h)
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1))
  const xx3 = a * xx1
  const yy3 = a * yy1
  const x = w * (xx3 + yy3) + x2
  const y = h * (-xx3 + yy3) + y2

  return { x, y }
}

// returns the position (top,right,bottom or right)
// passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const nx = Math.round(node.position.x)
  const ny = Math.round(node.position.y)
  const px = Math.round(intersectionPoint.x)
  const py = Math.round(intersectionPoint.y)

  if (px <= nx + 1) {
    return Position.Left
  }
  if (px >= nx + node.width - 1) {
    return Position.Right
  }
  if (py <= ny + 1) {
    return Position.Top
  }
  if (py >= node.position.y + node.height - 1) {
    return Position.Bottom
  }

  return Position.Top
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos)
// you need to create an edge
export function getEdgeParams(source, target, offset) {
  if (!source || !target) {
    return {
      sx: 0,
      sy: 0,
      tx: 0,
      ty: 0,
      sourcePos: 0,
      targetPos: 0
    }
  }

  const sourceIntersectionPoint = getNodeIntersection(source, target)
  const targetIntersectionPoint = getNodeIntersection(target, source)

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint)
  const targetPos = getEdgePosition(target, targetIntersectionPoint)

  const sourcePoint = getPositionWithOffest(
    sourceIntersectionPoint,
    sourcePos,
    offset
  )
  const targetPoint = getPositionWithOffest(
    targetIntersectionPoint,
    targetPos,
    offset
  )

  return {
    sx: sourcePoint.x,
    sy: sourcePoint.y,
    tx: targetPoint.x,
    ty: targetPoint.y,
    sourcePos,
    targetPos
  }
}

function getPositionWithOffest(intersectionPoint, position, offset) {
  if (position === Position.Right || position === Position.Left) {
    return {
      x: intersectionPoint.x,
      y: intersectionPoint.y + offset * 32
    }
  }

  return {
    x: intersectionPoint.x + offset * 32,
    y: intersectionPoint.y
  }
}

export function relationCoordinates(x, y, pos) {
  let relationX = x - 10
  let relationY = y - 10
  let width = 16
  let height = 16

  switch (pos) {
  case 'top':
    relationX = x - 8
    relationY = y - 16
    height = 16 + 8
    width = 16
    break
  case 'right':
    relationX = x - 8
    relationY = y - 8
    height = 16
    width = 16 + 8
    break
  case 'bottom':
    relationX = x - 8
    relationY = y - 8
    height = 16 + 8
    width = 16
    break
  case 'left':
    relationX = x - 16
    relationY = y - 8
    height = 16
    width = 16 + 8
    break
  default:
    break
  }

  return { relationX, relationY, width, height }
}
