import React, { ReactNode } from 'react'

import NavigationHeader from 'components_new/molecules/NavigationHeader'
import PageContainer from 'components_new/organisms/PageContainer'

import InternalNavigation from 'components_new/organisms/InternalNavigation'

interface InternalTemplateProps {
  children?: ReactNode
  leftSidebar?: ReactNode
}

const InternalTemplate = (props: InternalTemplateProps) => {
  const { children, leftSidebar } = props

  return (
    <PageContainer
      leftSidebar={leftSidebar}
      navHeader={<NavigationHeader title="Admin" />}
      navMenu={<InternalNavigation />}
    >
      {children}
    </PageContainer>
  )
}

export default InternalTemplate
