import React, { ReactNode, ElementType } from 'react'
import { TableHead as MUITableHead } from '@mui/material'

export interface TableHeadProps {
  children?: ReactNode
  component?: ElementType<any>
  sx?: object
}

/**
 * The TableHead component is a...
 */

const TableHead = React.forwardRef((props: TableHeadProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUITableHead
      sx={{
        '& td': {
          borderBottom: 1,
          borderColor: 'divider',
          '& th': { borderBottom: 1, borderColor: 'divider' }
        }
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MUITableHead>
  )
})

TableHead.displayName = 'TableHead'
export default TableHead
