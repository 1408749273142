import React, { useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { Form } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Input from 'components/common/Input'
import Select from 'components/common/Select'
import OverlayStepper from 'components/common/OverlayStepper'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import ToggleButton from 'components_new/atoms/ToggleButton'

import * as BuildActions from 'redux/actions/DataPlatform/cmdb/Tables'

import * as Functions from 'helpers/Functions'
import { REGEX } from 'helpers/Constants'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const NewRegister = ({
  tryCreateCmdbTable,
  CmdbTableStore,
  match,
  history,
  CmdbApiStore,
  AuthStore
}) => {
  const [initialFormValues, setInitialFormValues] = useState({
    columns: [{ column_name: undefined, label: undefined, type: undefined }]
  })
  const columnForm = useRef(null)
  const nameForm = useRef(null)
  const open = match.params.createType === 'manual'

  return (
    <OverlayStepper
      open={open}
      onClose={() => history.goBack()}
      onFinish={() => {
        const formValues = nameForm.current.getFieldsValue()
        const data = {
          attributes: {
            ...formValues,
            name: formValues.technical_name,
            columns: initialFormValues.columns.map((col, index) => ({
              ...col,
              label: col.column_name,
              data_type: Conf.DATA_TYPES[col.type],
              column_index: index,
              tag_primary_key:
                formValues.primary_key_column &&
                col.column_name === formValues.primary_key_column
            }))
          }
        }

        tryCreateCmdbTable({ data })

        history.push('/data-platform/build/registers')
      }}
      title={'Build'}
      onChangePage={() =>
        setInitialFormValues({
          ...initialFormValues,
          ...(columnForm.current ? columnForm.current.getFieldsValue() : {}),
          ...(nameForm.current ? nameForm.current.getFieldsValue() : {})
        })
      }
      steps={[
        {
          title: 'Bygg register',
          description: 'Definiera kolumner',
          valid: true,
          onNext: () => Functions.validateForm(columnForm),
          content: (
            <Form
              className={'width-100'}
              layout={'vertical'}
              initialValues={initialFormValues}
              ref={columnForm}
            >
              <ColumnList />
            </Form>
          )
        },
        {
          title: 'Namnge register',
          description: 'Döp din data',
          valid: true,
          onNext: () => Functions.validateForm(nameForm),
          content: (
            <Form
              layout={'vertical'}
              initialValues={initialFormValues}
              ref={nameForm}
            >
              <NameSettings
                cmdbTables={Object.values(CmdbTableStore.data)}
                columns={
                  columnForm.current ? columnForm.current.getFieldsValue() : []
                }
                cmdbApis={Object.values(CmdbApiStore.data)}
                AuthStore={AuthStore}
              />
            </Form>
          )
        }
      ]}
    />
  )
}

const ColumnList = () => {
  return (
    <>
      <h1 className={Styles['info-title']}>Skapa kolumner</h1>
      <Form.List name="columns" className={'width-100'}>
        {(fields, { add, remove }) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {fields.map(({ name, fieldKey, restField }, index) => {
              return (
                <Box key={`col-${fieldKey}-${index}`}>
                  <ColumnCard
                    fieldLength={fields.length}
                    onRemove={() => remove(name)}
                    index={index + 1}
                    name={name}
                    fieldKey={fieldKey}
                    restField={restField}
                  />
                </Box>
              )
            })}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Form.Item>
                <Button
                  onClick={() =>
                    add({
                      label: undefined,
                      column_name: undefined,
                      type: undefined
                    })
                  }
                >
                  Lägg till kolumn
                </Button>
              </Form.Item>
            </Box>
          </Box>
        )}
      </Form.List>
    </>
  )
}

const ColumnCard = ({
  index,
  onRemove,
  fieldLength,
  restField,
  name,
  fieldKey
}) => {
  return (
    <Paper>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h4 className={'secondary'}>Kolumn {index}</h4>
        {index !== 1 || fieldLength > 1 ? (
          <h4 className={'secondary clickable'} onClick={() => onRemove()}>
            <Icon name="Clear" />
          </h4>
        ) : null}
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Form.Item
          {...restField}
          rules={[
            {
              required: true,
              message: 'Obligatoriskt fält'
            },
            ({ getFieldsValue }) => ({
              validator(_, value) {
                const sameCols = getFieldsValue().columns.filter(
                  (c) => c.column_name === value
                )

                if (sameCols.length > 1) {
                  return Promise.reject('Flera likadana namn')
                }

                return Promise.resolve()
              }
            })
          ]}
          tooltip="Det tekniska namnet kommer att användas i databasen."
          label={'Tekniskt namn'}
          name={[name, 'column_name']}
          fieldKey={[fieldKey, 'column_name']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...restField}
          rules={[{ required: true, message: 'Välj format' }]}
          tooltip="All inmatning i registeret valideras mot det valda formatet."
          label={'Format'}
          name={[name, 'type']}
          fieldKey={[fieldKey, 'type']}
        >
          <Select options={Conf.COLUMN_TYPES} />
        </Form.Item>
      </Box>
    </Paper>
  )
}

const NameSettings = ({ cmdbTables, columns, cmdbApis, AuthStore }) => {
  const [belongsToApi, setBelongsToApi] = useState(false)

  return (
    <>
      <h1 className={Styles['info-title']}>Lägg till som</h1>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Obligatoriskt fält'
          },
          () => ({
            validator(_, value) {
              const exists = cmdbTables.find(
                (t) => t.attributes.technical_name === value
              )

              if (exists) {
                return Promise.reject(
                  'Ett register med det tekniska namnet finns redan'
                )
              }

              return Promise.resolve()
            }
          })
        ]}
        name={'technical_name'}
        label={'Tekniskt namn'}
      >
        <Input />
      </Form.Item>
      <p className={`secondary ${Styles.description}`}>
        Tekniskt namn är det namn registret får och den nyckel som används då
        datan ska matchas.
      </p>
      <Divider sx={{ my: 2 }} />
      <Box>
        <h2>Ska registret tillhöra ett API?</h2>

        <ToggleButton
          items={[
            { title: 'Ja', value: true },
            { title: 'Nej', value: false }
          ]}
          onChange={(event, value) => setBelongsToApi(value)}
          size="small"
          value={belongsToApi}
        />

        {belongsToApi ? (
          <Box sx={{ mt: 2 }}>
            <Form.Item
              name={'cmdb_api_id'}
              rules={[{ required: true, message: 'Obligatoriskt fält' }]}
            >
              <Select
                label={'* API-referens'}
                labelBackground
                options={cmdbApis.map((api) => ({
                  value: api.id,
                  label: api.attributes.title
                }))}
              />
            </Form.Item>

            <Form.Item
              name={'primary_key_column'}
              rules={[{ required: true, message: 'Obligatoriskt fält' }]}
            >
              <Select
                label={'* Primary key'}
                labelBackground
                options={columns?.columns.map((col) => ({
                  label: col.column_name,
                  value: col.column_name
                }))}
              />
            </Form.Item>

            <Box sx={{ display: 'flex' }}>
              <p className={Styles['slug-prefix']}>
                {`${process.env.REACT_APP_FLOW_API}${AuthStore.customer.slug}/build/{build-api-slug}/`}
              </p>
              <Form.Item
                name={'slug'}
                rules={[
                  { required: true, message: 'Obligatoriskt fält' },
                  () => ({
                    validator(_, value) {
                      if (!value || REGEX.SLUG.test(value)) {
                        return Promise.resolve()
                      }

                      return Promise.reject('Ogiltigt format på slug')
                    }
                  })
                ]}
                className={'width-100'}
              >
                <Input
                  className={Styles['slug-input-field']}
                  size="large"
                  label={'* Slug'}
                  labelBackground
                />
              </Form.Item>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  )
}

function mapStateToProps({ CmdbTableStore, CmdbApiStore, AuthStore }) {
  return {
    CmdbTableStore,
    CmdbApiStore,
    AuthStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BuildActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewRegister))
