import React from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import NavigationItem from 'components_new/molecules/NavigationItem'

import { getNavigationItems } from './utils'

const SettingsNavigation = (props: ComponentProps) => {
  const { AuthStore, NavigationMenuStore } = props

  const navItems = getNavigationItems(AuthStore)
  const collapsed = !NavigationMenuStore.open

  return (
    <>
      {navItems.map((item) => (
        <NavigationItem
          collapsed={collapsed}
          item={{
            href: item.href,
            icon: item.icon,
            title: item.title
          }}
          key={item.href}
        />
      ))}
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const connector = connect(mapStateToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(SettingsNavigation)
