import React, { ChangeEvent, DragEvent, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

type FileType = 'image/png' | 'image/jpg' | 'image/jpeg' | 'text/csv'

interface UploadFileProps {
  accept: FileType[]
  maxSize?: number // in MB.
  sx?: object
  uploadFile: (file: File) => void
}

const UploadFile = React.forwardRef((props: UploadFileProps, ref: any) => {
  const { accept, maxSize, sx, uploadFile } = props

  const [over, setOver] = useState<boolean>(false)
  const [error, setError] = useState<boolean | string>(false)

  const validate = (
    fileType: FileType,
    size: number
  ): { valid: boolean; message: string } => {
    if (!accept.includes(fileType)) {
      return { valid: false, message: 'Filen stöds ej.' }
    }
    if (maxSize && size > maxSize * 1000000) {
      // MB to B.
      return { valid: false, message: 'Filen är för stor.' }
    }

    return { valid: true, message: '' }
  }

  const dropHandler = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    const { valid, message } = validate(file.type as FileType, file.size)

    if (valid) {
      uploadFile(file)
      setError(false)
    } else {
      setError(message)
    }
  }

  const dragOverHandler = (event: DragEvent<HTMLElement>) => {
    setOver(true)
    event.preventDefault()
  }

  const browseHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const { valid, message } = validate(file.type as FileType, file.size)

      if (valid) {
        uploadFile(file)
        setError(false)
      } else {
        setError(message)
      }
    }
  }

  return (
    <Box
      onDragOver={(event) => dragOverHandler(event)}
      onDragLeave={() => setOver(false)}
      onMouseLeave={() => setOver(false)}
      onDrop={(event) => dropHandler(event)}
      sx={{
        width: '100%',
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: over ? 'primary.main' : 'action.focus',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: over ? 'primary.transparent' : undefined,
        p: 2,
        gap: 1,
        ...sx
      }}
    >
      <Icon name="CloudUploadOutlined" fontSize="large" color="primary" />
      <Text
        variant="subtitle1"
        color={over ? 'primary.dark' : 'text.secondary'}
      >
        {over ? 'Släpp här' : 'Drag och släpp här'}
      </Text>
      <Divider flexItem={true} orientation="horizontal" textAlign="center">
        <Text variant="body1" color="text.secondary">
          eller
        </Text>
      </Divider>
      <Button ref={ref} component="label" fullWidth={true}>
        Bläddra
        <input
          type="file"
          accept={accept.join(', ')}
          onChange={(event) => browseHandler(event)}
          style={{
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1
          }}
        />
      </Button>
      {error ? (
        <Text variant="caption" color="error.dark" align="center">
          {error}
        </Text>
      ) : null}
      <Text variant="caption" color="text.secondary" align="center">
        Filer som stöds:{' '}
        {accept.map((fileType) => fileType.split('/')[1]).join(', ')}
      </Text>
      <Text variant="caption" color="text.secondary" align="center">
        Maxstorlek: {maxSize ? maxSize + ' MB' : '-'}
      </Text>
    </Box>
  )
})

UploadFile.displayName = 'UploadFile'
export default UploadFile
