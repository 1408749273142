import React from 'react'

import TableCell from 'components_new/atoms/Table/TableCell'
import TableSortLabel from 'components_new/atoms/Table/TableSortLabel'

import {
  // ellipseLabel,
  getCellAlignment,
  getCellScaleSx
} from '../utils'
import { DatasetType } from 'types/GlobalWidget'

interface HeaderCellProps {
  divider: boolean
  label: string
  scaleFactor: number
  type: DatasetType
  active: boolean
  onClick?: () => void
  order?: 'asc' | 'desc'
  // Next release:
  // numberOfCharacters?: number
}

const HeaderCell = (props: HeaderCellProps) => {
  const {
    divider,
    label,
    scaleFactor,
    type,
    active,
    onClick,
    order
    // numberOfCharacters
  } = props

  return (
    <TableCell
      align={getCellAlignment(type)}
      sx={{
        borderRight: divider ? '1px solid' : undefined,
        '&:last-child': { borderRight: 0 },
        borderRightColor: 'divider',
        whiteSpace: 'nowrap',
        width: '1%', // For auto sized, fit content cells. (Hacker).
        ...getCellScaleSx(scaleFactor)
      }}
      sortDirection={active ? order : false}
      variant="head"
    >
      {onClick ? (
        <TableSortLabel
          active={active}
          direction={order}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()

            onClick()
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          sx={{
            fontSize: 'inherit',
            '& svg': {
              fontSize: '1.25em'
            }
          }}
        >
          {label}
          {/* ellipseLabel(label, numberOfCharacters) */}
        </TableSortLabel>
      ) : (
        label
      )}
    </TableCell>
  )
}

export default HeaderCell
