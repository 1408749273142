import { UserType } from 'components_new/pages/Dashboard/utils'
import {
  AccountBody,
  AccountReducerType,
  AccountType
} from 'redux/reducers/Accounts'
import { DashboardBody } from 'redux/reducers/Dashboards'
import { AccountRole, User } from 'types/GlobalUser'

export const formatUser = (
  account: AccountType,
  allowRevoke = false,
  owner = false
): UserType => {
  return {
    accountId: account.id,
    companyGroupId: account.company_group_id,
    name:
      account.first_name || account.last_name
        ? `${account.first_name} ${account.last_name}`
        : 'Nytt konto',
    email: account.email,
    role: account.role,
    allowRevoke,
    owner
  }
}

export const getPermissions = (
  AccountStore: AccountReducerType,
  dashboardData: DashboardBody,
  id: string,
  signedInUser: User | null,
  ownerId: string | null
): UserType[] => {
  if (dashboardData[id]?.share_settings && AccountStore.data && signedInUser) {
    const owner = getOwner(AccountStore.data, ownerId)

    const admins = Object.values(AccountStore.data)
      .filter(
        (account) =>
          account.role === AccountRole.ADMIN && account.id !== ownerId
      )
      .map((account) => formatUser(account))

    const viewers = dashboardData[id]?.share_settings.permissions
      .filter(
        (permission) =>
          permission.account_id && AccountStore.data[permission.account_id]
      )
      .map((permission) => {
        const account = AccountStore.data[permission.account_id as string]

        return formatUser(account, account.id !== signedInUser.id)
      })

    return [...(owner ? [owner] : []), ...admins, ...viewers]
  }

  return []
}

export function getOwner(
  accounts: AccountBody,
  ownerId: string | null
): UserType | null {
  if (!ownerId || !accounts[ownerId]) {
    return null
  }

  return formatUser(accounts[ownerId], false, true)
}
