import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Table from 'components/common/Table'

import Header from './Header'

import * as LockedModeActions from 'redux/actions/LockedMode'
import * as RuleActions from 'redux/actions/DataPlatform/dataQuality/Rules'
import * as FormatActions from 'redux/actions/DataPlatform/dataQuality/Formats'
import * as QualityActions from 'redux/actions/DataPlatform/dataQuality/Quality'

import * as Functions from 'helpers/Functions'

import { setChangedRules } from 'helpers/Storage/DataQuality'

import * as Conf from './conf.js'

const Quality = ({
  DatasetStore,
  RuleStore,
  FormatStore,
  match,
  setLockedMode,
  tryGetAllRules,
  tryPutRules,
  tryGetAllFormats,
  history,
  QualityStore,
  tryGetQualityOverview
}) => {
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState([])
  const table = DatasetStore.data[match.params.tableId]

  useEffect(() => {
    if (!FormatStore.fetched) {
      tryGetAllFormats()
    }

    if (!RuleStore.fetched) {
      tryGetAllRules()
    }

    if (!QualityStore.fetched) {
      tryGetQualityOverview()
    }

    setInitialData()
  }, [])

  useEffect(() => {
    setInitialData()
  }, [RuleStore.fetched, FormatStore.fetched])

  const setInitialData = () => {
    if (FormatStore.fetched && RuleStore.fetched && data.length === 0) {
      const initialRows = Conf.getInitialRows(
        table.attributes.output_attributes,
        Object.values(RuleStore.data),
        Object.values(FormatStore.data)
      )

      setData(initialRows)
    }
  }

  const setEditModeLocked = (bool) => {
    setLockedMode({
      locked: bool,
      ignoreId: bool ? 'link-container' : null,
      alert: bool
        ? {
            onOk: () => {
              setEditMode(false)
            }
          }
        : {}
    })

    setEditMode(bool)
  }

  const { totalValids, total } = Functions.getTotalValidityCount(
    QualityStore.data,
    table.id
  )

  return (
    table && (
      <Box sx={{ p: 2 }}>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Header totalCount={total} validCount={totalValids} />
        </Paper>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            {editMode ? (
              <>
                <Button onClick={() => setEditModeLocked(false)} variant="text">
                  Avbryt
                </Button>
                <Button
                  onClick={() => {
                    const body = Conf.formatPut(data, match.params.tableId)

                    tryPutRules(body, match.params.tableId)
                    setEditModeLocked(false)

                    setChangedRules()
                  }}
                  startIcon={<Icon name="SaveOutlined" />}
                >
                  Spara ändringar
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setEditModeLocked(true)}
                variant="text"
                startIcon={<Icon name="EditOutlined" />}
              >
                Redigeringsläge
              </Button>
            )}
          </Box>

          <Table
            className={'ignore-locked-mode'}
            headers={Conf.TABLE_HEADERS(
              editMode,
              setData,
              match.params.tableId,
              history,
              Object.values(FormatStore.data),
              FormatStore.data,
              QualityStore.data
            )}
            rows={Object.values(data)}
            parsedFormat
            ignoreLockedMode
            ellipsis={false}
            scroll={{ x: 50 }}
          />
        </Paper>
      </Box>
    )
  )
}

function mapStateToProps({
  DatasetStore,
  RuleStore,
  FormatStore,
  QualityStore
}) {
  return {
    DatasetStore,
    RuleStore,
    FormatStore,
    QualityStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...LockedModeActions,
      ...RuleActions,
      ...FormatActions,
      ...QualityActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Quality))
