import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import OneCustomer from 'components_new/pages/internal/OneCustomer'
import OurCustomers from 'components_new/pages/internal/OurCustomers'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <OneCustomer />}
        path={'/our-customers/:id'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <OurCustomers />}
        path={'/our-customers'}
      />
      <Route path={'/our-customers/*'}>
        <Redirect to="/our-customers" />
      </Route>
    </Switch>
  )
}

export default Routes
