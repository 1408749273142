import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v3/dashboard-widgets`
const TABLE_URL = `${BASE_URL}v3/tables`

export async function putWidgets(tableId, data, query) {
  return Axios.put(
    `${TABLE_URL}/${tableId}/dashboard-widgets${query ? `?${query}` : ''}`,
    data,
    Functions.parseAuthenticationHeader()
  )
}

export async function getOne(id, query) {
  return Axios.get(
    `${URL}/${id}${query ? `?${query}` : ''}`,
    Functions.parseAuthenticationHeader()
  )
}
