import React from 'react'
import { Empty, Table as AntdTable } from 'antd'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import Styles from './styles.module.css'

import './styles.css'

const Table = (props) => {
  const {
    size = 'small',
    headers = [],
    rows = [],
    onClick,
    hidden = false,
    toggle = { items: null, onClick: () => {}, selected: null },
    selectedRows,
    setSelectedRows,
    rowSelectionType,
    scroll,
    outerBorder = false,
    className,
    loading = false,
    ellipsis = true,
    thickHeader,
    rowHasOnClick = () => true,
    parsedFormat = false,
    bordered = false,
    rowClassName,
    onClickLabel,
    thickHeaderClassName,
    onChange,
    showTotal = () => {},
    totalRow,
    customSort = false,
    hideOnClick = false,
    pagination = false,
    noRowBorder = false,
    expandable,
    tableLayout
  } = props

  const headersToAdd = headers.map((item) => ({
    title: ({ sortColumn, sortOrder }) => (
      <>
        <div>{item.topLabel}</div>
        <div
          className={
            sortColumn && sortColumn.dataIndex === item.key
              ? Styles['sort-title-container']
              : ''
          }
        >
          {item.label}
          {sortColumn &&
            sortColumn.dataIndex === item.key &&
            sortOrder &&
            customSort && (
            <Icon name={sortOrder === 'ASC' ? 'ChevronUp' : 'ChevronDown'} />
          )}
        </div>
      </>
    ),
    dataIndex: item.key,
    key: item.key,
    render: item.render,
    ellipsis,
    className: `${item.className ? item.className : ''} ${
      Styles['column-padding']
    }`,
    width: item.width,
    sorter: item.sorter,
    showSorterTooltip: item.sorter ? { title: 'Sortera' } : null,
    sortDirections: customSort ? ['ASC', 'DESC'] : ['ascend', 'descend'],
    total: item.total,
    align: item.align ? item.align : 'left'
  }))

  if (onClick && !hideOnClick) {
    headersToAdd.push({
      title: '',
      render: function renderColumn(record) {
        return rowHasOnClick(record) ? (
          <div className={Styles['clickable-row-margin']}>
            {onClickLabel ? (
              onClickLabel
            ) : (
              <Icon name="ChevronRight" fontSize="small" />
            )}
          </div>
        ) : null
      },
      align: 'right'
    })
  }

  const rowsToAdd = parsedFormat
    ? rows
    : rows.map((item) => ({
      key: item.id,
      ...parseValues(item.attributes)
    }))

  let rowSelection = {}

  if (selectedRows || setSelectedRows) {
    rowSelection = {
      selectedRowKeys: selectedRows ? selectedRows : undefined,
      onChange: setSelectedRows ? (rows) => setSelectedRows(rows) : undefined,
      type: rowSelectionType
    }
  }

  return (
    <div
      className={`${className || ''} ${
        outerBorder ? Styles['outer-border'] : ''
      }`}
    >
      {toggle ? (
        <Toggle
          items={toggle.items}
          onClick={(selected) => toggle.onClick(selected)}
          selected={toggle.selected}
        />
      ) : null}
      {thickHeader ? (
        <div
          className={`${Styles['thick-header-container']} ${thickHeaderClassName}`}
        >
          <div>
            {React.isValidElement(thickHeader.header) ? (
              thickHeader.header
            ) : (
              <p className={'font-weight-700'}>{thickHeader.header}</p>
            )}
            <p className={'secondary'}>{thickHeader.subHeader}</p>
          </div>
          {thickHeader.actions}
        </div>
      ) : null}
      <AntdTable
        tableLayout={tableLayout}
        bordered={bordered}
        scroll={scroll}
        rowSelection={selectedRows || setSelectedRows ? rowSelection : null}
        showHeader={!hidden}
        size={size}
        columns={headersToAdd}
        dataSource={rowsToAdd}
        pagination={pagination}
        rowClassName={(record) =>
          `${noRowBorder ? 'no-border' : ''} ${rowClassName || ''} ${
            onClick && rowHasOnClick(record) ? 'clickable' : ''
          }`
        }
        locale={{
          emptyText: (
            <Empty
              description={'Ingen data'}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        }}
        onRow={
          onClick
            ? (record) => {
                return {
                  onClick: () =>
                    rowHasOnClick(record) ? onClick(record.key, record) : {}
                }
              }
            : null
        }
        loading={loading}
        className={
          size === 'middle'
            ? Styles['no-top-border']
            : outerBorder
              ? ''
              : Styles['top-border']
        }
        expandable={
          expandable
            ? expandable
            : {
                indentSize: 64,
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.children && record.children.length > 0 ? (
                    <IconButton
                      onClick={(e) => onExpand(record, e)}
                      size="small"
                    >
                      <Icon
                        fontSize="small"
                        name={expanded ? 'ExpandLess' : 'ExpandMore'}
                      />
                    </IconButton>
                  ) : null
              }
        }
        onChange={onChange}
        summary={
          showTotal
            ? (pageData) => showTotal(pageData, headersToAdd, totalRow)
            : null
        }
      />
    </div>
  )
}

const Toggle = ({ selected, items, onClick }) => {
  if (items) {
    return (
      <div className={Styles.toggle}>
        {items.map((item) => (
          <p
            className={selected === item ? Styles.selected : undefined}
            onClick={() => onClick(item)}
            key={item.key}
          >
            {item.label}
          </p>
        ))}
      </div>
    )
  }

  return null
}

const parseValues = (item) => {
  const parsed = {}

  Object.entries(item).forEach(([key, value]) => {
    if (key === 'children') {
      if (value && value.length > 0) {
        parsed[key] = []
        value.forEach((child) => {
          parsed[key].push({
            key: child.id,
            ...parseValues(child.attributes ? child.attributes : child)
          })
        })
      }
    } else {
      parsed[key] =
        value !== null && value !== undefined ? value.toString() : ''
    }
  })

  return parsed
}

Table.displayName = 'Table'
export default Table
