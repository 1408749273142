import { Dispatch } from 'redux'

import * as Api from 'redux/api/DashboardFolders'
import * as Types from 'redux/Types'

import {
  DashboardFolderPatchBody,
  DashboardFolderPostBody
} from 'types/GlobalDashboardFolder'

export function tryCreateDashboardFolder(body: DashboardFolderPostBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_DASHBOARD_FOLDER
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_DASHBOARD_FOLDER_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen har skapats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DASHBOARD_FOLDER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen kunde inte skapas' }
        })
      })
  }
}

export function tryGetAllDashboardFolders() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_DASHBOARD_FOLDERS
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_DASHBOARD_FOLDERS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_DASHBOARD_FOLDERS_FAILED
        })
      })
  }
}

export function tryUpdateDashboardFolder(
  id: string,
  body: DashboardFolderPatchBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_FOLDER
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_FOLDER_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_FOLDER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteDashboardFolder(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_DASHBOARD_FOLDER
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_FOLDER_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_FOLDER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Mappen kunde inte tas bort' }
        })
      })
  }
}
