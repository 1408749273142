import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { useParams } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import OneCustomerTemplate from 'components_new/templates/internal/OneCustomer'

import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as OrderedKpiTemplateActions from 'redux/actions/internal/OrderedKpiTemplates'
import * as OurCustomersActions from 'redux/actions/internal/OurCustomers'

import { PatchInternalCustomerBody } from 'types/GlobalCustomer'
import { PatchOrderedKpiTemplateBody } from 'types/GlobalOrderedKpiTemplate'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  CustomersAndLicensesStore: state.CustomersAndLicensesStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...KPITemplateActions,
      ...OrderedKpiTemplateActions,
      ...OurCustomersActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type OneCustomerPageProps = ConnectedProps<typeof connector>

const OneCustomerPage = (props: OneCustomerPageProps) => {
  const { CustomersAndLicensesStore, KPITemplateStore } = props
  const params = useParams<{ id: string }>()

  useEffect(() => {
    if (!CustomersAndLicensesStore.customersFetched) {
      props.tryGetCustomers()
    }

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      props.tryGetAllKPIs()
    }
  }, [])

  const customer = CustomersAndLicensesStore.customers[params.id]

  return (
    <InternalTemplate>
      {customer ? (
        <OneCustomerTemplate
          createOrderedKpiTemplate={(kpiTemplateId: string) =>
            props.tryCreateOrderedKpiTemplate(
              {
                data: { customer_id: params.id, kpi_template_id: kpiTemplateId }
              },
              params.id
            )
          }
          customer={customer}
          deleteOrderedKpiTemplate={(id: string) =>
            props.tryDeleteOrderedKpiTemplate(id, params.id)
          }
          kpiTemplates={KPITemplateStore.data}
          kpiTemplatesFetched={KPITemplateStore.fetchedKpis}
          updateCustomer={(body: PatchInternalCustomerBody) =>
            props.tryUpdateCustomer(params.id, body)
          }
          updateOrderedKpiTemplate={(
            id: string,
            body: PatchOrderedKpiTemplateBody
          ) => props.tryUpdateOrderedKpiTemplate(id, body, params.id)}
        />
      ) : null}
    </InternalTemplate>
  )
}

export default connector(OneCustomerPage)
