import React, { useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import FormControl from 'components_new/atoms/FormControl'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import InputLabel from 'components_new/atoms/InputLabel'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Select from 'components_new/atoms/Select'
import Snackbar from 'components_new/atoms/Snackbar'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import { Category } from 'redux/reducers/Dashboards'
import { translateCategory } from 'utils/enumTranslator'
import { InnerPatchDashboardGroup } from 'redux/api/DashboardGroups'

interface EditTemplateDialogProps {
  onClose: () => void
  onSubmit: (body: InnerPatchDashboardGroup) => void
  open: boolean
  categoryOptions: Category[]
  category: Category | null
  description: string
  id: string
}

const EditTemplateDialog = (props: EditTemplateDialogProps) => {
  const { id, onClose, onSubmit, open, categoryOptions, category } = props

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  )

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  useEffect(() => {
    setSelectedCategory(category)
  }, [category])

  const handleClose = () => {
    onClose()
    setSelectedCategory(category)
  }

  const handleSubmit = () => {
    onSubmit({ category: selectedCategory })

    onClose()
  }

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <AdvancedDialog
          actions={
            <>
              <Button variant="text" onClick={handleClose}>
                Avbryt
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Spara
              </Button>
            </>
          }
          onClose={handleClose}
          open={open}
          title="Redigera"
        >
          <FormControl fullWidth size="small">
            <InputLabel>Kategori</InputLabel>
            <Select label={'Kategori'} value={selectedCategory}>
              <MenuItem value="" onClick={() => setSelectedCategory(null)}>
                <em>Ingen</em>
              </MenuItem>
              {categoryOptions.map((categoryOption, i) => (
                <MenuItem
                  value={categoryOption}
                  key={i}
                  onClick={() => setSelectedCategory(categoryOption)}
                >
                  {translateCategory[categoryOption].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, p: 2, bgcolor: 'action.hover' }}>
            <Alert severity="warning">
              <strong>Glöm ej!</strong> Lägg in DashboardGrupp ID och övrigt
              innehåll i Contentful innan du publicerar mallen.
            </Alert>
            <TextField
              value={id}
              fullWidth
              label={'DashboardGrupp ID'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon name="Tag" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={'Kopiera id'}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setShowCopiedMessage(true)
                          navigator.clipboard.writeText(id)
                        }}
                      >
                        <Icon name="ContentCopy" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
                readOnly: true
              }}
            />
          </Box>
        </AdvancedDialog>
      </ThemeProvider>

      {/* --- FEEDBACK --- */}
      <Snackbar
        open={showCopiedMessage}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason !== 'clickaway') {
            setShowCopiedMessage(false)
          }
        }}
        message="Kopierat till urklipp"
      />
    </>
  )
}

export default EditTemplateDialog
