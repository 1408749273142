import React, { useEffect } from 'react'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Select from 'components/common/Select'
import Input from 'components/common/Input'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import { REGEX } from 'helpers/Constants'

import Styles from './styles.module.css'

const PutApiModal = ({ visible, onClose, apis, customerName, onUpdate }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(visible)
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={'Koppla register till API'}
      actions={
        <>
          <Button
            onClick={() => {
              onClose()
              form.resetFields()
            }}
            variant="text"
          >
            Avbryt
          </Button>
          <Button onClick={() => form.submit()}>Spara</Button>
        </>
      }
    >
      <Form
        layout="vertical"
        initialValues={{
          cmdb_api_id: undefined,
          slug: undefined
        }}
        form={form}
        onFinish={(values) => {
          const data = {
            attributes: {
              ...values
            }
          }

          onUpdate(data)
          onClose()
        }}
      >
        <Form.Item
          name={'cmdb_api_id'}
          rules={[{ required: true, message: 'Obligatoriskt fält' }]}
        >
          <Select
            label={'* API-referens'}
            options={apis.map((api) => ({
              value: api.id,
              label: api.attributes.title
            }))}
          />
        </Form.Item>

        <Box sx={{ display: 'flex' }}>
          <p
            className={Styles['slug-prefix']}
          >{`${process.env.REACT_APP_FLOW_API}${customerName}/{build-api-title}/build/`}</p>
          <Form.Item
            name={'slug'}
            rules={[
              { required: true, message: 'Obligatoriskt fält' },
              () => ({
                validator(_, value) {
                  if (!value || REGEX.SLUG.test(value)) {
                    return Promise.resolve()
                  }

                  return Promise.reject('Ogiltigt format på slug')
                }
              })
            ]}
            className={'width-100'}
          >
            <Input
              className={Styles['slug-text-field']}
              size="large"
              label={'* Slug'}
            />
          </Form.Item>
        </Box>
      </Form>
    </Modal>
  )
}

export default PutApiModal
