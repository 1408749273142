import { TInputOption } from 'components_new/molecules/SettingsEditDialog/utils'
import { WidgetObject } from 'types/GlobalWidget'

export const getOptions = (widget: WidgetObject): TInputOption[] => {
  const selectedSegments = [
    widget.settings.segment_by.selected,
    ...widget.settings.segment_by.additional.map(
      (item) => item.attribute_option_id
    )
  ]

  const options = widget.settings.segment_by.options.map((option) => {
    return {
      disabled: option.disabled || selectedSegments.includes(option.id),
      label: option.name,
      value: option.id
    }
  })

  return options
}
