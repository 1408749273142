import {
  getAll,
  putTargetValues,
  PutTargetValuesBody,
  update
} from 'redux/api/Configs'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { Category } from 'redux/reducers/Dashboards'

export function tryGetAllTargets() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_TARGETS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_TARGETS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_TARGETS_FAILED
        })
      })
  }
}

export function tryUpdateConfig(
  body: { [attribute: string]: number | null },
  category: Category
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_CONFIG
    })

    update({ data: body })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_CONFIG_SUCCESS,
          payload: { data: response.data.data, category }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konfigurering har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_CONFIG_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konfigurering kunde inte uppdateras' }
        })
      })
  }
}

export function tryPutTargetValues(id: string, body: PutTargetValuesBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_TARGET_VALUES
    })

    putTargetValues(id, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_TARGET_VALUES_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konfigurering har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_TARGET_VALUES_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konfigurering kunde inte uppdateras' }
        })
      })
  }
}
