import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const my_homepal_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/cmdb`

export async function getAll() {
  return Axios.get(URL, Functions.parseAuthenticationHeader())
}

export async function create(body) {
  return Axios.post(URL, body, Functions.parseAuthenticationHeader())
}

export function update(id, body) {
  return Axios.patch(
    `${URL}/${id}`,
    body,
    Functions.parseAuthenticationHeader()
  )
}

export async function updateClassification(id, body) {
  return Axios.patch(
    `${URL}/classification/${id}`,
    body,
    Functions.parseAuthenticationHeader()
  )
}

export async function remove(id) {
  return Axios.delete(`${URL}/${id}`, Functions.parseAuthenticationHeader())
}
