import React, {
  ChangeEventHandler,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  MutableRefObject
} from 'react'
import { TextField as MUITextField, SelectProps } from '@mui/material'

export interface TextFieldProps {
  autoComplete?: string
  autoFocus?: boolean
  children?: ReactNode
  component?: ElementType<any>
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  defaultValue?: ReactNode
  disabled?: boolean
  error?: boolean
  focused?: boolean
  fullWidth?: boolean
  helperText?: ReactNode
  id?: string
  inputRef?: MutableRefObject<any>
  InputLabelProps?: object
  InputProps?: object
  label?: ReactNode
  margin?: 'dense' | 'none' | 'normal'
  minRows?: number | string
  maxRows?: number | string
  multiline?: boolean
  name?: string
  onBlur?:
    | (() => void)
    | FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onChange?:
    | (() => void)
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onFocus?:
    | (() => void)
    | FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onKeyDown?: (() => void) | KeyboardEventHandler
  placeholder?: string
  required?: boolean
  rows?: number | string
  select?: boolean
  SelectProps?: Partial<SelectProps>
  size?: 'medium' | 'small'
  step?: string
  sx?: object
  value?: any
  variant?: 'outlined' | 'filled' | 'standard'
  type?: string
}

/**
 * The TextField component let users enter and
 * edit text.
 *
 * It acts as a wrapper component for a
 * complete form control including a label,
 * input, and help text.
 */

const TextField = React.forwardRef((props: TextFieldProps, ref: any) => {
  const { children, onFocus, sx, ...rest } = props

  return (
    <MUITextField
      {...rest}
      onFocus={
        // make sure that cursor is set at the end of the input on autoFocus
        props.autoFocus
          ? function (e) {
            const val = e.target.value

            e.target.value = ''
            e.target.value = val
          }
          : onFocus
      }
      sx={{
        my: props.select ? 0 : undefined,
        ...(sx || {})
      }}
      ref={ref}
      SelectProps={
        props.select
          ? {
              ...(props.SelectProps || {}),
              MenuProps: {
                ...(props.SelectProps?.MenuProps || {}),
                sx: {
                  zIndex: 1600,
                  ...(props.SelectProps?.MenuProps?.sx || {})
                }
              }
            }
          : props.SelectProps
      }
      //
      // This prevented our AutoComplete from selecting values
      // by arrow keys.
      // Let's keep it here for a while. It may have been added to solve a
      // bug fix for some other issue:
      //
      // onKeyDown={(e) => {
      //   e.stopPropagation()

      //   if (onKeyDown) {
      //     onKeyDown(e)
      //   }
      // }}
      //
    >
      {children}
    </MUITextField>
  )
})

TextField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  variant: 'outlined'
}

TextField.displayName = 'TextField'
export default TextField
