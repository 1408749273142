import React from 'react'
import { useTheme } from '@mui/material'
import { Chart } from 'react-chartjs-2'

import Paper from 'components_new/atoms/Paper'
import Box from 'components_new/atoms/Box'
import LoadingSection from 'components_new/molecules/LoadingSection'

interface DegreeOfActivationComboChartProps {
  labels: string[]
  degreeOfActivation: (number | string)[]
  total: (number | string)[]
  nrOfActivatedUsers: (number | string)[]
  title: string
  loading: boolean
}

const DegreeOfActivationComboChart = (
  props: DegreeOfActivationComboChartProps
) => {
  const PaperSx = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minWidth: 0,
    position: 'relative',
    gap: 2,
    mt: 2,
    bgcolor: 'grey.50'
  }

  const theme = useTheme()

  return (
    <Paper elevation={0} sx={PaperSx}>
      <Box sx={{ flex: '1 1 0', minHeight: 0 }}>
        {props.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <Box sx={{ position: 'relative', flexGrow: 1, minHeight: '100%' }}>
            <Chart
              type={'bar'}
              data={{
                labels: props.labels,
                datasets: [
                  {
                    data: props.total,
                    backgroundColor: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                    label: 'Skapade användare',
                    type: 'bar',
                    yAxisID: 'bar'
                  },
                  {
                    data: props.nrOfActivatedUsers,
                    backgroundColor: theme.palette.secondary.light,
                    borderColor: theme.palette.secondary.light,
                    label: 'Varav har blivit aktiverade',
                    type: 'bar',
                    yAxisID: 'bar'
                  },
                  {
                    data: props.degreeOfActivation,
                    backgroundColor: theme.palette.brand.main,
                    borderColor: theme.palette.brand.main,
                    label: 'Aktiveringsgrad (%)',
                    type: 'line',
                    yAxisID: 'line'
                  }
                ]
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      color: theme.palette.divider
                    },
                    ticks: {
                      color: theme.palette.text.secondary
                    }
                  },
                  bar: {
                    position: 'left',
                    display: true,
                    grid: {
                      drawBorder: false,
                      color: theme.palette.divider
                    },
                    title: {
                      display: true,
                      text: 'Användare (antal)',
                      color: theme.palette.text.secondary
                    },
                    ticks: {
                      count: 5,
                      callback: function (this, value): string {
                        return value.toString()
                      },
                      color: theme.palette.text.secondary
                    }
                  },
                  line: {
                    position: 'right',
                    grid: {
                      display: false,
                      drawBorder: false
                    },
                    title: {
                      display: true,
                      text: 'Aktiveringsgrad (%)',
                      color: theme.palette.text.secondary
                    },
                    ticks: {
                      count: 5,
                      callback: function (this, value, index, ticks): string {
                        if (index === ticks.length - 1) {
                          return '%'
                        }

                        return value.toString()
                      },
                      color: theme.palette.text.secondary
                    }
                  }
                }
              }}
            />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default DegreeOfActivationComboChart
