import React from 'react'

import TableCell from 'components_new/atoms/Table/TableCell'

import { DatasetType } from 'types/GlobalWidget'

import { getCellAlignment } from '../../../types/Table/utils'

interface HeadCellProps {
  label: string | number | null | string[]
  type?: DatasetType
}

const HeadCell = (props: HeadCellProps) => {
  const { label, type } = props

  return (
    <TableCell
      align={type ? getCellAlignment(type) : 'right'}
      sx={{
        whiteSpace: 'nowrap',
        width: '1%' // For auto sized, fit content cells.
      }}
      variant="head"
    >
      {label}
    </TableCell>
  )
}

export default HeadCell
