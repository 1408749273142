import React, { useEffect, useMemo, useState } from 'react'

import {
  TableComponents,
  TableVirtuoso,
  ScrollerProps,
  TableBodyProps
} from 'react-virtuoso'

import { default as MUITable } from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableFooter from 'components_new/atoms/Table/TableFooter'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import { IconNames } from 'components_new/atoms/Icon'
import {
  getOrderByArray,
  orderDataset,
  orderLabels,
  suppressResizeObserver
} from './utils'
import {
  WidgetObject,
  FormattedWidgetData,
  ParsedSegmentPath,
  FormattedWidgetDataDataset,
  ParsedWidgetDataLabel
} from 'types/GlobalWidget'

import BodyRow from './BodyRow'
import HeaderRow from './HeaderRow'
import { getScrollbarSx } from 'themes'
import { useTheme } from '@mui/material'
import FooterRow from './FooterRow'

interface TableProps {
  formattedData: FormattedWidgetData
  isComparativeButIllegal: boolean
  onClickSegment: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  onClickValue: {
    [kpiOptionId: string]:
      | ((
          dataLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  }
  scaleFactor: number
  widget: WidgetObject
}

export interface RowData {
  label: ParsedWidgetDataLabel
  link: string | null
}

const Table = (props: TableProps) => {
  const {
    formattedData,
    isComparativeButIllegal,
    onClickSegment,
    onClickValue,
    scaleFactor,
    widget
  } = props

  useEffect(() => {
    window.addEventListener('error', suppressResizeObserver)
  }, [])

  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<{
    id: string | null
    index: number | null
  }>({ id: null, index: null })

  const segmentBy = useMemo(() => {
    if (widget.settings.segment_by.display_name) {
      return widget.settings.segment_by.display_name
    }

    return (
      widget.settings.segment_by.options.find(
        (option) => option.id === widget.settings.segment_by.selected
      )?.name || ''
    )
  }, [widget])

  const segmentMaxChars = widget.settings.label_chars
  const showHeader = widget.settings.show_header
  const showVerticalDividers = widget.settings.show_vertical_dividers
  const allowSort = widget.settings.segment_by.additional.length === 0

  const rows: RowData[] = useMemo(() => {
    const parsedLabels = formattedData.labels.map((label, index) => ({
      label,
      link: formattedData.links.length > 0 ? formattedData.links[index] : null
    })) as RowData[]

    if (orderBy) {
      const sortingOpt = getOrderByArray(
        orderBy,
        formattedData.labels,
        formattedData.datasets
      )

      return orderLabels(parsedLabels, sortingOpt, order)
    }

    return parsedLabels
  }, [formattedData, order, orderBy])

  const datasets: FormattedWidgetDataDataset[] = useMemo(() => {
    if (orderBy) {
      return formattedData.datasets.map((dataset) => {
        const sortingOpt = getOrderByArray(
          orderBy,
          formattedData.labels,
          formattedData.datasets
        )

        return orderDataset(dataset, sortingOpt, order)
      })
    }

    return formattedData.datasets
  }, [formattedData, order, orderBy])

  const onSort = (id: string, index: number | null) => {
    const isAsc =
      orderBy.id === id && orderBy.index === index && order === 'asc'

    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy({ id, index })
  }

  const sortedDatasets = datasets
    .sort((a, b) => {
      if (a.settingIndex === b.settingIndex) {
        return a.index - b.index
      }

      return a.settingIndex - b.settingIndex
    })
    .filter((dataset) => !dataset.hidden)

  return (
    <TableVirtuoso
      data={rows}
      components={VirtuosoTableComponents}
      context={{ scaleFactor }}
      fixedHeaderContent={() => (
        <HeaderRow
          display={showHeader}
          datasets={sortedDatasets}
          onSort={allowSort ? onSort : undefined}
          order={order}
          orderBy={orderBy}
          scaleFactor={scaleFactor}
          segmentBy={segmentBy}
          verticalDividers={showVerticalDividers}
        />
      )}
      itemContent={(row, item) => (
        <BodyRow
          datasets={sortedDatasets}
          isComparativeButIllegal={isComparativeButIllegal}
          label={item.label}
          link={item.link}
          onClickSegment={onClickSegment}
          onClickValue={onClickValue}
          row={row}
          scaleFactor={scaleFactor}
          segmentMaxChars={segmentMaxChars}
          verticalDividers={showVerticalDividers}
          widget={widget}
        />
      )}
      fixedFooterContent={() =>
        widget.settings.show_total ? (
          <FooterRow datasets={sortedDatasets} scaleFactor={scaleFactor} />
        ) : null
      }
    />
  )
}

export default Table

/* ----- Virtuoso Table Components ----- */

const VirtuosoTableComponents: TableComponents<RowData> = {
  Scroller: React.forwardRef((props, ref) => {
    const theme = useTheme()

    return (
      <TableContainer
        {...props}
        sx={{
          maxHeight: '100%',
          overflowX: 'auto',
          overflowAnchor: 'none', // Fix for scrolling when horizontal scroll is active.
          ...getScrollbarSx(theme, (props.context as any).scaleFactor)
        }}
        ref={ref}
      />
    )
  }) as React.ComponentType<
    ScrollerProps & {
      context?: any
    }
  >,
  Table: (props) => (
    <MUITable
      {...props}
      size="small"
      sx={{
        borderCollapse: 'separate',
        tableLayout: 'fixed',
        width: 'auto' // For auto sized, fit content cells. (Hacker).
      }}
    />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )) as React.ComponentType<
    TableBodyProps & {
      context?: any
    }
  >,
  TableFoot: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableFooter {...props} ref={ref} />
  )) as React.ComponentType<
    Pick<React.ComponentPropsWithRef<'tfoot'>, 'style' | 'children' | 'ref'> & {
      context?: any
    }
  >,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )) as React.ComponentType<
    Pick<React.ComponentPropsWithRef<'thead'>, 'style' | 'children' | 'ref'> & {
      context?: any
    }
  >,
  TableRow: React.forwardRef((props, ref) => (
    <TableRow {...props} hover={true} lastRowHasBorder={true} ref={ref} />
  ))
}
