import React from 'react'
import Axios from 'axios'
import { isEqual } from 'lodash'

import KeyFigure from './KeyFigure'
import BarChart from './BarChart'
import PieChart from './PieChart'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { isDateType } from 'helpers/Functions/Date'
import {
  STANDARD_VALUES,
  STANDARD_VALUE,
  TODAY
} from 'components/containers/Applications/DataProducts/ManageDatasetModal/Operations/ManageOperation/conf.js'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/v3/tables/'
    : `${process.env.REACT_APP_HOMEPAL_MDM_API_PROD}v3/tables/`

export const COMPONENTS = {
  KEY_FIGURE: (props) => <KeyFigure {...props} />,
  TABLE: () => null,
  BAR_CHART: (props) => <BarChart {...props} />,
  COMBINATION_CHART: (props) => <BarChart {...props} />,
  PIE_CHART: (props) => <PieChart {...props} />,
  PIVOT_TABLE: () => null
}

export const ErrorComponent = ({ img, text }) => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <img
      src={img}
      alt={'broken-bar-chart'}
      className={Styles['error-component-img']}
    />
    <Text color="text.secondary" variant="subtitle2" sx={{ mt: 2 }}>
      {text}
    </Text>
  </Box>
)

/**
 * Requires snake case in input and outputs in snake case
 */
export const parseFilterConditions = (filterConditions) =>
  filterConditions.map((item) => ({
    condition: item.condition,
    conjunctive_operator: item.conjunctiveOperator,
    index: item.index,
    left_attribute: item.leftAttribute,
    right_attribute: item.rightAttribute,
    right_value: STANDARD_VALUES.includes(item.type)
      ? item.type
      : item.rightValue,
    type: STANDARD_VALUES.includes(item.type) ? STANDARD_VALUE : item.type
  }))

export const fetchPreview = (
  widgetType,
  tableId,
  formValues,
  setPreview,
  fetching
) => {
  // If the AbortController is aborted, it is consumed and a new instance
  // needs to be created. I.e. one instance has to be called/instance
  const controller = new AbortController()

  if (controller && fetching) {
    controller.abort()
  }

  setPreview({ data: [], metrics: [], fetching: true })

  Axios.post(
    `${BASE_URL}${tableId}/dashboard-widgets/preview`,
    {
      data: {
        attributes: {
          segment_by: formValues.segment_by,
          segment_group: formValues.segment_group,
          visualization_constraint: formValues.visualization_constraint,
          amount_to_show: formValues.amount_to_show,
          widget_metrics: formValues.widget_metrics.map((metric, i) => ({
            id: metric.id,
            attribute_id: metric.attribute_id,
            type: metric.type
              ? metric.type.length > 1
                ? metric.type[1]
                : metric.type[0]
              : null,
            period_attribute_id: metric.period_attribute_id,
            index: i,
            class: metric.class,
            metric_conditions: metric.metric_conditions
              ? parseFilterConditions(
                metric.metric_conditions.sort((mc1, mc2) =>
                  mc1.index < mc2.index ? -1 : 1
                )
              )
              : []
          })),
          breakdown_by: formValues.breakdown_by,
          type: widgetType,
          main_class_filters:
            formValues.main_class_filters ||
            (formValues.conditions
              ? parseFilterConditions(formValues.conditions)
              : [])
        }
      }
    },
    {
      ...parseAuthenticationHeader(),
      signal: controller.signal
    }
  )
    .then((response) => {
      setPreview({
        data: response.data.data.attributes.data,
        metrics: response.data.data.attributes.metrics,
        fetching: false
      })
    })
    .catch((e) => {
      setPreview({ data: [], metrics: [], fetching: false })

      // If the Axios request gets aborted it will return {message: 'canceled'}
      // Catch the request and runt the fetch again
      if (e.message === 'canceled') {
        fetchPreview(widgetType, tableId, formValues, setPreview, false)
      }
    })
}

export const validateConditions = (conditions) => {
  return conditions.every(
    (cond) =>
      cond.condition &&
      (cond.leftAttribute || cond.leftWidgetMetricId) &&
      cond.type &&
      (cond.rightValue ||
        cond.rightValue === 0 ||
        cond.rightAttribute ||
        cond.rightWidgetMetricId ||
        ['ONLY_LEFT', TODAY].includes(cond.type))
  )
}

/**
 * If conditions are set and has changed, validate required fields
 * @param {list} conditions
 * @param {list} prevConditions
 * @returns {boolean}
 */
export const validateFormValueConditions = (conditions, prevConditions) => {
  if (!conditions) {
    return true
  }

  const conditionsChanged = !isEqual(conditions, prevConditions)

  if (!conditionsChanged) {
    return true
  }

  return validateConditions(conditions)
}

export const validateRequiredPreviewDependency = (type, formValues) => {
  const requiredWidgetMetricFields =
    formValues?.widget_metrics
      .map((metric) => {
        if (metric.metric_conditions && metric.metric_conditions.length > 0) {
          return [
            metric.attribute_id,
            metric.type,
            validateConditions(metric.metric_conditions)
          ]
        }

        return [metric.attribute_id, metric.type]
      })
      .flat() || []

  let fieldsToValidate = []

  if (['BAR_CHART', 'COMBINATION_CHART', 'PIE_CHART'].includes(type)) {
    fieldsToValidate = [formValues?.segment_by, ...requiredWidgetMetricFields]
  } else if (type === 'TABLE') {
    fieldsToValidate =
      formValues?.widget_metrics.map((metric) => metric.attribute_id) || []
  } else if (type === 'KEY_FIGURE') {
    fieldsToValidate = requiredWidgetMetricFields
  } else if (type === 'PIVOT_TABLE') {
    fieldsToValidate = [formValues?.segment_by, ...requiredWidgetMetricFields]
  }

  return fieldsToValidate.every((item) => !!item)
}

export const getPreviewTriggers = (type, formValues) => {
  switch (type) {
  case 'KEY_FIGURE':
    return [
      formValues?.widget_metrics?.[0].type,
      formValues?.widget_metrics?.[0].attribute_id,
      formValues?.widget_metrics?.[0].metric_conditions
    ]
  case 'TABLE':
    return [formValues?.widget_metrics]
  case 'BAR_CHART':
    return [
      formValues?.segment_by,
      formValues?.widget_metrics,
      formValues?.segment_group,
      formValues?.breakdown_by,
      formValues?.breakdown_type
    ]
  case 'COMBINATION_CHART':
    return [
      formValues?.segment_by,
      formValues?.widget_metrics,
      formValues?.segment_group,
      formValues?.breakdown_by,
      formValues?.breakdown_type
    ]
  case 'PIE_CHART':
    return [
      formValues?.widget_metrics?.[0].type,
      formValues?.widget_metrics?.[0].attribute_id,
      formValues?.segment_by
    ]
  case 'PIVOT_TABLE':
    return [
      formValues?.segment_by,
      formValues?.widget_metrics,
      formValues?.breakdown_by,
      formValues?.breakdown_type
    ]
  default:
    return []
  }
}

export const getNullLabel = (segmentByDataType) => {
  if (isDateType(segmentByDataType)) {
    return 'Inget datum'
  }

  return 'Saknar värde'
}

/*
  Parse out a new filter if it is a drill through action
*/
export const parseDrillThroughFilter = (originalFilter, drillThrough) => {
  if (drillThrough.length === 0) {
    return originalFilter
  }

  const splitFilter = originalFilter.split('&')
  const drillThroughAttributeName = drillThrough[0].substring(1)

  const drillThroughFilter = `&filter[${drillThroughAttributeName}][eq]=${drillThrough[1]}`

  // If splitFilter.length === 1, there are no filters set
  if (splitFilter.length === 1) {
    return drillThroughFilter
  }

  let attributeAlreadyExists = false
  let updatedFilter = ''

  splitFilter.forEach((f) => {
    if (f.includes(`filter[${drillThroughAttributeName}][eq]=`)) {
      attributeAlreadyExists = true

      updatedFilter += drillThroughFilter
    } else if (f !== '') {
      updatedFilter += `&${f}`
    }
  })

  if (!attributeAlreadyExists) {
    updatedFilter += drillThroughFilter
  }

  return updatedFilter
}

/**
 * Check if edit widget is disabled
 * @param {object} widgetData
 * @returns
 */
export function disabledEditWidget(widgetData) {
  return !(widgetData.widget_metrics
    ? widgetData.widget_metrics.every((metric) => !metric.custom_function)
    : true)
}

/**
 * Check if a value matches any format.
 */
export const matchesFormatConditions = (format, row, segmentBy) =>
  format.filter_conditions
    .filter((cond) => {
      const conditionAttributes = cond.attributes
        ? {
            ...cond.attributes,
            leftAttribute: cond.attributes.left_attribute,
            rightAttribute: cond.attributes.right_attribute,
            leftWidgetMetricId: cond.attributes.left_widget_metric_id,
            rightWidgetMetricId: cond.attributes.right_widget_metric_id,
            rightValue: cond.attributes.right_value
          }
        : cond

      return validateConditions([conditionAttributes])
    })
    .every((cond) => {
      const parsedCondition = cond.attributes
        ? cond.attributes
        : {
            ...cond,
            left_attribute: cond.leftAttribute,
            right_attribute: cond.rightAttribute,
            left_widget_metric_id: cond.leftWidgetMetricId,
            right_widget_metric_id: cond.rightWidgetMetricId,
            right_value: cond.rightValue
          }

      const [leftValue, rightValue] = parseConditionValue(
        parsedCondition,
        row,
        segmentBy
      )

      if (leftValue || leftValue === 0) {
        const parsedEvalExpression = parseEvalCondition(
          leftValue,
          parsedCondition.condition,
          rightValue,
          parsedCondition.type
        )

        return eval(parsedEvalExpression)
      }

      return false
    })

/**
 * Parse eval condition
 */
const parseEvalCondition = (
  leftValue,
  condition,
  rightValue,
  conditionType
) => {
  if (conditionType === 'NUMBER_OF_CHARACTERS') {
    leftValue = leftValue ? leftValue.length : 0
  }

  if (
    leftValue &&
    typeof leftValue === 'string' &&
    !leftValue.match(/^[0-9]*$/)
  ) {
    leftValue = `"${leftValue}"`
  }
  if (
    rightValue &&
    typeof rightValue === 'string' &&
    !rightValue.match(/^[0-9]*$/)
  ) {
    rightValue = `"${rightValue}"`
  }

  switch (condition) {
  case '=':
    return `${leftValue} === ${rightValue}`
  case '!=':
    return `${leftValue} !== ${rightValue}`
  case '>':
    return `${leftValue} > ${rightValue}`
  case '>=':
    return `${leftValue} >= ${rightValue}`
  case '<':
    return `${leftValue} < ${rightValue}`
  case '<=':
    return `${leftValue} <= ${rightValue}`
  case 'IS NULL':
    return `(!${leftValue} && ${leftValue} !== 0)`
  case 'IS NOT NULL':
    return `(${leftValue} !== null && ${leftValue} !== undefined && ${leftValue} !== '')`
  case 'INCLUDES':
    return `${leftValue}.includes(${rightValue})`
  case 'EXCLUDES':
    return `!${leftValue}.includes(${rightValue})`
  case 'STARTS_WITH':
    return `${leftValue?.substring(0, rightValue.length) === rightValue}`
  case 'ENDS_WITH':
    return `${leftValue?.substring(rightValue.length) === rightValue}`
  default:
    return ''
  }
}

/**
 * Parses the right value of the condition. Only condition type
 * COLUMN needs to be checked, due to the value from a widget always
 * will be a number/decimal.
 */
const parseConditionValue = (condition, row, segmentBy) => {
  const leftIsSegmentBy = condition.left_attribute === segmentBy

  const leftValue =
    row[
      leftIsSegmentBy
        ? condition.left_attribute
        : condition.left_widget_metric_id
    ]

  let rightValue = condition?.right_value

  if (condition.type === 'COLUMN') {
    const rightIsSegmentBy = condition.right_attribute === segmentBy

    rightValue = rightIsSegmentBy
      ? condition.right_attribute
      : condition.right_widget_metric_id
  }

  return [leftValue, rightValue]
}
