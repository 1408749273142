import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AuthStoreActions from 'redux/actions/Authentication'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import Avatar from 'components_new/atoms/Avatar'
import IconButton from 'components_new/atoms/IconButton'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Skeleton from 'components_new/atoms/Skeleton'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

interface SelectCustomerProps {
  sx?: object
}

const SelectCustomer = (props: ComponentProps) => {
  const {
    AuthStore,
    CompanyGroupStore,
    KPIDashboardStore,
    NavigationMenuStore,
    setShowAsCustomerGroup,
    sx
  } = props

  const params = useParams<{ id: string }>()

  const companyGroups = useMemo(() => {
    return Object.values(CompanyGroupStore.data)
      .map((cg) => {
        const hasPermission = KPIDashboardStore.data[
          params.id
        ]?.share_settings.permissions.find(
          (perm) => perm.company_group_id === cg.id
        )

        return {
          ...cg,
          hasPermission: !!hasPermission
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
  }, [CompanyGroupStore, KPIDashboardStore])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const collapsed = !NavigationMenuStore.open

  const handleClose = () => {
    setAnchorEl(null)
  }

  // hide if *our* customer is not management company
  if (!Boolean(AuthStore.customer?.is_management_company)) return null

  // hide if user is a customer of *our* customer
  if (Boolean(AuthStore.user?.is_company_group)) return null

  // loading
  if (!CompanyGroupStore.fetched || !KPIDashboardStore.fetched)
    return <Skeleton height={37} sx={sx} />

  // success
  return (
    <>
      {
        // default
        !collapsed ? (
          <>
            <TextField
              label="Koncern"
              select={true}
              size="small"
              sx={sx}
              value={CompanyGroupStore.showAs ?? 'none'}
            >
              <MenuItem
                onClick={() => {
                  setShowAsCustomerGroup(null)

                  handleClose()
                }}
                value="none"
              >
                <em>Alla</em>
              </MenuItem>
              {companyGroups.map((companyGroup) => (
                <MenuItem
                  key={companyGroup.id}
                  onClick={() => {
                    setShowAsCustomerGroup(companyGroup.id)

                    handleClose()
                  }}
                  value={companyGroup.id}
                >
                  {companyGroup.name}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : (
          // collapsed
          <>
            <Tooltip
              placement="right"
              title={
                <>
                  <b>Koncern</b>
                  <br />
                  {CompanyGroupStore.showAs
                    ? companyGroups.find(
                      (companyGroup) =>
                        companyGroup.id === CompanyGroupStore.showAs
                    )?.name
                    : 'Alla'}
                </>
              }
            >
              <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{ height: 40, ...sx }}
              >
                <Avatar
                  alt={
                    CompanyGroupStore.showAs
                      ? companyGroups.find(
                        (companyGroup) =>
                          companyGroup.id === CompanyGroupStore.showAs
                      )?.name
                      : 'Alla'
                  }
                />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              open={Boolean(anchorEl)}
            >
              <MenuItem
                onClick={() => {
                  setShowAsCustomerGroup(null)

                  handleClose()
                }}
                selected={!Boolean(CompanyGroupStore.showAs)}
              >
                <em>Alla</em>
              </MenuItem>
              {companyGroups.map((companyGroup) => (
                <MenuItem
                  key={companyGroup.id}
                  onClick={() => {
                    setShowAsCustomerGroup(companyGroup.id)

                    handleClose()
                  }}
                  selected={CompanyGroupStore.showAs === companyGroup.id}
                >
                  {companyGroup.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )
      }
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  KPIDashboardStore: state.KPIDashboardStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions,
      ...CompanyGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & SelectCustomerProps

export default connector(SelectCustomer)
