import React, { useState } from 'react'
import { Input as AntdInput } from 'antd'

import ShortId from 'shortid'

import IconButton from 'components_new/atoms/IconButton'
import Icon from 'components_new/atoms/Icon'

// Local files.
import Styles from './styles.module.css'

// Overrite default antd.
import './styles.css'

/*
sizes:
  small - 2rem
  medium - 3rem
  large - 4rem
*/

const Input = (props) => {
  const {
    onChange,
    className = '',
    placeholder = '',
    value = '',
    disabled,
    size = 'middle',
    type = 'text',
    withLock,
    locked,
    setLocked,
    helperText,
    rows = 4,
    prefix,
    suffix,
    containerClassName,
    addonAfter,
    onPressEnter,
    allowClear = false,
    label = undefined,
    labelBackground,
    onKeyDown
  } = props

  const Input =
    type === 'password'
      ? AntdInput.Password
      : type === 'textarea'
        ? AntdInput.TextArea
        : AntdInput

  const [shortid] = useState(ShortId.generate())

  let id = `ant-input-${shortid}`

  if (addonAfter) {
    id = `ant-input-group-addon-input-${shortid}`
  }

  return (
    <div
      className={`${
        type === 'textarea' ? '' : Styles['container']
      } ${containerClassName} ${label ? Styles['label-container'] : ''}`}
    >
      {label && (
        <label
          className={`${disabled || locked ? Styles['disabled-label'] : ''} ${
            labelBackground ? Styles['colored-label'] : ''
          }`}
        >
          {label}
        </label>
      )}
      <Input
        id={id}
        className={className}
        size={size}
        placeholder={placeholder}
        disabled={disabled || (locked && withLock)}
        value={value}
        onChange={onChange}
        type={type}
        addonAfter={addonAfter}
        rows={type === 'textarea' ? rows : undefined}
        autoSize={
          type === 'textarea' ? { minRows: rows, maxRows: rows } : undefined
        }
        prefix={prefix}
        suffix={suffix}
        onPressEnter={onPressEnter ? (e) => onPressEnter(e) : () => {}}
        allowClear={allowClear}
        onKeyDown={onKeyDown}
      />
      {withLock ? (
        <IconButton onClick={() => setLocked(!locked)}>
          <Icon
            color={locked ? 'inherit' : 'primary'}
            name={locked ? 'LockOutlined' : 'LockOpenOutlined'}
          />
        </IconButton>
      ) : null}
      {type === 'textarea' ? (
        <small className={'secondary'}>{helperText}</small>
      ) : null}
    </div>
  )
}

export default Input
