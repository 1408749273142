import React from 'react'
import { Col } from 'antd'

import Card from 'components/common/Card'
import Button from 'components_new/atoms/Button'

import Styles from './styles.module.css'

const CreateOptionCard = ({ badge, icon, text, button }) => {
  return (
    <Col>
      <Card className={Styles['select-system-card-container']} badge={badge}>
        <div className={Styles['select-system-content-container']}>
          <img
            src={icon}
            alt={`${text} logo`}
            className={Styles['select-system-icon']}
          />
          <p className={Styles['select-system-text']}>{text}</p>
          <Button
            onClick={() => button.onClick()}
            variant={button.bordered ? 'text' : 'contained'}
          >
            {button.text}
          </Button>
        </div>
      </Card>
    </Col>
  )
}

export default CreateOptionCard
