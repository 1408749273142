import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import { useTheme } from '@mui/material'

import Node from './index.js'

import ProgressBar from 'components/common/ProgressBar'
import QualityHover from 'components/common/ProgressBar/QualityHover'

import * as Functions from 'helpers/Functions'

import Styles from './styles.module.css'

const OntologyNode = ({ data, selected }) => {
  const { hasCount, totalValids, total, percent } =
    Functions.getTotalValidityCount(data.datasetValidCounts, data.id)

  const hidden = !data.showLinkTables && data.isLinkTable

  if (hidden) {
    return null
  }

  return (
    <Node
      hiddenHandles
      selected={selected}
      className={Styles['ontology-redirect-wrapper']}
      containerClassName={Styles['ontology-outer-container']}
    >
      <Box sx={{ minWidth: '12rem', pointerEvents: 'all !important' }}>
        <Box
          sx={{
            bgcolor: data.isLinkTable ? 'white.main' : 'black.main',
            color: data.isLinkTable ? 'black.main' : 'white.main',
            px: 1,
            py: 0.5
          }}
        >
          <small>{data.name}</small>
        </Box>
        {data.showDetails ? (
          <>
            <Box sx={{ p: 1 }}>
              <small className={'secondary tiny'}>Schema</small>
              <OntologyNodeRow
                label={data.technicalName}
                showQuality={data.showQuality}
                percent={percent}
                hasCount={hasCount}
                totalValids={totalValids}
                total={total}
                isLinkTable={data.isLinkTable}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
              <small className={'secondary tiny'}>Attribut</small>
              {data.columns.map((col, i) => {
                const { hasCount, totalValids, total, percent } =
                  Functions.getTotalValidityCount(
                    data.datasetValidCounts,
                    data.id,
                    col.id
                  )

                return (
                  <OntologyNodeRow
                    key={`${col.id}-${i}`}
                    id={col.id}
                    label={col.name}
                    showQuality={data.showQuality}
                    percent={percent}
                    hasCount={hasCount}
                    totalValids={totalValids}
                    total={total}
                    primaryKey={data.primaryKey}
                    isLinkTable={data.isLinkTable}
                    selectedRelationAttribute={data.selectedRelationAttribute}
                  />
                )
              })}
            </Box>
          </>
        ) : null}
        <Divider />
        <Button
          disabled={!data.id}
          fullWidth
          onClick={() => data.onClick()}
          variant="text"
        >
          Öppna
        </Button>
      </Box>
    </Node>
  )
}

const OntologyNodeRow = ({
  id,
  label,
  showQuality,
  primaryKey,
  isLinkTable = false,
  percent,
  hasCount,
  totalValids,
  total,
  selectedRelationAttribute
}) => {
  const theme = useTheme()

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '20px',
        py: 0.25,
        px: 0.5,
        mb: 0.25,
        bgcolor:
          id && id === selectedRelationAttribute
            ? 'primary.transparent'
            : undefined,
        borderColor:
          id && id === selectedRelationAttribute ? 'primary.main' : undefined
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {((primaryKey && primaryKey === id) || isLinkTable) && (
          <Icon
            color="primary"
            fontSize="10px"
            name="KeyOutlined"
            sx={{ mr: 0.25 }}
          />
        )}
        <small className={`tiny ${Styles['static-input-text']}`}>{label}</small>
      </Box>
      {showQuality ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 0.5,
            justifyContent: 'spaceBetween'
          }}
        >
          <ProgressBar
            size={'small'}
            percent={percent}
            hideText
            leftoverColor={
              hasCount ? theme.palette.error.main : theme.palette.neutral.light
            }
            hoverContent={
              <QualityHover
                valid={totalValids}
                total={total}
                hasCount={hasCount}
              />
            }
          />
          <small className={`tiny secondary ${Styles['static-input-text']}`}>
            {hasCount ? `${percent}%` : '-'}
          </small>
        </Box>
      ) : null}
    </Paper>
  )
}

export default OntologyNode
