import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

/**
 *
 * @param {Array<object>} options - the options the sider
 * consists of, the items has the following notation
 *  {
 *    icon: IconDefinition
 *    header: string
 *    content: ReactNode
 *    index: number
 *    actions: {
 *      onClick: () => {}
 *      text: string
 *    }
 *  }
 * @returns a collapsible side bar
 */
const Sider = ({ options }) => {
  const initialValue = {
    icon: undefined,
    header: undefined,
    content: undefined,
    action: undefined,
    index: 0
  }

  const [collapsed, setCollapsed] = useState(false)
  const [collapsedOption, setCollapsedOption] = useState(initialValue)

  useEffect(() => {
    if (collapsed) {
      setCollapsedOption(options[collapsedOption.index])
    }
  }, [options])

  return (
    <Box
      sx={{
        bgcolor: 'white.main',
        height: '100%',
        display: 'flex',
        zIndex: 1
      }}
    >
      {collapsed && (
        <Box sx={{ height: '100%', display: 'flex' }}>
          <Divider flexItem orientation="vertical" />
          <Box sx={{ width: '25vw', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton
                  edge="start"
                  onClick={() => {
                    setCollapsedOption(initialValue)
                    setCollapsed(false)
                  }}
                  size="small"
                >
                  <Icon fontSize="small" name="Close" />
                </IconButton>
                <Text variant="subtitle2">{collapsedOption.header}</Text>
              </Box>

              {collapsedOption.action && (
                <Button onClick={collapsedOption.action.onClick} variant="text">
                  {collapsedOption.action.text}
                </Button>
              )}
            </Box>
            <Divider flexItem />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                gap: 1
              }}
            >
              {collapsedOption.content}
            </Box>
          </Box>
        </Box>
      )}
      <Divider flexItem orientation="vertical" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          width: '3rem',
          py: 1
        }}
      >
        {options.map((opt, i) => (
          <Box
            key={`opt-${i}`}
            onClick={() => {
              setCollapsedOption(collapsed ? initialValue : opt)
              setCollapsed(!collapsed)
            }}
          >
            <Icon name={opt.icon} className={Styles.icon} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Sider
