import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import {
  getAll,
  create,
  update,
  destroy,
  addCompanyToCompanyGroup,
  removeCompanyFromCompanyGroup
} from 'redux/api/CompanyGroups'

export function tryGetAllCompanyGroups() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_COMPANY_GROUPS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_COMPANY_GROUPS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_COMPANY_GROUPS_FAILED
        })
      })
  }
}

export function tryCreateCompanyGroup(name: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_COMPANY_GROUP
    })

    create(name)
      .then((response) => {
        dispatch({
          type: Types.CREATE_COMPANY_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern skapad' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_COMPANY_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern kunde inte skapas' }
        })
      })
  }
}

export function tryUpdateCompanyGroup(
  id: string,
  name?: string,
  economySetId?: string | null,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_COMPANY_GROUP
    })

    update(id, name, economySetId)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_COMPANY_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern uppdaterad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_COMPANY_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryDeleteCompanyGroup(
  id: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_COMPANY_GROUP
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_COMPANY_GROUP_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern borttagen' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_COMPANY_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern kunde inte tas bort' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryAddCompanyToCompanyGroup(
  id: string,
  companyId: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.ADD_COMPANY_TO_COMPANY_GROUP
    })

    addCompanyToCompanyGroup(id, companyId)
      .then((response) => {
        dispatch({
          type: Types.ADD_COMPANY_TO_COMPANY_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern uppdaterad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.ADD_COMPANY_TO_COMPANY_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryRemoveCompanyFromCompanyGroup(
  id: string,
  companyId: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.REMOVE_COMPANY_FROM_COMPANY_GROUP
    })

    removeCompanyFromCompanyGroup(id, companyId)
      .then((response) => {
        dispatch({
          type: Types.REMOVE_COMPANY_FROM_COMPANY_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern uppdaterad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.REMOVE_COMPANY_FROM_COMPANY_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Koncern kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function setShowAsCustomerGroup(id: string | null) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_SHOW_AS_COMPANY_GROUP,
      payload: id
    })
  }
}
