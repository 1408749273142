import React from 'react'

import DropdownButton from 'components/common/DropdownButton'

import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import { DATA_TYPES } from 'helpers/Constants'

/**
 * Get and sort OutputAttributes for given dataset.
 * Sort is prioritizing primary, title and then on name.
 * @param {object} dataset - A dataset object.
 * @returns {array} Parsed and sorted array of OutputAttributes.
 */
export const getAttributes = (dataset) => {
  return dataset.attributes.output_attributes
    .map((item) => ({
      key: item.id,
      id: item.id,
      name: item.attributes.name,
      real_name: item.attributes.real_name,
      type: item.attributes.type,
      is_primary: dataset.attributes.primary_key_attribute === item.id,
      is_reference: dataset.attributes.reference_key_attribute === item.id,
      manual_input: item.attributes.manual_input
    }))
    .sort((a, b) => {
      if (a.is_primary) {
        return -1
      }
      if (a.is_reference) {
        return b.is_primary ? 1 : -1
      }

      return b.is_primary || b.is_reference ? 1 : a.name.localeCompare(b.name)
    })
}

/**
 * Get headers for schema table.
 * @param {function} onDelete - Delete function for OutputAttribute.
 * @returns {array} Array of header specifications for antd table.
 */
export const getHeaders = (onDelete, openSettings, hideEdit) => {
  return [
    {
      label: 'Namn',
      key: 'name'
    },
    {
      label: 'Attribut ID',
      key: 'real_name',
      render: function renderColumn(realName) {
        return realName
      }
    },
    {
      label: 'TYP',
      key: 'type',
      render: function renderColumn(type) {
        return DATA_TYPES[type]
      }
    },
    {
      label: '',
      width: '12%',
      render: function renderColumn(row) {
        let label = ''

        if (row.is_primary) {
          label += 'Entitetens nyckel'

          if (row.is_reference) {
            label += ' & titel'
          }
        } else if (row.is_reference) {
          label += 'Entitetens titel'
        }

        return <Text variant="subtitle2">{label}</Text>
      }
    },
    {
      label: '',
      width: '12%',
      render: function renderColumn(row) {
        return (
          <Button onClick={() => openSettings(row)} size="small" variant="text">
            Inställningar
          </Button>
        )
      },
      hidden: hideEdit
    },
    {
      label: '',
      width: '5%',
      render: function renderColumn(row) {
        return (
          <DropdownButton
            icon={'MoreHorizOutlinedIcon'}
            iconClassName={'secondary clickable'}
            items={[
              {
                header: '',
                key: 'attribute-dropdown',
                items: [
                  {
                    label: 'Ta bort',
                    remove: true,
                    onClick: () => onDelete(row)
                  }
                ]
              }
            ]}
          />
        )
      },
      hidden: hideEdit
    }
  ].filter((header) => !header.hidden)
}
