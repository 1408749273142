import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { MenuItem as MUIMenuItem } from '@mui/material'

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

export interface MenuItemProps {
  children?: ReactNode
  component?: ElementType<any>
  dense?: boolean
  disabled?: boolean
  disableGutters?: boolean
  divider?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseEnter?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseLeave?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOver?: (() => void) | MouseEventHandler<HTMLElement>
  sx?: object
  to?: string
  value?: any
  selected?: boolean
}

/**
 * The MenuItem component allow users to take
 * actions, and make choices, with a single tap
 * from a Menu.
 *
 * The MenuItem works like a Button.
 */

const MenuItem = React.forwardRef((props: MenuItemProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIMenuItem
      component={props.to ? LinkBehavior : undefined}
      {...rest}
      ref={ref}
    >
      {children}
    </MUIMenuItem>
  )
})

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to="/" {...props} role={undefined} />
)

MenuItem.displayName = 'MenuItem'

export default MenuItem
