import React from 'react'

import Styles from './styles.module.css'

const ThickHeader = ({ header = '', subHeader = '', actions = [], tag }) => {
  return (
    <div className={Styles['container']}>
      <div>
        <div className={Styles['upper-header']}>
          <p className={'bold secondary'}>{header}</p>
          {tag}
        </div>
        <small className={'secondary'}>{subHeader}</small>
      </div>
      {actions}
    </div>
  )
}

export default ThickHeader
