import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'
import { arrayToObject } from 'helpers/Functions'
import { Category } from '../Dashboards'
import { Config, General, Target } from 'types/GlobalConfig'
import { cloneDeep } from 'lodash'

interface ConfigBody {
  [key: string]: {
    category: Category
    targets: {
      [id: string]: Target
    }
    generals: {
      [attribute: string]: General
    }
  }
}

export interface ConfigReducerType {
  data: ConfigBody
  fetched: boolean
}

const INITIAL_STATE: ConfigReducerType = {
  data: {},
  fetched: false
}

const getAllAction = createAction<{ data: Config[] }>(Types.GET_TARGETS_SUCCESS)
const putTargetValuesAction = createAction<{ data: Target }>(
  Types.PUT_TARGET_VALUES_SUCCESS
)
const updateConfigAction = createAction<{
  data: { category: Category; generals: General[] }[]
}>(Types.UPDATE_CONFIG_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)

const targetReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      const data: ConfigBody = {}

      payload.data.forEach((config) => {
        data[config.category] = {
          category: config.category,
          targets: arrayToObject(config.targets) as {
            [id: string]: Target
          },
          generals: arrayToObject(config.generals, 'attribute') as {
            [attribute: string]: General
          }
        }
      })

      return {
        data,
        fetched: true
      }
    })
    .addCase(putTargetValuesAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.category]: {
            ...state.data[payload.data.category],
            targets: {
              ...state.data[payload.data.category].targets,
              [payload.data.id]: payload.data
            }
          }
        }
      }
    })
    .addCase(updateConfigAction, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)

      payload.data.forEach((config) => {
        newData[config.category].generals = arrayToObject(
          config.generals,
          'attribute'
        ) as {
          [attribute: string]: General
        }
      })

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default targetReducer
