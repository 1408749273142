import React from 'react'

import FilterOperation from './FilterOperation'
import CalculateOperation from './CalculateOperation'
import ReplaceOperation from './ReplaceOperation'
import TimeDifferenceOperation from './TimeDifferenceOperation'

const ManageOperation = ({ type, chosenAttributes, filterForm }) => {
  return type ? (
    <>
      {type === 'FILTER' && (
        <FilterOperation
          chosenAttributes={chosenAttributes}
          formRef={filterForm}
        />
      )}
      {type === 'CALCULATE' && (
        <CalculateOperation
          chosenAttributes={chosenAttributes}
          formRef={filterForm}
          type={type}
        />
      )}
      {type === 'REPLACE' && (
        <ReplaceOperation
          chosenAttributes={chosenAttributes}
          formRef={filterForm}
          type={type}
        />
      )}
      {type === 'TIME_DIFFERENCE' && (
        <TimeDifferenceOperation
          chosenAttributes={chosenAttributes}
          formRef={filterForm}
          type={type}
        />
      )}
    </>
  ) : null
}

export default ManageOperation
