import * as Types from 'redux/Types'

const INITIAL_STATE = {
  paths: [],
  labels: []
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.SET_NAVIGATION:
    return {
      paths: payload.paths,
      labels: payload.labels
    }
  case Types.SIGN_OUT:
    return INITIAL_STATE
  default:
    return state
  }
}
