import React from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import Input from 'components/common/Input'
import DataTypeIcon from 'components/common/DataTypeIcon'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import { FORM_VALIDATIONS } from 'helpers/Constants'

import Styles from './styles.module.css'

const getFormValidations = (row, key, formRef, mapping) => {
  if (row.keep) {
    const data = formRef.getFieldsValue()

    Object.keys(mapping).forEach((k) => {
      if (!mapping[k].keep || k === key) {
        delete data[k]
      }
    })

    return [
      FORM_VALIDATIONS.REQUIRED,
      FORM_VALIDATIONS.DUPLICATE_SIMPLE(Object.values(data), 'Namnet används')
    ]
  }

  return undefined
}

export const COLUMN_OPERATION_HEADERS = (formRef, mapping) => [
  {
    label: 'Kolumn',
    key: 'column',
    render: (_, row) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <DataTypeIcon type={row.type} />
          <Text variant="body1">{row.outputAttributeName}</Text>
          <Text color="text.secondary" variant="body1">
            {row.tableName}
          </Text>
        </Box>
      )
    }
  },
  {
    label: 'Nytt namn',
    key: 'key',
    render: (key, row) => {
      return (
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues[key] !== curValues[key]
          }
          className={Styles['form-item']}
        >
          {() => {
            return (
              <Form.Item
                validateFirst
                name={key}
                className={Styles['form-item']}
                rules={getFormValidations(row, key, formRef, mapping)}
              >
                <Input disabled={row.keep !== true} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  }
]

export const getInitialValues = (chosenAttributes, mapping) => {
  const initialValues = {}

  Object.keys(chosenAttributes).forEach((key) => {
    initialValues[key] = mapping[key].name
  })

  return initialValues
}

export const getColumnOperationRows = (chosenAttributes, mapping) => {
  if (!chosenAttributes) {
    return []
  }

  return Object.values(chosenAttributes).map((attr) => ({
    key: attr.outputAttributeId,
    type: attr.type,
    outputAttributeName: attr.outputAttributeName,
    tableName: attr.tableName,
    keep: mapping[attr.outputAttributeId].keep
  }))
}

export const setSelectedAttributes = (
  mapping,
  selected,
  setMapping,
  setSelectedRows
) => {
  const newMapping = _.cloneDeep(mapping)

  Object.keys(newMapping).forEach((key) => {
    newMapping[key].keep = false
  })

  selected.forEach((key) => {
    newMapping[key].keep = true
  })

  setMapping(newMapping)
  setSelectedRows(selected)
}
