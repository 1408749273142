/* Fake feature for sales and review */

import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import { WidgetNotification } from 'types/GlobalWidgetNotification'

import { Tab } from './utils'

interface WidgetNotificationTabTitleProps {
  closeEditSubscribers: () => void
  editSubscribers: WidgetNotification | null
  tab: Tab
  setTab: (tab: Tab) => void
}

const WidgetNotificationTabTitle = (props: WidgetNotificationTabTitleProps) => {
  if (Boolean(props.editSubscribers)) {
    return (
      <TitleWithBack
        onBack={() => {
          props.setTab(Tab.DEFAULT)
          props.closeEditSubscribers()
        }}
        title={'Prenumeranter'}
      />
    )
  }

  if (props.tab === Tab.DEFAULT) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          component="span"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          Notifikationer
        </Box>
        <Button
          onClick={() => props.setTab(Tab.CREATE)}
          startIcon={<Icon name="NotificationAddOutlined" />}
        >
          Lägg till
        </Button>
      </Box>
    )
  }

  if (props.tab === Tab.CREATE) {
    return (
      <TitleWithBack
        onBack={() => props.setTab(Tab.DEFAULT)}
        title={'Skapa notifikation'}
      />
    )
  }

  return null
}

interface TitleWithBackProps {
  onBack: () => void
  title: string
}

const TitleWithBack = (props: TitleWithBackProps) => {
  return (
    <Box
      component="span"
      onClick={props.onBack}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'text.secondary',
        '&:hover': {
          color: 'text.primary'
        },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}
    >
      <Icon name="ArrowBackOutlined" sx={{ mr: 0.5 }} />
      <Box
        component="span"
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {props.title}
      </Box>
    </Box>
  )
}

export default WidgetNotificationTabTitle
