import React from 'react'

import Sources from 'components/containers/DataPlatform/collect/Sources'
import Specific from 'components/containers/DataPlatform/collect/Specific/Wrapper'

export const PAGE_MENU_ITEMS = (match, activeSource, source) => [
  {
    text: 'System',
    path: '/data-platform/collect/systems',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      ),
    items: [
      {
        text: 'Lägg till system',
        subPath: '/create'
      }
    ]
  },
  {
    text: 'Databaser',
    path: '/data-platform/collect/databases',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      ),
    items: [
      {
        text: 'Lägg till databas',
        subPath: '/create'
      }
    ]
  },
  {
    text: 'Strömmar',
    path: '/data-platform/collect/streams',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      ),
    items: [
      {
        text: 'Lägg till ström',
        subPath: '/create'
      }
    ]
  },
  {
    text: 'Filer',
    path: '/data-platform/collect/files',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      ),
    items: [
      {
        text: 'Lägg till fil',
        subPath: '/create'
      }
    ]
  },
  {
    text: 'Anpassade',
    path: '/data-platform/collect/custom',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      ),
    items: [
      {
        text: 'Lägg till anpassad',
        modal: true
      }
    ]
  },
  { text: 'divider' },
  {
    text: 'Alla datakällor',
    path: '/data-platform/collect/sources',
    children:
      match.params.id && activeSource ? (
        <Specific activeSource={activeSource} source={source} />
      ) : (
        <Sources />
      )
  }
]
