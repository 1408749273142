import React, { ReactNode, ElementType } from 'react'
import { TableContainer as MUITableContainer } from '@mui/material'

export interface TableContainerProps {
  children?: ReactNode
  component?: ElementType<any>
  sx?: object
}

/**
 * The TableContainer component is a...
 */

const TableContainer = React.forwardRef(
  (props: TableContainerProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUITableContainer {...rest} ref={ref}>
        {children}
      </MUITableContainer>
    )
  }
)

TableContainer.displayName = 'TableContainer'
export default TableContainer
