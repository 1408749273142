import React, { useState } from 'react'

import { translateFunnelStatusType } from 'utils/enumTranslator'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import OverflowText from 'components_new/molecules/OverflowText'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import {
  formatPercentageDiffValue,
  formatValue,
  getUnitLabel
} from '../../utils'

import { KpiOptionObject } from 'types/GlobalKpiOption'
import { FunnelStage } from 'types/GlobalWidget'

interface FunnelEventProps {
  interactive: boolean
  kpiOption: KpiOptionObject
  maxValue: number
  setDetailsDialog: (value: string) => void
  stage: FunnelStage
  totalValue: number
  value: number
}

const FunnelEvent = (props: FunnelEventProps) => {
  const {
    interactive,
    kpiOption,
    maxValue,
    setDetailsDialog,
    stage,
    totalValue,
    value
  } = props
  const [hover, setHover] = useState<boolean>(false)

  const funnelStatus = translateFunnelStatusType[stage.status]

  const percentage = ((value || 0) / totalValue) * 100

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0px',
          minWidth: 0,
          cursor: interactive ? 'pointer' : 'default'
        }}
        onClick={() => {
          if (interactive) {
            setDetailsDialog(stage.title)
          }
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box sx={{ pr: 1, width: 'fit-content', maxWidth: '100%' }}>
          <OverflowText
            color={'inherit'}
            variant="body2"
            tooltip={true}
            sx={{ fontWeight: 'bold' }}
          >
            {stage.title}
          </OverflowText>
          <Box
            sx={{
              height: '16px',
              borderLeft: 1,
              borderTop: 1,
              borderColor: 'divider',
              borderRadius: '8px 0 0 0'
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            borderLeft: 1,
            borderColor: 'divider'
          }}
        >
          {stage.index !== 0 ? (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 2,
                width: '18px',
                height: '18px',
                left: '-9px',
                borderRadius: '9px',
                bgcolor: 'action.focus',
                color: 'white.main',
                pointerEvents: 'none'
              }}
            >
              <Icon name="ChevronRightTwoTone" sx={{ fontSize: 18 }} />
            </Box>
          ) : null}
          <Tooltip
            title={
              <>
                <Text variant="body2" gutterBottom>
                  <b>{stage.title}</b>
                </Text>
                <Text variant="body2">
                  <b>
                    {formatValue(
                      value,
                      kpiOption.unit_prefix.selected,
                      kpiOption.number_of_decimals,
                      true,
                      kpiOption.equivalent.factor
                    )}{' '}
                    {getUnitLabel(
                      kpiOption.unit,
                      kpiOption.unit_prefix,
                      kpiOption.equivalent.unit
                    )}
                  </b>
                </Text>
                <Text variant="body2" color="white.dark" gutterBottom>
                  {!funnelStatus ? (
                    <>{formatPercentageDiffValue(percentage)}% av total</>
                  ) : (
                    'Inkluderas ej i total'
                  )}
                </Text>
              </>
            }
            placement="top"
            open={hover}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 0,
                position: 'relative',
                height: funnelStatus ? '100%' : `${(value / maxValue) * 100}%`,
                bgcolor:
                  !funnelStatus && !hover
                    ? 'action.focus'
                    : !funnelStatus && hover
                        ? 'action.disabled'
                        : hover && funnelStatus
                          ? `${funnelStatus.color}.transparent`
                          : undefined,
                borderWidth: funnelStatus ? '0 2px 0 2px' : undefined,
                borderColor: funnelStatus
                  ? `${funnelStatus.color}.main`
                  : undefined,
                borderStyle: funnelStatus ? 'dotted' : undefined,
                px: 0.25,
                transition: 'transform 0.2s ease-in-out',
                transform: hover && !funnelStatus ? 'scaleY(1.05)' : undefined
              }}
            >
              <Box
                sx={{
                  minWidth: 0,
                  textAlign: 'center',
                  overflowX: 'hidden',
                  overflowY: 'visible'
                }}
              >
                {funnelStatus ? (
                  <Icon
                    name={funnelStatus.iconName}
                    color={funnelStatus.color}
                  />
                ) : null}
                <OverflowText
                  color={
                    funnelStatus ? `${funnelStatus.color}.main` : 'inherit'
                  }
                  variant="body2"
                  tooltip={false}
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  {formatValue(
                    value,
                    kpiOption.unit_prefix.selected,
                    kpiOption.number_of_decimals,
                    true,
                    kpiOption.equivalent.factor
                  )}{' '}
                  {getUnitLabel(
                    kpiOption.unit,
                    kpiOption.unit_prefix,
                    kpiOption.equivalent.unit
                  )}
                </OverflowText>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </>
  )
}

export default FunnelEvent
