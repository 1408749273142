import { getSimFlawSettings, getSimFlaws } from 'redux/api/internal/SimFlaws'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { FlawType } from 'types/GlobalSimFlaw'

export function tryGetFlawSettings() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_GET_FLAW_SETTINGS
    })

    getSimFlawSettings()
      .then((response) => {
        dispatch({
          type: Types.INTERNAL_GET_FLAW_SETTINGS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_GET_FLAW_SETTINGS_FAILED
        })
      })
  }
}

export function tryGetSimFlaws(
  customerId: string,
  tables: string[],
  offset: number,
  flawType: FlawType | null
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_GET_SIM_FLAWS
    })

    getSimFlaws(customerId, tables, offset, flawType)
      .then((response) => {
        dispatch({
          type: Types.INTERNAL_GET_SIM_FLAWS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_GET_SIM_FLAWS_FAILED
        })
      })
  }
}
