import React from 'react'

import Text from 'components_new/atoms/Text'

import VirtualizedTable from 'components_new/molecules/VirtualizedTable'

import { InviteMessage } from 'types/GlobalInviteMessages'

interface InviteMessageTemplateProps {
  messages: InviteMessage[]
}

const InviteMessageTemplate = (props: InviteMessageTemplateProps) => {
  const { messages } = props

  return (
    <>
      <Text variant="h5" sx={{ lineHeight: 2 }}>
        Skickade meddelanden
      </Text>
      <Text sx={{ lineHeight: 2 }}>
        Här kan man se alla meddelanden som användare har valt att skicka med
        när de bjuder in en ny användare.
      </Text>
      <VirtualizedTable
        data={messages}
        loading={false}
        endReached={() => {}}
        columns={[
          { id: '1', dataKey: 'message', label: 'Meddelande', width: 'auto' },
          {
            id: '2',
            dataKey: 'invited',
            label: 'Inbjuden användare',
            width: 'auto'
          },
          {
            id: '3',
            dataKey: 'invited_by',
            label: 'Inbjuden av',
            width: 'auto'
          }
        ]}
        filters={{}}
        setFilters={() => {}}
      />
    </>
  )
}

export default InviteMessageTemplate
