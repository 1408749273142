import React from 'react'
import { Theme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'

import Marker from '../Marker'

interface ClusterProps {
  count: number
  kpis: string[]
  onClick: () => void
  sums: (number | null)[]
  theme: Theme
  units: (string | null)[]
}

const Cluster = (props: ClusterProps) => {
  const { count, kpis, onClick, sums, theme, units } = props

  return (
    <Marker
      body={
        <Table noDividers={true} size="small">
          <TableBody>
            {kpis.map((kpi, i) => (
              <TableRow key={kpi}>
                <TableCell align="left" sx={{ py: 0, pl: 0.5 }}>
                  <Text
                    color="text.primary"
                    component="span"
                    fontWeight="medium"
                    noWrap={true}
                    variant="body2"
                  >
                    {kpis[i]}
                  </Text>
                </TableCell>
                <TableCell align="right" sx={{ py: 0, pl: 0.5 }}>
                  <Text
                    color="text.primary"
                    component="span"
                    fontWeight="medium"
                    variant="body2"
                  >
                    {sums[i]?.toLocaleString('sv-SE')}
                  </Text>
                  <Text
                    color="text.secondary"
                    component="span"
                    sx={{
                      ml: '0.25em'
                    }}
                    variant="caption"
                  >
                    {units[i]}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
      theme={theme}
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Icon color="inherit" fontSize="small" name="DomainOutlined" />
          <Text
            onClick={onClick}
            variant="body2"
            noWrap={true}
            sx={{
              color: 'common.white',
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'none'
              }
            }}
          >
            {count + ' fastigheter'}
          </Text>
        </Box>
      }
      variant="cluster"
    />
  )
}

export default Cluster
