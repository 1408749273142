import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import { getAll, create, update, destroy, upload } from 'redux/api/EconomySets'

export function tryGetAllEconomySets() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_ECONOMY_SETS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_ECONOMY_SETS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_ECONOMY_SETS_FAILED
        })
      })
  }
}

export function tryCreateEconomySet(
  name: string,
  csvFile: Blob,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_ECONOMY_SET
    })

    create(name, csvFile)
      .then((response) => {
        dispatch({
          type: Types.CREATE_ECONOMY_SET_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan skapad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ECONOMY_SET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan kunde inte skapas' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryUpdateEconomySet(
  id: string,
  name: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ECONOMY_SET
    })

    update(id, name)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ECONOMY_SET_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan uppdaterad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ECONOMY_SET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryDeleteEconomySet(
  id: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_ECONOMY_SET
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ECONOMY_SET_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan borttagen' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ECONOMY_SET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan kunde inte tas bort' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryUploadEconomySet(
  id: string,
  csvFile: Blob,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPLOAD_ECONOMY_SET
    })

    upload(id, csvFile)
      .then((response) => {
        dispatch({
          type: Types.UPLOAD_ECONOMY_SET_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan uppdaterad' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPLOAD_ECONOMY_SET_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontoplan kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
