import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tooltip from 'components_new/atoms/Tooltip'
import Text from 'components_new/atoms/Text'

interface SegmentCellProps {
  href?: string
  label: string
  onClick?: () => void
}

const SegmentCell = (props: SegmentCellProps) => {
  const { label, href, onClick } = props

  return (
    <TableCell
      align={'left'}
      sx={{
        color: 'text.primary',
        whiteSpace: 'nowrap',
        width: '1%' // For auto sized, fit content cells.
      }}
      variant={'body'}
    >
      <Tooltip title={href ? 'Öppna i källsystem' : ''}>
        {href || onClick ? (
          <Link
            onClick={!href ? onClick : undefined}
            href={href}
            underline="always"
            target={href ? '_blank' : undefined}
            rel={href ? 'noreferrer' : undefined}
          >
            {label}
            {href ? (
              <Icon
                fontSize="inherit"
                name="OpenInNewOutlined"
                sx={{ ml: 0.5 }}
              />
            ) : null}
          </Link>
        ) : (
          <Text color="text.primary">{label}</Text>
        )}
      </Tooltip>
    </TableCell>
  )
}

export default SegmentCell
