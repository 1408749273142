import React, {
  ElementType,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef
} from 'react'

import Dialog from 'components_new/atoms/Dialog'

// These are going to be replaced with our own components later.
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material'
import Box from 'components_new/atoms/Box'

interface AdvancedDialogProps {
  actions?: ReactNode
  children: ReactNode
  component?: ElementType<any>
  contentText?: ReactNode
  disableOverflow?: boolean
  footer?: ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
  hero?: ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  noGutter?: boolean
  onClose?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  PaperProps?: object
  scrollToTop?: boolean
  sx?: object
  title?: ReactNode
}

/**
 * The AdvancedDialog component is a
 * variant of a Dialog that is bigger,
 * with borders, and therefore suitable
 * for displaying more advanced content
 * (i.e. a form or a data table)
 */

const AdvancedDialog = React.forwardRef(
  (props: AdvancedDialogProps, ref: any) => {
    const {
      actions,
      children,
      contentText,
      disableOverflow,
      footer,
      fullHeight,
      hero,
      noGutter,
      title,
      scrollToTop,
      ...rest
    } = props

    const contentRef = useRef<HTMLElement>(null)

    useEffect(() => {
      if (scrollToTop && contentRef.current) {
        contentRef.current.scrollTo({ top: 0, behavior: 'auto' })
      }
    })

    return (
      <Dialog {...rest} ref={ref}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        {hero ?? null}
        <DialogContent
          ref={contentRef}
          dividers={true}
          sx={{
            height: fullHeight ? '90vh' : undefined,
            overflow: disableOverflow ? 'hidden' : undefined,
            position: 'relative',
            p: noGutter ? 0 : undefined
          }}
        >
          {props.contentText ? (
            <DialogContentText sx={{ mb: 2 }}>{contentText}</DialogContentText>
          ) : null}
          {children}
        </DialogContent>
        {footer ? (
          <Box
            sx={{
              flex: '0 0 auto',
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            {footer}
          </Box>
        ) : null}
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )
  }
)

AdvancedDialog.defaultProps = {
  maxWidth: 'md',
  fullWidth: true,
  onMouseDown: (e) => e.stopPropagation()
}

AdvancedDialog.displayName = 'AdvancedDialog'
export default AdvancedDialog
