import React from 'react'

import LogotypeIcon from 'assets/logotypes/LogotypeIcon'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

interface DynamicLogotypeProps {
  collapsed: boolean
}

/**
 * The DynamicLogotype component is used
 * to toggle our logotype between the
 * full- and icon variant.
 */

const DynamicLogotype = (props: DynamicLogotypeProps) => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Box sx={{ height: '40px', width: '40px' }}>
        <LogotypeIcon />
      </Box>
      {!props.collapsed ? (
        <Collapse in={!props.collapsed} orientation="horizontal">
          <Text color="text.primary" variant="h5" noWrap>
            Homepal
          </Text>
        </Collapse>
      ) : null}
    </Stack>
  )
}

export default DynamicLogotype
