import * as DataConf from '../Data/conf'

export const PAGE_LIMIT = 5

export const getStreamVariables = (variables) => {
  if (!variables) {
    return {
      value_source_column_id: undefined,
      pk_source_column_id: undefined,
      time_source_column_id: undefined
    }
  }

  return {
    value_source_column_id:
      variables.attributes.value_source_column_id || undefined,
    pk_source_column_id: variables.attributes.pk_source_column_id || undefined,
    time_source_column_id:
      variables.attributes.time_source_column_id || undefined
  }
}

export const parseSourceColumns = (columns) =>
  columns ? columns.map((col) => ({ value: col.id, label: col.name })) : []

export const getRows = (streamTables, page) => {
  const start = PAGE_LIMIT * (page - 1)

  return streamTables ? streamTables.slice(start, start + PAGE_LIMIT) : []
}

export const TABLE_HEADERS = [
  {
    label: 'FUNKTION',
    key: 'function',
    width: '15rem',
    render: function renderColumn(col) {
      return DataConf.AGG_TRANSLATOR[col]
    }
  },
  {
    label: 'FREKVENS',
    key: 'frequency',
    render: function renderColumn(col) {
      return DataConf.FREQUENCY_TRANSLATOR[col]
    }
  }
]

export const FUNCTION_OPTIONS = Object.entries(DataConf.AGG_TRANSLATOR).map(
  ([value, label]) => ({ value, label })
)

export const FREQUENCY_OPTIONS = Object.entries(
  DataConf.FREQUENCY_TRANSLATOR
).map(([value, label]) => ({ value, label }))
