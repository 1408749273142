import React from 'react'

import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

function getPath(path, hideText) {
  if (path && path.length > 0) {
    let span = []

    if (!hideText) {
      span.push(
        <span
          className={`secondary ${Styles['hidden-text-path']}`}
          key={'product-path-tag-not-hidden-text'}
        >
          /{' '}
        </span>
      )
    } else {
      span.push(
        <span
          key={'product-path-tag-not-hidden-text'}
          className={Styles['hidden-text-path']}
        ></span>
      )
    }

    for (let i = 0; i < path.length - 1; i++) {
      span = [
        ...span,
        <span className={'secondary'} key={`product-path-tag-${i}`}>
          {path[i]} /{' '}
        </span>
      ]
    }

    return (
      <Text variant="body1">
        {span}
        {path[path.length - 1]}
      </Text>
    )
  }

  return null
}

// Product needs to be in collect, build.
const ProductTag = ({
  product = '',
  className = '',
  path = [],
  hideText,
  smallTag = true
}) => {
  return (
    <div className={`${Styles.container} ${className}`}>
      <div className={`${Styles.cube}`}></div>
      {hideText ? null : smallTag ? (
        <small className={'secondary bold tiny'}>
          {product ? product.toUpperCase() : null}
        </small>
      ) : (
        <small className={'secondary bold'}>
          {product ? product.toUpperCase() : null}
        </small>
      )}
      {getPath(path, hideText, smallTag)}
    </div>
  )
}

export default ProductTag
