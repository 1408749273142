import * as Types from 'redux/Types'

export function setAlert(data) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_ALERT,
      payload: data
    })
  }
}

export function resetAlert() {
  return (dispatch) => {
    dispatch({
      type: Types.RESET_ALERT
    })
  }
}
