import React, { ReactNode, ElementType } from 'react'
import { TableFooter as MUITableFooter } from '@mui/material'

export interface TableFooterProps {
  children?: ReactNode
  component?: ElementType<any>
  sx?: object
  style?: object
}

/**
 * The TableFooter component is a...
 */

const TableFooter = React.forwardRef((props: TableFooterProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUITableFooter
      sx={{
        '& td': {
          borderTop: 1,
          borderColor: 'divider',
          '& th': { borderTop: 1, borderColor: 'divider' }
        }
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MUITableFooter>
  )
})

TableFooter.displayName = 'TableFooter'
export default TableFooter
