import React, { useState, useEffect } from 'react'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import Styles from './styles.module.css'

const OverlayStepper = ({
  open,
  onClose,
  onFinish,
  title,
  steps,
  onChangePage
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setActiveIndex(0)
    }
  }, [open])

  const changePage = (newPage) => {
    if (onChangePage) {
      onChangePage()
    }

    setActiveIndex(newPage)
  }

  return (
    <div
      className={`${Styles.container} ${
        !open ? Styles['container-hidden'] : ''
      }`}
    >
      <div className={Styles.header}>
        <IconButton edge="start" onClick={() => onClose()}>
          <Icon name="Close" />
        </IconButton>
        <p className={Styles['header-title']}>
          {`${title} > `}
          <span className={Styles['second-path']}>
            {steps[activeIndex]?.title}
          </span>
        </p>
        <div>
          {activeIndex === 0 || steps[activeIndex].hidePrevious ? null : (
            <Button
              onClick={() => changePage(activeIndex - 1)}
              startIcon={<Icon name="ChevronLeft" />}
              variant="text"
            >
              Föregående
            </Button>
          )}
          <Button
            disabled={!steps[activeIndex]?.valid || loading}
            endIcon={
              activeIndex === steps.length - 1 ? null : (
                <Icon name="ChevronRight" />
              )
            }
            onClick={async () => {
              setLoading(true)
              let valid = true

              if (steps[activeIndex].onNext) {
                valid = await steps[activeIndex].onNext()
              }

              if (valid) {
                if (activeIndex === steps.length - 1) {
                  onFinish()
                } else {
                  changePage(activeIndex + 1)
                }
              }

              setLoading(false)
            }}
          >
            {activeIndex === steps.length - 1 ? 'Slutför' : 'Nästa'}
          </Button>
        </div>
      </div>

      <div className={Styles['scroll-container']}>
        {steps.length > 1 ? (
          <>
            <Steps steps={steps} activeIndex={activeIndex} />
            {steps.map((step, i) => (
              <div
                style={{ display: activeIndex !== i ? 'none' : '' }}
                className={Styles.content}
                key={`step-${i}`}
              >
                {steps[i].content}
              </div>
            ))}
          </>
        ) : (
          <div className={Styles['content-center']}>{steps[0].content}</div>
        )}
      </div>
    </div>
  )
}

const Steps = ({ steps, activeIndex }) => {
  return (
    <div className={Styles['stepper-container']}>
      {steps.map((step, i) => {
        const filled = activeIndex >= i
        const filledFirstLine = i <= activeIndex
        const filledSecondLine = i < activeIndex

        return (
          <div
            key={`${step.title}-${step.description.substring(4)}-${i}`}
            className={'flex'}
          >
            <div
              className={`${Styles['step-text-container']} ${
                i !== 0 ? Styles['step-text-container-margin'] : ''
              }`}
            >
              <p>{step.title}</p>
              <p className={'secondary'}>{step.description}</p>
            </div>
            <div className={Styles.stepper}>
              {i !== 0 ? (
                <div
                  className={`${Styles['stepper-line']} ${
                    filledFirstLine ? Styles.bordered : ''
                  }`}
                />
              ) : null}
              <h2
                className={`${Styles['step-circle']} ${
                  filled ? Styles['active-style'] : Styles['inactive-style']
                }`}
              >
                {i + 1}
              </h2>
              {i !== steps.length - 1 ? (
                <div
                  className={`${Styles['stepper-line']} ${
                    filledSecondLine ? Styles.bordered : ''
                  }`}
                />
              ) : null}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default OverlayStepper
