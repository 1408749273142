import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { ListItemButton as MUIListItemButton } from '@mui/material'
import { Color } from 'themes'

interface ListItemButtonProps {
  alignItems?: 'center' | 'flex-start'
  color?: 'brand' | Color
  children: ReactNode
  component?: ElementType<any>
  dense?: boolean
  disabled?: boolean
  disableGutters?: boolean
  divider?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  selected?: boolean
  to?: string
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItemButton = React.forwardRef(
  (props: ListItemButtonProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUIListItemButton sx={{ whiteSpace: 'nowrap' }} {...rest} ref={ref}>
        {children}
      </MUIListItemButton>
    )
  }
)

ListItemButton.displayName = 'ListItemButton'
export default ListItemButton
