import React, { useState, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ShortId from 'shortid'

import Select from 'components/common/Select'

import Box from 'components_new/atoms/Box'

import Node from './index.js'

import * as Conf from './conf.js'

const LowerNode = ({ id, data, selected, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  const [originalOutputAttributes, setOriginalOutputAttributes] = useState([])

  useEffect(() => {
    if (leftAttributes.length > 0) {
      const realNamesInOutAttributes = data.outputAttributes
        .filter((attr) => attr.shortId !== data.newAttribute)
        .map((attr) => attr.realName)

      const diffLeftAttributes = leftAttributes
        .filter((attr) => !realNamesInOutAttributes.includes(attr.realName))
        .map((attr) => attr)

      if (diffLeftAttributes.length > 0) {
        const outputAttributes = leftAttributes.map((attr) => ({
          ...attr,
          rightAttribute: null,
          leftAttribute: attr.shortId,
          shortId: ShortId.generate()
        }))

        setOriginalOutputAttributes(outputAttributes)
        setData(id, {
          outputAttributes
        })
      }
    }
  }, [leftAttributes])

  useEffect(() => {
    if (
      data.outputAttributes.length > 0 &&
      originalOutputAttributes.length === 0
    ) {
      const outputAttributes = data.outputAttributes.map((attr) => {
        const leftAttribute = leftAttributes.find(
          (leftAttr) => attr.leftAttribute === leftAttr.shortId
        )

        return {
          ...attr,
          type: leftAttribute?.type || attr.type
        }
      })

      setOriginalOutputAttributes(outputAttributes)
    }
  }, [data.outputAttributes])

  return (
    <Node
      icon="TransformOutlined"
      title={'Lower'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box sx={{ width: '172px' }}>
        <Select
          className={'nodrag'}
          search
          dropdownMatchSelectWidth={false}
          ignoreLockedMode
          inNode
          size={'small'}
          placeholder={<small className="secondary tiny">Attribut</small>}
          value={data.leftAttribute}
          onChange={(val) => {
            setData(id, {
              leftAttribute: val,
              outputAttributes: originalOutputAttributes
            })
          }}
          options={Conf.parseAttributeOptions(
            originalOutputAttributes,
            data.nodeNumberMapper
          )}
        />
      </Box>
    </Node>
  )
}

function mapStateToProps({ DatasetStore }) {
  return { DatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LowerNode)
