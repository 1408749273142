import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PageMenu from 'components/common/PageMenu'

import NewRegistry from 'components/containers/DataPlatform/build/New'
import Register from 'components/containers/DataPlatform/build/Register'
import Specific from 'components/containers/DataPlatform/build/Specific/Wrapper'
import Add from 'components/containers/DataPlatform/build/Add'

import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'
import * as CmdbApiActions from 'redux/actions/DataPlatform/cmdb/Apis'
import * as TagActions from 'redux/actions/DataPlatform/Tags'
import * as NavigationActions from 'redux/actions/Navigation'

import InternalPage from 'components_new/templates/InternalTemplate'

const Overview = ({
  AuthStore,
  CmdbTableStore,
  TagStore,
  NavigationMenuStore,
  history,
  setNavigation,
  match,
  tryGetCmdbTables,
  tryGetAllTags,
  CmdbApiStore,
  tryGetAllCmdbApis
}) => {
  useEffect(() => {
    setNavigation({ paths: [], labels: [] })

    if (!AuthStore.user.is_homepal_user) {
      history.replace('/')
    }

    if (!CmdbTableStore.fetched) {
      tryGetCmdbTables()
    }

    if (!TagStore.fetched) {
      tryGetAllTags()
    }

    if (!CmdbApiStore.fetched) {
      tryGetAllCmdbApis()
    }
  }, [])

  if (!CmdbTableStore.fetched) {
    return null
  }

  return (
    <InternalPage
      AuthStore={AuthStore}
      NavigationMenuStore={NavigationMenuStore}
    >
      <PageMenu
        page={'build'}
        items={[
          {
            text: 'Register',
            path: '/data-platform/build/registers',
            children: match.params.cmdbId ? <Specific /> : <Register />,
            items: [
              {
                text: 'Skapa register',
                subPath: '/create',
                children: <Add />
              }
            ]
          }
        ]}
      />
      <NewRegistry />
    </InternalPage>
  )
}

function mapStateToProps({
  AuthStore,
  CmdbTableStore,
  TagStore,
  CmdbApiStore,
  NavigationMenuStore
}) {
  return {
    AuthStore,
    CmdbTableStore,
    TagStore,
    CmdbApiStore,
    NavigationMenuStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CmdbTableActions,
      ...TagActions,
      ...NavigationActions,
      ...CmdbApiActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview))
