import React, { useEffect, useState } from 'react'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Input from 'components/common/Input'
import StatusBanner from 'components/common/StatusBanner'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import BaseUrlField from '../BaseUrlField'

import { FORM_VALIDATIONS } from 'helpers/Constants'

import Styles from './styles.module.css'

const EditSlug = ({
  visible,
  onClose,
  customerSlug,
  slug,
  onSave,
  setAlert,
  resetAlert
}) => {
  const [form] = Form.useForm()
  const [locked, setLocked] = useState(true)

  useEffect(() => {
    if (visible) {
      setLocked(true)
      form.setFieldsValue({ slug })
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onClose={() => onClose()}
      header={'Redigera Bas-URL'}
      actions={
        <>
          <Button onClick={() => onClose()} variant="text">
            Avbryt
          </Button>
          <Button onClick={() => form.submit()}>Spara</Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <BaseUrlField
          customerSlug={customerSlug}
          slug={''}
          className={Styles['slug-width-limit']}
        />
        <Form
          layout="vertical"
          className={'width-100'}
          form={form}
          onFinish={(values) => {
            onSave(values)
            onClose()
          }}
        >
          <Form.Item name={'slug'} rules={[FORM_VALIDATIONS.REQUIRED]}>
            <Input
              size="large"
              withLock
              locked={locked}
              setLocked={() =>
                setAlert({
                  header: 'Redigera bas-URL',
                  content: (
                    <Text variant="body1">
                      Att redigera bas-URL för detta API påverkar direkt alla
                      parter som för närvarande använder ditt API. Det kan
                      orsaka problem.
                    </Text>
                  ),
                  onCancel: () => resetAlert(),
                  onOk: () => {
                    setLocked(false)
                    resetAlert()
                  },
                  cancelText: 'Avbryt',
                  okText: 'Lås upp'
                })
              }
              lockStyle={Styles.lock}
              containerClassName={'width-100'}
            />
          </Form.Item>
        </Form>
      </Box>

      <StatusBanner
        title={'Tänk på!'}
        message={
          // eslint-disable-next-line max-len
          'Alla tjänster som använder ditt API kan sluta fungera då du ändrar din bas-URL för ett API.'
        }
        type={'warning'}
        showIcon
      />
    </Modal>
  )
}

export default EditSlug
