import {
  create,
  destroy,
  getAll
} from 'redux/api/DataPlatform/connections/VitecViews'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { VitecViewPost } from 'types/GlobalVitecView'

export function tryGetAllVitecViews(activeSourceId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_VITEC_VIEWS
    })

    getAll(activeSourceId)
      .then((response) => {
        dispatch({
          type: Types.GET_VITEC_VIEWS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_VITEC_VIEWS_FAILED
        })
      })
  }
}

export function tryCreateVitecView(body: VitecViewPost) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_VITEC_VIEW
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_VITEC_VIEW_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Vitec vy har skapats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_VITEC_VIEW_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Vitec vy kunde inte skapas' }
        })
      })
  }
}

export function tryDeleteVitecView(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_VITEC_VIEW
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_VITEC_VIEW_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Vitec vy har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_VITEC_VIEW_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Vitec vy kunde inte tas bort' }
        })
      })
  }
}
