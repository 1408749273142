import _ from 'lodash'

import * as Types from 'redux/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  linkers: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let linkers = {}
  let data = {}

  switch (type) {
  case Types.GET_ALL_TAGS_SUCCESS:
    data = Functions.arrayToObject(payload.tags)

    payload.linkers.forEach((item) => {
      linkers[item.attributes.reference_id] =
          data[item.attributes.tag_id].attributes.name
    })

    return {
      ...state,
      data,
      linkers,
      fetched: true
    }

  case Types.PUT_TAG_LINKER_SUCCESS:
    return {
      ...state,
      linkers: {
        ...state.linkers,
        [payload.linker.attributes.reference_id]: payload.tag.attributes.name
      },
      data: {
        ...state.data,
        [payload.linker.attributes.reference_id]: payload.tag
      }
    }

  case Types.DELETE_TAG_LINKER_SUCCESS:
    linkers = _.cloneDeep(state.linkers)

    delete linkers[payload]

    return {
      ...state,
      linkers
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
