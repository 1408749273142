import React, { ReactNode } from 'react'
import { ListItemSecondaryAction as MUIListItemSecondaryAction } from '@mui/material'

interface ListItemSecondaryActionProps {
  children: ReactNode
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const ListItemSecondaryAction = React.forwardRef(
  (props: ListItemSecondaryActionProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MUIListItemSecondaryAction {...rest} ref={ref}>
        {children}
      </MUIListItemSecondaryAction>
    )
  }
)

ListItemSecondaryAction.displayName = 'ListItemSecondaryAction'
export default ListItemSecondaryAction
