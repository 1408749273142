import React from 'react'

import Text from 'components_new/atoms/Text'

export const FILE_TABLE_HEADERS = (locked) => [
  {
    key: 'label',
    render: function renderColumn(col) {
      return (
        <Text
          color={locked ? 'text.secondary' : 'text.primary'}
          variant="body1"
        >
          {col}
        </Text>
      )
    }
  }
]

export const FILE_ROWS = [
  {
    id: 'append',
    attributes: { label: 'Lägg till systemdata' }
  },
  {
    id: 'overwrite',
    attributes: { label: 'Ersätt befintlig systemdata' }
  }
]
