import React, { ElementType, ReactNode } from 'react'
import { CardContent as MuiCardContent } from '@mui/material'

interface CardContentProps {
  children: ReactNode
  classes?: object
  component?: ElementType<any>
  sx?: object
}

/**
 * The CardContent component is used to
 * give the content of a the right layout
 * (read: paddings, margins, text aligments
 * etc.).
 *
 * @requires Card component.
 */

const CardContent = React.forwardRef((props: CardContentProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MuiCardContent {...rest} ref={ref}>
      {children}
    </MuiCardContent>
  )
})

CardContent.displayName = 'CardContent'
export default CardContent
