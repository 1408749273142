import React from 'react'

import Modal from 'components/common/Modal'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

const ResetPassword = ({ visible, onClose, sftpSetting, tryResetPassword }) => {
  return (
    <Modal
      header={'Generera nytt lösenord'}
      visible={visible}
      onClose={() => onClose()}
      actions={
        <Button
          onClick={() => {
            const data = { id: sftpSetting.id }

            tryResetPassword(sftpSetting.attributes.active_source_id, { data })
            onClose()
          }}
        >
          Generera
        </Button>
      }
    >
      <Box sx={{ display: 'flex' }}>
        <Icon color="error" name="ErrorOutlineOutlined" sx={{ mr: 1 }} />
        <Text variant="body1">
          Är du säker på att du vill generera ett nytt lösenord? Detta kommer
          att påverka alla andra användare som använder samma konto till SFTP
          servern.
        </Text>
      </Box>
    </Modal>
  )
}

export default ResetPassword
