import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Table from 'components/common/Table'
import Pagination from 'components/common/Pagination'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'

import * as Conf from './conf.js'

const Data = ({
  activeTable,
  tryGetStreamTablePreview,
  tryGetSourceTablePreview,
  tryUpdateSourceTable,
  AuthStore,
  SourceTableStore,
  source,
  match,
  history
}) => {
  const isStream = source.attributes.type === 'Stream'
  const streamTables =
    activeTable && activeTable.included.stream_tables
      ? activeTable.included.stream_tables
      : []
  const [activeStreamTableId, setActiveStreamTableId] = useState(
    streamTables.length > 0 ? streamTables[0].id : null
  )
  const activeStreamTable = Conf.getActiveStreamTable(
    streamTables,
    activeStreamTableId
  )

  const sourceTable = SourceTableStore.data[activeTable?.id]

  const getPreview = (limit, offset, streamTableId = activeStreamTableId) => {
    if (isStream) {
      if (streamTableId) {
        tryGetStreamTablePreview(activeTable.id, streamTableId, limit, offset)
      }
    } else {
      tryGetSourceTablePreview(activeTable.id, limit, offset)
    }
  }

  useEffect(() => {
    // change of source table fetch is done in wrapper
    if (
      isStream &&
      streamTables.length > 0 &&
      !(streamTables[0].id in SourceTableStore.data)
    ) {
      getPreview(10, 0, streamTables[0].id)
    }

    if (isStream) {
      setActiveStreamTableId(
        streamTables.length > 0 ? streamTables[0].id : null
      )
    }
  }, [activeTable])

  const changeFilter = (count) => {
    getPreview(count, 0)
  }

  const changePage = (currentPage, newPage) => {
    const subtract =
      newPage > currentPage ? SourceTableStore.limit : -SourceTableStore.limit

    getPreview(SourceTableStore.limit, SourceTableStore.offset + subtract)
  }

  const currentPage = SourceTableStore.offset / SourceTableStore.limit + 1

  const amountOfRows = sourceTable?.count || 0

  const tableSelected =
    (activeTable && !isStream) || (isStream && activeStreamTable && activeTable)

  return (
    <>
      {isStream ? (
        <Box sx={{ display: 'flex', mb: 1, gap: 1 }}>
          {streamTables.map((st) => (
            <Button
              onClick={() => {
                setActiveStreamTableId(st.id)
                getPreview(SourceTableStore.limit, 0, st.id)
              }}
              key={`${st.id}_filter_buttons`}
              variant={st.id !== activeStreamTableId ? 'text' : 'contained'}
            >
              {Conf.parseName(st.attributes)}
            </Button>
          ))}
        </Box>
      ) : null}

      <Table
        loading={SourceTableStore.fetching}
        thickHeader={Conf.getThickHeader(
          activeTable,
          amountOfRows,
          changeFilter,
          activeStreamTable,
          SourceTableStore.limit,
          tableSelected
        )}
        headers={
          activeTable
            ? Conf.TABLE_HEADERS(
              sourceTable?.data.attributes.columns,
              tryUpdateSourceTable,
              AuthStore,
              activeTable.id,
              match.params.id,
              history
            )
            : []
        }
        rows={tableSelected ? sourceTable?.data.included.preview || [] : []}
        parsedFormat
        bordered
        ellipsis={false}
        scroll={{ x: 400 }}
      />
      <Pagination
        perPage={SourceTableStore.limit}
        text={'rader'}
        onChange={(newPage) => changePage(currentPage, newPage)}
        totalItems={amountOfRows}
        page={currentPage}
      />
    </>
  )
}

function mapStateToProps({ AuthStore, SourceTableStore }) {
  return { AuthStore, SourceTableStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SourceTableActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Data))
