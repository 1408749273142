import React, { FC } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import CustomizationTemplate, {
  InnerCustomizationBody
} from 'components_new/templates/settings/Customization'

import * as CustomizationActions from 'redux/actions/Admin/Customization'

import { ApplicationState } from 'redux/Stores/types'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  CustomizationStore: state.CustomizationStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...CustomizationActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type CustomizationPageProps = ConnectedProps<typeof connector>

const CustomizationPage: FC<CustomizationPageProps> = (
  props: CustomizationPageProps
) => {
  const { AuthStore, CustomizationStore, tryUpdateColorScheme } = props

  return (
    <SettingsTemplate>
      {CustomizationStore.colors && AuthStore.customer ? (
        <CustomizationTemplate
          customColors={CustomizationStore.colors}
          setCustomColors={(params: InnerCustomizationBody) =>
            tryUpdateColorScheme({ data: params })
          }
          allowWhiteLabelColors={
            AuthStore.customer.allow_access.white_label_colors
          }
          allowWhiteLabelLogotype={
            AuthStore.customer.allow_access.white_label_logotype
          }
        />
      ) : (
        <LoadingSettings
          titles={['Hämtar din organisations färger', 'Tvättar penseln']}
        />
      )}
    </SettingsTemplate>
  )
}

export default connector(CustomizationPage)
