import React, { MutableRefObject, ReactNode } from 'react'
import { Radio as MUIRadio } from '@mui/material'

interface RadioProps {
  checked?: boolean
  checkedIcon?: ReactNode
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  defaultChecked?: boolean
  disabled?: boolean
  edge?: 'end' | 'start' | false
  icon?: ReactNode
  id?: string
  inputProps?: object
  inputRef?: MutableRefObject<any>
  onChange?: () => void
  required?: boolean
  size?: 'medium' | 'small'
  sx?: object
  value?: any
}

const Radio = React.forwardRef((props: RadioProps, ref: any) => {
  return <MUIRadio {...props} ref={ref} />
})

Radio.displayName = 'Radio'
export default Radio
