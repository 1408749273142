import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/color-schemes`

export interface ColorSchemePatchBody {
  data: {
    primary_color?: string | null
    secondary_color?: string | null
    tertiary_color?: string | null
    quaternary_color?: string | null
    quintary_color?: string | null
    senary_color?: string | null
    septenary_color?: string | null
    octonary_color?: string | null
    nonary_color?: string | null
    denary_color?: string | null
    text_primary_color?: string | null
    text_secondary_color?: string | null
    background_color?: string | null
    logotype?: string | null
    widget_background_color?: string | null
  }
}

export async function update(body: ColorSchemePatchBody) {
  return axios.patch(URL, body, parseAuthenticationHeader())
}
