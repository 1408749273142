import React from 'react'
import ShortId from 'shortid'

import { Tooltip } from 'antd'

import Icon from 'components_new/atoms/Icon'

import DataTypeIcon from 'components/common/DataTypeIcon'

import Styles from './styles.module.css'

export const parseInitiationCascader = (
  productTag,
  refTableId,
  DatasetStore
) => {
  if (productTag === 'collect') {
    const value = [productTag]

    Object.values(DatasetStore.sourceSchemas.collect).forEach((table) => {
      if (table.type === 'Stream') {
        Object.values(table.tables).forEach((subTable) => {
          const refTable = Object.values(subTable.tables).find(
            (t) => t.value === refTableId
          )

          if (refTable) {
            value.push(table.value, subTable.value, refTableId)
          }
        })
      } else {
        const refTable = Object.keys(table.tables).find((k) => k === refTableId)

        if (refTable) {
          value.push(table.value, refTableId)
        }
      }
    })

    return value
  } else {
    return [productTag, refTableId]
  }

  return []
}

export const parseCascader = (value, DatasetStore) => {
  if (value && DatasetStore.sourcesFetched) {
    const product = value[0]

    if (product === 'collect') {
      const table = DatasetStore.sourceSchemas.collect[value[1]]

      return table
        ? value.length === 4
          ? [table.label, table.tables[value[2]].label]
          : [table.label]
        : null
    } else {
      return []
    }
  }

  return []
}

/**
 * Parses all output attributes.
 *
 * @param {Array<object>} outputAttributes - array of output attributes
 * @param {object} nodeNumberMapper - number mapper to specify what node
 * which attribute comes from
 * @param {Array<string>} dataTypes - optional array with data types to include
 * @returns an array or output attributes to pick from
 */
export const parseAttributeOptions = (
  outputAttributes,
  nodeNumberMapper,
  dataTypes
) =>
  [...outputAttributes]
    .filter((out) =>
      dataTypes && dataTypes.length > 0 ? dataTypes.includes(out.type) : true
    )
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .map((out) => ({
      value: out.shortId,
      searchLabel: (
        <small className={Styles['node-number-container']}>
          <div
            className={`tiny ${Styles['attribute-label']} ${Styles['in-node-text']}`}
          >
            <DataTypeIcon type={out.type} />
            <small>{out.name}</small>
          </div>
          {nodeNumberMapper && out.prepend in nodeNumberMapper ? (
            <div
              className={`${Styles['node-number']} ${Styles['node-number-select-align']}`}
            >
              {nodeNumberMapper[out.prepend]}
            </div>
          ) : null}
        </small>
      ),
      label: out.name,
      ...out
    }))

export const parseOutputAttributes = (cascaderValue, DatasetStore, prepend) => {
  if (cascaderValue) {
    const product = cascaderValue[0]

    if (product === 'collect') {
      const table = DatasetStore.sourceSchemas.collect[cascaderValue[1]]

      return table.tables[cascaderValue[2]].columns.map((col) => ({
        shortId: ShortId.generate(),
        prepend,
        realName: col.dbName,
        name: col.label,
        type: col.type,
        leftAttribute: null,
        rightAttribute: null,

        // Should depend if the column is sensitive or not
        sensitiveRefColumnId: col.isSensitive ? col.value : null
      }))
    } else if (product === 'build') {
      return DatasetStore.sourceSchemas.build[cascaderValue[1]].columns.map(
        (col) => ({
          shortId: ShortId.generate(),
          prepend,
          realName: col.dbName,
          name: col.label,
          type: col.type,
          leftAttribute: null,
          rightAttribute: null
        })
      )
    } else if (product === 'shape') {
      return DatasetStore.sourceSchemas.shape[cascaderValue[1]].columns.map(
        (col) => ({
          shortId: ShortId.generate(),
          prepend,
          realName: col.dbName,
          name: col.label,
          type: col.type,
          leftAttribute: null,
          rightAttribute: null
        })
      )
    }
  }

  return []
}

export const AGGREGATE_FUNCTIONS = [
  { value: 'first', label: 'Första' },
  { value: 'sum', label: 'Summa' }
]

export const ONLY_LEFT = 'ONLY_LEFT'
export const COLUMN = 'COLUMN'
export const STANDARD_VALUE = 'STANDARD_VALUE'
export const NUMBER_OF_CHARACTERS = 'NUMBER_OF_CHARACTERS'
export const INPUT_VALUE = 'INPUT_VALUE'

export const ONLY_LEFT_CONDITIONS = ['IS NULL', 'IS NOT NULL']
export const TODAY = '__TODAY__'
export const STANDARD_VALUES = [TODAY]
export const ONLY_TEXT_CONDITIONS = [
  'INCLUDES',
  'EXCLUDES',
  'STARTS_WITH',
  'ENDS_WITH'
]

export const isDateType = (attribute) => {
  return attribute.type === 'DATE' || attribute.type === 'TIMESTAMP'
}

export const isNumberType = (attribute) => {
  return attribute.type === 'DOUBLE' || attribute.type === 'BIGINT'
}

/**
 * Parses out a list of data types that are date types, number types or string.
 * Help function to limit
 */
export const getDataTypes = (attribute) => {
  if (!attribute) {
    return []
  }

  if (isDateType(attribute)) {
    return ['DATE', 'TIMESTAMP']
  } else if (isNumberType(attribute)) {
    return ['BIGINT', 'DOUBLE']
  }

  return ['STRING']
}

export const CONDITIONS = (type) => {
  const stringConditions =
    type?.toUpperCase() === 'STRING'
      ? [
          { value: 'INCLUDES', label: 'inkluderar' },
          { value: 'EXCLUDES', label: 'inkluderar inte' },
          { value: 'STARTS_WITH', label: 'börjar med' },
          { value: 'ENDS_WITH', label: 'slutar med' }
        ]
      : []

  return [
    { value: '=', label: 'är lika med' },
    { value: '!=', label: 'inte är lika med' },
    { value: '<', label: 'är mindre än' },
    { value: '<=', label: 'är mindre än eller lika med' },
    { value: '>', label: 'är större än' },
    { value: '>=', label: 'är större än eller lika med' },
    ...stringConditions,
    { value: 'IS NULL', label: 'är tom' },
    { value: 'IS NOT NULL', label: 'inte är tom' }
  ]
}

const TEXT = [
  { value: NUMBER_OF_CHARACTERS, label: 'Antal tecken' },
  { value: COLUMN, label: 'Kolumn' },
  { value: INPUT_VALUE, label: 'Text' }
]

const NUMBER = [
  { value: COLUMN, label: 'Kolumn' },
  { value: INPUT_VALUE, label: 'Nummer' }
]

const DATE = [
  { value: TODAY, label: 'Idag' },
  { value: COLUMN, label: 'Kolumn' },
  { value: INPUT_VALUE, label: 'Specifikt datum' }
]

const BOOL = [
  { value: COLUMN, label: 'Kolumn' },
  { value: INPUT_VALUE, label: 'Text' }
]

export const FILTER_VALUE_TYPES = {
  STRING: TEXT,
  BIGINT: NUMBER,
  DOUBLE: NUMBER,
  TIMESTAMP: DATE,
  DATE: DATE,
  BOOLEAN: BOOL
}

export const DELIMITERS = [
  { label: 'Bindestreck', value: '-' },
  { label: 'Inget tecken', value: '' },
  { label: 'Mellanslag', value: ' ' }
]

export const FEED_PRODUCT_CONVERTER = {
  collect: 'Källdata',
  build: 'Manuell data',
  shape: 'Dataset',
  cast: 'Dataprodukt'
}

export const TYPE_TRANSLATOR = {
  API: 'System',
  File: 'Fil',
  OnPremise: 'Anpassad',
  OnPremiseDirect: 'Databas',
  Stream: 'Ström',
  JDBC: 'Databas',
  Application: 'Dataapplikation'
}

/**
 * Reset affected OutputAttribute.
 *
 * @param {object} attribute - OutputAttribute to check.
 * @param {string} attributeId - Id of OutputAttribute that have changed.
 * @returns {object} Parsed OutputAttribute
 */
const parseAffectedAttribute = (attribute, attributeId) => {
  if (attribute.leftAttribute === attributeId) {
    return {
      ...attribute,
      leftAttribute: null
    }
  }
  if (attribute.rightAttribute === attributeId) {
    return {
      ...attribute,
      rightAttribute: null
    }
  }

  return attribute
}

/**
 *
 * @param {array} elements - Array of elements on the Drawing board.
 * @param {string} currentId - Current processed node id.
 * @param {object} toUpdate - Additional elements to update.
 * @param {string | null} attributeId - Id of modified attribute
 * @returns {object} Additional elements to update
 */
export const findAffected = (
  globalNodeData = {},
  currentId,
  toUpdate,
  attributeId = null
) => {
  const affected = []

  Object.values(globalNodeData).forEach((elem) => {
    if (elem.data && elem.data.leftNode === currentId) {
      toUpdate[elem.id] = {
        ...elem,
        data: {
          ...elem.data,
          leftAttribute: null,
          outputAttributes:
            elem.type === 'UNITE' ||
            elem.type === 'OUTPUT' ||
            elem.type === 'CONCATENATE'
              ? elem.data.outputAttributes
                .filter((attr) => attr.prepend !== currentId)
                .map((attr) => {
                  if (attributeId) {
                    return parseAffectedAttribute(attr, attributeId)
                  }

                  return {
                    ...attr,
                    rightAttribute: null,
                    leftAttribute: null
                  }
                })
              : [],
          filterConditions: elem.data.filterConditions
            ? elem.data.filterConditions.map((fc) => ({
              ...fc,
              leftAttribute: null,
              rightAttribute: null
            }))
            : null
        }
      }

      affected.push(elem.id)
    } else if (elem.data && elem.data.rightNode === currentId) {
      toUpdate[elem.id] = {
        ...elem,
        data: {
          ...elem.data,
          rightAttribute: null,
          outputAttributes:
            elem.type === 'UNITE' || elem.type === 'OUTPUT'
              ? elem.data.outputAttributes
                .filter((attr) => attr.prepend !== currentId)
                .map((attr) => {
                  if (attributeId) {
                    return parseAffectedAttribute(attr, attributeId)
                  }

                  return {
                    ...attr,
                    rightAttribute: null,
                    leftAttribute: null
                  }
                })
              : []
        }
      }

      affected.push(elem.id)
    }
  })

  if (affected.length > 0) {
    affected.forEach((elem) => {
      findAffected(Object.values(globalNodeData), elem, toUpdate)
    })
  }

  return toUpdate
}

export const DATE_PARSER_FORMAT_OPTIONS = [
  { label: 'DATE', value: 'DATE' },
  { label: 'TIMESTAMP', value: 'TIMESTAMP' }
]

export const DATE_FORMAT_OPTIONS = [
  {
    title: 'yyyy',
    label: 'year (4)'
  },
  {
    title: 'yy',
    label: 'year (2)'
  },
  {
    title: 'MM',
    label: 'month'
  },
  {
    title: 'dd',
    label: 'day'
  },
  {
    title: '%',
    label: 'wildcard'
  }
]

export const TIMESTAMP_FORMAT_OPTIONS = [
  {
    title: 'yyyy',
    label: 'year (4)'
  },
  {
    title: 'yy',
    label: 'year (2)'
  },
  {
    title: 'MM',
    label: 'month'
  },
  {
    title: 'dd',
    label: 'day'
  },
  {
    title: 'HH',
    label: 'hour'
  },
  {
    title: 'mm',
    label: 'minute'
  },
  {
    title: 'ss',
    label: 'second'
  },
  {
    title: 'TT',
    label: 'offset'
  }
]

export const DATE_UNITS = [
  { value: 'MINUTES', label: 'Minuter' },
  { value: 'HOURS', label: 'Timmar' },
  { value: 'DAYS', label: 'Dagar' },
  { value: 'WORKING_DAYS', label: 'Arbetsdagar' },
  { value: 'YEARS', label: 'År' }
]

export const NodeTooltipText = ({ description, tooltipText }) => (
  <Tooltip title={description} className={Styles['tooltip-container']}>
    <Icon name={'HelpOutline'} />
    <small className="tiny inherit">{tooltipText}</small>
  </Tooltip>
)
/**
 * Get the sensitive ref column id from the two attributes that are
 * being united. Prioritizes leftAttribute.
 *
 * @param {string | null} leftAttributeId - id for the left outputAttribute
 * @param {string | null} rightAttributeId - id for the right outputAttribute
 * @param {Array<object>} childAttributes - array with all attributes
 * @returns A sensitiveRefColumnId or null
 */
export const getSensitiveRefColumn = (
  leftAttributeId,
  rightAttributeId,
  childAttributes
) => {
  const leftAttribute = childAttributes.find(
    (oa) => oa.shortId === leftAttributeId
  )
  const rightAttribute = childAttributes.find(
    (oa) => oa.shortId === rightAttributeId
  )

  if (leftAttribute?.sensitiveRefColumnId) {
    return leftAttribute.sensitiveRefColumnId
  }

  return rightAttribute?.sensitiveRefColumnId
}
