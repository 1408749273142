import React from 'react'

import Chip from 'components_new/atoms/Chip'
import Timeline from 'components/common/Timeline'

import * as Functions from 'helpers/Functions'

import * as Conf from './conf'

const History = ({ activeSource }) => (
  <Timeline
    steps={[
      ...activeSource.included.runs.map((run) => {
        const tag = Conf.STATUS_TO_TAG(run)

        return {
          title: <Chip color={tag.color} label={tag.text} size="small" />,
          description: tag.description
        }
      }),
      {
        title: <Chip color="success" label="Lyckad" size="small" />,
        description: `Integration skapad ${Functions.formatDateString(
          activeSource.attributes.created_at
        )}`
      }
    ]}
  />
)

export default History
