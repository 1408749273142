import React, { useState, useEffect } from 'react'

import { Bar } from 'react-chartjs-2'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import Broken from 'assets/insight_studio/widget/broken_grey.svg'
import NoData from 'assets/insight_studio/widget/no_data_bar_chart.svg'

import Paginator from './Paginator'

import { formatNumber } from 'helpers/Functions'

import {
  getMaxValue,
  createDataMemo,
  calculateWindowSize,
  setBarData,
  showSameAxisMax
} from './conf'
import { ErrorComponent } from '../conf'

import { useTheme } from '@mui/material'

const BarChart = ({ widgetData, segmentByAttribute }) => {
  const {
    metrics = [],
    data = [],
    title,
    segment_title,
    breakdown_type = 'stacked',
    breakdown_by,
    broken,
    widget_metrics = [],
    visualization_constraint,
    amount_to_show,
    segment_group
  } = widgetData

  const [size, setSize] = useState(15)
  const [window, setWindow] = useState({ start: 0, end: size })
  const [maxValue, setMaxValue] = useState({ y: 0, rightY: 0 })
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [dataToShow, setDataToShow] = useState([])
  const [dataMemo, setDataMemo] = useState({})
  const [showRightY, setShowRightY] = useState(false)

  const noData = data.length === 0
  const tooManyMetrics = metrics.length > 20
  const error = noData || broken || tooManyMetrics
  let paginate = data.length > window.end - window.start

  if (amount_to_show) {
    paginate = amount_to_show > window.end - window.start
  }

  useEffect(() => {
    if (data.length > 0) {
      const newSize = calculateWindowSize(breakdown_type, metrics)

      setWindow({ start: 0, end: newSize })
      setSize(newSize)

      setShowRightY(
        !!metrics.find(
          (m) => m.class === 'LINE' || m.class === 'INDEPENDENT_LINE'
        )
      )

      const dateMemoDetails = createDataMemo(
        data,
        metrics,
        breakdown_type,
        segmentByAttribute,
        segment_group,
        newSize,
        amount_to_show,
        widget_metrics,
        breakdown_by
      )

      setDataToShow(dateMemoDetails.dataToShow)
      setMaxValue(
        getMaxValue(dateMemoDetails.dataToShow, metrics, breakdown_type)
      )

      setDataMemo(dateMemoDetails.dataMemo)

      setBarData(dateMemoDetails.dataMemo, setLabels, setDatasets, window)
    }
  }, [data, breakdown_type, amount_to_show, visualization_constraint])

  useEffect(() => {
    setBarData(dataMemo, setLabels, setDatasets, window)
  }, [window])

  const errorComponent = (
    <ErrorComponent
      img={broken || tooManyMetrics ? Broken : NoData}
      text={
        broken
          ? 'Sektionen är trasig'
          : tooManyMetrics
            ? 'Dina val genererar för många fördelningar'
            : 'Ingen data att visa'
      }
      className={'secondary'}
    />
  )

  const metricName =
    widget_metrics.length > 0
      ? widget_metrics[0].title || widget_metrics[0].attributeName
      : ''

  const suffixLeftYAxis =
    widget_metrics.length > 0 && widget_metrics[0].suffix_plural
      ? widget_metrics[0].suffix_plural
      : ''

  const suffixRightYAxis =
    widget_metrics.length > 1 && widget_metrics[1].suffix_plural
      ? widget_metrics[1].suffix_plural
      : ''

  const theme = useTheme()

  const chart = (
    <Box
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 1 }}
    >
      <Box
        sx={{
          flex: 1,
          minWidth: 0,
          overflow: 'hidden'
        }}
      >
        <Bar
          options={{
            responsive: true,
            onResize: (chart) => {
              const dimensions = chart.canvas.parentNode.getBoundingClientRect()

              chart.config._config.options.aspectRatio =
                dimensions.width / dimensions.height

              chart.update()
            },
            plugins: {
              legend: {
                position: 'top',
                display: metrics.length > 1 ? true : false,
                labels: {
                  pointStyle: 'circle',
                  usePointStyle: true,
                  font: { family: 'IBM Plex Sans', weight: 500, size: 10 },
                  color: theme.palette.text.secondary
                }
              }
            },
            animation: {
              duration: 0
            },
            scales: {
              x: {
                title: {
                  text: segment_title,
                  display: true,
                  font: { family: 'IBM Plex Sans', weight: 500, size: 10 },
                  color: theme.palette.text.secondary
                },
                grid: {
                  display: false,
                  stacked: breakdown_type === 'stacked'
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  text: metricName,
                  display: true,
                  font: { family: 'IBM Plex Sans', weight: 500, size: 10 },
                  color: theme.palette.text.secondary
                },
                suggestedMax: maxValue.y,
                position: 'left',
                stacked: breakdown_type === 'stacked',
                ticks: {
                  callback: (value, index, ticks) =>
                    index === ticks.length - 1 && suffixLeftYAxis !== ''
                      ? suffixLeftYAxis
                      : formatNumber(value)
                }
              },
              rightY: {
                display: showRightY,
                beginAtZero: true,
                title: {
                  // TODO, add text here when label on right y is supported.
                  text: '',
                  display: false,
                  font: { family: 'IBM Plex Sans', weight: 500, size: 10 },
                  color: theme.palette.text.secondary
                },
                suggestedMax: showSameAxisMax(widget_metrics)
                  ? maxValue.y
                  : maxValue.rightY,
                position: 'right',
                grid: {
                  drawOnChartArea: false
                },
                ticks: {
                  callback: (value, index, ticks) =>
                    index === ticks.length - 1 && suffixRightYAxis !== ''
                      ? suffixRightYAxis
                      : formatNumber(value)
                }
              }
            }
          }}
          data={{
            labels,
            datasets: datasets
          }}
        />
      </Box>
      {paginate ? (
        <Paginator
          setWindow={setWindow}
          window={window}
          data={dataToShow}
          maxValue={maxValue.y}
          metrics={metrics}
          breakdown_type={breakdown_type}
          size={size}
        />
      ) : null}
    </Box>
  )

  return (
    <>
      {title ? (
        <Box sx={{ display: 'block', width: '100%' }}>
          <Box
            sx={{
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              p: 2,
              fontWeight: '500'
            }}
          >
            <Text color="text.primary" variant="subtitle2">
              {title}
            </Text>
          </Box>
          <Divider />
        </Box>
      ) : null}
      <Box
        sx={{
          flex: 1,
          minWidth: 0,
          overflow: 'hidden'
        }}
      >
        {error ? errorComponent : chart}
      </Box>
    </>
  )
}

export default BarChart
