export const filterData = (data, filterKey, search, condition) => {
  const filteredData = []

  data.forEach((record) => {
    const valToCheck = filterKey || filterKey === 0 ? record[filterKey] : record

    if (
      condition(record) &&
      valToCheck.toLowerCase().includes(search.toLowerCase())
    ) {
      filteredData.push(record)
    }

    if (record.children && record.children.length > 0) {
      filteredData.push(
        filterData(record.children, filterKey, search, condition)
      )
    }
  })

  return filteredData.flat()
}
