import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Tooltip from 'components_new/atoms/Tooltip'

import NotificationSettings from './NotificationSettings'

import EmbeddedDialog from '../dialogs/EmbeddedDialog'
import DeleteDialog from '../dialogs/DeleteDialog'
import InformationDialog from '../dialogs/InformationDialog'

import {
  InnerWidgetPatchBody,
  ParsedSegmentPath,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { CustomColors } from 'types/GlobalCustomization'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { ExploreDialogType } from '../dialogs/ExploreDialog'
import { EXPLORE_DISABLED_TOOLTIP, getExploreLabel } from 'utils/texts'

const NON_DATA_WIDGET_TYPES = [
  WidgetType.TEXT,
  WidgetType.COMMENT,
  WidgetType.IMAGE
]

interface WidgetMenuProps {
  colors?: CustomColors | null
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  deleteWidget?: (arg0: string) => void
  editable: boolean
  exportWidgetData: () => void
  kpiTemplates: KpiTemplateRedux
  loading: boolean
  embedded: boolean
  enableFullscreen?: boolean
  fullSize: boolean
  setDetailsDialog: (open: boolean, segments: ParsedSegmentPath[]) => void
  setEdit: () => void
  setExploreDialog: React.Dispatch<React.SetStateAction<ExploreDialogType>>
  setFullSize: (arg0: boolean) => void
  showMenu?: boolean
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const WidgetMenu = (props: WidgetMenuProps) => {
  const {
    dashboardFilterOptions,
    deleteWidget = () => {},
    editable,
    embedded,
    enableFullscreen,
    exportWidgetData,
    fullSize,
    setDetailsDialog,
    setEdit,
    setExploreDialog,
    setFullSize,
    showMenu = true,
    kpiTemplates,
    loading,
    updateWidget,
    widget
  } = props

  const [informationDialogOpen, setInformationDialogOpen] =
    useState<boolean>(false)

  const [anchorPosition, setAnchorPosition] = useState<{
    x: number
    y: number
  } | null>(null)
  const open = Boolean(anchorPosition)

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorPosition({ x: event.clientX, y: event.clientY })
  }

  const handleClose = () => {
    setAnchorPosition(null)
  }

  useEffect(() => {
    if (!showMenu) {
      handleClose()
    }
  }, [showMenu])

  const hideDetails = useMemo(() => {
    return !!widget.settings.kpi_options.every(
      (option) => !option.can_show_details
    )
  }, [widget.settings.kpi_options])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [embeddedDialogOpen, setEmbeddedDialogOpen] = useState(false)

  const type = widget.settings.type.selected

  const widgetWithData = !NON_DATA_WIDGET_TYPES.includes(type)
  const exploreKpi = widget.settings.kpi_options.find(
    (option) => option.hierarchy.length > 0
  )
  const mainKpi = widget.settings.kpi_options.find(
    (option) => option.index === 0
  )

  const ICON_BUTTON_SIZE = fullSize ? 32 : 26
  const ICON_SIZE = fullSize ? 24 : 20

  return (
    <>
      {!embedded ||
      (enableFullscreen &&
        type !== WidgetType.TEXT &&
        type !== WidgetType.COMMENT) ? (
            <>
              {/* ----- BUTTONS ----- */}
              <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {type !== WidgetType.TEXT && type !== WidgetType.COMMENT ? (
                  <IconButton
                    color="disabled"
                    onClick={() => {
                      setFullSize(!fullSize)
                    }}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      name={!fullSize ? 'ZoomInOutlined' : 'ZoomOutOutlined'}
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                ) : null}
                {/* Fake feature for sales and review */}
                <NotificationSettings
                  buttonSize={ICON_BUTTON_SIZE}
                  embedded={embedded}
                  iconSize={ICON_SIZE}
                  loading={loading}
                  widget={widget}
                />
                {!embedded &&
            editable &&
            !loading &&
            type !== WidgetType.TEXT &&
            type !== WidgetType.COMMENT ? (
                      <IconButton
                        color="disabled"
                        onClick={() => setEdit()}
                        size="small"
                        sx={{
                          width: ICON_BUTTON_SIZE,
                          height: ICON_BUTTON_SIZE
                        }}
                      >
                        <Icon
                          fontSize="small"
                          name="Edit"
                          sx={{
                            fontSize: ICON_SIZE
                          }}
                        />
                      </IconButton>
                    ) : null}
                {!embedded ? (
                  <IconButton
                    color="disabled"
                    // onClick={handleClick}
                    onClick={handleClick}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      fontSize="small"
                      name="MoreVertOutlined"
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                ) : null}
              </Box>

              {/* ----- SECTIONS ----- */}
              <ThemeProvider theme={getTheme('light')}>
                <Menu
                  // anchorEl={anchorEl}
                  disableScrollLock
                  // hideBackdrop={true}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    anchorPosition
                      ? { top: anchorPosition?.y, left: anchorPosition?.x }
                      : undefined
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={handleClose}
                  onMouseDown={(e) => e.stopPropagation()}
                  open={open && showMenu}
                  PaperProps={{
                    sx: {
                      width: 360
                    }
                  }}
                  MenuListProps={{
                    pointerEvents: 'auto'
                  }}
                  sx={{
                    width: 360
                  }}
                >
                  {editable && widgetWithData ? (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        setEmbeddedDialogOpen(true)
                      }}
                    >
                      <ListItemIcon>
                        <Badge
                          color="primary"
                          variant="dot"
                          invisible={!widget.settings.embeddable}
                        >
                          <Icon name="CodeOutlined" />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Bädda in" />
                    </MenuItem>
                  ) : null}
                  {widgetWithData ? (
                    <MenuItem
                      onClick={() => {
                        handleClose()

                        setDetailsDialog(true, [])
                      }}
                      disabled={hideDetails}
                    >
                      <ListItemIcon>
                        <Icon name="TableChartOutlined" />
                      </ListItemIcon>
                      <ListItemText primary="Visa underliggande data" />
                    </MenuItem>
                  ) : null}
                  {/* eslint-disable-next-line max-len */}
                  <Tooltip title={!exploreKpi ? EXPLORE_DISABLED_TOOLTIP : null}>
                    <Box>
                      <MenuItem
                        disabled={!exploreKpi}
                        onClick={() => {
                          handleClose()

                          setExploreDialog({
                            breakdownFilterValue: undefined,
                            breakdownRelationKey: undefined,
                            customPeriod: undefined,
                            /* eslint-disable-next-line max-len */
                            hierarchy: (exploreKpi as KpiOptionObject).hierarchy,
                            kpiOptionId: (exploreKpi as KpiOptionObject).id,
                            level: 0,
                            open: true,
                            segments: [],
                            showAll: true
                          })
                        }}
                      >
                        <ListItemIcon>
                          <Icon name="SearchOutlined" />
                        </ListItemIcon>
                        <ListItemText
                          primary={getExploreLabel(
                            (exploreKpi || mainKpi)?.title.toLowerCase()
                          )}
                        />
                      </MenuItem>
                    </Box>
                  </Tooltip>
                  {widgetWithData ? (
                    <MenuItem
                      onClick={() => {
                        handleClose()

                        setInformationDialogOpen(true)
                      }}
                    >
                      <ListItemIcon>
                        <Icon name="InfoOutlined" />
                      </ListItemIcon>
                      <ListItemText primary="Visa information" />
                    </MenuItem>
                  ) : null}
                  {widgetWithData &&
              type !== WidgetType.KEY_FIGURE &&
              type !== WidgetType.FUNNEL &&
              !loading ? (
                        <MenuItem
                          onClick={() => {
                            handleClose()

                            exportWidgetData()
                          }}
                        >
                          <ListItemIcon>
                            <Icon name="Download" />
                          </ListItemIcon>
                          <ListItemText primary="Exportera till Excel" />
                        </MenuItem>
                      ) : null}
                  {editable && widgetWithData ? <Divider /> : null}
                  {editable ? (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        setDeleteDialogOpen(true)
                      }}
                      key={2}
                    >
                      <ListItemIcon>
                        <Icon name="DeleteOutlined" />
                      </ListItemIcon>
                      <ListItemText primary="Radera" />
                    </MenuItem>
                  ) : null}
                </Menu>
              </ThemeProvider>
            </>
          ) : null}

      {/*-- dialogs --*/}
      <ThemeProvider theme={getTheme('light')}>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title={widget.title}
          handleDelete={() => {
            deleteWidget(widget.id)

            setDeleteDialogOpen(false)
          }}
        />

        <EmbeddedDialog
          dashboardFilterOptions={dashboardFilterOptions}
          open={embeddedDialogOpen}
          onClose={() => setEmbeddedDialogOpen(false)}
          onChange={(embeddable) => updateWidget(widget.id, { embeddable })}
          loading={loading}
          widget={widget}
        />

        <InformationDialog
          kpiTemplates={kpiTemplates}
          onClose={() => setInformationDialogOpen(false)}
          open={informationDialogOpen}
          widget={widget}
        />
      </ThemeProvider>
    </>
  )
}

export default WidgetMenu
