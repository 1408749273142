import React, { useState, useMemo } from 'react'
import ShortId from 'shortid'
import _ from 'lodash'

import { Checkbox } from 'antd'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'

import Node from './index.js'

const ClearNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const [showAll, setShowAll] = useState(false)
  const [checked, setChecked] = useState(
    leftNode
      ? leftNode.data.outputAttributes.map((item) => {
        if (
          data.outputAttributes.find(
            (oa) => oa.prepend === item.prepend && oa.name === item.name
          )
        ) {
          return false
        }

        return true
      })
      : []
  )

  return (
    <Node icon="RuleOutlined" title={'Clear'} selected={selected}>
      <Box sx={{ width: '172px' }}>
        {leftNode
          ? leftNode.data.outputAttributes
            .slice(0, showAll ? leftNode.data.outputAttributes.length : 4)
            .map((attr, i) => (
              <Box
                key={attr.shortId}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  mb: 0.25
                }}
              >
                <Checkbox
                  checked={checked[i]}
                  onChange={() => {
                    const tmpChecked = _.cloneDeep(checked)

                    tmpChecked[i] = !checked[i]
                    setChecked(tmpChecked)

                    setData(id, {
                      outputAttributes: leftNode.data.outputAttributes
                        .map((attr, i) =>
                          tmpChecked[i]
                            ? null
                            : { ...attr, shortId: ShortId.generate() }
                        )
                        .filter(Boolean)
                    })
                  }}
                />
                <Chip
                  label={attr.name}
                  size="small"
                  sx={{ width: '100%', justifyContent: 'flex-start' }}
                />
              </Box>
            ))
          : null}
        <Button
          endIcon={
            showAll ? <Icon name="ExpandLess" /> : <Icon name="ExpandMore" />
          }
          fullWidth
          onClick={() => setShowAll(!showAll)}
          sx={{ mt: 0.5 }}
          variant="text"
        >
          {showAll ? 'Visa mindre' : 'Visa alla'}
        </Button>
      </Box>
    </Node>
  )
}

export default ClearNode
