import React, { useMemo } from 'react'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

import Box from 'components_new/atoms/Box'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'

import StatusIcon from './StatusIcon'
import StatusRow from './StatusRow'

import { getAllStatuses } from './utils'

interface StatusProps {
  kpi: KpiTemplate
  variant: 'full' | 'short'
}

const Status = (props: StatusProps) => {
  const { kpi, variant } = props

  const statuses = useMemo(() => {
    return getAllStatuses(kpi)
  }, [kpi])

  return variant === 'short' ? (
    <Box
      sx={{
        alignItems: 'center',
        width: 'fit-content',
        display: 'flex',
        gap: 0.25
      }}
    >
      {statuses?.map((status) => {
        if (
          status.variant === 'required_target_missing' &&
          status.value === 'checked'
        ) {
          return null
        } else {
          return (
            <StatusIcon
              key={status.variant}
              value={status.value}
              variant={status.variant}
            />
          )
        }
      })}
    </Box>
  ) : (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        bgcolor: 'background.paper',
        overflow: 'hidden'
      }}
    >
      <Table size="small">
        <TableBody>
          {statuses?.map((status) => {
            if (
              status.variant === 'required_target_missing' &&
              status.value === 'checked'
            ) {
              return null
            } else {
              return (
                <StatusRow
                  key={status.variant}
                  value={status.value}
                  variant={status.variant}
                />
              )
            }
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default Status
