import { createAction, createReducer } from '@reduxjs/toolkit'

import { AlertState } from 'types/GlobalAlert'

import * as Types from 'redux/Types'

// Initial state
const INITIAL_STATE: AlertState = {
  cancelText: null,
  okText: null,
  onCancel: () => {},
  onOk: () => {},
  header: null,
  contentHeader: null,
  faIcon: null,
  faIconWarning: false,
  content: null,
  visible: false,
  size: 'sm'
}

// Actions
const setAlert = createAction<{ data: any }>(Types.SET_ALERT)
const resetAlert = createAction<{ data: any }>(Types.RESET_ALERT)
const signOutSuccess = createAction(Types.SIGN_OUT)

// Reducer
const AlertReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(setAlert, (state: AlertState, action: any) => {
      const { payload } = action

      return {
        cancelText: payload.cancelText,
        okText: payload.okText,
        onCancel: payload.onCancel,
        onOk: payload.onOk,
        header: payload.header,
        contentHeader: payload.contentHeader,
        faIcon: payload.faIcon,
        faIconWarning: payload.faIconWarning,
        content: payload.content,
        size: payload.size,
        visible: true
      }
    })
    .addCase(resetAlert, () => INITIAL_STATE)
    .addCase(signOutSuccess, () => INITIAL_STATE)
    .addDefaultCase((state: AlertState) => state)
})

export default AlertReducer
