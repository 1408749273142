import React from 'react'

import {
  formatPercentageDiffValue,
  formatValue
} from 'components_new/organisms/Widget/types/utils'
import Text from 'components_new/atoms/Text'
import { UnitPrefix } from 'types/GlobalKpiOption'

interface TrendTextProps {
  difference?: number
  equivalentFactor?: number | null
  increaseIsPositive: boolean
  numberOfDecimals: number
  sx?: object
  percentage: number
  unitPrefix?: UnitPrefix | null
}

const TrendText = (props: TrendTextProps) => {
  const {
    difference,
    equivalentFactor,
    increaseIsPositive,
    numberOfDecimals,
    sx,
    percentage,
    unitPrefix
  } = props

  const status: 'error' | 'success' | 'warning' = !percentage
    ? 'warning'
    : increaseIsPositive && percentage > 0
      ? 'success'
      : !increaseIsPositive && percentage < 0
          ? 'success'
          : 'error'

  const label = `${percentage > 0 ? '▲ ' : percentage < 0 ? '▼ ' : '▶ '}${
    percentage > 0 ? '+' : ''
  }${!percentage ? '0' : formatPercentageDiffValue(percentage)}%`

  const formattedDifference = difference
    ? formatValue(
      difference,
      unitPrefix ?? null,
      numberOfDecimals,
      true,
      equivalentFactor
    )
    : 0

  return (
    <Text
      component="span"
      fontSize="inherit"
      fontWeight="medium"
      noWrap={true}
      sx={sx}
    >
      <Text
        component="span"
        fontSize="inherit"
        fontWeight="inherit"
        sx={{
          color: `${status}.text`
        }}
      >
        {label}
      </Text>
      {difference !== undefined ? (
        <Text
          color="text.primary"
          component="span"
          fontSize="inherit"
          fontWeight="inherit"
        >
          {` (${difference > 0 ? '+' : ''}${formattedDifference})`}
        </Text>
      ) : null}
    </Text>
  )
}

export default TrendText
