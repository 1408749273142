import * as Api from 'redux/api/Applications/StandardModels/v3'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryActivateStandardModel() {
  return (dispatch) => {
    dispatch({
      type: Types.ACTIVATE_STANDARD
    })

    Api.activateStandard()
      .then((response) => {
        dispatch({
          type: Types.ACTIVATE_STANDARD_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Standardmodellen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.ACTIVATE_STANDARD_FAILED
        })

        messageWarning('Standardmodellen kunde inte skapas')
      })
  }
}
