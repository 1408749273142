import React from 'react'

import { Form } from 'antd'

import Lodash from 'lodash'

import Icon from 'components_new/atoms/Icon'

import Select from 'components/common/Select'
import Input from 'components/common/Input'

import * as Constants from 'helpers/Constants'

import Styles from './styles.module.css'
import IconButton from 'components_new/atoms/IconButton'

export const MAPPING_HEADERS = (
  editMode,
  fields,
  formRef,
  remove,
  setRows,
  stream
) => [
  {
    label: 'INKOMMANDE ATTRIBUT',
    key: 'incomingAttr',
    render: function renderColumn(i) {
      return fields[i] ? (
        <Form.Item
          {...fields[i].restField}
          className={Styles['form-padding']}
          name={[fields[i].name, 'in']}
          fieldKey={[fields[i].fieldKey, 'in']}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <Select
            ignoreLockedMode
            disabled={!editMode}
            options={INPUT_ATTRIBUTES(stream)}
          />
        </Form.Item>
      ) : null
    }
  },
  {
    label: '',
    key: 'arrow',
    render: function renderColumn() {
      return <Icon fontSize="small" name="ArrowForward" />
    },
    align: 'right',
    width: '5%'
  },
  {
    label: 'UTGÅENDE ATTRIBUT',
    key: 'outgoingAttr',
    render: function renderColumn(i) {
      return fields[i] ? (
        <Form.Item
          {...fields[i].restField}
          className={Styles['form-padding']}
          name={[fields[i].name, 'out']}
          fieldKey={[fields[i].fieldKey, 'out']}
          rules={[
            Constants.FORM_VALIDATIONS.REQUIRED,
            Constants.FORM_VALIDATIONS.INVALID_CHARACTERS,
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve()
                }
                const outgoings = formRef.current
                  ? formRef.current.getFieldsValue()
                  : undefined

                if (outgoings) {
                  const currentValue = Object.values(outgoings.mappings).find(
                    (item) => item.out === value
                  )

                  const exists = Object.values(outgoings.mappings).find(
                    (item) =>
                      item.out &&
                      item.out.toLowerCase() === value.toLowerCase() &&
                      item.id !== currentValue.id
                  )

                  if (exists) {
                    return Promise.reject(
                      'Ett utgående attribut med detta namnet existrerar redan.'
                    )
                  }
                }

                return Promise.resolve()
              }
            })
          ]}
        >
          <Input
            className={'ignore-locked-mode'}
            disabled={!editMode}
            placeholder={'Utgående attribut'}
          />
        </Form.Item>
      ) : null
    }
  },
  {
    label: 'DATATYP',
    key: 'datatype',
    render: function renderColumn(i) {
      return fields[i] ? (
        <Form.Item
          {...fields[i].restField}
          className={Styles['form-padding']}
          name={[fields[i].name, 'datatype']}
          fieldKey={[fields[i].fieldKey, 'datatype']}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <Select
            ignoreLockedMode
            disabled={!editMode}
            options={Constants.DATA_TYPES_OPTIONS}
            placeholder={'Datatyp'}
          />
        </Form.Item>
      ) : null
    },
    width: '20%'
  },
  {
    label: '',
    key: 'remove',

    render: function renderColumn(i) {
      return fields[i] ? (
        <IconButton
          disabled={!editMode}
          onClick={() => {
            if (editMode) {
              const values = Lodash.cloneDeep(
                formRef.current.getFieldsValue().mappings
              )

              values.splice(i, 1)

              setRows(getMappingRows({ mappings: values }))

              remove(fields[i].name)
            }
          }}
        >
          <Icon name="Close" />
        </IconButton>
      ) : null
    }
  }
]

export const getMappingRows = (mappings) => {
  if (mappings) {
    return mappings.mappings.map((item, i) => ({
      id: item.id,
      attributes: {
        incomingAttr: i,
        outgoingAttr: i,
        datatype: i,
        remove: i
      }
    }))
  }

  return []
}

export const INPUT_ATTRIBUTES = (json) => {
  if (json.length === 0) {
    return []
  }

  const keys = Object.keys(json[0])

  return keys.map((key) => ({
    label: key,
    value: key
  }))
}
