import React, { useEffect, useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as Actions from 'redux/actions/DataPlatform/connections/VitecViews'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import AddViewDialog from './AddViewDialog'
import DeleteViewDialog from './DeleteViewDialog'
import { VitecView } from 'types/GlobalVitecView'

const mapStateToProps = (state: ApplicationState) => ({
  VitecViewsStore: state.VitecViewsStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(Actions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type VitecViewsProps = {
  activeSourceId: string
}

type Props = VitecViewsProps & ConnectedProps<typeof connector>

const VitecViews = (props: Props) => {
  const {
    activeSourceId,
    tryCreateVitecView,
    tryDeleteVitecView,
    tryGetAllVitecViews,
    VitecViewsStore
  } = props
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [deleteView, setDeleteView] = useState<VitecView | null>(null)

  useEffect(() => {
    if (activeSourceId) {
      tryGetAllVitecViews(activeSourceId)
    }
  }, [activeSourceId])

  return (
    <>
      <Box>
        <Text variant="caption">Hantera vyer för Vitec</Text>
        <TableContainer
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            my: 2
          }}
        >
          <Table size="small" padding="normal" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: 'grey.100'
                  }}
                >
                  <Text>Vy</Text>
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: 'grey.100'
                  }}
                >
                  <Text>Tabell</Text>
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: 'grey.100'
                  }}
                >
                  {''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {VitecViewsStore.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.attributes.view_name}</TableCell>
                  <TableCell>{row.attributes.output_name}</TableCell>
                  <TableCell
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setDeleteView(row)}
                      size="small"
                    >
                      Ta bort
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button variant="contained" onClick={() => setAddModalOpen(true)}>
            Lägg till
          </Button>
        </Box>
      </Box>
      <AddViewDialog
        onClose={() => setAddModalOpen(false)}
        open={addModalOpen}
        onSubmit={(body) =>
          tryCreateVitecView({ ...body, active_source_id: activeSourceId })
        }
      />
      <DeleteViewDialog
        name={deleteView ? deleteView.attributes.output_name : ''}
        onClose={() => setDeleteView(null)}
        open={!!deleteView}
        onDelete={() => tryDeleteVitecView(deleteView?.id as string)}
      />
    </>
  )
}

export default connector(VitecViews)
