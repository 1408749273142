import React from 'react'

import AddTag from 'components/containers/DataPlatform/AddTag'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

export const AGG_TRANSLATOR = {
  LARGEST: 'Största',
  SMALLEST: 'Minsta',
  FIRST: 'Första',
  LAST: 'Sista',
  AVERAGE: 'Genomsnitt',
  MEDIAN: 'Median',
  SUM: 'Summerat'
}

export const FREQUENCY_TRANSLATOR = {
  HOURLY: 'per timme',
  DAILY: 'per dag',
  WEEKLY: 'per vecka'
}

export const parseName = (attributes) => {
  return `${AGG_TRANSLATOR[attributes.function]} ${
    FREQUENCY_TRANSLATOR[attributes.frequency]
  }`
}

export const getActiveStreamTable = (streamTables, id) => {
  return streamTables.find((st) => st.id === id)
}

export const getThickHeader = (
  activeTable,
  amountOfRows,
  changeFilter,
  activeStreamTable,
  limit,
  tableSelected
) => {
  return {
    header: tableSelected ? (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <AddTag referenceId={activeTable.id} />
        <span className={'secondary'}>
          {activeStreamTable
            ? `(${parseName(activeStreamTable.attributes)})`
            : ''}{' '}
          ({amountOfRows})
        </span>
      </Box>
    ) : (
      ''
    ),
    subHeader: tableSelected ? activeTable.attributes.name : '',
    actions: tableSelected ? (
      <Box sx={{ display: 'flex' }}>
        <Button
          color={limit === 10 ? 'primary' : 'inherit'}
          onClick={() => changeFilter(10)}
          size="small"
          variant="text"
        >
          10
        </Button>
        <Button
          color={limit === 100 ? 'primary' : 'inherit'}
          onClick={() => changeFilter(100)}
          size="small"
          variant="text"
        >
          100
        </Button>
      </Box>
    ) : null
  }
}

export const TABLE_HEADERS = (columns = []) =>
  columns.map((column) => {
    return {
      label: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <AddTag ignoreLockedMode referenceId={column.id} />
          <small className={'secondary tiny'}>{column.name}</small>
        </Box>
      ),
      render: function renderColumn(row) {
        if (row[column.athena_name] === true) {
          return 'Sant'
        }
        if (row[column.athena_name] === false) {
          return 'Falskt'
        }

        return row[column.athena_name]
      }
    }
  })
