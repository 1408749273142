import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

export function toggleSnackbarClosed() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.TOGGLE_SNACKBAR_CLOSED
    })
  }
}

export function toggleSnackbarOpen(message: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.TOGGLE_SNACKBAR_OPEN,
      payload: { message }
    })
  }
}
