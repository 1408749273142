import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DropdownButton from 'components/common/DropdownButton'
import Pagination from 'components/common/Pagination'
import Table from 'components/common/Table'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'

import * as FlawActions from 'redux/actions/DataPlatform/dataQuality/Flaws'
import * as QualityActions from 'redux/actions/DataPlatform/dataQuality/Quality'

import Filter from './Filter'
import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Flaws = ({
  DatasetStore,
  FlawStore,
  QualityStore,
  match,
  history,
  tryExportFlaws,
  tryGetAllFlaws,
  tryGetQualityOverview
}) => {
  const [page, setPage] = useState(1)
  const urlParams = new URLSearchParams(history.location.search)
  const filter = urlParams.get('attribute')
  const sort = urlParams.get('sort')

  const table = DatasetStore.data[match.params.tableId]
  const invalidRows = FlawStore.invalidCount
  const totalRows = FlawStore.totalCount

  useEffect(() => {
    if (!QualityStore.fetched) {
      tryGetQualityOverview()
    }
  }, [])

  useEffect(() => {
    getFlaws()
  }, [page, filter, sort])

  const getFlaws = () => {
    tryGetAllFlaws(match.params.tableId, (page - 1) * 10, filter, sort)
  }

  return (
    table && (
      <Box sx={{ p: 2 }}>
        <Paper sx={{ p: 2 }}>
          <Filter
            filter={filter}
            table={table}
            history={history}
            invalidRows={invalidRows}
            totalRows={totalRows}
          />
        </Paper>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Table
            loading={FlawStore.fetching}
            onChange={(pagination, filters, sorter) => {
              let sortVal = sorter.field

              if (sorter.order === 'DESC') {
                sortVal = `-${sortVal}`
              }

              history.push({
                search: Conf.updateSearchParams(history, 'sort', sortVal)
              })
            }}
            className={Styles['table-container']}
            thickHeaderClassName={Styles['table-header']}
            headers={Conf.FLAW_TABLE_HEADERS(
              table.attributes.output_attributes,
              QualityStore.data[match.params.tableId],
              filter
            )}
            rows={
              table.attributes.output_attributes.length > 0
                ? FlawStore.data.map((flaw, i) => ({
                  ...flaw.attributes,
                  key: `table-flaw-${i}`
                }))
                : []
            }
            parsedFormat
            thickHeader={{
              header: `Rader med ogiltiga värden (${invalidRows})`,
              actions: (
                <DropdownButton
                  icon={'DownloadOutlined'}
                  iconClassName={Styles['download-icon']}
                  items={[
                    {
                      key: 'download-button',
                      items: [
                        {
                          label: 'Exportera som .xlsx',
                          onClick: () =>
                            tryExportFlaws(match.params.tableId, 'xlsx', filter)
                        },
                        {
                          label: 'Exportera som .csv',
                          onClick: () =>
                            tryExportFlaws(match.params.tableId, 'csv', filter)
                        }
                      ]
                    }
                  ]}
                />
              )
            }}
            scroll={{ x: table.attributes.output_attributes.length * 130 }}
            customSort
          />
          <Pagination
            perPage={Conf.PAGE_LIMIT}
            text={'rader'}
            onChange={(value) => {
              setPage(value)
            }}
            totalItems={invalidRows}
            page={page}
          />
        </Paper>
      </Box>
    )
  )
}

function mapStateToProps({ DatasetStore, FlawStore, QualityStore }) {
  return { DatasetStore, FlawStore, QualityStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlawActions, ...QualityActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Flaws))
