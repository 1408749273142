import React, { MouseEventHandler, ReactNode } from 'react'
import { Checkbox as MUICheckbox } from '@mui/material'

interface CheckboxProps {
  checked?: boolean
  checkedIcon?: ReactNode
  children?: ReactNode
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  defaultChecked?: boolean
  disabled?: boolean
  edge?: 'end' | 'start' | false
  icon?: ReactNode
  id?: string
  indeterminate?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onChange?: () => void
  required?: boolean
  size?: 'medium' | 'small'
  sx?: object
  value?: any
}

/**
 * The Checkbox toggle the state of a single
 * setting on or off.
 */

const Checkbox = React.forwardRef((props: CheckboxProps, ref: any) => {
  return <MUICheckbox {...props} ref={ref} />
})

Checkbox.displayName = 'Checkbox'
export default Checkbox
