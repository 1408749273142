import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form } from 'antd'

import Table from 'components/common/Table'
import Pagination from 'components/common/Pagination'
import ThickHeader from 'components/common/ThickHeader'
import Select from 'components/common/Select'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'
import * as AlertActions from 'redux/actions/Alert'

import * as Functions from 'helpers/Functions'
import * as Constants from 'helpers/Constants'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Aggregation = ({
  activeTable,
  tryPutStreamVariables,
  tryCreateStreamTable,
  tryDeleteStreamTable,
  SourceStore,
  match,
  setAlert,
  resetAlert
}) => {
  const [page, setPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState([])
  const [showAggregationForm, setShowAggregationForm] = useState(false)
  const [streamVariables, setStreamVariables] = useState(
    Conf.getStreamVariables(activeTable?.included.stream_variables)
  )
  const [aggregations, setAggregations] = useState(
    Conf.getRows(activeTable?.included.stream_tables, page)
  )
  const sourceColumns = Conf.parseSourceColumns(activeTable?.attributes.columns)
  const prevUpdating = Functions.usePrevious(SourceStore.updating)

  // successful put of variables or create aggregation
  useEffect(() => {
    if (prevUpdating && !SourceStore.updating) {
      setStreamVariables(
        Conf.getStreamVariables(activeTable?.included.stream_variables)
      )
      setAggregations(Conf.getRows(activeTable?.included.stream_tables, page))

      // reset aggregation form
      if (showAggregationForm) {
        setShowAggregationForm(false)
      }
    }
  }, [SourceStore.updating])

  useEffect(() => {
    setStreamVariables(
      Conf.getStreamVariables(activeTable?.included.stream_variables)
    )
    setAggregations(Conf.getRows(activeTable?.included.stream_tables, page))
  }, [activeTable, page])

  return (
    <div className={Styles['aggregation-wrapper']}>
      <Variables
        streamVariables={streamVariables}
        sourceColumns={sourceColumns}
        disabled={!activeTable}
        onUpdate={(attributes) =>
          tryPutStreamVariables(activeTable.id, match.params.id, {
            data: {
              attributes
            }
          })
        }
      />
      <div className={Styles['actions-container']}>
        {selectedRows.length === 1 && (
          <Button
            onClick={() =>
              setAlert({
                isOpen: true,
                header: 'Bekräfta borttagning',
                contentHeader: 'Ta bort aggregering',
                faIcon: 'faTrash',
                okText: 'Ta bort',
                cancelText: 'Avbryt',
                onOk: () => {
                  tryDeleteStreamTable(
                    selectedRows[0],
                    activeTable.id,
                    match.params.id
                  )
                  resetAlert()
                },
                content:
                  // eslint-disable-next-line max-len
                  'Är du säker på att du vill ta bort aggregeringen? Detta kan resultera i att applikationer som är beroende av data slutar fungera.'
              })
            }
          >
            Ta bort
          </Button>
        )}
        {showAggregationForm ? (
          <Button onClick={() => setShowAggregationForm(false)}>Avbryt</Button>
        ) : (
          <Button
            onClick={() => setShowAggregationForm(true)}
            disabled={!activeTable}
            startIcon={<Icon name="AddCircleOutlineOutlined" />}
          >
            Lägg till aggregering
          </Button>
        )}
      </div>
      <Table
        headers={Conf.TABLE_HEADERS}
        rows={aggregations}
        rowClassName={Styles.row}
        thickHeader={{
          header: 'Aggregeringar'
        }}
        selectedRows={selectedRows}
        setSelectedRows={(rows) => setSelectedRows(rows)}
      />
      {showAggregationForm && (
        <AggregationForm
          create={(attributes) =>
            tryCreateStreamTable(activeTable.id, match.params.id, {
              data: { attributes }
            })
          }
        />
      )}
      <Pagination
        totalItems={activeTable?.included.stream_tables.length || 0}
        perPage={Conf.PAGE_LIMIT}
        text={'aggregeringar'}
        onChange={(page) => setPage(page)}
      />
    </div>
  )
}

const Variables = ({ streamVariables, sourceColumns, onUpdate, disabled }) => (
  <div className={Styles['variables-container']}>
    <ThickHeader header={'Variabler'} />
    <div className={'divider'} />
    <div className={Styles['variables-content']}>
      <div className={Styles['variables-item']}>
        <Icon name="SignalCellularAlt" />
        <p>Värde:</p>
        <Select
          placeholder={'Välj attribut'}
          value={streamVariables.value_source_column_id}
          onChange={(value) =>
            onUpdate({
              ...streamVariables,
              value_source_column_id: value
            })
          }
          options={sourceColumns}
          className={Styles['variables-item-select']}
          disabled={disabled}
          clearable={false}
        />
      </div>
      <div className={Styles['variables-item']}>
        <Icon name="KeyOutlined" />
        <p>Id:</p>
        <Select
          placeholder={'Välj attribut'}
          value={streamVariables.pk_source_column_id}
          onChange={(value) =>
            onUpdate({
              ...streamVariables,
              pk_source_column_id: value
            })
          }
          options={sourceColumns}
          className={Styles['variables-item-select']}
          disabled={disabled}
        />
      </div>
      <div className={Styles['variables-item']}>
        <Icon name="AccessTimeOutlined" />
        <p>Tid:</p>
        <Select
          placeholder={'Välj attribut'}
          value={streamVariables.time_source_column_id}
          onChange={(value) =>
            onUpdate({
              ...streamVariables,
              time_source_column_id: value
            })
          }
          options={sourceColumns}
          className={Styles['variables-item-select']}
          disabled={disabled}
          clearable={false}
        />
      </div>
    </div>
  </div>
)

const AggregationForm = ({ create }) => (
  <Form
    layout={'vertical'}
    initialValues={{ function: undefined, frequency: undefined }}
    onFinish={(values) => create(values)}
  >
    <div className={Styles['form-container']}>
      <div className={Styles['form-container-inputs']}>
        <Form.Item
          name={'function'}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <Select
            placeholder={'Välj funktion'}
            className={Styles['form-container-inputs-item']}
            options={Conf.FUNCTION_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          name={'frequency'}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
        >
          <Select
            placeholder={'Välj frekvens'}
            className={Styles['form-container-inputs-item']}
            options={Conf.FREQUENCY_OPTIONS}
          />
        </Form.Item>
      </div>
      <Button type="submit">Spara</Button>
    </div>
  </Form>
)

function mapStateToProps({ SourceStore }) {
  return { SourceStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...SourceTableActions, ...AlertActions },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Aggregation))
