import _ from 'lodash'

import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {}
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  let newData = {}

  switch (type) {
  case Types.GET_ALL_CMDB_ROWS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.tableId]: {
          data: Functions.arrayToObject(payload.data),
          fetched: true
        }
      }
    }

  case Types.SAVE_REGISTER_CHANGES_SUCCESS:
    newData = _.cloneDeep(state.data[payload.table.id].data)

    if (payload.rows.deleted.succeeded) {
      Functions.objectToArray(payload.rows.deleted.succeeded).forEach(
        (i) => delete newData[i]
      )
    }

    if (payload.rows.puts.succeeded) {
      payload.rows.puts.succeeded.forEach((item) => (newData[item.id] = item))
    }

    return {
      ...state,
      data: {
        ...state.data,
        [payload.table.id]: { data: newData }
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
