import * as Api from 'redux/api/Applications/Endpoints/v3'
import * as Types from 'redux/Types'
import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryUpdateEndpoint(id, attributes) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_ENDPOINT
    })

    const body = {
      data: {
        attributes
      }
    }

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ENDPOINT_SUCCESS,
          payload: response.data.data
        })
        messageSuccess('Objekt har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ENDPOINT_FAILED
        })
        messageWarning('Objekt kunde inte uppdateras')
      })
  }
}
