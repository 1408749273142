import React, { useState, useEffect, useRef } from 'react'
import ShortId from 'shortid'

import Box from 'components_new/atoms/Box'

import { getMaxValue, addStyleDocument, removeStyleDocument } from './conf'
import Styles from './styles.module.css'

const Paginator = ({
  data,
  window,
  setWindow,
  maxValue,
  metrics,
  breakdownType,
  size
}) => {
  const [tiles, setTiles] = useState([])
  const [id] = useState(`id-${ShortId.generate()}`)
  const ref = useRef(null)

  const percent = 100 / data.length

  if (ref.current) {
    ref.current.style.setProperty(`--${id}-width`, `${percent * size}%`)
  }

  useEffect(() => {
    addStyleDocument(id)

    ref.current.style.setProperty(`--${id}-width`, `${percent * size}%`)

    return () => {
      removeStyleDocument(id)
    }
  }, [])

  useEffect(() => {
    const widthPercent = percent + 0.001
    const width = `${widthPercent}%`

    const newTiles = data.map((row, i) => {
      const maxValueInRow = getMaxValue([row], metrics, breakdownType).y

      return (
        <Box
          key={i}
          style={{
            height: `${24 - (maxValueInRow / maxValue) * 24}px`,
            left: `${i * percent}%`,
            width: width
          }}
          className={Styles['paginate-ticker']}
        />
      )
    })

    setTiles(newTiles)
  }, [data, maxValue])

  return (
    <Box
      sx={{
        mt: 1,
        position: 'relative'
      }}
    >
      <input
        id={id}
        ref={ref}
        className={Styles['pagination-slider']}
        type="range"
        value={window.start}
        min="0"
        max={data.length - size}
        onInput={(e) => {
          setWindow({
            start: parseInt(e.target.value),
            end: parseInt(e.target.value) + size
          })
        }}
      />
      {tiles}
    </Box>
  )
}

export default Paginator
