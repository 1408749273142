import React, { useEffect, useState, useMemo, useRef } from 'react'

import { Layout } from 'react-grid-layout'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, useParams } from 'react-router-dom'

import * as AccountActions from 'redux/actions/Accounts'
import * as AdditionalSegmentActions from 'redux/actions/AdditionalSegments'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as SIMActions from 'redux/actions/SIM'
import * as DashboardFilterOptionActions from 'redux/actions/DashboardFilterOptions'
import * as WidgetActions from 'redux/actions/Widgets'
import * as KpiOptionActions from 'redux/actions/KpiOptions'
import * as WidgetTagActions from 'redux/actions/WidgetTags'

import { AspectRatio } from 'redux/reducers/Dashboards'
import { ApplicationState } from 'redux/Stores/types'
import {
  CustomPeriodFilter,
  FormattedWidgetData,
  FunnelStagePutBody,
  InnerWidgetPatchBody,
  PeriodFilter,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'
import { AccountRole } from 'types/GlobalUser'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

import { isCustomPeriodFilter, isPeriodFilterEnum } from 'utils/functions'

import {
  getWidgetQueryParams,
  downloadPNG,
  findAvailableSpace,
  formatWidgetData,
  convertWidgetDataToExport,
  getDetailsQueryParams
} from './utils'

import DashboardGrid from 'components_new/organisms/DashboardGrid'
import Box from 'components_new/atoms/Box'
import Widget from 'components_new/organisms/Widget'

import Loading from './loading'

interface DashboardProps {
  gridRef: any | null
  setAvailableSpace: (value: Layout | false) => void
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
}

const Dashboard = (props: ComponentProps) => {
  const {
    gridRef,
    setAvailableSpace,
    dashboardFilter,
    periodFilter,
    setDashboardFilter,
    // redux stores:
    AccountStore,
    AuthStore,
    CompanyGroupStore,
    CustomizationStore,
    KPIDashboardStore,
    KPITemplateStore,
    DashboardFilterOptionsStore,
    // redux actions:
    tryCreateAdditionalSegmentBy,
    tryCreateKpiOption,
    tryCreateWidgetTag,
    tryExportDetails,
    tryExportWidgetData,
    tryDeleteAdditionalSegmentBy,
    tryDeleteKpiOption,
    tryDeleteWidget,
    tryDeleteWidgetTag,
    tryGetDetails,
    tryUpdateKpiOption,
    tryUpdateWidgetTag,
    tryPutFunnelStages,
    tryUpdateAdditionalSegmentBy,
    tryUpdateDashboardLayout,
    tryUpdateWidget,
    tryUploadDashboardThumbnail,
    tryUploadWidgetImage,
    tryGetDashboardFilterData
  } = props

  const params = useParams<{ id: string }>()
  const history = useHistory()

  const signedInUser = AuthStore.user
  const isHomepalUser = !!signedInUser?.is_homepal_user
  const isAdmin = signedInUser?.role === AccountRole.ADMIN

  useEffect(() => {
    const { tryGetAllKPIs, tryGetAllFilterOptions, tryGetOneDashboard } = props

    if (params.id !== KPIDashboardStore.activeFetchOne) {
      tryGetOneDashboard(params.id, () => history.push('/dashboards'))
    }

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      tryGetAllKPIs()
    }

    if (
      !KPITemplateStore.fetchedFilterOptions &&
      !KPITemplateStore.fetchingFilterOptions
    ) {
      tryGetAllFilterOptions()
    }
  }, [params.id])

  const dashboard = KPIDashboardStore.data[params.id]
  const widgets = dashboard?.widgets || []
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  const isOwner = group?.owner === AuthStore.user?.id

  const editable = isAdmin || isHomepalUser || isOwner

  // Needs to be a ref
  const isDownloadReadyRef = useRef(false)

  useEffect(() => {
    const allFetched = Object.values(widgets).every(
      (widget) =>
        widget.data ||
        [WidgetType.TEXT, WidgetType.COMMENT, WidgetType.IMAGE].includes(
          widget.settings.type.selected
        ) ||
        widget.status.broken
    )

    const isMainDashboard =
      dashboard && group ? group.dashboards[0] === dashboard.id : false

    isDownloadReadyRef.current = allFetched && isMainDashboard
  }, [widgets, params.id])

  useEffect(() => {
    // Listen to route changes
    const unlisten = history.listen(() => {
      // Save the thumbnail before the route changes
      if (gridRef.current && isDownloadReadyRef.current) {
        downloadPNG(gridRef.current, (thumbnail: Blob | null) => {
          if (thumbnail && !AuthStore?.user?.is_company_group) {
            tryUploadDashboardThumbnail(dashboard.id, thumbnail)
          }
        })
      }
    })

    // Clean up event listener when the component unmounts
    return () => {
      unlisten()
    }
  }, [history])

  // We need to rebuild this.
  useEffect(() => {
    // Fetch data for new/edited widgets
    const { tryGetOneWidget } = props

    if (KPIDashboardStore.currentWidgetId && dashboard) {
      const widget = dashboard?.widgets[KPIDashboardStore.currentWidgetId]

      tryGetOneWidget(
        widget.id,
        widget.settings.type.selected,
        widget.settings.show_total,
        widget.settings.segment_by.additional,
        dashboard.id,
        getWidgetQueryParams(
          widget,
          dashboardFilter,
          periodFilter,
          CompanyGroupStore.showAs
        )
      )
    }
  }, [KPIDashboardStore.currentWidgetId])

  useEffect(() => {
    if (params.id in KPIDashboardStore.data) {
      KPIDashboardStore.data[params.id].dashboard_filters.forEach(
        (dashboardFilter) => {
          if (
            !(dashboardFilter.relation_key in DashboardFilterOptionsStore.data)
          ) {
            tryGetDashboardFilterData(dashboardFilter.relation_key)
          }
        }
      )
    }
  }, [KPIDashboardStore.data])

  const [dashboardWidgets, setWidgets] = useState<string[]>([])

  useEffect(() => {
    if (Boolean(widgets)) {
      setWidgets(Object.values(widgets).map((widget) => widget.id))

      setAvailableSpace(findAvailableSpace(layout))
    }
  }, [widgets])

  const formattedWidgets = useMemo(() => {
    return dashboardWidgets.map((id) => {
      const widget = widgets[id]

      if (!widget) {
        return null
      }

      return {
        id,
        data: formatWidgetData(widget),
        status: widget.status,
        widget,
        type: widget.settings.type.selected
      }
    })
  }, [dashboardWidgets, widgets])

  const layout: { x: number; y: number; w: number; h: number; i: string }[] =
    useMemo(() => {
      const newLayout = dashboardWidgets
        .filter((id: string) => widgets[id])
        .map((id: string) => {
          const widget = widgets[id]

          return {
            x: widget.layout.x,
            y: widget.layout.y,
            w: widget.layout.width,
            h: widget.layout.height,
            i: id
          }
        })

      setAvailableSpace(findAvailableSpace(newLayout))

      return newLayout
    }, [dashboardWidgets])

  // loading
  if (
    KPIDashboardStore.activeFetchOne !== params.id ||
    !AccountStore.fetched ||
    !KPIDashboardStore.fetched
  ) {
    return <Loading />
  }

  // success
  return (
    <DashboardGrid
      colors={CustomizationStore?.colors}
      editable={editable}
      embedded={false}
      gridRef={gridRef}
      layout={layout}
      ratio={AspectRatio.AUTO}
      title={dashboard.title}
      updateLayout={(layout) =>
        tryUpdateDashboardLayout(dashboard.id, {
          data: {
            layout: layout.map((l) => ({
              width: l.w,
              height: l.h,
              x: l.x,
              y: l.y,
              id: l.i
            }))
          }
        })
      }
    >
      {({ scaleFactor }) => {
        return formattedWidgets
          .map(
            (
              formattedWidget: {
                id: string
                data: FormattedWidgetData
                type: WidgetType
                status: {
                  broken: boolean
                  required_data_modelling_missing: boolean
                  required_target_missing: boolean
                }
                widget: WidgetObject
              } | null
            ) => {
              if (!formattedWidget) {
                return null
              }

              const { id, data, type, status, widget } = formattedWidget

              return (
                <Box key={id} sx={{ position: 'relative' }}>
                  <Widget
                    allowBenchmarking={
                      AuthStore.customer?.allow_access.benchmarking
                    }
                    colors={CustomizationStore?.colors}
                    createAdditionalSegmentBy={(attributeOptionId) =>
                      tryCreateAdditionalSegmentBy(
                        {
                          widget_id: widget.id,
                          attribute_option_id: attributeOptionId
                        },
                        dashboard.id
                      )
                    }
                    createKpiOption={(body: KpiOptionPostAttributes) =>
                      tryCreateKpiOption(body, dashboard.id)
                    }
                    createWidgetTag={(body: WidgetTagPostAttributes) =>
                      tryCreateWidgetTag(body, dashboard.id)
                    }
                    customer={AuthStore.customer}
                    customPeriodFilter={
                      periodFilter && isCustomPeriodFilter(periodFilter)
                        ? periodFilter
                        : null
                    }
                    dashboardFilter={dashboardFilter}
                    // eslint-disable-next-line max-len
                    dashboardFilterOptions={dashboard.dashboard_filters.map(
                      (filter) => {
                        // eslint-disable-next-line max-len
                        const option = DashboardFilterOptionsStore.options.find(
                          (opt) => opt.relation_key === filter.relation_key
                        )

                        return {
                          attribute_id: filter.attribute_id,
                          title: option?.title || '',
                          relation_key: filter.relation_key,
                          relation_name: option?.relation_name ?? null,
                          index: filter.index,
                          options:
                            DashboardFilterOptionsStore.data[
                              filter.relation_key
                            ]
                        }
                      }
                    )}
                    deleteAdditionalSegmentBy={(id) =>
                      // eslint-disable-next-line max-len
                      tryDeleteAdditionalSegmentBy(id, widget.id, dashboard.id)
                    }
                    deleteKpiOption={(id: string) =>
                      tryDeleteKpiOption(id, widget.id, dashboard.id)
                    }
                    deleteWidget={() =>
                      tryDeleteWidget(widget.id, dashboard.id)
                    }
                    deleteWidgetTag={(id: string) =>
                      tryDeleteWidgetTag(id, widget.id, dashboard.id)
                    }
                    details={KPIDashboardStore.details}
                    editable={editable}
                    exportDetails={(
                      kpiOptionId,
                      ext,
                      sort,
                      filters,
                      customPeriodFilter
                    ) => {
                      const fileName = widget.title

                      const queryParams = getDetailsQueryParams(
                        widget,
                        dashboardFilter,
                        customPeriodFilter || periodFilter,
                        sort,
                        filters,
                        CompanyGroupStore.showAs
                      )

                      // eslint-disable-next-line max-len
                      tryExportDetails(kpiOptionId, fileName, ext, queryParams)
                    }}
                    exportWidgetData={() => {
                      // eslint-disable-next-line max-len
                      const { headers, rows } = convertWidgetDataToExport(
                        widget,
                        data
                      )

                      tryExportWidgetData(headers, rows, widget.title)
                    }}
                    fetchedKpis={KPITemplateStore.fetchedKpis}
                    filterOptions={KPITemplateStore.filterOptions}
                    formattedData={data}
                    getDetails={(
                      kpiOptionId,
                      offset,
                      sort,
                      filters,
                      customPeriodFilter
                    ) => {
                      const queryParams = getDetailsQueryParams(
                        widget,
                        dashboardFilter,
                        customPeriodFilter || periodFilter,
                        sort,
                        filters,
                        CompanyGroupStore.showAs
                      )

                      tryGetDetails(kpiOptionId, offset, queryParams)
                    }}
                    isAdmin={isAdmin}
                    kpiTemplates={KPITemplateStore.data}
                    kpiTemplatesFetched={KPITemplateStore.fetchedKpis}
                    loading={
                      !widget.data &&
                      type !== WidgetType.TEXT &&
                      type !== WidgetType.COMMENT &&
                      type !== WidgetType.IMAGE &&
                      !status.broken
                    }
                    periodFilter={
                      periodFilter && isPeriodFilterEnum(periodFilter)
                        ? periodFilter
                        : null
                    }
                    putFunnelStages={(body: FunnelStagePutBody) =>
                      tryPutFunnelStages(widget.id, dashboard.id, body)
                    }
                    scaleFactor={scaleFactor}
                    setDashboardFilter={setDashboardFilter}
                    showAs={CompanyGroupStore.showAs}
                    // eslint-disable-next-line max-len
                    updateAdditionalSegmentBy={(id, attributeOptionId) =>
                      tryUpdateAdditionalSegmentBy(
                        id,
                        { attribute_option_id: attributeOptionId },
                        dashboard.id
                      )
                    }
                    updateKpiOption={(
                      id: string,
                      body: KpiOptionPatchAttributes
                    ) => tryUpdateKpiOption(id, body, dashboard.id)}
                    updateWidget={(
                      widgetId: string,
                      attribute: InnerWidgetPatchBody
                    ) => {
                      tryUpdateWidget(
                        widgetId,
                        { data: { ...attribute } },
                        !!periodFilter
                      )
                    }}
                    updateWidgetTag={(
                      id: string,
                      body: WidgetTagPatchAttributes
                    ) => tryUpdateWidgetTag(id, body, dashboard.id)}
                    uploadImage={(image: Blob, name: string) =>
                      tryUploadWidgetImage(widget.id, image, name)
                    }
                    widget={widget}
                  />
                </Box>
              )
            }
          )
          .filter(Boolean)
      }}
    </DashboardGrid>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  CustomizationStore: state.CustomizationStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore,
  NavigationMenuStore: state.NavigationMenuStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AdditionalSegmentActions,
      ...AccountActions,
      ...DashboardActions,
      ...DashboardFilterOptionActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...SIMActions,
      ...WidgetActions,
      ...WidgetTagActions,
      ...KpiOptionActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector> & DashboardProps

export default connector(Dashboard)
