import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { withRouter } from 'react-router-dom'

import AuthTemplate from 'components_new/templates/AuthTemplate'
import MainTemplate from 'components_new/templates/MainTemplate'

import ActivateAccount from './ActivateAccount'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import SignIn from './SignIn'

import { getSession, getUserFromEmail, getIdpLoginAction } from './AuthModule'

import {
  LOGIN,
  FORGOT_PASSWORD,
  VERIFICATION_CODE,
  SET_PASSWORD,
  SIGNED_IN,
  LOGIN_WITH_AZURE
} from './utils'

import {
  handleIdp,
  handleLogin,
  handleSetPassword,
  handleForgotPassword,
  handleVerificationCode,
  handleErrors
} from './ActionHandlers'
import { signOut } from './AuthModule'

const Auth = ({
  children,
  setLoggedIn,
  validating,
  isAuthenticated,
  history
}) => {
  const [blank, setBlank] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(null)
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [idpAction, setIdpAction] = useState(null)
  const [invitedBy, setInvitedBy] = useState(null)
  const [dashboardName, setDashboardName] = useState(null)
  const [formRef] = Form.useForm()
  const [errors, setErrors] = useState([])

  useEffect(() => {
    setBlank(true)

    if (window.location.search.includes('&confirmation_code=')) {
      const email = window.location.search.split('email=')[1].split('&')[0]
      const code = window.location.search.split('confirmation_code=')[1]

      setConfirmationCode(code)
      setUser(getUserFromEmail(email.toLowerCase()))
      setState(VERIFICATION_CODE)
      setBlank(false)
    } else if (window.location.search.includes('?code=')) {
      const errors = handleIdp(setState, setBlank)

      handleErrors(errors, formRef)
    } else if (
      window.location.search.includes('email=') &&
      window.location.search.includes('chal=')
    ) {
      const handleCreateAccount = () => {
        const email = window.location.search.split('email=')[1].split('&')[0]

        const chal = window.location.search.split('chal=')[1]
        const redirectOnError = () => {
          history.push('/login')
          setState(LOGIN)
        }

        handleLogin(
          { email: email, password: chal },
          setState,
          setUser,
          redirectOnError
        )
        setBlank(false)
        setEmail(email)
      }

      signOut()
        .then(() => {
          handleCreateAccount()
        })
        .catch(() => handleCreateAccount())
    } else {
      getSession()
        .then(() => {
          setState(SIGNED_IN)
          setBlank(false)
        })
        .catch(() => {
          setState(LOGIN)
          setBlank(false)
        })
    }
  }, [])

  useEffect(() => {
    if (state === LOGIN) {
      getIdpLoginAction(setState).then((action) => {
        setIdpAction(action)
      })
    }
  }, [state])

  useEffect(() => {
    if (state === SIGNED_IN && !isAuthenticated && !validating) {
      setLoggedIn()
    }
  }, [state])

  useEffect(() => {
    if (!isAuthenticated && state) {
      setState(LOGIN)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (
      state &&
      state !== SIGNED_IN &&
      window.location.pathname.includes('dashboards')
    ) {
      const splitSearch = window.location.search.split('&')
      /*
        When inviting a user, the search link and thereby the split should 
        generate the following array:
        [
          ?email={username},
          chal={####},
          invitedBy=${event.request.clientMetaData.invitedBy}
          dashboardName=${event.request.clientMetaData.dashboardName}
        ]
      */

      splitSearch.forEach((queryParam) => {
        if (queryParam.includes('invitedBy')) {
          setInvitedBy(queryParam.split('=')[1])
        } else if (queryParam.includes('dashboardName')) {
          setDashboardName(queryParam.split('=')[1])
        }
      })

      history.push('/login', { redirectPath: window.location.pathname })
    } else if (
      state &&
      state !== SIGNED_IN &&
      window.location.pathname !== '/login'
    ) {
      history.push('/login')
    }
  }, [state])

  if ((blank && state !== SIGNED_IN) || !state) {
    return null
  }

  return state === SIGNED_IN ? (
    <>{children}</>
  ) : (
    <MainTemplate>
      <AuthTemplate>
        {state === LOGIN ? (
          <SignIn
            errors={errors}
            loading={loading}
            onForgetPassword={() => setState(FORGOT_PASSWORD)}
            onSignIn={async (email, password) => {
              setLoading(true)

              const errors = await handleLogin(
                { email, password },
                setState,
                setUser
              )

              setErrors(errors)

              setLoading(false)
            }}
            onSignInWithMicrosoft={() => setState(LOGIN_WITH_AZURE)}
            setErrors={(errors) => setErrors(errors)}
          />
        ) : null}

        {state === FORGOT_PASSWORD ? (
          <ForgotPassword
            errors={errors}
            loading={loading}
            onBackToSignIn={() => setState(LOGIN)}
            onForgotPassword={async (email, setPasswordHasBeenReset) => {
              setLoading(true)

              const errors = await handleForgotPassword(
                { email },
                setPasswordHasBeenReset
              )

              setErrors(errors)

              setLoading(false)
            }}
            setErrors={(errors) => setErrors(errors)}
          />
        ) : null}

        {state === VERIFICATION_CODE ? (
          <ResetPassword
            confirmationCode={confirmationCode}
            errors={errors}
            loading={false}
            onBackToSignIn={() => setState(LOGIN)}
            onResetPassword={async (code, password) => {
              setLoading(true)

              const errors = await handleVerificationCode(
                { password, code },
                setState,
                user
              )

              setErrors(errors)

              setLoading(false)
            }}
            setErrors={(errors) => setErrors(errors)}
          />
        ) : null}

        {state === SET_PASSWORD ? (
          <ActivateAccount
            dashboardName={dashboardName}
            email={email || user?.username}
            errors={errors}
            invitedBy={invitedBy}
            loading={loading}
            onActivateAccount={async (password, firstName, lastName) => {
              setLoading(true)

              const errors = await handleSetPassword(
                { password, first_name: firstName, last_name: lastName },
                setState,
                user,
                email || user?.username
              )

              setErrors(errors)

              setLoading(false)
            }}
            setErrors={(errors) => setErrors(errors)}
          />
        ) : null}

        {/* state === LOGIN_WITH_AZURE ? (
        <SignInWithMicrosoft
          errors={errors}
          onSignInWithMicrosoft={async (email) => {
            setLoading(true)

            const errors = await handleLoginWithAzure({ email })

            setErrors(errors)

            setLoading(false)
          }}
          loading={loading}
          onSignInWithHomepal={() => setState(LOGIN)}
          setErrors={(errors) => setErrors(errors)}
        />
      ) : null */}
      </AuthTemplate>
    </MainTemplate>
  )
}

export default withRouter(Auth)
