import React, { useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import TabSwitch from 'components_new/molecules/TabSwitch'

import { AccountRole, translateAccountRole } from 'types/GlobalUser'
import { CompanyGroup } from 'types/GlobalCompanyGroups'

enum SwitchTabValues {
  COLLEAGUE = 'COLLEAGUE',
  COMPANY_GROUP = 'COMPANY_GROUP'
}

const translateSwitchTabValues: Record<SwitchTabValues, string> = {
  [SwitchTabValues.COLLEAGUE]: 'Kollega',
  [SwitchTabValues.COMPANY_GROUP]: 'Koncernanvändare'
}

interface AddAccountProps {
  companyGroups?: CompanyGroup[]
  hideNames?: boolean
  hideRole?: boolean
  onInvite: (
    email: string,
    message: string,
    firstName: string,
    lastName: string,
    role: AccountRole,
    companyGroupId?: string
  ) => void
  showInviteCompanyGroup?: boolean
  sx?: object
}

const AddAccount = (props: AddAccountProps) => {
  const {
    companyGroups = [],
    hideNames = false,
    hideRole = false,
    onInvite,
    showInviteCompanyGroup,
    sx
  } = props

  const [dialogTab, setDialogTab] = useState<SwitchTabValues>(
    SwitchTabValues.COLLEAGUE
  )
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [message, setMessage] = useState('')
  const [role, setRole] = useState<AccountRole>(AccountRole.VIEW)
  const [companyGroupId, setCompanyGroupId] = useState<string | null>(null)

  const validEmail = email.length > 0 && email.includes('@')
  const emptyFirstName = firstName.length === 0
  const emptyLastName = lastName.length === 0
  const validCompanyGroup =
    dialogTab === SwitchTabValues.COLLEAGUE || !!companyGroupId

  const [showErrors, setShowErrors] = useState(false)

  const validFields =
    validEmail &&
    (hideNames || (!emptyFirstName && !emptyLastName)) &&
    validCompanyGroup

  const validate = () => {
    if (validFields) {
      setShowErrors(false)

      return true
    } else {
      setShowErrors(true)

      return false
    }
  }

  const handleSubmit = () => {
    if (validate()) {
      onInvite(
        email,
        message,
        firstName,
        lastName,
        role,
        companyGroupId || undefined
      )

      setEmail('')
      setFirstName('')
      setLastName('')
      setMessage('')
      setRole(AccountRole.VIEW)
      setCompanyGroupId(null)
    }
  }

  return (
    <>
      <Collapse in={showInviteCompanyGroup}>
        <TabSwitch
          options={Object.values(SwitchTabValues).map((value) => ({
            value,
            label: translateSwitchTabValues[value]
          }))}
          value={dialogTab}
          onChange={(value) => {
            if (value === SwitchTabValues.COLLEAGUE) {
              setCompanyGroupId(null)
            }
            if (value === SwitchTabValues.COMPANY_GROUP) {
              setRole(AccountRole.VIEW)
            }

            setDialogTab(value as SwitchTabValues)
          }}
          color={'primary'}
          sx={{ mt: -2, mb: 2 }}
        />
      </Collapse>
      <Stack spacing={2} alignItems="flex-start" direction="column" sx={sx}>
        {hideNames ? null : (
          <Stack spacing={1} sx={{ width: '100%' }}>
            <TextField
              label="Förnamn"
              required={true}
              autoComplete="off"
              error={showErrors && emptyFirstName}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              helperText={
                showErrors && emptyFirstName
                  ? 'Du måste ange ett förnamn.'
                  : undefined
              }
              sx={{ marginTop: 0, marginBottom: 0 }}
            />
            <TextField
              label="Efternamn"
              required={true}
              autoComplete="off"
              error={showErrors && emptyLastName}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              helperText={
                showErrors && emptyLastName
                  ? 'Du måste ange ett efternamn.'
                  : undefined
              }
              sx={{ marginTop: 0, marginBottom: 0 }}
            />
          </Stack>
        )}
        <TextField
          label="Email"
          required={true}
          autoComplete="off"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          error={showErrors && !validEmail}
          helperText={
            showErrors && !validEmail
              ? 'Du måste ange en giltig email. Exempel: hej@homepal.se'
              : undefined
          }
          sx={{ marginTop: 0, marginBottom: 0 }}
        />
        {hideRole || dialogTab === SwitchTabValues.COMPANY_GROUP ? null : (
          <TextField
            label={'Välj roll...'}
            select
            onChange={(e) => setRole(e.target.value as AccountRole)}
            value={role}
          >
            {Object.values(AccountRole)
              .filter(
                (opt) => opt === AccountRole.VIEW || opt === AccountRole.ADMIN
              )
              .map((opt) => {
                return (
                  <MenuItem value={opt} key={opt}>
                    {translateAccountRole[opt].display}
                  </MenuItem>
                )
              })}
          </TextField>
        )}
        {dialogTab === SwitchTabValues.COMPANY_GROUP ? (
          <TextField
            error={showErrors && !validCompanyGroup}
            helperText={
              showErrors && !validCompanyGroup
                ? 'Du måste välja en koncern.'
                : undefined
            }
            label={'Välj koncern...'}
            select
            onChange={(e) => setCompanyGroupId(e.target.value)}
            value={companyGroupId}
          >
            {companyGroups.map((cg) => {
              return (
                <MenuItem value={cg.id} key={cg.id}>
                  {cg.name}
                </MenuItem>
              )
            })}
          </TextField>
        ) : null}
        <Collapse in={validFields}>
          <TextField
            label={'Meddelande'}
            autoComplete="off"
            value={message}
            rows={3}
            multiline
            onChange={(event) => setMessage(event.target.value)}
            sx={{ my: 0 }}
          />
          <Alert severity="info" sx={{ mt: 2 }}>
            <Text fontSize="inherit" color="inherit" gutterBottom={true}>
              <b>Tänk på!</b> Personen har nog aldrig använt Homepal innan.
            </Text>
            <Text fontSize="inherit" color="inherit">
              Det kan hjälpa mycket att bifoga ett kort meddelande i din
              inbjudan för att förklara <i>varför</i> personen har blivit
              inbjuden och <i>vad</i> den ska göra.
            </Text>
          </Alert>
        </Collapse>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          Bjud in
        </Button>
      </Stack>
    </>
  )
}

export default AddAccount
