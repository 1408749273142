import React, { ReactNode, useState } from 'react'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { PaperProps, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'
import Box from 'components_new/atoms/Box'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

export type Option = {
  group?: string
  id: string | number | null
  label: string | number | null
}

interface SelectSearchProps {
  closeOnSelect?: boolean
  color?: 'primary' | 'secondary'
  customIcon?: ReactNode
  disabled?: boolean
  externalLabel?: string
  helperText?: ReactNode
  label?: string
  lastOption?: ReactNode
  multiple?: boolean
  // List if multiple=true
  onChange: ((params: Option) => void) | ((params: Option[]) => void)
  options: Option[]
  placeholder?: string
  size?: 'small' | 'medium'
  sx?: object
  selected: Option[] | Option | null // List if multiple=true
  sort?: boolean
}

const SelectSearch = React.forwardRef((props: SelectSearchProps, ref: any) => {
  const [inputValue, setInputValue] = useState<string>('')

  const showGroups =
    props.options[0]?.group &&
    !props.options.every(({ group }) => group === props.options[0].group)

  const getSortedOptions = (): any[] => {
    return props.options.sort((a, b) => {
      if (showGroups && a.group && b.group) {
        if (a.group.toLowerCase() === b.group.toLowerCase()) {
          return sortAlphabeticalAsc(a.label, b.label)
        }

        return sortAlphabeticalAsc(a.group, b.group)
      }

      return sortAlphabeticalAsc(a.label, b.label)
    })
  }

  const LightPaper = (paperProps: PaperProps) => {
    return (
      <ThemeProvider theme={getTheme('light')}>
        <Paper>
          {paperProps.children}
          {props.lastOption ? (
            <Box
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {props.lastOption}
            </Box>
          ) : null}
        </Paper>
      </ThemeProvider>
    )
  }

  return (
    <>
      {props.externalLabel ? (
        <Text color="text.primary" variant="body2" fontWeight="medium">
          {props.externalLabel}
        </Text>
      ) : null}
      <Autocomplete
        autoHighlight={true}
        disableCloseOnSelect={!props.closeOnSelect}
        disabled={props.disabled}
        filterSelectedOptions={true}
        groupBy={showGroups ? (option) => option.group : undefined}
        helperText={props.helperText}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        ListboxProps={{
          sx: {
            maxHeight: 200
          }
        }}
        multiple={props.multiple ?? true}
        onChange={(event, newValue) => {
          setInputValue('')
          props.onChange(newValue)
        }}
        onClose={() => setInputValue('')}
        openOnFocus={true}
        options={props.sort ? getSortedOptions() : props.options}
        PaperComponent={LightPaper}
        ref={ref}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color={props.color ?? undefined}
              label={option.label}
              size={props.size ?? 'small'}
              sx={{
                maxWidth: 'calc(100% - 36px)'
              }}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(ev) => setInputValue(ev.target.value)}
            label={props.label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    sx={{ width: 24, height: 24 }}
                  >
                    {props.customIcon ?? <Icon name="Search" />}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
            placeholder={props.placeholder ?? 'Sök...'}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} disabled={option.disabled} key={option.id}>
            {option.label}
          </MenuItem>
        )}
        size={props.size ?? 'small'}
        sx={{
          width: '100%',
          maxWidth: '100%',
          minWidth: 0,
          mt: props.label ? 1 : undefined,
          '& .MuiFormControl-root': {
            m: 0
          },
          ...props.sx
        }}
        value={props.selected}
      />
    </>
  )
})

SelectSearch.displayName = 'SelectSearch'
export default SelectSearch
