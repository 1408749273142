import React from 'react'

import Text from 'components_new/atoms/Text'

import SimpleDialog from 'components_new/molecules/SimpleDialog'
import Button from 'components_new/atoms/Button'

type Props = {
  name: string
  open: boolean
  onClose: () => void
  onDelete: () => void
}

const DeleteViewDialog = (props: Props) => {
  const { onClose, onDelete, open, name } = props

  return (
    <SimpleDialog
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onDelete()
              onClose()
            }}
          >
            Ta bort
          </Button>
        </>
      }
      onClose={close}
      open={open}
      title="Ta bort"
    >
      <Text>
        Vitec vyn{' '}
        <b>
          &quot;
          {name}
          &quot;
        </b>{' '}
        kommer att raderas permanent. Är du säker?
      </Text>
    </SimpleDialog>
  )
}

export default DeleteViewDialog
