import { TNavigationItem } from 'components_new/molecules/NavigationItem'
import { AuthenticationState } from 'types/GlobalAuthentication'

export const getNavigationItems = (
  AuthStore: AuthenticationState
): TNavigationItem[] => {
  return [
    {
      icon: 'PaletteOutlined',
      href: '/admin/settings/customization',
      title: 'Anpassningar'
    },
    {
      icon: 'GroupOutlined',
      href: '/admin/settings/users',
      title: 'Användare'
    },
    {
      icon: 'VerifiedUserOutlined',
      href: '/admin/settings/authentication',
      title: 'Autentisering'
    },
    {
      icon: 'ApartmentTwoTone',
      href: '/admin/settings/companies',
      title: 'Bolag',
      hidden: !AuthStore.customer?.is_management_company
    },
    {
      icon: 'AccountTreeTwoTone',
      href: '/admin/settings/chart-of-accounts',
      title: 'Kontoplaner'
    },
    {
      icon: 'PaidOutlined',
      href: '/admin/settings/economy',
      title: 'Ekonomiska nyckeltal'
    },
    {
      icon: 'TuneOutlined',
      href: '/admin/settings/config',
      title: 'Konfigurering'
    },
    {
      icon: 'LocalPoliceOutlined',
      href: '/admin/settings/license',
      title: 'Licens'
    },
    {
      icon: 'TitleOutlined',
      href: '/admin/settings/terminologi',
      title: 'Terminologi'
    }
  ]
}
