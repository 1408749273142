import * as Types from 'redux/Types'

export function setNavigation(data) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_NAVIGATION,
      payload: data
    })
  }
}
