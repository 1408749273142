import React from 'react'

import Box from 'components_new/atoms/Box'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import { DatasetType } from 'types/GlobalWidget'

import { getCellAlignment } from '../../../types/Table/utils'

interface BodyCellProps {
  label: string | number | null | string[] | number[]
  type: DatasetType
  variant?: 'body' | 'footer' | 'head'
}

const BodyCell = (props: BodyCellProps) => {
  const { label, type, variant } = props

  return (
    <TableCell
      align={getCellAlignment(type)}
      sx={{
        whiteSpace: 'nowrap',
        width: '1%' // For auto sized, fit content cells.
      }}
      variant={variant}
    >
      <Content label={label} type={type} />
    </TableCell>
  )
}

interface ContentProps {
  label: string | number | string[] | number[] | null
  type: DatasetType
}

const Content = (props: ContentProps) => {
  const { label, type } = props

  if (type === DatasetType.ENUM) {
    return (
      // Use Chip in next release: <Chip label={label} size="small" />
      <Box sx={{ display: 'flex', gridGap: 4, justifyContent: 'center' }}>
        {(label as string[]).map((item, i) => (
          <Tag key={i} label={item} />
        ))}
      </Box>
    )
  }
  if (type === DatasetType.STRING) {
    return (
      <Box sx={{ display: 'flex', gridGap: 4 }}>
        {(label as string[]).map((item, index, labels) => (
          <Text component="span" fontSize="inherit" key={index} variant="body2">
            {item}
            {index < labels.length - 1 ? '; ' : ''}
          </Text>
        ))}
      </Box>
    )
  }
  if (type === DatasetType.NUMBER_TAG) {
    return (
      <Box sx={{ display: 'flex', gridGap: 4 }}>
        {(label as number[]).map((item, index, labels) => (
          <Text component="span" fontSize="inherit" key={index} variant="body2">
            {item}
            {index < labels.length - 1 ? '; ' : ''}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <Text component="span" fontSize="inherit" variant="body2">
      {label}
    </Text>
  )
}

export default BodyCell
