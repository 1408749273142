/* Fake feature for sales and review */

import React, { useEffect, useRef, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Autocomplete from 'components_new/atoms/Autocomplete'
import Avatar from 'components_new/atoms/Avatar'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import SectionHeader from 'components_new/molecules/SectionHeader'

import WidgetNotifcationListItem from './WidgetNotifcationListItem'

import {
  ListenerSettings,
  PeriodicSettings,
  translateWidgetNotificationFrequency,
  translateWidgetNotificationFunction,
  translateWidgetNotificationType,
  WidgetNotification,
  WidgetNotificationFrequency,
  WidgetNotificationFunction,
  WidgetNotificationType
} from 'types/GlobalWidgetNotification'
import { WidgetNotificationObject } from 'redux/reducers/WidgetNotifications'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { AccountBody, AccountType } from 'redux/reducers/Accounts'

import { Tab } from './utils'

interface WidgetNotifcationContentProps {
  accounts: AccountBody
  addSubscriber: (userId: string) => void
  removeSubscriber: (userId: string) => void
  editSubscribers: WidgetNotification | null
  kpiOptions: KpiOptionObject[]
  onCreate: (
    type: WidgetNotificationType,
    periodSettings?: PeriodicSettings,
    listenerSettings?: ListenerSettings
  ) => void
  onEditSubscribers: (notification: string) => void
  onDelete: (notification: WidgetNotification) => void
  tab: Tab
  widgetNotifications: {
    fetched: boolean
    notifications: WidgetNotificationObject
  }
}

const WidgetNotifcationContent = (props: WidgetNotifcationContentProps) => {
  const addUserInputRef = useRef<HTMLElement | null>(null)

  const [newWidgetKpiOptionId, setNewWidgetKpiOptionId] = useState(
    props.kpiOptions[0].id
  )

  const [newWidgetNotificationValue, setNewWidgetNotificationValue] =
    useState('')

  const [newWidgetNotificationFunction, setNewWidgetNotificationFunction] =
    useState<WidgetNotificationFunction>(
      WidgetNotificationFunction.GREATER_THAN
    )

  const [newWidgetNotificationFrequency, setNewWidgetNotificationFrequency] =
    useState<WidgetNotificationFrequency>(
      WidgetNotificationFrequency.EVERY_WEEK
    )

  const [newNotificationType, setNewNotificationType] =
    useState<WidgetNotificationType>(WidgetNotificationType.PERIODIC)

  // Reset create state to default on open.
  useEffect(() => {
    if (props.tab === Tab.CREATE) {
      setNewWidgetKpiOptionId(props.kpiOptions[0].id)
      setNewWidgetNotificationValue('')
      setNewWidgetNotificationFunction(WidgetNotificationFunction.GREATER_THAN)
      setNewWidgetNotificationFrequency(WidgetNotificationFrequency.EVERY_WEEK)
      setNewNotificationType(WidgetNotificationType.PERIODIC)
    }
  }, [props.tab])

  if (props.editSubscribers) {
    const accounts = Object.values(props.accounts).map((account) => {
      return {
        ...account,
        name: `${account.first_name} ${account.last_name}`
      }
    })

    const subscribers = accounts.filter((acc) =>
      props.editSubscribers?.subscribers.includes(acc.id)
    )

    const nonSubscribers = accounts.filter(
      (acc) => !props.editSubscribers?.subscribers.includes(acc.id)
    )

    return (
      <>
        <Alert severity="info">
          <strong>
            Som adminstratör kan du lägga till och ta bort användare från
            notifikationer.
          </strong>{' '}
          En användare med tillgång till dashboarden kan själv välja att lägga
          till eller ta bort sig själv från en notifikation.
        </Alert>
        <Autocomplete
          blurOnSelect={true}
          disableClearable
          value={null}
          onChange={(event, newUser: AccountType) => {
            props.addSubscriber(newUser.id)
          }}
          options={nonSubscribers}
          filterSelectedOptions
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.accountId === value.accountId
          }
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemAvatar>
                <Avatar alt={option.name} />
              </ListItemAvatar>
              <ListItemText primary={option.name} secondary={option.email} />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={addUserInputRef}
              label="Lägg till prenumerant"
              placeholder="Sök..."
            />
          )}
          renderTags={() => null}
          sx={{
            '& .MuiFormControl-root': {
              mt: 2,
              mb: 2
            }
          }}
        />
        <SectionHeader title="Prenumeranter" />
        {subscribers.map((sub) => {
          return (
            <ListItem
              key={sub.id}
              secondaryAction={
                <IconButton
                  color="disabled"
                  onClick={() => props.removeSubscriber(sub.id)}
                  size="small"
                >
                  <Icon fontSize="small" name="NotificationsOffOutlined" />
                </IconButton>
              }
            >
              <Icon color="disabled" name="PersonOutlineOutlined" />
              <ListItemText
                sx={{ ml: 3 }}
                primary={sub.name}
                secondary={sub.email}
                primaryTypographyProps={{ fontWeight: 500 }}
              />
            </ListItem>
          )
        })}
      </>
    )
  }

  if (props.tab === Tab.DEFAULT) {
    const notifications = Object.values(props.widgetNotifications.notifications)

    return props.widgetNotifications.fetched ? (
      notifications.length > 0 ? (
        <List>
          {notifications.map((notification) => (
            <WidgetNotifcationListItem
              key={notification.id}
              kpiOptions={props.kpiOptions}
              notification={notification}
              onDelete={() => props.onDelete(notification)}
              onEditSubscribers={() => props.onEditSubscribers(notification.id)}
            />
          ))}
        </List>
      ) : (
        <Alert severity="info" sx={{ mt: 2 }}>
          <strong>Inga notifikationer har skapats på widgeten!</strong> Skapa en
          första notifikationa för att säkerställa att du eller dina kollegor
          inte missar viktig information!
        </Alert>
      )
    ) : null
  }

  if (props.tab === Tab.CREATE) {
    return (
      <>
        <TextField
          fullWidth
          label="Typ av notifikation"
          autoComplete="off"
          value={newNotificationType}
          onChange={(event) =>
            setNewNotificationType(event.target.value as WidgetNotificationType)
          }
          required
          select
        >
          {Object.values(WidgetNotificationType).map((type) => {
            return (
              <MenuItem key={type} value={type}>
                {translateWidgetNotificationType[type]}
              </MenuItem>
            )
          })}
        </TextField>

        {/* Create settings for PERIODIC notification */}
        <Collapse in={newNotificationType === WidgetNotificationType.PERIODIC}>
          <Alert severity="info" sx={{ mt: 2 }}>
            En periodisk notifikation är effektiv för att påminna en användare
            om att de ska ta del av information vid ett återkommande tillfälle.
          </Alert>
          <TextField
            fullWidth
            label="Upprepnings intervall"
            autoComplete="off"
            value={newWidgetNotificationFrequency}
            onChange={(event) =>
              setNewWidgetNotificationFrequency(
                event.target.value as WidgetNotificationFrequency
              )
            }
            required
            select
            sx={{ mt: 2 }}
          >
            {Object.values(WidgetNotificationFrequency).map((freq) => {
              return (
                <MenuItem key={freq} value={freq}>
                  {translateWidgetNotificationFrequency[freq]}
                </MenuItem>
              )
            })}
          </TextField>
        </Collapse>

        {/* Create settings for LISTENER notification */}
        <Collapse in={newNotificationType === WidgetNotificationType.LISTENER}>
          <Alert severity="info" sx={{ mt: 2 }}>
            En notifikation som skickas när något inträffar är effektivt för att
            säkerställa att en användare kan agera så tidigt som möjligt utan
            att behöva leta i en dashboard.
          </Alert>
          <TextField
            fullWidth
            label="Nyckeltal"
            autoComplete="off"
            value={newWidgetKpiOptionId}
            onChange={(event) => setNewWidgetKpiOptionId(event.target.value)}
            required
            select
            sx={{ mt: 2 }}
          >
            {props.kpiOptions.map((kpi) => {
              return (
                <MenuItem key={kpi.id} value={kpi.id}>
                  {kpi.title}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            fullWidth
            label="Regel"
            autoComplete="off"
            value={newWidgetNotificationFunction}
            onChange={(event) =>
              setNewWidgetNotificationFunction(
                event.target.value as WidgetNotificationFunction
              )
            }
            required
            select
            sx={{ mt: 2 }}
          >
            {Object.values(WidgetNotificationFunction).map((func) => {
              return (
                <MenuItem key={func} value={func}>
                  {translateWidgetNotificationFunction[func]}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            fullWidth
            label="Värde"
            autoComplete="off"
            value={newWidgetNotificationValue}
            onChange={(event) =>
              setNewWidgetNotificationValue(event.target.value)
            }
            required
            type={'number'}
            sx={{ mt: 2 }}
          />
          <Alert severity="info">
            <strong>Tänk på!</strong> Regeln gäller för varje segment i widgeten
            med valda filtreringar och tidsperioder.
          </Alert>
        </Collapse>

        <Button
          variant="contained"
          disabled={
            newNotificationType === WidgetNotificationType.LISTENER &&
            newWidgetNotificationValue === ''
          }
          onClick={() => {
            props.onCreate(
              newNotificationType,
              newNotificationType === WidgetNotificationType.PERIODIC
                ? { frequency: newWidgetNotificationFrequency }
                : undefined,
              newNotificationType === WidgetNotificationType.LISTENER
                ? {
                    kpi_option_id: newWidgetKpiOptionId,
                    function: newWidgetNotificationFunction,
                    value: newWidgetNotificationValue as unknown as number
                  }
                : undefined
            )
          }}
          sx={{ mt: 2 }}
        >
          Skapa
        </Button>
      </>
    )
  }

  return null
}

export default WidgetNotifcationContent
