import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'
import { TrackingUser } from 'types/GlobalTracking'

export interface TrackingReducerType {
  users: any[]
  notActivatedUsers: any[]
  customers: any[]
  usersFetched: boolean
  customersFetched: boolean
  last30DaysGraph: object
  daysSinceCA: object
  graphsFetched: boolean
  NSMCAs: number | null
  NSMDashboardsOpened: number | null
  NSMEmbeddedViewed: number | null
  NSMAccounts: number | null
  NSMActiveAccounts: number | null
  NSMInvitesSentToHomepal: number | null
  NSMFetched: boolean
  apis: any[]
  apisFetched: boolean
  usersUsage: TrackingUser[]
  usersUsageFeteched: boolean
  oneUserUsage: {
    [key: string]: TrackingUser
  }
}

// Initial state
const INITIAL_STATE: TrackingReducerType = {
  users: [],
  notActivatedUsers: [],
  customers: [],
  usersFetched: false,
  customersFetched: false,
  last30DaysGraph: {},
  daysSinceCA: {},
  graphsFetched: false,
  NSMCAs: null,
  NSMDashboardsOpened: null,
  NSMEmbeddedViewed: null,
  NSMAccounts: null,
  NSMActiveAccounts: null,
  NSMInvitesSentToHomepal: null,
  NSMFetched: false,
  apis: [],
  apisFetched: false,
  usersUsage: [],
  usersUsageFeteched: false,
  oneUserUsage: {}
}

// Actions
const getUsers = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_MINDSET_FUNNEL_USERS_SUCCESS
)
const getCustomers = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_MINDSET_FUNNEL_CUSTOMERS_SUCCESS
)
const getGraphData = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_MINDSET_FUNNEL_GRAPH_DATA_SUCCESS
)
const getNSMData = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_NSM_DATA_SUCCESS
)
const getApis = createAction<{ data: any[] }>(Types.MEASUREMENTS_APIS_SUCCESS)
const getUsersUsage = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_GET_USERS_USAGE_SUCCESS
)
const getOneUserUsage = createAction<{ data: any }>(
  Types.MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS
)
const updateUser = createAction<{ data: any }>(
  Types.MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const dashboardReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(getUsers, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        users: payload.data.activated_accounts,
        notActivatedUsers: payload.data.not_activated_accounts,
        usersFetched: true
      }
    })
    .addCase(getCustomers, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        customers: payload.data,
        customersFetched: true
      }
    })
    .addCase(getGraphData, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        last30DaysGraph: payload.last_30_days,
        daysSinceCA: payload.days_since_CA,
        graphsFetched: true
      }
    })
    .addCase(getNSMData, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        NSMCAs: payload.CAs,
        NSMDashboardsOpened: payload.dashboardsOpened,
        NSMEmbeddedViewed: payload.embeddedWidgetsViewed,
        NSMAccounts: payload.accounts.count,
        NSMActiveAccounts: payload.activeAccounts.count,
        NSMInvitesSentToHomepal: payload.invitesSentToHomepal,
        NSMFetched: true
      }
    })
    .addCase(getApis, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        apis: payload,
        apisFetched: true
      }
    })
    .addCase(getUsersUsage, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        usersUsage: payload,
        usersUsageFetched: true
      }
    })
    .addCase(getOneUserUsage, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        oneUserUsage: {
          ...state.oneUserUsage,
          [payload.id]: payload
        }
      }
    })
    .addCase(updateUser, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        usersUsage: state.usersUsage.map((user: TrackingUser) => {
          if (user.id === payload.id) {
            return {
              ...user,
              title: payload.title
            }
          }

          return user
        }),
        oneUserUsage: {
          ...state.oneUserUsage,
          [payload.id]: {
            ...state.oneUserUsage[payload.id],
            title: payload.title
          }
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default dashboardReducer
