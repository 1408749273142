import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { isValidDate } from 'helpers/Functions/Date'
import { parseFilterBody } from 'helpers/Functions/Filters'
import {
  GetOneWidget,
  WidgetPostBody,
  WidgetPatchBody,
  PeriodFilter,
  FunnelStagePutBody,
  WidgetData
} from 'types/GlobalWidget'
import { FilterBody, Sort } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/widgets`

export interface QueryParams {
  filter?: FilterBody[]
  segment_by?: string | number | null
  sort?: Sort | null
  period_filter?: PeriodFilter
  from_date?: string
  to_date?: string
}

const getManualPeriodQuery = (from: string, to: string) => {
  let specificDateQuery = ''

  const fromDate = new Date(from)
  const toDate = new Date(to)

  // Make sure it is same date
  fromDate.setHours(6)
  toDate.setHours(6)

  const parsedFromDate = fromDate.toLocaleDateString('sv')
  const parsedToDate = toDate.toLocaleDateString('sv')

  specificDateQuery = `from_date=${parsedFromDate}&to_date=${parsedToDate}`

  return specificDateQuery
}

export const parseQueryParam = (queryParam: QueryParams, rawQuery = '') => {
  let queryString = rawQuery

  if (queryParam.period_filter) {
    queryString = `period_filter=${queryParam.period_filter}`
  }

  if (queryParam.filter && queryParam.filter.length > 0) {
    if (queryString) {
      queryString += '&'
    }

    queryString += queryParam.filter
      .map((filter) => parseFilterBody(filter.attributeId, filter))
      .filter(Boolean)
      .join('&')
  }

  if (queryParam.segment_by) {
    if (queryString) {
      queryString += '&'
    }

    queryString += `filter[segment_by][eq]=${encodeURIComponent(
      queryParam.segment_by
    )}`
  }

  if (queryParam.sort) {
    if (queryString) {
      queryString += '&'
    }

    queryString += `sort=${queryParam?.sort.direction === 'desc' ? '-' : ''}${
      queryParam?.sort.attribute
    }`
  }

  if (
    queryParam.from_date &&
    queryParam.to_date &&
    isValidDate(new Date(queryParam.from_date)) &&
    isValidDate(new Date(queryParam.to_date))
  ) {
    if (queryString) {
      queryString += '&'
    }

    queryString += getManualPeriodQuery(
      queryParam.from_date,
      queryParam.to_date
    )
  }

  if (queryString && queryString[0] !== '?') {
    queryString = '?' + queryString
  }

  return queryString
}

export async function getOneAdditionalSegment(
  id: string,
  additionalSegmentId: string,
  queryParam: QueryParams | null
) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.get(
    `${URL}/${id}/${additionalSegmentId}${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function getOneAdditionalSegmentPublic(
  id: string,
  additionalSegmentId: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams

  return axios.get(
    `${BASE_URL}v1/public/widgets/${id}/${additionalSegmentId}${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function getOneTotal(id: string, queryParam: QueryParams | null) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.get(
    `${URL}/${id}/total${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function getOneTotalPublic(
  id: string,
  queryParam: QueryParams | null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams

  return axios.get(
    `${BASE_URL}v1/public/widgets/${id}/total${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function getOne(id: string, queryParam: QueryParams | null) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.get<{ data: GetOneWidget }>(
    `${URL}/${id}${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function getOneFromHierarchy(
  id: string,
  kpiOptionId: string,
  attributeOptionId: string,
  queryParam: QueryParams | null
) {
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.get<{ data: WidgetData }>(
    `${URL}/${id}/${kpiOptionId}/${attributeOptionId}${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function create(body: WidgetPostBody) {
  return axios.post(URL, body, parseAuthenticationHeader())
}

export async function update(id: string, body: WidgetPatchBody) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

// Get data from an embedded widget
export async function getOnePublic(
  id: string,
  queryParam: QueryParams | null = null,
  rawQueryParams = ''
) {
  const parsedQueryParams = queryParam
    ? parseQueryParam(queryParam, rawQueryParams)
    : rawQueryParams

  return axios.get<{ data: GetOneWidget }>(
    `${BASE_URL}v1/public/widgets/${id}${parsedQueryParams}`
  )
}

export async function putImage(id: string, image: Blob, name: string) {
  const formData = new FormData()

  formData.append('image', image)
  formData.append('name', name)

  return axios.put(`${URL}/${id}/image`, formData, parseAuthenticationHeader())
}

export async function putFunnelStages(
  id: string,
  funnelStages: FunnelStagePutBody
) {
  return axios.put(
    `${URL}/${id}/funnel-stages`,
    { data: { funnel_stages: funnelStages } },
    parseAuthenticationHeader()
  )
}
