import React, { ReactNode, ElementType, MouseEventHandler } from 'react'
import { TableRow as MUITableRow } from '@mui/material'

export interface TableRowProps {
  children?: ReactNode
  component?: ElementType<any>
  hover?: boolean
  lastRowHasBorder?: boolean
  noBorder?: boolean
  selected?: boolean
  sx?: object
  onClick?: MouseEventHandler<HTMLTableRowElement>
}

/**
 * The TableRow component is a...
 */

const TableRow = React.forwardRef((props: TableRowProps, ref: any) => {
  const { children, lastRowHasBorder, noBorder, sx, ...rest } = props

  const noBorderSx = { '& > *': { borderBottom: 'unset!important' } }
  const hasBorderSx = {
    '& td': { borderBottom: 1, borderColor: 'divider' },
    '& th': { borderBottom: 1, borderColor: 'divider' },
    '&:last-child td, &:not(:only-child) th': lastRowHasBorder
      ? { borderBottom: 1, borderColor: 'divider' }
      : { borderBottom: 0 }
  }

  const borderSx = noBorder ? noBorderSx : hasBorderSx

  return (
    <MUITableRow {...rest} ref={ref} sx={{ ...borderSx, ...sx }}>
      {children}
    </MUITableRow>
  )
})

TableRow.displayName = 'TableRow'
export default TableRow
