import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { KPI_UNIT } from 'types/Enums'
import { PutKpiTemplateCalculationBlock } from 'types/GlobalKpiTemplates'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/kpis`

export async function getAll() {
  return axios.get(URL, parseAuthenticationHeader())
}

export async function getAllFilterOptions() {
  const response = await axios.get(
    `${URL}/filter-options`,
    parseAuthenticationHeader()
  )

  if (response.data?.redirected_to) {
    const redirectedResponse = await axios.get(response.data.redirected_to)

    return { data: redirectedResponse }
  }

  return response
}

export async function requestKpi(email: string | undefined) {
  const url =
    'https://hooks.slack.com/services/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'
  const data = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: ':pray: Någon har skickat en förfrågan om ett nytt nyckeltal!'
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Konto: *${email}*`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: '<@U032Y48EJPQ>, *markera* detta meddelande när action tagits.'
          }
        ]
      }
    ]
  }

  return axios.post(url, JSON.stringify(data), {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  })
}

export async function update(
  id: string,
  body: {
    data: {
      name: string
      unit?: KPI_UNIT
      blocks: PutKpiTemplateCalculationBlock
    }
  }
) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function create(body: {
  data: { name: string; unit: KPI_UNIT; blocks: PutKpiTemplateCalculationBlock }
}) {
  return axios.post(URL, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
