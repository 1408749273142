import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

export function toggleNavigationOpen() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.TOGGLE_NAVIGATION
    })
  }
}

export function toggleNavigationFolder(folder: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.TOGGLE_NAVIGATION_FOLDER,
      payload: folder
    })
  }
}
