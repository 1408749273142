import { getOntology } from 'redux/api/Applications/DataProducts/v3'
import * as Types from 'redux/Types'

export function tryGetOntology() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_APPS_ONTOLOGY
    })

    getOntology()
      .then((response) => {
        dispatch({
          type: Types.GET_APPS_ONTOLOGY_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_APPS_ONTOLOGY_FAILED
        })
      })
  }
}
