import React from 'react'

import Icon from 'components_new/atoms/Icon'

import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

function IconHeaderFlag({ icon, header }) {
  return (
    <div className={Styles['container']}>
      {icon && <Icon name={icon} className={Styles.icon} />}
      <Text color="inherit" variant="subtitle2">
        {header}
      </Text>
    </div>
  )
}

export default IconHeaderFlag
