import React, { useState } from 'react'
import { Form } from 'antd'
import { isMoment } from 'moment'

import _ from 'lodash'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import StatusBanner from 'components/common/StatusBanner'
import SideBar from 'components/common/CreateModal/SideBar'
import SelectOptionModal from 'components/common/SelectOptionModal'

import Styles from './styles.module.css'
import ManageOperation from './ManageOperation'
import OperationCard from './OperationCard'
import {
  OPERATION_TYPES,
  OPERATION_OPTIONS,
  replaceInputExpression,
  parseSavedValue,
  resetForm,
  updateOperationsUsingNewAttribute
} from './conf'

import { TODAY } from './ManageOperation/conf'

const Operations = ({
  operations,
  setOperations,
  setMapping,
  chosenAttributes,
  setAlert,
  resetAlert
}) => {
  const [filterForm] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [operationType, setOperationType] = useState(undefined)
  const [operationIndex, setOperationIndex] = useState(undefined)

  return (
    <>
      <Box>
        {operations.map((op, i) => (
          <OperationCard
            key={`operation-${i}`}
            type={op.type}
            attributes={op.attributes}
            newAttribute={op.newAttribute}
            leftAttribute={op.leftAttribute}
            rightAttribute={op.rightAttribute}
            chosenAttributes={chosenAttributes}
            isLast={operations.length - 1 === i}
            onRemove={() =>
              setAlert({
                header: 'Ta bort operation',
                content: (
                  <>
                    <StatusBanner
                      title={'Tänk på!'}
                      message={
                        // eslint-disable-next-line max-len
                        'Operationer som ligger efter aktuell operation kan påverkas och sluta fungera.'
                      }
                      type={'warning'}
                      showIcon
                    />
                    <p>Vill du verkligen ta bort aktuell operation?</p>
                  </>
                ),
                onCancel: () => resetAlert(),
                onOk: () => {
                  const tmpOperations = _.cloneDeep(operations)

                  tmpOperations.splice(i, 1)

                  setOperations(tmpOperations)
                  resetAlert()
                },
                cancelText: 'Avbryt',
                okText: 'Ta bort',
                color: 'warning'
              })
            }
            onEdit={() => {
              filterForm.setFieldsValue({
                conditions: op.attributes.map((c) => ({
                  ...c.condition,
                  leftAttribute: c.outputAttributeId
                })),
                leftAttribute: op.leftAttribute,
                rightAttribute: op.rightAttribute,
                newAttribute: op.newAttribute,
                newAttributeInit: op.newAttribute,
                unit: op.unit,
                // used in time difference node
                valueType: op.value === TODAY ? TODAY : 'INPUT_VALUE',
                value: parseSavedValue(op.value, op.type, chosenAttributes)
              })
              setOperationType(op.type)
              setOperationIndex(i)
            }}
          />
        ))}
        <Button
          fullWidth
          onClick={() => {
            resetForm(filterForm)

            setVisible(true)
          }}
          size="large"
          startIcon={<Icon name="AddCircleOutlineOutlined" />}
          variant="text"
          sx={{ mt: 2 }}
        >
          Lägg till
        </Button>
      </Box>

      <SelectOptionModal
        visible={visible}
        onClose={() => setVisible(false)}
        options={OPERATION_OPTIONS}
        onSelect={setOperationType}
      />

      <SideBar
        open={operationType}
        title={operationType ? null : 'Välj operationer'}
        description={
          'Lägg till en eller flera operationer för få ut datan så som du vill.'
        }
        content={
          <ManageOperation
            type={operationType}
            chosenAttributes={chosenAttributes}
            filterForm={filterForm}
          />
        }
        contentClassName={Styles['manage-operation']}
        actions={
          <>
            <Button
              onClick={() => {
                setOperationType(undefined)
                setOperationIndex(undefined)
              }}
              variant="text"
            >
              Avbryt
            </Button>
            <Button
              onClick={async () => {
                try {
                  // validate dataset name
                  await filterForm.validateFields()
                } catch (e) {
                  return
                }

                const formValues = filterForm.getFieldsValue()
                const attributes = formValues.conditions.map((c) => {
                  return {
                    outputAttributeId: c.leftAttribute,
                    condition: c
                  }
                })

                const tmpOperations = _.cloneDeep(operations)
                let value = formValues.value

                if (operationType === OPERATION_TYPES.CALCULATE.name) {
                  value = replaceInputExpression(value, chosenAttributes)
                }

                if (value && isMoment(value)) {
                  value = value.format('YYYY-MM-DD')
                }

                if (formValues.valueType === TODAY) {
                  value = TODAY
                }

                if (
                  attributes.length > 0 ||
                  operationType !== OPERATION_TYPES.FILTER
                ) {
                  const newAttribute = formValues.newAttribute?.trim()

                  if (newAttribute) {
                    setMapping((mapping) => {
                      const previousMapping = formValues.newAttributeInit
                        ? mapping[formValues.newAttributeInit]
                        : {}

                      updateOperationsUsingNewAttribute(
                        formValues.newAttributeInit,
                        newAttribute,
                        tmpOperations
                      )

                      return {
                        ...mapping,
                        [newAttribute]: {
                          ...previousMapping,
                          name: newAttribute,
                          keep: true
                        }
                      }
                    })
                  }
                  if (operationIndex === undefined) {
                    tmpOperations.push({
                      type: operationType,
                      newAttribute,
                      leftAttribute: formValues.leftAttribute,
                      rightAttribute: formValues.rightAttribute,
                      unit: formValues.unit,
                      value: value,
                      attributes
                    })
                  } else {
                    tmpOperations[operationIndex] = {
                      type: operationType,
                      newAttribute,
                      leftAttribute: formValues.leftAttribute,
                      rightAttribute: formValues.rightAttribute,
                      unit: formValues.unit,
                      value: value,
                      attributes
                    }
                  }
                } else if (operationIndex !== undefined) {
                  tmpOperations.splice(operationIndex, 1)
                }

                setOperations(tmpOperations)
                setOperationType(undefined)
                setOperationIndex(undefined)
                resetForm(filterForm)
              }}
            >
              Spara
            </Button>
          </>
        }
        overlay
      />
    </>
  )
}

export default Operations
