import React from 'react'

import { KpiOptionObject } from 'types/GlobalKpiOption'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

interface ValuePreviewProps {
  kpi: KpiOptionObject
}

const ValuePreview = (props: ValuePreviewProps) => {
  const { kpi } = props

  const lowerValue = kpi.lower_bound_threshold
  const upperValue = kpi.upper_bound_threshold
  const increaseIsPositive = kpi.increase_is_positive
  const intermediateMode = kpi.lower_bound_threshold !== null

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      {increaseIsPositive ? (
        <>
          {intermediateMode ? (
            <>
              <Marker status="error" />
              <Separator />
              {lowerValue}
              <Separator />
              <Marker status="warning" />
              <Separator />
              {upperValue}
              <Separator />
              <Marker status="success" />
            </>
          ) : (
            <>
              <Marker status="error" />
              <Separator />
              {upperValue}
              <Separator />
              <Marker status="success" />
            </>
          )}
        </>
      ) : (
        <>
          {intermediateMode ? (
            <>
              <Marker status="success" />
              <Separator />
              {upperValue}
              <Separator />
              <Marker status="warning" />
              <Separator />
              {lowerValue}
              <Separator />
              <Marker status="error" />
            </>
          ) : (
            <>
              <Marker status="success" />
              <Separator />
              {upperValue}
              <Separator />
              <Marker status="error" />
            </>
          )}
        </>
      )}
    </Box>
  )
}

const Marker = (props: { status: 'error' | 'success' | 'warning' }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: 6,
      bgcolor: `${props.status}.main`
    }}
  />
)

const Separator = () => (
  <Text component="span" color="action.disabled" fontWeight="bold">
    {'<'}
  </Text>
)

export default ValuePreview
