import React, { ElementType, ReactNode } from 'react'
import { Skeleton as MUISkeleton } from '@mui/material'

interface SkeletonProps {
  animation?: 'pulse' | 'wave' | false
  children?: ReactNode
  component?: ElementType<any>
  height?: number | string
  sx?: object
  variant?: 'circular' | 'rectangular' | 'rounded' | 'text'
  width?: number | string
}

/**
 * The Skeleton component displays a placeholder
 * preview of your content before the data gets
 * loaded to reduce load-time frustration.
 */

const Skeleton = React.forwardRef((props: SkeletonProps, ref: any) => {
  const { sx, ...rest } = props

  return (
    <MUISkeleton
      {...rest}
      ref={ref}
      sx={{ transform: 'scale(1)', WebkitTransform: 'scale(1)', ...sx }}
    />
  )
})

Skeleton.displayName = 'Skeleton'
export default Skeleton
