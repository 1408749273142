import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import { StatusBannerBody, StatusBannerData } from 'utils/types'

import BannerModal from './BannerModal'

interface StatusBannerTemplateProps {
  data: { [id: string]: StatusBannerData }
  customers: { [id: string]: any }
  create: (data: StatusBannerBody) => void
  update: (id: string, data: StatusBannerBody) => void
  delete: (id: string) => void
}

const StatusBannerTemplate = (props: StatusBannerTemplateProps) => {
  const { customers, create, data, update } = props
  const [open, setOpen] = useState<boolean>(false)
  const [deleteId, setDelete] = useState<string | null>(null)
  const [selected, setSelected] = useState<string | null>(null)

  const setEdit = (id: string) => {
    setSelected(id)
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ mb: 8 }}>
        <Stack justifyContent="space-between">
          <Text variant="h5" sx={{ lineHeight: 1 }}>
            Aktiva banderoller
          </Text>
          <Button
            startIcon={<Icon name="AddCircleOutlineOutlined" />}
            onClick={() => setOpen(true)}
          >
            Skapa
          </Button>
        </Stack>
        <Divider sx={{ my: 2 }} />
        {Object.values(data)
          .filter((banner) => banner.show || banner.show_public)
          .map((banner) => (
            <BannerCard
              banner={banner}
              customers={customers}
              key={banner.id}
              setEdit={setEdit}
              setDelete={setDelete}
            />
          ))}
      </Box>
      <Box>
        <Text variant="h5" sx={{ lineHeight: 1 }}>
          Inaktiva banderoller
        </Text>
        <Divider sx={{ my: 2 }} />
        {Object.values(data)
          .filter((banner) => !banner.show && !banner.show_public)
          .map((banner) => (
            <BannerCard
              banner={banner}
              customers={customers}
              key={banner.id}
              setEdit={setEdit}
              setDelete={setDelete}
            />
          ))}
      </Box>
      <BannerModal
        customers={customers}
        data={selected ? data[selected] : undefined}
        onClose={() => {
          setOpen(false)
          setSelected(null)
        }}
        open={open}
        submit={(body) => {
          selected ? update(selected, body) : create(body)
          setOpen(false)
          setSelected(null)
        }}
      />
      <DeleteDialog
        open={!!deleteId}
        handleClose={() => {
          setDelete(null)
        }}
        handleDelete={() => {
          if (deleteId) {
            props.delete(deleteId)
            setDelete(null)
          }
        }}
        type="banderoll"
        title={deleteId ? data[deleteId].title : undefined}
      />
    </>
  )
}

const BannerCard = (props: {
  banner: StatusBannerData
  customers: { [id: string]: any } // TODO
  setDelete: (id: string) => void
  setEdit: (id: string) => void
}) => {
  const { banner, customers, setDelete, setEdit } = props
  const isActive = banner.show || banner.show_public

  return (
    <Box
      sx={{
        p: 1,
        border: '1px solid',
        borderColor: isActive ? 'brand.main' : 'divider',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        mb: 1
      }}
    >
      <Stack spacing={2}>
        <Icon name={banner.icon} color={banner.severity} />
        <Stack spacing={2}>
          {banner.customer_ids.length > 0 ? (
            <Stack spacing={1}>
              {banner.customer_ids.map((customerId) => {
                const customer = customers[customerId]

                if (!customer) {
                  return (
                    <Tag color="warning" key={customerId} label={'Undefined'} />
                  )
                }

                return (
                  <Tag color="info" key={customerId} label={customer.name} />
                )
              })}
            </Stack>
          ) : (
            <Tag label={'Alla'} color="info" />
          )}
          <Divider orientation="vertical" />
          <strong>{banner.title}</strong>
          <Text
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {banner.description}
          </Text>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Button onClick={() => setEdit(banner.id)} size="small">
          Ändra
        </Button>
        <Button onClick={() => setDelete(banner.id)} size="small" color="error">
          Ta bort
        </Button>
      </Stack>
    </Box>
  )
}

export default StatusBannerTemplate
