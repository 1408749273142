import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Card from 'components_new/atoms/Card'
import CardActions from 'components_new/atoms/Card/CardActions'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import CardContent from 'components_new/atoms/Card/CardContent'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import { Category } from 'redux/reducers/Dashboards'
import { translateCategory } from 'utils/enumTranslator'
import { ThemeProvider, useTheme } from '@mui/material'
import { getTheme } from 'themes'

interface KpiThumbnailProps {
  availableInLicense?: boolean
  category?: Category
  hasData?: boolean
  hasTarget?: boolean | null
  isPremium?: boolean
  onClick: () => void
  summary: any
  sx?: object
  title: string
  url?: string
  variant?: 'custom' | 'other'
}

const KpiThumbnail = (props: KpiThumbnailProps) => {
  const {
    availableInLicense = true,
    category,
    hasData = true,
    hasTarget,
    isPremium = false,
    onClick,
    summary,
    sx,
    title,
    url,
    variant
  } = props

  const [hover, setHover] = useState(false)
  const [requested, setRequested] = useState(false)

  const theme = useTheme()

  const sendRequest = () => {
    if (variant === 'custom') {
      onClick()
      setRequested(true)
    }
  }

  const disabled = !hasData || !availableInLicense || hasTarget === false

  return variant === 'custom' ? (
    <Card
      variant={'outlined'}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 1,
        ...sx
      }}
    >
      <CardContent>
        <Text variant="h6" color={'text.primary'}>
          {title}
        </Text>
        <Text
          variant="body1"
          color="text.secondary"
          sx={{
            mt: 1,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {summary}
        </Text>
      </CardContent>
      <CardActions>
        <Button disabled={requested} onClick={sendRequest} variant="text">
          {requested ? 'Förfrågan skickad' : 'Skicka förfrågan'}
        </Button>
      </CardActions>
    </Card>
  ) : (
    <ThemeProvider theme={getTheme('light')}>
      <Card
        elevation={disabled ? 0 : undefined}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          width: '100%',
          height: '100%',
          bgcolor:
            category && !disabled
              ? `${translateCategory[category].color}.main`
              : category && disabled
                ? 'action.active'
                : undefined,
          borderRadius: 1,
          opacity: disabled && variant !== 'other' ? 0.75 : undefined,
          ...sx
        }}
        variant={variant === 'other' ? 'outlined' : undefined}
      >
        <CardActionArea
          component="a"
          disabled={disabled}
          onClick={!disabled ? () => onClick() : () => {}}
          sx={{
            width: '100%',
            height: '100%',
            pointerEvents: 'all!important'
          }}
        >
          <CardContent
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <ThemeProvider
              theme={getTheme(variant === 'other' ? 'light' : 'dark')}
            >
              <Box>
                <Chip
                  label={
                    category ? translateCategory[category].title : 'Övrigt'
                  }
                  size="small"
                  sx={{ mb: 1 }}
                />
                <Chip
                  label={'Premium'}
                  size="small"
                  sx={{ mb: 1, ml: 1, display: isPremium ? undefined : 'none' }}
                />
                <Text variant="h6" color={'text.primary'}>
                  {title}
                </Text>
                <Text
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    mt: 1,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {summary}
                </Text>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              >
                <ThemeProvider theme={getTheme('light')}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      bgcolor: 'background.overlay',
                      transition: theme.transitions.create(
                        ['transform', 'opacity'],
                        {
                          easing: theme.transitions.easing.easeInOut,
                          duration: theme.transitions.duration.enteringScreen
                        }
                      ),
                      transform: hover ? 'translateY(0%)' : 'translateY(20%)',
                      opacity: hover ? 1 : 0,
                      p: 2
                    }}
                  >
                    <Box>
                      <Text variant="h6" color={'text.primary'}>
                        {title}
                      </Text>
                      <Text
                        variant="body1"
                        color="text.primary"
                        sx={{
                          mt: 1,
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitLineClamp: '3',
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {summary}
                      </Text>
                    </Box>
                    <Stack sx={{ mt: 0.5, width: '100%' }}>
                      {!disabled ? (
                        <Button
                          color={
                            category
                              ? translateCategory[category].color
                              : 'primary'
                          }
                          variant="contained"
                          fullWidth={true}
                        >
                          Lägg till
                        </Button>
                      ) : null}

                      {variant !== 'other' ? (
                        <Button
                          onClick={(event) => {
                            window.open(url, '_blank')
                            event.stopPropagation()
                          }}
                          endIcon={<Icon name="OpenInNew" />}
                          color={
                            category
                              ? translateCategory[category].color
                              : 'primary'
                          }
                          variant="text"
                          fullWidth={true}
                        >
                          Läs mer
                        </Button>
                      ) : null}
                    </Stack>
                  </Box>
                </ThemeProvider>
              </Box>
            </ThemeProvider>
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
  )
}

export default KpiThumbnail
