import React, { useEffect } from 'react'

import { Form } from 'antd'

import Button from 'components_new/atoms/Button'
import Modal from 'components/common/Modal'
import Input from 'components/common/Input'

import { FORM_VALIDATIONS, DESCRIPTOR } from 'helpers/Constants'

const EditName = ({ visible, dataProduct, onClose, onSave, dataProducts }) => {
  const [form] = Form.useForm()

  const descriptor = DESCRIPTOR[dataProduct.attributes.type]

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: dataProduct.attributes.name
      })
    }
  }, [visible])

  return (
    <Modal
      header={'Redigera namn'}
      visible={visible}
      onClose={() => onClose()}
      actions={
        <>
          <Button onClick={() => onClose()} variant="text">
            Avbryt
          </Button>
          <Button onClick={() => form.submit()}>Spara</Button>
        </>
      }
    >
      <Form
        layout={'vertical'}
        form={form}
        initialValues={{
          name: undefined
        }}
        onFinish={(values) => {
          onSave(values)
          onClose()
        }}
      >
        <Form.Item
          name={'name'}
          rules={[
            FORM_VALIDATIONS.REQUIRED,
            FORM_VALIDATIONS.DUPLICATE(
              dataProducts,
              'name',
              `En ${descriptor} med samma namn existerar redan`,
              dataProduct.id
            )
          ]}
        >
          <Input size="large" label={`Namnge ${descriptor}`} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditName
