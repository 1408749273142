import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'
import Icon from 'components_new/atoms/Icon'
import LinearProgress from 'components_new/atoms/LinearProgress'
import Paper from 'components_new/atoms/Paper'
import { default as AtomTable } from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'

import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import LoadingSection from 'components_new/molecules/LoadingSection'

import {
  getKeyFigureProgress,
  parseKeyFigureData,
  parseLicenseData
} from './utils'

import { Customer } from 'types/GlobalCustomer'
import { KpiTemplateRedux, KpiTemplateType } from 'types/GlobalKpiTemplates'

interface LicenseTemplateProps {
  customer: Customer | null
  kpiTemplates: KpiTemplateRedux
  kpiTemplatesFetched: boolean
}

interface TableProps {
  headers: {
    label: string
    align?: 'center' | 'left' | 'right'
  }[]
  loading?: boolean
  rows: {
    label: string
    isHeader?: boolean
    colSpan?: number
    values: boolean[]
  }[]
  size?: 'small' | 'medium'
  sx?: object
}

interface ImplementationProgressKpiProps {
  label: string
  value: number
}

const LicenseTemplate = (props: LicenseTemplateProps) => {
  const kpiTemplates = Object.values(props.kpiTemplates).filter(
    (template) => template.type === KpiTemplateType.STANDARD
  )
  const licenseData = parseLicenseData(props.customer?.allow_access)
  const keyFigureData = parseKeyFigureData(kpiTemplates)
  const {
    available,
    ordered,
    integratedProgress,
    modelledProgress,
    qualityAssuredProgress,
    totalProgress
  } = getKeyFigureProgress(kpiTemplates)

  return (
    <>
      <CollapsibleSection title="Nyckeltal" sx={{ py: 2, maxWidth: 'section' }}>
        <Box sx={{ maxWidth: 'content' }}>
          <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
            Information om vilka nyckeltal som ingår i din licens samt vilka som
            är beställda och redo för användning.
          </Text>
        </Box>
        <ThemeProvider theme={getTheme('dark')}>
          <Paper
            elevation={0}
            sx={{
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              minWidth: 0,
              position: 'relative'
            }}
          >
            <>
              <Box>
                <strong>
                  {`Du har beställt ${ordered} av ${available} nyckeltal som ingår i din licens.`}
                </strong>{' '}
                Kontakta{' '}
                <a href={'mailto:support@homepal.se'}>support@homepal.se</a> om
                du vill beställa fler.
              </Box>
              <Box sx={{ mt: 2 }}>
                <LinearProgress
                  value={(ordered / available) * 100}
                  label={true}
                  sx={{ height: 10, borderRadius: 5 }}
                  color={'success'}
                  variant={'determinate'}
                />
              </Box>
            </>
          </Paper>
        </ThemeProvider>
        <Box sx={{ maxWidth: 'content', mt: 4 }}>
          <Text variant="h6">Beställda nyckeltal</Text>
          <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
            Information om vilka nyckeltal du har beställt i förhållande till
            implementationsstatus.
          </Text>
        </Box>
        <ThemeProvider theme={getTheme('dark')}>
          <Grid
            container={true}
            spacing={2}
            sx={{ flex: 1, position: 'relative' }}
          >
            <ImplementationProgressKpi
              label={'Integrerat'}
              value={integratedProgress}
            />
            <ImplementationProgressKpi
              label={'Modellerat'}
              value={modelledProgress}
            />
            <ImplementationProgressKpi
              label={'Kvalitetssäkrat'}
              value={qualityAssuredProgress}
            />
            <ImplementationProgressKpi label={'Totalt'} value={totalProgress} />
          </Grid>
        </ThemeProvider>
        <Table
          headers={keyFigureData.headers}
          loading={!props.kpiTemplatesFetched}
          rows={keyFigureData.rows}
          sx={{ maxHeight: 520 }}
          size={'small'}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Licens" sx={{ maxWidth: 'section', mt: 2 }}>
        <Box sx={{ maxWidth: 'content' }}>
          <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
            Information om vilka features som ingår i din licens.
          </Text>
        </Box>
        <Table headers={licenseData.headers} rows={licenseData.rows} />
      </CollapsibleSection>
    </>
  )
}

const ImplementationProgressKpi = (props: ImplementationProgressKpiProps) => {
  return (
    <Grid item={true} xs={12} md={3}>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          minWidth: 0,
          position: 'relative'
        }}
        elevation={0}
      >
        <Text color={'text.primary'} fontWeight={'bold'}>
          {props.label}
        </Text>
        <Text variant="h3" color={'text.primary'}>
          {`${Math.round(props.value * 100)}%`}
        </Text>
      </Paper>
    </Grid>
  )
}

const Table = (props: TableProps) => {
  if (props.loading) {
    return (
      <Paper sx={{ mt: 2, width: '100%', height: 520 }}>
        <LoadingSection
          titles={[
            'Hämtar information om din licens',
            'Hämtar information om dina nyckeltal'
          ]}
          loading
        />
      </Paper>
    )
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, ...(props.sx ? props.sx : {}) }}
    >
      <AtomTable
        sx={{ minWidth: 650 }}
        aria-label="caption table"
        stickyHeader
        size={props.size}
      >
        <ThemeProvider theme={getTheme('dark')}>
          <TableHead sx={{ bgcolor: 'black.main' }}>
            <TableRow>
              {props.headers.map((header) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  align={header.align}
                  key={header.label}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </ThemeProvider>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.label}
              sx={{ bgcolor: row.isHeader ? 'grey.200' : undefined }}
            >
              <TableCell
                scope="row"
                sx={{ fontWeight: 'bold' }}
                colSpan={row.colSpan}
              >
                {row.label}
              </TableCell>
              {row.values.map((value, j) => (
                <TableCell
                  key={`${row.label}-${j}`}
                  align={'center'}
                  sx={{ width: 100 }}
                >
                  <Icon
                    name={value ? 'Check' : 'Close'}
                    color={value ? 'success' : 'error'}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </AtomTable>
    </TableContainer>
  )
}

export default LicenseTemplate
