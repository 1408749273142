/* Fake feature for sales and review */

export enum WidgetNotificationType {
  PERIODIC = 'PERIODIC',
  LISTENER = 'LISTENER'
}

export const translateWidgetNotificationType: Record<
  WidgetNotificationType,
  string
> = {
  [WidgetNotificationType.PERIODIC]: 'Periodisk',
  [WidgetNotificationType.LISTENER]: 'Vid händelse'
}

export enum WidgetNotificationFrequency {
  DAILY = 'DAILY',
  DAILY_WORK_DAY = 'DAILY_WORK_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export const translateWidgetNotificationFrequency: Record<
  WidgetNotificationFrequency,
  string
> = {
  [WidgetNotificationFrequency.DAILY]: 'Varje dag',
  [WidgetNotificationFrequency.DAILY_WORK_DAY]: 'Varje arbetsdag',
  [WidgetNotificationFrequency.EVERY_WEEK]: 'Måndag varje vecka',
  [WidgetNotificationFrequency.MONTHLY]: 'Den 1:a varje månad',
  [WidgetNotificationFrequency.QUARTERLY]: 'Den 1:a varje kvartal',
  [WidgetNotificationFrequency.YEARLY]: 'Den 1:a varje år'
}

export enum WidgetNotificationFunction {
  GREATER_THAN = 'GREATER_THAN',
  LESSER_THAN = 'LESSER_THAN'
}

export const translateWidgetNotificationFunction: Record<
  WidgetNotificationFunction,
  string
> = {
  [WidgetNotificationFunction.GREATER_THAN]: 'Blir större än',
  [WidgetNotificationFunction.LESSER_THAN]: 'Blir mindre än'
}

export interface PeriodicSettings {
  frequency: WidgetNotificationFrequency
}

export interface ListenerSettings {
  kpi_option_id: string
  function: WidgetNotificationFunction
  value: number
}

export interface WidgetNotification {
  id: string
  dashboard_id: string
  widget_id: string
  type: WidgetNotificationType
  periodic_settings?: PeriodicSettings
  listener_settings?: ListenerSettings
  subscribers: string[]
}
