import React, { ReactNode } from 'react'

import { SxProps, useTheme } from '@mui/material'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

interface SettingsToggleOptionProps {
  badge?: boolean
  disabled?: boolean
  iconName: IconNames
  onClick?: () => void
  selected: boolean
  sx?: SxProps
  title?: string
  tooltip?: ReactNode
}

const SettingsToggleOption = React.forwardRef(
  (props: SettingsToggleOptionProps, ref: any) => {
    const { badge, disabled, iconName, onClick, selected, sx, title, tooltip } =
      props

    const theme = useTheme()

    return (
      <Box ref={ref} component="li" sx={sx}>
        <Tooltip title={tooltip}>
          <IconButton
            disabled={disabled}
            color={selected ? 'primary' : undefined}
            onClick={onClick}
            sx={{
              borderRadius: 1,
              width: '100%'
            }}
          >
            <Badge color="info" variant="dot" invisible={!badge}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Icon name={iconName} sx={{ mb: 1 }} />
                <Chip
                  color={selected ? 'primary' : undefined}
                  label={title}
                  size="small"
                  sx={{
                    fontSize: theme.typography.caption.fontSize,
                    height: '18px'
                  }}
                />
              </Box>
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
)

SettingsToggleOption.displayName = 'SettingsToggleOption'

export default SettingsToggleOption
