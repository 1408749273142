import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Spreadsheet from 'components/containers/DataPlatform/build/Spreadsheet'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import * as CmdbRowActions from 'redux/actions/DataPlatform/cmdb/Rows'
import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'
import * as AlertActions from 'redux/actions/Alert'

import ApiSection from './ApiSection'
import PutApiModal from './PutApiModal'

const Data = ({
  match,
  CmdbRowStore,
  tryGetCmdbRows,
  CmdbTableStore,
  tryGetCmdbTables,
  CmdbApiStore,
  AuthStore,
  setAlert,
  resetAlert,
  tryUpdateCmdbTable
}) => {
  const [putApiModal, setPutApiModal] = useState(false)

  useEffect(() => {
    if (!CmdbRowStore.data[match.params.cmdbId]) {
      tryGetCmdbRows(match.params.cmdbId)
    }

    if (!CmdbTableStore.fetched) {
      tryGetCmdbTables()
    }
  }, [])

  const table = CmdbTableStore.data[match.params.cmdbId]
  const primaryKey = table
    ? table.attributes.columns.find((c) => c.attributes.tag_primary_key)
    : undefined

  if (!CmdbRowStore.data[match.params.cmdbId]) {
    return null
  }

  return (
    <>
      <Paper sx={{ mt: 2, p: 2 }}>
        <h2>API</h2>
        <Divider sx={{ my: 2 }} />
        <small className="secondary">
          Om registret är kopplat till ett API så kan du se och uppdatera
          informationen här eller ta bort kopplingen.
        </small>

        {table.attributes.cmdb_api_id ? (
          <Box>
            <ApiSection
              table={table}
              primaryKey={primaryKey}
              api={CmdbApiStore.data[table.attributes.cmdb_api_id]}
              customerName={AuthStore.customer.slug}
              setPutApiModal={setPutApiModal}
              setAlert={setAlert}
              resetAlert={resetAlert}
              onRemove={() =>
                tryUpdateCmdbTable(table.id, {
                  data: { attributes: { cmdb_api_id: null, slug: null } }
                })
              }
            />
          </Box>
        ) : (
          <Box>
            <Button
              disabled={!primaryKey}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
              onClick={() =>
                setPutApiModal({
                  cmdb_api_id: undefined,
                  slug: undefined
                })
              }
              variant="text"
            >
              Koppla register till API
            </Button>
          </Box>
        )}
      </Paper>

      <Spreadsheet table={table} />

      <PutApiModal
        visible={putApiModal}
        onClose={() => setPutApiModal(undefined)}
        apis={Object.values(CmdbApiStore.data)}
        customerName={AuthStore.customer.slug}
        onUpdate={(data) => tryUpdateCmdbTable(table.id, { data })}
      />
    </>
  )
}

function mapStateToProps({
  CmdbRowStore,
  AuthStore,
  CmdbTableStore,
  CmdbApiStore
}) {
  return {
    CmdbRowStore,
    AuthStore,
    CmdbTableStore,
    CmdbApiStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CmdbRowActions,
      ...CmdbTableActions,
      ...AlertActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Data))
