import React from 'react'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, flex: 0.25 }}>
          {[1, 2, 3, 4].map((item) => (
            <Skeleton
              key={item}
              variant="rectangular"
              height={'100%'}
              sx={{ flex: 1 }}
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flex: 0.75 }}>
          <Skeleton variant="rectangular" height={'100%'} sx={{ flex: 0.5 }} />
          <Box
            sx={{
              flex: 0.5,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            {[1, 2].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={'100%'}
                sx={{ flex: 0.5 }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
