import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import { useTheme } from '@mui/material'

interface EmptyPlaceholderProps {
  actions?: ReactNode
  background?: boolean
  empty: boolean
  height?: number | string
  icon?: IconNames
  scaleFactor?: number
  sx?: object
  title?: string
}

/**
 * The EmptyPlaceholder component is used
 * to show a placholder text when there
 * is not any items to display.
 *
 * It expands, and fills up, all
 * available space.
 */

const EmptyPlaceholder = React.forwardRef(
  (props: EmptyPlaceholderProps, ref: any) => {
    const theme = useTheme()

    return props.empty ? (
      <Box
        ref={ref}
        sx={{
          height: props.height ?? '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: props.background ? theme.palette.action.hover : undefined,
          borderRadius: props.background
            ? `${theme.shape.borderRadius}px`
            : undefined,
          gap: 1,
          p: 2,
          fontSize: props.scaleFactor ? 16 * props.scaleFactor : undefined,
          ...props.sx
        }}
      >
        {props.icon ? (
          <Icon
            color="disabled"
            name={props.icon}
            sx={{ fontSize: props.scaleFactor ? props.scaleFactor * 40 : 40 }}
          />
        ) : null}
        {props.title ? (
          <Text
            align="center"
            color="text.disabled"
            variant="body2"
            fontWeight="medium"
            fontSize={props.scaleFactor ? 'inherit' : undefined}
          >
            {props.title}
          </Text>
        ) : (
          false
        )}
        {props.actions ? (
          <Stack alignItems="center" sx={{ gap: 1 }}>
            {props.actions}
          </Stack>
        ) : null}
      </Box>
    ) : null
  }
)

EmptyPlaceholder.displayName = 'EmptyPlaceholder'
export default EmptyPlaceholder
