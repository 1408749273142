import ShortId from 'shortid'
import { isEdge, addEdge, isNode } from 'reactflow'
import Dagre from 'dagre'
import Moment from 'moment'

import * as Functions from 'helpers/Functions'
import { STANDARD_VALUE } from 'components/common/Node/conf.js'

export const DATASET_HEADERS = [
  { label: 'DATASET', key: 'name' },
  { label: 'KOLUMNER', key: 'attribute_count' }
]

export const NEW_ATTRIBUTE_NODES = [
  'DATE_PARSER',
  'TIME_DIFFERENCE',
  'CALCULATE'
]

export const TRANSFORM_NODES = [
  {
    title: 'Filter',
    icon: 'FilterAltOutlined',
    description: 'Filtrerar dataset efter ett visst villkor.',
    enum: 'FILTER',
    active: true
  },
  {
    title: 'Left join',
    icon: 'JoinLeftOutlined',
    description: 'Lägger ihop två tabeller genom en gemensam kolumn.',
    enum: 'LEFT_JOIN',
    active: true
  },
  {
    title: 'Unite',
    icon: 'DeviceHubOutlined',
    description: 'Laddar in poster från två tabeller i samma dataset.',
    enum: 'UNITE',
    active: true
  },
  {
    title: 'Clear',
    icon: 'RuleOutlined',
    description: 'Väljer bort kolumner som inte används.',
    enum: 'CLEAR',
    active: true
  },
  {
    title: 'Aggregate',
    icon: 'TuneOutlined',
    description: 'Aggregerar data baserat på vald funktion.',
    enum: 'AGGREGATE',
    active: true
  },
  {
    title: 'Concatenate',
    icon: 'MergeOutlined',
    description: 'Skapa ny kolumn genom att sammanfoga två kolumner.',
    enum: 'CONCATENATE',
    active: true
  },
  {
    title: 'Date parser',
    icon: 'EventAvailableOutlined',
    description: 'Konverterar textelement till önskat datumformat.',
    enum: 'DATE_PARSER',
    active: true
  },
  {
    title: 'Time difference',
    icon: 'TimerOutlined',
    description: 'Beräknar tidsskillnaden mellan två datum.',
    enum: 'TIME_DIFFERENCE',
    active: true
  },
  {
    title: 'Replace',
    icon: 'SwapHorizOutlined',
    description: 'Ersätt värden i en kolumn.',
    enum: 'REPLACE',
    active: true
  },
  {
    title: 'Calculate',
    icon: 'CalculateOutlined',
    description: 'Räkna ut värde.',
    enum: 'CALCULATE',
    active: true
  },
  {
    title: 'Cast',
    icon: 'TransformOutlined',
    description: 'Ändra typ för ett attribut.',
    enum: 'CAST',
    active: true
  },
  {
    title: 'Trim',
    icon: 'TransformOutlined',
    description: 'Trimma ett attribut på white-spaces.',
    enum: 'TRIM',
    active: true
  },
  {
    title: 'Lower',
    icon: 'TransformOutlined',
    description: 'Gör en kolumn till lowercase.',
    enum: 'LOWER',
    active: true
  }
]

export const updateGlobalNodeData = (nodes) => {
  const data = {}

  nodes.forEach((n) => {
    data[n.id] = n
  })

  return nodes.map((n) => ({
    ...n,
    data: { ...n.data, globalNodeData: data }
  }))
}

export const updateGraph = (
  setNodes,
  setEdges,
  elements,
  id,
  data,
  newNode,
  newEdge,
  elementsToRemove,
  fullObj
) => {
  const layedOut = getLayoutedElements({
    elementsObj: elements,
    id,
    data,
    newNode,
    newEdge,
    elementsToRemove,
    fullObj
  })

  setNodes(updateGlobalNodeData(layedOut.filter((e) => !isEdge(e))))
  setEdges(layedOut.filter((e) => isEdge(e)))
}

const findAffected = (currentId, elements, toUpdate) => {
  const affected = []

  elements.forEach((elem) => {
    if (elem.data && elem.data.leftNode === currentId) {
      toUpdate[elem.id] = {
        ...elem,
        data: {
          ...elem.data,
          leftAttribute: null,
          outputAttributes:
            elem.type === 'UNITE' ||
            elem.type === 'OUTPUT' ||
            elem.type === 'CONCATENATE'
              ? elem.data.outputAttributes
                .filter((attr) => attr.prepend !== currentId)
                .map((attr) => ({
                  ...attr,
                  rightAttribute: null,
                  leftAttribute: null
                }))
              : [],
          filterConditions: elem.data.filterConditions
            ? elem.data.filterConditions.map((fc) => ({
              ...fc,
              leftAttribute: null,
              rightAttribute: null
            }))
            : null
        }
      }

      affected.push(elem.id)
    } else if (elem.data && elem.data.rightNode === currentId) {
      toUpdate[elem.id] = {
        ...elem,
        data: {
          ...elem.data,
          rightAttribute: null,
          outputAttributes:
            elem.type === 'UNITE' || elem.type === 'OUTPUT'
              ? elem.data.outputAttributes
                .filter((attr) => attr.prepend !== currentId)
                .map((attr) => ({
                  ...attr,
                  rightAttribute: null,
                  leftAttribute: null
                }))
              : []
        }
      }

      affected.push(elem.id)
    }
  })

  if (affected.length > 0) {
    affected.forEach((elem) => {
      findAffected(elem, elements, toUpdate)
    })
  }

  return toUpdate
}

const getLayoutedElements = ({
  elementsObj,
  id,
  data,
  newNode,
  newEdge,
  elementsToRemove = [],
  fullObj,
  hasPositions = true
}) => {
  const obj = fullObj ? fullObj : elementsObj

  let elements = Object.values(obj)
  const dagreGraph = new Dagre.graphlib.Graph()

  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ rankdir: 'LR' })
  const newElements = {}
  let newNodeNumberMapper = undefined

  if (newNode) {
    newNodeNumberMapper = newNode.data.nodeNumberMapper
  }

  if (elementsToRemove.length > 0) {
    let skipRemove = false
    let updateObj = {}
    const filteredElementsToRemove = elementsToRemove.filter((id) => {
      const item = obj[id]

      if (item && !skipRemove && item.type === 'OUTPUT') {
        skipRemove = true

        return false
      }

      if (item && isEdge(item)) {
        const sourceNode = elementsObj[item.source]
        const targetNode = elementsObj[item.target]

        if (
          item.source === targetNode.data.leftNode &&
          !elementsToRemove.find((elem) => targetNode.id === elem.id)
        ) {
          newElements[targetNode.id] = {
            ...targetNode,
            data: {
              ...targetNode.data,
              leftNode: null,
              leftAttribute: null,
              outputAttributes: targetNode.data.outputAttributes.map(
                (attr) => ({
                  ...attr,
                  leftAttribute: null
                })
              )
            }
          }
        } else if (
          item.source === targetNode.data.rightNode &&
          !elementsToRemove.find((elem) => targetNode.id === elem.id)
        ) {
          newElements[targetNode.id] = {
            ...targetNode,
            data: {
              ...targetNode.data,
              rightNode: null,
              rightAttribute: null,
              outputAttributes: targetNode.data.outputAttributes.map(
                (attr) => ({ ...attr, rightAttribute: null })
              )
            }
          }
        }

        return !(sourceNode.data.locked || targetNode.data.locked)
      }

      updateObj = findAffected(id, elements, {})

      return item && !item.data.locked
    })

    if (!skipRemove) {
      Object.values(updateObj).forEach((elem) => {
        if (!filteredElementsToRemove.includes(elem.id)) {
          newElements[elem.id] = elem
        }
      })
      elements = elements.filter(
        (elem) => !filteredElementsToRemove.includes(elem.id)
      )
      filteredElementsToRemove.forEach((id) => {
        delete newElements[id]
      })
    }
  }

  if (newNode) {
    elements = [newNode, ...elements]
  }

  if (newEdge) {
    elements = addEdge(newEdge, elements)
  }

  if (!hasPositions) {
    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, {
          width: 370,
          height: 230
        })
      } else {
        dagreGraph.setEdge(el.source, el.target)
      }
    })

    Dagre.layout(dagreGraph)
  }

  elements.forEach((el) => {
    if (el.id === id) {
      el.data = {
        ...el.data,
        ...data
      }
    }

    if (isNode(el)) {
      if (!hasPositions) {
        const nodeWithPosition = dagreGraph.node(el.id)

        el.targetPosition = 'top'
        el.sourcePosition = 'bottom'

        el.position = {
          x: 200 + nodeWithPosition.x - 370 / 2 + Math.random() / 1000,
          y: 200 + nodeWithPosition.y - 230 / 2
        }
      }

      if (newNodeNumberMapper) {
        el.data.nodeNumberMapper = newNodeNumberMapper
      }
    }

    newElements[el.id] = el.id in newElements ? newElements[el.id] : el
  })

  return Object.values(newElements)
}

const parseInitialAttributes = (attrs, isAggregateNode = false) =>
  attrs.map((r) => ({
    leftAttribute: r.attributes.left_attribute,
    name: r.attributes.name,
    realName: r.attributes.real_name,
    shortId: r.id,
    type: r.attributes.type,
    rightAttribute: r.attributes.right_attribute,
    prepend: r.attributes.prepend,
    function: r.attributes.function,
    id: r.id,
    manualInput: r.attributes.manual_input,
    delimiter: r.attributes.delimiter,
    isCalculated: r.attributes.is_calculated,
    // group by attribute is missing leftAttribute
    isGroupBy: isAggregateNode ? !r.attributes.left_attribute : null,
    sensitiveRefColumnId: r.attributes.sensitive_ref_column_id
  }))

const NODE_NUMBER_TYPES = [
  'INPUT',
  'UNITE',
  'CONCATENATE',
  'AGGREGATE',
  'DATE_PARSER',
  'TIME_DIFFERENCE',
  'REPLACE',
  'CALCULATE'
]

// load with nodes and edges
export const getInitialElements = (
  DatasetStore,
  match,
  table,
  buildTableName
) => {
  let nodeNumberMapper = {}
  let nodeNumberCounter = 1
  let hasPositions = true

  const datasetElements = DatasetStore.data[
    match.params.tableId
  ].included.shape_nodes.map((node) => {
    if (NODE_NUMBER_TYPES.includes(node.attributes.type)) {
      nodeNumberMapper[node.id] = parseInt(nodeNumberCounter)

      nodeNumberCounter++
    }

    if (!node.attributes.location_x) {
      hasPositions = false
    }

    const initialOutputAttributes = parseInitialAttributes(
      node.included.output_attributes,
      node.attributes.type === 'AGGREGATE'
    )

    return {
      type: node.attributes.type === 'MAP' ? 'OUTPUT' : node.attributes.type,
      id: node.id,
      position: hasPositions
        ? { x: node.attributes.location_x, y: node.attributes.location_y }
        : { x: node.attributes.type === 'MAP' ? 600 : 100, y: 100 },
      data: {
        leftNode: node.attributes.left_node,
        leftAttribute: node.attributes.left_attribute,
        locked:
          node.attributes.type === 'MAP'
            ? DatasetStore.data[match.params.tableId].attributes.locked
            : false,
        manualInputBuildId: table.attributes.manual_input_build_id,
        buildTableName,
        rightNode: node.attributes.right_node,
        rightAttribute: node.attributes.right_attribute,
        filterType: node.attributes.filter_type,
        refTableId: node.attributes.ref_table_id,
        productTag: node.attributes.product_tag,
        groupByAttribute: node.attributes.group_by_attribute,
        newAttribute: node.attributes.new_attribute,
        value:
          node.attributes.type === 'CALCULATE'
            ? parseSavedValue(node.attributes.value, initialOutputAttributes)
            : node.attributes.value,
        unit: node.attributes.unit,
        filterConditions: node.included.filter_conditions
          .map((cond) => {
            const dataType = node.included.output_attributes.find(
              (item) => item.id === cond.attributes.left_attribute
            )?.attributes?.type

            let rightValue = cond.attributes.right_value

            if (
              cond.attributes.type !== STANDARD_VALUE &&
              (dataType === 'DATE' || dataType === 'TIMESTAMP')
            ) {
              rightValue = Moment(rightValue, 'YYYY-MM-DD')
            }

            return {
              leftAttribute: cond.attributes.left_attribute,
              condition: cond.attributes.condition,
              rightValue: rightValue,
              rightAttribute: cond.attributes.right_attribute,
              conjunctiveOperator: cond.attributes.conjunctive_operator,
              type:
                cond.attributes.type === STANDARD_VALUE
                  ? cond.attributes.right_value
                  : cond.attributes.type,
              index: cond.attributes.index,
              dataType
            }
          })
          .sort((a, b) => (a.index < b.index ? -1 : 1)),
        name: table.attributes.name,
        technicalName: table.attributes.technical_name,
        primaryKey:
          DatasetStore.data[match.params.tableId].attributes
            .primary_key_attribute,
        referenceKey:
          DatasetStore.data[match.params.tableId].attributes
            .reference_key_attribute,
        outputAttributes: initialOutputAttributes
      }
    }
  })

  const edges = []

  datasetElements.forEach((elem) => {
    elem.data.nodeNumberMapper = nodeNumberMapper
    if (elem.data.leftNode) {
      edges.push({
        id: `edges-${elem.data.leftNode}_${elem.id}`,
        type: 'CUSTOM',
        source: elem.data.leftNode,
        sourceHandle: null,
        target: elem.id,
        targetHandle:
          elem.type === 'UNITE' || elem.type === 'LEFT_JOIN' ? 'left' : null,
        animated: true
      })
    }

    if (elem.data.rightNode) {
      edges.push({
        id: `edges-${elem.data.rightNode}_${elem.id}`,
        type: 'CUSTOM',
        source: elem.data.rightNode,
        sourceHandle: null,
        target: elem.id,
        targetHandle:
          elem.type === 'UNITE' || elem.type === 'LEFT_JOIN' ? 'right' : null,
        animated: true
      })
    }
  })

  return getLayoutedElements({
    elementsObj: Functions.arrayToObject([...datasetElements, ...edges]),
    hasPositions
  })
}

export const onConnect = (params, nodes, edges) => {
  const targetTaken = edges.find(
    (e) => e.target === params.target && e.targetHandle === params.targetHandle
  )
  const sourceTaken = edges.find((e) => e.source === params.source)

  const targetNode = nodes.find((n) => n.id === params.target)
  const sourceNode = nodes.find((n) => n.id === params.source)

  let handleTaken = !params.targetHandle

  // check if handle is taken
  if (
    (params.targetHandle &&
      params.targetHandle === 'left' &&
      targetNode.data.leftNode) ||
    (params.targetHandle === 'right' && targetNode.data.rightNode)
  ) {
    handleTaken = true
  }

  if (!sourceTaken && (!targetTaken || !handleTaken)) {
    const edgeId = ShortId.generate()

    const update = {
      id: params.target,
      data: {}
    }

    if (params.targetHandle === 'left' || !params.targetHandle) {
      if (targetNode.type === 'FILTER') {
        update.data.outputAttributes = sourceNode.data.outputAttributes.map(
          (attr) => ({
            ...attr,
            leftAttribute: null,
            rightAttribute: null,
            shortId: ShortId.generate()
          })
        )
      }

      update.data.leftNode = params.source
    } else {
      update.data.rightNode = params.source
    }

    return {
      update,
      edge: {
        ...params,
        id: edgeId,
        type: 'CUSTOM',
        animated: true,
        data: {
          edgeId
        }
      }
    }
  }
}

export const updatePosition = (node, setElements) => {
  setElements((els) => ({
    ...els,
    [node.id]: {
      ...els[node.id],
      position: node.position
    }
  }))
}

/**
 * Creates a blank output attribute, will only be called if the
 * shape node type is UNITE, CONCATENATE, DATE_PARSER or TIME_DIFFERENCE
 *
 * @param {string} id - prepend id
 * @param {string} shortId - shortid
 * @param {string} type - the type of the shape node
 * @returns {object} - an initial output attribute
 */
export const newNodeOutputAttribute = (id, shortId, type) => {
  const outputAttribute = {
    leftAttribute: undefined,
    name: undefined,
    realName: undefined,
    shortId: shortId,
    type: undefined,
    rightAttribute: undefined,
    prepend: id,
    delimiter: undefined,
    sensitiveRefColumnId: undefined
  }

  if (type === 'CONCATENATE') {
    outputAttribute.type = 'STRING'

    // default delimiter for concatenate is empty string
    outputAttribute.delimiter = ''
  } else if (type === 'DATE_PARSER') {
    outputAttribute.type = 'DATE'
  } else if (type === 'TIME_DIFFERENCE') {
    outputAttribute.type = 'BIGINT'
  } else if (type === 'CALCULATE') {
    outputAttribute.type = 'DOUBLE'
  }

  return outputAttribute
}

const UUID_REGEX = /(@).*?(?=\+|_-_|\*|\/|\(|\)|$)/g

const parseSavedValue = (value, outputAttributes) => {
  return value
    .replace(UUID_REGEX, (match) => {
      const validOption = outputAttributes.find(
        (oa) => oa.id === match.replace('@', '').trim()
      )

      return `@${validOption?.name}`
    })
    .replace('_-_', '-')
}
