import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import { create, update, destroy } from 'redux/api/EconomyAccounts'

export function tryCreateEconomyAccount(
  data: { name: string; number: string; economy_set_id: string },
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_ECONOMY_ACCOUNT
    })

    create(data)
      .then((response) => {
        dispatch({
          type: Types.CREATE_ECONOMY_ACCOUNT_SUCCESS,
          payload: {
            data: response.data.data,
            economySetId: data.economy_set_id
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto skapat' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ECONOMY_ACCOUNT_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto kunde inte skapas' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryUpdateEconomyAccount(
  id: string,
  name: string,
  economySetId: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ECONOMY_ACCOUNT
    })

    update(id, name)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ECONOMY_ACCOUNT_SUCCESS,
          payload: { data: response.data.data, economySetId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto uppdaterat' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ECONOMY_ACCOUNT_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}

export function tryDeleteEconomyAccount(
  id: string,
  economySetId: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_ECONOMY_ACCOUNT
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ECONOMY_ACCOUNT_SUCCESS,
          payload: { id, economySetId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto borttaget' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ECONOMY_ACCOUNT_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Konto kunde inte tas bort' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
