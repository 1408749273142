import React, {
  DragEventHandler,
  ElementType,
  MouseEventHandler,
  ReactNode
} from 'react'
import { Box as MUIBox, SxProps } from '@mui/material'

interface BoxProps {
  children?: ReactNode
  className?: string
  component?: ElementType<any>
  id?: string
  sx?: SxProps
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onDragEnter?: (() => void) | DragEventHandler<HTMLElement>
  onDragLeave?: (() => void) | DragEventHandler<HTMLElement>
  onDragOver?: (() => void) | DragEventHandler<HTMLElement>
  onDrop?: (() => void) | DragEventHandler<HTMLElement>
  onMouseEnter?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseLeave?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOver?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOut?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseUp?: (() => void) | MouseEventHandler<HTMLElement>
}

/**
 * The Box component serves as a wrapper component
 * for most of the CSS utility needs. Think of it
 * as a <div> replacer.
 */

const Box = React.forwardRef((props: BoxProps, ref: any) => (
  <MUIBox {...props} ref={ref}>
    {props.children}
  </MUIBox>
))

Box.displayName = 'Box'
export default Box
