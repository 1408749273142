import React from 'react'

import { PaletteMode } from '@mui/material'
import { TStyle } from './SelectStyle'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import { FormattedWidgetData, ParsedWidgetDataLabel } from 'types/GlobalWidget'

export interface CustomGeoJsonProperties {
  cluster?: boolean
  cluster_id?: number
  differences: (number | null)[]
  title: string
  values: number[]
  percentages: (number | null)[]
  // values
  value_0?: number | null
  value_1?: number | null
  value_2?: number | null
  value_3?: number | null
  value_4?: number | null
  value_5?: number | null
  value_6?: number | null
  value_7?: number | null
  value_8?: number | null
  value_9?: number | null
  // clusterProperty: sum
  sum_0?: number | null
  sum_1?: number | null
  sum_2?: number | null
  sum_3?: number | null
  sum_4?: number | null
  sum_5?: number | null
  sum_6?: number | null
  sum_7?: number | null
  sum_8?: number | null
  sum_9?: number | null
}

export const styleLink: Record<PaletteMode, Record<TStyle, string>> = {
  ['dark']: {
    ['street']: 'mapbox://styles/axelhomepal/clwyqtg7401gg01ny34zv458j',
    ['satellite']: 'mapbox://styles/axelhomepal/clvgkg7y6017y01qv89vh0lod'
  },
  ['light']: {
    ['street']: 'mapbox://styles/axelhomepal/clwymc8z301bh01qxebh10p9m',
    ['satellite']: 'mapbox://styles/axelhomepal/clvgkg7y6017y01qv89vh0lod'
  }
}

export const mapBetaTooltip = (
  <Box
    sx={{
      '& ul': {
        display: 'block',
        listStyle: 'disc outside none',
        margin: '1em 0',
        padding: '0 0 0 24px'
      },
      '& li': {
        display: 'list-item'
      }
    }}
  >
    <Text color="inherit" fontSize="inherit" gutterBottom={true}>
      <b>Att visa data på karta utvärderas</b>
    </Text>
    <Text color="inherit" fontSize="inherit">
      Du använder den här visualiseringen med vetskapen om att:
    </Text>
    <Text color="inherit" fontSize="inherit">
      <ul>
        <li>
          viss funktionalitet kan <u>bete sig oväntat</u>,
        </li>
        <li>
          utseende kan <u>ändras mycket</u> framöver,
        </li>
        <li>
          det här kan bli en <u>tilläggstjänst</u> som inte ingår i din
          nuvarande licens.
        </li>
      </ul>
    </Text>
  </Box>
)

export type TFeature = {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: {
    title: string
    label: ParsedWidgetDataLabel
    values: string[]
    percentages: (number | null)[]
    differences: number[]
    // for clusters:
    value_0: number
    value_1: number
    value_2: number
    value_3: number
    value_4: number
    value_5: number
    value_6: number
    value_7: number
    value_8: number
    value_9: number
  }
}

export const getFeatureByIndex = (
  formattedData: FormattedWidgetData,
  i: number
): TFeature => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: formattedData.coordinates[i] as [number, number]
    },
    properties: {
      title: formattedData.labels[i].display_label,
      label: formattedData.labels[i],
      values: formattedData.datasets.map(
        (dataset) => dataset.prettyData[i] as string
      ),
      percentages: formattedData.datasets.map((dataset) => {
        if (dataset.percentages) {
          return dataset.percentages[i] as number
        } else {
          return null
        }
      }),
      differences: formattedData.datasets.map((dataset) => {
        if (dataset.percentages && dataset.percentages[i]) {
          return Math.floor(
            (dataset.data[i] as number) *
              (dataset.percentages[i] as number) *
              0.01
          )
        } else {
          return 0
        }
      }),
      // for clusters:
      value_0: formattedData.datasets[0]
        ? (formattedData.datasets[0].data[i] as number)
        : 0,
      value_1: formattedData.datasets[1]
        ? (formattedData.datasets[1].data[i] as number)
        : 0,
      value_2: formattedData.datasets[2]
        ? (formattedData.datasets[2].data[i] as number)
        : 0,
      value_3: formattedData.datasets[3]
        ? (formattedData.datasets[3].data[i] as number)
        : 0,
      value_4: formattedData.datasets[4]
        ? (formattedData.datasets[4].data[i] as number)
        : 0,
      value_5: formattedData.datasets[5]
        ? (formattedData.datasets[5].data[i] as number)
        : 0,
      value_6: formattedData.datasets[6]
        ? (formattedData.datasets[6].data[i] as number)
        : 0,
      value_7: formattedData.datasets[7]
        ? (formattedData.datasets[7].data[i] as number)
        : 0,
      value_8: formattedData.datasets[8]
        ? (formattedData.datasets[8].data[i] as number)
        : 0,
      value_9: formattedData.datasets[9]
        ? (formattedData.datasets[9].data[i] as number)
        : 0
    }
  }
}
