import React, { useRef } from 'react'

import { Form, Row, Col } from 'antd'

import Modal from 'components/common/Modal'
import Button from 'components_new/atoms/Button'
import Input from 'components/common/Input'

import * as Constants from 'helpers/Constants'

import Styles from './styles.module.css'
import IconButton from 'components_new/atoms/IconButton'
import Icon from 'components_new/atoms/Icon'

const EditEndpointsModal = ({ open, closeModal, onUpdate, source }) => {
  const formRef = useRef(null)

  const initialValues = {
    endpoints: source.included.endpoints
      ? source.included.endpoints.map((item) => ({ ...item.attributes }))
      : []
  }

  return (
    <Modal
      visible={open}
      onClose={() => closeModal()}
      header={'Uppdatera autentisering'}
    >
      <Form
        layout={'vertical'}
        ref={formRef}
        onFinish={(formAttr) => {
          onUpdate(formAttr)
          formRef.current.resetFields()
        }}
        initialValues={initialValues}
      >
        <Form.List name={'endpoints'}>
          {(fields, { add, remove }) => (
            <Row gutter={[16, 16]}>
              {fields.map(({ name, fieldKey, restField }, index) => (
                <div
                  className={Styles['endpoint-row-container']}
                  key={`col-${fieldKey}-${index}`}
                >
                  <Col span={10}>
                    <Form.Item
                      name={[name, 'url']}
                      fieldKey={[fieldKey, 'url']}
                      {...restField}
                      rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                    >
                      <Input placeholder={'Url'} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[name, 'content_field']}
                      fieldKey={[fieldKey, 'content_field']}
                      {...restField}
                    >
                      <Input placeholder={'Plats'} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[name, 'name']}
                      fieldKey={[fieldKey, 'name']}
                      {...restField}
                      rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                    >
                      <Input placeholder={'Namn'} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item>
                      <IconButton onClick={() => remove(name)}>
                        <Icon name="Close" />
                      </IconButton>
                    </Form.Item>
                  </Col>
                </div>
              ))}
              <div className={'width-100'}>
                <Form.Item>
                  <Button
                    onClick={() =>
                      add({
                        url: undefined,
                        content_field: undefined,
                        name: undefined
                      })
                    }
                  >
                    Lägg till endpoint
                  </Button>
                </Form.Item>
              </div>
            </Row>
          )}
        </Form.List>
        <Row gutter={8} align={'end'}>
          <Col>
            <Button onClick={() => closeModal()} variant="text">
              Avbryt
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="submit">Spara</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditEndpointsModal
