import React, { ElementType, ReactNode } from 'react'
import { Table as MUITable, tableCellClasses } from '@mui/material'

export interface TableProps {
  children?: ReactNode
  component?: ElementType<any>
  noDividers?: boolean
  padding?: 'checkbox' | 'none' | 'normal'
  size?: 'medium' | 'small'
  stickyHeader?: boolean
  sx?: object
}

/**
 * The Table component is a...
 */

const Table = React.forwardRef((props: TableProps, ref: any) => {
  const { children, noDividers, sx, ...rest } = props

  return (
    <MUITable
      sx={{
        ...(noDividers
          ? {
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }
          : null),
        ...sx
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MUITable>
  )
})

Table.displayName = 'Table'
export default Table
