import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Button as MUIButton, useTheme } from '@mui/material'
import { FillSpinner } from 'react-spinners-kit'
import { Color } from 'themes'

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

interface ButtonProps {
  children?: ReactNode
  color?:
    | Color
    | 'inherit'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
    | 'neutral'
  component?: ElementType<any>
  disabled?: boolean
  disableElevation?: boolean
  disableRipple?: boolean
  endIcon?: ReactNode
  fullHeight?: boolean
  fullWidth?: boolean
  href?: string
  loading?: boolean
  loadingTitle?: string
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large' | 'gigantic'
  startIcon?: ReactNode
  sx?: object
  type?: 'button' | 'submit' | 'reset'
  to?: string
  variant?: 'contained' | 'outlined' | 'text'
}

/**
 * The Button component allow users to take
 * actions, and make choices, with a single tap.
 */

const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const {
    loading,
    fullHeight,
    startIcon,
    endIcon,
    disabled,
    loadingTitle = 'Laddar',
    size,
    sx,
    ...rest
  } = props

  const theme = useTheme()

  return (
    <MUIButton
      component={props.to ? LinkBehavior : undefined}
      sx={{
        height: fullHeight ? '100%' : null,
        whiteSpace: 'nowrap',
        ...(size === 'gigantic' && {
          padding: '18px 32px',
          fontSize: theme.typography.h6.fontSize
        }),
        ...sx
      }}
      ref={ref}
      endIcon={loading ? null : endIcon}
      startIcon={
        loading ? (
          <FillSpinner
            size={14}
            color={theme.palette.action.focus}
            loading={props.loading}
          />
        ) : (
          startIcon
        )
      }
      disabled={loading ? loading : disabled}
      size={size === 'gigantic' ? undefined : size}
      {...rest}
    >
      {loading ? `${loadingTitle}...` : props.children}
    </MUIButton>
  )
})

// To do: Add LinkBehavior to ListItemButton, IconButton, MenuItem,
// CardActionArea as well.
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
  (props, ref) => <RouterLink ref={ref} to="/" {...props} role={undefined} />
)

Button.defaultProps = {
  variant: 'contained'
}

Button.displayName = 'Button'
export default Button
