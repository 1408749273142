import React from 'react'

import DataTypeIcon from 'components/common/DataTypeIcon'

import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import { FORM_VALIDATIONS } from 'helpers/Constants'

import Styles from './styles.module.css'

export const ONLY_LEFT = 'ONLY_LEFT'
export const COLUMN = 'COLUMN'
export const STANDARD_VALUE = 'STANDARD_VALUE'
export const NUMBER_OF_CHARACTERS = 'NUMBER_OF_CHARACTERS'
export const INPUT_VALUE = 'INPUT_VALUE'

export const CONDITIONS = (type) => {
  if (type === 'BOOLEAN') {
    return [
      { value: 'IS TRUE', label: 'är sant' },
      { value: 'IS FALSE', label: 'är falskt' },
      { value: 'IS NULL', label: 'är tom' },
      { value: 'IS NOT NULL', label: 'inte är tom' }
    ]
  }

  const stringConditions =
    type === 'STRING'
      ? [
          { value: 'INCLUDES', label: 'inkluderar' },
          { value: 'EXCLUDES', label: 'inkluderar inte' },
          { value: 'STARTS_WITH', label: 'börjar med' },
          { value: 'ENDS_WITH', label: 'slutar med' }
        ]
      : []

  return [
    { value: '=', label: 'är lika med' },
    { value: '!=', label: 'inte är lika med' },
    { value: '>', label: 'är större än' },
    { value: '>=', label: 'är större än eller lika med' },
    { value: '<', label: 'är mindre än' },
    { value: '<=', label: 'är mindre än eller lika med' },
    ...stringConditions,
    { value: 'IS NULL', label: 'är tom' },
    { value: 'IS NOT NULL', label: 'inte är tom' }
  ]
}

export const DATE_UNITS = [
  { value: 'MINUTES', label: 'Minuter' },
  { value: 'HOURS', label: 'Timmar' },
  { value: 'DAYS', label: 'Dagar' },
  { value: 'WORKING_DAYS', label: 'Arbetsdagar' },
  { value: 'YEARS', label: 'År' }
]

export const ONLY_LEFT_CONDITIONS = [
  'IS NULL',
  'IS NOT NULL',
  'IS TRUE',
  'IS FALSE'
]
export const TODAY = '__TODAY__'
export const STANDARD_VALUES = [TODAY]
export const ONLY_TEXT_CONDITIONS = [
  'INCLUDES',
  'EXCLUDES',
  'STARTS_WITH',
  'ENDS_WITH'
]

export const isDate = (dtype) => dtype === 'DATE' || dtype === 'TIMESTAMP'
export const isNumber = (dtype) => dtype === 'DOUBLE' || dtype === 'BIGINT'

const TEXT = [
  { value: 'NUMBER_OF_CHARACTERS', label: 'Antal tecken' },
  { value: 'COLUMN', label: 'Kolumn' },
  { value: 'INPUT_VALUE', label: 'Text' }
]

const NUMBER = [
  { value: 'COLUMN', label: 'Kolumn' },
  { value: 'INPUT_VALUE', label: 'Nummer' }
]

const DATE = [
  { value: TODAY, label: 'Idag' },
  { value: 'COLUMN', label: 'Kolumn' },
  { value: 'INPUT_VALUE', label: 'Specifikt datum' }
]

export const FILTER_VALUE_TYPES = {
  STRING: TEXT,
  BIGINT: NUMBER,
  DOUBLE: NUMBER,
  TIMESTAMP: DATE,
  DATE: DATE
}

export const TYPE_GROUPS = {
  STRING: ['STRING'],
  BIGINT: ['BIGINT', 'DOUBLE'],
  DOUBLE: ['BIGINT', 'DOUBLE'],
  TIMESTAMP: ['DATE', 'TIMESTAMP'],
  DATE: ['DATE', 'TIMESTAMP'],
  BOOLEAN: ['BOOLEAN']
}

export const getSelectOptions = (chosenAttributes, dataType) =>
  Object.values(chosenAttributes)
    .filter((attr) => {
      if (!dataType) {
        return true
      }

      if (isDate(dataType) && isDate(attr.type)) {
        return true
      } else if (isNumber(dataType) && isNumber(attr.type)) {
        return true
      } else if (dataType === 'STRING' && attr.type === 'STRING') {
        return true
      }

      return false
    })
    .map((attr) => ({
      value: attr.outputAttributeId,
      label: (
        <div className={Styles['replace-select-options']}>
          <DataTypeIcon type={attr.type} />
          <Text variant="body1">{attr.outputAttributeName}</Text>
        </div>
      )
    }))

export const InfoMessage = ({ infoText }) => (
  <div>
    <Icon name="InfoOutlined" />
    <small className={'secondary'}>{infoText}</small>
  </div>
)

export const getDuplicateColumnValidation = (chosenAttributes) => {
  const allAttributeNames = Object.values(chosenAttributes).map(
    (attr) => attr.outputAttributeName
  )

  return FORM_VALIDATIONS.DUPLICATE_SIMPLE(allAttributeNames)
}

export const getAllAttributesWithinTypes = (chosenAttributes, types) =>
  Object.values(chosenAttributes)
    .filter((attr) => types.includes(attr.type))
    .map((attr) => attr)

export const getDateOptions = (chosenAttributes) => {
  return getAllAttributesWithinTypes(chosenAttributes, [
    'DATE',
    'TIMESTAMP'
  ]).map((attr) => ({
    label: (
      <div className={Styles['attribute-label']}>
        <DataTypeIcon type={'DATE'} />
        <Text variant="body1">{attr.outputAttributeName}</Text>
      </div>
    ),
    value: attr.outputAttributeId
  }))
}
