import React from 'react'

import _ from 'lodash'

import { Form } from 'antd'

import * as Constants from 'helpers/Constants'

import Select from 'components/common/Select'
import Input from 'components/common/Input'
import InputNumber from 'components/common/InputNumber'
import DatePicker from 'components/common/DatePicker'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'

import * as Conf from '../conf'
import Styles from '../styles.module.css'

// TODO: form attributes for dashboard widgets => snake case
const Condition = ({
  remove,
  formRef,
  attributeOptions,
  name,
  formListPath, // conditions || widgetMetrics[0].metricConditions
  namePrepend,
  // Attribute || WidgetMetricId (used with widget metric formattings)
  referenceNameSuffix = 'Attribute'
}) => {
  const values = formRef.getFieldsValue()
  const valueObject = _.get(values, formListPath)
  const value = valueObject && name in valueObject ? valueObject[name] : {}
  const leftIsSegmentBy = values.segment_by === value?.leftAttribute
  const rightIsSegmentBy = values.segment_by === value?.rightAttribute

  const leftName = leftIsSegmentBy
    ? 'leftAttribute'
    : `left${referenceNameSuffix}`
  const rightName = rightIsSegmentBy
    ? 'rightAttribute'
    : `right${referenceNameSuffix}`

  return (
    <Paper key={`attr-${name}`} sx={{ p: 1 }} variant="outlined">
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 0.5 }}>
        <IconButton edge="end" onClick={() => remove()} size="small">
          <Icon fontSize="small" name="Close" />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Form.Item
          name={[...namePrepend, leftName]}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          className={Styles['form-item']}
          getValueFromEvent={(leftAttribute) => {
            const prevAttribute = attributeOptions.find(
              (attr) => attr.value === value[leftName]
            )
            const newAttribute = attributeOptions.find(
              (attr) => attr.value === leftAttribute
            )

            const prevDataType = prevAttribute?.type
            const newDataType = newAttribute?.type

            if (value.type === null) {
              // set type COLUMN if first time choosing attribute
              _.set(values, `${formListPath}[${name}].type`, Conf.COLUMN)

              formRef.setFieldsValue(values)
            }
            if (prevDataType !== newDataType) {
              // reset values if data type is changed
              _.set(values, `${formListPath}[${name}].type`, Conf.COLUMN)
              _.set(values, `${formListPath}[${name}].condition`, null)
              _.set(values, `${formListPath}[${name}].rightValue`, null)
              _.set(values, `${formListPath}[${name}].rightAttribute`, null)
              _.set(
                values,
                `${formListPath}[${name}].rightWidgetMetricId`,
                null
              )

              formRef.setFieldsValue(values)
            }

            if (
              leftName === 'leftWidgetMetricId' &&
              leftAttribute === values.segment_by
            ) {
              // if segment by and metric formattings, set leftAttribute
              _.set(
                values,
                `${formListPath}[${name}].leftAttribute`,
                leftAttribute
              )

              formRef.setFieldsValue(values)

              return null
            }

            return leftAttribute
          }}
        >
          <Select
            size="middle"
            placeholder={'Attribut'}
            options={attributeOptions}
          />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {(form) => {
            const values = form.getFieldsValue()
            const valueObject = _.get(values, formListPath)
            const item = valueObject?.[name]
            const isReferencingSegmentBy =
              values.segmentBy === item?.leftAttribute

            // segmentBy is never widget metric
            const leftValue = isReferencingSegmentBy
              ? item?.leftAttribute
              : item?.[leftName]

            const attributeType = attributeOptions.find(
              (attr) => attr.value === leftValue
            )?.type

            return (
              leftValue && (
                <Form.Item
                  name={[...namePrepend, 'condition']}
                  rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                  className={Styles['form-item']}
                  getValueFromEvent={(condition) => {
                    // check if condition is type ONLY_LEFT
                    if (Conf.ONLY_LEFT_CONDITIONS.includes(condition)) {
                      _.set(
                        values,
                        `${formListPath}[${name}].type`,
                        Conf.ONLY_LEFT
                      )
                    } else if (Conf.ONLY_TEXT_CONDITIONS.includes(condition)) {
                      _.set(
                        values,
                        `${formListPath}[${name}].type`,
                        Conf.INPUT_VALUE
                      )
                    } else if (value.type === Conf.ONLY_LEFT) {
                      // if previous type was ONLY_LEFT then set COLUMN
                      _.set(
                        values,
                        `${formListPath}[${name}].type`,
                        Conf.COLUMN
                      )
                    }

                    form.setFieldsValue(values)

                    return condition
                  }}
                >
                  <Select
                    size="middle"
                    placeholder={'Villkor'}
                    options={Conf.CONDITIONS(attributeType)}
                    sorted={false}
                  />
                </Form.Item>
              )
            )
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {(form) => {
            const values = form.getFieldsValue()
            const valueObject = _.get(values, formListPath)
            const item = valueObject?.[name]
            const isReferencingSegmentBy =
              values.segmentBy === item?.leftAttribute

            // segmentBy is never widget metric
            const leftValue = isReferencingSegmentBy
              ? item?.leftAttribute
              : item?.[leftName]

            const attribute = attributeOptions.find(
              (attr) => attr.value === leftValue
            )

            const dataType = attribute?.type

            return (
              item &&
              item.condition &&
              item.type !== Conf.ONLY_LEFT &&
              dataType && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <Form.Item
                      name={[...namePrepend, 'type']}
                      className={Styles['form-item']}
                      rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                      getValueFromEvent={(type) => {
                        if (type !== value.type) {
                          // reset right value and attribute if type is changed
                          _.set(
                            values,
                            `${formListPath}[${name}].rightValue`,
                            null
                          )
                          _.set(
                            values,
                            `${formListPath}[${name}].rightAttribute`,
                            null
                          )
                          _.set(
                            values,
                            `${formListPath}[${name}].rightWidgetMetricId`,
                            null
                          )

                          form.setFieldsValue(values)
                        }

                        return type
                      }}
                    >
                      <Select
                        size={'middle'}
                        clearable={false}
                        options={
                          Conf.ONLY_TEXT_CONDITIONS.includes(item.condition)
                            ? [
                                {
                                  value: 'INPUT_VALUE',
                                  label: 'Text'
                                }
                              ]
                            : Conf.FILTER_VALUE_TYPES[dataType]
                        }
                      />
                    </Form.Item>
                  </Box>

                  {
                    // standard values should not have an additional value
                    !Conf.STANDARD_VALUES.includes(item.type) && (
                      <Box sx={{ flex: 1 }}>
                        {item.type === Conf.COLUMN ? (
                          <Form.Item
                            name={[...namePrepend, rightName]}
                            className={Styles['form-item']}
                            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                            getValueFromEvent={(rightReferenceValue) => {
                              const values = formRef.getFieldsValue()

                              if (
                                rightName === 'rightWidgetMetricId' &&
                                rightReferenceValue === values.segmentBy
                              ) {
                                // if segment by and metric formattings,
                                // set rightAtribute
                                _.set(
                                  values,
                                  `${formListPath}[${name}].rightAttribute`,
                                  rightReferenceValue
                                )

                                formRef.setFieldsValue(values)

                                return null
                              }

                              return rightReferenceValue
                            }}
                          >
                            <Select
                              size="middle"
                              placeholder={'Villkorsvärde'}
                              options={attributeOptions.filter((option) =>
                                Conf.TYPE_GROUPS[dataType].includes(option.type)
                              )}
                            />
                          </Form.Item>
                        ) : (
                          <RightValueFormItem
                            type={item.type}
                            dataType={dataType}
                            namePrepend={namePrepend}
                          />
                        )}
                      </Box>
                    )
                  }
                </Box>
              )
            )
          }}
        </Form.Item>
      </Box>
    </Paper>
  )
}

const RightValueFormItem = ({ type, dataType, namePrepend }) => {
  let inputField = <Input size={'middle'} placeholder={'Villkorsvärde'} />

  if (Conf.isNumber(dataType) || type === Conf.NUMBER_OF_CHARACTERS) {
    inputField = <InputNumber size={'middle'} placeholder={'Villkorsvärde'} />
  } else if (Conf.isDate(dataType)) {
    inputField = (
      <DatePicker
        size={'middle'}
        className={Styles['filter-operation-date-picker']}
        ignoreLockedMode
      />
    )
  }

  return (
    <Form.Item
      name={[...namePrepend, 'rightValue']}
      rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
      className={Styles['form-item']}
    >
      {inputField}
    </Form.Item>
  )
}

export default Condition
