import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'
import DataTypeIcon from 'components/common/DataTypeIcon'
import DatePicker from 'components/common/DatePicker'
import IconHeaderFlag from 'components/common/IconHeaderFlag'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Tooltip from 'components_new/atoms/Tooltip'

import Filters from './Filters'

import * as Constants from 'helpers/Constants'

import {
  getDuplicateColumnValidation,
  getDateOptions,
  DATE_UNITS,
  TODAY
} from './conf'

import { OPERATION_TYPES } from '../conf'

import Styles from './styles.module.css'

const TimeDifferenceOperation = ({ chosenAttributes, formRef, type }) => {
  const [columnState, setNewColumnState] = useState({ from: true, to: true })
  const [stateChosenAttributes, setStateChosenAttributes] = useState(
    _.cloneDeep(chosenAttributes)
  )
  const newAttributeName = formRef.getFieldValue('newAttribute')

  useEffect(() => {
    if (newAttributeName in stateChosenAttributes) {
      const copyState = _.cloneDeep(stateChosenAttributes)

      delete copyState[newAttributeName]
      setStateChosenAttributes(copyState)
    }

    const fields = formRef.getFieldsValue()

    // check for relative or absolute date
    // valueType is needed because form for value will not be
    // rendered if valueType = __TODAY__
    const renderDateBox = fields.value || fields.valueType ? false : true

    setNewColumnState({
      from: !!fields.leftAttribute || renderDateBox,
      to: !!fields.rightAttribute || renderDateBox
    })
  }, [stateChosenAttributes])

  const options = getDateOptions(stateChosenAttributes)

  return (
    <>
      <Form layout="vertical" form={formRef}>
        <IconHeaderFlag
          icon={OPERATION_TYPES[type].icon}
          header={OPERATION_TYPES[type].label}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <Form.Item name={'newAttributeInit'} noStyle />
          <Form.Item
            name={'newAttribute'}
            rules={[
              Constants.FORM_VALIDATIONS.REQUIRED,
              getDuplicateColumnValidation(stateChosenAttributes)
            ]}
            className={Styles['form-item']}
          >
            <Input
              prefix={<DataTypeIcon type={'BIGINT'} />}
              suffix={
                // eslint-disable-next-line max-len
                <Tooltip title="Resultatet av din tidsperiod kommer att lagras i en ny kolumn. Döp denna kolumn till ett lämpligt namn.">
                  <Icon name="HelpOutline" fontSize="small" />
                </Tooltip>
              }
              label={'Kolumn'}
            />
          </Form.Item>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {columnState.to && columnState.from ? (
            /* Empty value form if value state is not open. */
            <Form.Item noStyle name={'value'} />
          ) : null}

          <DateSection
            label={'Från'}
            column={columnState.from}
            itemKey={'from'}
            setNewColumnState={setNewColumnState}
            options={options}
            formRef={formRef}
          />
          <DateSection
            label={'Till'}
            itemKey={'to'}
            column={columnState.to}
            setNewColumnState={setNewColumnState}
            options={options}
            formRef={formRef}
          />

          <Form.Item
            name={'unit'}
            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
            className={Styles['form-item']}
          >
            <Select label={'Tidsenhet'} options={DATE_UNITS} sorted={false} />
          </Form.Item>
          <small className="secondary">
            <b>Tänk på!</b> Alla beräkningar med tomma värden (NULL) kommer
            resultera i ett tomt värde (NULL).
          </small>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Filters chosenAttributes={stateChosenAttributes} formRef={formRef} />
      </Form>
    </>
  )
}

const DateSection = ({
  label,
  column,
  setNewColumnState,
  itemKey,
  options,
  formRef
}) => {
  const otherKey = itemKey === 'from' ? 'to' : 'from'
  const formKey = itemKey === 'from' ? 'leftAttribute' : 'rightAttribute'

  return (
    <Paper
      sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
      variant="outlined"
    >
      {column ? (
        <Form.Item
          name={formKey}
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          className={Styles['form-item']}
        >
          <Select
            label={label}
            placeholder={label}
            options={options}
            labelBackground
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            name={'valueType'}
            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
            className={Styles['form-item']}
            getValueFromEvent={(valueType) => {
              const values = formRef.getFieldsValue()

              values.value = null
              formRef.setFieldsValue(values)

              return valueType
            }}
          >
            <Select
              label={label}
              placeholder={label}
              options={[
                { value: TODAY, label: 'Idag' },
                { value: 'INPUT_VALUE', label: 'Specifikt datum' }
              ]}
              labelBackground
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {(form) => {
              const valueType = form.getFieldValue('valueType')

              if (valueType === 'INPUT_VALUE') {
                return (
                  <Form.Item
                    name={'value'}
                    rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                    className={Styles['form-item']}
                  >
                    <DatePicker label={'Datum'} labelBackground />
                  </Form.Item>
                )
              }

              return null
            }}
          </Form.Item>
        </>
      )}

      <ToggleButton
        items={[
          { title: 'Kolumn', value: true },
          { title: 'Datum', value: false }
        ]}
        onChange={(event, value) => {
          const values = formRef.getFieldsValue()

          if (!value) {
            // specific date, unset previous column value
            if (itemKey === 'from') {
              values.leftAttribute = null
            } else {
              values.rightAttribute = null
            }
          }
          // change state, unset value
          values.value = null
          values.valueType = null
          formRef.setFieldsValue(values)
          setNewColumnState((currentState) => ({
            [itemKey]: value,
            [otherKey]:
              !currentState[otherKey] && !value ? true : currentState[otherKey]
          }))
        }}
        size="small"
        value={column}
      />
    </Paper>
  )
}

export default TimeDifferenceOperation
