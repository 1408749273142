import React, { ReactNode, useCallback, useRef } from 'react'

import { TextVariantType } from 'components_new/atoms/Text'
import { useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import { useResizeDetector } from 'react-resize-detector'

interface FitTextProps {
  align?: 'left' | 'center' | 'right'
  children: ReactNode
  color?: string
  sx?: object
  variant: TextVariantType
}

/**
 * The FitText component automatically resizes
 * the textual content to fit both the parent's
 * width and its height without overflowing.
 */

const FitText = (props: FitTextProps) => {
  const parentRef = useRef<HTMLElement | null>(null)
  const childRef = useRef<HTMLElement | null>(null)

  const isOverflown = (parent: HTMLElement) => {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = parent

    return scrollWidth > clientWidth || scrollHeight > clientHeight
  }

  const resizeText = (
    child: HTMLElement | null,
    parent: HTMLElement | null,
    minSize: number,
    maxSize: number,
    stepSize: number,
    unit: 'px' | 'em'
  ) => {
    let fontSize = minSize
    let overflow = false

    if (child && parent) {
      while (!overflow && fontSize < maxSize) {
        child.style.fontSize = `${fontSize}${unit}`
        overflow = isOverflown(parent)
        if (!overflow) fontSize += stepSize
      }

      // Revert to last state where no overflow happened:
      child.style.fontSize = `${fontSize - stepSize}${unit}`
    }
  }

  const onResize = useCallback(() => {
    resizeText(childRef.current, parentRef.current, 12, 512, 1, 'px')
  }, [])

  const { ref } = useResizeDetector({ onResize })

  const theme = useTheme()
  const font = theme.typography[props.variant]

  return (
    <Box
      ref={(el: HTMLDivElement) => {
        parentRef.current = el
        ref.current = el
      }}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        color: props.color,
        ...props.sx
      }}
    >
      <Box
        ref={childRef}
        component="span"
        sx={{
          fontSize: '12px',
          display: 'inline-block',
          height: 'fit-content',
          whiteSpace: 'nowrap',
          fontWeight: font.fontWeight,
          textAlign: props.align ?? 'left',
          lineHeight: 'inherit',
          alignSelf: 'center'
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default FitText
