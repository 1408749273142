import React from 'react'
import Axios from 'axios'
import { isMoment } from 'moment'

import DataTypeIcon from 'components/common/DataTypeIcon'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import {
  STANDARD_VALUES,
  STANDARD_VALUE
} from 'components/containers/Applications/DataProducts/ManageDatasetModal/Operations/ManageOperation/conf'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { parseNewAttributeType } from './Operations/conf'

import Styles from './styles.module.css'

export const TYPES = {
  API: 'API',
  INSIGHT_STUDIO: 'INSIGHT_STUDIO'
}

export const INITIAL_PREVIEW = {
  data: [],
  meta: { count: 0, sort: undefined }
}
export const PAGE_LIMIT = 10
const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/v3/tables/'
    : `${process.env.REACT_APP_HOMEPAL_MDM_API_PROD}v3/tables/`

/**
 * Fetch relatied table ids to id and returns a tree with
 * data from Ontology
 *
 * @param {string} id - Main table id, picked in select
 * @param {object} ontologyTables - Object with all the tables in the Ontology
 * @param {object} data - An object with an array of objects with information
 * how the remaining table are related to the main table
 * @returns An array of tree data passed on to the Tree-component
 */
export const getAttributeRows = (id, ontologyTables, data) => {
  if (!ontologyTables || !id || !data) {
    return []
  }

  const treeData = parseTreeData(id, ontologyTables, data)

  return treeData.map((tree) => ({
    title: (
      <Box sx={{ display: 'flex' }}>
        <p className={Styles['tree-attribute-title']}>{tree.title}</p>
        <p className="secondary">{tree.children.length}</p>
      </Box>
    ),
    key: tree.key,
    children: tree.children.map((leaf) => ({
      title: (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            whiteSpace: 'nowrap'
          }}
        >
          <DataTypeIcon type={leaf.type} />
          <Text variant="body1">{leaf.title}</Text>
        </Box>
      ),
      key: leaf.key
    }))
  }))
}

/**
 * Parse data from table and return it in a way that the Tree component
 * can interpret
 *
 * @param {string} id - Main table id, picked in select
 * @param {object} ontologyTables - Object with all the tables in the Ontology
 * @param {object} data - An object with an array of objects with information
 * how the remaining table are related to the main table
 * @returns An array of objects in a tree-like structure
 */
export const parseTreeData = (id, ontologyTables, data) => {
  const tableIds = [id, ...parseRelatedTableIds(data)]

  return tableIds
    .filter((tableId) => ontologyTables[tableId])
    .map((tableId) => ({
      title: ontologyTables[tableId].attributes.name,
      key: tableId,
      children: ontologyTables[tableId].attributes.output_attributes
        .map((oa) => ({
          title: oa.attributes.name,
          type: oa.attributes.type,
          key: oa.id
        }))
        .sort((a, b) => (a.title < b.title ? -1 : 1))
    }))
    .sort((a, b) => (a.title < b.title ? -1 : 1))
}

/**
 * Fetch all the related table ids from the API response
 *
 * @param {array<object>} data - An array of objects with information
 * how the remaining table are related to the main table
 * @returns An array of unique table ids
 */
const parseRelatedTableIds = (data) => {
  // Fetch related tableIds
  const relatedTableIds = Array.from(
    new Set(data.map((d) => getTableIds(d)).flat())
  )

  return relatedTableIds
}

/**
 * Recursively parses the object to get all table ids
 *
 * @param {object} relatedData - Object containing related table data
 * @returns A list of table ids
 */
const getTableIds = (relatedData) => {
  if (relatedData.relations.length === 0) {
    return [relatedData.id]
  }

  return [
    ...relatedData.relations.map((r) => getTableIds(r)),
    relatedData.id
  ].flat()
}

export const getOntologyMapping = (tables) => {
  const mapping = {}

  tables.forEach((table) => {
    table.attributes.output_attributes.forEach((attribute) => {
      mapping[attribute.id] = { name: attribute.attributes.name, keep: false }
    })
  })

  return mapping
}

/**
 * Maps up all tables to their respectively name.
 *
 * @param {array} tables - All ontology tables.
 * @param {object} editTable - The table we are editing.
 * @returns A mapper {[id]: {keep: bool, name: string}}
 */
const getInitialMapping = (tables, editTable, transformation) => {
  const mapping = getOntologyMapping(tables)

  transformation.output_attributes.map((mappedAttribute) => {
    const attribute = editTable.attributes.output_attributes.find(
      (oa) => oa.id === mappedAttribute.output_attribute_id
    )

    const keep = !!attribute

    // If the origin table from the attribute is the same as
    // the table we are editing, then it is a newly created attribute.
    const isNewAttribute = mappedAttribute.origin_table_id === editTable.id

    if (attribute || isNewAttribute) {
      const mappingObj = {
        originName: mappedAttribute.origin_output_attribute_name,
        name:
          attribute?.attributes?.name ||
          mappedAttribute.origin_output_attribute_name,
        type: attribute?.attributes?.type,
        keep,
        originalId: mappedAttribute.output_attribute_id,
        isNewAttribute
      }

      if (isNewAttribute) {
        mapping[mappedAttribute.origin_output_attribute_name] = mappingObj
      }

      mapping[mappedAttribute.origin_attribute_id] = mappingObj
    }
  })

  return mapping
}

export const getInitialValues = (
  match,
  AppsDataProductStore,
  AppsDatasetStore,
  ontologyTables
) => {
  const tableId = match.params.tableId
  const editTable = tableId
    ? AppsDataProductStore.data[match.params.id].attributes.tables.find(
      (item) => item.id === tableId
    )
    : null

  const transformation = tableId
    ? AppsDatasetStore.transformations[tableId]
    : null

  const outputAttributeCounter = {}

  ontologyTables.forEach(
    (item) =>
      (outputAttributeCounter[item.id] =
        item.attributes.output_attributes.length)
  )

  if (editTable && transformation) {
    const mapping = getInitialMapping(ontologyTables, editTable, transformation)

    return {
      ...transformation,
      operations: transformation.operations.map((op) => {
        return {
          type: op.type,
          newAttribute: op.new_attribute,
          newAttributeId: op.new_attribute_id,
          leftAttribute: mapping[op.left_attribute]?.isNewAttribute
            ? mapping[op.left_attribute]?.originName
            : op.left_attribute,
          rightAttribute: mapping[op.right_attribute]?.isNewAttribute
            ? mapping[op.right_attribute]?.originName
            : op.right_attribute,
          unit: op.unit,
          value: op.value,
          attributes: op.conditions
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .map((item) => {
              const isStandardValue = item.type === STANDARD_VALUE

              return {
                outputAttributeId: mapping[item.left_attribute].isNewAttribute
                  ? mapping[item.left_attribute].originName
                  : item.left_attribute,
                condition: {
                  conjunctiveOperator: item.conjunctive_operator,
                  rightAttribute: item.right_attribute,
                  rightValue: item.right_value,
                  condition: item.condition,
                  index: item.index,
                  type: isStandardValue ? item.right_value : item.type
                }
              }
            })
        }
      }),
      checkedKeys: transformation.tables
        .map((item) => {
          if (
            outputAttributeCounter[item.table_id] ===
            item.output_attributes.length
          ) {
            return [item.table_id, ...item.output_attributes.map((a) => a.id)]
          }

          return item.output_attributes.map((a) => a.id)
        })
        .flat(),
      mapping
    }
  }

  return null
}

export const getCurrentIndices = (initialValues, match) => {
  const currentIndices = {}

  initialValues.output_attributes.forEach((attr) => {
    if (
      attr.index !== null &&
      !Object.values(currentIndices).includes(attr.index)
    ) {
      if (attr.origin_table_id === match.params.tableId) {
        currentIndices[attr.origin_output_attribute_name] = attr.index
      } else {
        currentIndices[attr.origin_attribute_id] = attr.index
      }
    }
  })

  return currentIndices
}

export const getAttributes = (getAll, checkedKeys, attributes, operations) => {
  const obj = {}

  checkedKeys.forEach((key) =>
    key in attributes ? (obj[key] = attributes[key]) : undefined
  )

  if (getAll) {
    operations.forEach((op) =>
      op.newAttribute
        ? (obj[op.newAttribute] = {
            outputAttributeId: op.newAttribute,
            outputAttributeName: op.newAttribute,
            tableName: '',
            type: parseNewAttributeType(op.type, attributes?.[op.leftAttribute])
          })
        : undefined
    )
  }

  return obj
}

export const updateIndices = (chosenAttributes, indices, setIndices) => {
  if (Object.keys(chosenAttributes).length === 0) {
    return
  }

  const newIndices = { ...indices }

  // If an attribute is removed we decrease index of all above with one.
  Object.keys(newIndices).forEach((outputAttributeId) => {
    if (!(outputAttributeId in chosenAttributes)) {
      const index = newIndices[outputAttributeId]

      Object.keys(newIndices).forEach((outputAttributeId2) => {
        if (
          outputAttributeId !== outputAttributeId2 &&
          newIndices[outputAttributeId2] > index
        ) {
          newIndices[outputAttributeId2] = newIndices[outputAttributeId2] - 1
        }
      })
      delete newIndices[outputAttributeId]
    }
  })

  const indicesArr = Object.values(newIndices)
  let maxIndex = indicesArr.length > 0 ? Math.max(...indicesArr) : -1

  Object.values(chosenAttributes).forEach((attribute) => {
    if (!(attribute.outputAttributeId in newIndices)) {
      newIndices[attribute.outputAttributeId] = maxIndex + 1
      maxIndex = maxIndex + 1
    }
  })

  setIndices(newIndices)
}

const updateIndex = (outputAttributeId, indices, setIndices, direction) => {
  const newIndices = { ...indices }
  const currentIndex = newIndices[outputAttributeId]
  const newIndex = currentIndex + direction

  Object.keys(indices).forEach((key) => {
    if (indices[key] === newIndex) {
      newIndices[key] = newIndices[key] - direction
    }
  })

  newIndices[outputAttributeId] = newIndex

  setIndices(newIndices)
}

export const getHeaders = (
  chosenAttributes,
  mapping,
  currentStep,
  indices,
  setIndices
) => {
  const max = Math.max(...Object.values(indices))

  return Object.values(chosenAttributes)
    .sort((a, b) =>
      indices[a.outputAttributeId] < indices[b.outputAttributeId] ? -1 : 1
    )
    .filter(
      (attribute) =>
        mapping[attribute.outputAttributeId]?.keep || currentStep < 2
    )
    .map((attribute) => ({
      label: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            position: 'relative'
          }}
        >
          <DataTypeIcon type={attribute.type} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <small className={'secondary tiny'}>{attribute.tableName}</small>
            <small>{mapping[attribute.outputAttributeId].name}</small>
          </Box>
          <Box
            sx={{
              ml: 1,
              mr: 0.5,
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: '18px'
            }}
          >
            <IconButton
              disabled={indices[attribute.outputAttributeId] === 0}
              onClick={(e) => {
                e.stopPropagation()

                if (indices[attribute.outputAttributeId] > 0) {
                  updateIndex(
                    attribute.outputAttributeId,
                    indices,
                    setIndices,
                    -1
                  )
                }
              }}
              size="small"
            >
              <Icon name="ChevronLeft" />
            </IconButton>
            <IconButton
              disabled={indices[attribute.outputAttributeId] === max}
              onClick={(e) => {
                e.stopPropagation()

                if (indices[attribute.outputAttributeId] < max) {
                  updateIndex(
                    attribute.outputAttributeId,
                    indices,
                    setIndices,
                    1
                  )
                }
              }}
              size="small"
            >
              <Icon name="ChevronRight" />
            </IconButton>
          </Box>
        </Box>
      ),
      key: attribute.outputAttributeId,
      sorter: true,
      render: function renderItem(value) {
        if (attribute.type === 'BOOLEAN') {
          return value ? 'Sant' : 'Falskt'
        }

        return value
      }
    }))
}
export const updatePreview = (page, sort, rawData, setPreview, setLoading) => {
  setLoading(true)
  const offset = PAGE_LIMIT * (page - 1)
  let sortQuery = ''

  if (sort) {
    sortQuery = `&sort=${sort}`
  }

  Axios.post(
    `${BASE_URL}preview?offset=${offset}&limit=${PAGE_LIMIT}${sortQuery}`,
    {
      data: {
        attributes: getShapeData(rawData)
      }
    },
    parseAuthenticationHeader()
  )
    .then((response) => {
      setPreview(response.data)
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
    })
}

export const getShapeData = ({
  attributes,
  checkedKeys,
  nameForm,
  match,
  mainTable,
  operations,
  mapping,
  indices
}) => {
  // parse table object
  const tablesObject = {}

  checkedKeys
    // ignore checked tables
    .filter((key) => attributes[key])
    .forEach((key) => {
      const attribute = attributes[key]

      const output_attribute = {
        name: mapping[key].name,
        id: key,
        keep: mapping[key].keep,
        original_id: mapping[key].originalId,
        index: indices[key]
      }

      if (tablesObject[attribute.tableId]) {
        tablesObject[attribute.tableId].output_attributes.push(output_attribute)
      } else {
        tablesObject[attribute.tableId] = {
          id: attribute.tableId,
          output_attributes: [output_attribute]
        }
      }
    })

  operations.forEach((op) => {
    if (op.newAttribute) {
      tablesObject[Object.keys(tablesObject)[0]].output_attributes.push({
        name: mapping[op.newAttribute].name,
        id: op.newAttribute,
        keep: mapping[op.newAttribute].keep,
        original_id: mapping[op.newAttribute]?.originalId,
        index: indices[op.newAttribute]
      })
    }
  })

  return {
    data_product_id: match.params.id,
    name: nameForm?.getFieldValue('name'),
    main_table_id: mainTable,
    tables: Object.values(tablesObject),
    operations: operations.map((operation) => {
      return {
        type: operation.type,
        new_attribute: operation.newAttribute,
        left_attribute: operation.leftAttribute,
        right_attribute: operation.rightAttribute,
        unit: operation.unit,
        value: operation.value,
        value:
          operation.type === 'CALCULATE'
            ? parseOperationCalculateValue(operation.value, operations)
            : operation.value,
        conditions: operation.attributes.map(
          ({ outputAttributeId, condition }) => {
            // check for date type
            let rightValue = isMoment(condition.rightValue)
              ? condition.rightValue.format('YYYY-MM-DD')
              : condition.rightValue

            // standard values are stored in type
            if (STANDARD_VALUES.includes(condition.type)) {
              rightValue = condition.type
            }

            return {
              left_attribute: outputAttributeId,
              // STANDARD VALUEs are currently stored in type (filter form)
              condition: condition.condition,
              right_attribute: condition.rightAttribute,
              right_value: rightValue,
              // STANDARD VALUES are currently stored in type (filter form)
              type: STANDARD_VALUES.includes(condition.type)
                ? STANDARD_VALUE
                : condition.type,
              conjunctive_operator: condition.conjunctiveOperator
            }
          }
        )
      }
    })
  }
}

const UUID_REGEX = /(@).*?(?=\+|_-_|\*|\/|\(|\)|$)/g

// Replaces the name of the new attributes, if the value is saved in our db
const parseOperationCalculateValue = (value, operations) => {
  const newAttributeIds = operations.map((op) => op.newAttributeId)

  return value.replace(UUID_REGEX, (match) => {
    const m = match.replace('@', '')

    if (newAttributeIds.includes(m)) {
      const op = operations.find((operation) => operation.newAttributeId === m)

      return `@${op.newAttribute}`
    }

    return match
  })
}
