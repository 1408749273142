import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={24}
        width={'100%'}
        sx={{ mb: 1 }}
      />
      <Skeleton variant="rectangular" height={24} width={'100%'} />
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
