import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  sourceSchemas: {
    collect: [],
    build: [],
    shape: []
  },
  fetchingSources: false,
  sourcesFetched: false,
  working: false,
  error: null,
  fetched: false,
  fetching: false,
  currentFetchOne: null
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_ALL_DATASETS:
    return {
      ...state,
      fetching: true
    }

  case Types.GET_ALL_DATASETS_SUCCESS:
    return {
      ...state,
      data: {
        ...Functions.arrayToObject(payload),
        ...state.data
      },
      fetched: true,
      fetching: false
    }

  case Types.GET_ALL_DATASETS_FAILED:
    return {
      ...state,
      fetched: false,
      fetching: false
    }

  case Types.GET_ONE_DATASET:
    return {
      ...state,
      fetchingOne: true
    }

  case Types.GET_ONE_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      },
      currentFetchOne: payload.id,
      fetchingOne: false,
      fetched: true
    }

  case Types.GET_ONE_DATASET_FAILED:
    return {
      ...state,
      fetchingOne: false
    }

  case Types.CREATE_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.UPDATE_DATASET_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_DATASET_SUCCESS:
    delete state.data[payload]

    return {
      ...state,
      data: {
        ...state.data
      }
    }

  case Types.PUT_DATASET_SCHEMA_SUCCESS:
    // TODO: Här ska något annat göras
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: {
          ...state.data[payload.id],
          attributes: payload.attributes,
          included: payload.included
        }
      }
    }

  case Types.GET_ALL_SOURCE_SCHEMAS:
    return {
      ...state,
      sourcesFetched: false,
      fetchingSources: true
    }

  case Types.GET_ALL_SOURCE_SCHEMAS_SUCCESS:
    return {
      ...state,
      fetchingSources: false,
      sourceSchemas: {
        build: Functions.arrayToObject(payload.build, 'value'),
        shape: Functions.arrayToObject(payload.shape, 'value'),
        collect: Functions.arrayToObject(
          payload.collect.map((item) => ({
            ...item,
            tables: Functions.arrayToObject(item.tables, 'value')
          })),
          'value'
        )
      },
      sourcesFetched: true
    }

  case Types.GET_ALL_SOURCE_SCHEMAS_SUCCESS:
    return {
      ...state,
      fetchingSources: false
    }

  case Types.CREATE_OUTPUT_ATTRIBUTE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.UPDATE_OUTPUT_ATTRIBUTE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_OUTPUT_ATTRIBUTE:
    return {
      ...state,
      working: true,
      error: null
    }

  case Types.DELETE_OUTPUT_ATTRIBUTE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      },
      working: false
    }

  case Types.DELETE_OUTPUT_ATTRIBUTE_FAILED:
    return {
      ...state,
      working: false,
      error: payload
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
