import React, { useRef } from 'react'

import { Form, Row, Col } from 'antd'

import Modal from 'components/common/Modal'
import Button from 'components_new/atoms/Button'
import Input from 'components/common/Input'
import Select from 'components/common/Select'

import * as Constants from 'helpers/Constants'

const EditAuthModal = ({ open, closeModal, onUpdate, source }) => {
  const formRef = useRef(null)
  const initialValues =
    source.included.auth_params.length === 1
      ? { ...source.included.auth_params[0].attributes, value: undefined }
      : {}

  return (
    <Modal
      visible={open}
      onClose={() => closeModal()}
      header={'Uppdatera autentisering'}
    >
      <Form
        layout={'vertical'}
        ref={formRef}
        onFinish={(formAttr) => {
          onUpdate({ auth_params: [formAttr] })
          formRef.current.resetFields()
        }}
        initialValues={initialValues}
      >
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'location'}
          label={'Plats'}
        >
          <Select
            options={[
              { label: 'Query', value: 'query' },
              { label: 'Headers', value: 'headers' }
            ]}
          />
        </Form.Item>
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'field'}
          label={'Fält'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'value'}
          label={'Nyckel'}
        >
          <Input />
        </Form.Item>
        <Row gutter={8} align={'end'}>
          <Col>
            <Button onClick={() => closeModal()} variant="text">
              Avbryt
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="submit">Spara</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditAuthModal
