import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import OptionsMenu from 'components_new/molecules/OptionsMenu'

import { getOptions, operators, translateOperator } from './utils'

import {
  KpiVariable,
  KpiVariableWithChildren
} from 'redux/reducers/KpiVariables'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock,
  KpiTemplateCalculationBlockType,
  KpiTemplateCalculationOperation
} from 'types/GlobalKpiTemplates'

interface KpiFormulaBlockProps {
  block: KpiTemplateCalculationBlock
  deleteBlock: (id: string, side: 'left' | 'right') => void
  formattedVariables: KpiVariableWithChildren[]
  editValue: (
    id: string,
    key: string,
    value: KpiTemplate | KpiVariable | KpiTemplateCalculationOperation | string
  ) => void
  editType: (
    id: string,
    side: 'left' | 'right',
    value: KpiTemplateCalculationBlockType
  ) => void
  variables: KpiVariable[]
  templates: KpiTemplate[]
}

const parseBlock = (
  block: KpiTemplateCalculationBlock,
  formattedVariables: KpiVariableWithChildren[],
  variables: KpiVariable[],
  templates: KpiTemplate[],
  editType: (
    id: string,
    side: 'left' | 'right',
    value: KpiTemplateCalculationBlockType
  ) => void,
  editValue: (
    id: string,
    key: string,
    value: KpiTemplate | KpiVariable | string
  ) => void,
  deleteBlock: (id: string, side: 'left' | 'right') => void,
  parentBlockId?: string,
  parentSide?: 'left' | 'right'
) => {
  const left = getSide(
    block,
    formattedVariables,
    variables,
    templates,
    'left',
    editType,
    editValue,
    deleteBlock
  )
  const right = getSide(
    block,
    formattedVariables,
    variables,
    templates,
    'right',
    editType,
    editValue,
    deleteBlock
  )

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        p: 1,
        borderRadius: 1,
        bgcolor: 'action.hover',
        flex: 1
      }}
    >
      <Box sx={{ flex: 1 }}>{left}</Box>
      <Box sx={{ width: 38 }}>
        <TextField
          SelectProps={{
            IconComponent: () => null
          }}
          select={true}
          size="small"
          onChange={(event) => {
            editValue(
              block.id,
              'operation',
              event.target.value as KpiTemplateCalculationOperation
            )
          }}
          value={block.operation}
          sx={{ my: 0 }}
        >
          {operators.map((o) => (
            <MenuItem key={o} value={o}>
              {translateOperator[o]}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={{ flex: 1 }}>{right}</Box>
      {parentBlockId && parentSide ? (
        <Box sx={{ position: 'absolute', top: -12, right: -12 }}>
          <IconButton
            size="small"
            onClick={() => deleteBlock(parentBlockId, parentSide)}
          >
            <Icon color="error" fontSize="small" name="RemoveCircleOutlined" />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  )
}

const getSide = (
  block: KpiTemplateCalculationBlock,
  formattedVariables: KpiVariableWithChildren[],
  variables: KpiVariable[],
  templates: KpiTemplate[],
  side: 'left' | 'right',
  editType: (
    id: string,
    side: 'left' | 'right',
    value: KpiTemplateCalculationBlockType
  ) => void,
  editValue: (
    id: string,
    key: string,
    value: KpiTemplate | KpiVariable | string
  ) => void,
  deleteBlock: (id: string, side: 'left' | 'right') => void
) => {
  if (block[`${side}_type`] === KpiTemplateCalculationBlockType.NUMBER) {
    const number = block[`${side}_value`]

    return (
      <TextField
        type="number"
        InputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: (
            <InputAdornment position="end">
              <OptionsMenu
                size="small"
                options={getOptions((value: KpiTemplateCalculationBlockType) =>
                  editType(block.id, side, value)
                )}
                tooltip="Klicka för att ändra typ"
                variant="icon"
              />
            </InputAdornment>
          )
        }}
        fullWidth={true}
        placeholder="0"
        size="small"
        onChange={(event) => {
          editValue(block.id, `${side}_value`, event.target.value)
        }}
        value={number ?? ''}
        sx={{ my: 0, minWidth: 128 }}
      />
    )
  }

  if (block[`${side}_type`] === KpiTemplateCalculationBlockType.VARIABLE) {
    const variable = block[`${side}_kpi_variable`] as KpiVariable

    return (
      <TextField
        fullWidth={true}
        label={!variable ? 'Välj kontonivå...' : undefined}
        SelectProps={{
          IconComponent: () => (
            <OptionsMenu
              size="small"
              options={getOptions((value: KpiTemplateCalculationBlockType) =>
                editType(block.id, side, value)
              )}
              tooltip="Klicka för att ändra typ"
              variant="icon"
            />
          )
        }}
        select={true}
        size="small"
        onChange={(event) => {
          editValue(
            block.id,
            `${side}_kpi_variable`,
            variables.find((v) => v.id === event.target.value) as KpiVariable
          )
        }}
        value={variable ? variable.id : ''}
        sx={{ my: 0 }}
      >
        {formattedVariables.map((v0) => {
          const item = (
            <MenuItem key={v0.id} value={v0.id}>
              {v0.name}
            </MenuItem>
          )

          const children = v0.children.map((v1) => {
            const item2 = (
              <MenuItem key={v1.id} value={v1.id} sx={{ marginLeft: 2 }}>
                {v1.name}
              </MenuItem>
            )

            const children2 = v1.children.map((v2) => (
              <MenuItem key={v2.id} value={v2.id} sx={{ marginLeft: 4 }}>
                {v2.name}
              </MenuItem>
            ))

            return [item2, ...children2]
          })

          return [item, ...children]
        })}
      </TextField>
    )
  }

  if (block[`${side}_type`] === KpiTemplateCalculationBlockType.TEMPLATE) {
    const template = block[`${side}_kpi_template`] as KpiTemplate

    return (
      <TextField
        fullWidth={true}
        label={!template ? 'Välj nyckeltal...' : undefined}
        SelectProps={{
          IconComponent: () => (
            <OptionsMenu
              size="small"
              options={getOptions((value: KpiTemplateCalculationBlockType) =>
                editType(block.id, side, value)
              )}
              tooltip="Klicka för att ändra typ"
              variant="icon"
            />
          )
        }}
        select={true}
        size="small"
        onChange={(event) => {
          editValue(
            block.id,
            `${side}_kpi_template`,
            templates.find((t) => t.id === event.target.value) as KpiTemplate
          )
        }}
        value={template ? template.id : ''}
        sx={{ my: 0 }}
      >
        {templates.map((template) => (
          <MenuItem key={template.id} value={template.id}>
            {template.title}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  if (block[`${side}_type`] === KpiTemplateCalculationBlockType.BLOCK) {
    const nestledBlock = block[
      `${side}_kpi_calculation_block`
    ] as KpiTemplateCalculationBlock

    return parseBlock(
      nestledBlock,
      formattedVariables,
      variables,
      templates,
      editType,
      editValue,
      deleteBlock,
      block.id,
      side
    )
  }

  return (
    <OptionsMenu
      options={getOptions((value: KpiTemplateCalculationBlockType) =>
        editType(block.id, side, value)
      )}
      tooltip="Lägg till"
      variant="buttonField"
      sx={{
        width: '100%'
      }}
    />
  )
}

const KpiFormulaBlock = (props: KpiFormulaBlockProps) => {
  const {
    block,
    formattedVariables,
    editType,
    editValue,
    deleteBlock,
    variables,
    templates
  } = props

  return parseBlock(
    block,
    formattedVariables,
    variables,
    templates,
    editType,
    editValue,
    deleteBlock
  )
}

export default KpiFormulaBlock
