import React from 'react'
import ShortId from 'shortid'

import BlockDeleteContainer from 'components/common/BlockDeleteContainer'

import Data from 'components/containers/DataPlatform/collect/Specific/Data'
import Import from 'components/containers/DataPlatform/collect/Specific/Import'
import Aggregation from 'components/containers/DataPlatform/collect/Specific/Aggregation'
import Classification from 'components/containers/DataPlatform/collect/Specific/Classification'
import History from 'components/containers/DataPlatform/collect/Specific/History'
import Logs from 'components/containers/DataPlatform/collect/Specific/Logs'
import Table from 'components/containers/DataPlatform/collect/Specific/Table'
import Upload from 'components/containers/DataPlatform/collect/Specific/Upload'
import VitecViews from 'components/containers/DataPlatform/collect/Specific/VitecViews'

import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'

export const DROP_DOWN_ACTIONS = (
  setEditName,
  setAlert,
  resetAlert,
  connectedSystems,
  tryDeleteActiveSource,
  activeSource,
  tryActivateActiveSource,
  tryScanActiveSource,
  history,
  source,
  match,
  setEditAuth,
  setEditEndpoints,
  isFile,
  tryUpdateActiveSource
) => [
  {
    header: 'information',
    key: 'dropdown-actions-0',
    items: [
      {
        onClick: () => setEditName(true),
        label: 'Redigera namn'
      },
      ...(source.attributes.type === 'HIDDEN'
        ? [
            {
              onClick: () => setEditAuth(true),
              label: 'Redigera autentisering'
            },
            {
              onClick: () => setEditEndpoints(true),
              label: 'Redigera endpoints'
            }
          ]
        : [])
    ].flat()
  },
  {
    header: 'datakälla',
    key: 'dropdown-actions-1',
    items: [
      {
        onClick: () =>
          tryUpdateActiveSource(match.params.id, {
            data: { attributes: { multiple_files: true } }
          }),
        label: 'Aktivera stöd för flertalet filer',
        disabled: !!activeSource.attributes.multiple_files || !isFile
      },
      {
        onClick: () => tryActivateActiveSource(activeSource.id),
        label: 'Aktivera datakälla',
        disabled: !!activeSource.attributes.technical_name
      },
      {
        onClick: () => tryScanActiveSource(activeSource.id),
        label: 'Skanna schema',
        disabled: source.attributes.type !== 'API'
      },
      {
        onClick: () => console.log('pause'),
        label: 'Pausa datakälla',
        disabled: true
      },
      {
        onClick: () => {
          if (connectedSystems.length !== 0) {
            setAlert({
              isOpen: true,
              header: 'Datakällan används',
              okText: 'OK',
              onOk: () => {
                resetAlert()
              },
              content: (
                <BlockDeleteContainer
                  deleteType={'Datakällan'}
                  dependencyType={'modellerna'}
                  names={connectedSystems.map((item) => item.attributes.name)}
                />
              )
            })
          } else {
            setAlert({
              isOpen: true,
              header: 'Ta bort datakälla',
              okText: 'Ta bort',
              cancelText: 'Avbryt',
              onOk: () => {
                tryDeleteActiveSource(source.id, match.params.id)
                resetAlert()
                history.push('/data-platform/collect/sources')
              },
              content:
                // eslint-disable-next-line max-len
                'Är du säker på att du vill ta bort datakällan? Detta kan resultera i att applikationer som är beroende av data slutar fungera.'
            })
          }
        },
        label: 'Ta bort datakälla',
        remove: true
      }
    ]
  }
]

export const PARSE_CONTENT = (
  currrentPage,
  activeTable,
  buttonClick,
  activeSource,
  source,
  editMode,
  setEditMode,
  resetValues,
  formMappingRef
) => {
  switch (currrentPage) {
  case 'data':
    return <Data activeTable={activeTable} source={source} />
  case 'import':
    return <Import activeTable={activeTable} />
  case 'classification':
    return (
      <Classification activeTable={activeTable} buttonClick={buttonClick} />
    )
  case 'aggregation':
    return <Aggregation activeTable={activeTable} />
  case 'views':
    return <VitecViews activeSourceId={activeSource.id} />
  case 'history':
    return <History activeSource={activeSource} />
  case 'logs':
    return <Logs activeTable={activeTable} />
  case 'table':
    return (
      <Table
        activeSource={activeSource}
        activeTable={activeTable}
        editMode={editMode}
        setEditMode={setEditMode}
        resetValues={resetValues}
        formRef={formMappingRef}
      />
    )
  case 'upload':
    return <Upload activeSource={activeSource} />
  default:
    return 'Empty'
  }
}

export const DESCRIPTION_HEADERS = {
  data: 'Tänk på!',
  classification: 'Information'
}

export const DESCRIPTIONS = {
  data: `Glöm inte att definiera och klassificera dina tabeller och
  attribut för att enklare hantera datan och dra nytta av alla
  funktioner i Homepals portal.`,
  classification: `Genom att definiera era tabeller och attribut med läsbara namn och beskrivningar underlättar ni hanteringen av er data i Homepals portal.

Primär nyckel krävs för att kunna hålla reda på tabellens rader i systemet. Nyckeln bör vara helt unik.

Referens krävs för varje tabell för att möjliggöra spårbarhet och revidering av data. Välj ett attribut som är unikt och läsbart.`
}

export const PAGE_HEADER_TOGGLE = (history, id, isStream, isFile, isVitec) => {
  const pathSplit = window.location.pathname.split('/')

  const search = history.location.search

  return [
    {
      label: 'Data',
      value: 'data',
      onClick: () => {
        history.push(
          `/data-platform/collect/${pathSplit[3]}/${id}/data${search}`
        )
      }
    },
    ...(!isFile && !isStream
      ? [
          {
            label: 'Import',
            value: 'import',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/import${search}`
              )
            }
          }
        ]
      : []),
    ...(isStream
      ? [
          {
            label: 'Tabell',
            value: 'table',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/table${search}`
              )
            }
          },
          {
            label: 'Aggregering',
            value: 'aggregation',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/aggregation${search}`
              )
            }
          }
        ]
      : []),
    ...(isFile
      ? [
          {
            label: 'Uppladdning',
            value: 'upload',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/upload${search}`
              )
            }
          }
        ]
      : []),
    ...(isVitec
      ? [
          {
            label: 'Vyer',
            value: 'views',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/views${search}`
              )
            }
          }
        ]
      : []),
    {
      label: 'Klassificering',
      value: 'classification',
      onClick: () => {
        history.push(
          `/data-platform/collect/${pathSplit[3]}/${id}/classification${search}`
        )
      }
    },
    ...(isStream
      ? [
          {
            label: 'Logg',
            value: 'logs',
            onClick: () => {
              history.push(
                `/data-platform/collect/${pathSplit[3]}/${id}/logs${search}`
              )
            }
          }
        ]
      : []),
    {
      label: 'Historik',
      value: 'history',
      onClick: () => {
        history.push(
          `/data-platform/collect/${pathSplit[3]}/${id}/history${search}`
        )
      }
    }
  ]
}
const Type = ({ type }) => {
  let text = 'System'
  let icon = <Icon name="StorageOutlined" />

  if (type === 'OnPremise') {
    icon = <Icon name="SettingsSuggestOutlined" />
    text = 'Anpassad'
  } else if (type === 'OnPremiseDirect') {
    icon = <Icon name="DnsOutlined" />
    text = 'Databas'
  } else if (type === 'File') {
    icon = <Icon name="InsertDriveFileOutlined" />
    text = 'Fil'
  } else if (type === 'Stream') {
    icon = <Icon name="RssFeed" />
    text = 'Ström'
  }

  return <Chip icon={icon} label={text} size="small" />
}

export const INFORMATION_HEADER_ITEMS = (source, activeSource, updatedBy) => [
  {
    header: 'typ',
    icon: <Type type={source.attributes.type} />,
    key: ShortId.generate()
  },
  {
    header: 'senaste körning',
    description: activeSource.attributes.latest_run_timestamp || '-',
    key: ShortId.generate()
  },
  {
    header: 'skapades',
    description: activeSource.attributes.created_at.split('T')[0],
    key: ShortId.generate()
  },
  {
    header: 'skapades av',
    description: updatedBy,
    key: ShortId.generate()
  }
]

export const getActiveStreamTable = (streamTables, id) => {
  return streamTables.find((st) => st.id === id)
}
