import * as Api from 'redux/api/DataPlatform/dataProducts/v2'
import * as ApiV3 from 'redux/api/Applications/DataProducts/v3'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryPutOntology(data, type) {
  const dispatchType =
    type === 'custom' ? 'PUT_CUSTOM_ONTOLOGY' : 'PUT_STANDARD_ONTOLOGY'

  return (dispatch) => {
    dispatch({
      type: Types[dispatchType]
    })

    Api.putOntology(data, type)
      .then((response) => {
        dispatch({
          type: Types[`${dispatchType}_SUCCESS`],
          payload: response.data.data
        })

        messageSuccess('Informationsmodellen har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types[`${dispatchType}_FAILED`]
        })

        messageWarning('Informationsmodellen kunde inte uppdateras')
      })
  }
}

export function tryGetOntology(type) {
  const dispatchType =
    type === 'custom' ? 'GET_CUSTOM_ONTOLOGY' : 'GET_STANDARD_ONTOLOGY'

  return (dispatch) => {
    dispatch({
      type: Types[dispatchType]
    })

    Api.getOntology(type)
      .then((response) => {
        dispatch({
          type: Types[`${dispatchType}_SUCCESS`],
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types[`${dispatchType}_FAILED`]
        })
      })
  }
}

export function tryStartOntology(id, type) {
  return (dispatch) => {
    dispatch({
      type: Types.START_ONTOLOGY
    })

    ApiV3.startJobRun(id)
      .then((response) => {
        dispatch({
          type: Types.START_ONTOLOGY_SUCCESS,
          payload: { data: response.data.data, ontologyType: type }
        })

        messageSuccess('En import har startats.')
      })
      .catch(() => {
        dispatch({
          type: Types.START_ONTOLOGY_FAILED
        })

        messageWarning('Det gick inte att starta en import.')
      })
  }
}

export function tryPollOntology(id, type) {
  return (dispatch) => {
    dispatch({
      type: Types.POLL_ONTOLOGY
    })

    ApiV3.pollJobRun(id)
      .then((response) => {
        dispatch({
          type: Types.POLL_ONTOLOGY_SUCCESS,
          payload: { data: response.data.data, ontologyType: type }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.POLL_ONTOLOGY_FAILED
        })
      })
  }
}
