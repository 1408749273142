import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'
import * as AlertActions from 'redux/actions/Alert'

import DropdownButton from 'components/common/DropdownButton'
import InformationHeader from 'components/common/InformationHeader'
import PageHeader from 'components/common/PageHeader'

import Paper from 'components_new/atoms/Paper'

import AddTag from 'components/containers/DataPlatform/AddTag'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Specific = ({
  match,
  CmdbTableStore,
  AuthStore,
  tryDeleteCmdbTable,
  setAlert,
  resetAlert,
  history
}) => {
  const buildRegister = CmdbTableStore.data[match.params.cmdbId].attributes
  const user = CmdbTableStore.data[match.params.cmdbId].included.user
  const shapes = CmdbTableStore.data[match.params.cmdbId].included.active_shapes

  const createdBy = user
    ? `${user.attributes.first_name ? user.attributes.first_name : ''} ${
      user.attributes.last_name ? user.attributes.last_name : ''
    }`
    : 'Homepal'

  const [activeToggle, setActiveToggle] = useState('data')

  useEffect(() => {
    const pathSplit = window.location.pathname.split('/')

    setActiveToggle(pathSplit[pathSplit.length - 1])
  }, [window.location.pathname])

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <PageHeader
          header={buildRegister.technical_name}
          customOnBack={() => history.push('/data-platform/build/registers')}
          toggle
          items={Conf.PAGE_HEADER_TOGGLE(history, match.params.cmdbId)}
          activeToggle={activeToggle}
          actions={
            <DropdownButton
              filled
              items={Conf.ACTION_ITEMS(
                setAlert,
                resetAlert,
                shapes,
                buildRegister.technical_name,
                match,
                tryDeleteCmdbTable,
                history,
                AuthStore
              )}
            >
              Åtgärder
            </DropdownButton>
          }
          className={Styles['page-header']}
          subHeaderChildren={<AddTag referenceId={match.params.cmdbId} />}
        />
        <InformationHeader
          details={Conf.INFORMATION_HEADER_ITEMS(buildRegister, createdBy)}
        />
      </Paper>
      {Conf.PARSE_CONTENT(activeToggle)}
    </>
  )
}

function mapStateToProps({ CmdbTableStore, AuthStore }) {
  return {
    CmdbTableStore,
    AuthStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CmdbTableActions,
      ...AlertActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Specific))
