import React, { useMemo } from 'react'

import { useTheme } from '@mui/material'

import TableRow from 'components_new/atoms/Table/TableRow'

import HeaderCell from '../HeaderCell'

import { DatasetType, FormattedWidgetDataDataset } from 'types/GlobalWidget'

import { ORDER_BY_SEGMENT_BY } from '../utils'

interface HeaderRowProps {
  display: boolean
  datasets: FormattedWidgetDataDataset[]
  scaleFactor: number
  segmentBy: string
  verticalDividers: boolean
  onSort?: (id: string, index: number | null) => void
  order?: 'asc' | 'desc'
  orderBy?: { id: string | null; index: number | null }
}

const HeaderRow = (props: HeaderRowProps) => {
  const {
    display,
    datasets,
    scaleFactor,
    segmentBy,
    verticalDividers,
    onSort,
    order,
    orderBy
  } = props

  const headerCells = useMemo(
    () =>
      datasets.map((dataset, i) => (
        <HeaderCell
          key={i}
          divider={verticalDividers}
          label={dataset.label}
          scaleFactor={scaleFactor}
          type={dataset.type}
          active={
            orderBy
              ? dataset.id === orderBy.id && dataset.index === orderBy.index
              : false
          }
          onClick={onSort ? () => onSort(dataset.id, dataset.index) : undefined}
          order={order}
          // Next release:
          // numberOfCharacters={dataset.numberOfCharacters}
        />
      )),
    [datasets, order, orderBy, scaleFactor]
  )

  const theme = useTheme()

  return display ? (
    <TableRow
      sx={{
        bgcolor: theme.palette.background.widget,
        backgroundImage: `linear-gradient(${theme.palette.background.overlay}, ${theme.palette.background.overlay})`
      }}
    >
      <HeaderCell
        divider={verticalDividers}
        label={segmentBy}
        scaleFactor={scaleFactor}
        type={DatasetType.STRING}
        active={orderBy ? ORDER_BY_SEGMENT_BY === orderBy.id : false}
        onClick={onSort ? () => onSort(ORDER_BY_SEGMENT_BY, null) : undefined}
        order={order}
      />
      {headerCells}
    </TableRow>
  ) : null
}

export default HeaderRow
