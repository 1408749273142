import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import PageHeader from 'components/common/PageHeader'
import Pagination from 'components/common/Pagination'
import Table from 'components/common/Table'

import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Register = ({ CmdbTableStore, TagStore, CmdbApiStore, history }) => {
  const [toggle, setToggle] = useState(Conf.TOGGLE_ITEMS[0])
  const [page, setPage] = useState(1)
  const [rows, count] = Conf.getRows(
    page,
    toggle,
    CmdbTableStore,
    TagStore,
    CmdbApiStore
  )

  return (
    <Paper sx={{ p: 2 }}>
      <PageHeader
        header={'Register'}
        actions={
          <Button
            onClick={() =>
              history.push('/data-platform/build/registers/create')
            }
            startIcon={<Icon name="AddCircleOutlineOutlined" />}
          >
            Skapa register
          </Button>
        }
        className={Styles['page-header']}
      />
      <Table
        rows={rows}
        headers={Conf.SOURCE_HEADERS}
        onClick={(id) =>
          history.push(`/data-platform/build/registers/${id}/data`)
        }
        toggle={{
          items: Conf.TOGGLE_ITEMS,
          onClick: (item) => setToggle(item),
          selected: toggle
        }}
      />
      <Pagination
        perPage={Conf.PAGE_LIMIT}
        text={'register'}
        onChange={(page) => setPage(page)}
        totalItems={count}
      />
    </Paper>
  )
}

function mapStateToProps({ CmdbTableStore, CmdbApiStore, TagStore }) {
  return {
    CmdbTableStore,
    CmdbApiStore,
    TagStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CmdbTableActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Register))
