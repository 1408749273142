import React from 'react'

import { DashboardGroup } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'

import DashboardThumbnail from 'components_new/molecules/DashboardThumbnail'
import SimpleSearch from 'components_new/molecules/SimpleSearch'
import ThumbnailButton from 'components_new/molecules/ThumbnailButton'
import ThumbnailList from 'components_new/molecules/ThumbnailList'
import TooltipToggleButtonGroup from 'components_new/molecules/TooltipToggleButtonGroup'

import { AuthenticationState } from 'types/GlobalAuthentication'

import { translateDashboardStatus } from 'utils/enumTranslator'
import { parseElapsedTimeSince } from 'helpers/Functions/Date'
import { sortAlphabeticalAsc, sortDateDesc } from 'utils/sortHelper'
import { getThumbnail } from 'utils/functions'
import { SortByMethod } from 'components_new/organisms/Finder/utils'

interface TemplatesProps {
  AuthStore: AuthenticationState
  createTemplate: () => void
  dashboardUsers?: { [key: string]: string[] }
  sortBy: SortByMethod
  templates: DashboardGroup[]
  updateSortByMethod: (param: SortByMethod) => void
}

const Templates = (props: TemplatesProps) => {
  const {
    createTemplate,
    dashboardUsers,
    sortBy,
    templates,
    updateSortByMethod
  } = props

  const sortByMethod = function (
    a: DashboardGroup,
    b: DashboardGroup,
    method: SortByMethod
  ) {
    switch (method) {
    case SortByMethod.ALPHABETICAL:
      return sortAlphabeticalAsc(a.title, b.title)
    case SortByMethod.LAST_VIEWED:
      return sortDateDesc(
        a.latest_visit ? new Date(a.latest_visit) : null,
        b.latest_visit ? new Date(b.latest_visit) : null
      )
    case SortByMethod.UPDATED_AT:
      return sortDateDesc(new Date(a.updated_at), new Date(b.updated_at))
    default:
      return 0
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <SimpleSearch
          options={templates.map((template) => {
            return {
              id: template.id,
              title: template.title,
              subtitle: `Ändrades för ${parseElapsedTimeSince(
                template.updated_at
              )} sedan`,
              href: `/dashboards/${template.id}`,
              src: getThumbnail(template.thumbnail),
              group: template.status
                ? translateDashboardStatus[template.status].title
                : ''
            }
          })}
          placeholder="Sök efter mall"
          size="small"
          sortGroups={true}
          sx={{ maxWidth: 'content', mr: 2 }}
        />
        <Stack spacing={1} alignItems="center" sx={{ pb: 0.5 }}>
          <TooltipToggleButtonGroup
            exclusive={true}
            fullWidth={false}
            items={[
              {
                value: SortByMethod.LAST_VIEWED,
                title: <Icon name="History" />,
                tooltip: (
                  <>
                    Sortera efter <b>Senast besökt</b>
                  </>
                )
              },
              {
                value: SortByMethod.ALPHABETICAL,
                title: <Icon name="TextRotationNone" />,
                tooltip: (
                  <>
                    Sortera efter <b>A-Ö</b>
                  </>
                )
              },
              {
                value: SortByMethod.UPDATED_AT,
                title: <Icon name="DateRange" />,
                tooltip: (
                  <>
                    Sortera efter <b>Senast ändrad</b>
                  </>
                )
              }
            ]}
            size="small"
            value={sortBy}
            onChange={(event: any, value: SortByMethod) => {
              if (value !== null) updateSortByMethod(value)
            }}
            color="primary"
          />
        </Stack>
      </Box>
      <Box sx={{ flex: '1 1 auto', position: 'relative', minHeight: 0 }}>
        <ThumbnailList
          firstChild={
            <ThumbnailButton
              color="brand"
              iconName="AddCircleOutlineOutlined"
              onClick={() => createTemplate()}
            >
              Ny mall
            </ThumbnailButton>
          }
        >
          {templates
            .sort(function (a, b) {
              return sortByMethod(a, b, sortBy)
            })
            .map((group) => (
              <DashboardThumbnail
                AuthStore={props.AuthStore}
                group={group}
                href={`/templates/${group.dashboards[0]}`}
                users={dashboardUsers?.[group.id] || []}
                key={group.id}
                variant="dashboard"
                mediaAdornments={
                  group.status ? (
                    <Chip
                      color={translateDashboardStatus[group.status].color}
                      label={translateDashboardStatus[group.status].title}
                      size="small"
                    />
                  ) : undefined
                }
                src={getThumbnail(group.thumbnail)}
              />
            ))}
        </ThumbnailList>
      </Box>
    </Box>
  )
}

export default Templates
