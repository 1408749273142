import React, { useEffect, useState } from 'react' // , useEffect, useState // useState // ReactNode,

import { AspectRatio } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import Logotype from 'assets/logotypes/Logotype'

import { ThemeProvider } from '@mui/material'
import { getTheme, hexToRgbObject } from 'themes'

interface DashboardFooterProps {
  colors?: any
  dashboardRatio: AspectRatio
  logotype?: string
  scaleFactor: number
  title: string
}

const DashboardFooter = (props: DashboardFooterProps) => {
  const { colors, dashboardRatio, logotype, scaleFactor, title } = props

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000)

    return () => {
      clearInterval(timer)
    }
  })

  const textColor = getTextColor(colors?.background_color)

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        bgcolor:
          dashboardRatio === AspectRatio['16_9']
            ? 'action.hover'
            : colors?.background_color || 'white.main',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 4 * scaleFactor,
        overflow: 'hidden'
      }}
    >
      <ThemeProvider theme={getTheme('light')}>
        {dashboardRatio === '16_9' ? (
          <>
            <Box sx={{ flex: 1 }}>
              <Text
                color={textColor}
                fontWeight="bold"
                fontSize={32 * scaleFactor}
                noWrap
              >
                {title}
              </Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1
              }}
            >
              <Text
                fontWeight="medium"
                fontSize={24 * scaleFactor}
                noWrap
                color={textColor}
                align="right"
              >
                {date.toLocaleTimeString('sv', {
                  hour: 'numeric',
                  minute: 'numeric'
                })}
              </Text>
              <Text
                fontWeight="medium"
                fontSize={18 * scaleFactor}
                noWrap
                color={textColor}
                align="right"
                sx={{ '&:first-letter': { textTransform: 'uppercase' } }}
              >
                {date.toLocaleDateString('sv', {
                  weekday: 'short'
                })}{' '}
                {date
                  .toLocaleString('sv', {
                    day: 'numeric',
                    month: 'short'
                  })
                  .toLowerCase()}
              </Text>
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                flex: 1
              }}
            >
              {logotype ? (
                <Box sx={{ height: 40 * scaleFactor }}>
                  <img
                    src={logotype}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              ) : (
                <Stack alignItems="center" sx={{ gap: 0.5 }}>
                  <Text
                    color={textColor}
                    fontWeight="bold"
                    fontSize={16 * scaleFactor}
                    lineHeight={1}
                    noWrap
                  >
                    Powered by
                  </Text>
                  <Box
                    sx={{
                      height: 40 * scaleFactor,
                      color: textColor
                    }}
                  >
                    <Logotype />
                  </Box>
                </Stack>
              )}
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ flex: 1 }}>
              <Text
                color={textColor}
                fontWeight="bold"
                fontSize={24 * scaleFactor}
                noWrap
              >
                {title}
              </Text>
            </Box>
            {logotype ? (
              <Box sx={{ height: 32 * scaleFactor }}>
                <img
                  src={logotype}
                  alt={'Logotyp'}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </Box>
            ) : (
              <Stack alignItems="center" sx={{ gap: 0.5 }}>
                <Text
                  color="text.primary"
                  fontWeight="bold"
                  fontSize={12 * scaleFactor}
                  lineHeight={1}
                  noWrap
                >
                  Powered by
                </Text>
                <Box
                  sx={{
                    height: 32 * scaleFactor,
                    color: textColor
                  }}
                >
                  <Logotype />
                </Box>
              </Stack>
            )}
          </>
        )}
      </ThemeProvider>
    </Box>
  )
}

const getTextColor = (color: string | null) => {
  if (color) {
    const rgb = hexToRgbObject(color)
    const black = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

    if (black) {
      return '#0f172a'
    } else {
      return '#ffffff'
    }
  } else {
    return '#0f172a'
  }
}

export default DashboardFooter
