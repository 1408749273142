import React from 'react'

import { WhoCanView } from 'redux/reducers/Dashboards'

import FormControl from 'components_new/atoms/FormControl'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Select from 'components_new/atoms/Select'

import { translateWhoCanView } from 'utils/enumTranslator'

interface WhoCanViewSectionProps {
  editable: boolean
  setWhoCanView: (value: WhoCanView) => void
  whoCanView: WhoCanView
}

const whoCanViewOptions = [WhoCanView.ONLY_ADDED_USERS, WhoCanView.ORGANIZATION]

const WhoCanViewSection = (props: WhoCanViewSectionProps) => {
  const { editable, setWhoCanView, whoCanView } = props

  return (
    <>
      {editable ? (
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          hiddenLabel
          margin="normal"
          disabled={!editable}
          sx={{ my: 0 }}
        >
          <Select
            value={whoCanView}
            onChange={(event) =>
              setWhoCanView(event.target.value as WhoCanView)
            }
            renderValue={(value: WhoCanView) => (
              <MenuItem>
                <ListItemIcon>
                  <Icon name={translateWhoCanView[value].iconName} />
                </ListItemIcon>
                <ListItemText
                  primary={translateWhoCanView[value].title}
                  secondary={translateWhoCanView[value].description}
                />
              </MenuItem>
            )}
            MenuProps={{ disableScrollLock: true }}
          >
            {whoCanViewOptions.map((value, i) => (
              <MenuItem value={value} key={i}>
                <ListItemIcon>
                  <Icon name={translateWhoCanView[value].iconName} />
                </ListItemIcon>
                <ListItemText
                  primary={translateWhoCanView[value].title}
                  secondary={translateWhoCanView[value].description}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <ListItem sx={{ bgcolor: 'action.hover' }}>
          <ListItemIcon>
            <Icon name={translateWhoCanView[whoCanView].iconName} />
          </ListItemIcon>
          <ListItemText
            primary={translateWhoCanView[whoCanView].title}
            secondary={translateWhoCanView[whoCanView].description}
          />
        </ListItem>
      )}
    </>
  )
}

export default WhoCanViewSection
