import React, { ElementType, ReactNode } from 'react'
import { InputAdornment as MUIInputAdornment } from '@mui/material'

interface InputAdornmentProps {
  children?: ReactNode
  component?: ElementType<any>
  disablePointerEvents?: boolean
  disableTypography?: boolean
  position: 'end' | 'start'
  sx?: object
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * The InputAdornment component is used to
 * add a prefix, a suffix, or an action to
 * an input.
 *
 * For instance, you can use an icon button
 * to hide or reveal the password.
 */

const InputAdornment = React.forwardRef(
  (props: InputAdornmentProps, ref: any) => {
    return (
      <MUIInputAdornment {...props} ref={ref}>
        {props.children}
      </MUIInputAdornment>
    )
  }
)

InputAdornment.displayName = 'InputAdornment'
export default InputAdornment
