import React from 'react'

import { Form, Row, Col } from 'antd'

import Modal from 'components/common/Modal'
import Button from 'components_new/atoms/Button'
import Input from 'components/common/Input'

const EditName = ({
  open,
  closeModal,
  onUpdate,
  name,
  SourceStore,
  activeSourceId
}) => {
  const initialValues = { display_name: name }

  return (
    <Modal
      visible={open}
      onClose={() => closeModal()}
      header={'Uppdatera namn'}
    >
      <Form
        layout={'vertical'}
        onFinish={(formAttr) => onUpdate(formAttr)}
        initialValues={initialValues}
      >
        <Form.Item
          label={'Namn'}
          rules={[
            { required: true, message: 'Var god fyll i ett namn' },
            () => ({
              validator(rule, value) {
                if (
                  Object.values(SourceStore.data)
                    .filter(
                      (item) => item.included && item.included.active_sources
                    )
                    .some((item) =>
                      item.included.active_sources.some(
                        (activeSource) =>
                          activeSource.attributes.display_name.toLowerCase() ===
                            value.toLowerCase() &&
                          activeSource.id !== activeSourceId
                      )
                    )
                ) {
                  return Promise.reject(
                    new Error(
                      `Ett system med samma namn '${value}' existerar redan`
                    )
                  )
                }

                return Promise.resolve()
              }
            })
          ]}
          name="display_name"
        >
          <Input placeholder={'Skriv namn...'} />
        </Form.Item>
        <Row gutter={8} align={'end'}>
          <Col>
            <Button onClick={() => closeModal()} variant="text">
              Avbryt
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="submit">Spara</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default EditName
