import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, update, destroy } from 'redux/api/internal/OrderedKpiTemplates'

import {
  PatchOrderedKpiTemplateBody,
  PostOrderedKpiTemplateBody
} from 'types/GlobalOrderedKpiTemplate'

export function tryCreateOrderedKpiTemplate(
  body: PostOrderedKpiTemplateBody,
  customerId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_ORDERED_KPI_TEMPLATE
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_ORDERED_KPI_TEMPLATE_SUCCESS,
          payload: { response: response.data, customerId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunden har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ORDERED_KPI_TEMPLATE_FAILED
        })
      })
  }
}

export function tryUpdateOrderedKpiTemplate(
  id: string,
  body: PatchOrderedKpiTemplateBody,
  customerId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ORDERED_KPI_TEMPLATE
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ORDERED_KPI_TEMPLATE_SUCCESS,
          payload: { response: response.data, id, customerId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunden har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ORDERED_KPI_TEMPLATE_FAILED
        })
      })
  }
}

export function tryDeleteOrderedKpiTemplate(id: string, customerId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_ORDERED_KPI_TEMPLATE
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ORDERED_KPI_TEMPLATE_SUCCESS,
          payload: { id, customerId }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kunden har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ORDERED_KPI_TEMPLATE_FAILED
        })
      })
  }
}
