import React, { ElementType } from 'react'
import * as MUIIcon from '@mui/icons-material'
import { Color } from 'themes'

export type IconNames = keyof typeof MUIIcon

interface IconProps {
  color?:
    | Color
    | 'inherit'
    | 'action'
    | 'brand'
    | 'default'
    | 'disabled'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'black'
    | 'white'
  component?: ElementType<any>
  fontSize?: 'large' | 'medium' | 'small' | 'inherit'
  name: IconNames
  sx?: object
}

/**
 * The Icon component displays an icon from Google's
 * 2100 official Material icons. Each icon comes in
 * five different themes.
 *
 * All icon names can be found in Materiual UI:s
 * documentation: https://mui.com/material-ui/material-icons/
 */

const Icon = React.forwardRef((props: IconProps, ref: any) => {
  const { name, ...rest } = props
  const Icon = MUIIcon[name]

  return Icon ? <Icon {...rest} ref={ref} /> : null
})

Icon.displayName = 'Icon'
export default Icon
