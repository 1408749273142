/* Fake feature for sales and review */

import React from 'react'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'

import { WidgetNotification } from 'types/GlobalWidgetNotification'
import { KpiOptionObject } from 'types/GlobalKpiOption'

import { translateNotificationTitle } from './utils'

interface WidgetNotifcationListItemProps {
  notification: WidgetNotification
  onEditSubscribers: () => void
  onDelete: () => void
  kpiOptions: KpiOptionObject[]
}

const WidgetNotifcationListItem = (props: WidgetNotifcationListItemProps) => {
  return (
    <ListItem
      secondaryAction={
        <>
          <Button onClick={() => props.onEditSubscribers()} variant="text">
            Prenumeranter
          </Button>
          <Button onClick={props.onDelete} variant="text">
            Ta bort
          </Button>
        </>
      }
    >
      <Icon color="disabled" name="NotificationsNoneOutlined" />
      <ListItemText
        sx={{ ml: 3 }}
        primary={translateNotificationTitle(
          props.notification,
          props.kpiOptions
        )}
        secondary={`${props.notification.subscribers.length} ${
          props.notification.subscribers.length === 1
            ? 'prenumerant'
            : 'prenumeranter'
        }`}
        primaryTypographyProps={{ fontWeight: 500 }}
      />
    </ListItem>
  )
}

export default WidgetNotifcationListItem
