import { Dispatch } from 'redux'

import * as Api from 'redux/api/Companies'
import * as Types from 'redux/Types'

import { PatchCompanyBody } from 'types/GlobalCompany'

export function tryGetAllCompanies() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_COMPANIES
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_COMPANIES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_COMPANIES_FAILED
        })
      })
  }
}

export function tryUpdateCompany(id: string, body: PatchCompanyBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_COMPANY
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_COMPANY_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Bolag uppdaterad' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_COMPANY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Bolag kunde inte uppdateras' }
        })
      })
  }
}
