export const roles = [
  'Affärs/verksamhetsutvecklare',
  'Affärsutveckling/verksamhetsstyrningschef',
  'Arkitekt',
  'Assistent',
  'Controller',
  'Digital kommunikatör',
  'Drift/Teknisk chef',
  'Ekonom',
  'Ekonomichef (CFO)',
  'Energichef',
  'Energicontroller',
  'Energisamordnare',
  'Energistrateg',
  'Fastighetscontroller',
  'Fastighetsutvecklingschef',
  'Finansiering',
  'Förvaltare',
  'Förvaltning, fastighet, distrikt- och regionschef',
  'Hållbarhetschef',
  'HR-chef',
  'Hyresadministratör',
  'Inköpschef',
  'IT & digitaliseringschef',
  'IT-ansvar (annan rollbeskrivning)',
  'IT-arkitekt',
  'Kommunikationschef',
  'Kommunikationssamordnare',
  'Kund- och marknadschef',
  'Kundansvarig',
  'Kundcenterchef',
  'Ledningsgrupp',
  'Miljöansvar',
  'Objektspecialist/projektledare IT',
  'Områdesvärd',
  'Projekt/projektutvecklingschef',
  'Projektledare',
  'Redovisning',
  'Säkerhetsansvarig',
  'Systemförvaltare',
  'Teknisk förvaltare',
  'Underhållschef',
  'Uthyrare',
  'Uthyrningschef',
  'VD',
  'Vice VD'
]

export const categories = [
  'Arbetsordrar',
  'Annonsering',
  'Besiktningar',
  'Ekonomi',
  'Hyresavtal',
  'Hyresobjekt',
  'Hållbarhet',
  'In- och utflyttning',
  'Kundservice',
  'Kundvårdsärenden',
  'Planerat underhåll',
  'Resurser',
  'Vakans'
]
