import React, { ReactNode } from 'react'

import { Color } from 'themes'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

interface SettingsItemProps {
  actions?: ReactNode
  body?: string
  children?: ReactNode
  color?: 'error' | 'info' | 'success' | 'warning' | Color | 'brand'
  disabled?: boolean
  disabledText?: ReactNode
  dividerTitle?: ReactNode
  icon?: ReactNode
  indent?: number
  noDivider?: boolean
  onClick?: () => void
  sx?: object
  title?: ReactNode
  value?: number | string | null
  variant?: 'action' | 'add' | 'edit'
}

const SettingsItem = React.forwardRef((props: SettingsItemProps, ref: any) => {
  const {
    actions,
    body,
    children,
    color,
    disabled,
    disabledText,
    dividerTitle,
    icon,
    indent,
    noDivider,
    onClick,
    sx,
    title,
    value,
    variant
  } = props

  return (
    <Box
      ref={ref}
      component="li"
      sx={{
        '& .MuiDivider-root': {
          marginLeft: icon ? 7 : 2
        },
        '&:last-child': {
          '& .MuiDivider-root': {
            display: 'none'
          }
        },
        ...sx
      }}
    >
      <SettingsItemWrapper
        alignCenter={variant === 'action'}
        disabled={disabled}
        disabledText={disabledText}
        onClick={onClick}
      >
        {icon ? (
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>{icon}</ListItemIcon>
        ) : null}
        {(title || body) && variant !== 'action' ? (
          <Box sx={{ mr: 1, ml: indent }}>
            {title ? (
              <Text
                component="div"
                color={`${color}.text` ?? 'text.primary'}
                sx={{
                  fontWeight: 'medium'
                }}
              >
                {title}
                {variant === 'add' ? '...' : ''}
              </Text>
            ) : null}
            {body ? (
              <Text color={'text.secondary'} component="div">
                {body}
              </Text>
            ) : null}
          </Box>
        ) : null}
        {variant === 'action' ? (
          <Text
            align={'center'}
            component="div"
            color={`${color}.text` ?? 'text.primary'}
            sx={{
              fontWeight: 'medium'
            }}
          >
            {title}
          </Text>
        ) : undefined}
        {(actions || children || onClick || value !== undefined) &&
        variant !== 'action' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flex: 1,
                  gap: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: 0
                }}
              >
                {value !== undefined ? (
                  <>
                    {value === null || value === '' ? (
                      <Text
                        color="text.disabled"
                        fontStyle="italic"
                        variant="body1"
                      >
                    Inget valt än
                      </Text>
                    ) : (
                      <Text
                        color="text.secondary"
                        variant="body1"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: 0
                        }}
                      >
                        {value}
                      </Text>
                    )}
                  </>
                ) : null}
                {actions}
                {children ? (
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0
                    }}
                  >
                    {children}
                  </Box>
                ) : null}
              </Box>
            ) : null}
        {onClick && (!variant || variant === 'edit') ? (
          <Icon
            color="disabled"
            fontSize="small"
            name={variant === 'edit' ? 'EditOutlined' : 'KeyboardArrowRight'}
            sx={{ ml: 1 }}
          />
        ) : null}
      </SettingsItemWrapper>
      {noDivider ? null : <Divider textAlign="left">{dividerTitle}</Divider>}
    </Box>
  )
})

const SettingsItemWrapper = (props: {
  alignCenter?: boolean
  children: ReactNode
  disabled?: boolean
  disabledText?: ReactNode
  onClick?: () => void
}) => {
  const { alignCenter, children, disabled, disabledText, onClick } = props

  if (onClick) {
    return (
      <Tooltip
        placement="left"
        title={disabled && disabledText ? disabledText : ''}
      >
        <span style={disabled ? { cursor: 'not-allowed' } : undefined}>
          <ListItemButton
            disabled={disabled}
            onClick={onClick}
            sx={alignCenter ? { justifyContent: 'center' } : undefined}
          >
            {children}
          </ListItemButton>
        </span>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip
        placement="left"
        title={disabled && disabledText ? disabledText : ''}
      >
        <span
          style={
            disabled ? { cursor: 'not-allowed', opacity: 0.38 } : undefined
          }
        >
          <ListItem
            component="div"
            sx={disabled ? { pointerEvents: 'none' } : undefined}
          >
            {children}
          </ListItem>
        </span>
      </Tooltip>
    )
  }
}

SettingsItem.displayName = 'SettingsItem'

export default SettingsItem
