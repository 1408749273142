import { Theme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { getColor } from 'components_new/organisms/Widget/types/utils'
import { TrackingUser } from 'types/GlobalTracking'

export type ParsedObject = { [role: string]: { [preference: string]: number } }

const appendToObject = (
  obj: ParsedObject,
  role: string,
  preference: string
) => {
  if (role in obj) {
    if (preference in obj[role]) {
      obj[role][preference] += 1
    } else {
      obj[role][preference] = 1
    }
  } else {
    obj[role] = {
      [preference]: 1
    }
  }
}

export const getOptions = (
  theme: Theme,
  displayLegend: boolean
): ChartOptions<'bar'> => {
  return {
    plugins: {
      legend: {
        display: displayLegend,
        labels: {
          color: theme.palette.text.secondary
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      bar: {
        borderRadius: 2
      },
      point: {
        radius: 0,
        hitRadius: 0
      }
    },
    interaction: {
      intersect: true
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
          color: theme.palette.divider
        }
      },
      y: {
        stacked: true,
        display: true,
        grid: {
          display: true,
          drawBorder: false,
          color: theme.palette.divider
        }
      },
      border: {
        display: false
      }
    }
  }
}

export const getRolesData = (usersUsage: TrackingUser[], theme: Theme) => {
  const obj: { [role: string]: number } = {}

  usersUsage.forEach((user) => {
    if (user.additional_information) {
      const roles = getRoles(user.additional_information.role)

      roles.forEach((role) => {
        if (obj[role]) {
          obj[role] += 1
        } else {
          obj[role] = 1
        }
      })
    }
  })

  const labels = Object.keys(obj)

  return {
    labels,
    dataLabels: labels,
    datasets: [
      {
        data: labels.map((label) => obj[label]),
        backgroundColor: getColor(1, 0, theme, true, null, null),
        borderColor: getColor(1, 0, theme, true, null, null)
      }
    ]
  }
}

const getRoles = (role: string) => {
  let roleString = `${role}`
  const roles = []

  if (
    roleString.includes('Förvaltning, fastighet, distrikt- och regionschef')
  ) {
    // Replace all different ways this role can occur as.
    // "",role," and "role," and ",role" and "role"
    roleString = roleString.replace(
      ',Förvaltning, fastighet, distrikt- och regionschef,',
      ''
    )
    roleString = roleString.replace(
      'Förvaltning, fastighet, distrikt- och regionschef,',
      ''
    )
    roleString = roleString.replace(
      ',Förvaltning, fastighet, distrikt- och regionschef',
      ''
    )
    roleString = roleString.replace(
      'Förvaltning, fastighet, distrikt- och regionschef',
      ''
    )
    roles.push('Förvaltning, fastighet, distrikt- och regionschef')
  }

  roles.push(...roleString.split(','))

  return roles
}

export const getRolesAndPreferencesData = (
  usersUsage: TrackingUser[],
  theme: Theme
) => {
  const obj: ParsedObject = {}
  const allPreferences = new Set<string>()

  usersUsage.forEach((user) => {
    if (user.additional_information) {
      const roles = getRoles(user.additional_information.role)
      const preferences = user.additional_information.preference.split(',')

      roles.forEach((role) => {
        preferences.forEach((preference) => {
          allPreferences.add(preference)
          appendToObject(obj, role, preference)
        })
      })
    }
  })

  const labels = Object.keys(obj)

  return {
    labels,
    dataLabels: labels,
    datasets: Array.from(allPreferences).map((preference, i) => {
      return {
        label: preference,
        data: labels.map((label) => {
          return obj[label][preference] || 0
        }),
        backgroundColor: getColor(1, i, theme, true, null, null),
        borderColor: getColor(1, i, theme, true, null, null)
      }
    })
  }
}
