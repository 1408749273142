import React from 'react'

import Toggle from 'components/common/Toggle/v2'

import Styles from './styles.module.css'

export const ReadTableToggle = ({ tryUpdateSourceTable, activeTable }) => (
  <Toggle
    options={[
      { label: 'Ja', value: true },
      { label: 'Nej', value: false }
    ]}
    value={activeTable.attributes.load}
    onChange={() =>
      tryUpdateSourceTable(
        activeTable.id,
        {
          data: {
            attributes: { columns: [], load: !activeTable.attributes.load }
          }
        },
        activeTable.attributes.active_source_id
      )
    }
    className={Styles['read-table-toggle']}
  />
)

export const HEADERS = (tableId, tryUpdateSourceColumn) => [
  {
    label: 'Kolumn',
    key: 'column'
  },
  {
    label: 'GDPR-skyddad',
    key: 'isSensitive',
    render: function renderColumn(isSensitive, row) {
      return (
        <Toggle
          options={[
            { label: 'Ja', value: true },
            { label: 'Nej', value: false }
          ]}
          value={isSensitive}
          onChange={() =>
            tryUpdateSourceColumn(tableId, row.id, {
              data: { attributes: { is_sensitive: !isSensitive } }
            })
          }
        />
      )
    }
  }
]
