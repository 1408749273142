type Range = {
  start: number
  end: number
  label: string
}

export type RangeAccount = {
  name: string | null
  number: string
  disabled: boolean
}
export type EconomyAccountMap = {
  [setId: string]: { [number: string]: string }
}

/**
 * Generates ranges on the form 0-999...9000-999, 1000-1099...1900-1999,
 * 1000-1009...1090-1099, and so on.
 *
 * @param start is the number to start on,
 * @param factor is the factor for each start number,
 * @param n is the amount of ranges.
 * @returns an array of ranges with a start and end number (together
 * with a label).
 */
export const getRanges = (start: number, factor: number, n: number) => {
  const ranges: Range[] = []

  for (let i = 0; i < n; i++) {
    const rangeStart: number = start + i * factor
    const rangeEnd: number = start + factor + i * factor - 1

    const rangeStartString = String(rangeStart).padStart(4, '0')
    const rangeEndString = String(rangeEnd).padStart(4, '0')

    ranges.push({
      start: rangeStart,
      end: rangeEnd,
      label: rangeStartString + '-' + rangeEndString
    })
  }

  return ranges
}

/**
 * Create range groups for every level on thousand, hundred and tenth.
 */
export const getRangeGroups = (
  accountsMap: { [number: string]: string },
  limitToAccounts?: string[]
) => {
  const first = getRanges(1000, 1000, 9).map((range) => {
    const second = getRanges(range.start, 100, 10).map((subRange) => {
      const third = getRanges(subRange.start, 10, 10).map((lastRange) => {
        const accounts: RangeAccount[] = []

        for (let i = lastRange.start; i <= lastRange.end; i++) {
          const accountNumber = i.toString()

          accounts.push({
            number: accountNumber,
            name: accountsMap[accountNumber] || null,
            disabled: limitToAccounts
              ? !limitToAccounts.includes(accountNumber)
              : false
          })
        }

        return {
          accounts: accounts,
          range: lastRange,
          disabled: accounts.every((acc) => acc.disabled)
        }
      })

      return {
        accounts: third.map((item) => item.accounts).flat(),
        range: subRange,
        options: third,
        disabled: third.every((item) => item.disabled)
      }
    })

    return {
      accounts: second.map((item) => item.accounts).flat(),
      range,
      options: second,
      disabled: second.every((item) => item.disabled)
    }
  })

  return {
    accounts: first.map((item) => item.accounts).flat(),
    options: first,
    disabled: first.every((item) => item.disabled)
  }
}

/**
 * Selects or deselect number based on the range input.
 *
 * @param selected is the current selected numbers,
 * @param rangeStart is the start of the range to be selected or deselected,
 * @param rangeEnd  is the end of the range to be selected or deselected,
 * @returns the new selected numbers.
 */
export const handleToggle = (
  selected: string[],
  accountsInRange: RangeAccount[]
): string[] => {
  const selectionStatus = getRangeSelectionStatus(selected, accountsInRange)

  const temp = new Set(selected)

  if (selectionStatus === 'checked' || selectionStatus === 'indeterminate') {
    accountsInRange.forEach((acc) => {
      temp.delete(acc.number)
    })
  } else {
    accountsInRange
      .filter((acc) => !acc.disabled)
      .forEach((acc) => {
        temp.add(acc.number)
      })
  }

  return Array.from(temp) as string[]
}

/**
 * Get the selected status of a range.
 *
 * @param selected is the current selected numbers,
 * @param accountsInRange accounts in given range.
 * @returns 'checked' if all numbers in the range exist in the current
 * selected numbers, 'indeterminate' if some of them exist, and false
 * if none of them exist.
 */
export const getRangeSelectionStatus = (
  selected: string[],
  accountsInRange: RangeAccount[]
): 'checked' | 'indeterminate' | false => {
  const availableAccountsInRange = accountsInRange.filter(
    (acc) => !acc.disabled
  )

  if (availableAccountsInRange.length === 0) {
    // all accounts are disabled
    return false
  }

  const isSelected = accountsInRange.map((acc) => selected.includes(acc.number))

  if (isSelected.every(Boolean)) {
    return 'checked'
  } else if (isSelected.some(Boolean)) {
    return 'indeterminate'
  } else {
    return false
  }
}
