import React from 'react'

import Box from 'components_new/atoms/Box'

import ValueSection from './ValueContainer'

import NoValue from '../../errors/NoValue'

import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import { translateWidgetType } from 'utils/enumTranslator'

interface KeyFigureProps {
  scaleFactor: number
  widget: WidgetObject
}

const KeyFigure = (props: KeyFigureProps) => {
  const { scaleFactor, widget } = props

  if (widget.data && (!!widget.data.value || widget.data.value === 0)) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          fontFamily: 'IBM Plex Sans', // 'Dm Sans'?
          color: 'text.primary'
        }}
      >
        <ValueSection widget={widget} />
      </Box>
    )
  }

  return (
    <NoValue
      embedded={false}
      iconName={translateWidgetType[WidgetType.KEY_FIGURE].iconName}
      scaleFactor={scaleFactor}
    />
  )
}

export default KeyFigure
