import React, { useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

import Button from 'components_new/atoms/Button'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import EditKpiSection from './EditKpiSection'

import {
  KpiVariable,
  KpiVariableWithChildren
} from 'redux/reducers/KpiVariables'
import { KPI_UNIT } from 'types/Enums'

import { formatBlock, isValidBlock } from './utils'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock,
  PutKpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

interface EditKpiDialogProps {
  formattedVariables: KpiVariableWithChildren[]
  kpi: KpiTemplate
  onClose: () => void
  onSave: (body: {
    name: string
    blocks: PutKpiTemplateCalculationBlock
  }) => void
  open: boolean
  variables: KpiVariable[]
  templates: KpiTemplate[]
}

const EditKpiDialog = (props: EditKpiDialogProps) => {
  const {
    formattedVariables,
    kpi,
    onClose,
    onSave,
    open,
    variables,
    templates
  } = props

  const [editFormula, setEditFormula] = useState<KpiTemplateCalculationBlock>(
    cloneDeep(
      (kpi.custom_calculation_block ||
        kpi.default_calculation_block) as KpiTemplateCalculationBlock
    )
  )
  const [editName, setEditName] = useState<string>(kpi.title)

  const validBlock = useMemo(() => isValidBlock(editFormula), [editFormula])

  return (
    <AdvancedDialog
      fullWidth={true}
      maxWidth="lg"
      title="Redigera nyckeltal"
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            disabled={!editName || !validBlock}
            variant="contained"
            onClick={() => {
              onSave({ name: editName, blocks: formatBlock(editFormula) })
            }}
          >
            Spara
          </Button>
        </>
      }
    >
      <EditKpiSection
        formattedVariables={formattedVariables}
        editFormula={editFormula}
        editName={editName}
        editUnit={kpi.unit as KPI_UNIT}
        setEditFormula={setEditFormula}
        setEditName={setEditName}
        variables={variables}
        templates={templates.filter((template) => template.id !== kpi.id)}
        homepalKpi={!!kpi.default_calculation_block}
      />
    </AdvancedDialog>
  )
}

export default EditKpiDialog
