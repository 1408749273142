import React, { useState } from 'react'

import DataTypeIcon from 'components/common/DataTypeIcon'
import IconHeaderFlag from 'components/common/IconHeaderFlag'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import {
  parseOperationAttributes,
  SHOW_MORE_THRESHOLD,
  OPERATION_TYPES
} from './conf'

const OperationCard = ({
  type,
  attributes,
  newAttribute,
  leftAttribute,
  rightAttribute,
  chosenAttributes,
  isLast,
  onRemove,
  onEdit
}) => {
  const [showMore, setShowMore] = useState(false)
  const chosenLeftAttribute = chosenAttributes[leftAttribute]
  const chosenRightAttribute = chosenAttributes[rightAttribute]

  const attributesToShow = parseOperationAttributes(
    chosenAttributes,
    type,
    attributes,
    newAttribute,
    chosenLeftAttribute,
    chosenRightAttribute
  )

  return (
    <Box>
      <Paper
        sx={{
          p: 1,
          position: 'relative'
        }}
        variant="outlined"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
          <IconHeaderFlag
            icon={OPERATION_TYPES[type].icon}
            header={OPERATION_TYPES[type].label}
          />
          <IconButton edge="end" onClick={onRemove} size="small">
            <Icon fontSize="small" name="Close" />
          </IconButton>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box sx={{ mb: 1 }}>
            {attributesToShow.map((attribute, i) =>
              !showMore && i >= SHOW_MORE_THRESHOLD ? null : (
                <UsedAttribute
                  key={`attr-${i}`}
                  type={attribute.type}
                  name={attribute.name}
                  tableName={attribute.tableName}
                />
              )
            )}
            {attributesToShow.length > SHOW_MORE_THRESHOLD && (
              <>
                <Button
                  fullWidth
                  onClick={() => setShowMore(!showMore)}
                  variant="text"
                  startIcon={
                    <Icon name={showMore ? 'ExpandLess' : 'ExpandMore'} />
                  }
                  sx={{ mt: 1 }}
                >
                  {showMore ? 'Visa mindre' : 'Visa mer'}
                </Button>
                <Divider sx={{ my: 1 }} />
              </>
            )}
          </Box>
          <Button fullWidth onClick={onEdit} variant="text">
            Redigera
          </Button>
        </Box>
      </Paper>
      {!isLast && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Icon name="ArrowDownward" />
        </Box>
      )}
    </Box>
  )
}

const UsedAttribute = ({ type, name, tableName }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 0.5,
      padding: 0.5
    }}
  >
    <DataTypeIcon type={type} />
    <Text variant="body1">{name}</Text>
    <Text color="text.secondary" variant="body1">
      {tableName}
    </Text>
  </Box>
)

export default OperationCard
