import React from 'react'

import { KpiTemplate, KpiTemplateType } from 'types/GlobalKpiTemplates'
import { Customer } from 'types/GlobalCustomer'

import Text from 'components_new/atoms/Text'

import UserListItem from 'components_new/molecules/UserListItem'

interface CreatedByProps {
  customer: Customer | null
  kpi: KpiTemplate
}

const CreatedBy = (props: CreatedByProps) => {
  const { customer, kpi } = props

  const isCustom = kpi.type === KpiTemplateType.CUSTOM

  return (
    <>
      {isCustom ? (
        <>
          <UserListItem
            body="Skapat av din organisation"
            title={customer?.name ?? ''}
            type="customer"
          />
          <Text color="text.secondary" variant="body2" gutterBottom={true}>
            Homepal är ett verktyg för uppföljning och rapportering som är
            specialbyggt för medarbetare på fastighetsbolag.
          </Text>
          <Text color="text.secondary" variant="body2">
            Med Homepal kan du skapa egna ekonomiska nyckeltal utifrån data som
            redan är uppkopplad, helt utan hjälp av konsulter.
          </Text>
        </>
      ) : (
        <>
          <UserListItem
            body="Skapat av vårt team"
            title="Homepal"
            type="homepal"
          />
          <Text color="text.secondary" variant="body2" gutterBottom={true}>
            Homepal är ett verktyg för uppföljning och rapportering som är
            specialbyggt för medarbetare på fastighetsbolag.
          </Text>
          <Text color="text.secondary" variant="body2">
            Med Homepal börjar du aldrig från noll, utan med data som redan är
            uppkopplad och nyckeltal som redan är beräknade – som du sedan kan
            modifiera precis som du vill, helt utan hjälp av konsulter.
          </Text>
        </>
      )}
    </>
  )
}

export default CreatedBy
