import React from 'react'
import Icon from 'components_new/atoms/Icon'

import Chip from 'components_new/atoms/Chip'

import Styles from './styles.module.css'

/*
 * Requires key in items
 */
const List = ({ items, active, setActive }) => {
  return (
    <div className={Styles.container}>
      {items.map((item) => {
        let className = `clickable ${Styles['item-container']} `

        if (item.value === active) {
          className += Styles['active-container']
        }

        return (
          <div
            className={className}
            onClick={() => setActive(item.value)}
            key={item.key}
          >
            <small className={'secondary overflow-ellipsis'} title={item.label}>
              {item.label}
            </small>
            <div className={Styles['item-container-right']}>
              {item.tag && <Chip label={item.tag} size="small" />}
              <Icon name="ChevronRight" />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default List
