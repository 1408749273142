import { getStatusBanner } from 'redux/api/StatusBanner'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryGetStatusBanner(customerId: string, isPublic?: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_STATUS_BANNER
    })

    getStatusBanner(customerId, isPublic)
      .then((response) => {
        dispatch({
          type: Types.GET_STATUS_BANNER_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_STATUS_BANNER_FAILED
        })
      })
  }
}
