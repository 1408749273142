import * as Types from 'redux/Types'

import * as AlertActions from 'redux/actions/Alert'

export function setLockedMode(data) {
  return (dispatch) => {
    dispatch({
      type: Types.SET_LOCKED_MODE,
      payload: { ...data, ignoreId: !data.locked ? null : data.ignoreId },
      onOk: () => {
        dispatch({ type: Types.SET_LOCKED_MODE, payload: { locked: false } })
        AlertActions.resetAlert()(dispatch)
      }
    })
  }
}
