import React, { useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SectionHeader from 'components_new/molecules/SectionHeader'

import EditVariableAccountsDialog from './account_dialogs/EditVariableAccountsDialog'

import { KpiVariable } from 'redux/reducers/KpiVariables'
import { KPI_UNIT } from 'types/Enums'
import {
  KpiTemplate,
  PutKpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

import KpiSection from './KpisSection'
import VariablesSection from './VariablesSection'
import VariablesAccountsSection from './AccountsSection'
import { KpiVariableOption } from 'types/GlobalCompanyGroups'
import { EconomySet } from 'types/GlobalEconomySets'
import { EconomyAccountMap } from './account_dialogs/utils'

interface EconomyTemplateProps {
  economySets: EconomySet[]
  isManagementCompany: boolean
  kpis: KpiTemplate[]
  kpiVariableOptions: KpiVariableOption[]
  onCreateKpi: (body: {
    name: string
    unit: KPI_UNIT
    blocks: PutKpiTemplateCalculationBlock
  }) => void
  onCreateVariable: (
    name: string,
    level: number,
    parentKpiVariableId?: string
  ) => void
  onDeleteKpi: (id: string) => void
  onDeleteVariable: (id: string, callback: (err?: boolean) => void) => void
  onUpdateKpi: (
    id: string,
    body: { name: string; blocks: PutKpiTemplateCalculationBlock }
  ) => void
  onUpdateVariable: (
    id: string,
    name: string,
    callback: (err?: boolean) => void
  ) => void
  onUpdateVariableAccounts: (
    accounts: string[],
    economySetId: string,
    variableId: string,
    callback: (err?: boolean) => void
  ) => void
  variables: KpiVariable[]
}

const EconomyTemplate = (props: EconomyTemplateProps) => {
  const {
    economySets,
    isManagementCompany,
    kpis,
    kpiVariableOptions,
    onCreateKpi,
    onDeleteKpi,
    onUpdateKpi,
    onCreateVariable,
    onDeleteVariable,
    onUpdateVariable,
    onUpdateVariableAccounts,
    variables
  } = props
  const [editVariable, setEditVariable] = useState<{
    economySetId: string
    variableId: string
    selectedAccounts: string[]
    limitToAccounts?: string[]
  } | null>(null)

  const levelZeroVariables = useMemo(
    () => variables.filter((v) => v.level === 0),
    [variables]
  )

  const accountsMap = useMemo(() => {
    const map: EconomyAccountMap = {}

    economySets.forEach((set) => {
      map[set.id] = {}

      set.accounts.forEach((acc) => {
        if (acc.name) {
          map[set.id][acc.number] = acc.name
        }
      })
    })

    return map
  }, [economySets])

  return (
    <>
      <CollapsibleSection
        title="Nyckeltal"
        size="medium"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
      >
        <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
          Definiera anpassade nyckeltal inom ekonomi. De här nyckeltalen kan
          aktiveras under <code>Ekonomi &gt; Anpassade</code> när du lägger till
          ett nyckeltal i en dashboard.
        </Text>
        <KpiSection
          kpis={kpis}
          onCreateKpi={onCreateKpi}
          onDeleteKpi={onDeleteKpi}
          onUpdateKpi={onUpdateKpi}
          variables={variables}
        />
      </CollapsibleSection>
      <CollapsibleSection
        title="Kontonivåer"
        size="medium"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
      >
        <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
          Definiera anpassade kontonivåer inom ekonomi med referenser till
          specifika konton. Den första kontonivån kan användas ovan i de
          anpassade nyckeltalens formler.
        </Text>
        <VariablesSection
          allVariables={variables}
          kpis={kpis}
          level={0}
          onCreateVariable={(
            name: string,
            level: number,
            parentId?: string
          ) => {
            onCreateVariable(name, level, parentId)
          }}
          onDeleteVariable={onDeleteVariable}
          onUpdateVariable={onUpdateVariable}
          parentVariable={null}
          variables={levelZeroVariables}
        />
        <Box sx={{ mt: 4 }}>
          <SectionHeader title="Kontoreferenser" divider={true} />
          <Text color="text.secondary" variant="body2" sx={{ my: 2 }}>
            Lägg till kontoreferenser till kontonivåerna ovan.
          </Text>
          {isManagementCompany ? (
            economySets.map((economySet, i) => {
              return (
                <CollapsibleSection
                  key={economySet.id}
                  defaultCollapsed={i !== 0}
                  title={economySet.name}
                  size="small"
                  variant="outlined"
                >
                  <VariablesAccountsSection
                    accountsMap={accountsMap}
                    allVariables={variables}
                    economySetId={economySet.id}
                    editVariable={editVariable}
                    kpiVariableOptions={kpiVariableOptions}
                    level={0}
                    onUpdateVariableAccounts={onUpdateVariableAccounts}
                    setEditVariable={setEditVariable}
                    variables={levelZeroVariables}
                  />
                </CollapsibleSection>
              )
            })
          ) : (
            <VariablesAccountsSection
              accountsMap={accountsMap}
              allVariables={variables}
              economySetId={economySets[0].id}
              editVariable={editVariable}
              kpiVariableOptions={kpiVariableOptions}
              level={0}
              onUpdateVariableAccounts={onUpdateVariableAccounts}
              setEditVariable={setEditVariable}
              variables={levelZeroVariables}
            />
          )}
        </Box>
      </CollapsibleSection>
      {/* ----- Dialogs ----- */}
      {editVariable ? (
        <EditVariableAccountsDialog
          accountsMap={accountsMap}
          economySetId={editVariable.economySetId as string}
          limitToAccounts={editVariable.limitToAccounts}
          open={Boolean(editVariable)}
          onClose={() => setEditVariable(null)}
          onSave={(accounts, callback) => {
            onUpdateVariableAccounts(
              accounts,
              editVariable.economySetId,
              editVariable.variableId,
              (err) => {
                if (!err) {
                  setEditVariable(null)
                }
                callback()
              }
            )
          }}
          selectedAccounts={editVariable.selectedAccounts}
        />
      ) : null}
    </>
  )
}

export default EconomyTemplate
