import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/connections/source-tables`

export async function create(body) {
  return Axios.post(URL, body, await Functions.parseAuthenticationHeader())
}

export async function update(id, body) {
  const url = `${URL}/${id}`

  return Axios.patch(url, body, await Functions.parseAuthenticationHeader())
}

export async function getPreview(id, limit = 10, offset = 0) {
  return Axios.get(
    `${URL}/${id}?limit=${limit}&offset=${offset}`,
    await Functions.parseAuthenticationHeader()
  )
}

export async function getLogs(id, limit = 10, offset = 0) {
  return Axios.get(
    `${URL}/${id}/logs?limit=${limit}&offset=${offset}`,
    await Functions.parseAuthenticationHeader()
  )
}

export async function getStreamPreview(
  id,
  streamTableId,
  limit = 10,
  offset = 0
) {
  return Axios.get(
    `${URL}/${id}/stream-tables/${streamTableId}?limit=${limit}&offset=${offset}`,
    await Functions.parseAuthenticationHeader()
  )
}

export async function putColumnsAndFilters(id, body) {
  const url = `${URL}/${id}`

  return Axios.put(url, body, await Functions.parseAuthenticationHeader())
}

export async function putStreamVariables(id, body) {
  const url = `${URL}/${id}/stream-variables`

  return Axios.put(url, body, await Functions.parseAuthenticationHeader())
}

export async function createStreamTable(id, body) {
  const url = `${URL}/${id}/stream-tables`

  return Axios.post(url, body, await Functions.parseAuthenticationHeader())
}

export async function updateSourceColumn(id, columnId, body) {
  const url = `${URL}/${id}/source-columns/${columnId}`

  return Axios.patch(url, body, await Functions.parseAuthenticationHeader())
}

export async function deleteStreamTable(id, sourceTableId) {
  const url = `${URL}/${sourceTableId}/stream-tables/${id}`

  return Axios.delete(url, await Functions.parseAuthenticationHeader())
}
