import React from 'react'

import { translateCategory } from 'utils/enumTranslator'
import { DashboardGroup } from 'redux/reducers/Dashboards'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Box from 'components_new/atoms/Box'

import ThumbnailList from 'components_new/molecules/ThumbnailList'

import SortimentItem from 'components_new/organisms/SortimentItem'
import { getThumbnail } from 'utils/functions'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'
import ThumbnailButton from 'components_new/molecules/ThumbnailButton'

interface SortimentViewProps {
  createDashboard: (id?: string) => void
  isHomepal: boolean
  limitReached: boolean
  onOrder: (id: string) => void
  setTemplate: (param: DashboardGroup) => void
  templates: DashboardGroup[]
}

const SortimentView = (props: SortimentViewProps) => {
  const {
    createDashboard,
    isHomepal,
    limitReached,
    onOrder,
    setTemplate,
    templates
  } = props

  return (
    <>
      <Box
        sx={{
          flex: 1
        }}
      >
        <ThumbnailList
          firstChild={
            <Tooltip
              title={
                limitReached ? <DashboardLimitReachedTooltip /> : undefined
              }
            >
              <ThumbnailButton
                color="primary"
                disabled={limitReached}
                iconName="DashboardCustomizeOutlined"
                onClick={() => createDashboard()}
              >
                Tom dashboard
              </ThumbnailButton>
            </Tooltip>
          }
        >
          {templates
            .sort((a, b) => {
              if (a.category.selected && b.category.selected) {
                if (
                  translateCategory[a.category.selected]?.title ===
                  translateCategory[b.category.selected]?.title
                ) {
                  return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
                }

                return translateCategory[a.category.selected]?.title >
                  translateCategory[b.category.selected]?.title
                  ? 1
                  : -1
              }

              return 1
            })
            .map((template, i) => (
              <SortimentItem
                body={documentToReactComponents(
                  template.cms_data?.fields.summary
                )}
                category={template.category.selected}
                isHomepal={isHomepal}
                limitReached={limitReached}
                onCreate={() => createDashboard(template.id)}
                onShow={() => setTemplate(template)}
                onOrder={() => onOrder(template.title)}
                src={getThumbnail(template.thumbnail)}
                title={template.title}
                key={i}
              />
            ))}
        </ThumbnailList>
      </Box>
    </>
  )
}

const DashboardLimitReachedTooltip = () => {
  return (
    <>
      <Text variant="body2" color="inherit" gutterBottom>
        <b>{'Taket för antal dashboards i din licens är nått. 😔'}</b>
      </Text>
      <Text component="div" variant="body2" gutterBottom>
        {
          'Din organisation har nått taket för antalet dashboards som ingår i er licens.'
        }
      </Text>
      <Text variant="body2" color="inherit" gutterBottom>
        {'Kontakta din systemägare för mer information.'}
      </Text>
    </>
  )
}

export default SortimentView
