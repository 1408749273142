import React, { useEffect, useState, useMemo } from 'react'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'

import { getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import {
  FilterConditionFormatBody,
  FilterPatchBody
} from 'types/GlobalKpiOption'

import Button from 'components_new/atoms/Button'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import NativeFitlerGroupSection from '../NativeFilterGroupSection'

import { formatUpdateFilterBody } from '../utils'

interface FilterGroupDialogProps {
  allFilters: FilterConditionFormatBody[]
  filterOptions: KpiTemplateFilterOptionsBody | null
  onClose: () => void
  open: boolean
  updateFilters: (filters: FilterPatchBody[]) => void
}

const FilterGroupDialog = (props: FilterGroupDialogProps) => {
  const { allFilters, filterOptions, onClose, open, updateFilters } = props
  const [filters, setFilters] = useState<FilterConditionFormatBody[]>([])
  const groupId = useMemo(() => nanoid(), [open])

  useEffect(() => {
    setFilters([])
  }, [open])

  const baseFilter = useMemo(() => {
    const filter = cloneDeep(allFilters[0])

    filter.attribute_option.selected = null
    filter.condition.selected = null
    filter.type.selected = null

    return filter
  }, [allFilters])

  const handleClose = () => {
    onClose()
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <>
            <Button onClick={onClose} variant="text">
              Avbryt
            </Button>
            <Button
              onClick={() => {
                const newFilters = formatUpdateFilterBody([
                  ...allFilters,
                  ...filters
                ])

                updateFilters(newFilters)
                onClose()
              }}
            >
              Spara
            </Button>
          </>
        }
        contentText={
          'De filter du lägger till här kommer bilda en filtergrupp där endast ett av kriterierna behöver uppfyllas.'
        }
        onClose={handleClose}
        open={open}
        title={'Lägg till en filtergrupp'}
        sx={{ zIndex: 1302 }}
      >
        <NativeFitlerGroupSection
          filterGroupId={groupId}
          filterOptions={filterOptions}
          filters={filters.length === 0 ? [baseFilter] : filters}
          updateFilters={(filters) => {
            const newFilters: { [id: string]: FilterConditionFormatBody } = {}

            filters.forEach((filter) => {
              const key = `${filter.attribute_option_id}_${filter.condition}_${filter.type}`

              if (key in newFilters) {
                (newFilters[key].value.selected as string[]).push(
                  filter.value as string
                )
              } else {
                newFilters[key] = {
                  filter_group_id: groupId,
                  attribute_option: {
                    selected: filter.attribute_option_id,
                    options: baseFilter.attribute_option.options
                  },
                  condition: {
                    selected: filter.condition,
                    options: baseFilter.condition.options
                  },
                  type: {
                    selected: filter.type,
                    options: baseFilter.type.options
                  },
                  value: {
                    selected:
                      typeof filter.value === 'string'
                        ? [filter.value]
                        : filter.value
                  },
                  from_value: {
                    selected: filter.from_value || null
                  },
                  to_value: {
                    selected: filter.to_value || null
                  }
                }
              }
            })

            setFilters(Object.values(newFilters))
          }}
        />
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default FilterGroupDialog
