import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const FLOW_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3994/'
    : process.env.REACT_APP_FLOW_API

const URL = `${BASE_URL}v3/tables`

export async function create(body) {
  return Axios.post(URL, body, Functions.parseAuthenticationHeader())
}

export async function update(id, body) {
  return Axios.patch(
    `${URL}/${id}`,
    body,
    Functions.parseAuthenticationHeader()
  )
}

export async function destroy(id) {
  return Axios.delete(`${URL}/${id}`, Functions.parseAuthenticationHeader())
}

export async function putTableFilterVariables(id, body) {
  return Axios.put(
    `${URL}/${id}/table-filter-variables`,
    body,
    Functions.parseAuthenticationHeader()
  )
}

export async function exportDataset(id, query = '') {
  const options = Functions.parseAuthenticationHeader()

  options.responseType = 'blob'

  return Axios.get(`${URL}/${id}/export${query}`, options)
}

/**
 * Fetch data from FLOW API.
 * @param {string} endpoint - API endpoint.
 * (customer_slug/data_product_slug/table_slug)
 * @param {string} query - Query string. (ex. ?limit=10&offset=0)
 * @returns {Promise} - Promise with response data.
 */
export async function getPreview(endpoint, query = '') {
  let parsedQuery = query

  if (query) {
    parsedQuery += '&is_portal=true'
  } else {
    parsedQuery = '?is_portal=true'
  }

  const url = `${FLOW_BASE_URL}${endpoint}${parsedQuery}`
  const response = await Axios.get(url, Functions.parseAuthenticationHeader())

  if (response.status === 206) {
    return Axios.get(response.data.url)
  }

  return response
}

export async function getTransformation(id) {
  return Axios.get(
    `${URL}/${id}/transformation`,
    Functions.parseAuthenticationHeader()
  )
}

export async function getFilterVariables(id) {
  return Axios.get(
    `${URL}/${id}/table-filter-variables`,
    Functions.parseAuthenticationHeader()
  )
}
