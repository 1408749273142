import React, { useRef, useEffect, useState, MouseEventHandler } from 'react'

import { useResizeDetector } from 'react-resize-detector'
import { useTheme } from '@mui/material'

import { TextProps, TextVariantType } from 'components_new/atoms/Text'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

interface OverflowTextProps extends TextProps {
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  tooltip?: boolean
  variant: TextVariantType
}

/**
 * The OverflowText component automatically ellipses
 * the textual content when overflowing as well as
 * displaying informative text when users hover over,
 * focus on, or tap the content.
 */

const OverflowText = (props: OverflowTextProps) => {
  const { tooltip, ...rest } = props

  const boxRef = useRef<any>(null)

  const [isOverflow, setIsOverflow] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)
  const [isDrag, setIsDrag] = useState<boolean>(false)

  const theme = useTheme()
  const font = theme.typography[props.variant]

  const { width, ref } = useResizeDetector({
    handleHeight: false
  })

  useEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth)
    }
  }, [width])

  return (
    <Tooltip
      title={tooltip ? props.children : ''}
      arrow
      open={isOverflow && isHover && !isDrag}
      disableHoverListener
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onDragStart={() => setIsDrag(true)}
      onDragEnd={() => setIsDrag(true)}
    >
      <Box
        ref={(el: HTMLDivElement) => {
          boxRef.current = el
          ref.current = el
        }}
        sx={{
          fontSize: font.fontSize,
          fontWeight: font.fontWeight,
          lineHeight: font.lineHeight,
          color: props.color,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: 0
        }}
      >
        <Text component="span" {...rest}>
          {props.children}
        </Text>
      </Box>
    </Tooltip>
  )
}

OverflowText.defaultProps = {
  tooltip: true
}

export default OverflowText
