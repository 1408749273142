import React, { ElementType, ReactNode } from 'react'
import { Badge as MUIBadge } from '@mui/material'

interface BadgeProps {
  anchorOrigin?: { horizontal: 'left' | 'right'; vertical: 'bottom' | 'top' }
  badgeContent?: ReactNode
  children?: ReactNode
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  component?: ElementType<any>
  invisible?: boolean
  max?: number
  overlap?: 'circular' | 'rectangular'
  showZero?: boolean
  sx?: object
  variant?: 'dot' | 'standard'
}

/**
 * Badge generates a small badge to the top-right
 * of its child(ren).
 */

const Badge = React.forwardRef((props: BadgeProps, ref: any) => (
  <MUIBadge {...props} ref={ref}>
    {props.children}
  </MUIBadge>
))

Badge.displayName = 'Badge'
export default Badge
