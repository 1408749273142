import React from 'react'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { ElementType } from 'types/GlobalWidget'

interface DragItem {
  hidden: boolean
  iconName: IconNames
  index: number
  sx?: object
  showType?: boolean
  title: string
  type?: ElementType
}

const DragItem = (props: DragItem) => {
  const { hidden, iconName, index, showType, title, type } = props

  return (
    <Box
      sx={{
        px: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
    >
      <Tooltip
        title={
          index !== 0 ? (
            ''
          ) : (
            <>
              <Text fontWeight="bold" variant="body2" gutterBottom>
                Det här är ditt huvudnyckeltal.
              </Text>
              <Text variant="body2" gutterBottom>
                Om du lägger till fler nyckeltal så påverkas en del av deras
                egenskaper (till exempel <code>enhet</code>) av ditt
                huvudnyckeltal.
              </Text>
              <Text variant="body2" gutterBottom>
                Ett huvudnyckeltal går inte att radera eller flytta.
              </Text>
            </>
          )
        }
      >
        <Badge color="success" variant="dot" invisible={index !== 0}>
          <Icon name={iconName} color="disabled" />
        </Badge>
      </Tooltip>
      <Text
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        variant="subtitle2"
      >
        {title}
      </Text>
      {showType ? (
        <>
          <Tooltip
            placement={'top'}
            title={type === ElementType.KPI ? 'Nyckeltal' : 'Attribut'}
          >
            <Box>
              <Tag
                startIconName={
                  type === ElementType.KPI
                    ? 'TrendingUpOutlined'
                    : 'SellOutlined'
                }
                color="primary"
              />
            </Box>
          </Tooltip>
          {hidden ? (
            <Tooltip placement={'top'} title={'Kolumnen är dold'}>
              <Box>
                <Tag startIconName={'VisibilityOffOutlined'} color="primary" />
              </Box>
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </Box>
  )
}

export default DragItem
