import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ShortId from 'shortid'
import _ from 'lodash'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'
import Tooltip from 'components_new/atoms/Tooltip'
import Spin from 'components_new/atoms/Spin'

import DropdownButton from 'components/common/DropdownButton'
import Input from 'components/common/Input'
import Modal from 'components/common/Modal'
import Pagination from 'components/common/Pagination'
import Select from 'components/common/Select'
import ThickHeader from 'components/common/ThickHeader'

import AddTag from 'components/containers/DataPlatform/AddTag'

import { Form, Row, Col, Popover } from 'antd'

import * as AlertActions from 'redux/actions/Alert'
import * as LockedModeActions from 'redux/actions/LockedMode'
import * as CmdbRowsActions from 'redux/actions/DataPlatform/cmdb/Rows'
import * as CmdbTableActions from 'redux/actions/DataPlatform/cmdb/Tables'

import * as Constants from 'helpers/Constants'
import * as Functions from 'helpers/Functions'
import * as Conf from './conf.js'

import Styles from './styles.module.css'

const PAGE_LIMIT = 20

const DATA_TYPES = [
  ...Object.keys(Constants.BUILD_FORMATS).map(
    (key) => Constants.BUILD_FORMATS[key]
  )
]

class Spreadsheet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: [],
      rows: [],
      sort: {
        key: null,
        asc: false
      },
      page: 1,
      selected: {
        col: null,
        row: null
      },
      editedRows: {
        deleted: [],
        updated: [],
        created: []
      },
      editedCols: {
        deleted: [],
        updated: [],
        created: []
      },
      validationErrors: [],
      addColumnModal: false,
      editMode: false
    }
  }

  _handleKeyDown(event) {
    const { columns, selected, addColumnModal, editMode } = this.state
    const { keyCode, shiftKey } = event

    // Do not remove !== null, catches 0.
    if (
      editMode &&
      selected.col !== null &&
      selected.row !== null &&
      !addColumnModal
    ) {
      // Different actions depending on if the input is selected or not.
      const inputSelected = document.activeElement.id === 'selected-input-field'

      if (keyCode === 9) {
        const rows = this.getRows()

        // Catch shift key.
        if (shiftKey) {
          let col = selected.col - (1 % columns.length)
          let row = selected.row

          if (selected.col === 0) {
            row =
              selected.row === 0
                ? rows.length - 1
                : selected.row - (1 % rows.length)
            col = columns.length - 1
          }

          this.setState({ selected: { row, col } })
        } else {
          const col = (selected.col + 1) % columns.length
          const row =
            selected.col + 1 >= columns.length
              ? (selected.row + 1) % rows.length
              : selected.row

          this.setState({ selected: { row, col } })
        }

        event.preventDefault()
      } else if (inputSelected) {
        if (keyCode === 13) {
          const rows = this.getRows()

          this.setState({
            selected: { ...selected, row: (selected.row + 1) % rows.length }
          })
        }
      } else if (
        keyCode === 37 ||
        keyCode === 38 ||
        keyCode === 39 ||
        keyCode === 40 ||
        keyCode === 13
      ) {
        const rows = this.getRows()

        // Catch right.
        if (keyCode === 37 && selected.col !== 0) {
          this.setState({ selected: { ...selected, col: selected.col - 1 } })
        }

        // Catch up.
        if (keyCode === 38 && selected.row !== 0) {
          this.setState({ selected: { ...selected, row: selected.row - 1 } })
        }

        // Catch left.
        if (keyCode === 39 && selected.col < columns.length - 1) {
          this.setState({ selected: { ...selected, col: selected.col + 1 } })
        }

        // Catch down.
        if (keyCode === 40 && selected.row < rows.length - 1) {
          this.setState({ selected: { ...selected, row: selected.row + 1 } })
        }

        if (keyCode === 13) {
          const input = document.getElementById('selected-input-field')

          if (input) {
            input.focus()
          }
        }

        event.preventDefault()
      }
    }
  }

  componentDidMount() {
    const { CmdbRowStore, CmdbTableStore, match } = this.props

    document.addEventListener('keydown', (event) => this._handleKeyDown(event))

    this.setState({
      columns: Conf.parseColumns(
        CmdbTableStore.data[match.params.cmdbId].attributes.columns
      ),
      rows: Object.values(CmdbRowStore.data[match.params.cmdbId].data)
    })
  }

  componentDidUpdate(prevProps) {
    const { editMode, CmdbRowStore, CmdbTableStore, match } = this.props
    const { sort } = this.state
    const newState = {}

    const rows = Object.values(CmdbRowStore.data[match.params.cmdbId].data)
    const prevRows = Object.values(
      prevProps.CmdbRowStore.data[match.params.cmdbId].data
    )
    const columns = Conf.parseColumns(
      CmdbTableStore.data[match.params.cmdbId].attributes.columns
    )
    const prevColumns = Conf.parseColumns(
      prevProps.CmdbTableStore.data[match.params.cmdbId].attributes.columns
    )

    if (editMode && !prevProps.editMode) {
      newState.selected = { col: 0, row: 0 }
    } else if (!editMode && prevProps.editMode) {
      this.resetSheet(false)
    }

    if (!_.isEqual(rows, prevRows)) {
      newState.rows = Functions.sort(rows, sort.key, sort.asc)
    }

    if (!_.isEqual(columns, prevColumns)) {
      newState.columns = Conf.sortColumns(columns)
    }

    if (
      (editMode && !prevProps.editMode) ||
      !_.isEqual(rows, prevRows) ||
      !_.isEqual(columns, prevColumns)
    ) {
      this.setState(newState)
    }

    this.onRegisterChange()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (event) =>
      this._handleKeyDown(event)
    )
  }

  onChangeCell(index, columnName, value) {
    const { editedRows, rows, columns, validationErrors, page } = this.state
    const rowIndex = index + (page - 1) * 20
    const id = rows[rowIndex].id
    const action = rows[rowIndex].created ? 'created' : 'updated'
    const column = columns.find(
      (col) => col.attributes.column_name === columnName
    )

    const newEditedRows = _.cloneDeep(editedRows)
    const previouslyChanged = newEditedRows[action].find((row) => row.id === id)

    if (previouslyChanged) {
      previouslyChanged.attributes[columnName] = value
    } else {
      newEditedRows[action].push({
        ...rows[rowIndex],
        attributes: {
          ...rows[rowIndex].attributes,
          [columnName]: value
        }
      })
    }

    let newValidationErrors = _.cloneDeep(validationErrors)

    // Validates a cell if regex is present for the column.
    if (column.regex && value && !column.regex.test(value)) {
      if (
        !validationErrors.find(
          (error) => error.col === column.id && error.row === id
        )
      ) {
        newValidationErrors.push({
          col: column.id,
          row: id,
          label: column.typeLabel
        })
      }
    } else {
      newValidationErrors = validationErrors.filter(
        (error) => error.col !== column.id || error.row !== id
      )
    }

    const newRows = _.cloneDeep(rows)

    newRows[rowIndex].attributes[columnName] = value

    this.setState({
      validationErrors: newValidationErrors,
      rows: newRows,
      editedRows: newEditedRows
    })
  }

  onSort(columnName) {
    const { sort, rows } = this.state
    const asc = sort.key === columnName ? !sort.asc : true
    const newSort = { key: columnName, asc }

    this.setState({
      rows: Functions.sort(rows, columnName, asc),
      sort: newSort
    })
  }

  onRegisterChange() {
    const { LockedModeStore } = this.props
    const { editedRows, editedCols } = this.state

    if (
      !_.isEmpty([
        ...editedCols.deleted,
        ...editedCols.updated,
        ...editedCols.created
      ]) ||
      !_.isEmpty([
        ...editedRows.deleted,
        ...editedRows.updated,
        ...editedRows.created
      ])
    ) {
      if (!LockedModeStore.locked) {
        this.props.setLockedMode({ locked: true })
      }
    } else {
      if (LockedModeStore.locked) {
        this.props.setLockedMode({ locked: false })
      }
    }
  }

  onSave() {
    const { CmdbTableStore, match } = this.props
    const { editedRows, editedCols, validationErrors, rows, sort } = this.state

    if (validationErrors.length === 0) {
      this.onRegisterChange()

      this.setEditModeLocked(false)

      this.setState({
        rows: Functions.sort(rows, sort.key, sort.asc),
        selected: { row: null, col: null },
        editedRows: {
          deleted: [],
          updated: [],
          created: []
        },
        editedCols: {
          deleted: [],
          updated: [],
          created: []
        },
        validationErrors: []
      })

      const columns = Conf.parseColumns(
        CmdbTableStore.data[match.params.cmdbId].attributes.columns
      )

      Conf.save(editedRows, editedCols, columns, (data) =>
        this.props.trySaveRegisterChanges(match.params.cmdbId, data)
      )
    } else {
      this.props.setAlert({
        header: 'Formatfel',
        contentHeader: 'Fel format på data',
        faIcon: 'faExclamationCircle',
        content:
          // eslint-disable-next-line max-len
          'Datan i markerade celler uppfyller inte de formatkrav du satt på registret.\nÄndra till rätt format för att kunna spara.',
        okText: 'Stäng',
        onOk: () => this.props.resetAlert()
      })
    }
  }

  resetSheet(setMode = true) {
    const { CmdbTableStore, CmdbRowStore, match } = this.props

    if (setMode) {
      this.setEditModeLocked(false)
    }

    this.setState({
      columns: Conf.parseColumns(
        CmdbTableStore.data[match.params.cmdbId].attributes.columns
      ),
      rows: Object.values(CmdbRowStore.data[match.params.cmdbId].data),
      selected: { row: null, col: null },
      editedRows: {
        deleted: [],
        updated: [],
        created: []
      },
      editedCols: {
        deleted: [],
        updated: [],
        created: []
      },
      validationErrors: []
    })
  }

  addRow() {
    const { rows, columns, editedRows, page } = this.state

    const row = {
      id: ShortId.generate(),
      attributes: {},
      created: true
    }

    columns.forEach((col) => (row.attributes[col.attributes.column_name] = ''))

    editedRows.created.push(row)

    const newRows = _.cloneDeep(rows)

    newRows.splice(PAGE_LIMIT * (page - 1), 0, row)

    this.setState({
      rows: newRows,
      editedRows,
      selected: { row: 0, col: 0 }
    })
  }

  removeRow() {
    const { rows, selected, editedRows, validationErrors, page } = this.state

    const rowObj = rows[selected.row]
    const id = rowObj.id
    const newRows = _.cloneDeep(rows).filter((row) => row.id !== id)

    let newState = { rows: newRows }

    if (rowObj.created) {
      // Ta väck värdet från created, inget har "raderats"
      editedRows.created.splice(
        editedRows.created.findIndex((i) => i.id == id),
        1
      )

      newState = {
        ...newState,
        editedRows,
        validationErrors: validationErrors.filter((err) => err.row !== id)
      }
    } else {
      newState = {
        ...newState,
        editedRows: { ...editedRows, deleted: [...editedRows.deleted, id] },
        validationErrors: validationErrors.filter((err) => err.row !== id)
      }
    }

    // New page is empty, go to previous
    if (this.getRows().length === 1 && page !== 1) {
      newState.page = page - 1
    }

    this.setState({ ...this.state, ...newState })
  }

  getRows() {
    const { rows, page } = this.state

    return rows.slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT)
  }

  addColumn(attributes, form) {
    const { columns, editedCols } = this.state

    const type = DATA_TYPES.find((item) => item.value === attributes.format)

    const col = {
      id: ShortId.generate(),
      attributes: {
        key: ShortId.generate(),
        label: attributes.technical_name,
        column_name: attributes.technical_name,
        type: attributes.format,
        data_type: type.dataType,
        column_index: columns.length
      },
      typeLabel: type.label,
      regex: type.regex,
      input: type.input,
      created: true
    }

    form.resetFields()

    this.closeModal({
      columns: Conf.sortColumns([...columns, col]),
      editedCols: { ...editedCols, created: [...editedCols.created, col] }
    })
  }

  removeColumn() {
    const { selected, columns, editedCols } = this.state

    const removedCol = columns[selected.col]

    let newColumns = _.cloneDeep(columns)
    const newEditedCols = _.cloneDeep(editedCols)

    newColumns.splice(selected.col, 1)

    // Set new indices, we can assume all is sorted on column_index.
    newColumns = newColumns.map((col, index) => ({
      ...col,
      attributes: { ...col.attributes, column_index: index }
    }))

    newEditedCols.updated = newColumns.map((c, i) => {
      const updatedCol = newEditedCols.updated.find((uc) => c.id === uc)

      if (updatedCol) {
        return {
          ...updatedCol,
          attributes: { ...updatedCol.attributes, column_index: i },
          created: true
        }
      }

      return {
        id: c.id,
        attributes: {
          ...c.attributes,
          column_index: i
        }
      }
    })

    if (removedCol.created) {
      newEditedCols.created.splice(
        newEditedCols.created.findIndex((c) => c.id === removedCol.id),
        1
      )

      this.setState({
        columns: Conf.sortColumns(newColumns),
        editedCols: newEditedCols
      })
    } else {
      this.setState({
        columns: Conf.sortColumns(newColumns),
        editedCols: {
          ...newEditedCols,
          deleted: [...editedCols.deleted, removedCol.id]
        }
      })
    }
  }

  closeModal(newState = {}) {
    this.setState({
      addColumnModal: false,
      ...newState
    })
  }

  setEditModeLocked(bool) {
    this.setState({ editMode: bool, selected: { row: 0, col: 0 } })

    let alert = {}

    if (bool) {
      alert = {
        header: 'Överge data',
        content:
          // eslint-disable-next-line max-len
          'Du har osparade ändringar och kommer att förlora alla nya ändringar genom att fortsätta.',
        okText: 'Fortsätt',
        onOk: () => {
          this.setState({ editMode: false })
          this.props.resetAlert()
          this.props.setLockedMode({
            locked: false,
            ignoreId: null,
            alert: {}
          })
        },
        cancelText: 'Avbryt',
        contentHeader: 'Osparade ändringar',
        faIcon: 'faExclamationTriangle'
      }
    }

    this.props.setLockedMode({
      locked: bool,
      ignoreId: bool ? 'edit-container' : null,
      alert
    })
  }

  renderActions() {
    const { setAlert, resetAlert, table } = this.props
    const { selected, columns, rows, editMode } = this.state

    if (editMode) {
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DropdownButton
            className={Styles['action-button']}
            ignoreLockedMode
            items={[
              {
                header: 'rad',
                key: 'dropdown-row-0',
                items: [
                  {
                    label: 'Lägg till rad',
                    onClick: () => this.addRow()
                  },
                  {
                    label: 'Ta bort rad',
                    onClick: () => this.removeRow(),
                    remove: rows.length > 0,
                    disabled: rows.length === 0
                  }
                ]
              }
            ]}
          >
            Rad
          </DropdownButton>
          <DropdownButton
            className={Styles['action-button']}
            ignoreLockedMode
            disabled={table.attributes.is_ontology}
            items={[
              {
                header: 'kolumn',
                key: 'dropdown-column-0',
                items: [
                  {
                    label: 'Lägg till kolumn',
                    onClick: () => this.setState({ addColumnModal: true })
                  },
                  {
                    label: 'Ta bort kolumn',
                    remove: true,
                    onClick: () =>
                      setAlert({
                        header: 'Bekräfta borttagning',
                        contentHeader: 'Ta bort kolumn',
                        content: `Du kommer permanent att förlora all data som finns under ${
                          columns[selected.col].attributes.column_name
                        }.`,
                        faIcon: 'faTrash',
                        okText: 'Ta bort',
                        cancelText: 'Avbryt',
                        onOk: () => {
                          this.removeColumn()
                          resetAlert()
                        }
                      })
                  }
                ]
              }
            ]}
          >
            Kolumn
          </DropdownButton>
          <Button onClick={() => this.resetSheet()} variant="text">
            Avbryt
          </Button>
          <Button
            onClick={() => {
              if (table.attributes.is_ontology) {
                // primary key is always the left-most column
                const primaryKeyAttribute = columns[0].attributes.column_name

                const ids = rows.map((r) => r.attributes[primaryKeyAttribute])
                const uniqueIds = Array.from(new Set(ids))

                // If all ids aren't unique, the user should not be able to save
                if (ids.length === uniqueIds.length) {
                  this.onSave()
                } else {
                  setAlert({
                    header: 'Duplicerade id',
                    contentHeader: 'Du har angett duplicerade id',
                    content: `Ett eller flera id är duplicerade under kolumn ${primaryKeyAttribute.toUpperCase()}.
                    För att kunna spara, se till så att alla id är unika.`,
                    okText: 'OK',
                    onOk: () => resetAlert()
                  })
                }
              } else {
                this.onSave()
              }
            }}
            startIcon={<Icon name="SaveOutlined" />}
          >
            Spara ändringar
          </Button>
        </Box>
      )
    }

    return (
      <Button
        onClick={() => this.setEditModeLocked(true)}
        startIcon={<Icon name="EditOutlined" />}
        variant="text"
      >
        Redigeringsläge
      </Button>
    )
  }

  getHeaderActions(table) {
    const { match } = this.props
    const { editMode, rows } = this.state

    if (editMode) {
      return []
    }

    return [
      <ExportDropdown
        key={'header-actions-0'}
        onExport={(type) =>
          this.props.tryExportData(match.params.cmdbId, type, table.name)
        }
        canExport={rows.length > 0}
      />
    ]
  }

  render() {
    const {
      columns,
      rows,
      sort,
      page,
      selected,
      validationErrors,
      addColumnModal,
      editMode
    } = this.state
    const { loading, match, CmdbTableStore } = this.props

    const table = CmdbTableStore.data[match.params.cmdbId].attributes
    const thickHeaderActions = this.getHeaderActions(table)

    return (
      <Paper sx={{ mt: 2, p: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Data</h2>
          {this.renderActions()}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <ThickHeader
            header={table.technical_name}
            actions={thickHeaderActions}
            tag={<AddTag referenceId={match.params.cmdbId} ignoreLockedMode />}
          />
          <Box
            sx={{
              display: 'flex',
              overflow: 'scroll',
              position: 'relative',
              width: '100%'
            }}
          >
            <SpreadListCount
              rows={this.getRows()}
              start={(page - 1) * PAGE_LIMIT}
              selected={selected}
            />
            <SpreadListColumns
              loading={loading}
              columns={columns}
              rows={loading ? [] : this.getRows()}
              editMode={editMode}
              onChangeCell={(index, columnName, value) =>
                this.onChangeCell(index, columnName, value)
              }
              sort={sort}
              onSort={(column) => this.onSort(column)}
              selected={selected}
              setSelectedCell={(col, row) =>
                this.setState({ selected: { col, row } })
              }
              validationErrors={validationErrors}
              history={this.props.history}
            />
          </Box>
          {loading ? (
            <Box
              sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin color="primary" />
            </Box>
          ) : null}
          {editMode ? (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                top: 0
              }}
            >
              <Chip
                icon={<Icon name="EditOutlined" />}
                label="Redigeringsläge"
              />
            </Box>
          ) : null}
        </Box>

        <Pagination
          perPage={PAGE_LIMIT}
          text={'rader'}
          onChange={(value) => this.setState({ page: value })}
          totalItems={rows.length}
          page={page}
        />

        <ColumnModal
          addColumnModal={addColumnModal}
          closeModal={() => this.closeModal()}
          addColumn={(attributes, form) => this.addColumn(attributes, form)}
          columns={columns}
        />
      </Paper>
    )
  }
}

const ExportDropdown = ({ onExport, canExport }) => {
  if (!onExport) {
    return null
  }

  const className = `${Styles['export-item']} ${
    canExport ? '' : Styles['disabled']
  }`

  const content = (
    <div>
      <p
        onClick={() => (canExport ? onExport('xlsx') : null)}
        className={className}
      >
        Exportera som .xlsx
      </p>
      <p
        onClick={() => (canExport ? onExport('csv') : null)}
        className={className}
      >
        Exportera som .csv
      </p>
    </div>
  )

  return (
    <Popover content={content} placement={'bottom'}>
      <IconButton edge="end">
        <Icon name="DownloadOutlined" />
      </IconButton>
    </Popover>
  )
}

const SpreadListCount = ({ start, rows, selected }) => {
  return (
    <div>
      <div className={Styles['column-tag-container']} />
      <div
        className={`${Styles['counter-container']} ${Styles['counter-container-not-last']}`}
      ></div>
      {rows.map((row, index) => {
        const key = `counter-${start + index}`
        const last = index === rows.length - 1
        let container = Styles['counter-container']

        if (!last) {
          container += ` ${Styles['counter-container-not-last']}`
        }

        if (selected.row === index) {
          container += ` ${Styles['counter-container-selected']}`
        }

        return (
          <div key={key} className={container}>
            {start + index + 1}
          </div>
        )
      })}
    </div>
  )
}

const ColumnModal = ({ addColumnModal, closeModal, addColumn, columns }) => {
  const [form] = Form.useForm()

  return (
    <Modal
      className={'ignore-locked-mode'}
      size={'small'}
      visible={addColumnModal}
      onClose={() => {
        form.resetFields()
        closeModal()
      }}
      header={'Lägg till kolumn'}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={(attributes) => addColumn(attributes, form)}
      >
        <>
          <Form.Item
            label={'Tekniskt namn'}
            rules={[
              { required: true, message: 'Var god fyll i ett tekniskt namn' },
              () => ({
                validator(_, value) {
                  const sameCols = columns.filter(
                    (c) => c.attributes.column_name === value
                  )

                  if (sameCols.length > 0) {
                    return Promise.reject('Det tekniska namnet finns redan')
                  }

                  return Promise.resolve()
                }
              })
            ]}
            name="technical_name"
          >
            <Input placeholder={'Tekniskt namn'} />
          </Form.Item>
          <Form.Item
            label={'Format'}
            name="format"
            rules={[{ required: true, message: 'Var god välj format' }]}
          >
            <Select
              placeholder={'Välj format...'}
              options={DATA_TYPES}
              ignoreLockedMode
            />
          </Form.Item>{' '}
        </>
        <Row className={Styles['button-row']} gutter={8} align={'end'}>
          <Col>
            <Button
              onClick={() => {
                form.resetFields()
                closeModal()
              }}
              variant="text"
            >
              Avbryt
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="submit">
                {addColumnModal ? 'Lägg till' : 'Uppdatera'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const SpreadListColumns = ({
  editMode,
  columns,
  rows,
  onChangeCell,
  sort,
  onSort,
  selected,
  setSelectedCell,
  validationErrors
}) => {
  return (
    <div className={Styles['inner-container']}>
      {columns.map((item, colIndex) => {
        const cells = rows.map((row) => {
          return {
            id: row.id,
            value: row.attributes[item.attributes.column_name]
          }
        })

        let columnContainer = Styles['column-item-container']
        const colSelect = selected.col === colIndex

        if (colSelect) {
          columnContainer += ` ${Styles['column-item-container-selected']}`

          if (item.input === 'date') {
            columnContainer += ` ${Styles['column-item-container-selected-date']}`
          }
        }

        return (
          <div
            className={Styles['outer-column-container']}
            key={item.attributes.key}
          >
            <div className={Styles['column-tag-container']}>
              {item.created ? null : (
                <AddTag ignoreLockedMode referenceId={item.id} />
              )}
            </div>
            <div
              className={`${Styles['column-container']} ${
                colSelect ? Styles['column-container-focused'] : ''
              }`}
              key={item.attributes.key}
            >
              <div
                className={columnContainer}
                onClick={() => onSort(item.attributes.column_name)}
              >
                {sort.key === item.attributes.column_name ? (
                  <Icon
                    fontSize="small"
                    name={sort.asc ? 'ArrowDropUp' : 'ArrowDropDown'}
                  />
                ) : null}
                {item.attributes.column_name}
              </div>
              {cells.map((cell, rowIndex) => {
                const key = `${item.attributes.key}-${cell.id}-${rowIndex}`
                const last = rowIndex === cells.length - 1
                const isSelected =
                  selected.col === colIndex &&
                  selected.row === rowIndex &&
                  editMode
                const error = validationErrors.find(
                  (error) => error.col === item.id && error.row === cell.id
                )

                let rowContainer = Styles['row-item-container']

                if (!last) {
                  rowContainer += ` ${Styles['row-item-container-not-last']}`
                }

                if (error && !isSelected) {
                  rowContainer += ` ${Styles['row-item-error']}`
                }

                if (isSelected) {
                  rowContainer += ` ${Styles['row-item-selected-position']}`
                }

                return (
                  <div
                    key={key}
                    onClick={
                      editMode
                        ? () => setSelectedCell(colIndex, rowIndex)
                        : () => {}
                    }
                    className={rowContainer}
                  >
                    {error ? (
                      <Tooltip title={error.label}>
                        <Box
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0
                          }}
                        >
                          <Icon
                            color="error"
                            fontSize="small"
                            name="ErrorOutline"
                          />
                        </Box>
                      </Tooltip>
                    ) : null}
                    {parseCell(
                      isSelected,
                      item,
                      cell,
                      rowIndex,
                      editMode,
                      onChangeCell
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}

      <div className={Styles['column-filler-container']}>
        <div className={Styles['column-tag-container']} />
        <div
          className={`${Styles['filler-item']} ${Styles['filler-item-column']}`}
        ></div>
        {rows.map((row, index) => (
          <div key={`filler-${index}`} className={Styles['filler-item']}></div>
        ))}
      </div>
    </div>
  )
}

const parseCell = (
  isSelected,
  item,
  cell,
  rowIndex,
  editMode,
  onChangeCell
) => {
  if (item.input === 'bool') {
    let cellLabel = cell.value

    if (cellLabel === true) {
      cellLabel = 'Sant'
    } else if (cellLabel === false) {
      cellLabel = 'Falskt'
    }

    return isSelected ? (
      <select
        id={'selected-select-field'}
        className={`${Styles['row-item-selected']}`}
        value={cell.value}
        onChange={(e) =>
          onChangeCell(
            rowIndex,
            item.attributes.column_name,
            e.target.value === 'false' ? false : true
          )
        }
        disabled={!editMode}
      >
        <option value="" selected disabled hidden>
          Välj värde
        </option>
        <option value={true}>Sant</option>
        <option value={false}>Falskt</option>
      </select>
    ) : (
      <p className={Styles['row-item-inner-container']}>{cellLabel}</p>
    )
  }

  return isSelected ? (
    <input
      id={'selected-input-field'}
      className={`${Styles['row-item-selected']}`}
      value={cell.value === null ? '' : cell.value}
      onChange={(e) =>
        onChangeCell(rowIndex, item.attributes.column_name, e.target.value)
      }
      disabled={!editMode}
      type={item.input || ''}
    />
  ) : React.isValidElement(cell.value) ? (
    <div className={Styles['row-item-inner-container']}>{cell.value}</div>
  ) : (
    <p className={Styles['row-item-inner-container']}>{cell.value}</p>
  )
}

function mapStateToProps({
  CmdbRowStore,
  CmdbTableStore,
  AlertStore,
  LockedModeStore
}) {
  return {
    CmdbRowStore,
    CmdbTableStore,
    AlertStore,
    LockedModeStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertActions,
      ...LockedModeActions,
      ...CmdbRowsActions,
      ...CmdbTableActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Spreadsheet))
