import React, { MouseEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { DashboardStatus } from 'redux/reducers/DashboardTemplates'
import { DashboardType } from 'redux/reducers/Dashboards'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { translateDashboardStatus } from 'utils/enumTranslator'

import EditTemplateDialog from '../dialogs/EditTemplateDialog'

import Loading from './loading'

interface PublishChipProps {
  sx?: object
}

const PublishChip = (props: ComponentProps) => {
  const { AuthStore, KPIDashboardStore, sx, tryUpdateDashboardGroup } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)
  }

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  const isHomepal = AuthStore.user?.is_homepal_user

  const [dialog, setDialog] = useState<boolean>(false)

  if (!isHomepal) return null

  // loading
  if (!KPIDashboardStore.fetched || !Boolean(dashboard) || !Boolean(group)) {
    return <Loading />
  }

  // success
  return (
    <>
      <Chip
        color={translateDashboardStatus[group?.status].color}
        label={translateDashboardStatus[group?.status].title}
        clickable
        onClick={handleClick}
        onDelete={handleClick}
        deleteIcon={<Icon name="ExpandMore" />}
        size="small"
        sx={sx}
      />
      <ThemeProvider theme={getTheme('light')}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <ListItem>
            <Text variant="caption" color="text.secondary">
              Ändra status till
            </Text>
          </ListItem>
          <Tooltip
            title={
              <>
                Det går inte att publicera en mall utan att ha valt en kategori.
                <br />
                <Button
                  onClick={() => setDialog(true)}
                  size="small"
                  sx={{ my: 1 }}
                  variant="text"
                >
                  Välj kategori
                </Button>
              </>
            }
            disableHoverListener={
              dashboard?.type === DashboardType.DEFAULT ||
              Boolean(group?.category.selected)
            }
          >
            <MenuItem
              disabled={!Boolean(group?.category.selected) && !isHomepal}
              onClick={(event) => {
                tryUpdateDashboardGroup(group?.id, {
                  data: { status: DashboardStatus.PUBLISHED }
                })
                handleClose(event)
              }}
              onMouseDown={(event) => event.stopPropagation()}
              key={0}
            >
              {translateDashboardStatus[DashboardStatus.PUBLISHED].title}
            </MenuItem>
          </Tooltip>
          <MenuItem
            onClick={(event) => {
              tryUpdateDashboardGroup(group?.id, {
                data: { status: DashboardStatus.DRAFT }
              })
              handleClose(event)
            }}
            onMouseDown={(event) => event.stopPropagation()}
            key={1}
          >
            {translateDashboardStatus[DashboardStatus.DRAFT].title}
          </MenuItem>
        </Menu>
      </ThemeProvider>

      {/*-- dialogs --*/}
      <EditTemplateDialog
        category={group?.category.selected || null}
        categoryOptions={group?.category.options}
        description={group?.description}
        open={dialog}
        onSubmit={(data) =>
          tryUpdateDashboardGroup(group?.id, {
            data
          })
        }
        onClose={() => setDialog(false)}
        id={group?.id}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...CompanyGroupActions,
      ...DashboardActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & PublishChipProps

export default connector(PublishChip)
