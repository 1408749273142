import React, { useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import Box from 'components_new/atoms/Box'
import { FunnelStage, FunnelStagePutBody } from 'types/GlobalWidget'
import { cloneDeep } from 'lodash'
import ReOrderItem from '../ReOrderItem'
import DndList from 'components_new/molecules/DndList'

interface EditDialogProps {
  funnelStages: FunnelStage[]
  updateEvents: (body: FunnelStagePutBody) => void
  onClose: () => void
  open: boolean
}

const EditDialog = (props: EditDialogProps) => {
  const { funnelStages, onClose, open, updateEvents } = props

  const [tempEvents, setTempEvents] = useState<FunnelStage[]>([])

  useEffect(() => {
    setTempEvents(funnelStages.map((value) => ({ ...value })))
  }, [funnelStages])

  const handleUpdate = () => {
    const body = tempEvents.map((event) => ({
      status: event.status,
      hidden: event.hidden,
      title: event.title
    }))

    updateEvents(body)
    onClose()
  }

  const handleCancel = () => {
    setTempEvents(funnelStages.map((value) => ({ ...value })))
    onClose()
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        title="Redigera ordning"
        open={open}
        onClose={handleCancel}
        actions={
          <>
            <Button variant="text" onClick={handleCancel}>
              Avbryt
            </Button>
            <Button onClick={handleUpdate} variant="contained">
              Spara
            </Button>
          </>
        }
        sx={{ zIndex: 1400 }}
      >
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            minWidth: 0,
            overflow: 'hidden'
          }}
        >
          <DndList
            items={tempEvents.map((item, i) => {
              return {
                disabled: false,
                id: item.title,
                content: (
                  <Box
                    key={item.title}
                    sx={{
                      height: '100%',
                      minHeight: 100,
                      width: '100%',
                      minWidth: 0,
                      p: 0.5
                    }}
                  >
                    <ReOrderItem
                      editable={true}
                      hidden={item.hidden}
                      setHidden={(hidden) => {
                        const newEvents = cloneDeep(tempEvents)

                        newEvents[i].hidden = hidden

                        setTempEvents(newEvents)
                      }}
                      status={item.status}
                      setStatus={(status) => {
                        const newEvents = cloneDeep(tempEvents)

                        newEvents[i].status = status

                        setTempEvents(newEvents)
                      }}
                      title={item.title}
                    />
                  </Box>
                )
              }
            })}
            handleDragEnd={(newOrder) => {
              setTempEvents(
                newOrder.map(
                  (title) =>
                    funnelStages.find((fs) => fs.title === title) as FunnelStage
                )
              )
            }}
            horizontal={true}
          />
        </Box>
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default EditDialog
