import React, { FC, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import SettingsTemplate from 'components_new/templates/SettingsTemplate'
import EconomyTemplate from 'components_new/templates/settings/Economy'

import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as KpiVariableActions from 'redux/actions/KpiVariables'
import * as KpiVariableOptionActions from 'redux/actions/KpiVariableOptions'
import * as EconomySetActions from 'redux/actions/EconomySets'

import { ApplicationState } from 'redux/Stores/types'
import { KpiTemplateType } from 'types/GlobalKpiTemplates'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  EconomySetStore: state.EconomySetStore,
  KPITemplateStore: state.KPITemplateStore,
  KpiVariableStore: state.KpiVariableStore,
  KpiVariableOptionStore: state.KpiVariableOptionStore,
  NavigationMenuStore: state.NavigationMenuStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...KPITemplateActions,
      ...KpiVariableActions,
      ...KpiVariableOptionActions,
      ...EconomySetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type EconomyPageProps = ConnectedProps<typeof connector>

const EconomyPage: FC<EconomyPageProps> = (props: EconomyPageProps) => {
  const {
    AuthStore,
    EconomySetStore,
    KPITemplateStore,
    KpiVariableStore,
    KpiVariableOptionStore,
    tryCreateKpiTemplate,
    tryCreateKpiVariable,
    tryDeleteKpiTemplate,
    tryDeleteKpiVariable,
    tryGetAllEconomySets,
    tryGetAllKPIs,
    tryGetAllKpiVariableOptions,
    tryGetAllKpiVariables,
    tryPutKpiVariableOptions,
    tryUpdateKpiVariable,
    tryUpdateKpiTemplate
  } = props

  useEffect(() => {
    if (!KPITemplateStore.fetchingKpis && !KPITemplateStore.fetchedKpis) {
      tryGetAllKPIs()
    }

    if (!KpiVariableStore.fetched) {
      tryGetAllKpiVariables()
    }

    if (!EconomySetStore.fetched && !EconomySetStore.fetching) {
      tryGetAllEconomySets()
    }

    if (!KpiVariableOptionStore.fetched) {
      tryGetAllKpiVariableOptions()
    }
  }, [])

  return (
    <SettingsTemplate>
      {KpiVariableStore.fetched &&
      KPITemplateStore.fetchedKpis &&
      KpiVariableOptionStore.fetched &&
      EconomySetStore.fetched ? (
            <EconomyTemplate
              economySets={Object.values(EconomySetStore.data)}
              // eslint-disable-next-line max-len
              isManagementCompany={!!AuthStore.customer?.is_management_company}
              kpis={Object.values(KPITemplateStore.data).filter(
                (kpi) => kpi.type === KpiTemplateType.CUSTOM
              )}
              kpiVariableOptions={KpiVariableOptionStore.data}
              onCreateKpi={(body) => {
                tryCreateKpiTemplate({ data: body })
              }}
              onCreateVariable={(
                name: string,
                level: number,
                parentKpiVariableId?: string
              ) => {
                tryCreateKpiVariable({
                  name,
                  level,
                  parent_kpi_variable_id: parentKpiVariableId
                })
              }}
              onDeleteKpi={(id) => {
                tryDeleteKpiTemplate(id)
              }}
              onDeleteVariable={(id, callback) => {
                tryDeleteKpiVariable(id, callback)
              }}
              onUpdateKpi={(id, body) => {
                tryUpdateKpiTemplate(id, { data: body })
              }}
              onUpdateVariable={(id, name, callback) => {
                tryUpdateKpiVariable(id, { name }, callback)
              }}
              onUpdateVariableAccounts={(
                accounts,
                economySetId,
                variableId,
                callback
              ) => {
                tryPutKpiVariableOptions(
                  economySetId,
                  variableId,
                  accounts,
                  callback
                )
              }}
              variables={Object.values(KpiVariableStore.data)}
            />
          ) : (
            <LoadingSettings
              titles={[
                'Hämtar information om dina ekonomiska nyckeltal',
                'Sätter upp dina definitioner'
              ]}
            />
          )}
    </SettingsTemplate>
  )
}

export default connector(EconomyPage)
