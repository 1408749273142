import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  feed: {},
  customOntology: {},
  standardOntology: {},
  fetched: false,
  fetchedOne: false,
  fetchedCustomOntology: false,
  fetchedStandardOntology: false,

  activateStandard: false,
  hasStandard: true
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_CUSTOM_ONTOLOGY:
    return {
      ...state,
      fetchedCustomOntology: false
    }

  case Types.GET_CUSTOM_ONTOLOGY_SUCCESS:
    return {
      ...state,
      customOntology: payload,
      fetchedCustomOntology: true
    }

  case Types.GET_STANDARD_ONTOLOGY:
    return {
      ...state,
      fetchedStandardOntology: false
    }

  case Types.GET_STANDARD_ONTOLOGY_SUCCESS:
    return {
      ...state,
      standardOntology: payload,
      fetchedStandardOntology: true
    }

  case Types.GET_STANDARD_ONTOLOGY_FAILED:
    return {
      ...state,
      hasStandard: false
    }

  case Types.PUT_CUSTOM_ONTOLOGY_SUCCESS:
    return {
      ...state,
      customOntology: payload
    }

  case Types.PUT_STANDARD_ONTOLOGY_SUCCESS:
    return {
      ...state,
      standardOntology: payload
    }

  case Types.START_ONTOLOGY_SUCCESS:
    return {
      ...state,
      ...updateOntology(state, payload.ontologyType, payload.data)
    }

  case Types.POLL_ONTOLOGY_SUCCESS:
    return {
      ...state,
      ...updateOntology(state, payload.ontologyType, payload.data)
    }

  case Types.ACTIVATE_STANDARD_SUCCESS:
    return {
      ...state,
      activateStandard: true,
      hasStandard: true
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}

const updateOntology = (state, type, data) => {
  return type === 'CUSTOM'
    ? {
        customOntology: {
          ...state.customOntology,
          attributes: {
            ...state.customOntology.attributes,
            latest_run: data
          }
        }
      }
    : {
        standardOntology: {
          ...state.standardOntology,
          attributes: {
            ...state.standardOntology.attributes,
            latest_run: data
          }
        }
      }
}
