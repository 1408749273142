import React, { ElementType, ReactNode } from 'react'
import { FormControl as MUIFormControl } from '@mui/material'

interface FormControlProps {
  children?: ReactNode
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  component?: ElementType<any>
  disabled?: boolean
  error?: boolean
  focused?: boolean
  fullWidth?: boolean
  hiddenLabel?: boolean
  margin?: 'dense' | 'none' | 'normal'
  required?: boolean
  size?: 'medium' | 'small'
  sx?: object
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * The FormControl component provides context such
 * as filled/focused/error/required for form inputs.
 * Relying on the context provides high flexibility
 * and ensures that the state always stays consistent
 * across the children of the FormControl. This
 * context is used by the following components:
 * - FormLabel
 * - FormHelperText
 * - Input
 * - InputLabel
 *
 * Note: Only one InputBase can be used within a
 * FormControl because it creates visual
 * inconsistencies. For instance, only one input can
 * be focused at the same time, the state shouldn't
 * be shared.
 */

const FormControl = React.forwardRef((props: FormControlProps, ref: any) => (
  <MUIFormControl {...props} ref={ref}>
    {props.children}
  </MUIFormControl>
))

FormControl.displayName = 'FormControl'
export default FormControl
