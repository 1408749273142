import React from 'react'

import { WhoCanView } from 'redux/reducers/Dashboards'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Stack from 'components_new/atoms/Stack'

import { translateWhoCanView } from 'utils/enumTranslator'

interface WhoCanViewItemProps {
  fullRights: boolean
  whoCanView: WhoCanView
}

const WhoCanViewItem = (props: WhoCanViewItemProps) => {
  const { fullRights, whoCanView } = props

  return (
    <ListItem
      dense={true}
      secondaryAction={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Chip label={'Visa'} size="small" />
          {fullRights ? <Box sx={{ width: 36 }} /> : null}
        </Stack>
      }
    >
      <ListItemAvatar sx={{ bgcolor: 'transparent' }}>
        <Avatar sx={{ bgcolor: 'transparent' }}>
          <Icon
            name={translateWhoCanView[whoCanView].iconName}
            color="disabled"
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={translateWhoCanView[whoCanView].title}
        primaryTypographyProps={{ variant: 'subtitle2' }}
      />
    </ListItem>
  )
}

export default WhoCanViewItem
