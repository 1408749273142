import React, { useEffect, useMemo, useState } from 'react'
import { Theme, useTheme } from '@mui/material'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Paper from 'components_new/atoms/Paper'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableFooter from 'components_new/atoms/Table/TableFooter'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import Text from 'components_new/atoms/Text'
import TextField, { TextFieldProps } from 'components_new/atoms/TextField'

import SectionHeader from 'components_new/molecules/SectionHeader'

import { getCategories, getInputRefs } from './utils'
import { SimRenaming } from 'utils/types'

import { Customer } from 'types/GlobalCustomer'

interface SimRenamingProps {
  customer: Customer | null
  terms: {
    [key: string]: SimRenaming
  }
  putTerms: (data: SimRenaming[]) => void
}

const SimRenamingTemplate = (props: SimRenamingProps) => {
  const { customer, putTerms, terms } = props

  const [isEdited, setIsEdited] = useState<boolean>(false)
  const categories = useMemo(() => getCategories(terms), [terms])
  const termInputRefs = getInputRefs(terms)

  // Init
  useEffect(() => {
    reset()
  }, [terms])

  // Actions
  const reset = () => {
    Object.values(terms).forEach((term) => {
      termInputRefs[term.id].current.value = term.modified_name
    })
  }

  const cancel = () => {
    reset()
    setIsEdited(false)
  }

  const save = () => {
    putTerms(
      Object.values(terms).map((term) => ({
        ...term,
        modified_name: termInputRefs[term.id].current.value
      }))
    )
    setIsEdited(false)
  }

  const theme = useTheme()

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: 'section'
      }}
    >
      <SectionHeader divider={true} size="large" title="Terminologi" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}
      >
        <Box sx={{ py: 2, maxWidth: 'content' }}>
          <Text variant="body2" color="text.secondary" gutterBottom>
            Anpassa terminologi så att det matchar de benämningar som används i
            er organisation.
          </Text>
          <Alert severity="info" sx={{ my: 2 }}>
            Om inget annat anges så kommer Homepals benämningar att appliceras.
          </Alert>
        </Box>
        <TableContainer
          sx={{
            height: '75vh',
            maxHeight: '75vh',
            borderWidth: 1,
            borderColor: 'divider',
            borderStyle: 'solid',
            borderRadius: 1
          }}
        >
          <Table size="small" stickyHeader={true}>
            <TableHead
              sx={{
                bgcolor: 'grey.100'
              }}
            >
              <TableRow>
                <TableCell>Homepal</TableCell>
                <TableCell sx={{ width: 12 }}>{''}</TableCell>
                <TableCell>{customer?.name}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((group) => (
                <>
                  <TableRow
                    key={group.category}
                    sx={{
                      position: 'sticky',
                      top: 37,
                      zIndex: 1,
                      bgcolor: 'grey.200'
                    }}
                  >
                    <TableCell colSpan={3}>
                      <SectionHeader
                        size="small"
                        title={group.category}
                        sx={{
                          pt: 0.5,
                          '& .MuiStack-root': { mb: 0.25 }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {group.terms.map((term) => {
                    return (
                      <TermRow
                        key={term.id}
                        term={term}
                        theme={theme}
                        inputRef={termInputRefs[term.id]}
                        setIsEdited={setIsEdited}
                        isEdited={isEdited}
                      />
                    )
                  })}
                </>
              ))}
            </TableBody>
            <TableFooter
              sx={{
                borderTop: '1px solid',
                borderColor: 'divider',
                position: 'sticky',
                bottom: 0,
                zIndex: 2
              }}
            >
              <TableRow>
                <TableCell colSpan={3} sx={{ p: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      bgcolor: 'grey.100'
                    }}
                  >
                    {isEdited ? (
                      <>
                        <Text color="text.secondary">
                          Du har ändringar som måste sparas.
                        </Text>
                        <Button
                          disabled={!isEdited}
                          onClick={cancel}
                          variant="text"
                        >
                          Avbryt
                        </Button>
                      </>
                    ) : null}
                    <Button disabled={!isEdited} onClick={save}>
                      Spara
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}

interface TermRowProps extends TextFieldProps {
  inputRef: React.MutableRefObject<any>
  isEdited: boolean
  setIsEdited: (value: boolean) => void
  term: SimRenaming
  theme: Theme
}

const TermRow = ({
  isEdited,
  theme,
  term,
  setIsEdited,
  ...props
}: TermRowProps) => {
  const [value, setValue] = useState<string>('')

  // required to useMemo because it gets triggered before render
  // where useEffect triggers after render --> reset wont work
  useMemo(() => {
    if (props.inputRef.current.value !== value) {
      setValue(props.inputRef.current.value)
    }
  }, [props.inputRef.current.value])

  return (
    <TableRow key={term.id} hover={true}>
      <TableCell>
        <TextField
          disabled={true}
          InputProps={
            !value
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon color="success" name="Check" />
                    </InputAdornment>
                  )
                }
              : undefined
          }
          size="small"
          value={term.original_name}
          sx={{
            m: 0,
            '& .MuiInputBase-input.Mui-disabled': {
              cursor: 'not-allowed',
              WebkitTextFillColor: !value
                ? theme.palette.success.dark
                : undefined,
              color: !value ? 'success.dark' : undefined
            },
            '& .MuiOutlinedInput-root': !value
              ? {
                  '&.Mui-disabled fieldset': {
                    borderColor: 'success.main'
                  }
                }
              : undefined
          }}
        />
      </TableCell>
      <TableCell sx={{ width: 12 }}>=</TableCell>
      <TableCell>
        <TextField
          {...props}
          InputProps={
            value
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon color="success" name="Check" />
                    </InputAdornment>
                  )
                }
              : undefined
          }
          value={value ?? ''}
          onChange={(e) => {
            setValue(e.target.value)

            if (!isEdited) {
              setIsEdited(true)
            }
          }}
          size="small"
          sx={{
            m: 0,
            '& .MuiInputBase-input': value
              ? {
                  WebkitTextFillColor: theme.palette.success.dark,
                  color: 'success.dark'
                }
              : undefined,
            '& .MuiOutlinedInput-root': value
              ? {
                  '& fieldset': {
                    borderColor: 'success.main'
                  },
                  '&:hover fieldset': {
                    borderColor: 'success.dark'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'success.dark'
                  }
                }
              : undefined
          }}
        />
      </TableCell>
    </TableRow>
  )
}

export default SimRenamingTemplate
