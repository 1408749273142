import React, { MouseEventHandler, ReactNode } from 'react'
import { Drawer as MUIDrawer } from '@mui/material'

interface DrawerProps {
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  children?: ReactNode
  elevation?: number
  hideBackdrop?: boolean
  ModalProps?: object
  onClose?: () => void
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  open?: boolean
  PaperProps?: object
  SlideProps?: object
  sx?: object
  variant?: 'permanent' | 'persistent' | 'temporary'
}

/**
 * [INSERT DESCRIPTION]
 */

const Drawer = React.forwardRef((props: DrawerProps, ref: any) => {
  return (
    <MUIDrawer {...props} ref={ref}>
      {props.children}
    </MUIDrawer>
  )
})

Drawer.displayName = 'Drawer'
export default Drawer
