import React, { ReactNode } from 'react'
import { Collapse as MUICollapse } from '@mui/material'

interface CollapseProps {
  children?: ReactNode
  collapsedSize?: number | string
  in?: boolean
  orientation?: 'horizontal' | 'vertical'
  sx?: object
  timeout?: 'auto' | number
  unmountOnExit?: boolean
}

/**
 * The Collapse component acts as a controlled
 * wrapper for content that should be shown and
 * hidden in a collapsing way.
 */

const Collapse = React.forwardRef((props: CollapseProps, ref: any) => {
  return (
    <MUICollapse {...props} ref={ref}>
      {props.children}
    </MUICollapse>
  )
})

Collapse.displayName = 'Collapse'
export default Collapse
