import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Table from 'components/common/Table'
import Pagination from 'components/common/Pagination'

import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'

import * as Conf from './conf.js'

const Logs = ({ activeTable, tryGetLogs, LogStore }) => {
  useEffect(() => {
    if (activeTable && activeTable.id !== LogStore.currentTableId) {
      tryGetLogs(activeTable.id, LogStore.limit, 0)
    }
  }, [activeTable])

  const changePage = (page) => {
    tryGetLogs(activeTable.id, LogStore.limit, LogStore.limit * (page - 1))
  }

  const currentPage = LogStore.offset / LogStore.limit + 1

  const amountOfRows = LogStore.count

  return (
    <>
      <Table
        loading={LogStore.fetching}
        headers={Conf.TABLE_HEADERS}
        rows={activeTable ? LogStore.data : []}
        ellipsis={false}
        scroll={{ x: 400 }}
      />
      <Pagination
        perPage={LogStore.limit}
        text={'rader'}
        onChange={(newPage) => changePage(newPage)}
        totalItems={amountOfRows}
        page={currentPage}
      />
    </>
  )
}

function mapStateToProps({ LogStore }) {
  return { LogStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SourceTableActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logs))
