import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { DashboardStatus } from 'redux/reducers/DashboardTemplates'
import { DashboardType } from 'redux/reducers/Dashboards'
import { DashboardPostBody } from '../Dashboards'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboards`

export interface DashboardTemplatePatchBody {
  data: {
    title?: string
    subtitle?: string
    description?: string
    status?: DashboardStatus
  }
}

export async function getAll() {
  return axios.get(
    `${URL}?type=${DashboardType.TEMPLATE}`,
    parseAuthenticationHeader()
  )
}

export async function create(body: DashboardPostBody) {
  return axios.post(
    URL,
    {
      data: {
        ...body.data,
        type: DashboardType.TEMPLATE
      }
    },
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: DashboardTemplatePatchBody) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function order(title: string, email: string | undefined) {
  const url =
    'https://hooks.slack.com/services/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'
  const data = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: ':shopping_trolley: Någon har beställt en dashboard!'
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Dashboard: *${title}*`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Konto: *${email}*`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: '<@U032Y48EJPQ>, *markera* detta meddelande när action tagits.'
          }
        ]
      }
    ]
  }

  return axios.post(url, JSON.stringify(data), {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  })
}
