import * as Types from 'redux/Types'
import * as Functions from 'helpers/Functions'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_FORMATS_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload),
      fetched: true
    }
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
