import { parseFilterParams } from 'helpers/Functions/Filters'
import { Filters } from 'utils/types'

export type Header = { id: string; label: string; hidden: boolean }

const parseIgnoreHeaders = (headers: Header[]) => {
  const queryString = 'ignore_attributes='

  const ignoredHeaders = headers.filter((h) => h.hidden).map((h) => h.id)

  if (ignoredHeaders.length > 0) {
    return queryString + ignoredHeaders.join()
  }

  return ''
}

export const parseQueryParams = (filters: Filters, offset: number) => {
  const filterQuery = parseFilterParams(filters)
  const afterOffset = filterQuery ? '&' : ''

  return `offset=${offset}${afterOffset}${filterQuery}`
}

export const parseQueryParamsExport = (filters: Filters, headers: Header[]) => {
  const filterQuery = parseFilterParams(filters)
  const ignoreHeadersQuery = parseIgnoreHeaders(headers)
  const afterFilter = filterQuery && ignoreHeadersQuery ? '&' : ''

  return `${filterQuery}${afterFilter}${ignoreHeadersQuery}`
}

export const getOffset = (page: number) => {
  return (page - 1) * 25
}
