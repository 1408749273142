import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as AuthStoreActions from 'redux/actions/Authentication'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'

import { AccountRole } from 'types/GlobalUser'
import { DashboardGroup } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import OverflowText from 'components_new/molecules/OverflowText'
import DashboardThumbnail from 'components_new/molecules/DashboardThumbnail'
import StandardThumbnail from 'components_new/molecules/StandardThumbnail'
import ThumbnailList from 'components_new/molecules/ThumbnailList'
import TooltipToggleButtonGroup from 'components_new/molecules/TooltipToggleButtonGroup'

import DashboardFolderThumbnail from 'components_new/organisms/DashboardFolderThumbnail'

import { translateDashboardStatus } from 'utils/enumTranslator'

import {
  SortByMethod,
  getEmptyState,
  getPermissions,
  getSelectedGroups,
  groupDashboards
} from './utils'

import {
  getAvailableFolders,
  getAvailableGroups,
  getThumbnail
} from 'utils/functions'

import { sortAlphabeticalAsc, sortDateDesc } from 'utils/sortHelper'

import Loading from './loading'

interface FinderProps {
  sx?: object
}

const Finder = (props: ComponentProps) => {
  const {
    AccountStore,
    AuthStore,
    DashboardFolderStore,
    KPIDashboardStore,
    sx,
    tryPutDashboardFavorite
  } = props

  const history = useHistory()

  // View all available dashboards
  const resetAll = () => {
    history.push('/dashboards')
  }

  const params = useParams<{ folderId?: string }>()
  const selectedFolderId = params.folderId

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  const selectedFolder = folders.find((item) => item.id === selectedFolderId)
  const selectedFavorites = location.pathname === '/dashboards/favorites'

  const userCanEditFolder = Boolean(
    AuthStore.user?.role === AccountRole.ADMIN ||
      AuthStore.user?.is_homepal_user
  )

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)

  const groupedDashboards = groupDashboards(availableGroups, folders)

  const sortByMethod = function (
    a: DashboardGroup,
    b: DashboardGroup,
    method: SortByMethod
  ) {
    switch (method) {
    case SortByMethod.ALPHABETICAL:
      return sortAlphabeticalAsc(a.title, b.title)
    case SortByMethod.LAST_VIEWED:
      return sortDateDesc(
        a.latest_visit ? new Date(a.latest_visit) : null,
        b.latest_visit ? new Date(b.latest_visit) : null
      )
    case SortByMethod.UPDATED_AT:
      return sortDateDesc(new Date(a.updated_at), new Date(b.updated_at))
    default:
      return 0
    }
  }

  // LocalStorage variables
  const [sortBy, setSortByMethod] = useState<SortByMethod>(
    SortByMethod.LAST_VIEWED
  )

  useEffect(() => {
    // List dashboards how
    const listDashboards = localStorage.getItem('listDashboards')

    if (listDashboards) {
      const listDashboardsObject = JSON.parse(listDashboards)

      setSortByMethod(listDashboardsObject.sortBy)
    } else {
      localStorage.setItem(
        'listDashboards',
        JSON.stringify({ sortBy: SortByMethod.LAST_VIEWED as string })
      )
    }
  }, [])

  const updateSortByMethod = (value: SortByMethod) => {
    localStorage.setItem(
      'listDashboards',
      JSON.stringify({ sortBy: value as string })
    )
    setSortByMethod(value)
  }

  // Filtered and sorted dashboards
  const handledDashboards = getSelectedGroups(
    groupedDashboards,
    selectedFavorites,
    selectedFolderId
  ).sort((a, b) => sortByMethod(a, b, sortBy))

  const isHomepal = !!AuthStore.user?.is_homepal_user

  // map users to dashboards
  const dashboardUsers = useMemo(() => {
    if (KPIDashboardStore.fetched && AccountStore.fetched) {
      const dashboardUsers: { [key: string]: string[] } = {}

      Object.values(KPIDashboardStore.data).forEach((dashboard) => {
        const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

        const userNames = getPermissions(
          AccountStore,
          KPIDashboardStore.data,
          dashboard.id,
          AuthStore.user,
          group?.owner
        ).map((item) => item.name)

        if (dashboardUsers[dashboard.dashboard_group_id]) {
          const newPermissions = userNames.filter(
            (perm) =>
              !dashboardUsers[dashboard.dashboard_group_id].includes(perm)
          )

          if (newPermissions.length > 0) {
            dashboardUsers[dashboard.dashboard_group_id].push(...newPermissions)
          }
        } else {
          dashboardUsers[dashboard.dashboard_group_id] = userNames
        }
      })

      return dashboardUsers
    }

    return {}
  }, [KPIDashboardStore.data, AccountStore.data])

  const thumbnailMemo = useMemo(() => {
    const thumbnails: { [id: string]: string } = {}

    availableGroups.forEach((item) => {
      thumbnails[item.id] = getThumbnail(item.thumbnail)
    })

    return thumbnails
  }, [KPIDashboardStore.data])

  const toggleFavorite = (id: string) => {
    tryPutDashboardFavorite(id)
  }

  // loading
  if (
    !AccountStore.fetched ||
    !DashboardFolderStore.fetched ||
    !KPIDashboardStore.fetched
  )
    return <Loading />

  // success
  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          ...sx
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            pb: 1
          }}
        >
          {!selectedFolder ? (
            <OverflowText variant="h5">
              {selectedFavorites ? 'Favoriter' : 'Alla dashboards'}
            </OverflowText>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <OverflowText variant="h5" color="text.secondary">
                <Link to={'/dashboards'}>Alla dashboards</Link>
              </OverflowText>
              <Icon color="disabled" name="NavigateNextOutlined" />
              <OverflowText variant="h5">{selectedFolder.name}</OverflowText>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            position: 'relative',
            overflow: 'auto',
            minHeight: 0,
            p: 2,
            pt: 0
          }}
        >
          {selectedFavorites ||
          Boolean(selectedFolder) ||
          folders.length === 0 ? null : (
              <Box sx={{ my: 3 }}>
                <Text fontWeight="700">Mappar</Text>
                <ThumbnailList>
                  {folders.map((folder) => (
                    <DashboardFolderThumbnail
                      userCanEditFolder={userCanEditFolder}
                      folder={folder}
                      key={folder.id}
                    />
                  ))}
                </ThumbnailList>
              </Box>
            )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            <Text fontWeight="700">Dashboards</Text>
            <Stack spacing={1} alignItems="center">
              <TooltipToggleButtonGroup
                exclusive={true}
                fullWidth={false}
                items={[
                  {
                    value: SortByMethod.LAST_VIEWED,
                    title: <Icon name="History" />,
                    tooltip: (
                      <>
                        Sortera efter <b>Senast besökt</b>
                      </>
                    )
                  },
                  {
                    value: SortByMethod.ALPHABETICAL,
                    title: <Icon name="TextRotationNone" />,
                    tooltip: (
                      <>
                        Sortera efter <b>A-Ö</b>
                      </>
                    )
                  },
                  {
                    value: SortByMethod.UPDATED_AT,
                    title: <Icon name="DateRange" />,
                    tooltip: (
                      <>
                        Sortera efter <b>Senast ändrad</b>
                      </>
                    )
                  }
                ]}
                size="small"
                value={sortBy}
                onChange={(event: any, value: SortByMethod) => {
                  if (value !== null) updateSortByMethod(value)
                }}
                color="primary"
              />
            </Stack>
          </Box>
          <ThumbnailList>
            {handledDashboards.length > 0
              ? handledDashboards.map((group) => (
                <DashboardThumbnail
                  AuthStore={AuthStore}
                  folders={folders}
                  group={group}
                  href={`/dashboards/${group.dashboards[0]}`}
                  users={dashboardUsers?.[group.id] || []}
                  userIsCustomer={userIsCustomer}
                  key={group.id}
                  variant="dashboard"
                  mediaAdornments={
                    isHomepal ? (
                      <Chip
                        color={translateDashboardStatus[group.status].color}
                        label={translateDashboardStatus[group.status].title}
                        size="small"
                      />
                    ) : undefined
                  }
                  src={thumbnailMemo[group.id]}
                  toggleFavorite={
                    toggleFavorite
                      ? () => toggleFavorite(group.id)
                      : undefined
                  }
                />
              ))
              : [
                  <StandardThumbnail
                    key={'placeholder'}
                    disabled={!getEmptyState(selectedFavorites).routeToAll}
                    onClick={() => resetAll()}
                    title={getEmptyState(selectedFavorites).title}
                    body={getEmptyState(selectedFavorites).body}
                    content={
                      <Box
                        sx={{
                          py: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getEmptyState(selectedFavorites).src}
                          width="60%"
                        />
                      </Box>
                    }
                    actions={
                      getEmptyState(selectedFavorites).routeToAll ? (
                        <Button
                          endIcon={<Icon name="ArrowForwardOutlined" />}
                          variant="text"
                        >
                          Gå till Alla dashboards
                        </Button>
                      ) : null
                    }
                  />
                ]}
          </ThumbnailList>
        </Box>
      </Paper>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...AuthStoreActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & FinderProps

export default connector(Finder)
