import React, { useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Alert from 'components_new/atoms/Alert'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import UserListItem from 'components_new/molecules/UserListItem'

import AddAccount from 'components_new/organisms/AddAccount'

import EditUserDialog from '../EditUserDialog'

import { ParsedCompanyGroup } from 'types/GlobalCompanyGroups'
import { Company } from 'types/GlobalCompany'
import { AccountRole, PostUserBody, TRANSLATE_ROLES } from 'types/GlobalUser'
import { AccountType, RoleReducerType } from 'redux/reducers/Accounts'

import IconButton from 'components_new/atoms/IconButton'
import TextField from 'components_new/atoms/TextField'

interface EditCompanyGroupDialogProps {
  addCompany: (companyId: string, callback?: (err?: boolean) => void) => void
  companiesWithoutCompanyGroup: Company[]
  companyGroup: ParsedCompanyGroup | null
  createUser: (body: PostUserBody) => void
  deleteUser: (id: string) => void
  isHomepal?: boolean
  onClose: () => void
  open: boolean
  removeCompany: (companyId: string, callback?: (err?: boolean) => void) => void
  roles: RoleReducerType
  updateCompanyGroup: (
    id: string,
    name?: string,
    economySetId?: string | null,
    callback?: (err?: boolean) => void
  ) => void
}

const EditCompanyGroupDialog = (props: EditCompanyGroupDialogProps) => {
  const {
    addCompany,
    companyGroup,
    createUser,
    deleteUser,
    removeCompany,
    roles,
    updateCompanyGroup
  } = props
  const [addCompanyOpen, setAddCompanyOpen] = useState<boolean>(false)
  const [inviteUserOpen, setInviteUserOpen] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<AccountType | null>(null)
  const [deleteUserAlert, setDeleteUserAlert] = useState<AccountType | null>(
    null
  )
  const [deleteCompany, setDeleteCompany] = useState<Company | null>(null)
  const [addingCompany, setAddingCompany] = useState<string | null>(null)
  const [removingCompany, setRemovingCompany] = useState<boolean>(false)
  const [renameOpen, setRenameOpen] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(companyGroup?.name || '')
  const [renameLoading, setRenameLoading] = useState<boolean>(false)

  const handleCloseRename = () => {
    setRenameOpen(false)

    setNewName(companyGroup?.name || '')
  }

  useEffect(() => {
    if (props.open) {
      setNewName(companyGroup?.name || '')
    }
  }, [props.open])

  return (
    <ThemeProvider theme={getTheme('light')}>
      <AdvancedDialog
        actions={
          <Button variant="text" onClick={props.onClose}>
            Stäng
          </Button>
        }
        onClose={props.onClose}
        open={props.open}
        title={'Redigera koncern'}
      >
        <UserListItem
          component="div"
          title={companyGroup?.name || ''}
          type="companygroup"
          sx={{ bgcolor: 'grey.200', mb: 2 }}
          actions={
            <IconButton onClick={() => setRenameOpen(true)}>
              <Icon color={'disabled'} fontSize="small" name={'EditOutlined'} />
            </IconButton>
          }
        />
        <CollapsibleSection
          actions={
            <Button
              onClick={() => setAddCompanyOpen(true)}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till
            </Button>
          }
          size="medium"
          title="Bolag"
          variant="blank"
          sx={{ p: 0 }}
        >
          <List>
            {companyGroup?.companies
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((company) => (
                <ListItem
                  key={company.id}
                  secondaryAction={
                    <Button
                      onClick={() => setDeleteCompany(company)}
                      variant="text"
                    >
                      Ta bort från koncern
                    </Button>
                  }
                >
                  <Icon color="disabled" name="ApartmentOutlined" />
                  <ListItemText
                    sx={{ ml: 3 }}
                    primary={company.name}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              ))}
          </List>
        </CollapsibleSection>
        <CollapsibleSection
          actions={
            <Button
              onClick={() => setInviteUserOpen(true)}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till
            </Button>
          }
          size="medium"
          title="Användare"
          variant="blank"
          sx={{ p: 0 }}
        >
          <List>
            {companyGroup?.accounts
              .sort((a, b) =>
                `${a.first_name} ${a.last_name}`.localeCompare(
                  `${b.first_name} ${b.last_name}`
                )
              )
              .map((user) => (
                <UserListItem
                  key={user.id}
                  actions={
                    <>
                      <Chip
                        icon={<Icon name={'PersonOutlineOutlined'} />}
                        label={TRANSLATE_ROLES[user.role]}
                        sx={{ mr: 2 }}
                      />
                      <Button onClick={() => setEditUser(user)} variant="text">
                        Redigera
                      </Button>
                      <Button
                        onClick={() => setDeleteUserAlert(user)}
                        variant="text"
                      >
                        Ta bort
                      </Button>
                    </>
                  }
                  body={user.email}
                  title={
                    user.first_name && user.last_name
                      ? user.first_name + ' ' + user.last_name
                      : 'Namnlös'
                  }
                />
              ))}
          </List>
        </CollapsibleSection>
      </AdvancedDialog>
      <SimpleDialog
        actions={
          <Button variant="text" onClick={() => setInviteUserOpen(false)}>
            Stäng
          </Button>
        }
        onClose={() => setInviteUserOpen(false)}
        open={inviteUserOpen}
        title={`Bjud in en användare till ${companyGroup?.name}`}
      >
        <AddAccount
          hideRole
          onInvite={(
            email: string,
            message: string,
            firstName: string,
            lastName: string
          ) => {
            createUser({
              data: {
                email,
                first_name: firstName,
                last_name: lastName,
                message,
                company_group_id: (companyGroup as ParsedCompanyGroup).id,
                role: roles[AccountRole.VIEW].id
              }
            })
          }}
        />
      </SimpleDialog>
      <SimpleDialog
        title={'Radera användare?'}
        open={Boolean(deleteUserAlert)}
        onClose={() => setDeleteUserAlert(null)}
        actions={
          <>
            <Button variant="text" onClick={() => setDeleteUserAlert(null)}>
              Stäng
            </Button>
            <Button
              color="error"
              onClick={() => {
                deleteUser((deleteUserAlert as AccountType).id)

                setDeleteUserAlert(null)
              }}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Användaren{' '}
            <code>
              {deleteUserAlert
                ? deleteUserAlert.first_name + ' ' + deleteUserAlert.last_name
                : ''}
            </code>{' '}
            kommer att raderas permanent. Är du säker?
          </>
        }
      />
      <SimpleDialog
        title={'Radera bolag från koncern?'}
        open={Boolean(deleteCompany)}
        onClose={() => setDeleteCompany(null)}
        actions={
          <>
            <Button variant="text" onClick={() => setDeleteCompany(null)}>
              Stäng
            </Button>
            <Button
              color="error"
              onClick={() => {
                setRemovingCompany(true)
                removeCompany((deleteCompany as Company).id, (err) => {
                  if (!err) {
                    setDeleteCompany(null)
                  }
                  setRemovingCompany(false)
                })
              }}
              loading={removingCompany}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Användare som tillhör koncernen <code>{companyGroup?.name}</code>{' '}
            kommer inte längre kunna ta del av data kopplat till bolaget{' '}
            <code>{deleteCompany?.name}</code>. Är du säker?
          </>
        }
      />
      <SimpleDialog
        actions={
          <Button variant="text" onClick={() => setAddCompanyOpen(false)}>
            Stäng
          </Button>
        }
        onClose={() => setAddCompanyOpen(false)}
        open={addCompanyOpen}
        title={`Lägg till bolag på ${companyGroup?.name}`}
      >
        {props.companiesWithoutCompanyGroup.length > 0 ? (
          <List sx={{ position: 'relative' }}>
            {props.companiesWithoutCompanyGroup
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((company) => (
                <ListItem
                  key={company.id}
                  secondaryAction={
                    <Button
                      loading={company.id === addingCompany}
                      disabled={
                        Boolean(addingCompany) && company.id !== addingCompany
                      }
                      onClick={() => {
                        setAddingCompany(company.id)

                        addCompany(company.id, () => setAddingCompany(null))
                      }}
                      variant="text"
                    >
                      Lägg till
                    </Button>
                  }
                >
                  <Icon color="disabled" name="ApartmentOutlined" />
                  <ListItemText
                    sx={{ ml: 3 }}
                    primary={company.name}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              ))}
          </List>
        ) : (
          <Alert severity="warning" icon={<Icon name="Warning" />}>
            <strong>Inga bolag saknar koncerntillhörighet.</strong> Ta bort ett
            bolag från en koncern för att lägga till det på denna koncernen.
          </Alert>
        )}
      </SimpleDialog>
      <EditUserDialog
        email={editUser?.email ?? null}
        firstName={editUser?.first_name ?? null}
        isHomepal={props.isHomepal}
        lastName={editUser?.last_name ?? null}
        role={editUser?.role ?? null}
        onClose={() => setEditUser(null)}
        updateUser={(body) => {
          console.log('@TODO: Edit user, await backend', body)
        }}
        roles={props.roles}
        open={Boolean(editUser)}
      />
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseRename}>
            Stäng
          </Button>
        }
        onClose={handleCloseRename}
        open={renameOpen}
        title={`Döp om "${companyGroup?.name}"`}
      >
        <TextField
          fullWidth={true}
          label="Titel"
          autoComplete="off"
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
        <Button
          disabled={newName.length === 0}
          loading={renameLoading}
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            updateCompanyGroup(
              companyGroup?.id || '',
              newName,
              undefined,
              (err) => {
                if (!err) {
                  setRenameOpen(false)
                }

                setRenameLoading(false)
              }
            )

            setRenameLoading(true)
          }}
        >
          Spara
        </Button>
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default EditCompanyGroupDialog
