import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import React from 'react'

import Styles from './styles.module.css'

const QualityHover = ({ valid, total, hasCount }) => {
  return (
    <div>
      {!hasCount ? (
        'Kvalitet visas efter nästa körning.'
      ) : (
        <>
          <div className={Styles['row-container']}>
            <div className={Styles['space-container']}>
              <Chip
                color="success"
                icon={<Icon name="CheckCircleOutline" />}
                label="Giltiga"
                size="small"
              />
            </div>
            <div className={Styles['space-container']}>
              <p className={'secondary'}>{valid}</p>
              <p className={'white'}>
                {100 * parseFloat((valid / total).toFixed(2))}%
              </p>
            </div>
          </div>
          <div className={Styles['row-container']}>
            <div className={Styles['space-container']}>
              <Chip
                color="error"
                icon={<Icon name="ErrorOutlineOutlined" />}
                label="Ogiltiga"
                size="small"
              />
            </div>
            <div className={Styles['space-container']}>
              <p className={'secondary'}>{total - valid}</p>
              <p className={'white'}>
                {100 * parseFloat(((total - valid) / total).toFixed(2))}%
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default QualityHover
