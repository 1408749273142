import React from 'react'

import Select from 'components/common/Select'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Filter = ({ table, history, filter, invalidRows, totalRows }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Text color="text.secondary" variant="subtitle2">
          Filtrera på
        </Text>
        <Select
          className={Styles['filter-select']}
          value={filter}
          onChange={(val) => {
            history.push({
              search: Conf.updateSearchParams(history, 'attribute', val)
            })
          }}
          placeholder="Alla"
          options={Conf.FILTER_OPTIONS(table.attributes.output_attributes)}
        />
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Text variant="body1">
          Ogiltiga rader:{' '}
          <b>{new Intl.NumberFormat('sv-SE').format(invalidRows)}</b>
        </Text>
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Text variant="body1">
          Totalt antal rader:{' '}
          <b>{new Intl.NumberFormat('sv-SE').format(totalRows)}</b>
        </Text>
      </Box>
    </Box>
  )
}

export default Filter
