import * as Types from 'redux/Types'

import { cloneDeep } from 'lodash'

const INITIAL_STATE = {
  data: {},
  fetching: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  let tabs

  switch (type) {
  case Types.GET_ONE_DATA_PRODUCT_SUCCESS:
    tabs = {}

    if (payload.attributes.type === 'INSIGHT_STUDIO') {
      payload.attributes.tables?.[0]?.included.dashboard_tabs.forEach(
        (tab) => {
          tabs[tab.id] = tab.included.dashboard_widgets_placeholders
        }
      )
    }

    return {
      ...state,
      data: tabs
    }

  case Types.CREATE_TAB_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: []
      }
    }

  case Types.DELETE_TAB_SUCCESS:
    tabs = cloneDeep(state.data)
    delete tabs[payload.tabId]

    return {
      ...state,
      data: tabs
    }

  case Types.PUT_WIDGETS:
    return {
      ...state,
      fetching: true
    }

  case Types.PUT_WIDGETS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.tabId]: payload.data
      },
      fetching: false
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
