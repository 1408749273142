import React, { useEffect, useMemo, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ShortId from 'shortid'

import AddNode from './AddNode.js'

import DrawingBoard from 'components/common/DrawingBoard'

import Box from 'components_new/atoms/Box'

import InputNode from 'components/common/Node/InputNode'
import LeftJoinNode from 'components/common/Node/LeftJoinNode'
import UniteNode from 'components/common/Node/UniteNode'
import OutputNode from 'components/common/Node/OutputNode'
import FilterNode from 'components/common/Node/FilterNode'
import ClearNode from 'components/common/Node/ClearNode'
import AggregateNode from 'components/common/Node/AggregateNode'
import ConcatenateNode from 'components/common/Node/ConcatenateNode'
import DateParserNode from 'components/common/Node/DateParserNode'
import TimeDifferenceNode from 'components/common/Node/TimeDifferenceNode'
import ReplaceNode from 'components/common/Node/ReplaceNode'
import CalculateNode from 'components/common/Node/CalculateNode'
import CastNode from 'components/common/Node/CastNode'
import TrimNode from 'components/common/Node/TrimNode'

import Edge from 'components/common/Edge'

import * as LockedModeActions from 'redux/actions/LockedMode'
import * as AlertActions from 'redux/actions/Alert'

import Styles from './styles.module.css'
import * as Conf from './conf'
import LowerNode from 'components/common/Node/LowerNode.js'

const Board = ({
  DatasetStore,
  match,
  editMode,
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,
  resetBoard,
  setResetBoard,
  addNode,
  setAddNode,
  board,
  setBoard,
  CmdbTableStore
}) => {
  const table = DatasetStore.data[match.params.tableId]
  const buildTableName =
    CmdbTableStore.data[table.attributes.manual_input_build_id]?.attributes.name

  const updateGraph = useCallback(
    (id, data, newNode, newEdge, elementsToRemove, fullObj) => {
      Conf.updateGraph(
        setNodes,
        setEdges,
        [...nodes, ...edges],
        id,
        data,
        newNode,
        newEdge,
        elementsToRemove,
        fullObj
      )
    },
    [nodes, edges]
  )

  /**
   * Initilize Drawing board.
   */
  const initializeBoard = () => {
    const initElements = Conf.getInitialElements(
      DatasetStore,
      match,
      table,
      buildTableName
    )

    updateGraph(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      initElements
    )
  }

  // update board on mount
  useEffect(() => {
    initializeBoard()
  }, [])
  // update on buildTableName change
  useEffect(() => {
    initializeBoard()
  }, [buildTableName])

  useEffect(() => {
    if (resetBoard) {
      const initElements = Conf.getInitialElements(
        DatasetStore,
        match,
        table,
        buildTableName
      )

      updateGraph(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        initElements
      )

      setResetBoard(false)
    }
  }, [resetBoard])

  const setData = (id, data, affectedUpdate = {}) => {
    setNodes((nodes) => {
      const newNodes = nodes.map((n) => {
        const update = affectedUpdate[n.id]

        let newNode = n

        if (update) {
          newNode = { ...newNode, ...update }
        }

        if (n.id === id) {
          newNode.data = { ...newNode.data, ...data }
        }

        return newNode
      })

      return Conf.updateGlobalNodeData(newNodes)
    })
  }

  useEffect(() => {
    if (board) {
      board.fitView()
    }
  }, [board])

  const nodeTypes = useMemo(() => {
    return {
      INPUT: function inputNode(props) {
        return (
          <InputNode
            {...props}
            setData={setData}
            showOntology={!table.attributes.is_ontology}
          />
        )
      },
      LEFT_JOIN: function leftJoin(props) {
        return <LeftJoinNode {...props} setData={setData} />
      },
      OUTPUT: function output(props) {
        return <OutputNode {...props} setData={setData} />
      },
      UNITE: function uniteNode(props) {
        return <UniteNode {...props} setData={setData} />
      },
      FILTER: function filter(props) {
        return <FilterNode {...props} setData={setData} />
      },
      CLEAR: function clear(props) {
        return <ClearNode {...props} setData={setData} />
      },
      AGGREGATE: function aggregate(props) {
        return <AggregateNode {...props} setData={setData} />
      },
      CONCATENATE: function concatenate(props) {
        return <ConcatenateNode {...props} setData={setData} />
      },
      DATE_PARSER: function dateParser(props) {
        return <DateParserNode {...props} setData={setData} />
      },
      TIME_DIFFERENCE: function timeDifference(props) {
        return <TimeDifferenceNode {...props} setData={setData} />
      },
      REPLACE: function replace(props) {
        return <ReplaceNode {...props} type={'REPLACE'} setData={setData} />
      },
      CALCULATE: function calculate(props) {
        return <CalculateNode {...props} type={'CALCULATE'} setData={setData} />
      },
      CAST: function cast(props) {
        return <CastNode {...props} type={'CAST'} setData={setData} />
      },
      TRIM: function trim(props) {
        return <TrimNode {...props} type={'TRIM'} setData={setData} />
      },
      LOWER: function lower(props) {
        return <LowerNode {...props} type={'LOWER'} setData={setData} />
      },
      default: function d() {
        return null
      }
    }
  }, [])

  const edgeTypes = useMemo(() => {
    return {
      CUSTOM: function custom(props) {
        return <Edge {...props} />
      }
    }
  }, [])

  return (
    <Box sx={{ height: '100%' }}>
      <DrawingBoard
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onLoad={(b) => setBoard(b)}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={(params) => {
          const data = Conf.onConnect(params, nodes, edges)

          if (data) {
            updateGraph(data.update.id, data.update.data, null, data.edge)
          }
        }}
        className={editMode ? Styles['edit-container'] : null}
        editMode={editMode}
      />
      <AddNode
        visible={addNode}
        onClose={() => setAddNode(false)}
        onCreate={(type) => {
          const els = nodes
          const nodeNumberMapper = {
            ...(els.find((e) => e.data.nodeNumberMapper)?.data
              ?.nodeNumberMapper || {})
          }

          const currentNumbers = Object.values(nodeNumberMapper)
          const maxNumber =
            currentNumbers.length > 0 ? Math.max(...currentNumbers) : 0
          const id = ShortId.generate()
          const shortId = ShortId.generate()

          nodeNumberMapper[id] = maxNumber + 1

          const newNode = {
            type,
            id,
            data: {
              leftNode: null,
              leftAttribute: null,
              rightNode: null,
              rightAttribute: null,
              filterType: type === 'FILTER' ? 'OR' : null,
              refTableId: null,
              productTag: null,
              isOutputNode: false,
              filterConditions: [],
              nodeNumberMapper,
              newAttribute: Conf.NEW_ATTRIBUTE_NODES.includes(type)
                ? shortId
                : null,
              value: null,
              outputAttributes:
                type === 'UNITE' ||
                type === 'CONCATENATE' ||
                Conf.NEW_ATTRIBUTE_NODES.includes(type)
                  ? [Conf.newNodeOutputAttribute(id, shortId, type)]
                  : []
            },
            position: { x: 100, y: 100 }
          }

          updateGraph(null, null, newNode)

          setAddNode(false)
        }}
      />
    </Box>
  )
}

function mapStateToProps({ DatasetStore, CmdbTableStore }) {
  return {
    DatasetStore,
    CmdbTableStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...LockedModeActions,
      ...AlertActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Board))
