import * as SourceApi from 'redux/api/DataPlatform/connections/sources/v1'
import * as Types from 'redux/Types'

export function tryGetAllSources() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_SOURCES
    })

    SourceApi.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_SOURCES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_SOURCES_FAILED
        })
      })
  }
}

export function tryGetOneSource(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_SOURCE
    })
    SourceApi.getOne(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_SOURCE_SUCCESS,
          payload: { ...response.data, activeSourceId: id }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_SOURCE_FAILED
        })
      })
  }
}
