import React from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Paper from 'components_new/atoms/Paper'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import IconTip from 'components_new/molecules/IconTip'
import SectionHeader from 'components_new/molecules/SectionHeader'

import DashboardViewsLineChart from './DashboardViewsLineChart'

import { TrackingUser, UserTag } from 'types/GlobalTracking'
import { translateUserTag } from 'components_new/pages/internal/UsersTracking/utils'
import { parseDatetime, toObjectArray } from './utils'

interface UserTrackingTemplateProps {
  user: TrackingUser
}

interface ViewedPerDashboardsListProps {
  user: TrackingUser
}

interface LatestViewsListProps {
  user: TrackingUser
}

const UserTrackingTemplate = (props: UserTrackingTemplateProps) => {
  const { user } = props

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          minHeight: 0,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pl: 2
        }}
      >
        <Paper sx={{ p: 2, height: 'calc(100% / 3)' }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              position: 'relative'
            }}
          >
            <SectionHeader title="Visningar av dashboards" />
            <DashboardViewsLineChart
              labels={user.views.per_day.labels}
              values={user.views.per_day.values}
            />
          </Box>
        </Paper>
        <Grid container spacing={2} sx={{ height: 'calc(100% / 3 * 2)' }}>
          <Grid item xs={8} sx={{ height: '100%' }}>
            <ViewedPerDashboardsList user={user} />
          </Grid>
          <Grid item xs={4} sx={{ height: '100%' }}>
            <LatestViewsList user={user} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const ViewedPerDashboardsList = (props: ViewedPerDashboardsListProps) => {
  const { user } = props

  return (
    <Paper sx={{ height: '100%' }}>
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table
          sx={{
            minWidth: 0
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: 'background.paper' }}>
                Dashboard
              </TableCell>
              <TableCell align="right" sx={{ bgcolor: 'background.paper' }}>
                Visningar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toObjectArray(
              user.views.per_dashboard.labels,
              user.views.per_dashboard.values
            )
              .sort((a, b) => b.value - a.value)
              .map((dashboard) => (
                <TableRow
                  key={dashboard.label}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell align="left">{dashboard.label}</TableCell>
                  <TableCell align="right">{dashboard.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const LatestViewsList = (props: LatestViewsListProps) => {
  const { user } = props

  return (
    <Paper
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}
    >
      <SectionHeader
        title="Aktivitetslogg"
        titleAdornment={
          <IconTip
            size="small"
            title="Innehåller endast Visningar än så länge."
          />
        }
      />
      <List dense={true} sx={{ flex: 1, overflowY: 'scroll' }}>
        {user.views.latest_views.map((view) => (
          <ListItem
            key={view.viewed_at}
            sx={{
              borderRadius: 2,
              bgcolor: 'action.hover',
              '&:not(:last-child)': { mb: 0.5 }
            }}
          >
            <ListItemAvatar>
              <Avatar>{translateUserTag[UserTag.DASHBOARD_VIEWS].icon}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Box component="span" sx={{ fontWeight: 600 }}>
                    Ny visning
                  </Box>{' '}
                  • {parseDatetime(view.viewed_at)}
                </>
              }
              primaryTypographyProps={{
                color: 'text.secondary',
                fontSize: '0.75rem'
              }}
              secondary={
                view.dashboard === 'Borttagen' ? (
                  <em>{view.dashboard}</em>
                ) : (
                  view.dashboard
                )
              }
              secondaryTypographyProps={{
                color:
                  view.dashboard === 'Borttagen'
                    ? 'text.secondary'
                    : 'text.primary',
                fontSize: '0.875rem'
              }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default UserTrackingTemplate
