import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as AlertActions from 'redux/actions/Alert'
import * as ApiKeyActions from 'redux/actions/Applications/ApiKeys'
import * as EndpointActions from 'redux/actions/Applications/Endpoints'
import * as DataProductActions from 'redux/actions/Applications/DataProducts'
import * as DatasetActions from 'redux/actions/Applications/Datasets'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import Table from 'components/common/Table'

import BaseUrlField from './BaseUrlField'

// Modals
import EditSlug from './modals/EditSlug'
import ManageApiKey from './modals/ManageApiKey'
import EditEndpointObject from './modals/EditEndpoint'

import GetStarted from 'components/containers/Applications/DataProducts/modals/GetStarted'

import * as Conf from './conf'
import IconButton from 'components_new/atoms/IconButton'

const Connection = ({
  dataProduct,
  setAlert,
  resetAlert,
  AuthStore,
  tryCreateApiKey,
  tryUpdateDataProductKey,
  tryDeleteDataProductKey,
  tryUpdateEndpoint,
  tryUpdateDataProduct,
  tryDownloadApiDocumentation,
  match,
  tryGetDatasetTransformation,
  AppsDatasetStore
}) => {
  const [editSlug, setEditSlug] = useState(false)
  const [manageApiKey, setManageApiKey] = useState(undefined)
  const [endpoint, setEndpoint] = useState(undefined)
  const [showApiKey, setShowApiKey] = useState({})
  const [getStarted, setGetStarted] = useState(false)

  useEffect(() => {
    if (
      dataProduct.attributes.tables?.length === 0 &&
      !match.path.includes('/new')
    ) {
      setGetStarted(true)
    } else if (dataProduct.attributes.tables?.length > 0) {
      dataProduct.attributes.tables.forEach((table) =>
        tryGetDatasetTransformation(table.id)
      )
    }
  }, [dataProduct.attributes.tables?.length])

  const customerSlug = AuthStore.customer.slug

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Paper sx={{ p: 2, display: 'flex', gap: 1 }}>
          <BaseUrlField
            customerSlug={customerSlug}
            slug={dataProduct.attributes.slug}
            bold
          />
          <IconButton onClick={() => setEditSlug(true)}>
            <Icon name="EditOutlined" />
          </IconButton>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          <Button
            onClick={() =>
              tryDownloadApiDocumentation(
                dataProduct.id,
                dataProduct.attributes.name
              )
            }
            startIcon={<Icon name="DownloadOutlined" />}
            variant="text"
          >
            Dokumentation
          </Button>
        </Paper>
        <Paper sx={{ mt: 2, p: 2, display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 2 }}>
            <Table
              size="middle"
              headers={Conf.getEndpointHeaders((id, slug, table) => {
                setEndpoint({
                  id,
                  slug,
                  output_attributes: table.attributes.output_attributes,
                  mapped_output_attributes:
                    AppsDatasetStore.transformations[table.id].output_attributes
                })
              })}
              rows={Conf.getEndpointRows(dataProduct.attributes.tables)}
              parsedFormat
              expandable={Conf.expandableTable()}
            />
            <Text color="text.secondary" variant="caption">
              Objekt och endpoints genereras automatiskt utifrån de dataset som
              skapas under delningen.
            </Text>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Table
              size="middle"
              headers={Conf.API_KEY_HEADERS(
                setManageApiKey,
                setAlert,
                resetAlert,
                (apiKeyId) => tryDeleteDataProductKey(apiKeyId, dataProduct.id),
                showApiKey,
                setShowApiKey
              )}
              rows={Conf.getApiKeys(dataProduct.included.api_keys)}
              parsedFormat
            />

            <Button
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
              onClick={() => setManageApiKey({ name: undefined })}
              variant="text"
            >
              Generera nyckel
            </Button>
          </Box>
        </Paper>
      </Box>

      <EditSlug
        visible={editSlug}
        onClose={() => setEditSlug(false)}
        customerSlug={customerSlug}
        slug={dataProduct.attributes.slug}
        onSave={(attributes) =>
          tryUpdateDataProduct(dataProduct.id, attributes)
        }
        setAlert={setAlert}
        resetAlert={resetAlert}
      />

      <ManageApiKey
        value={manageApiKey}
        onClose={() => setManageApiKey(undefined)}
        onSave={(name) => {
          if (manageApiKey && manageApiKey.id) {
            tryUpdateDataProductKey(manageApiKey.id, name)
          } else {
            tryCreateApiKey({
              data_product_id: dataProduct.id,
              name
            })
          }
        }}
        apiKeys={
          dataProduct.included && dataProduct.included.api_keys
            ? dataProduct.included.api_keys
            : {}
        }
      />

      <EditEndpointObject
        endpoint={endpoint}
        onClose={() => setEndpoint(undefined)}
        setAlert={setAlert}
        resetAlert={resetAlert}
        onSave={(attributes) => tryUpdateEndpoint(endpoint.id, attributes)}
      />

      <GetStarted
        visible={getStarted}
        onClose={() => setGetStarted(false)}
        description={
          <>
            <Text gutterBottom>
              Ditt API:s endpoints genereras automatiskt utifrån de dataset som
              skapas.
            </Text>
            <Text>Börja med att lägga till ett dataset.</Text>
          </>
        }
      />
    </>
  )
}

function mapStateToProps({ AuthStore, AppsDatasetStore }) {
  return { AuthStore, AppsDatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertActions,
      ...ApiKeyActions,
      ...EndpointActions,
      ...DataProductActions,
      ...DatasetActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Connection))
