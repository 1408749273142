import React, { ReactNode, useEffect, useState } from 'react'

import Collapse from 'components_new/atoms/Collapse'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'
import Stack from 'components_new/atoms/Stack'
import Text, { TextVariantType } from 'components_new/atoms/Text'

type CollapsibleSectionSize = 'default' | 'small' | 'medium'

interface CollapsibleSectionProps {
  actions?: ReactNode
  children?: ReactNode
  localStorageId?: string
  icon?: ReactNode
  size?: CollapsibleSectionSize
  sx?: object
  title?: string
  titleAdornments?: ReactNode
  variant?: 'blank' | 'elevation' | 'outlined'
  defaultCollapsed?: boolean
  onChange?: () => void
}

const getSizeAlternatives = (
  size?: CollapsibleSectionSize
): {
  mb: number
  textVariant: TextVariantType
} => {
  switch (size) {
  case 'medium':
    return {
      mb: 2,
      textVariant: 'h6'
    }
  case 'small':
    return {
      mb: 2,
      textVariant: 'subtitle2'
    }
  default:
    return {
      mb: 1,
      textVariant: 'h5'
    }
  }
}

/**
 * The CollapsibleSection component is used
 * to divide a page in different sections
 * that can be open (shown or hidden).
 */

const CollapsibleSection = React.forwardRef(
  (props: CollapsibleSectionProps, ref: any) => {
    const {
      actions,
      localStorageId,
      children,
      icon,
      size = 'default',
      sx,
      title,
      titleAdornments,
      variant,
      defaultCollapsed,
      onChange,
      ...rest
    } = props

    const { mb, textVariant } = getSizeAlternatives(size)

    const [open, setOpen] = useState<boolean>(Boolean(!defaultCollapsed))

    useEffect(() => {
      if (localStorageId) {
        const state = localStorage.getItem(localStorageId)

        if (state) {
          setOpen(state === 'true')
        } else {
          localStorage.setItem(localStorageId, 'true')
        }
      }
    }, [])

    const handleChange = () => {
      if (localStorageId) {
        localStorage.setItem(localStorageId, !open as unknown as string)
      }
      setOpen(!open)
      onChange ? onChange() : null
    }

    return (
      <Paper
        elevation={variant === 'blank' ? 0 : undefined}
        ref={ref}
        sx={{ mb, p: 2, ...sx }}
        variant={
          variant === 'elevation' || variant === 'outlined'
            ? variant
            : undefined
        }
        {...rest}
      >
        <Stack alignItems="center" justifyContent="space-between">
          <Stack alignItems="center" spacing={1}>
            <IconButton onClick={handleChange} size="small">
              <Icon name={open ? 'ExpandMore' : 'ExpandLess'} />
            </IconButton>
            {icon ?? null}
            <Text color="text.primary" variant={textVariant}>
              {title}
            </Text>
            {titleAdornments}
          </Stack>
          {actions ? (
            <Stack alignItems="center" spacing={1}>
              {actions}
            </Stack>
          ) : null}
        </Stack>
        <Collapse
          in={
            localStorageId
              ? localStorage.getItem(localStorageId) === 'true'
              : open
          }
        >
          <Divider sx={{ my: 1 }} />
          {children}
        </Collapse>
      </Paper>
    )
  }
)

CollapsibleSection.displayName = 'CollapsibleSection'
export default CollapsibleSection
