import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { arrayToObject } from 'helpers/Functions'
import { Company } from 'types/GlobalCompany'

export type CompanyBody = {
  [key: string]: Company
}

export interface CompanyReducerType {
  data: CompanyBody
  fetched: boolean
}

// Initial state
const INITIAL_STATE: CompanyReducerType = {
  data: {},
  fetched: false
}

// Actions
const getAllAction = createAction<{ data: Company[] }>(
  Types.GET_ALL_COMPANIES_SUCCESS
)
const updateAction = createAction<{ data: Company }>(
  Types.UPDATE_COMPANY_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const companyReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as CompanyBody,
        fetched: true
      }
    })
    .addCase(updateAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default companyReducer
