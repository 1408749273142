import React from 'react'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Input from 'components/common/Input'
import Button from 'components_new/atoms/Button'

import { FORM_VALIDATIONS, DESCRIPTOR } from 'helpers/Constants'

const CreateDataProduct = ({
  visible,
  onClose,
  tryCreateDataproduct,
  dataProducts,
  type
}) => {
  const [form] = Form.useForm()

  const descriptor = DESCRIPTOR[type]

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose()
        form.resetFields()
      }}
      header={`Skapa ${descriptor}`}
      actions={
        <>
          <Button
            onClick={() => {
              onClose()
              form.resetFields()
            }}
            variant="text"
          >
            Avbryt
          </Button>
          <Button onClick={() => form.submit()}>Skapa</Button>
        </>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          const data = {
            attributes: {
              name: values.name,
              type
            }
          }

          tryCreateDataproduct({ data })

          form.resetFields()
          onClose()
        }}
        initialValues={{ name: undefined }}
      >
        <Form.Item
          name={'name'}
          rules={[
            FORM_VALIDATIONS.REQUIRED,
            FORM_VALIDATIONS.DUPLICATE(
              dataProducts,
              'name',
              `En ${descriptor} med detta namn existerar redan`
            )
          ]}
        >
          <Input size="large" label={`Namnge ${descriptor}`} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateDataProduct
