import React from 'react'
import { Route } from 'react-router-dom'

const AuthRoute = ({ path, Component, isAuthenticated }) => (
  <Route path={path} exact>
    {isAuthenticated ? <Component /> : null}
  </Route>
)

export default AuthRoute
