import React, { ReactNode } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AuthStoreActions from 'redux/actions/Authentication'
import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import CustomerPreview from 'components_new/organisms/CustomerPreview'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'

interface NavBarProps {
  navContent?: ReactNode
  navFooter?: ReactNode
  navMenu?: ReactNode
}

const NavBar = (props: ComponentProps) => {
  const {
    navContent,
    navFooter,
    navMenu,
    // redux stores
    NavigationMenuStore
  } = props

  const collapsed = !NavigationMenuStore?.open

  return (
    <Box
      sx={{
        height: '100%',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        minHeight: 0
      }}
    >
      {navContent ? (
        <Box
          sx={{
            p: collapsed ? '7px' : 2,
            pt: collapsed ? 1.5 : 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {navContent}
        </Box>
      ) : null}

      <Box
        sx={{
          flex: 1,
          px: collapsed ? '13px' : 2,
          overflowY: 'auto'
        }}
      >
        {navMenu ?? null}
      </Box>

      <Box
        sx={{
          p: collapsed ? 1 : 2,
          pt: collapsed ? undefined : 1,
          pb: collapsed ? 2 : undefined,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {navFooter ? (
          <Box
            sx={{
              px: 0.5,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.25
            }}
          >
            {navFooter}
          </Box>
        ) : null}
        <Divider flexItem={true} sx={{ my: 2 }} />
        <CustomerPreview />
      </Box>
    </Box>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions,
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & NavBarProps

export default connector(NavBar)
