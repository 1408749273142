import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v3/shape-relations`

export async function getRelatedTables(id) {
  return Axios.get(`${URL}/${id}`, await Functions.parseAuthenticationHeader())
}
