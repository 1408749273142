import React from 'react'

import Box from 'components_new/atoms/Box'

export const EmbeddedWidget = (props: { id: string }) => {
  const { id } = props

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        aspectRatio: 3 / 4
      }}
    >
      <iframe
        key={id}
        style={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          borderRadius: '16px',
          overflow: 'hidden'
        }}
        src={`https://portal.homepal.se/public/widgets/${id}`}
        allowFullScreen
        frameBorder="0"
      ></iframe>
    </Box>
  )
}

export const widgetIds = [
  '41225dd2-e56f-4fa6-8b89-3793ffa6e2f9', // Hyresbortfall
  'adb0642a-6fff-4652-85d4-3d80ef3d1093', // Driftnetto
  '6ff290a3-d78c-4463-a78a-50866e5c6320', // Energianvändning per m2 (Atemp, NÅK)
  '98e96085-b240-4d03-8bbf-0c94b34cc77a', // Avslutade arbetsordrar
  '7183c4d3-8140-4820-b89a-f4a38466caef', // Vakansgrad över tid
  'fd3afd80-d068-485b-b047-e216b90eae06' // Uppsagda objekt
]
