import React from 'react'

import Switch from 'components_new/atoms/Switch'

import { Category } from 'redux/reducers/Dashboards'
import { translateCategory } from 'utils/enumTranslator'

import Icon from 'components_new/atoms/Icon'

import { AllowAcess } from 'types/GlobalCustomer'
import {
  OrderedKpiTemplate,
  PatchOrderedKpiTemplateBody
} from 'types/GlobalOrderedKpiTemplate'
import { KpiTemplate } from 'types/GlobalKpiTemplates'

const KEY_FIGURE_HEADERS = [
  {
    label: 'Nyckeltal'
  },
  {
    label: 'I licens',
    align: 'center' as const
  },
  {
    label: 'Beställd',
    align: 'center' as const
  },
  {
    label: 'Integrerad',
    align: 'center' as const
  },
  {
    label: 'Modellerad',
    align: 'center' as const
  },
  {
    label: 'Kvalitetssäkrad',
    align: 'center' as const
  }
]

const addCategoryRows = (
  allowAccess: AllowAcess | undefined,
  templates: KpiTemplate[],
  category: Category,
  orderedKpiTemplates: OrderedKpiTemplate[],
  createOrderedKpiTemplate: (kpiTemplateId: string) => void,
  deleteOrderedKpiTemplate: (id: string) => void,
  updateOrderedKpiTemplate: (
    id: string,
    body: PatchOrderedKpiTemplateBody
  ) => void
) => {
  let rows = []

  rows.push({
    colSpan: 6,
    label: translateCategory[category].title,
    isHeader: true,
    values: []
  })

  rows = [
    ...rows,
    ...templates
      .filter((template) => template.category === category)
      .map((template) => {
        let inLicense = true

        if (template.is_premium && !allowAccess?.premium_kpis) {
          inLicense = false
        }

        const orderedKpiTemplate = orderedKpiTemplates.find(
          (item) => item.kpi_template_id === template.id
        )

        return {
          label: template.title,
          values: [
            <Icon
              key={'inLicense'}
              name={inLicense ? 'Check' : 'Close'}
              color={inLicense ? 'success' : 'error'}
            />,
            <Switch
              key={'isOrdered'}
              color="primary"
              checked={!!orderedKpiTemplate}
              onChange={() => {
                if (!orderedKpiTemplate) {
                  createOrderedKpiTemplate(template.id)
                } else {
                  deleteOrderedKpiTemplate(orderedKpiTemplate.id)
                }
              }}
            />,
            <Switch
              key={'isIntegrated'}
              color="primary"
              checked={!!orderedKpiTemplate?.integrated_at}
              disabled={!orderedKpiTemplate}
              onChange={() => {
                if (orderedKpiTemplate) {
                  if (!orderedKpiTemplate?.integrated_at) {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: { integrated_at: new Date() }
                    })
                  } else {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: {
                        integrated_at: null,
                        modelled_at: null,
                        quality_assured_at: null
                      }
                    })
                  }
                }
              }}
            />,
            <Switch
              key={'isModelled'}
              color="primary"
              checked={!!orderedKpiTemplate?.modelled_at}
              disabled={!orderedKpiTemplate?.integrated_at}
              onChange={() => {
                if (orderedKpiTemplate) {
                  if (!orderedKpiTemplate?.modelled_at) {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: { modelled_at: new Date() }
                    })
                  } else {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: {
                        modelled_at: null,
                        quality_assured_at: null
                      }
                    })
                  }
                }
              }}
            />,
            <Switch
              key={'isQualityAssured'}
              color="primary"
              checked={!!orderedKpiTemplate?.quality_assured_at}
              disabled={!orderedKpiTemplate?.modelled_at}
              onChange={() => {
                if (orderedKpiTemplate) {
                  if (!orderedKpiTemplate?.quality_assured_at) {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: { quality_assured_at: new Date() }
                    })
                  } else {
                    updateOrderedKpiTemplate(orderedKpiTemplate.id, {
                      data: {
                        quality_assured_at: null
                      }
                    })
                  }
                }
              }}
            />
          ]
        }
      })
  ]

  return rows
}

export const parseKeyFigureData = (
  allowAccess: AllowAcess | undefined,
  kpiTemplates: KpiTemplate[],
  orderedKpiTemplates: OrderedKpiTemplate[],
  createOrderedKpiTemplate: (kpiTemplateId: string) => void,
  deleteOrderedKpiTemplate: (id: string) => void,
  updateOrderedKpiTemplate: (
    id: string,
    body: PatchOrderedKpiTemplateBody
  ) => void
) => {
  if (!allowAccess) {
    return { rows: [], headers: [] }
  }

  kpiTemplates.sort((a, b) => (a.title > b.title ? 1 : -1))

  const rows = [
    ...addCategoryRows(
      allowAccess,
      kpiTemplates,
      Category.ECONOMY,
      orderedKpiTemplates,
      createOrderedKpiTemplate,
      deleteOrderedKpiTemplate,
      updateOrderedKpiTemplate
    ),
    ...addCategoryRows(
      allowAccess,
      kpiTemplates,
      Category.REAL_ESTATE_PORTFOLIO,
      orderedKpiTemplates,
      createOrderedKpiTemplate,
      deleteOrderedKpiTemplate,
      updateOrderedKpiTemplate
    ),
    ...addCategoryRows(
      allowAccess,
      kpiTemplates,
      Category.RENTAL,
      orderedKpiTemplates,
      createOrderedKpiTemplate,
      deleteOrderedKpiTemplate,
      updateOrderedKpiTemplate
    ),
    ...addCategoryRows(
      allowAccess,
      kpiTemplates,
      Category.TECHNICAL_MANAGEMENT,
      orderedKpiTemplates,
      createOrderedKpiTemplate,
      deleteOrderedKpiTemplate,
      updateOrderedKpiTemplate
    ),
    ...addCategoryRows(
      allowAccess,
      kpiTemplates,
      Category.ENERGY,
      orderedKpiTemplates,
      createOrderedKpiTemplate,
      deleteOrderedKpiTemplate,
      updateOrderedKpiTemplate
    )
  ]

  return {
    rows,
    headers: KEY_FIGURE_HEADERS
  }
}
