import * as Api from 'redux/api/Applications/shapeRelations/v3'
import * as Types from 'redux/Types'

export function tryGetRelatedTables(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_RELATED_TABLES
    })

    Api.getRelatedTables(id)
      .then((response) => {
        dispatch({
          type: Types.GET_RELATED_TABLES_SUCCESS,
          payload: response.data.data,
          id
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_RELATED_TABLES_FAILED
        })
      })
  }
}
