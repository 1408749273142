import React, {
  ElementType,
  ReactElement,
  ReactNode,
  SyntheticEvent
} from 'react'
import { Snackbar as MUISnackbar, SnackbarCloseReason } from '@mui/material'

interface SnackbarProps {
  action?: ReactNode
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right'
    vertical: 'bottom' | 'top'
  }
  autoHideDuration?: number
  children?: ReactElement<any>
  component?: ElementType<any>
  message?: ReactNode
  onClose?:
    | (() => void)
    | ((
        event: SyntheticEvent<any> | Event,
        reason: SnackbarCloseReason
      ) => void)
  open?: boolean | false
  sx?: object
}

/**
 * The Snackbar component provides brief
 * notifications. The component is also known
 * as a 'toast'.
 *
 * They appear temporarily, towards the
 * bottom of the screen. They shouldn't
 * interrupt the user experience, and they
 * don't require user input to disappear.
 */

const Snackbar = React.forwardRef((props: SnackbarProps, ref: any) => {
  return (
    <MUISnackbar {...props} ref={ref}>
      {props.children}
    </MUISnackbar>
  )
})

Snackbar.displayName = 'Snackbar'
export default Snackbar
