import * as Api from 'redux/api/DataPlatform/shapes/datasets/v2'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetOneDataset(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_DATASET
    })

    Api.getOne(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DATASET_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DATASET_FAILED
        })
      })
  }
}

export function tryCreateDataset(body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_DATASET
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_DATASET_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Ett nytt dataset har skapas.')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DATASET_FAILED
        })

        messageWarning('Datasetet kunde inte skapas.')
      })
  }
}

export function tryUpdateDataset(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_DATASET
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DATASET_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Datasetet har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DATASET_FAILED
        })

        messageWarning('Datasetet kunde inte uppdateras.')
      })
  }
}

export function tryDeleteDataset(id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_DATASET
    })

    Api.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_DATASET_SUCCESS,
          payload: id
        })

        messageSuccess('Datasetet har tagits bort.')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DATASET_FAILED
        })

        messageWarning('Datasetet kunde inte tas bort.')
      })
  }
}

export function tryPutDatasetSchema(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_DATASET_SCHEMA
    })

    Api.put(id, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_DATASET_SCHEMA_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Datasetets schema har uppdaterats.')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_DATASET_SCHEMA_FAILED
        })

        messageWarning('Datasetets schema kunde inte skapas.')
      })
  }
}
