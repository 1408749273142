import React, { ElementType, MouseEventHandler, ReactNode } from 'react'

import Dialog from 'components_new/atoms/Dialog'
import DialogTitle from 'components_new/atoms/Dialog/DialogTitle'

// These are going to be replaced with our own components later.
import { DialogContent, DialogActions, DialogContentText } from '@mui/material'

interface SimpleDialogProps {
  actions?: ReactNode
  children?: ReactNode
  component?: ElementType<any>
  contentText?: ReactNode
  disableOverflow?: boolean
  disablePortal?: boolean
  disableRestoreFocus?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose?: () => void
  onMouseDown?: MouseEventHandler<HTMLElement>
  open: boolean
  PaperProps?: object
  sx?: object
  title?: ReactNode
}

/**
 * The SimpleDialog component is a
 * variant of a Dialog that is smaller
 * and simpler, and therefore suitable
 * for displaying short content (i.e.
 * a promt or a short form).
 */

const SimpleDialog = React.forwardRef((props: SimpleDialogProps, ref: any) => {
  const {
    actions,
    children,
    contentText,
    disableOverflow,
    fullHeight,
    title,
    ...rest
  } = props

  return (
    <Dialog {...rest} ref={ref}>
      {title ? (
        <DialogTitle
          sx={{
            // Because of antd's global.less
            color: 'text.primary'
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent
        sx={{
          overflow: disableOverflow ? 'hidden' : undefined,
          height: fullHeight ? '90vh' : undefined
        }}
      >
        {props.contentText ? (
          <DialogContentText>{contentText}</DialogContentText>
        ) : null}
        {children}
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  )
})

SimpleDialog.defaultProps = {
  maxWidth: 'sm',
  fullWidth: true,
  onMouseDown: (e) => e.stopPropagation()
}

SimpleDialog.displayName = 'SimpleDialog'
export default SimpleDialog
