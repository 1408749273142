import React, { ChangeEvent, useState } from 'react'

import {
  DataType,
  InnerWidgetPatchBody,
  VisualizationConstraint,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import { translateVisualizationConstrant } from 'utils/enumTranslator'

import Switch from 'components_new/atoms/Switch'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'

import AdditionalSegmentSection from './AdditionalSegmentSection'

import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'
import { getSegmentByOptions, getSortOptions } from './utils'

interface SegmentSectionProps {
  createAdditionalSegmentBy: (attributeOptionId: string) => void
  deleteAdditionalSegmentBy: (id: string) => void
  onClose: () => void
  updateAdditionalSegmentBy: (id: string, attributeOptionId: string) => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.FUNNEL,
  WidgetType.LIST,
  WidgetType.PIE_CHART
]

const SegmentSection = (props: SegmentSectionProps) => {
  const {
    createAdditionalSegmentBy,
    deleteAdditionalSegmentBy,
    onClose,
    updateAdditionalSegmentBy,
    updateWidget,
    widget
  } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  const getSegmentTitle = (
    type: Omit<
      WidgetType,
      WidgetType.KEY_FIGURE | WidgetType.LINE_CHART | WidgetType.MAP
    >
  ) => {
    switch (type) {
    case WidgetType.BAR_CHART:
      return 'Staplar'
    case WidgetType.BUBBLE_CHART:
      return 'Bubblor'
    case WidgetType.LIST:
      return 'Rader'
    case WidgetType.COMBO_CHART:
      return 'Staplar och linjer'
    case WidgetType.PIE_CHART:
      return 'Tårtbitar'
    case WidgetType.FUNNEL:
      return 'Steg'
    default:
      return ''
    }
  }

  const isDate = widget.settings.segment_by.type === DataType.DATE
  const selected = widget.settings.segment_by.selected
  const selectedLabel =
    widget.settings.segment_by.options.find((option) => option.id === selected)
      ?.name ?? ''

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: getPropertyTitle('segment_by')
    })
  }

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    data: {
      options: { label: string; value: any }[]
      value: string
      type: TInputType
    }
  }>({
    open: false,
    property: 'segment_by',
    title: '',
    data: {
      options: [],
      value: '',
      type: 'text'
    }
  })

  // Properties edited in this component.
  type TProperty =
    | 'label_chars'
    | 'segment_by'
    | 'segment_by_display_name'
    | 'top_n_limit'
    | 'visualization_constraint'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'label_chars':
      return 'Max antal tecken'
    case 'segment_by':
      return 'Segmentering'
    case 'segment_by_display_name':
      return 'Alias'
    case 'top_n_limit':
      return `Antal ${getSegmentTitle(
        widget.settings.type.selected
      ).toLowerCase()}`
    case 'visualization_constraint':
      return 'Sortering'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''
    let inputType: TInputType = 'text'
    let inputOptions: { label: string; value: any }[] = []

    switch (property) {
    case 'label_chars':
      inputType = 'number'
      inputValue = widget.settings.label_chars?.toString() ?? ''
      break
    case 'segment_by':
      inputOptions = getSegmentByOptions(widget.settings.segment_by.options)
      inputType = 'select'
      inputValue = widget.settings.segment_by.selected ?? ''
      break
    case 'segment_by_display_name':
      inputType = 'text'
      inputValue = widget.settings.segment_by.display_name ?? ''
      break
    case 'top_n_limit':
      inputType = 'number'
      inputValue = widget.settings.top_n_limit?.toString() ?? ''
      break
    case 'visualization_constraint':
      inputOptions = getSortOptions(isDate)
      inputType = 'select'
      inputValue = widget.settings.visualization_constraint ?? ''
    }

    setDialog({
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (event: ChangeEvent<HTMLInputElement>) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value: event.target.value }
    }))
  }

  const handleSubmitDialog = () => {
    let body: InnerWidgetPatchBody = {}

    switch (dialog.property) {
    case 'label_chars':
      body = {
        label_chars: dialog.data.value ? parseInt(dialog.data.value) : null
      }
      break
    case 'segment_by':
      body = { segment_by: dialog.data.value }
      break
    case 'segment_by_display_name':
      body = { segment_by_display_name: dialog.data.value ?? null }
      break
    case 'top_n_limit':
      body = {
        top_n_limit: dialog.data.value ? parseInt(dialog.data.value) : null
      }
      break
    case 'visualization_constraint':
      body = {
        visualization_constraint: dialog.data.value
          ? (dialog.data.value as VisualizationConstraint)
          : null
      }
      break
    }

    updateWidget(widget.id, body)
  }

  return (
    <>
      <SettingsGroup title={getSegmentTitle(widget.settings.type.selected)}>
        <SettingsItem
          onClick={handleOpenDrawer}
          title={getPropertyTitle('segment_by')}
          value={selectedLabel}
        />
        {[
          WidgetType.BAR_CHART,
          WidgetType.COMBO_CHART,
          WidgetType.LIST
        ].includes(widget.settings.type.selected) &&
        widget.settings.allow_visualization_constraint ? (
              <>
                <SettingsItem
                  onClick={() => handleOpenDialog('visualization_constraint')}
                  title={getPropertyTitle('visualization_constraint')}
                  value={
                    widget.settings.visualization_constraint
                      ? translateVisualizationConstrant[isDate ? 'date' : 'text'][
                        widget.settings.visualization_constraint
                      ]
                      : null
                  }
                  variant="edit"
                />
                <SettingsItem
                  onClick={() => handleOpenDialog('top_n_limit')}
                  title={getPropertyTitle('top_n_limit')}
                  value={widget.settings.top_n_limit}
                  variant="edit"
                />
              </>
            ) : null}
      </SettingsGroup>

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        <SettingsGroup>
          <SettingsItem
            onClick={() => handleOpenDialog('segment_by')}
            title={getPropertyTitle('segment_by')}
            value={selectedLabel}
            variant="edit"
          />
          {widget.settings.type.selected === WidgetType.LIST ? (
            <SettingsItem
              actions={
                <Switch
                  checked={widget.settings.external_link.show}
                  onChange={() =>
                    updateWidget(widget.id, {
                      show_external_link: !widget.settings.external_link.show
                    })
                  }
                  size="small"
                />
              }
              disabled={!widget.settings.external_link.available}
              disabledText="Länkning är inte tillgänglig för din valda segmentering"
              title="Länka till källsystem"
            />
          ) : null}
        </SettingsGroup>

        <SettingsGroup helperText="Ibland kan det vara bra att sätta en begränsning på antal tecken för din segmentering för att ge utrymme åt datavisualiseringen.">
          <SettingsItem
            onClick={() => handleOpenDialog('segment_by_display_name')}
            title={getPropertyTitle('segment_by_display_name')}
            value={widget.settings.segment_by.display_name}
            variant="edit"
          />
          {[
            WidgetType.BAR_CHART,
            WidgetType.COMBO_CHART,
            WidgetType.LINE_CHART,
            WidgetType.LIST
          ].includes(widget.settings.type.selected) ? (
                <SettingsItem
                  onClick={() => handleOpenDialog('label_chars')}
                  title={getPropertyTitle('label_chars')}
                  value={widget.settings.label_chars}
                  variant="edit"
                />
              ) : null}
        </SettingsGroup>

        <AdditionalSegmentSection
          createAdditionalSegmentBy={createAdditionalSegmentBy}
          deleteAdditionalSegmentBy={deleteAdditionalSegmentBy}
          onClose={onClose}
          updateAdditionalSegmentBy={updateAdditionalSegmentBy}
          widget={widget}
        />
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default SegmentSection
