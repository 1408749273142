import React, { ReactNode } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'

interface PageToolbarProps {
  title?: ReactNode
  leftActions?: ReactNode
  rightActions?: ReactNode
}

const PageToolbar = (props: PageToolbarProps) => {
  const { leftActions, rightActions } = props

  return (
    <>
      <ThemeProvider theme={getTheme('dark')}>
        <Box
          sx={{
            bgcolor: 'grey.400',
            borderBottomStyle: 'solid',
            borderBottomColor: 'divider',
            borderBottomWidth: '1px'
          }}
        >
          <Stack
            alignItems="center"
            spacing={2}
            sx={{ minWidth: 0, height: '31px', px: 2 }}
          >
            <Stack
              alignItems="center"
              spacing={2}
              sx={{ flexGrow: 1, minWidth: 0 }}
            >
              {leftActions}
            </Stack>
            <Box sx={{ flexShrink: 0 }}>
              <Stack alignItems="center" spacing={2}>
                {rightActions}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  )
}

PageToolbar.displayName = 'PageToolbar'
export default PageToolbar
