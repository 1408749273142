import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Stack from 'components_new/atoms/Stack'

import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsGroup from 'components_new/molecules/SettingsGroup'

import EditDialog from './EditDialog'
import ReOrderItem from './ReOrderItem'

import {
  FunnelStagePutBody,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface ReOrderSectionProps {
  putFunnelStages: (body: FunnelStagePutBody) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [WidgetType.FUNNEL]

const ReOrderSection = (props: ReOrderSectionProps) => {
  const { putFunnelStages, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const stages = widget.settings.segment_by.stages

  return (
    <>
      <SettingsGroup title="Sortera">
        <SettingsItem>
          <Box sx={{ width: '100%' }}>
            <Stack sx={{ height: 128, mb: 1 }}>
              {stages.map((stage) => (
                <Box
                  key={stage.index}
                  sx={{
                    height: '100%',
                    width: '100%',
                    minWidth: 0,
                    p: 0.5
                  }}
                >
                  <ReOrderItem
                    editable={false}
                    hidden={stage.hidden}
                    setHidden={() => {}}
                    status={stage.status}
                    setStatus={() => {}}
                    title={stage.title}
                  />
                </Box>
              ))}
            </Stack>
            <Button
              onClick={() => setEditDialogOpen(true)}
              fullWidth={true}
              variant="text"
            >
              Redigera
            </Button>
          </Box>
        </SettingsItem>
      </SettingsGroup>

      <EditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        updateEvents={putFunnelStages}
        funnelStages={stages}
      />
    </>
  )
}

export default ReOrderSection
