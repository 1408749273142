import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import StatusBannerTemplate from 'components_new/templates/internal/StatusBanner'

import * as CustomersAndLicensesActions from 'redux/actions/internal/OurCustomers'
import * as StatusBannerActions from 'redux/actions/internal/StatusBanner'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  CustomersAndLicensesStore: state.CustomersAndLicensesStore,
  InternalStatusBannerStore: state.InternalStatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    { ...CustomersAndLicensesActions, ...StatusBannerActions },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type StatusBannerPageProps = ConnectedProps<typeof connector>

const StatusBannerPage = (props: StatusBannerPageProps) => {
  const { CustomersAndLicensesStore, InternalStatusBannerStore } = props

  useEffect(() => {
    if (!CustomersAndLicensesStore.customersFetched) {
      props.tryGetCustomers()
    }
    if (!InternalStatusBannerStore.fetched) {
      props.tryGetStatusBanner()
    }
  }, [])

  return (
    <InternalTemplate>
      <StatusBannerTemplate
        data={InternalStatusBannerStore.data}
        customers={CustomersAndLicensesStore.customers}
        create={props.tryCreateStatusBanner}
        update={props.tryUpdateStatusBanner}
        delete={props.tryDeleteStatusBanner}
      />
    </InternalTemplate>
  )
}

export default connector(StatusBannerPage)
