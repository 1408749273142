import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: [],
  model: {},
  fetched: false,
  fetching: false,
  error: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.SET_DATASET_PREVIEW_MODEL:
    return {
      ...state,
      model: payload,
      fetched: false,
      fetching: false
    }

  case Types.GET_DATASET_PREVIEW:
    return {
      ...state,
      fetched: false,
      fetching: true,
      error: false
    }

  case Types.GET_DATASET_PREVIEW_SUCCESS:
    return {
      ...state,
      data: payload,
      fetched: true,
      fetching: false
    }

  case Types.GET_DATASET_PREVIEW_FAILED:
    return {
      ...state,
      data: [],
      fetched: false,
      fetching: false,
      error: true
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
