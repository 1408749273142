import React, { useState } from 'react'
import { Dropdown as AntdDropdown } from 'antd'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

// Override antd css.
import './styles.css'

const DropdownButton = (props) => {
  const {
    children,
    transparent,
    items = [],
    className = '',
    ignoreLockedMode,
    noChevron,
    header,
    subHeader,
    iconItems = false,
    icon,
    iconClassName,
    disabled
  } = props

  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  return (
    <AntdDropdown
      overlay={Conf.menu({
        items,
        header,
        iconItems,
        subHeader,
        ignoreLockedMode,
        setVisible
      })}
      className={`ignore-locked-mode ${Styles.container} ${className}`}
      overlayClassName={Styles['items-container']}
      placement={'bottomRight'}
      onOpenChange={handleVisibleChange}
      open={visible}
      trigger={icon ? ['click'] : undefined}
      disabled={disabled}
    >
      {icon ? (
        <div className={Styles['icon-container']}>
          <Icon name={icon} className={iconClassName} />
        </div>
      ) : (
        <Button
          disabled={disabled}
          endIcon={noChevron ? null : <Icon name="ExpandMore" />}
          variant={transparent ? 'text' : 'contained'}
        >
          {children}
        </Button>
      )}
    </AntdDropdown>
  )
}

export default DropdownButton
