import React, { useState, useMemo } from 'react'

import Alert from 'components_new/atoms/Alert'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import FormControlLabel from 'components_new/atoms/FormControlLabel'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Switch from 'components_new/atoms/Switch'
import TextField from 'components_new/atoms/TextField'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import EditCompanyGroupDialog from 'components_new/organisms/dialogs/EditCompanyGroupDialog'

import { AccountType, RoleReducerType } from 'redux/reducers/Accounts'
import { CompanyGroup, ParsedCompanyGroup } from 'types/GlobalCompanyGroups'
import { Company, PatchCompanyBody } from 'types/GlobalCompany'
import { PostUserBody } from 'types/GlobalUser'
import { AuthenticationState } from 'types/GlobalAuthentication'

interface Props {
  AuthStore: AuthenticationState
  addCompanyToCompanyGroup: (
    id: string,
    companyId: string,
    callback?: (err?: boolean) => void
  ) => void
  companies: Company[]
  companyGroups: CompanyGroup[]
  createCompanyGroup: (name: string) => void
  createUser: (body: PostUserBody) => void
  deleteCompanyGroup: (id: string, callback?: (err?: boolean) => void) => void
  deleteUser: (id: string) => void
  isHomepal?: boolean
  removeCompanyFromCompanyGroup: (
    id: string,
    companyId: string,
    callback?: (err?: boolean) => void
  ) => void
  roles: RoleReducerType
  updateCompany: (id: string, body: PatchCompanyBody) => void
  updateCompanyGroup: (
    id: string,
    name?: string,
    economySetId?: string | null,
    callback?: (err?: boolean) => void
  ) => void
  users: AccountType[]
}

const CompaniesTemplate = (props: Props) => {
  const {
    AuthStore,
    addCompanyToCompanyGroup,
    companies,
    companyGroups,
    createCompanyGroup,
    createUser,
    deleteCompanyGroup,
    deleteUser,
    removeCompanyFromCompanyGroup,
    updateCompany,
    updateCompanyGroup,
    users
  } = props
  const [
    showCompaniesWithoutCompanyGroup,
    setShowCompaniesWithoutCompanyGroup
  ] = useState<boolean>(false)

  const [createCompanyGroupOpen, setCreateCompanyGroupOpen] =
    useState<boolean>(false)
  const [newCompanyGroupName, setNewCompanyGroupName] = useState<string>('')

  const [editCompanyGroup, setEditCompanyGroup] =
    useState<ParsedCompanyGroup | null>(null)

  const [companyGroupToDelete, setCompanyGroupToDelete] =
    useState<ParsedCompanyGroup | null>(null)

  const [removingCompanyGroup, setRemovingCompanyGroup] =
    useState<boolean>(false)

  const parsedCompanyGroups: ParsedCompanyGroup[] = useMemo(() => {
    const newGroups = companyGroups.map((companyGroup) => {
      const filteredCompanies = companies.filter(
        (company) =>
          companyGroup.companies.includes(company.id) && !company.ignore
      )

      return {
        id: companyGroup.id,
        name: companyGroup.name,
        accounts: users.filter((u) => u.company_group_id === companyGroup.id),
        companies: filteredCompanies
      }
    })

    if (editCompanyGroup) {
      setEditCompanyGroup(
        newGroups.find(
          (g) => g.id === editCompanyGroup.id
        ) as ParsedCompanyGroup
      )
    }

    return newGroups
  }, [companies, companyGroups, users])

  const companiesWithoutCompanyGroup = useMemo(
    () =>
      companies.filter(
        (company) =>
          !companyGroups.some((companyGroup) =>
            companyGroup.companies.includes(company.id)
          ) && !company.ignore
      ),
    [companies, companyGroups]
  )

  return (
    <>
      {AuthStore.customer?.is_management_company ? (
        <CollapsibleSection
          title="Koncerner"
          sx={{ flexGrow: 1, maxWidth: 'section' }}
          actions={
            <Button
              onClick={() => setCreateCompanyGroupOpen(true)}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
            >
              Lägg till
            </Button>
          }
        >
          {companiesWithoutCompanyGroup.length > 0 ? (
            <Alert
              severity="warning"
              icon={<Icon name="WarningAmberOutlined" />}
            >
              <strong>
                {companiesWithoutCompanyGroup.length} bolag saknar
                koncerntillhörighet.
              </strong>{' '}
              Information som är kopplat till bolag som saknar
              koncerntillhörighet går inte att dela till externa användare.{' '}
              {/* eslint-disable-next-line max-len */}
              <Link onClick={() => setShowCompaniesWithoutCompanyGroup(true)}>
                Tryck här för att se vilka bolag som saknar koncern.
              </Link>{' '}
              Om du behöver hjälp kontakta supporten på{' '}
              <Link href={'mailto:support@homepal.se'}>support@homepal.se</Link>{' '}
              eller skriv ett meddelande i chatten.
            </Alert>
          ) : null}

          <List>
            {parsedCompanyGroups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((companyGroup) => (
                <ListItem
                  key={companyGroup.id}
                  secondaryAction={
                    <>
                      <Button
                        onClick={() => setEditCompanyGroup(companyGroup)}
                        variant="text"
                      >
                        Redigera
                      </Button>
                      <Button
                        // eslint-disable-next-line max-len
                        onClick={() => setCompanyGroupToDelete(companyGroup)}
                        variant="text"
                      >
                        Ta bort
                      </Button>
                    </>
                  }
                >
                  <Icon color="disabled" name="Business" />
                  <ListItemText
                    sx={{ ml: 3 }}
                    primary={companyGroup.name}
                    secondary={`${companyGroup.companies.length} bolag • ${companyGroup.accounts.length} användare`}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              ))}
          </List>
        </CollapsibleSection>
      ) : null}

      <CollapsibleSection
        title="Bolag"
        sx={{ flexGrow: 1, maxWidth: 'section', mt: 2 }}
      >
        <Alert severity="info" icon={<Icon name="InfoOutlined" />}>
          Bolag som Homepal läst in från underliggande system. Avsluta
          synkronisering av ett bolag för att all underliggande data kopplat
          till bolaget ska försvinna från era dashboards. Detta bör användas för
          till exempel testbolag eller inaktiva bolag. Uppdateringar kommer slå
          igenom i era dashboards vid nästa inläsning.
        </Alert>
        <List>
          {companies
            .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
            .map((company) => (
              <ListItem
                key={company.id}
                secondaryAction={
                  <FormControlLabel
                    control={<Switch color="success" size="small" />}
                    checked={!company.ignore}
                    onChange={() =>
                      updateCompany(company.id, {
                        data: { ignore: !company.ignore }
                      })
                    }
                    label="Synkronisera"
                    labelPlacement="end"
                  />
                }
              >
                <Icon color="disabled" name="ApartmentOutlined" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={company.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
        </List>
      </CollapsibleSection>

      {/* DIALOGS */}
      <EditCompanyGroupDialog
        addCompany={(companyId, callback) =>
          addCompanyToCompanyGroup(
            editCompanyGroup?.id as string,
            companyId,
            callback
          )
        }
        companiesWithoutCompanyGroup={companiesWithoutCompanyGroup}
        companyGroup={editCompanyGroup}
        createUser={createUser}
        deleteUser={deleteUser}
        isHomepal={props.isHomepal}
        onClose={() => setEditCompanyGroup(null)}
        open={Boolean(editCompanyGroup)}
        removeCompany={(companyId, callback) =>
          removeCompanyFromCompanyGroup(
            editCompanyGroup?.id as string,
            companyId,
            callback
          )
        }
        updateCompanyGroup={updateCompanyGroup}
        roles={props.roles}
      />
      <SimpleDialog
        title={'Radera koncern?'}
        open={Boolean(companyGroupToDelete)}
        onClose={() => setCompanyGroupToDelete(null)}
        actions={
          <>
            <Button
              variant="text"
              onClick={() => setCompanyGroupToDelete(null)}
            >
              Stäng
            </Button>
            <Button
              color="error"
              onClick={() => {
                setRemovingCompanyGroup(true)
                deleteCompanyGroup(
                  (companyGroupToDelete as ParsedCompanyGroup).id,
                  (err) => {
                    if (!err) {
                      setCompanyGroupToDelete(null)
                    }

                    setRemovingCompanyGroup(false)
                  }
                )
              }}
              loading={removingCompanyGroup}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Koncernen <code>{companyGroupToDelete?.name}</code> kommer att
            raderas permanent.{' '}
            <b>Användare som tillhör koncernen kommer raderas</b> och bolag
            kommer att läggas under saknar koncern tillhörighet. Är du säker?
          </>
        }
      />
      <SimpleDialog
        actions={
          <Button
            variant="text"
            onClick={() => {
              setCreateCompanyGroupOpen(false)
              setNewCompanyGroupName('')
            }}
          >
            Stäng
          </Button>
        }
        onClose={() => {
          setCreateCompanyGroupOpen(false)
          setNewCompanyGroupName('')
        }}
        open={createCompanyGroupOpen}
        title={'Skapa en koncern'}
      >
        <TextField
          label="Koncernnamn"
          required={true}
          autoComplete="off"
          value={newCompanyGroupName}
          onChange={(event) => setNewCompanyGroupName(event.target.value)}
          sx={{ mt: 0, mb: 2 }}
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            createCompanyGroup(newCompanyGroupName)

            setCreateCompanyGroupOpen(false)
            setNewCompanyGroupName('')
          }}
        >
          Skapa
        </Button>
      </SimpleDialog>
      <AdvancedDialog
        actions={
          <Button
            variant="text"
            onClick={() => setShowCompaniesWithoutCompanyGroup(false)}
          >
            Stäng
          </Button>
        }
        onClose={() => setShowCompaniesWithoutCompanyGroup(false)}
        open={showCompaniesWithoutCompanyGroup}
        title={'Bolag utan koncerntillhörighet'}
      >
        <List>
          {companiesWithoutCompanyGroup
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((company) => (
              <ListItem key={company.id}>
                <Icon color="disabled" name="ApartmentOutlined" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={company.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
        </List>
      </AdvancedDialog>
    </>
  )
}

export default CompaniesTemplate
