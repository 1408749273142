import React from 'react'

import TextField from 'components_new/atoms/TextField'

interface EditVariableSectionProps {
  editName: string
  isDuplicate: boolean
  setEditName: (value: string) => void
}

const EditVariableSection = (props: EditVariableSectionProps) => {
  const { editName, isDuplicate, setEditName } = props

  return (
    <TextField
      label="Namn"
      value={editName}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(event.target.value)
      }}
      error={isDuplicate}
      helperText={
        isDuplicate ? 'Det finns redan en kontonivå med det namnet' : undefined
      }
    />
  )
}

export default EditVariableSection
