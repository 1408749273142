import React from 'react'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import Link from 'components_new/atoms/Link'

export interface NoTargetProps {
  embedded: boolean
  isAdmin: boolean
  scaleFactor: number
}

const NoTarget = (props: NoTargetProps) => {
  const { embedded, isAdmin, scaleFactor } = props

  return (
    <EmptyPlaceholder
      actions={
        !embedded && isAdmin ? (
          <Link href="/admin/settings/config">Konfigurera nyckeltal</Link>
        ) : undefined
      }
      background={true}
      empty={true}
      title="Ditt nyckeltal verkar saknar nödvändig konfigurering."
      icon="CrisisAlertTwoTone"
      scaleFactor={scaleFactor}
    />
  )
}

export default NoTarget
