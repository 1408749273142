import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <Skeleton
      variant="rectangular"
      height={48}
      width={'100%'}
      sx={{ borderRadius: '24px' }}
    />
  )
}

Loading.displayName = 'Loading'

export default Loading
