import React, { useRef, useEffect, useState } from 'react'

import * as Api from 'redux/api/internal/Chat'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { useTheme } from '@mui/material'

interface LiveChatProps {
  user: {
    email: string
    id: string
    name: string
  }
}

const LiveChat = (props: LiveChatProps) => {
  const { user } = props
  const theme = useTheme()
  const [hash, setHash] = useState<string | null>(null)

  const tawkMessengerRef = useRef<any>()

  useEffect(() => {
    Api.getHash()
      .then((response) => {
        setHash(response.data)
      })
      .catch(() => {
        // do nothing
      })
  }, [])

  const onLoad = () => {
    tawkMessengerRef.current.setAttributes(
      {
        email: user.email,
        name: user.name,
        hash: hash,
        userId: user.id
      },
      function () {
        // do nothing
      }
    )
  }

  return hash ? (
    <TawkMessengerReact
      customStyle={{ zIndex: theme.zIndex.fab }}
      onLoad={onLoad}
      propertyId={process.env.REACT_APP_TAWK_PORTAL_PROPERTY_ID ?? ''}
      ref={tawkMessengerRef}
      widgetId={'1hkehjck1'}
    />
  ) : null
}

LiveChat.displayName = 'LiveChat'
export default LiveChat
