import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as NavigationActions from 'redux/actions/Navigation'
import * as AlertActions from 'redux/actions/Alert'
import * as DataProductActions from 'redux/actions/Applications/DataProducts'

import PageHeader from 'components/common/PageHeader/v2'

import ManageDatasetModal from 'components/containers/Applications/DataProducts/ManageDatasetModal'

import EditName from 'components/containers/Applications/DataProducts/modals/EditName'

import Box from 'components_new/atoms/Box'

import NavigationHeader from 'components_new/molecules/NavigationHeader'

import PageContainer from 'components_new/organisms/PageContainer'

import Connection from './Connection'
import Dataset from './Dataset'

import {
  getMetaInformation,
  toggleItems,
  getActions
} from 'helpers/Functions/DataProducts'

const SpecificAPI = ({
  match,
  history,
  AppsDataProductStore,
  UserStore,
  setNavigation,
  setAlert,
  resetAlert,
  tryDeleteDataProduct,
  tryUpdateDataProduct,
  location
}) => {
  const [activeToggle, setActiveToggle] = useState('connection')
  const [editName, setEditName] = useState(false)

  const dataProduct = AppsDataProductStore.data[match.params.id]

  useEffect(() => {
    setNavigation({
      paths: ['/access-hub', `/access-hub/${dataProduct.id}/${activeToggle}`],
      labels: ['Delningar', dataProduct.attributes.name]
    })
  }, [])

  useEffect(() => {
    const pathList = location.pathname.split('/')

    setActiveToggle(pathList[3])
  }, [location])

  return (
    <PageContainer navHeader={<NavigationHeader title="API" />}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <PageHeader
            header={dataProduct.attributes.name}
            meta={getMetaInformation(dataProduct, UserStore)}
            toggleItems={toggleItems(
              history,
              match.params.id,
              setActiveToggle,
              'API'
            )}
            activeToggle={activeToggle}
            actions={getActions(
              setAlert,
              resetAlert,
              () => {
                tryDeleteDataProduct(
                  dataProduct.id,
                  dataProduct.attributes.type
                )
                history.push('/access-hub')
              },
              setEditName,
              'API'
            )}
          />

          {activeToggle === 'connection' && (
            <Connection dataProduct={dataProduct} />
          )}
          {activeToggle === 'dataset' && <Dataset />}

          <EditName
            visible={editName}
            dataProduct={dataProduct}
            dataProducts={AppsDataProductStore.data}
            onClose={() => setEditName(false)}
            onSave={(attributes) =>
              tryUpdateDataProduct(dataProduct.id, attributes)
            }
          />
          <ManageDatasetModal type={dataProduct.attributes.type} />
        </Box>
      </Box>
    </PageContainer>
  )
}

function mapStateToProps({
  AppsDataProductStore,
  UserStore,
  NavigationMenuStore
}) {
  return { AppsDataProductStore, UserStore, NavigationMenuStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...NavigationActions,
      ...AlertActions,
      ...DataProductActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SpecificAPI))
