import React, { useState, useEffect, useMemo } from 'react'

import ShortId from 'shortid'
import { cloneDeep } from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'
import InputNumber from 'components/common/InputNumber'
import DatePicker from 'components/common/DatePicker'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import ToggleButton from 'components_new/atoms/ToggleButton'

import Node from './index.js'

import {
  getDataTypes,
  getSensitiveRefColumn,
  isDateType,
  isNumberType,
  parseAttributeOptions
} from './conf.js'

import InnerFilterContent from './FilterNode/InnerFilterContent.js'

const ReplaceNode = ({ id, selected, data, setData }) => {
  const [valueType, setValueType] = useState(false)
  const [newColumn, setNewColumn] = useState(false)
  const [newAttributeId, setNewAttributeId] = useState(ShortId.generate())
  const [newAttributeName, setNewAttributeName] = useState(null)
  const [leftAttribute, setLeftAttribute] = useState(null)
  const [isStandardValue, setIsStandardValue] = useState(false)

  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  useEffect(() => {
    const debounce = setTimeout(() => {
      const tmpOutput = cloneDeep(data.outputAttributes)
      const newAttributeIndex = tmpOutput.findIndex(
        (attr) => attr.shortId === data.newAttribute
      )

      if (newAttributeIndex > -1 && newColumn) {
        tmpOutput[newAttributeIndex].name = newAttributeName
        tmpOutput[newAttributeIndex].realName = newAttributeName

        setData(id, { outputAttributes: tmpOutput })
      }
    }, 500)

    return () => clearTimeout(debounce)
  }, [newAttributeName])

  useEffect(() => {
    if (data.rightAttribute) {
      setValueType(true)
    }
  }, [data.rightAttribute])

  useEffect(() => {
    if (data.newAttribute) {
      const newAttribute = data.outputAttributes.find(
        (oa) => oa.id === data.newAttribute
      )

      if (newAttribute) {
        setNewAttributeName(newAttribute.name)
        setNewColumn(true)
        setNewAttributeId(newAttribute.shortId)
      }
    }
  }, [data.newAttribute])

  useEffect(() => {
    if (data.leftAttribute) {
      const newLeftAttr = leftAttributes.find(
        (leftAttr) => leftAttr.shortId === data.leftAttribute
      )

      if (leftAttribute && newLeftAttr.type !== leftAttribute.type) {
        setData(id, { rightAttribute: null })
      }

      setLeftAttribute(
        leftAttributes.find(
          (leftAttr) => leftAttr.shortId === data.leftAttribute
        )
      )
    } else {
      setLeftAttribute(null)
      setData(id, { rightAttribute: null })
    }
  }, [data.leftAttribute])

  useEffect(() => {
    // Set state on mount
    if (data.value === 'NULL') {
      setIsStandardValue(true)
    }
  }, [data.value])

  useEffect(() => {
    if (leftAttributes.length > 0) {
      const realNamesInOutAttributes = data.outputAttributes
        .filter((attr) => attr.shortId !== data.newAttribute)
        .map((attr) => attr.realName)

      const clearAttributes = !leftAttributes.every((attr) =>
        realNamesInOutAttributes.includes(attr.realName)
      )

      if (clearAttributes) {
        setLeftAttribute(null)
        setData(id, {
          outputAttributes: resetOutputAttributes(
            data,
            leftAttributes,
            newAttributeId
          ),
          filterConditions: [],
          leftAttribute: null,
          rightAttribute: null,
          value: null
        })
      }
    }
  }, [leftAttributes])

  return (
    <Node
      icon="SwapHorizOutlined"
      title={'Replace'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.25,
          width: '240px'
        }}
      >
        <Select
          className={'nodrag'}
          search
          ignoreLockedMode
          inNode
          size={'small'}
          placeholder={<small className="secondary tiny">Attribut</small>}
          value={data.leftAttribute}
          onChange={(leftAttribute) => {
            const tmpOutput = cloneDeep(data.outputAttributes)

            if (data.newAttribute) {
              const newAttributeIndex = tmpOutput.findIndex(
                (attr) => attr.shortId === data.newAttribute
              )

              if (newAttributeIndex > -1) {
                tmpOutput[newAttributeIndex].sensitiveRefColumnId =
                  getSensitiveRefColumn(
                    leftAttribute,
                    data.rightAttribute,
                    leftAttributes
                  )
              }
            }

            setData(id, { leftAttribute, outputAttributes: tmpOutput })
          }}
          options={parseAttributeOptions(leftAttributes, data.nodeNumberMapper)}
        />
        <ToggleButton
          className={'nodrag'}
          items={[
            { title: 'Värde', value: false },
            { title: 'Kolumn', value: true }
          ]}
          onChange={(event, value) => {
            setValueType(value)

            if (value) {
              // Change from value to column
              setData(id, { value: null })
            } else {
              // Change from column to value
              setData(id, { rightAttribute: null })
            }
          }}
          size="small"
          value={valueType}
        />
        {valueType ? (
          <Select
            className={'nodrag'}
            search
            ignoreLockedMode
            inNode
            size={'small'}
            placeholder={<small className="secondary tiny">Attribut</small>}
            value={data.rightAttribute}
            onChange={(rightAttribute) => {
              const tmpOutput = cloneDeep(data.outputAttributes)

              if (data.newAttribute) {
                const newAttributeIndex = tmpOutput.findIndex(
                  (attr) => attr.shortId === data.newAttribute
                )

                if (newAttributeIndex > -1) {
                  tmpOutput[newAttributeIndex].sensitiveRefColumnId =
                    getSensitiveRefColumn(
                      data.leftAttribute,
                      rightAttribute,
                      leftAttributes
                    )
                }
              }

              setData(id, { rightAttribute, outputAttributes: tmpOutput })
            }}
            options={parseAttributeOptions(
              leftAttributes,
              data.nodeNumberMapper,
              getDataTypes(leftAttribute)
            )}
          />
        ) : (
          <>
            <Select
              className={'nodrag'}
              options={[
                {
                  label: <small className="secondary tiny">Värde</small>,
                  value: false
                },
                {
                  label: (
                    <small className="secondary tiny">Standardvärde</small>
                  ),
                  value: true
                }
              ]}
              clearable={false}
              value={isStandardValue}
              onChange={(value) => {
                setIsStandardValue(value)
                setData(id, { value: null })
              }}
              ignoreLockedMode
              inNode
              size={'small'}
            />
            <ConditionalValue
              id={id}
              data={data}
              setData={setData}
              leftAttribute={leftAttribute}
              isStandardValue={isStandardValue}
            />
          </>
        )}
        <ToggleButton
          className={'nodrag'}
          items={[
            { title: 'Samma kolumn', value: false },
            { title: 'Ny kolumn', value: true }
          ]}
          onChange={(event, value) => {
            setNewColumn(value)
            const tmpOutput = cloneDeep(data.outputAttributes)

            if (value) {
              // Create new column
              tmpOutput.push({
                leftAttribute: undefined,
                name: undefined,
                realName: undefined,
                shortId: newAttributeId,
                type: leftAttribute?.type || 'STRING',
                rightAttribute: undefined,
                prepend: id,
                delimiter: undefined,
                sensitiveRefColumnId: getSensitiveRefColumn(
                  data.leftAttribute,
                  data.rightAttribute,
                  leftAttributes
                )
              })
              setData(id, {
                outputAttributes: tmpOutput,
                newAttribute: newAttributeId
              })
            } else {
              // Remove new column
              const updatedOutputAttributes = tmpOutput.filter(
                (oa) => oa.shortId !== newAttributeId
              )

              setNewAttributeName(null)
              setData(id, {
                outputAttributes: updatedOutputAttributes,
                newAttribute: null
              })
            }
          }}
          size="small"
          value={newColumn}
        />
        {newColumn && (
          <Input
            className={'nodrag'}
            size={'small'}
            value={newAttributeName}
            placeholder={'Kolumn'}
            onChange={(value) => setNewAttributeName(value.target.value)}
          />
        )}
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box>
        <small className="secondary tiny">Villkor</small>
        <InnerFilterContent
          id={id}
          data={data}
          setData={setData}
          nodePadding={false}
          filterOutNewAttribute={data.newAttribute}
        />
      </Box>
    </Node>
  )
}

const ConditionalValue = ({
  id,
  data,
  setData,
  leftAttribute,
  isStandardValue
}) => {
  if (isStandardValue) {
    return (
      <Select
        className={'nodrag'}
        size="small"
        inNode
        value={data.value}
        onChange={(value) => setData(id, { value })}
        ignoreLockedMode
        options={[{ label: 'NULL', value: 'NULL' }]}
      />
    )
  }

  if (leftAttribute && isDateType(leftAttribute)) {
    return (
      <DatePicker
        size="small"
        value={data.value}
        picker={'date'}
        onChange={(value) => setData(id, { value })}
        ignoreLockedMode
      />
    )
  } else if (leftAttribute && isNumberType(leftAttribute)) {
    return (
      <InputNumber
        size={'small'}
        value={data.value}
        placeholder={'Värde'}
        onChange={(value) => setData(id, { value })}
      />
    )
  }

  return (
    <Input
      size={'small'}
      value={data.value}
      placeholder={'Värde'}
      onChange={(value) => setData(id, { value: value.target.value })}
    />
  )
}

const resetOutputAttributes = (data, leftAttributes, newAttributeId) => {
  const newAttribute = data.outputAttributes.find(
    (attr) => attr.shortId === newAttributeId
  )

  return [
    ...leftAttributes.map((attr) => ({
      ...attr,
      leftAttribute: null,
      rightAttribute: null,
      shortId: ShortId.generate()
    })),
    newAttribute
  ].filter(Boolean)
}

export default ReplaceNode
