import React from 'react'

import { Segmented } from 'antd'

import './styles.css'

/**
 * A component used to toggle between different values
 *
 * Segmented component from antd
 * https://ant.design/components/segmented/
 *
 * @param {string | number} value - Currently selected value
 * @param {function(value: string | number)} onChange - Callback
 * function that is triggered when the state changes
 * @param {string | array<{label: ReactNode, value: string}>} options - Options
 * that the toggle exists of
 * @param {large | middle | small} size - size of the toggle
 * large = 2.5rem, middle = 2rem, small = 1.5rem
 * @returns A toggle
 */
const Toggle = ({ value, onChange, options, className, size = 'middle' }) => {
  return (
    <Segmented
      value={value}
      onChange={onChange}
      options={options}
      block
      className={className}
      size={size}
    />
  )
}

export default Toggle
