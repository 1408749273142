export enum SurveyType {
  DidYouFindTheInformation = 'DidYouFindTheInformation'
}

export enum SurveyResponseType {
  NoResponse = 'NoResponse',
  Yes = 'Yes',
  No = 'No',
  Closed = 'Closed'
}

export const translateSurveyResponseTypeEnum: Record<
  SurveyResponseType,
  string
> = {
  [SurveyResponseType.NoResponse]: 'Inget svar',
  [SurveyResponseType.Yes]: 'Ja',
  [SurveyResponseType.No]: 'Nej',
  [SurveyResponseType.Closed]: 'Stängde utan svar'
}

export type PostSurveyBody = {
  type: SurveyType
}

export type PatchSurveyBody = {
  response: SurveyResponseType
}

export type Survey = {
  id: string
  customer: {
    id: string
    name: string
  }
  user: {
    id: string
    name: string
  }
  type: SurveyType
  response: SurveyResponseType
  created_at: Date
  updated_at: Date
}

export type SurveyReducerType = {
  data: Survey[]
  fetched: false
}
