import React from 'react'

import { Empty, Tree as AntdTree } from 'antd'

import Icon from 'components_new/atoms/Icon'

import './styles.css'
import Styles from './styles.module.css'

/**
 * Tree component from antd, adjusted to Homepal's use cases
 * https://ant.design/components/tree/
 *
 * @param {boolean} checkable - Enables checkboxes before the nodes
 * @param {array} data - An array with objects containing data with props
 * { title: ReactNode, key: string, children: array }
 * @param {ReactNode} header - ReactNode containing a header for the tree
 * @param {number} height - Max height of tree, needs to be a number for
 * scroll to work as intended
 * @param {array} checkedKeys - An array containing all checked keys
 * @param {() => array} setCheckedKeys - Function that sets what keys
 * are checked
 * @param {string} className - Class name for the surrounding container
 * @returns a tree of nodes
 */
const Tree = ({
  checkable = true,
  data = [],
  header,
  height,
  checkedKeys,
  setCheckedKeys,
  className
}) => {
  return (
    <div className={`${Styles.container} ${className}`}>
      {header}

      {data.length === 0 ? (
        <Empty
          className={Styles['empty-tree']}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'Ingen data'}
        />
      ) : (
        <AntdTree
          checkable={checkable}
          blockNode
          selectable={false}
          treeData={data}
          switcherIcon={({ expanded }) => (
            <Icon
              name={expanded ? 'ExpandMore' : 'ChevronRight'}
              sx={{ fontSize: '24px!important' }}
            />
          )}
          height={height}
          checkedKeys={checkedKeys}
          onCheck={(checkedKeysValue) => setCheckedKeys(checkedKeysValue)}
        />
      )}
    </div>
  )
}

export default Tree
