import React, {
  ChangeEventHandler,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode
} from 'react'
import { InputBase as MUIInputBase } from '@mui/material'

interface InputBaseProps {
  autoComplete?: string
  autoFocus?: boolean
  component?: ElementType<any>
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  defaultValue?: ReactNode
  disabled?: boolean
  endAdornment?: ReactNode
  error?: boolean
  fullWidth?: boolean
  inputProps?: object
  label?: ReactNode
  margin?: 'dense' | 'none'
  maxRows?: number | string
  minRows?: number | string
  multiline?: boolean
  name?: string
  onBlur?: () => void
  onChange?: (() => void) | ChangeEventHandler<HTMLInputElement>
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onFocus?:
    | (() => void)
    | FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onKeyUp?: (() => void) | KeyboardEventHandler<HTMLInputElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  rows?: number | string
  size?: 'medium' | 'small'
  sx?: object
  startAdornment?: ReactNode
  value?: any
}

/**
 * The InputBase component contains as few styles
 * as possible. It aims to be a simple building
 * block for creating an input. It contains a load
 * of style reset and some state logic.
 */

const InputBase = React.forwardRef((props: InputBaseProps, ref: any) => {
  return <MUIInputBase {...props} ref={ref} />
})

InputBase.displayName = 'InputBase'
export default InputBase
