import React from 'react'

import {
  InnerWidgetPatchBody,
  TViewport,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

interface ViewportSectionProps {
  setViewport: (value: TViewport) => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  viewport: TViewport
  widget: WidgetObject
}

const LEGAL_TYPES = [WidgetType.MAP]

const ViewportSection = (props: ViewportSectionProps) => {
  const { updateWidget, viewport, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  return (
    <>
      <SettingsGroup
        helperText={
          <>
            Flytta och zooma kartan till önskat läge. Klicka sedan på{' '}
            <b>Spara</b> ovan för att uppdatera kartans startposition.
          </>
        }
        title="Kamera"
      >
        <SettingsItem title="Longitud" value={viewport.longitude} />
        <SettingsItem title="Latitud" value={viewport.latitude} />
        <SettingsItem title="Zoom" value={viewport.zoom} />
        <SettingsItem
          disabled={
            viewport.longitude === widget.settings.viewport.longitude &&
            viewport.latitude === widget.settings.viewport.latitude &&
            viewport.zoom === widget.settings.viewport.zoom
          }
          onClick={() => {
            updateWidget(widget.id, {
              latitude: viewport.latitude,
              longitude: viewport.longitude,
              zoom: viewport.zoom
            })
          }}
          title="Spara"
          variant="action"
        />
      </SettingsGroup>

      {/* TODO: Pitch does not change correct in frontend, I
      believe it is due to initMap.on('move') in map component */}
      {/*<ListItem>
        <ListItemText primary="Lutning" />
        <ListItemSecondaryAction>
          <Switch
            color="primary"
            checked={widget.settings.viewport.pitch === 60}
            onChange={() => {
              const newValue = widget.settings.viewport.pitch === 60 ? 0 : 60

              updateWidget(widget.id, {
                pitch: newValue
              })

              setViewport({
                latitude: viewport.latitude,
                longitude: viewport.longitude,
                zoom: viewport.zoom,
                pitch: newValue
              })
            }}
          />
        </ListItemSecondaryAction>
          </ListItem>*/}
    </>
  )
}

export default ViewportSection
