import React, { useEffect, useMemo, useState } from 'react'

import InputAdornment from 'components_new/atoms/InputAdornment'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'
import Button from 'components_new/atoms/Button'
import { General, translateGeneralConfigAttributes } from 'types/GlobalConfig'
import ToggleButton from 'components_new/atoms/ToggleButton'

interface GeneralSettingsSectionProps {
  settings: {
    [attribute: string]: General
  }
  onUpdate: (body: { [attribute: string]: number | null }) => void
}

const GeneralSettingsSection = (props: GeneralSettingsSectionProps) => {
  const { onUpdate, settings } = props

  const defaultGenerals = useMemo(() => {
    const state: { [attribute: string]: number | string } = {}

    Object.values(settings).forEach((item) => {
      state[item.attribute] = item.value
    })

    return state
  }, [settings])

  const [generals, setGenerals] = useState(defaultGenerals)

  useEffect(() => setGenerals(defaultGenerals), [defaultGenerals])

  return (
    <Table size="small">
      <TableBody>
        {Object.entries(generals).map(([attribute, value], i) => (
          <TableRow key={i}>
            <TableCell>
              <Text sx={{ flexShrink: 0 }}>
                {translateGeneralConfigAttributes[attribute]}
              </Text>
            </TableCell>
            <TableCell>
              {settings[attribute].options.length > 0 ? (
                <ToggleButton
                  color={'primary'}
                  exclusive={true}
                  fullWidth={true}
                  items={settings[attribute].options.map((option) => ({
                    title: option.label,
                    value: option.value
                  }))}
                  value={value}
                  onChange={(_, value) => {
                    onUpdate({ [attribute]: value })
                    setGenerals((prevState) => ({
                      ...prevState,
                      [attribute]: value
                    }))
                  }}
                />
              ) : (
                <TextField
                  type="number"
                  InputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    endAdornment: settings[attribute].unit ? (
                      <InputAdornment position="end">
                        {settings[attribute].unit}
                      </InputAdornment>
                    ) : null
                  }}
                  onBlur={(e) => {
                    if (
                      parseInt(e.target.value) !== settings[attribute].value
                    ) {
                      onUpdate({ [attribute]: parseInt(e.target.value) })
                    }
                  }}
                  onChange={(e) =>
                    setGenerals((prevState) => ({
                      ...prevState,
                      [attribute]: parseInt(e.target.value)
                    }))
                  }
                  size="small"
                  sx={{ m: 0, p: 0 }}
                  value={value}
                />
              )}
            </TableCell>
            <TableCell>
              <Tooltip title="Återställ till Homepals standardvärde">
                <Button
                  variant="text"
                  onClick={() => onUpdate({ [attribute]: null })}
                >
                  Återställ
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default GeneralSettingsSection
