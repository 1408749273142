import React from 'react'

import { getStatus } from '../utils'
import { formatValue, getUnitLabel } from '../../utils'
import { translateStatus } from 'utils/enumTranslator'

import Box from 'components_new/atoms/Box'
import FitText from 'components_new/molecules/FitText'

import { WidgetObject } from 'types/GlobalWidget'

interface ValueSectionProps {
  widget: WidgetObject
}

const ValueSection = (props: ValueSectionProps) => {
  const { widget } = props

  const increaseIsPositive =
    widget.settings.kpi_options[0].increase_is_positive ?? true
  const lowerThresholdValue =
    widget.settings.kpi_options[0].lower_bound_threshold ?? null
  const numberOfDecimals =
    widget.settings.kpi_options[0].number_of_decimals || 0
  const unit = widget.settings.kpi_options[0].unit || null
  const unitPrefix = widget.settings.kpi_options[0].unit_prefix
  const unitPrefixSelected =
    widget.settings.kpi_options[0].unit_prefix.selected || null
  const upperThresholdValue =
    widget.settings.kpi_options[0].upper_bound_threshold ?? null
  const value = widget.data?.value || 0
  const equivalentFactor = widget.settings.kpi_options[0].equivalent.factor
  const equivalentUnit = widget.settings.kpi_options[0].equivalent.unit

  const formattedValue = formatValue(
    value,
    unitPrefixSelected,
    numberOfDecimals,
    false,
    equivalentFactor
  ) as number | null

  const status = getStatus(
    formattedValue,
    upperThresholdValue,
    lowerThresholdValue,
    increaseIsPositive
  )

  return (
    <FitText variant="h1">
      <Box
        component="span"
        sx={{
          display: 'block',
          color: status
            ? `${translateStatus[status].colorName}.text`
            : undefined
        }}
      >
        {formatValue(
          value,
          unitPrefixSelected,
          numberOfDecimals,
          true,
          equivalentFactor
        )}
        <Box component="span" sx={{ fontSize: '0.6em', ml: '0.2em' }}>
          {getUnitLabel(unit, unitPrefix, equivalentUnit)}
        </Box>
      </Box>
    </FitText>
  )
}

export default ValueSection
