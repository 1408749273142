import React, { MouseEvent, useState } from 'react'

import { ChromePicker } from 'react-color'

import { Popover, useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'
import { getContrastTextColor } from 'themes'

interface ColorInputProps {
  border?: boolean
  disabled?: boolean
  hexCode: string
  isDefault: boolean
  onChange?: (hexCode: string) => void
  onReset?: () => void
  sx?: object
  title?: string
}

const ColorInput = React.forwardRef((props: ColorInputProps, ref: any) => {
  const { border, isDefault, disabled, hexCode, onChange, onReset, sx, title } =
    props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const [editColor, setEditColor] = useState<string>(hexCode)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'action.hover',
          width: 'fit-content',
          p: 1,
          borderRadius: 1,
          ...sx
        }}
        ref={ref}
      >
        <Text align="center" gutterBottom={true} variant="caption">
          {title}
        </Text>
        <Box>
          <Tooltip title={disabled ? 'Färgen går inte att redigera' : ''}>
            <Box
              onClick={(event) => {
                disabled ? null : handleClick(event)
              }}
              sx={{
                position: 'relative',
                cursor: disabled ? 'default' : 'pointer',
                width: 96,
                height: 96,
                borderRadius: 3,
                bgcolor: hexCode,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderStyle: border ? 'solid' : undefined,
                borderWidth: border ? 1 : undefined,
                borderColor: border ? 'action.focus' : undefined,
                // Animation:
                transition: 'transform 240ms ease 0s, box-shadow 240ms ease 0s',
                '&:hover': !disabled
                  ? {
                      transform: 'translateY(-4px)',
                      boxShadow: theme.shadows[12]
                    }
                  : undefined
              }}
            >
              <Text
                fontWeight="bold"
                variant="caption"
                color={getContrastTextColor(hexCode)}
              >
                {hexCode.toUpperCase()}
              </Text>
              <Box
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4
                }}
              >
                {disabled ? (
                  <Icon
                    fontSize="small"
                    name="LockOutlined"
                    sx={{
                      color: getContrastTextColor(hexCode)
                    }}
                  />
                ) : null}
                {!isDefault ? (
                  <Icon
                    fontSize="small"
                    name="EditOutlined"
                    sx={{
                      color: getContrastTextColor(hexCode)
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          </Tooltip>
        </Box>
        {!disabled ? (
          <Button
            disabled={isDefault}
            onClick={onReset}
            sx={{ mt: 0.5 }}
            variant="text"
          >
            Auto
          </Button>
        ) : null}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          onChange ? onChange(editColor) : undefined
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <ChromePicker
          color={editColor}
          onChange={(value) => setEditColor(value.hex)}
          disableAlpha
        />
      </Popover>
    </>
  )
})

export default ColorInput
