import { AttributeOption, VisualizationConstraint } from 'types/GlobalWidget'

export const getSegmentByOptions = (options: AttributeOption[]) => {
  const newOptions = options.map((option) => {
    return {
      label: option.name,
      value: option.id
    }
  })

  return newOptions
}

export const getSortOptions = (isDate: boolean) => {
  let options = []

  if (isDate) {
    options = [
      {
        label: 'Senast först',
        value: VisualizationConstraint.LARGEST
      },
      {
        label: 'Tidigast först',
        value: VisualizationConstraint.SMALLEST
      }
    ]
  } else {
    options = [
      {
        label: 'Störst värde först',
        value: VisualizationConstraint.LARGEST
      },
      {
        label: 'Minst värde först',
        value: VisualizationConstraint.SMALLEST
      },
      {
        label: 'Alfabetisk ordning (A till Ö)',
        value: VisualizationConstraint.SEGMENT_SMALLEST
      },
      {
        label: 'Alfabetisk ordning (Ö till A)',
        value: VisualizationConstraint.SEGMENT_LARGEST
      }
    ]
  }

  return options
}
