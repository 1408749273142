import React from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import SettingsTable from '../SettingsTable'

const DetailsDialog = ({ open, onClose }) => {
  return (
    <SimpleDialog
      onClose={onClose}
      open={open}
      title={'Detaljerade instruktioner'}
      actions={
        <Button onClick={() => onClose()} variant="text">
          Stäng
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <List>
          <ListItem>
            <ListItemIcon>1.</ListItemIcon>
            <ListItemText>
              Gå till <b>Azure Active Directory</b>.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>2.</ListItemIcon>
            <ListItemText>
              Gå till <b>Enterprise Applications</b> via den vänstra menyn.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>3.</ListItemIcon>
            <ListItemText>
              Skapa en ny applikation genom att klicka på <b>New application</b>
              , och välj sedan <b>Create your own application</b>. Ge den ett
              namn (t.ex.
              <i>
                {'"'}Homepal SSO{'"'}
              </i>
              ).{' '}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>4.</ListItemIcon>
            <ListItemText>
              Säkerställ att alternativet{' '}
              <b>
                Integrate any other application you don’t find in the gallery
                (Non-gallery)
              </b>{' '}
              är valt och klicka på <b>Create</b>.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>5.</ListItemIcon>
            <ListItemText>
              Gå till <b>Set up single sign on</b> och klicka på <b>SAML</b>.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>6.</ListItemIcon>
            <ListItemText>
              Redigera uppgifterna genom att klicka på <b>Edit</b> (under{' '}
              <b>Basic SAML Configuration</b>) och fyll i följande:
            </ListItemText>
          </ListItem>
        </List>
        <SettingsTable />
        <List>
          <ListItem>
            <ListItemIcon>7.</ListItemIcon>
            <ListItemText>Spara uppgifterna.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>8.</ListItemIcon>
            <ListItemText>
              Gå in under <b>SAML Certificates</b> och kopiera{' '}
              <b>App Federation Metadata Url</b>. Mata sedan in den i Homepal.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>10.</ListItemIcon>
            <ListItemText>
              Nu är du klar! 👍 Lägg till de användare (eller grupper) som ska
              ha tillgång till Homepal. Detta gör du under{' '}
              <b>Users and groups</b> i Microsoft Azure AD.
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </SimpleDialog>
  )
}

export default DetailsDialog
