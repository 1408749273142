import React from 'react'

import { KpiStatus } from 'types/GlobalKpiTemplates'
import { TStatus, translateKpiTemplateStatus } from './utils'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'

import IconTip from 'components_new/molecules/IconTip'

import StatusIcon from './StatusIcon'

interface StatusRowProps {
  value: TStatus
  variant: Exclude<keyof KpiStatus, 'perform_template_id'>
}

const StatusRow = (props: StatusRowProps) => {
  const { value, variant } = props

  const { title, body } = translateKpiTemplateStatus[variant][value]

  return (
    <TableRow key={variant}>
      <TableCell padding="checkbox">
        <StatusIcon disableTooltip={true} value={value} variant={variant} />
      </TableCell>
      <TableCell>
        <Box sx={{ py: 0.5 }}>
          <Text variant="subtitle2">
            {title}
            {value !== 'checked' && variant !== 'required_target_missing' ? (
              <IconTip
                size="small"
                title="Kontakta din systemägare för mer information."
              />
            ) : null}
          </Text>
          <Text variant="body2">{body}</Text>
          {value !== 'checked' && variant === 'required_target_missing' ? (
            <Box sx={{ p: 2 }}>
              <Button
                endIcon={<Icon name="ChevronRight" />}
                fullWidth
                size="small"
                to="/admin/settings/config"
                variant="text"
              >
                Konfigurera nyckeltal
              </Button>
            </Box>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default StatusRow
