import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import * as AuthenticationApi from 'redux/api/Authentication'

export function setLoggedIn() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.VALIDATE_USER
    })

    AuthenticationApi.whoAmI()
      .then((response) => {
        dispatch({
          type: Types.SET_SIGNED_IN,
          payload: {
            ...response.data.data.attributes
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.SIGN_OUT
        })
      })
  }
}

export function signOut() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.SIGN_OUT
    })
  }
}
