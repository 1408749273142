import * as Constants from 'helpers/Constants'

export const COLUMN_TYPES = Object.keys(Constants.BUILD_FORMATS).map((key) => ({
  value: Constants.BUILD_FORMATS[key].value,
  label: Constants.BUILD_FORMATS[key].label
}))

export const DATA_TYPES = {}

Object.keys(Constants.BUILD_FORMATS).forEach(
  (key) => (DATA_TYPES[key] = Constants.BUILD_FORMATS[key].dataType)
)
