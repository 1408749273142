import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemSecondaryAction from 'components_new/atoms/List/ListItemSecondaryAction'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import UploadFile from 'components_new/molecules/UploadFile'

import { InnerWidgetPatchBody, WidgetObject } from 'types/GlobalWidget'

interface ImageSectionProps {
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  uploadImage: (image: Blob, name: string) => void
  widget: WidgetObject
}

const ImageSection = (props: ImageSectionProps) => {
  const { updateWidget, uploadImage, widget } = props

  return (
    <SettingsGroup>
      <Box sx={{ width: '100%' }}>
        <UploadFile
          accept={['image/png', 'image/jpg', 'image/jpeg']}
          maxSize={2}
          uploadFile={(file) => uploadImage(file, file.name)}
        />
        {widget.image ? (
          <ListItem component="div" sx={{ bgcolor: 'action.hover', mt: 1 }}>
            <ListItemAvatar>
              <Box
                sx={{
                  position: 'relative',
                  height: '48px',
                  width: '48px',
                  backgroundImage: `url(${widget.image.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center 50%',
                  borderRadius: 1
                }}
              />
            </ListItemAvatar>
            <ListItemText primary={widget.image.name} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => updateWidget(widget.id, { image: null })}
              >
                <Icon name="DeleteOutline" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ) : null}
      </Box>
    </SettingsGroup>
  )
}

export default ImageSection
