import React, { FC, useEffect } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import ChartOfAccountsTemplate from 'components_new/templates/settings/ChartOfAccounts'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

import * as AccountActions from 'redux/actions/Accounts'
import * as AccountRoleActions from 'redux/actions/AccountRoles'
import * as CompanyActions from 'redux/actions/Companies'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as EconomySetActions from 'redux/actions/EconomySets'
import * as EconomyAccountActions from 'redux/actions/EconomyAccounts'

const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyStore: state.CompanyStore,
  CompanyGroupStore: state.CompanyGroupStore,
  EconomySetStore: state.EconomySetStore,
  NavigationMenuStore: state.NavigationMenuStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AccountRoleActions,
      ...CompanyActions,
      ...CompanyGroupActions,
      ...EconomyAccountActions,
      ...EconomySetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ChartOfAccountsPageProps = ConnectedProps<typeof connector>

const ChartOfAccountsPage: FC<ChartOfAccountsPageProps> = (
  props: ChartOfAccountsPageProps
) => {
  const {
    AccountStore,
    AuthStore,
    CompanyStore,
    CompanyGroupStore,
    EconomySetStore,
    tryGetAllAccounts,
    tryGetAllAccountRoles,
    tryGetAllCompanies,
    tryGetAllCompanyGroups,
    tryGetAllEconomySets,
    tryCreateEconomyAccount,
    tryCreateEconomySet,
    tryDeleteEconomyAccount,
    tryDeleteEconomySet,
    tryUploadEconomySet,
    tryUpdateCompanyGroup,
    tryUpdateEconomyAccount,
    tryUpdateEconomySet
  } = props

  useEffect(() => {
    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!CompanyStore.fetched) {
      tryGetAllCompanies()
    }

    if (!EconomySetStore.fetched && !EconomySetStore.fetching) {
      tryGetAllEconomySets()
    }
  }, [])

  return (
    <>
      <SettingsTemplate>
        {AccountStore.fetched &&
        AccountStore.rolesFetched &&
        CompanyStore.fetched &&
        CompanyGroupStore.fetched ? (
              <ChartOfAccountsTemplate
                AuthStore={AuthStore}
                companyGroups={Object.values(CompanyGroupStore.data)}
                createEconomyAccount={tryCreateEconomyAccount}
                createEconomySet={tryCreateEconomySet}
                deleteEconomyAccount={tryDeleteEconomyAccount}
                deleteEconomySet={tryDeleteEconomySet}
                economySets={Object.values(EconomySetStore.data)}
                updateCompanyGroup={tryUpdateCompanyGroup}
                updateEconomyAccount={tryUpdateEconomyAccount}
                updateEconomySet={tryUpdateEconomySet}
                uploadEconomySet={tryUploadEconomySet}
              />
            ) : (
              <LoadingSettings
                titles={['Kopplar konton', 'Läser in kontoplaner']}
              />
            )}
      </SettingsTemplate>
    </>
  )
}

export default connector(ChartOfAccountsPage)
