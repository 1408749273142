import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Card as MUICard } from '@mui/material'

interface CardProps {
  children: ReactNode
  component?: ElementType<any>
  elevation?: number
  onMouseEnter?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseLeave?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOver?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseOut?: (() => void) | MouseEventHandler<HTMLElement>
  raised?: boolean
  sx?: object
  variant?: 'elevation' | 'outlined' | undefined
}

/**
 * The Card component is a surface that contains
 * content and actions about a single subject.
 *
 * They should be easy to scan for relevant and
 * actionable information. Elements, like text
 * and images, should be placed on them in a way
 * that clearly indicates hierarchy.
 */

const Card = React.forwardRef((props: CardProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUICard {...rest} ref={ref}>
      {children}
    </MUICard>
  )
})

Card.displayName = 'Card'
export default Card
