import * as Api from 'redux/api/DataPlatform/connections/sourceTables/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryUpdateSourceTable(sourceTableId, body, activeSourceId) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_SOURCE_TABLE
    })

    Api.update(sourceTableId, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_SOURCE_TABLE_SUCCESS,
          payload: { ...response.data, activeSourceId }
        })

        messageSuccess('Tabellen och dess attribut har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_SOURCE_TABLE_FAILED
        })

        messageWarning('Tabellen och dess attribut kunde inte uppdateras')
      })
  }
}

export function tryGetSourceTablePreview(id, limit, offset) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_SOURCE_TABLE,
      payload: { id }
    })

    Api.getPreview(id, limit, offset)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_SOURCE_TABLE_SUCCESS,
          payload: { data: response.data, limit, offset }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_SOURCE_TABLE_FAILED
        })
      })
  }
}

export function tryGetStreamTablePreview(id, streamTableId, limit, offset) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ONE_SOURCE_TABLE,
      payload: { id }
    })

    Api.getStreamPreview(id, streamTableId, limit, offset)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_SOURCE_TABLE_SUCCESS,
          payload: { data: response.data, limit, offset }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_SOURCE_TABLE_FAILED
        })
      })
  }
}

export function tryCreateSourceTable(body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_SOURCE_TABLE
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_SOURCE_TABLE_SUCCESS,
          payload: {
            ...response.data,
            activeSourceId: body.data.attributes.active_source_id
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_SOURCE_TABLE_FAILED
        })
      })
  }
}

export function tryGetLogs(id, limit, offset) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_LOGS,
      payload: { id }
    })

    Api.getLogs(id, limit, offset)
      .then((response) => {
        dispatch({
          type: Types.GET_LOGS_SUCCESS,
          payload: { data: response.data, limit, offset }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_LOGS_FAILED
        })
      })
  }
}

export function tryPutColumnsAndFilters(sourceTableId, body, activeSourceId) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_COLUMNS_AND_FILTERS
    })

    Api.putColumnsAndFilters(sourceTableId, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_COLUMNS_AND_FILTERS_SUCCESS,
          payload: { ...response.data, activeSourceId }
        })

        messageSuccess('Mappningen och villkoren har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_COLUMNS_AND_FILTERS_FAILED
        })

        messageWarning('Mappningen och villkoren kunde inte uppdateras')
      })
  }
}

export function tryPutStreamVariables(id, activeSourceId, body) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_STREAM_VARIABLES
    })

    Api.putStreamVariables(id, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_STREAM_VARIABLES_SUCCESS,
          payload: {
            ...response.data,
            activeSourceId
          }
        })

        messageSuccess('Tabellens variabler har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_STREAM_VARIABLES_FAILED
        })

        messageWarning('Tabellen variabler kunde inte uppdateras')
      })
  }
}

export function tryCreateStreamTable(id, activeSourceId, body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_STREAM_TABLE
    })

    Api.createStreamTable(id, body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_STREAM_TABLE_SUCCESS,
          payload: {
            ...response.data,
            activeSourceId
          }
        })

        messageSuccess('Aggregering har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_STREAM_TABLE_FAILED
        })

        messageWarning('Aggregering kunde inte skapas')
      })
  }
}

export function tryDeleteStreamTable(id, sourceTableId, activeSourceId) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_STREAM_TABLE
    })

    Api.deleteStreamTable(id, sourceTableId)
      .then((response) => {
        dispatch({
          type: Types.DELETE_STREAM_TABLE_SUCCESS,
          payload: {
            ...response.data,
            activeSourceId
          }
        })

        messageSuccess('Aggregering har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_STREAM_TABLE_FAILED
        })

        messageWarning('Aggregering kunde inte tas bort')
      })
  }
}
