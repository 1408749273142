import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import CreateCard from 'components_new/molecules/CreateCard'
import LoadingSection from 'components_new/molecules/LoadingSection'

import { SIMTable } from 'redux/reducers/SIM'

interface CreateListDialogProps {
  lists: SIMTable[]
  onClose: () => void
  open: boolean
}

const CreateListDialog = (props: CreateListDialogProps) => {
  const history = useHistory()

  return (
    <AdvancedDialog
      title={'Välj en lista att utgå från'}
      open={props.open}
      onClose={props.onClose}
      actions={
        <Button variant="text" onClick={props.onClose}>
          Stäng
        </Button>
      }
    >
      <Stack direction="column" spacing={1}>
        <Text color="text.secondary" variant="body1" gutterBottom>
          Klicka på en lista för att modifiera en lista.
        </Text>
        {props.lists.length > 0 ? (
          props.lists.map((list) => (
            <CreateCard
              body={`En lista med alla ${list.plural_title.toLowerCase()} som existerar i Homepal.`}
              icon="ViewListTwoTone"
              key={list.id}
              onClick={() => history.push(`/lists/${list.id}`)}
              title={list.title}
              variant="outlined"
              disabled={!list.has_data}
              titleAdornments={
                !list.has_data && (
                  <Tooltip title={'Du verkar sakna data för den här listan'}>
                    <Icon
                      fontSize="small"
                      color="disabled"
                      name="LockOutlined"
                    />
                  </Tooltip>
                )
              }
            />
          ))
        ) : (
          <LoadingSection
            titles={[
              'Hämtar alla tabeller som går att exportera',
              'Samlar in data från dina system',
              'Synkroniserar och bearbetar din data'
            ]}
            loading
          />
        )}
      </Stack>
    </AdvancedDialog>
  )
}

export default CreateListDialog
