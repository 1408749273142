import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let data = {}

  switch (type) {
  case Types.GET_ALL_ROLES_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload),
      fetched: true
    }

  case Types.CREATE_ROLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.data.id]: payload.data
      }
    }

  case Types.UPDATE_ROLE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_ROLE_SUCCESS:
    data = { ...state.data }
    delete data[payload]

    return { ...state, data }

  case Types.DELETE_MANY_ROLES_SUCCESS:
    data = { ...state.data }

    payload.forEach((id) => {
      delete data[id]
    })

    return { ...state, data }
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
