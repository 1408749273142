import React from 'react'
import { Select } from 'antd'

import Icon from 'components_new/atoms/Icon'

import './styles.css'
import Styles from './styles.module.css'
import IconButton from 'components_new/atoms/IconButton'
const { Option } = Select

const parseValue = (option, greyText, inNode) => {
  const lab = option.searchLabel ? option.searchLabel : option.label

  return inNode ? (
    <small
      className={`tiny width-100 overflow-ellipsis ${
        greyText ? 'secondary' : ''
      }`}
    >
      {lab}
    </small>
  ) : (
    lab
  )
}

const parseOption = (option, greyText, icon, search, inNode) => {
  return inNode ? (
    <small className={`tiny width-100 ${greyText ? 'secondary' : ''}`}>
      {option.searchLabel ? option.searchLabel : option.label}
    </small>
  ) : search && option.searchLabel ? (
    option.searchLabel
  ) : icon ? (
    <div className={Styles['label-with-icon']}>
      <Icon icon={icon} className={Styles.icon} /> {option.label}
    </div>
  ) : (
    option.label
  )
}

const SelectMenu = (props) => {
  const {
    onChange,
    ignoreLockedMode,
    placeholder = '',
    options = [],
    className,
    multi = false,
    clearable = true,
    disabled = false,
    size = 'large',
    value = undefined,
    icon,
    search = false,
    sorted = true,
    inNode,
    greyText = false,
    dropdownMatchSelectWidth = true,
    tagRender,
    optionLabelProp = 'label',
    label,
    withLock,
    locked,
    setLocked,
    containerClassName,
    labelBackground = false,
    dropdownRender,
    grid = false,
    maxTagCount
  } = props

  const optionsToUse = sorted
    ? options.sort((a, b) =>
      typeof a.label === 'string' ? a.label.localeCompare(b.label) : undefined
    )
    : options

  const gridClassName = grid ? 'select-grid' : ''

  return (
    <div
      className={`${containerClassName} ${
        label ? Styles['label-container'] : ''
      }`}
    >
      {label && (
        <label className={labelBackground ? Styles['colored-label'] : ''}>
          {label}
        </label>
      )}
      <div className={'flex-horizontal-gap'}>
        <Select
          dropdownRender={dropdownRender}
          value={value}
          className={`width-100 ${gridClassName} ${className} ${
            ignoreLockedMode ? 'ignore-locked-mode' : ''
          }`}
          maxTagCount={maxTagCount}
          size={size}
          mode={multi ? 'multiple' : null}
          placeholder={placeholder}
          disabled={disabled || locked}
          allowClear={clearable}
          onChange={onChange}
          showSearch={search}
          optionLabelProp={optionLabelProp}
          optionFilterProp="label"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          listItemHeight={grid ? 32 * 4 : undefined}
          listHeight={grid ? 256 * 4 : undefined}
          filterOption={
            search
              ? (input, option) =>
                  option.searchValue
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
              : undefined
          }
          getPopupContainer={(trigger) =>
            inNode ? document.body : trigger.parentNode
          }
          tagRender={tagRender}
        >
          {optionsToUse.map((option, i) => (
            <Option
              {...option}
              className={ignoreLockedMode ? 'ignore-locked-mode' : ''}
              key={`${option.value}-${i}`}
              value={option.value}
              label={parseValue(option, greyText, inNode)}
              searchValue={
                option.searchLabel ? option.label : option.searchLabel
              }
            >
              {parseOption(option, greyText, icon, search, inNode)}
            </Option>
          ))}
        </Select>
        {withLock ? (
          <IconButton onClick={() => setLocked(!locked)}>
            <Icon
              color={locked ? 'inherit' : 'primary'}
              name={locked ? 'LockOutlined' : 'LockOpenOutlined'}
            />
          </IconButton>
        ) : null}
      </div>
    </div>
  )
}

export default SelectMenu
