import React from 'react'
import ShortId from 'shortid'

import Icon from 'components_new/atoms/Icon'
import Chip from 'components_new/atoms/Chip'

import * as Functions from 'helpers/Functions'

export const SOURCE_HEADERS = [
  { label: 'REGISTER', key: 'name' },
  {
    label: 'TAGG',
    key: 'tag',
    render: function renderColumn(tag) {
      return tag && <Chip color="primary" label={tag} size="small" />
    }
  },
  {
    label: 'API',
    key: 'api',
    render: function renderColumn(apiTitle) {
      return apiTitle ? (
        <p>{apiTitle}</p>
      ) : (
        <p className="secondary">{'Inte ansluten'}</p>
      )
    }
  },
  {
    label: 'ANVÄNDNING',
    key: 'status',
    render: function renderColumn(status) {
      return (
        <Chip
          color={status ? 'success' : 'warning'}
          label={status ? 'Används' : 'Används ej'}
          size="small"
        />
      )
    }
  },
  {
    label: 'TYP',
    key: 'type',
    render: function renderColumn() {
      return (
        <Chip icon={<Icon name="TableView" />} label="Register" size="small" />
      )
    }
  },
  {
    label: 'SENASTE UPPDATERING',
    key: 'last_changed'
  }
]

export const TOGGLE_ITEMS = [
  {
    label: 'Alla',
    value: null,
    key: ShortId.generate()
  },
  {
    label: 'Används',
    value: true,
    key: ShortId.generate()
  },
  {
    label: 'Används ej',
    value: false,
    key: ShortId.generate()
  }
]

export const PAGE_LIMIT = 10

export const getRows = (
  page,
  toggle,
  CmdbTableStore,
  TagStore,
  CmdbApiStore
) => {
  const data = Functions.objectToArray(CmdbTableStore.data)
    .map((row) => ({
      id: row.id,
      attributes: {
        name: row.attributes.technical_name,
        tag: TagStore.linkers[row.id],
        api: CmdbApiStore.data[row.attributes.cmdb_api_id]?.attributes.title,
        rows: row.attributes.number_of_rows,
        status: row.attributes.is_ontology || row.included.active_shapes,
        last_changed: row.attributes.last_changed
          ? row.attributes.last_changed.replace('T', ' ').split('.')[0]
          : ''
      }
    }))
    .filter((item) =>
      toggle.value === null
        ? true
        : item.attributes.status.length > 0 === toggle.value
    )

  const start = PAGE_LIMIT * (page - 1)

  return [data.slice(start, start + PAGE_LIMIT), data.length]
}
