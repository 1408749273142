import * as Api from 'redux/api/Applications/Datasets/v3'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryCreateDataset(attributes, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_APPS_DATASET
    })

    const body = {
      data: {
        attributes
      }
    }

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_APPS_DATASET_SUCCESS,
          payload: {
            ...response.data.data,
            data_product_id: attributes.data_product_id
          }
        })

        onSuccess()

        messageSuccess('Nytt dataset har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_APPS_DATASET_FAILED
        })

        messageWarning('Det gick inte att skapa dataset')
      })
  }
}

export function tryUpdateDataset(id, attributes, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_APPS_DATASET
    })

    const body = {
      data: {
        attributes
      }
    }

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_APPS_DATASET_SUCCESS,
          payload: {
            ...response.data.data,
            data_product_id: attributes.data_product_id
          }
        })

        onSuccess()

        messageSuccess('Dataset har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_APPS_DATASET_FAILED
        })

        messageWarning('Det gick inte att uppdatera dataset')
      })
  }
}

export function tryDeleteDataset(id, dataProductId) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_APPS_DATASET
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_APPS_DATASET_SUCCESS,
          payload: { id, data_product_id: dataProductId }
        })

        messageSuccess('Dataset har tagits bort.')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_APPS_DATASET_FAILED
        })

        messageWarning('Det gick inte att ta bort dataset.')
      })
  }
}

export function tryGetDatasetPreview(endpoint, query) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_APPS_DATASET_PREVIEW
    })

    Api.getPreview(endpoint, query)
      .then((response) => {
        const key = `${endpoint}${query}`

        dispatch({
          type: Types.GET_APPS_DATASET_PREVIEW_SUCCESS,
          payload: { key, data: response.data, endpoint }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_APPS_DATASET_PREVIEW_FAILED
        })
      })
  }
}

export function tryPutTableFilterVariables(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_TABLE_FILTER_VARIABLES
    })

    Api.putTableFilterVariables(id, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_TABLE_FILTER_VARIABLES_SUCCESS,
          payload: { id, data: response.data.data }
        })

        messageSuccess('Globala filter har uppdaterats.')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_TABLE_FILTER_VARIABLES_FAILED
        })

        messageWarning('Det gick inte att uppdatera de globala filterna.')
      })
  }
}

export function tryExportDataset(id, dataProductName, query) {
  return (dispatch) => {
    dispatch({
      type: Types.EXPORT_INSIGHT_STUDIO
    })

    Api.exportDataset(id, query)
      .then((response) => {
        const fileName = `${dataProductName}.xlsx`
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.EXPORT_INSIGHT_STUDIO_SUCCESS
        })

        messageSuccess('Export lyckades')
      })
      .catch(() => {
        dispatch({
          type: Types.EXPORT_INSIGHT_STUDIO_FAILED
        })

        messageWarning('Export misslyckades')
      })
  }
}

export function tryGetDatasetTransformation(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_DATASET_TRANSFORMATION
    })

    Api.getTransformation(id)
      .then((response) => {
        dispatch({
          type: Types.GET_DATASET_TRANSFORMATION_SUCCESS,
          payload: { id, data: response.data.data }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DATASET_TRANSFORMATION_FAILED
        })
      })
  }
}

export function tryGetDatasetFilterVariables(id) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_DATASET_FILTER_VARIABLES
    })

    Api.getFilterVariables(id)
      .then((response) => {
        dispatch({
          type: Types.GET_DATASET_FILTER_VARIABLES_SUCCESS,
          payload: { id, data: response.data.data }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DATASET_FILTER_VARIABLES_FAILED
        })
      })
  }
}
