import React, { useMemo } from 'react'

import { Chart } from 'react-chartjs-2'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import { TrackingUser } from 'types/GlobalTracking'

import { getRolesData, getRolesAndPreferencesData, getOptions } from './utils'
import { useTheme } from '@mui/material'

interface RolesProps {
  usersUsage: TrackingUser[]
}

const Roles = (props: RolesProps) => {
  const theme = useTheme()

  const PaperSx = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minWidth: 0,
    position: 'relative'
  }

  const rolesAndPreferencesData: any = useMemo(() => {
    return getRolesAndPreferencesData(props.usersUsage, theme)
  }, [props.usersUsage])

  const rolesData: any = useMemo(() => {
    return getRolesData(props.usersUsage, theme)
  }, [props.usersUsage])

  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Grid container={true} spacing={2} sx={{ height: '100%', width: '100%' }}>
        <Grid item={true} xs={6}>
          <Paper elevation={0} sx={PaperSx}>
            <Text
              component="div"
              fontSize={20}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: 'text.primary'
              }}
            >
              {'Antal roller'}
            </Text>
            <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
              <Chart
                type="bar"
                data={rolesData}
                options={getOptions(theme, false)}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item={true} xs={6}>
          <Paper elevation={0} sx={PaperSx}>
            <Text
              component="div"
              fontSize={20}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: 'text.primary'
              }}
            >
              {'Antal roller uppdelat efter angiven preferens'}
            </Text>
            <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
              <Chart
                type="bar"
                data={rolesAndPreferencesData}
                options={getOptions(theme, true)}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Roles
