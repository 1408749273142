import React from 'react'

import Paper from 'components_new/atoms/Paper'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'

const SettingsTable = () => {
  return (
    <Paper variant="outlined">
      <Table sx={{ minWidth: 'content' }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Identifier (Entity ID)</b>
            </TableCell>
            <TableCell align="right">
              urn:amazon:cognito:sp:eu-north-1_6wlMPt7SU
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Reply URL</b>
            </TableCell>
            <TableCell align="right">
              https://homepal.auth.eu-north-1.amazoncognito.com/saml2/idpresponse
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <b>Relay State</b>
            </TableCell>
            <TableCell align="right">https://portal.homepal.se</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default SettingsTable
