import { TInputOption } from 'components_new/molecules/SettingsEditDialog/utils'
import { KpiOptionObject } from 'types/GlobalKpiOption'
import { WidgetObject } from 'types/GlobalWidget'

export const getBreakdownOptions = (
  kpi: KpiOptionObject,
  widget: WidgetObject
): TInputOption[] => {
  const newOptions = kpi.breakdown_by.options
    .filter((option) => option.id !== widget.settings.segment_by.selected)
    .map((option) => {
      return {
        disabled: option.disabled,
        label: option.name,
        value: option.id
      }
    })

  const defaultOptions = [
    {
      disabled: false,
      label: 'Ingen',
      value: 'none'
    }
  ]

  return [...defaultOptions, ...newOptions]
}
