export interface SettingRow {
  value: string
  label: string
  attributes: { value: string; label: string }[]
}

export enum FlawType {
  DUPLICATE = 'DUPLICATE',
  IS_NULL = 'IS_NULL'
}

export interface FlawRow {
  id: string
  customer_id: string | null
  table_name: string
  attribute_name: string
  row_id: string
  row_value: string | null
  type: FlawType
}

export const flawTypeColor: { [type in FlawType]: 'warning' | 'error' } = {
  [FlawType.DUPLICATE]: 'error',
  [FlawType.IS_NULL]: 'warning'
}

export const translateFlawType: { [type in FlawType]: string } = {
  [FlawType.DUPLICATE]: 'Duplicerad',
  [FlawType.IS_NULL]: 'Saknar värde'
}
