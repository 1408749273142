import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v3/data-products`

export async function getAll(type) {
  return Axios.get(`${URL}?type=${type}`, Functions.parseAuthenticationHeader())
}

export async function getOntology() {
  return Axios.get(`${URL}/ontology`, Functions.parseAuthenticationHeader())
}

export async function getOne(id) {
  return Axios.get(`${URL}/${id}`, Functions.parseAuthenticationHeader())
}

export async function update(id, body) {
  return Axios.patch(
    `${URL}/${id}`,
    body,
    Functions.parseAuthenticationHeader()
  )
}

export async function create(body) {
  return Axios.post(URL, body, Functions.parseAuthenticationHeader())
}

export async function destroy(id) {
  return Axios.delete(`${URL}/${id}`, Functions.parseAuthenticationHeader())
}

export async function downloadApiDocumentation(id) {
  return Axios.get(
    `${URL}/${id}/documentation`,
    Functions.parseAuthenticationHeader()
  )
}

export async function startJobRun(id) {
  return Axios.post(
    `${URL}/${id}/start-job-run`,
    {},
    Functions.parseAuthenticationHeader()
  )
}

export async function pollJobRun(id) {
  return Axios.get(
    `${URL}/${id}/poll-job-run`,
    Functions.parseAuthenticationHeader()
  )
}
