import { IconNames } from 'components_new/atoms/Icon'
import { cloneDeep } from 'lodash'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  AttributeOption,
  ComparativePeriod,
  CustomPeriodFilter,
  DateFilterType,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { ExploreDialogType } from '../dialogs/ExploreDialog'
import React from 'react'
import { getHierarchyForSegment } from '../dialogs/ExploreDialog/utils'
import { translateComparativePeriod } from 'utils/enumTranslator'

export const getShowDetailsOptions = (
  widget: WidgetObject,
  setDetailsDialogOpen: (arg0: boolean, arg1: ParsedSegmentPath[]) => void,
  embedded: boolean
) => {
  const allowDetails = widget.settings.kpi_options.some(
    (option) => option.can_show_details
  )

  if (widget.settings.segment_by.allow_click && allowDetails && !embedded) {
    return [
      {
        title: (filters: ParsedSegmentPath[]) =>
          `Visa underliggande data för ${filters
            .map((item) => item.display_label)
            .join(', ')}`,
        onClick: (filters: ParsedSegmentPath[]) => {
          setDetailsDialogOpen(true, filters)
        },
        iconName: 'TableChartOutlined' as IconNames
      }
    ]
  }

  return []
}

export const getDashboardFilterOptions = (
  widget: WidgetObject,
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void,
  dashboardFilter: DashboardFilter,
  standaloneWidget: boolean
) => {
  if (widget.settings.segment_by.allow_click && !standaloneWidget) {
    return [
      {
        title: (filters: ParsedSegmentPath[]) =>
          `Filtrera på ${filters.map((item) => item.display_label).join(', ')}`,
        onClick: (filters: ParsedSegmentPath[]) => {
          const newAttributeOptionIds = filters.map(
            (item) => item.attribute_option_id
          )

          // remove any affected filters
          const newDashboardFilter = cloneDeep(dashboardFilter).filter(
            (filter) =>
              filter.attribute_option_id &&
              !newAttributeOptionIds.includes(filter.attribute_option_id)
          )

          let customPeriodFilter: CustomPeriodFilter | undefined = undefined

          filters.forEach((filter) => {
            if (filter.period) {
              customPeriodFilter = {
                from: filter.period.from_date,
                to: filter.period.to_date
              }
            } else {
              const selectedAttributeOption =
                widget.settings.segment_by.options.find(
                  (opt) => opt.id === filter.attribute_option_id
                ) as AttributeOption

              newDashboardFilter.push({
                relation_key: selectedAttributeOption.relation_key,
                attribute_option_id: selectedAttributeOption.id,
                attribute: selectedAttributeOption.attribute_id,
                value: filter.label
              })
            }
          })

          setDashboardFilter(newDashboardFilter, customPeriodFilter)
        },
        iconName: 'FilterAltOutlined' as IconNames
      }
    ]
  }

  return []
}

export const getExploreOnClick = (
  widget: WidgetObject,
  setExploreDialog: React.Dispatch<React.SetStateAction<ExploreDialogType>>,
  embedded: boolean
) => {
  const kpiOptionsObject: {
    [kpiOptionId: string]:
      | ((
          dataLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  } = {}

  if (widget.settings.segment_by.allow_click && !embedded) {
    widget.settings.kpi_options.forEach((kpiOption) => {
      if (kpiOption.hierarchy.length > 0) {
        kpiOptionsObject[kpiOption.id] = (
          dataLabel,
          path,
          breakdown,
          customPeriod
        ) => {
          const hasBreakdown = !!kpiOption.breakdown_by.selected
          const attributeOptionId = dataLabel.segment_options[kpiOption.id]

          const hierarchy = getHierarchyForSegment(kpiOption, attributeOptionId)

          const exploreCustomPeriod = dataLabel.period
            ? {
                fromDate: dataLabel.period.from_date,
                toDate: dataLabel.period.to_date
              }
            : customPeriod

          const segments = [...path]

          if (!dataLabel.period) {
            segments.push(dataLabel)
          }

          setExploreDialog({
            customPeriod: exploreCustomPeriod,
            segments,
            open: true,
            hierarchy: hierarchy,
            breakdownFilterValue: hasBreakdown ? breakdown : undefined,
            breakdownRelationKey: hasBreakdown
              ? kpiOption.breakdown_by.options.find(
                (opt) => opt.id === kpiOption.breakdown_by.selected
              )?.relation_key
              : undefined,
            level: 0,
            kpiOptionId: kpiOption.id,
            showAll: !!dataLabel.period
          })
        }
      } else {
        kpiOptionsObject[kpiOption.id] = null
      }
    })
  }

  return kpiOptionsObject
}

export const getExternalLinkOptions = (
  widget: WidgetObject,
  embedded: boolean
) => {
  if (
    !embedded &&
    widget.settings.external_link.available &&
    widget.settings.external_link.show
  ) {
    return [
      {
        title: (segments: ParsedSegmentPath[], link?: string | null) =>
          link
            ? `Öppna ${segments[0].display_label} i källsystem`
            : `${segments[0].display_label} saknar koppling till källsystem`,
        onClick: (segments: ParsedSegmentPath[], link?: string | null) =>
          link ? window.open(link, '_blank') : {},
        iconName: 'LinkOutlined' as IconNames
      }
    ]
  }

  return []
}

export const getIsComparativeButIllegal = (
  dateFilter: PeriodFilter,
  comparativePeriod: ComparativePeriod | null,
  dateFilterType: DateFilterType
) => {
  if (comparativePeriod) {
    return !(
      comparativePeriod in
      translateComparativePeriod[dateFilterType][dateFilter]
    )
  }

  return false
}
