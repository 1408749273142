export const SHOW_MORE_THRESHOLD = 3

export const OPERATION_TYPES = {
  REPLACE: {
    label: 'Ersätt',
    icon: 'PublishedWithChangesOutlined',
    name: 'REPLACE'
  },
  FILTER: {
    label: 'Filtrera',
    icon: 'FilterAltOutlined',
    name: 'FILTER'
  },
  CALCULATE: {
    label: 'Beräkna',
    icon: 'PercentOutlined',
    name: 'CALCULATE'
  },
  TIME_DIFFERENCE: {
    label: 'Tidsperiod',
    icon: 'TimerOutlined',
    name: 'TIME_DIFFERENCE'
  }
}

export const OPERATION_OPTIONS = [
  {
    icon: OPERATION_TYPES.REPLACE.icon,
    title: OPERATION_TYPES.REPLACE.label,
    description: 'Ersätt värden i en kolumn.',
    type: 'REPLACE'
  },
  {
    icon: OPERATION_TYPES.FILTER.icon,
    title: OPERATION_TYPES.FILTER.label,
    description: 'Filtrera bort rader utifrån villkor.',
    type: 'FILTER'
  },
  {
    icon: OPERATION_TYPES.CALCULATE.icon,
    title: OPERATION_TYPES.CALCULATE.label,
    description: 'Utför aritmetiska beräkningar.',
    type: 'CALCULATE'
  },
  {
    icon: OPERATION_TYPES.TIME_DIFFERENCE.icon,
    title: OPERATION_TYPES.TIME_DIFFERENCE.label,
    description: 'Beräkna tidsperiod mellan två datum.',
    type: 'TIME_DIFFERENCE'
  }
]

const UUID_REGEX = /(@).*?(?=\+|_-_|\*|\/|\(|\)|$)/g

export const replaceInputExpression = (toReplace, chosenAttributes) => {
  let value = toReplace.replace('-', '_-_')

  Object.values(chosenAttributes).forEach((attribute) => {
    const regex = new RegExp(
      `@${attribute.outputAttributeName}(?=[^a-zA-Z]|$)`,
      'g'
    )

    value = value.replace(regex, `@${attribute.outputAttributeId}`)
  })

  return value
}

export const parseSavedValue = (value, type, chosenAttributes) => {
  return type === OPERATION_TYPES.CALCULATE.name
    ? value
      .replace(UUID_REGEX, (match) => {
        return `@${
          chosenAttributes[match.replace('@', '').trim()].outputAttributeName
        }`
      })
      .replace('_-_', '-')
    : value
}

export const resetForm = (form) => {
  form.setFieldsValue({
    conditions: [],
    newAttribute: null,
    leftAttribute: null,
    rightAttribute: null,
    unit: null,
    value: null,
    valueType: null
  })
}

export const updateOperationsUsingNewAttribute = (
  oldName,
  newName,
  operations
) => {
  if (oldName !== newName) {
    operations.forEach((op) => {
      op.attributes.forEach((attr) => {
        if (attr.outputAttributeId === oldName) {
          attr.outputAttributeId = newName
        }
        if (attr.condition.right_attribute === oldName) {
          attr.condition.right_attribute = newName
        }
      })
    })
  }
}

export const parseNewAttributeType = (operationType, chosenLeftAttribute) => {
  switch (operationType) {
  case OPERATION_TYPES.CALCULATE.name:
    return 'DOUBLE'
  case OPERATION_TYPES.REPLACE.name:
    return chosenLeftAttribute?.type || 'STRING'
  case OPERATION_TYPES.TIME_DIFFERENCE.name:
    return 'BIGINT'
  default:
    return 'STRING'
  }
}

export const parseOperationAttributes = (
  chosenAttributes,
  type,
  attributes,
  newAttribute,
  chosenLeftAttribute,
  chosenRightAttribute
) => {
  const items = attributes.map(({ outputAttributeId }) => ({
    type: chosenAttributes[outputAttributeId]?.type,
    name: chosenAttributes[outputAttributeId]?.outputAttributeName,
    tableName: chosenAttributes[outputAttributeId]?.tableName
  }))

  if (newAttribute) {
    items.unshift({
      type: parseNewAttributeType(type, chosenLeftAttribute),
      name: newAttribute,
      tableName: ''
    })
  }

  if (chosenRightAttribute) {
    items.unshift({
      type: chosenRightAttribute.type,
      name: chosenRightAttribute.outputAttributeName,
      tableName: chosenRightAttribute.tableName
    })
  }

  if (chosenLeftAttribute) {
    items.unshift({
      type: chosenLeftAttribute.type,
      name: chosenLeftAttribute.outputAttributeName,
      tableName: chosenLeftAttribute.tableName
    })
  }

  return items
}
