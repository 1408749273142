import React, { FC, useEffect, useMemo } from 'react'

import { cloneDeep } from 'lodash'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import ListsTemplate from 'components_new/templates/internal/Lists'

import * as SIMActions from 'redux/actions/SIM'
import * as DatasetActions from 'redux/actions/Applications/Datasets'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  SIMStore: state.SIMStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...SIMActions,
      ...DatasetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ListsPageProps = ConnectedProps<typeof connector>

const ListsPage: FC<ListsPageProps> = (props: ListsPageProps) => {
  const { SIMStore } = props

  useEffect(() => {
    const { tryGetSIMTables } = props

    if (SIMStore.tables.length === 0) {
      tryGetSIMTables()
    }
  }, [])

  const tables = useMemo(() => {
    if (SIMStore.tables.length > 0) {
      return cloneDeep(SIMStore.tables).sort((t1, t2) => {
        // Sort so that all available tables to export appears first.
        // Then sort alphabetically

        const boolDiff = Number(t2.has_data) - Number(t1.has_data)

        if (boolDiff !== 0) {
          return boolDiff
        }

        return t1.title.toLowerCase() < t2.title.toLowerCase() ? -1 : 1
      })
    }

    return []
  }, [SIMStore.tables])

  return (
    <>
      <InternalTemplate>
        <ListsTemplate lists={tables} />
      </InternalTemplate>
    </>
  )
}

export default connector(ListsPage)
