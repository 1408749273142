import React, { ReactNode } from 'react'

import Chip from 'components_new/atoms/Chip'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'

interface SidebarItemProps {
  number?: number
  onClick: () => void
  selected: boolean
  sx?: object
  title: ReactNode
}

const SidebarItem = React.forwardRef((props: SidebarItemProps, ref: any) => {
  const { number, onClick, selected, sx, title } = props

  return (
    <MenuItem
      ref={ref}
      onClick={onClick}
      selected={selected}
      sx={{ height: 42, ...sx }}
    >
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          fontWeight: 600,
          fontSize: '0.875rem',
          lineHeight: 1.25,
          color: selected ? 'primary.dark' : 'text.secondary'
        }}
      />
      {number !== undefined ? (
        <ListItemIcon position="end">
          <Chip
            color={selected ? 'primary' : 'default'}
            disabled={!selected}
            label={number}
            size="small"
          />
        </ListItemIcon>
      ) : null}
    </MenuItem>
  )
})

SidebarItem.displayName = 'SidebarItem'
export default SidebarItem
