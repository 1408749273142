import { cloneDeep } from 'lodash'
import { KpiTemplate, KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { DataType, WidgetObject, WidgetType } from 'types/GlobalWidget'
import { WidgetTagObject } from 'types/GlobalWidgetTag'

export const getIconName = (index: number, widget: WidgetObject) => {
  const type = widget.settings.type.selected
  const segmentType = widget.settings.segment_by.type

  switch (type) {
  case WidgetType.BAR_CHART:
    return 'SignalCellularAltOutlined'
  case WidgetType.LINE_CHART:
    return 'ShowChartOutlined'
  case WidgetType.LIST:
    return 'VerticalSplitOutlined'
  case WidgetType.BUBBLE_CHART:
    if (index === 0) {
      return 'BorderBottomOutlined'
    } else if (index === 1) {
      return 'BorderLeftOutlined'
    } else {
      return 'BubbleChartOutlined'
    }
  case WidgetType.COMBO_CHART:
    if (index === 0) {
      return 'BarChartTwoTone'
    } else {
      if (segmentType === DataType.DATE) {
        return 'ShowChartTwoTone'
      }

      return 'WorkspacesTwoTone'
    }
  case WidgetType.KEY_FIGURE: {
    return 'OneKOutlined'
  }
  case WidgetType.PIE_CHART: {
    return 'PieChart'
  }
  case WidgetType.MAP: {
    return 'ListOutlined'
  }
  default:
    return 'CalendarViewWeekOutlined'
  }
}

export const getAddTitle = (widget: WidgetObject, numberOfKpis: number) => {
  const type = widget.settings.type.selected
  const segmentType = widget.settings.segment_by.type

  if (type === WidgetType.BUBBLE_CHART) {
    if (numberOfKpis === 1) {
      return 'Lägg till Y-axel'
    }

    if (numberOfKpis === 2) {
      return 'Lägg till radie'
    }
  }

  if (type === WidgetType.COMBO_CHART) {
    if (segmentType === DataType.DATE) {
      return 'Lägg till linje'
    }

    return 'Lägg till punkter'
  }

  return 'Lägg till nyckeltal'
}

export const validateTemplateSegmentation = (
  template: KpiTemplate,
  widget: WidgetObject,
  filteredRelationKeys: string[],
  selectedSegmentRelationKey: string | null
): boolean => {
  if (widget.settings.type.selected === WidgetType.LINE_CHART) {
    return true
  }

  return !!(
    selectedSegmentRelationKey &&
    template.segment_relation_keys.includes(selectedSegmentRelationKey) &&
    filteredRelationKeys.every((relationKey) =>
      template.filter_relation_keys.includes(relationKey)
    )
  )
}

export const validateAvailableKpiTemplate = (
  template: KpiTemplate,
  widget: WidgetObject,
  unit: string | null,
  filteredRelationKeys: string[],
  selectedSegmentRelationKey: string | null
) => {
  const widgetIsBarChart =
    widget.settings.type.selected === WidgetType.BAR_CHART
  const widgetIsLineChart =
    widget.settings.type.selected === WidgetType.LINE_CHART
  const widgetIsFunnel = template.widget_type === WidgetType.FUNNEL

  let validSegmentation = false

  if (template.sub_kpis.length > 0) {
    // check if any sub kpi is allowed
    validSegmentation = template.sub_kpis.some((subTemplate) =>
      validateTemplateSegmentation(
        subTemplate,
        widget,
        filteredRelationKeys,
        selectedSegmentRelationKey
      )
    )
  } else {
    validSegmentation = validateTemplateSegmentation(
      template,
      widget,
      filteredRelationKeys,
      selectedSegmentRelationKey
    )
  }

  const validUnit =
    widgetIsBarChart || widgetIsLineChart ? template.unit === unit : true

  return !widgetIsFunnel && validUnit && validSegmentation
}

export const getAvailableKpiTemplates = (
  kpiTemplates: KpiTemplateRedux,
  widget: WidgetObject
) => {
  const filteredKpiTemplates: KpiTemplateRedux = {}
  const mainKpi = widget.settings.kpi_options.find((kpi) => kpi.index === 0)

  const unit = mainKpi?.original_unit || null
  const selectedSegmentRelationKey = widget.settings.segment_by.selected
    ? widget.settings.segment_by.options.find(
      (opt) => opt.id === widget.settings.segment_by.selected
    )?.relation_key ?? null
    : null

  const widgetFilters = widget.settings.filters.filter((f) => f.value.selected)
  const filteredRelationKeys = widgetFilters.map((filter) => {
    const selectedOption = filter.attribute_option.options.find(
      (option) => option.id === filter.attribute_option.selected
    )

    return selectedOption?.relation_key as string
  })

  Object.values(kpiTemplates)
    .filter((template) =>
      validateAvailableKpiTemplate(
        template,
        widget,
        unit,
        filteredRelationKeys,
        selectedSegmentRelationKey
      )
    )
    .forEach((kpi) => {
      const newKpi = cloneDeep(kpi)

      if (kpi.sub_kpis.length > 0) {
        // remove any unsupported sub kpis.
        newKpi.sub_kpis = newKpi.sub_kpis.filter((subTemplate) =>
          validateTemplateSegmentation(
            subTemplate,
            widget,
            filteredRelationKeys,
            selectedSegmentRelationKey
          )
        )
      }

      filteredKpiTemplates[newKpi.id] = newKpi
    })

  return filteredKpiTemplates
}

export const getTagOptions = (tags: {
  selected: WidgetTagObject[]
  options: {
    id: string
    name: string
  }[]
}) => {
  const newOptions = tags.options.map((tag) => ({
    value: tag.id,
    label: tag.name,
    disabled: !!tags.selected.find((t) => {
      return t.tag_option_id === tag.id
    })
  }))

  return newOptions
}
