import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import * as SurveyActions from 'redux/actions/internal/Survey'
import * as TrackingActions from 'redux/actions/Tracking'

import UsersTrackingTemplate from 'components_new/templates/internal/UsersTracking'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  SurveyStore: state.SurveyStore,
  TrackingStore: state.TrackingStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...SurveyActions,
      ...TrackingActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type TrackingPageProps = ConnectedProps<typeof connector>

const UsersTrackingPage = (props: TrackingPageProps) => {
  const { SurveyStore, TrackingStore } = props

  useEffect(() => {
    if (!SurveyStore.fetched) {
      props.tryGetAllSurveys()
    }

    if (!TrackingStore.usersUsageFeteched) {
      props.tryGetUsersUsage()
    }
  }, [])

  return (
    <InternalTemplate>
      <UsersTrackingTemplate
        SurveyStore={SurveyStore}
        usersUsage={TrackingStore.usersUsage}
      />
    </InternalTemplate>
  )
}

export default connector(UsersTrackingPage)
