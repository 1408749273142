import React, { ReactNode } from 'react'
import { Theme, ThemeProvider } from '@mui/material'

import Box from 'components_new/atoms/Box'

import { hexToRgbString } from 'themes'

interface MarkerProps {
  body: ReactNode
  theme: Theme
  title: ReactNode
  variant?: 'cluster' | 'single'
}

const Marker = (props: MarkerProps) => {
  const { body, theme, title, variant } = props

  const isCluster = variant === 'cluster'
  const pinSize = 12

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ position: 'relative', cursor: 'default' }}>
        {isCluster ? (
          <Box
            sx={{
              position: 'absolute',
              transform: `translateX(-50%) translateY(calc(-50% + ${8}px))`,
              bgcolor: theme.palette.primary.transparent,
              border: '1px dashed',
              borderColor: theme.palette.primary.main,
              width: 32,
              height: 32,
              borderRadius: 16
            }}
          />
        ) : null}
        <Box
          sx={{
            position: 'absolute',
            transform: `translateX(-50%) translateY(calc(-100% + ${pinSize}px))`
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
              borderRadius: 1,
              boxShadow: theme.shadows[3],
              overflow: 'hidden',
              background: hexToRgbString(theme.palette.primary.main, 0.8),
              backdropFilter: 'blur(5px)',
              WebkitBackdropFilter: 'blur(5px)',
              border: `1px solid ${theme.palette.divider}`
            }}
          >
            <Box
              sx={{
                p: 1,
                color: '#ffffff'
              }}
            >
              {title}
            </Box>
            <Box
              sx={{
                bgcolor: theme.palette.background.widget,
                borderRadius: 1,
                p: 1
              }}
            >
              {body}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: pinSize,
                height: pinSize,
                bgcolor: theme.palette.primary.main,
                transform: 'translateY(-50%) rotate(45deg)',
                boxShadow: theme.shadows[3],
                zIndex: -1
              }}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Marker
