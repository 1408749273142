import React, { useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme, hexToRgbObject } from 'themes'

import { CustomColors } from 'types/GlobalCustomization'
import { Customer } from 'types/GlobalCustomer'
import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  KpiTemplateFilterOptionsBody,
  KpiTemplateRedux
} from 'types/GlobalKpiTemplates'
import {
  CustomPeriodFilter,
  FormattedWidgetData,
  FunnelStagePutBody,
  InnerWidgetPatchBody,
  PeriodFilter,
  ParsedSegmentPath,
  TViewport,
  WidgetObject
} from 'types/GlobalWidget'
import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'

import { DashboardReducerType } from 'redux/reducers/Dashboards'

import { FileExtension, Filters, Sort } from 'utils/types'

import ExploreDialog, { ExploreDialogType } from './dialogs/ExploreDialog'
import FullSizeDialog from './dialogs/FullSizeDialog'
import DetailsDialog from './dialogs/DetailsDialog'

import WidgetBase from './WidgetBase'
import WidgetBody from './WidgetBody'
import WidgetFooter from './WidgetFooter'
import WidgetHeader from './WidgetHeader'
import WidgetSettings from './WidgetSettings'

export const INITIAL_EXPLORE_DIALOG = {
  customPeriod: undefined,
  segments: [],
  open: false,
  hierarchy: [],
  segmentFilterValue: [],
  segmentRelationKey: [],
  breakdownFilterValue: undefined,
  breakdownRelationKey: undefined,
  level: 0,
  kpiOptionId: null
}

interface WidgetProps {
  allowBenchmarking?: boolean
  colors?: CustomColors | null
  createAdditionalSegmentBy?: (attributeOptionId: string) => void
  createKpiOption?: (body: KpiOptionPostAttributes) => void
  createWidgetTag?: (body: WidgetTagPostAttributes) => void
  customer: Customer | null
  customPeriodFilter?: CustomPeriodFilter | null
  dashboardFilter: DashboardFilter
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  deleteAdditionalSegmentBy?: (id: string) => void
  deleteKpiOption?: (id: string) => void
  deleteWidget?: (arg0: string) => void
  deleteWidgetTag?: (id: string) => void
  details?: DashboardReducerType['details']
  editable: boolean
  embedded?: boolean
  enableFullscreen?: boolean
  exportDetails?: (
    kpiOptionId: string,
    extension: FileExtension,
    sort: Sort | null,
    filters: Filters,
    customPeriodFilter?: CustomPeriodFilter
  ) => void
  exportWidgetData?: () => void
  fetchedKpis?: boolean
  filterOptions?: KpiTemplateFilterOptionsBody | null
  formattedData: FormattedWidgetData
  getDetails?: (
    kpiOptionId: string,
    offset: number,
    sort: Sort | null,
    filters: Filters,
    customPeriodFilter?: CustomPeriodFilter
  ) => void
  isAdmin: boolean
  kpiTemplates?: KpiTemplateRedux
  kpiTemplatesFetched?: boolean
  loading: boolean
  periodFilter?: PeriodFilter | null
  plain?: boolean
  putFunnelStages?: (body: FunnelStagePutBody) => void
  scaleFactor: number
  setDashboardFilter?: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  showAs?: string | null
  standaloneWidget?: boolean
  updateAdditionalSegmentBy?: (id: string, attributeOptionId: string) => void
  updateKpiOption?: (id: string, body: KpiOptionPatchAttributes) => void
  updateWidget?: (arg0: string, arg1: InnerWidgetPatchBody) => void
  updateWidgetTag?: (id: string, body: WidgetTagPatchAttributes) => void
  uploadImage?: (image: Blob, name: string) => void
  widget: WidgetObject
}

const Widget = (props: WidgetProps) => {
  const {
    allowBenchmarking = true,
    colors,
    createAdditionalSegmentBy = () => {},
    createKpiOption = () => {},
    createWidgetTag = () => {},
    customer,
    customPeriodFilter = null,
    dashboardFilter,
    dashboardFilterOptions,
    deleteAdditionalSegmentBy = () => {},
    deleteKpiOption = () => {},
    deleteWidget = () => {},
    deleteWidgetTag = () => {},
    details = {},
    editable = false,
    embedded = false,
    enableFullscreen,
    exportDetails = () => {},
    exportWidgetData = () => {},
    fetchedKpis = true,
    filterOptions = null,
    formattedData,
    getDetails = () => {},
    isAdmin = false,
    kpiTemplates = {},
    kpiTemplatesFetched = false,
    loading,
    periodFilter = null,
    plain = false,
    putFunnelStages = () => {},
    scaleFactor,
    setDashboardFilter = () => {},
    showAs = null,
    standaloneWidget = false,
    updateAdditionalSegmentBy = () => {},
    updateKpiOption = () => {},
    updateWidget = () => {},
    updateWidgetTag = () => {},
    uploadImage = () => {},
    widget
  } = props

  const [fullSize, setFullSize] = useState(false)
  const [edit, setEdit] = useState(false)

  const [detailsDialog, setDetailsDialog] = useState<{
    open: boolean
    segments: ParsedSegmentPath[]
  }>({ open: false, segments: [] })

  const [exploreDialog, setExploreDialog] = useState<ExploreDialogType>(
    INITIAL_EXPLORE_DIALOG
  )

  let widgetTheme: 'dark' | 'light' = 'light'

  if (colors?.widget_background_color) {
    const rgb = hexToRgbObject(colors.widget_background_color)
    const black = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

    if (!black) {
      widgetTheme = 'dark'
    }
  }

  // For MAP only, required in both settings and widget:
  const [viewport, setViewport] = useState<TViewport>({
    longitude: widget.settings.viewport.longitude,
    latitude: widget.settings.viewport.latitude,
    pitch: widget.settings.viewport.pitch,
    zoom: widget.settings.viewport.zoom
  })

  /*-- For Map beta: --*/
  const [fakeLoading, setFakeLoading] = useState(false)

  function triggerFakeLoading() {
    setFakeLoading(true)

    setTimeout(() => setFakeLoading(false), 200)
  }
  /*-----*/

  const getWidgetBody = (scale: boolean) => {
    const controlledScaleFactor = scale ? scaleFactor : 0.8

    return (
      <>
        <WidgetHeader
          colors={colors}
          dashboardFilterOptions={dashboardFilterOptions}
          deleteWidget={deleteWidget}
          editable={editable}
          embedded={embedded}
          enableFullscreen={enableFullscreen}
          exportWidgetData={exportWidgetData}
          fullSize={fullSize}
          kpiTemplates={kpiTemplates}
          loading={loading}
          scaleFactor={controlledScaleFactor}
          setDetailsDialog={(open, segments) =>
            setDetailsDialog({ open, segments })
          }
          setExploreDialog={setExploreDialog}
          setFullSize={setFullSize}
          setEdit={() => setEdit(true)}
          updateWidget={updateWidget}
          widget={widget}
        />
        <WidgetBody
          dashboardFilter={dashboardFilter}
          embedded={embedded}
          fakeLoading={fakeLoading}
          formattedData={formattedData}
          fullSize={fullSize}
          isAdmin={isAdmin}
          loading={loading}
          periodFilter={periodFilter}
          scaleFactor={controlledScaleFactor}
          setDashboardFilter={setDashboardFilter}
          setDetailsDialog={(open, segments) =>
            setDetailsDialog({ open, segments })
          }
          setExploreDialog={setExploreDialog}
          setEdit={setEdit}
          setViewport={setViewport}
          standaloneWidget={standaloneWidget}
          viewport={viewport}
          widget={widget}
        />
        <WidgetFooter
          loading={loading}
          customPeriodFilter={customPeriodFilter}
          periodFilter={periodFilter}
          scaleFactor={controlledScaleFactor}
          widget={widget}
        />
      </>
    )
  }

  return (
    <>
      {/*-- default widget --*/}
      <ThemeProvider theme={getTheme(widgetTheme, colors)}>
        <WidgetBase
          colors={colors}
          embedded={embedded}
          editable={editable}
          plain={plain}
          scaleFactor={scaleFactor}
          widgetType={widget.settings.type.selected}
        >
          {
            // scaled content
            getWidgetBody(true)
          }
        </WidgetBase>
      </ThemeProvider>

      {/*-- full size widget --*/}
      <ThemeProvider theme={getTheme(widgetTheme, colors)}>
        <FullSizeDialog
          colors={colors}
          onClose={() => setFullSize(false)}
          open={fullSize}
        >
          {
            // no scaled content
            getWidgetBody(false)
          }
        </FullSizeDialog>
      </ThemeProvider>

      <ThemeProvider theme={getTheme('light')}>
        {/*-- widget dialogs --*/}
        <DetailsDialog
          open={detailsDialog.open}
          onClose={() => setDetailsDialog({ open: false, segments: [] })}
          details={details}
          getDetails={(
            kpiOptionId,
            offset,
            sort,
            filters,
            detailPeriodFunction
          ) =>
            getDetails(kpiOptionId, offset, sort, filters, detailPeriodFunction)
          }
          exportDetails={(
            kpiId,
            extension,
            sort,
            filters,
            detailPeriodFunction
          ) =>
            exportDetails(kpiId, extension, sort, filters, detailPeriodFunction)
          }
          filterValueOptions={{}}
          segmentPaths={detailsDialog.segments}
          widget={widget}
        />
        <ExploreDialog
          dashboardFilter={dashboardFilter}
          exploreDialog={exploreDialog}
          periodFilter={periodFilter || customPeriodFilter}
          setExploreDialog={setExploreDialog}
          showAs={showAs}
          widget={widget}
        />

        {/*-- widget drawer --*/}
        <WidgetSettings
          allowBenchmarking={allowBenchmarking}
          createAdditionalSegmentBy={createAdditionalSegmentBy}
          createKpiOption={createKpiOption}
          createWidgetTag={createWidgetTag}
          customer={customer}
          deleteAdditionalSegmentBy={deleteAdditionalSegmentBy}
          deleteKpiOption={deleteKpiOption}
          deleteWidgetTag={deleteWidgetTag}
          fetchedKpis={fetchedKpis}
          filterOptions={filterOptions}
          fullSize={fullSize}
          kpiTemplates={kpiTemplates}
          kpiTemplatesFetched={kpiTemplatesFetched}
          open={edit}
          onClose={() => setEdit(false)}
          putFunnelStages={putFunnelStages}
          setViewport={setViewport}
          triggerFakeLoading={triggerFakeLoading}
          updateAdditionalSegmentBy={updateAdditionalSegmentBy}
          updateKpiOption={updateKpiOption}
          updateWidget={updateWidget}
          updateWidgetTag={updateWidgetTag}
          uploadImage={uploadImage}
          viewport={viewport}
          widget={widget}
        />
      </ThemeProvider>
    </>
  )
}

export default Widget
