import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Build from 'components/pages/DataPlatform/Build'
import Collect from 'components/pages/DataPlatform/Collect'
import Shape from 'components/pages/DataPlatform/Shape'

// Ontology
import Ontology from 'components/pages/DataPlatform/Ontology'

import AuthRoute from './AuthRoute'

const Routes = ({ AuthStore }) => {
  return (
    <Switch>
      {/* Ontology routes */}
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Ontology}
        path={'/data-platform/ontology/:type'}
        exact
      />
      {/* Ontology datasets routes */}
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Shape}
        path={'/data-platform/ontology/datasets/:tableId/transformation'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Shape}
        path={'/data-platform/ontology/datasets/:tableId/schema'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Shape}
        path={'/data-platform/ontology/datasets/:tableId/quality'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Shape}
        path={'/data-platform/ontology/datasets/:tableId/flaws'}
        exact
      />
      {/* Collect routes - saving /collect for overview. */}
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/systems'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/databases'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/streams'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/files'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/custom'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/sources'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:createType/create'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/table'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/aggregation'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/logs'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/data'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/import'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/views'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/classification'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/history'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/table'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/upload'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Collect}
        path={'/data-platform/collect/:sourceType/:id/aggregation'}
        exact
      />
      {/* Build routes - saving /build for overview. */}
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers/create'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers/create/:createType'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers/:cmdbId'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers/:cmdbId/data'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={Build}
        path={'/data-platform/build/registers/:cmdbId/classification'}
        exact
      />
      <Route path={'/data-platform'}>
        <Redirect to="/data-platform/ontology/standard" />
      </Route>
      <Route path={'/data-platform/*'}>
        <Redirect to="/data-platform/ontology/standard" />
      </Route>
    </Switch>
  )
}

export default Routes
