import React, { Component } from 'react'
import { Modal } from 'antd'

import Icon from 'components_new/atoms/Icon'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import ButtonStyles from 'components/common/Button/styles.module.css'
import Styles from './styles.module.css'
import './styles.css'

class Alert extends Component {
  state = {
    faIcon: null
  }

  componentDidUpdate(prevProps) {
    const { AlertStore } = this.props

    if (prevProps.AlertStore.faIcon !== AlertStore.faIcon) {
      this.setState({ faIcon: AlertStore.faIcon })
    }
  }

  getColorClasses() {
    const { AlertStore } = this.props

    const classes = {}

    if (AlertStore.color === 'warning') {
      classes.okColorClass = Styles['ok-warning-button-color']
      classes.cancelColorClass = Styles['cancel-warning-button-color']
    } else {
      classes.okColorClass = ''
      classes.cancelColorClass = ''
    }

    return classes
  }

  render() {
    const { faIcon } = this.state
    const { AlertStore, resetAlert } = this.props

    let containerClassName = Styles['container']
    const { okColorClass, cancelColorClass } = this.getColorClasses()

    if (AlertStore.size) {
      containerClassName += ` ${Styles[AlertStore.size]}`
    }

    return (
      <Modal
        className={containerClassName}
        zIndex={2002}
        okButtonProps={{
          className: `${ButtonStyles['filled-container']} ${ButtonStyles.container} ${okColorClass}`
        }}
        cancelButtonProps={{
          className: `${ButtonStyles.container} ${
            ButtonStyles['bordered-container']
          } ${AlertStore.cancelText ? '' : 'hidden'} ${cancelColorClass}`
        }}
        cancelText={AlertStore.cancelText}
        okText={AlertStore.okText}
        onCancel={
          AlertStore.onCancel ? () => AlertStore.onCancel() : () => resetAlert()
        }
        onOk={AlertStore.onOk ? () => AlertStore.onOk() : () => resetAlert()}
        title={AlertStore.header}
        open={AlertStore.visible}
        closable={false}
      >
        <Box sx={{ p: 4 }}>
          {AlertStore.contentHeader ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                gap: 1
              }}
            >
              {faIcon && (
                <Icon
                  className={`${Styles['fa-icon']} ${
                    AlertStore.faIconWarning ? Styles['fa-icon-warning'] : ''
                  }`}
                  name={faIcon}
                />
              )}
              <Text variant="h5">{AlertStore.contentHeader}</Text>
            </Box>
          ) : null}
          {AlertStore.content}
        </Box>
      </Modal>
    )
  }
}

export default Alert
