import React from 'react'

import { keyframes, useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'

interface PulsingBadgeProps {
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  pulsing: boolean
  size: number
  sx?: object
}

/**
 * PulsingBadge generates a small badge that pulses.
 */

// 'keyframes' will not accept a full 'rgba(r,g,b,a)' stored in a variable
// for some reason. Therefore this special case function is needed.
const hexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return r + ', ' + g + ', ' + b
}

const PulsingBadge = React.forwardRef((props: PulsingBadgeProps, ref: any) => {
  const { color, pulsing, sx, size } = props

  const theme = useTheme()

  const pulse = keyframes`
    0% {
      box-shadow: 0 0 0 0px rgba(${hexToRGB(theme.palette[color].main)}, 0.5);
    }
    100% {
      box-shadow: 0 0 0 ${size - size * 0.6}px rgba(${hexToRGB(
  theme.palette[color].main
)}, 0);
    } 
  `

  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: size / 2,
        bgcolor: pulsing ? undefined : 'action.hover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}
      ref={ref}
    >
      <Box
        sx={{
          width: size * 0.6,
          height: size * 0.6,
          borderRadius: '50%',
          bgcolor: pulsing ? `${color}.main` : 'action.focus',
          animation: pulsing ? `${pulse} 2s infinite` : undefined
        }}
      />
    </Box>
  )
})

PulsingBadge.displayName = 'PulsingBadge'
export default PulsingBadge
