import React from 'react'

import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'
import Box from 'components_new/atoms/Box'

interface SubcategoryTitleRowProps {
  title: string
}

const SubcategoryTitleRow = (props: SubcategoryTitleRowProps) => {
  const { title } = props

  return (
    <TableRow
      sx={{
        zIndex: 2,
        position: 'sticky',
        top: 37, // Height of table head.
        bgcolor: 'background.paper'
      }}
    >
      <TableCell padding="none" colSpan={5}>
        <Box sx={{ py: 2, px: 7 }}>
          <Text variant="h6" fontWeight="bold">
            {title}
          </Text>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default SubcategoryTitleRow
