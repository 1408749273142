import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import { putLayout, PutLayout, putFavorite } from 'redux/api/DashboardFavorites'

export function tryPutDashboardFavoritesLayout(body: PutLayout) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_DASHBOARD_FAVORITES_LAYOUT
    })

    putLayout(body)
      .then(() => {
        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITES_LAYOUT_SUCCESS,
          payload: body.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITES_LAYOUT_FAILED
        })
      })
  }
}

export function tryPutDashboardFavorite(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_DASHBOARD_FAVORITE
    })

    putFavorite(id)
      .then((response) => {
        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITE_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_DASHBOARD_FAVORITE_FAILED
        })
      })
  }
}
