import React from 'react'

import Box from 'components_new/atoms/Box'
import TableCell from 'components_new/atoms/Table/TableCell'
import Text from 'components_new/atoms/Text'

import { getCellScaleSx } from '../utils'

interface FooterCellProps {
  align?: 'start' | 'end'
  scaleFactor: number
  value?: string | number | null
}

const FooterCell = (props: FooterCellProps) => {
  const { align = 'end', scaleFactor, value } = props

  return (
    <TableCell
      sx={{
        borderRight: '1px solid',
        '&:last-child': { borderRight: 0 },
        borderRightColor: 'divider',
        whiteSpace: 'nowrap',
        width: '1%', // For auto sized, fit content cells. (Hacker).
        ...getCellScaleSx(scaleFactor)
      }}
      variant="footer"
    >
      {value ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: align === 'end' ? 'flex-end' : 'flex-start'
          }}
        >
          <Text
            color="text.primary"
            fontSize="inherit"
            fontWeight="medium"
            variant="body2"
          >
            {value}
          </Text>
        </Box>
      ) : null}
    </TableCell>
  )
}

export default FooterCell
