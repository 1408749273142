import React from 'react'

import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Box from 'components_new/atoms/Box'
import LoadingSection from 'components_new/molecules/LoadingSection'

interface ActivationDashboardKpiBoxProps {
  title: string
  value: number
  bestPossibleValue: number
  totalUsers: number
  notActivatedUsers: number
  loading: boolean
}

const ActivationDashboardKpiBox = (props: ActivationDashboardKpiBoxProps) => {
  const PaperSx = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minWidth: 0,
    position: 'relative',
    gap: 2,
    bgcolor: 'grey.50'
  }

  return (
    <Paper elevation={0} sx={PaperSx}>
      <Box sx={{ flex: '0 0 100px' }}>
        <Text
          component="div"
          fontSize={18}
          fontWeight={'bold'}
          lineHeight={1.1}
          sx={{
            color: 'text.primary'
          }}
        >
          {props.title}
        </Text>
        {props.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <>
            <Text
              component="div"
              fontSize={60}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: 'text.primary',
                mt: 2
              }}
            >
              {`${props.value} dagar`}
            </Text>
            <Text
              component="div"
              fontSize={18}
              fontWeight={'bold'}
              lineHeight={1.1}
              sx={{
                color: 'text.secondary',
                mt: 1
              }}
            >
              {`Bästa möjliga aktiveringstid är ${props.bestPossibleValue} dagar`}
            </Text>
            <Text
              component="div"
              fontSize={14}
              lineHeight={1.1}
              sx={{
                color: 'text.secondary',
                mt: 2
              }}
            >
              {`Antal användare: ${props.totalUsers}`}
            </Text>
            <Text
              component="div"
              fontSize={14}
              lineHeight={1.1}
              fontWeight={'bold'}
              sx={{
                color:
                  props.notActivatedUsers === 0 ? 'success.main' : 'error.main',
                mt: 0.5
              }}
            >
              {`Varav ej aktiverade: ${props.notActivatedUsers}`}
            </Text>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default ActivationDashboardKpiBox
