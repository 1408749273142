import { exportTable, getSIMTables, getSIMData } from 'redux/api/SIM'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { FileExtension } from 'utils/types'

export function tryGetSIMTables() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_SIM_TABLES
    })

    getSIMTables()
      .then((response) => {
        dispatch({
          type: Types.GET_SIM_TABLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_SIM_TABLES_FAILED
        })
      })
  }
}

export function tryGetSIMData(id: string, queryString: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_SIM_DATA,
      payload: { tableId: id }
    })

    getSIMData(id, queryString)
      .then((response) => {
        dispatch({
          type: Types.GET_SIM_DATA_SUCCESS,
          payload: { data: { ...response.data.data, tableId: id } }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_SIM_DATA_FAILED
        })
      })
  }
}

export function tryExportTable(
  id: string,
  tableName: string,
  extension: FileExtension,
  queryString: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.EXPORT_SIM_TABLE
    })

    exportTable(id, extension, queryString)
      .then((response) => {
        const fileName = `${tableName}.${extension}`
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.EXPORT_SIM_TABLE_SUCCESS
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export lyckades' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.EXPORT_SIM_TABLE_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export misslyckades' }
        })
      })
  }
}
