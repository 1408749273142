import React, { useEffect } from 'react'
import _ from 'lodash'

import Select from 'components/common/Select'
import Tree from 'components/common/Tree'

import Box from 'components_new/atoms/Box'

import { arrayToObject } from 'helpers/Functions'

import * as Conf from '../conf'

const Attributes = ({
  tables,
  tryGetRelatedTables,
  relations,
  checkedKeys,
  setCheckedKeys,
  mainTable,
  setMainTable,
  lockedMaintable,
  setLockedMaintable,
  setMapping,
  mapping
}) => {
  const height = (window.innerHeight * 50) / 100 //50vh in px

  useEffect(() => {
    if (mainTable && !relations[mainTable]) {
      tryGetRelatedTables(mainTable)
    }
  }, [mainTable])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Select
        label={'Välj entitet att utgå från'}
        value={mainTable}
        onChange={(value) => setMainTable(value)}
        options={
          tables
            ? tables
              .filter((t) => !t.attributes.is_link_table)
              .map((t) => ({
                value: t.id,
                label: t.attributes.name
              }))
            : []
        }
        withLock
        locked={lockedMaintable}
        setLocked={() => setLockedMaintable()}
      />
      <Tree
        height={height}
        checkable
        data={Conf.getAttributeRows(
          mainTable,
          arrayToObject(tables),
          relations[mainTable]
        )}
        checkedKeys={checkedKeys}
        setCheckedKeys={(selected) => {
          const newMapping = _.cloneDeep(mapping)

          selected.forEach((key) => {
            if (
              !checkedKeys.find((key2) => key2 === key) &&
              key in newMapping
            ) {
              newMapping[key].keep = true
            }
          })

          setMapping(newMapping)
          setCheckedKeys(selected)
        }}
      />
    </Box>
  )
}

export default Attributes
