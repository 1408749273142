import {
  getApiLogs,
  getCustomers,
  getGraphData,
  getNSMData,
  getUsers,
  getUsersUsage,
  getOneUserUsage
} from 'redux/api/Tracking'
import { update as updateUser } from 'redux/api/Accounts'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

import { PatchUserBody } from 'types/GlobalUser'

export function tryGetCustomers() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_MINDSET_FUNNEL_CUSTOMERS
    })

    getCustomers()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_CUSTOMERS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_CUSTOMERS_FAILED
        })
      })
  }
}

export function tryGetUsers() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_MINDSET_FUNNEL_USERS
    })

    getUsers()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_USERS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_USERS_FAILED
        })
      })
  }
}

export function tryGetGraphData() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_MINDSET_FUNNEL_GRAPH_DATA
    })

    getGraphData()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_GRAPH_DATA_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_MINDSET_FUNNEL_GRAPH_DATA_FAILED
        })
      })
  }
}

export function tryGetNSMData() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_NSM_DATA
    })

    getNSMData()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_NSM_DATA_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_NSM_DATA_FAILED
        })
      })
  }
}

export function tryGetApiLogs() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_APIS
    })

    getApiLogs()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_APIS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_APIS_FAILED
        })
      })
  }
}

export function tryGetUsersUsage() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_GET_USERS_USAGE
    })

    getUsersUsage()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_GET_USERS_USAGE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_GET_USERS_USAGE_FAILED
        })
      })
  }
}

export function tryGetOneUserUsage(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_GET_ONE_USER_USAGE
    })

    getOneUserUsage(id)
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_GET_ONE_USER_USAGE_FAILED
        })
      })
  }
}

export function tryUpdateUser(id: string, body: PatchUserBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_UPDATE_USER_USAGE
    })

    updateUser(id, body)
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_UPDATE_USER_USAGE_FAILED
        })
      })
  }
}
