import React, { ReactNode } from 'react'

import PageContainer from 'components_new/organisms/PageContainer'

import GlobalNavigation from 'components_new/organisms/GlobalNavigation'
import FinderNavigation from 'components_new/organisms/FinderNavigation'
import ShareDashboardButton from 'components_new/organisms/ShareDashboardButton'
import PublishChip from 'components_new/organisms/PublishChip'
import DashboardUserFilter from 'components_new/organisms/DashboardUserFilter'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import SelectCustomer from 'components_new/organisms/SelectCustomer'
import DashboardHeader from 'components_new/organisms/DashboardHeader'
import { Layout } from 'react-grid-layout'
import NewButton from 'components_new/organisms/NewButton'

interface DashboardTemplateProps {
  availableSpace: Layout | false
  children?: ReactNode
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  gridRef: any | null
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter: (value: PeriodFilter | CustomPeriodFilter | null) => void
}

const DashboardTemplate = (props: DashboardTemplateProps) => {
  const {
    availableSpace,
    children,
    dashboardFilter,
    gridRef,
    periodFilter,
    setDashboardFilter,
    setPeriodFilter
  } = props

  return (
    <PageContainer
      navContent={
        <>
          <SelectCustomer sx={{ mb: 2 }} />
          <NewButton sx={{ mb: 1 }} />
        </>
      }
      navFooter={<GlobalNavigation />}
      navMenu={<FinderNavigation />}
      rightSidebar={
        // @TODO: Hide if type is Template
        <DashboardUserFilter
          dashboardFilter={dashboardFilter}
          periodFilter={periodFilter}
          setDashboardFilter={setDashboardFilter}
          setPeriodFilter={setPeriodFilter}
        />
      }
      rightSidebarBadge={dashboardFilter.length > 0}
      rightSidebarTooltip={'Filter'}
      topBar={
        <DashboardHeader
          availableSpace={availableSpace}
          gridRef={gridRef}
          dashboardFilter={dashboardFilter}
          setDashboardFilter={setDashboardFilter}
        />
      }
      topBarActions={
        <>
          <ShareDashboardButton />
          <PublishChip />
        </>
      }
      topBarNoGutter={true}
    >
      {children}
    </PageContainer>
  )
}

export default DashboardTemplate
