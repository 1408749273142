import {
  ActivationDashboard,
  ActivationDashboardAccount
} from 'types/GlobalTracking'

const EMPTY = {
  total: 0,
  nrOfNotActivatedUsers: 0,
  avgActivationTime: 0,
  avgBestActivationTime: 0
}

export function calculateComboChart(input: ActivationDashboard | null) {
  const labels: string[] = []
  const avgActivationTime: (number | string)[] = []
  const nrOfNotActivatedUsers: (number | string)[] = []
  const avgBestActivationTime: (number | string)[] = []

  if (!input) {
    return {
      labels,
      nrOfNotActivatedUsers,
      avgActivationTime,
      avgBestActivationTime
    }
  }

  input.activation_over_time.forEach((item) => {
    const calculations = calculate(item.accounts)

    labels.push(item.label)
    nrOfNotActivatedUsers.push(calculations.nrOfNotActivatedUsers)
    avgActivationTime.push(calculations.avgActivationTime)
    avgBestActivationTime.push(calculations.avgBestActivationTime)
  })

  return {
    labels,
    avgActivationTime,
    nrOfNotActivatedUsers,
    avgBestActivationTime
  }
}

export function calculateKpiBoxes(data: ActivationDashboard | null) {
  if (!data) {
    return { all: EMPTY, beforeSep: EMPTY, afterSep: EMPTY }
  }

  const all = data.accounts

  return {
    all: calculate(all),
    beforeSep: calculate(
      all.filter((account) => account.created_before_end_of_aug)
    ),
    afterSep: calculate(
      all.filter((account) => !account.created_before_end_of_aug)
    )
  }
}

function calculate(accounts: ActivationDashboardAccount[]) {
  const total = accounts.length
  const nrOfNotActivatedUsers = accounts.filter(
    (account) => !account.is_activated
  ).length
  const daysToActivated: number[] = accounts
    .filter((account) => account.days_to_activated)
    .map((account) => account.days_to_activated as number)
  const bestDaysToActivated: number[] = accounts.map(
    (account) => account.days_to_activated_or_today
  )

  return {
    total,
    nrOfNotActivatedUsers,
    avgActivationTime:
      daysToActivated.length === 0
        ? 0
        : parseInt(
          (
            daysToActivated.reduce((a, b) => a + b, 0) /
              daysToActivated.length
          ).toFixed(0)
        ),
    avgBestActivationTime:
      bestDaysToActivated.length === 0
        ? 0
        : parseInt(
          (
            bestDaysToActivated.reduce((a, b) => a + b, 0) /
              bestDaysToActivated.length
          ).toFixed(0)
        )
  }
}
