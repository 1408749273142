import React, { ReactNode, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'

import { Color } from 'themes'
import { getThumbnail } from 'utils/functions'

interface DashboardPreviewProps {
  children?: ReactNode
  color?: Color
  showroomId: string
  thumbnail: string
}

const DashboardPreview = (props: DashboardPreviewProps) => {
  const { children, color, showroomId, thumbnail } = props

  const [hover, setHover] = useState(false)

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={'https://portal.homepal.se/public/dashboards/' + showroomId}
    >
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          position: 'relative',
          width: '100%',
          paddingBottom: '56.25%',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: hover ? `${color}.main` : 'action.selected',
          borderRadius: 1,
          overflow: 'hidden',
          cursor: 'pointer'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            top: '0%',
            bottom: '0%',
            left: '0%',
            right: '0%',
            overflow: 'hidden',
            pointerEvents: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'action.hover',
            opacity: hover ? 1 : 0
          }}
        >
          <Button
            color={color}
            size="large"
            variant="contained"
            disableElevation={true}
            endIcon={
              <Icon
                name="NorthEast"
                sx={{
                  transform: hover ? 'translateX(4px)' : undefined,
                  transition: '0.2s'
                }}
              />
            }
            sx={{ position: 'absolute' }}
          >
            Öppna
          </Button>
        </Box>
        <Box
          sx={{
            mixBlendMode: 'hard-light',
            backgroundImage:
              'radial-gradient(circle at 90% 10%,rgba(255,255,255,.2) 8%,transparent 60%),linear-gradient(rgba(255,255,255,.1),rgba(255,255,255,0) 30%)',
            position: 'absolute',
            zIndex: 2,
            top: '0%',
            bottom: '0%',
            left: '0%',
            right: '0%',
            overflow: 'hidden',
            pointerEvents: 'none'
          }}
        />
        {children}
        <img
          src={getThumbnail(thumbnail)}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '2px'
          }}
        />
      </Box>
    </a>
  )
}

export default DashboardPreview
