import { IconNames } from 'components_new/atoms/Icon'
import { TNavigationItem } from 'components_new/molecules/NavigationItem'
import { DashboardGroup } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'

export const getEmptyState = (showFavorites: boolean) => {
  if (showFavorites) {
    return {
      title: 'Inga favoriter här',
      body: 'Favoritmarkera dashboards så hamnar de här.',
      src: '/assets/illustrations/star_dashboard.svg',
      routeToAll: true
    }
  }

  return {
    title: 'Inga dashboards här',
    body: 'Det finns inga dashboards att visa från din organisation.',
    src: '/assets/illustrations/find_dashboard.svg',
    routeToAll: false
  }
}

export const NO_FOLDER = 'NO_FOLDER'
export const FAVORITES = 'FAVORITES'

export const groupDashboards = (
  groups: DashboardGroup[],
  folders: DashboardFolder[]
) => {
  const groupedDashboards: { [key: string]: DashboardGroup[] } = {
    [NO_FOLDER]: groups.filter(
      (group) => !folders.some((folder) => folder.id === group.folder_id)
    ),
    [FAVORITES]: groups.filter((group) => group.favorite.active)
  }

  folders.forEach((folder) => {
    groupedDashboards[folder.id] = groups.filter(
      (group) => group.folder_id === folder.id
    )
  })

  return groupedDashboards
}

export const getSelectedGroups = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  showFavorites: boolean,
  folderId?: string | null
) => {
  if (showFavorites) {
    return groupedDashboards[FAVORITES] || []
  }

  if (folderId) {
    return groupedDashboards[folderId] || []
  }

  return groupedDashboards[NO_FOLDER] || []
}

export const getFolderTree = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  folders: DashboardFolder[]
): TNavigationItem[] => {
  return [
    {
      icon: 'GridViewOutlined',
      href: '/dashboards',
      title: 'Alla dashboards',
      subItems: [
        ...folders.map((folder) => {
          return {
            icon: 'FolderOutlined' as IconNames,
            href: `/dashboards/folders/${folder.id}`,
            title: folder.name,
            subItems: (groupedDashboards[folder.id] || [])
              .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
              .map((group) => {
                return {
                  activePaths: group.dashboards,
                  icon: 'AnalyticsOutlined' as IconNames,
                  href: `/dashboards/${group.dashboards[0]}`,
                  title: group.title
                }
              })
          }
        }),
        // without folder
        ...(groupedDashboards[NO_FOLDER] || [])
          .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
          .map((group) => {
            return {
              activePaths: group.dashboards,
              icon: 'AnalyticsOutlined' as IconNames,
              href: `/dashboards/${group.dashboards[0]}`,
              title: group.title
            }
          })
      ]
    },
    {
      icon: 'StarOutlined',
      href: '/dashboards/favorites',
      title: 'Favoriter',
      subItems: (groupedDashboards[FAVORITES] || [])
        .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
        .map((group) => {
          return {
            activePaths: group.dashboards,
            icon: 'AnalyticsOutlined',
            href: `/dashboards/${group.dashboards[0]}`,
            title: group.title
          }
        })
    }
    // Implement in nex release:
    //
    // {
    //   icon: 'RestoreOutlined',
    //   href: '/dashboards/recent',
    //   title: 'Senaste'
    // }
  ]
}
