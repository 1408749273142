import React from 'react'

import { Handle, Position } from 'reactflow'

import { useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'

import Styles from './styles.module.css'

const Node = ({
  icon,
  title,
  children,
  hasInput = true,
  twoInputs,
  leftInputCharacter,
  rightInputCharacter,
  selected,
  locked,
  isOutputNode = false,
  hiddenHandles = false,
  className,
  nodeNumber
}) => {
  const theme = useTheme()
  const handleCssObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    background: theme.palette.primary.transparent,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    width: '24px'
  }

  return (
    <>
      <Paper
        sx={{
          minWidth: '8rem',
          backgroundColor: selected ? 'grey.100' : undefined,
          overflow: 'hidden'
        }}
      >
        {title && (
          <>
            <Box
              sx={{
                alignItems: 'center',
                bgcolor: 'black.main',
                color: 'white.main',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                px: 1
              }}
            >
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Icon fontSize="10px" name={icon} sx={{ mr: 0.25 }} />
                {title}
              </Box>
              {locked ? <Icon fontSize="10px" name="LockOutlined" /> : null}
              {nodeNumber ? (
                <Chip color="primary" label={nodeNumber} size="small" />
              ) : null}
            </Box>
            <Divider />
          </>
        )}
        <Box
          className={className}
          sx={{
            minHeight: '3rem',
            p: 1
          }}
        >
          {children}
        </Box>
        {hasInput ? (
          twoInputs ? (
            <Box>
              <Handle
                id="left"
                isConnectable={true}
                type="target"
                position={Position.Left}
                className={`${
                  hiddenHandles ? Styles['hidden-handle-left'] : ''
                } ${Styles['handle-left']} ${Styles['multiple-handles-top']}`}
                style={handleCssObject}
              >
                <small className={'tiny'} style={{ pointerEvents: 'none' }}>
                  {leftInputCharacter}
                </small>
              </Handle>
              <Handle
                id="right"
                isConnectable={true}
                type="target"
                position={Position.Left}
                className={`${
                  hiddenHandles ? Styles['hidden-handle-left'] : ''
                } ${Styles['handle-left']} ${
                  Styles['multiple-handles-bottom']
                }`}
                style={handleCssObject}
              >
                <small className={'tiny'} style={{ pointerEvents: 'none' }}>
                  {rightInputCharacter}
                </small>
              </Handle>
            </Box>
          ) : (
            <Handle
              isConnectable={true}
              type="target"
              position={Position.Left}
              className={`${
                hiddenHandles ? Styles['hidden-handle-left'] : ''
              } ${Styles['handle-left']}`}
              style={handleCssObject}
            >
              {!hiddenHandles ? (
                <Icon
                  fontSize="small"
                  name="ChevronRight"
                  sx={{ pointerEvents: 'none' }}
                />
              ) : null}
            </Handle>
          )
        ) : null}
        {!isOutputNode ? (
          <Handle
            isConnectable={true}
            type="source"
            position={Position.Right}
            className={`${hiddenHandles ? Styles['hidden-handle-right'] : ''} ${
              Styles['handle-right']
            }`}
            style={handleCssObject}
          >
            {!hiddenHandles ? (
              <Icon
                fontSize="small"
                name="ChevronRight"
                sx={{ pointerEvents: 'none' }}
              />
            ) : null}
          </Handle>
        ) : null}
      </Paper>
    </>
  )
}

export default Node
