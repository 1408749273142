import React from 'react'

import Icon from 'components_new/atoms/Icon'

/**
 * Returns data regarding a specific type, defaults to STRING
 *
 * @param {string} type
 * @returns object containing icon and label for type
 */
export const getTypeData = (type) => {
  switch (type) {
  case 'BOOLEAN':
    return {
      icon: <Icon name="CheckBoxTwoTone" fontSize="12px" />,
      label: 'Sant/Falskt'
    }
  case 'DATE':
    return {
      icon: <Icon name="CalendarMonthTwoTone" fontSize="12px" />,
      label: 'Datum'
    }
  case 'DOUBLE':
    return {
      icon: false,
      label: 'Decimaltal',
      altIcon: (
        <svg viewBox="0 0 10 10">
          <text
            x={'50%'}
            y={'50%'}
            textAnchor="middle"
            fontSize="26"
            fill="#22c55e"
            fontWeight={'400'}
            cursor="default"
          >
              ,
          </text>
        </svg>
      )
    }
  case 'BIGINT':
    return {
      icon: <Icon name="TagTwoTone" fontSize="12px" />,
      label: 'Heltal'
    }
  case 'TIMESTAMP':
    return {
      icon: <Icon name="TimerTwoTone" fontSize="12px" />,
      label: 'Tidsstämpel'
    }

  default:
    return {
      icon: <Icon name="TitleTwoTone" fontSize="12px" />,
      label: 'Text'
    }
  }
}
