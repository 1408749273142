import React from 'react'

import Icon from 'components_new/atoms/Icon'

import Chip from 'components_new/atoms/Chip'
import Tooltip from 'components_new/atoms/Tooltip'

export const PAGE_LIMIT = 10

export const updateSearchParams = (history, key, val) => {
  const urlParams = new URLSearchParams(history.location.search)

  if (val) {
    urlParams.set(key, val)
  } else {
    urlParams.delete(key)
  }

  return urlParams.toString()
}

export const FILTER_OPTIONS = (attributes) => {
  return attributes.map((attr) => ({
    label: attr.attributes.name,
    value: attr.id
  }))
}

export const FLAW_TABLE_HEADERS = (attributes, quality, attributeId) => {
  const topLabelShow = (id) =>
    quality && id in quality && (!attributeId || attributeId === id)

  return attributes.map((attr) => ({
    topLabel:
      topLabelShow(attr.id) &&
      (quality[attr.id].invalid === 0 ? (
        <Icon color="success" name="CheckCircleOutlined" />
      ) : (
        <Chip
          color="error"
          icon={<Icon color="error" name="ErrorOutlineOutlined" />}
          label={`${quality[attr.id].invalid} ogiltiga`}
          size="small"
        />
      )),
    label: (
      <div className={'overflow-ellipsis'}>
        <small className={'tiny uppercase'}>{attr.attributes.name}</small>
      </div>
    ),
    key: attr.attributes.real_name,
    sorter: true,
    showSorterTooltip: {
      title: 'Sortera'
    },
    render: function renderColumn(val) {
      return (
        val &&
        (val.errors.length > 0 ? (
          <Tooltip
            title={
              val.errors.length === 1 ? (
                ERROR_DESCRIPTION[val.errors[0]]
              ) : (
                <ul>
                  {val.errors.map((er, i) => (
                    <li key={`${attr.id}-errors-${i}`}>
                      {ERROR_DESCRIPTION[er]}
                    </li>
                  ))}
                </ul>
              )
            }
          >
            <Chip
              clickable
              color="error"
              icon={<Icon name="ErrorOutlineOutlined" />}
              label={val.value}
              size="small"
            />
          </Tooltip>
        ) : (
          val.value
        ))
      )
    }
  }))
}

const ERROR_DESCRIPTION = {
  EXISTS: 'Värde saknas.',
  UNIQUE: 'Värde är inte unikt.',
  FORMAT: 'Värde har fel format.'
}
