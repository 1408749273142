import React, { ElementType, ReactNode } from 'react'
import { Stack as MUIStack } from '@mui/material'

interface StackProps {
  alignItems?: string
  children?: ReactNode
  component?: ElementType<any>
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row'
  divider?: ReactNode
  justifyContent?: string
  spacing?: number | string
  sx?: object
}

/**
 * The Stack component manages layout of immediate
 * children along the vertical or horizontal axis
 * with optional spacing and/or dividers between
 * each child.
 *
 * Should be used instead of a Box (<div>) with
 * 'display: flex' + 'flexDirection: row | column'.
 */

const Stack = React.forwardRef((props: StackProps, ref: any) => {
  return (
    <MUIStack {...props} ref={ref}>
      {props.children}
    </MUIStack>
  )
})

Stack.defaultProps = {
  direction: 'row'
}

Stack.displayName = 'Stack'
export default Stack
