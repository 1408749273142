import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Cascader from 'components/common/Cascader'

import * as ShapeDatasetActions from 'redux/actions/DataPlatform/shapes/v2/Datasets'
import * as ShapeSourceSchemaActions from 'redux/actions/DataPlatform/shapes/SourceSchemas'

import * as Conf from './conf.js'

const InputCascader = ({
  value,
  onChange,
  className = '',
  disabled = false,
  ignoreLockedMode = false,
  placeholder = 'Datakälla',
  showOntology = true,
  clearable = true,
  DatasetStore,
  size,
  onlyShowLastValue,
  dropdownClassName,
  ...Actions
}) => {
  // on mount
  useEffect(() => {
    if (!DatasetStore.sourcesFetched && !DatasetStore.fetchingSources) {
      Actions.tryGetSourceSchemas()
    }
  }, [])

  if (!DatasetStore.sourcesFetched) {
    return null
  }

  const options = Conf.INPUT_OPTIONS(DatasetStore, showOntology)

  return (
    <div className={className}>
      <Cascader
        size={size}
        value={value}
        onChange={(value) => onChange(value)}
        options={options}
        className={'width-100'}
        placeholder={placeholder}
        disabled={disabled}
        ignoreLockedMode={ignoreLockedMode}
        clearable={clearable}
        onlyShowLastValue={onlyShowLastValue}
        dropdownClassName={dropdownClassName}
        search
      />
    </div>
  )
}

function mapStateToProps({ DatasetStore }) {
  return { DatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...ShapeDatasetActions, ...ShapeSourceSchemaActions },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InputCascader))
