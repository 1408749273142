import React, { useState } from 'react'

import { useTheme } from '@mui/material'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import ButtonField from 'components_new/molecules/ButtonField'
import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import ColorInput from 'components_new/molecules/ColorInput'

import { ColorSchemePatchBody } from 'redux/api/Admin/Customization'

import EditLogotypeDialog from './EditLogotypeDialog'
import { getColorMode, getTheme } from 'themes'

import { CustomColors } from 'types/GlobalCustomization'

export type InnerCustomizationBody = ColorSchemePatchBody['data']
interface CustomizationProps {
  customColors: CustomColors
  setCustomColors: (params: InnerCustomizationBody) => void
  allowWhiteLabelColors: boolean
  allowWhiteLabelLogotype: boolean
}

const CustomizationTemplate = (props: CustomizationProps) => {
  const { customColors, setCustomColors } = props

  const theme = useTheme()

  const [editLogotype, setEditLogotype] = useState<boolean>(false)

  const resetCustomColors = () => {
    setCustomColors({
      primary_color: null,
      secondary_color: null,
      tertiary_color: null,
      quaternary_color: null,
      quintary_color: null,
      senary_color: null,
      septenary_color: null,
      octonary_color: null,
      nonary_color: null,
      denary_color: null,
      text_primary_color: null,
      text_secondary_color: null,
      background_color: null,
      widget_background_color: null
    })
  }

  const getPrimaryColor = () => {
    if (customColors?.primary_color) {
      return customColors?.primary_color
    }

    if (customColors?.widget_background_color) {
      return getTheme(getColorMode(customColors.widget_background_color))
        .palette.primary.main
    }

    return theme.palette.primary.main
  }

  return (
    <>
      <CollapsibleSection
        title="Logotyp"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
      >
        <Box sx={{ py: 2, maxWidth: 'content' }}>
          <Text variant="body2" color="text.secondary" gutterBottom>
            Anpassa logotyp så att detta matchar er grafiska profil och
            identitet.
          </Text>
          <Box sx={{ pt: 2 }}>
            {!props.allowWhiteLabelLogotype ? (
              <>
                <Alert severity="warning" icon={<Icon name="PrivacyTip" />}>
                  <strong>Anpassad logotyp ingår inte i din licens.</strong>{' '}
                  Kontakta{' '}
                  <Link href={'mailto:support@homepal.se'}>
                    support@homepal.se
                  </Link>{' '}
                  om du vill aktivera den här funktionen.
                </Alert>
              </>
            ) : (
              <Box sx={{ height: 72 }}>
                {customColors.logotype ? (
                  <Box
                    onClick={() => setEditLogotype(true)}
                    sx={{
                      position: 'relative',
                      height: '100%',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      bgcolor: 'action.hover',
                      '&:hover': {
                        bgcolor: 'action.selected'
                      },
                      width: 'fit-content',
                      p: 1,
                      borderRadius: 1
                    }}
                  >
                    <Icon
                      fontSize="small"
                      name="EditOutlined"
                      sx={{ position: 'absolute', top: 4, right: 4 }}
                    />
                    <img
                      src={customColors.logotype}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                ) : (
                  <ButtonField
                    fullHeight={true}
                    onClick={() => setEditLogotype(true)}
                    sx={{ width: 144 }}
                  >
                    Lägg till
                  </ButtonField>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </CollapsibleSection>

      <CollapsibleSection
        actions={
          <Tooltip title="Återgå till att använda Homepals standardfärger">
            <Button
              onClick={resetCustomColors}
              startIcon={<Icon name="RestartAltOutlined" />}
            >
              Återställ alla
            </Button>
          </Tooltip>
        }
        title="Färgschema"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
      >
        <Box sx={{ py: 2, maxWidth: 'content' }}>
          {!props.allowWhiteLabelColors ? (
            <Alert severity="warning" icon={<Icon name="PrivacyTip" />}>
              <strong>Anpassat färgschema ingår inte i din licens.</strong>{' '}
              Kontakta{' '}
              <Link href={'mailto:support@homepal.se'}>support@homepal.se</Link>{' '}
              om du vill aktivera den här funktionen.
            </Alert>
          ) : (
            <>
              <Text variant="body2" color="text.secondary" gutterBottom>
                Anpassa färgschema så att det matchar organisationens grafiska
                profil och identitet.
              </Text>
              <Box sx={{ py: 2 }}>
                <Text variant="h6" gutterBottom>
                  Bakgrund
                </Text>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <ColorInput
                    hexCode={
                      customColors?.background_color ?? theme.palette.white.main
                    }
                    isDefault={!customColors?.background_color}
                    key={0}
                    title="Dashboard"
                    onChange={(hexCode: string) =>
                      setCustomColors({ background_color: hexCode })
                    }
                    onReset={() => setCustomColors({ background_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.widget_background_color ??
                      theme.palette.white.main
                    }
                    isDefault={!customColors?.widget_background_color}
                    key={1}
                    title="Widget"
                    onChange={(hexCode: string) =>
                      setCustomColors({ widget_background_color: hexCode })
                    }
                    onReset={() =>
                      setCustomColors({ widget_background_color: null })
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2 }}>
                <Text variant="h6" gutterBottom>
                  Grafer
                </Text>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <ColorInput
                    hexCode={getPrimaryColor()}
                    isDefault={!customColors?.primary_color}
                    key={2}
                    title="Primär"
                    onChange={(hexCode: string) =>
                      setCustomColors({ primary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ primary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.secondary_color ??
                      theme.palette.secondary.main
                    }
                    isDefault={!customColors?.secondary_color}
                    key={3}
                    title="Sekundär"
                    onChange={(hexCode: string) =>
                      setCustomColors({ secondary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ secondary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.tertiary_color ??
                      theme.palette.tertiary.main
                    }
                    key={4}
                    isDefault={!customColors?.tertiary_color}
                    title="Tertiär"
                    onChange={(hexCode: string) =>
                      setCustomColors({ tertiary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ tertiary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.quaternary_color ??
                      theme.palette.quaternary.main
                    }
                    key={5}
                    isDefault={!customColors?.quaternary_color}
                    title="#4"
                    onChange={(hexCode: string) =>
                      setCustomColors({ quaternary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ quaternary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.quintary_color ??
                      theme.palette.quintary.main
                    }
                    key={6}
                    isDefault={!customColors?.quintary_color}
                    title="#5"
                    onChange={(hexCode: string) =>
                      setCustomColors({ quintary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ quintary_color: null })}
                  />
                  {/* ----- NEW ----- */}
                  <ColorInput
                    hexCode={
                      customColors?.senary_color ?? theme.palette.senary.main
                    }
                    key={7}
                    isDefault={!customColors?.senary_color}
                    title="#6"
                    onChange={(hexCode: string) =>
                      setCustomColors({ senary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ senary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.septenary_color ??
                      theme.palette.septenary.main
                    }
                    isDefault={!customColors?.septenary_color}
                    key={8}
                    title="#7"
                    onChange={(hexCode: string) =>
                      setCustomColors({ septenary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ septenary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.octonary_color ??
                      theme.palette.octonary.main
                    }
                    isDefault={!customColors?.octonary_color}
                    key={9}
                    title="#8"
                    onChange={(hexCode: string) =>
                      setCustomColors({ octonary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ octonary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.nonary_color ?? theme.palette.nonary.main
                    }
                    isDefault={!customColors?.nonary_color}
                    key={10}
                    title="#9"
                    onChange={(hexCode: string) =>
                      setCustomColors({ nonary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ nonary_color: null })}
                  />
                  <ColorInput
                    hexCode={
                      customColors?.denary_color ?? theme.palette.denary.main
                    }
                    isDefault={!customColors?.denary_color}
                    key={11}
                    title="#10"
                    onChange={(hexCode: string) =>
                      setCustomColors({ denary_color: hexCode })
                    }
                    onReset={() => setCustomColors({ denary_color: null })}
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2 }}>
                <Text variant="h6" gutterBottom>
                  Text
                </Text>
                <Box sx={{ pb: 2 }}>
                  <Alert severity="info">
                    <strong>Tänk på!</strong> Text anpassas automatiskt efter
                    bakgrund i widget och dashboard. Om du väljer att själv
                    anpassa textfärger kommer den automatiska anpassningen att
                    inaktiveras. Vi rekommenderar därför <u>starkt</u> att ha
                    kvar text på <code>auto</code>.
                  </Alert>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <ColorInput
                    hexCode={
                      customColors?.text_primary_color ??
                      theme.palette.black.main
                    }
                    isDefault={!customColors?.text_primary_color}
                    key={12}
                    title="Primär"
                    onChange={(hexCode: string) =>
                      setCustomColors({ text_primary_color: hexCode })
                    }
                    onReset={() =>
                      setCustomColors({ text_primary_color: null })
                    }
                  />
                  <ColorInput
                    hexCode={
                      customColors?.text_secondary_color ??
                      theme.palette.black.main
                    }
                    isDefault={!customColors?.text_secondary_color}
                    key={13}
                    title="Sekundär"
                    onChange={(hexCode: string) =>
                      setCustomColors({ text_secondary_color: hexCode })
                    }
                    onReset={() =>
                      setCustomColors({ text_secondary_color: null })
                    }
                  />
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Text variant="h6" gutterBottom>
                Status
              </Text>
              <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <ColorInput
                    disabled={true}
                    hexCode={theme.palette.success.main}
                    isDefault={true}
                    key={14}
                    title={'Positiv färg'}
                  />
                  <ColorInput
                    disabled={true}
                    hexCode={theme.palette.warning.main}
                    isDefault={true}
                    key={15}
                    title={'Varningsfärg'}
                  />
                  <ColorInput
                    disabled={true}
                    hexCode={theme.palette.error.main}
                    isDefault={true}
                    key={16}
                    title={'Negativ färg'}
                  />
                </Box>
              </Box>
              <Box sx={{ pt: 2 }}>
                <Alert severity="info">
                  <strong>Tänk på!</strong> Du bör undvika att välja en grön,
                  gul eller röd färg i dina anpassade graf- och textfärger ovan.
                  Dessa används redan till att indikera positiv eller negativ
                  utveckling i ett nyckeltal, och i vissa fall även som
                  statusindikator.
                </Alert>
              </Box>
            </>
          )}
        </Box>
      </CollapsibleSection>

      <EditLogotypeDialog
        open={editLogotype}
        onClose={() => setEditLogotype(false)}
        onSave={(value) => setCustomColors({ logotype: value })}
        value={customColors.logotype}
      />
    </>
  )
}

export default CustomizationTemplate
