import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import {
  PatchDashboardGroup,
  copy,
  update,
  destroy,
  updateOrder
} from 'redux/api/DashboardGroups'
import { DashboardType } from 'redux/reducers/Dashboards'

export function tryUpdateDashboardOrder(id: string, body: { data: string[] }) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_TEMPLATE_ORDER
    })

    updateOrder(id, body)
      .then(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_ORDER_SUCCESS,
          payload: {
            id,
            order: body.data
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_ORDER_FAILED
        })
      })
  }
}

export function tryCopyDashboardTemplateGroup(
  id: string,
  navigate: (id: string) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.COPY_DASHBOARD_TEMPLATE_GROUP
    })

    copy(id, DashboardType.TEMPLATE)
      .then((response) => {
        dispatch({
          type: Types.COPY_DASHBOARD_TEMPLATE_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard duplicerad',
            duration: 10000,
            link: () => navigate(response.data.data.dashboards[0].id),
            linkText: 'Gå till dashboard >'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.COPY_DASHBOARD_TEMPLATE_GROUP_FAILED
        })
      })
  }
}

export function tryUpdateDashboardTemplateGroup(
  id: string,
  body: PatchDashboardGroup
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_TEMPLATE_GROUP
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard uppdaterad',
            duration: 5000
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_TEMPLATE_GROUP_FAILED
        })
      })
  }
}

export function tryDeleteDashboardTemplateGroup(
  id: string,
  callback: () => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_DASHBOARD_TEMPLATE_GROUP
    })

    destroy(id)
      .then(() => {
        callback()

        dispatch({
          type: Types.DELETE_DASHBOARD_TEMPLATE_GROUP_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard borttagen',
            duration: 5000
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_TEMPLATE_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard kunde inte tas bort',
            duration: 5000
          }
        })
      })
  }
}
