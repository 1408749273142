import * as Api from 'redux/api/DataPlatform/dataQuality/flaws/v1'
import * as Types from 'redux/Types'

export function tryExportFlaws(tableId, type, attribute) {
  return (dispatch) => {
    dispatch({
      type: Types.EXPORT_FLAWS
    })

    Api.exportFlaws(tableId, type, attribute)
      .then((response) => {
        const fileName = `Databrister.${type}`
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.EXPORT_FLAWS_SUCCESS
        })
      })
      .catch(() => {
        dispatch({
          type: Types.EXPORT_FLAWS_FAILED
        })
      })
  }
}

export function tryGetAllFlaws(tableId, offset, attribute, sort) {
  return (dispatch) => {
    dispatch({
      type: Types.GET_FLAWS
    })

    Api.getAll(tableId, offset, attribute, sort)
      .then((response) => {
        dispatch({
          type: Types.GET_FLAWS_SUCCESS,
          payload: response.data,
          tableId
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_FLAWS_FAILED
        })
      })
  }
}
