import React from 'react'

import Lodash from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'
import InputNumber from 'components/common/InputNumber'
import DatePicker from 'components/common/DatePicker'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ToggleButton from 'components_new/atoms/ToggleButton'

import * as Conf from '../conf.js'
import Styles from '../styles.module.css'

const InnerFilterContent = ({
  id,
  data,
  setData,
  filterOutNewAttribute = null
}) => {
  const getTypeInput = (r, i) => {
    switch (r.type) {
    case Conf.INPUT_VALUE:
      if (r.dataType === 'BIGINT' || r.dataType === 'DOUBLE') {
        return (
          <InputNumber
            size={'small'}
            value={r.rightValue}
            onChange={(val) => {
              const tmpConditions = Lodash.cloneDeep(data.filterConditions)

              tmpConditions[i].rightAttribute = null
              tmpConditions[i].rightValue = val
              setData(id, { filterConditions: tmpConditions })
            }}
          />
        )
      }
      if (r.dataType === 'DATE' || r.dataType === 'TIMESTAMP') {
        return (
          <DatePicker
            value={r.rightValue}
            picker={'date'}
            className={Styles['filter-date-picker']}
            onChange={(val) => {
              const tmpConditions = Lodash.cloneDeep(data.filterConditions)

              tmpConditions[i].rightAttribute = null
              tmpConditions[i].rightValue = val
              setData(id, { filterConditions: tmpConditions })
            }}
          />
        )
      }

      return (
        <Input
          size={'small'}
          value={r.rightValue}
          onChange={(val) => {
            const tmpConditions = Lodash.cloneDeep(data.filterConditions)

            tmpConditions[i].rightAttribute = null
            tmpConditions[i].rightValue = val.target.value
            setData(id, { filterConditions: tmpConditions })
          }}
        />
      )
    case Conf.NUMBER_OF_CHARACTERS:
      return (
        <InputNumber
          size={'small'}
          value={r.rightValue}
          onChange={(val) => {
            const tmpConditions = Lodash.cloneDeep(data.filterConditions)

            tmpConditions[i].rightAttribute = null
            tmpConditions[i].rightValue = val
            setData(id, { filterConditions: tmpConditions })
          }}
        />
      )
    case Conf.COLUMN:
      const options = Conf.parseAttributeOptions(
        data.outputAttributes.filter((item) => item.type === r.dataType),
        data.nodeNumberMapper
      )

      return (
        <Select
          search
          dropdownMatchSelectWidth={false}
          ignoreLockedMode
          inNode
          size={'small'}
          value={r.rightAttribute}
          onChange={(val) => {
            const tmpConditions = Lodash.cloneDeep(data.filterConditions)

            tmpConditions[i].rightValue = null
            tmpConditions[i].rightAttribute = val
            setData(id, { filterConditions: tmpConditions })
          }}
          options={
            filterOutNewAttribute
              ? options.filter((opt) => opt.value !== filterOutNewAttribute)
              : options
          }
        />
      )
    case Conf.STANDARD_VALUE:
      return null
    default:
      return null
    }
  }

  const attributeOptions = Conf.parseAttributeOptions(
    data.outputAttributes,
    data.nodeNumberMapper
  )

  return (
    <Box className={'nodrag'}>
      {data.filterConditions.map((r, i) => {
        return (
          <Box key={`${id}-filter-attr-${i}`}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ mb: 0.25 }}>
                  <Select
                    search
                    dropdownMatchSelectWidth={false}
                    placeholder={'Attribut...'}
                    ignoreLockedMode
                    inNode
                    size={'small'}
                    value={r.leftAttribute}
                    onChange={(val) => {
                      const tmpConditions = Lodash.cloneDeep(
                        data.filterConditions
                      )

                      tmpConditions[i].leftAttribute = val
                      tmpConditions[i].type = null

                      if (val) {
                        const leftAttribute = data.outputAttributes.find(
                          (item) => item.shortId === val
                        )

                        tmpConditions[i].dataType =
                          leftAttribute.type?.toUpperCase()
                      } else {
                        tmpConditions[i].dataType = null
                      }

                      setData(id, { filterConditions: tmpConditions })
                    }}
                    options={
                      filterOutNewAttribute
                        ? attributeOptions.filter(
                          (opt) => opt.value !== filterOutNewAttribute
                        )
                        : attributeOptions
                    }
                  />
                </Box>
                {r.leftAttribute && (
                  <Box sx={{ mb: 0.25 }}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      ignoreLockedMode
                      inNode
                      size={'small'}
                      placeholder={'Förhållande...'}
                      value={r.condition}
                      onChange={(val) => {
                        const tmpConditions = Lodash.cloneDeep(
                          data.filterConditions
                        )

                        tmpConditions[i].condition = val

                        if (Conf.ONLY_LEFT_CONDITIONS.includes(val)) {
                          tmpConditions[i].type = Conf.ONLY_LEFT
                        } else if (Conf.ONLY_TEXT_CONDITIONS.includes(val)) {
                          tmpConditions[i].type = Conf.INPUT_VALUE
                        } else if (
                          tmpConditions[i].type === Conf.ONLY_LEFT ||
                          !tmpConditions[i].type
                        ) {
                          // change to default type column
                          // if prev type was ONLY_LEFT or null
                          tmpConditions[i].type = Conf.COLUMN
                        }

                        setData(id, { filterConditions: tmpConditions })
                      }}
                      options={Conf.CONDITIONS(r.dataType)}
                      sorted={false}
                    />
                  </Box>
                )}
                {r.condition && (
                  <Box sx={{ display: 'flex', gap: 0.25, mb: 0.25 }}>
                    {r.type !== Conf.ONLY_LEFT && (
                      <Box sx={{ flex: 1 }}>
                        <Select
                          clearable={false}
                          dropdownMatchSelectWidth={false}
                          ignoreLockedMode
                          inNode
                          size={'small'}
                          value={r.type}
                          onChange={(val) => {
                            const tmpConditions = Lodash.cloneDeep(
                              data.filterConditions
                            )

                            if (tmpConditions[i].type !== val) {
                              tmpConditions[i].rightValue = null
                              tmpConditions[i].rightAttribute = null
                            }

                            if (Conf.STANDARD_VALUES.includes(val)) {
                              tmpConditions[i].rightValue = val
                            }

                            tmpConditions[i].type = val

                            setData(id, { filterConditions: tmpConditions })
                          }}
                          greyText
                          options={
                            Conf.ONLY_TEXT_CONDITIONS.includes(r.condition)
                              ? [{ value: Conf.INPUT_VALUE, label: 'Text' }]
                              : Conf.FILTER_VALUE_TYPES[
                                r.dataType?.toUpperCase()
                              ]
                          }
                        />
                      </Box>
                    )}
                    <Box sx={{ flex: 1 }}>{getTypeInput(r, i)}</Box>
                  </Box>
                )}
              </Box>
              <Box>
                <IconButton
                  edge="end"
                  onClick={() => {
                    const tmpConditions = Lodash.cloneDeep(
                      data.filterConditions
                    )

                    tmpConditions.splice(i, 1)
                    setData(id, { filterConditions: tmpConditions })
                  }}
                  size="small"
                >
                  <Icon fontSize="small" name="Close" />
                </IconButton>
              </Box>
            </Box>
            {i !== data.filterConditions.length - 1 && (
              <ToggleButton
                items={[
                  { title: 'AND', value: 'AND' },
                  { title: 'OR', value: 'OR' }
                ]}
                onChange={(event, value) => {
                  const tmpConditions = Lodash.cloneDeep(data.filterConditions)

                  tmpConditions[i].conjunctiveOperator = value
                  setData(id, { filterConditions: tmpConditions })
                }}
                size="small"
                sx={{ my: 1 }}
                value={r.conjunctiveOperator}
              />
            )}
          </Box>
        )
      })}

      <Button
        fullWidth
        onClick={() =>
          setData(id, {
            filterConditions: [
              ...data.filterConditions,
              {
                leftAttribute: undefined,
                condition: undefined,
                rightValue: undefined,
                rightAttribute: undefined,
                conjunctiveOperator: 'AND',
                type: Conf.COLUMN,
                index:
                  data.filterConditions.length > 0
                    ? data.filterConditions[data.filterConditions.length - 1]
                      .index + 1
                    : 0
              }
            ]
          })
        }
        size="small"
        startIcon={<Icon name="AddCircleOutlineOutlined" fontSize="small" />}
        sx={{ mt: 1 }}
        variant="text"
      >
        Lägg till
      </Button>
    </Box>
  )
}

export default InnerFilterContent
