import _ from 'lodash'

export const INPUT_OPTIONS = (DatasetStore, showOntology) =>
  [
    {
      value: 'collect',
      label: 'Källdata',
      children: getTables(Object.values(DatasetStore.sourceSchemas.collect))
    },
    {
      value: 'build',
      label: 'Referensdata',
      children: getTables(Object.values(DatasetStore.sourceSchemas.build))
    },
    showOntology
      ? [
          {
            value: 'shape',
            label: 'Harmoniserad data',
            children: getTables(Object.values(DatasetStore.sourceSchemas.shape))
          }
        ]
      : []
  ].flat()

const getTables = (tables, parent = null, isStream = false) =>
  Object.values(tables).map((table) => {
    const typeIsStream = isStream || (parent && parent.type === 'Stream')

    // empty columns or sub tables are disabled
    let disabled =
      _.isEmpty(table.tables) &&
      (typeIsStream
        ? parent.columns?.length || 0
        : table.columns?.length || 0) === 0

    // check stream tables
    if (typeIsStream && !table.columns) {
      disabled = parent.columns.length === 0
    }

    return {
      value: table.value,
      label: table.label,
      disabled,
      children: table.tables ? getTables(table.tables, table, typeIsStream) : []
    }
  })
