import React from 'react'

import Styles from './styles.module.css'

import Chip from 'components_new/atoms/Chip'

const Card = ({
  header = '',
  children,
  background,
  active,
  className = '',
  style = {},
  id,
  onClick = () => {},
  badge
}) => {
  return (
    <div>
      {badge ? (
        <div className={Styles['badge']}>
          <Chip label={badge} size="small" />
        </div>
      ) : null}
      <div
        id={id}
        onClick={() => onClick()}
        style={style}
        className={`${Styles['container']} ${active ? Styles['active'] : ''}
      ${background ? Styles.background : ''}
      ${className}`}
      >
        {header ? (
          <div className={Styles.header}>
            <h1>{header}</h1>
          </div>
        ) : null}
        <div className={Styles.children}>{children}</div>
      </div>
    </div>
  )
}

export default Card
