import React from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from 'components_new/atoms/Box'

import PageHeader from 'components/common/PageHeader'
import CreateOptionCard from 'components/common/CreateOptionCard'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

import * as AlertActions from 'redux/actions/Alert'

const Add = ({ history, setAlert, resetAlert }) => {
  return (
    <Box>
      <PageHeader header={'Skapa register'} className={Styles['page-header']} />
      <Box sx={{ display: 'flex' }}>
        {Conf.ADD_REGISTRY_OPTIONS(history, setAlert, resetAlert).map(
          (item) => {
            return (
              <CreateOptionCard
                key={item.key}
                badge={item.badge}
                icon={item.icon}
                text={item.text}
                button={item.button}
              />
            )
          }
        )}
      </Box>
    </Box>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AlertActions, dispatch)
}

export default connect(() => ({}), mapDispatchToProps)(withRouter(Add))
