import { update, ColorSchemePatchBody } from 'redux/api/Admin/Customization'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryUpdateColorScheme(body: ColorSchemePatchBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_COLOR_SCHEME
    })

    update(body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_COLOR_SCHEME_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Anpassningen har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_COLOR_SCHEME_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Anpassningen kunde inte uppdateras' }
        })
      })
  }
}
