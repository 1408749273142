import React from 'react'

import Button from 'components_new/atoms/Button'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface DeleteDialogProps {
  handleDelete: () => void
  open: boolean
  onClose: () => void
  title: string
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const { onClose, open, title, handleDelete } = props

  return (
    <SimpleDialog
      title="Radera nyckeltal?"
      contentText={
        <>
          Ditt nyckeltal{' '}
          <b>
            &quot;
            {title}
            &quot;
          </b>{' '}
          kommer att raderas permanent. Är du säker?
        </>
      }
      onClose={onClose}
      open={open}
      actions={
        <>
          <Button onClick={onClose} variant="text">
            Avbryt
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Radera
          </Button>
        </>
      }
    />
  )
}

export default DeleteDialog
