import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboard-favorites`

export type PutLayout = {
  data: {
    dashboard_group_id: string
    index: number
  }[]
}

export async function putLayout(body: PutLayout) {
  return axios.put(`${URL}/layout`, body, parseAuthenticationHeader())
}

export async function putFavorite(id: string) {
  return axios.put(`${URL}/${id}`, null, parseAuthenticationHeader())
}
