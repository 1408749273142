import { translateOperator } from './kpi_dialogs/KpiFormulaBlock/utils'

import { KpiVariable } from 'redux/reducers/KpiVariables'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

export function blockToString(
  block: KpiTemplateCalculationBlock,
  variables: KpiVariable[],
  templates: KpiTemplate[]
): string {
  let left = ''
  let right = ''

  if (block.left_value) {
    left = block.left_value.toString()
  }
  if (block.right_value) {
    right = block.right_value.toString()
  }

  if (block.left_kpi_variable) {
    const id = block.left_kpi_variable.id
    const variable = variables.find((v) => v.id === id) as KpiVariable

    left = variable.name
  }
  if (block.right_kpi_variable) {
    const id = block.right_kpi_variable.id
    const variable = variables.find((v) => v.id === id) as KpiVariable

    right = variable.name
  }
  if (block.left_kpi_template) {
    const id = block.left_kpi_template.id
    const template = templates.find((v) => v.id === id) as KpiTemplate

    left = template.title
  }
  if (block.right_kpi_template) {
    const id = block.right_kpi_template.id
    const template = templates.find((v) => v.id === id) as KpiTemplate

    right = template.title
  }

  if (block.left_kpi_calculation_block) {
    left = `(${blockToString(
      block.left_kpi_calculation_block,
      variables,
      templates
    )})`
  }
  if (block.right_kpi_calculation_block) {
    right = `(${blockToString(
      block.right_kpi_calculation_block,
      variables,
      templates
    )})`
  }

  return `${left} ${
    block.operation ? translateOperator[block.operation] : ''
  } ${right}`
}

export function getKpiFormulaString(
  formula: KpiTemplateCalculationBlock | null,
  variables: KpiVariable[],
  templates: KpiTemplate[]
): string | null {
  if (formula) {
    return blockToString(formula, variables, templates)
  }

  return null
}
