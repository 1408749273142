import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'
import { DashboardFilterOptions } from 'types/GlobalDashboardFilter'

export interface DashboardFilterReducerType {
  options: DashboardFilterOptions[]
  data: { [relationKey: string]: string[] | undefined }
  fetched: boolean
  fetching: boolean
}

const INITIAL_STATE: DashboardFilterReducerType = {
  options: [],
  data: {},
  fetched: false,
  fetching: false
}

const getOptionsAction = createAction(Types.GET_DASHBOARD_FILTER_OPTIONS)
const getOptionsFailedAction = createAction(
  Types.GET_DASHBOARD_FILTER_OPTIONS_FAILED
)
const getOptionsSuccessAction = createAction<{
  data: DashboardFilterOptions[]
}>(Types.GET_DASHBOARD_FILTER_OPTIONS_SUCCESS)

const getDataAction = createAction<string>(Types.GET_ONE_DASHBOARD_FILTER_DATA)
const getDataFailedAction = createAction<string>(
  Types.GET_ONE_DASHBOARD_FILTER_DATA_FAILED
)
const getDataSuccessAction = createAction<{
  relationKey: string
  options: string[]
}>(Types.GET_ONE_DASHBOARD_FILTER_DATA_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)

const simReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getOptionsAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getOptionsFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(getOptionsSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        options: payload.data,
        fetched: true,
        fetching: false
      }
    })
    .addCase(getDataAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: { ...state.data, [payload]: undefined }
      }
    })
    .addCase(getDataFailedAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: { ...state.data, [payload]: [] }
      }
    })
    .addCase(getDataSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: { ...state.data, [payload.relationKey]: payload.options }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default simReducer
