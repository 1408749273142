import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/economy-accounts`

export async function create(data: {
  name: string
  number: string
  economy_set_id: string
}) {
  return axios.post(URL, { data }, parseAuthenticationHeader())
}

export async function update(id: string, name: string) {
  return axios.patch(
    `${URL}/${id}`,
    { data: { name } },
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
