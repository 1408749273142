import React, { FC, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import SimRenamingTemplate from 'components_new/templates/settings/SimRenamings'

import * as SimRenamingActions from 'redux/actions/SimRenamings'

import { ApplicationState } from 'redux/Stores/types'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  SimRenamingStore: state.SimRenamingStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...SimRenamingActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type SimRenamingPageProps = ConnectedProps<typeof connector>

const SimRenamingPage: FC<SimRenamingPageProps> = (
  props: SimRenamingPageProps
) => {
  const {
    AuthStore,
    SimRenamingStore,
    tryGetSimRenamings,
    tryPutSimRenamings
  } = props

  useEffect(() => {
    if (!SimRenamingStore.fetched) {
      tryGetSimRenamings()
    }
  }, [])

  return (
    <SettingsTemplate>
      {SimRenamingStore.fetched ? (
        <SimRenamingTemplate
          customer={AuthStore.customer}
          terms={SimRenamingStore.data}
          putTerms={(terms) =>
            tryPutSimRenamings(
              terms
                .filter((term) => !!term.modified_name)
                .map((term) => ({
                  name: term.modified_name,
                  attribute_id: term.attribute_id,
                  fact_dimension_linker_id: term.fact_dimension_linker_id
                }))
            )
          }
        />
      ) : (
        <LoadingSettings
          titles={['Hämtar din organisations terminologi', 'Vässar pennan']}
        />
      )}
    </SettingsTemplate>
  )
}

export default connector(SimRenamingPage)
