import { getNullLabel, matchesFormatConditions } from '../conf'
import { FORMAT_COLORS } from '../../Specific/Dashboard/SettingSection/conf'

const BAR_COLORS = [
  '#3b82f6', // Blue 6
  '#93c5fd', // Blue 4
  '#1d4ed8', // Blue 8
  '#6366f1', // Indigo 6
  '#a5b4fc', // Indigo 4
  '#4338ca' // Indigo 8
]

const LINE_COLORS = [
  '#ec4899', // Pink 6
  '#f9a8d4', // Pink 4
  '#be185d', // Pink 8
  '#06b6d4', // Cyan 6
  '#67e8f9', // Cyan 4
  '#0e7490' // Cyan 8
]

const MONTH_INDEX = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'Maj',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Okt',
  11: 'Nov',
  12: 'Dec'
}

const DAY_OF_WEEK_INDEX = {
  0: 'Mån',
  1: 'Tis',
  2: 'Ons',
  3: 'Tors',
  4: 'Fre',
  5: 'Lör',
  6: 'Sön'
}

const MAX_SIZE = 15
const MIN_SIZE = 3
const DEFAULT_SIZE = 8

const transparentize = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))

  return `rgba(${r},${g},${b},${alpha})`
}

export const setBarData = (dataMemo, setLabels, setDatasets, window) => {
  const dataWindow = dataMemo[`${window.start}-${window.end}`] || {}

  setLabels(dataWindow.labels || [])
  setDatasets(dataWindow.datasets || [])
}

export const calculateWindowSize = (breakdownType, metrics) => {
  const groupedLength =
    breakdownType !== 'stacked'
      ? metrics.filter((m) => m.class === 'STANDARD').length
      : 1

  return MAX_SIZE / groupedLength < MIN_SIZE || groupedLength < 1
    ? DEFAULT_SIZE
    : MAX_SIZE / groupedLength
}

export const createDataMemo = (
  data,
  metrics,
  breakdownType,
  segmentByAttribute,
  segmentGroup,
  windowEnd,
  amountToShow,
  widgetMetrics,
  breakdownBy
) => {
  const dataMemo = {}
  const segmentByName = segmentByAttribute?.attributes.name
  const segmentByDataType = segmentByAttribute?.attributes.type

  if (segmentGroup) {
    fillSegmentData(data, segmentGroup, segmentByName, metrics)
  }

  const dataEnd = amountToShow || data.length

  const dataToShow = data.slice(0, dataEnd)

  // Validate metrics
  const validMetrics = metrics.filter((metric) =>
    dataToShow.find(
      (row) => row[metric.attributeId] || row[metric.attributeId] === 0
    )
  )

  dataToShow.forEach((_, i) => {
    const slicedData = dataToShow.slice(i, i + windowEnd)

    dataMemo[`${i}-${i + windowEnd}`] = {}
    dataMemo[`${i}-${i + windowEnd}`].labels = getLabels(
      slicedData,
      segmentByName,
      segmentByDataType,
      segmentGroup
    )

    const helperObject = {}

    validMetrics.forEach((metric) => {
      helperObject[metric.attributeId] = slicedData.map(
        (row) => row[metric.attributeId]
      )
    })

    dataMemo[`${i}-${i + windowEnd}`].datasets = getDatasets(
      validMetrics,
      helperObject,
      breakdownType,
      widgetMetrics,
      segmentByDataType,
      breakdownBy,
      data,
      i,
      segmentByAttribute
    )
  })

  return { dataMemo, dataToShow }
}

export const getLabels = (data, segmentBy, segmentByDataType, segmentGroup) => {
  if (segmentGroup === 'MONTH') {
    return Object.keys(data).map((key) => MONTH_INDEX[data[key][segmentBy]])
  }

  if (segmentGroup === 'DAY_OF_WEEK') {
    return Object.keys(data).map(
      (key) => DAY_OF_WEEK_INDEX[data[key][segmentBy]]
    )
  }

  return Object.keys(data).map(
    (key) => data[key][segmentBy] || getNullLabel(segmentByDataType)
  )
}

export const getDatasets = (
  metrics,
  data,
  breakdownType,
  widgetMetrics,
  segmentByDataType,
  breakdownBy,
  rawData,
  rawDataIndex,
  segmentByAttribute
) => {
  const barColors = !breakdownBy
    ? metrics
      .filter((m) => m.class === 'STANDARD')
      .map((metric, i) => {
        return data[metric.attributeId]
          .map((d, j) =>
            getBackgroundColor(
              widgetMetrics[metric.metricIndex],
              {
                // add segmentBy (uuid) as key to match pivot behavior
                // TODO: Return uuid in response from api instead of label.
                ...(segmentByAttribute
                  ? {
                      [segmentByAttribute.id]:
                          rawData[rawDataIndex + j][
                            segmentByAttribute.attributes.name
                          ]
                    }
                  : {}),
                ...rawData[rawDataIndex + j]
              },
              i,
              segmentByAttribute?.id
            )
          )
          .flat()
      })
      .flat()
    : []

  const bars = metrics
    .filter((m) => m.class === 'STANDARD')
    .map((metric, i) => {
      const label =
        widgetMetrics?.[metric.metricIndex] && !breakdownBy
          ? widgetMetrics[metric.metricIndex].title
          : metric.attributeId

      return {
        backgroundColor: breakdownBy
          ? transparentize(BAR_COLORS[i % BAR_COLORS.length], 0.75)
          : barColors,
        label: label || getNullLabel(segmentByDataType),
        data: data[metric.attributeId] || [],
        yAxisID: 'y',
        order: 2,
        stack: breakdownType === 'stacked' ? 'stack-0' : undefined
      }
    })

  const lines = metrics
    .filter((m) => m.class === 'LINE' || m.class === 'INDEPENDENT_LINE')
    .map((metric, i) => {
      const label = widgetMetrics?.[metric.metricIndex]
        ? widgetMetrics[metric.metricIndex].title
        : metric.attributeId

      return {
        borderColor: LINE_COLORS[i % LINE_COLORS.length],
        backgroundColor: LINE_COLORS[i % LINE_COLORS.length],
        label: label || getNullLabel(segmentByDataType),
        type: 'line',
        data: data[metric.attributeId],
        yAxisID: 'rightY',
        order: 1
      }
    })

  return [...bars, ...lines]
}

export const addStyleDocument = (id) => {
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')

  const css = `#${id} {
        --${id}-width: 10%;
    }
    
    #${id}::-webkit-slider-thumb {
        width: var(--${id}-width) !important;
    }
    #${id}::-moz-range-thumb {
      width: var(--${id}-width) !important;
    }
    `

  head.appendChild(style)

  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.innerHTML = ''
    style.appendChild(document.createTextNode(css))
  }
}

export const removeStyleDocument = (id) => {
  const head = document.head || document.getElementsByTagName('head')[0]

  const headChildren = head?.children || []

  for (let i = 0; i < headChildren.length; i++) {
    const child = headChildren[i]

    if (child.outerText.startsWith(`#${id}`)) {
      child.remove()
    }
  }
}

const getRowMaxStacked = (row, metrics) => {
  const yMax = metrics
    .filter((m) => m.class === 'STANDARD')
    .reduce(
      (acc, metric) =>
        acc + (row[metric.attributeId] ? parseInt(row[metric.attributeId]) : 0),
      0
    )

  let rightYMax = 0

  metrics
    .filter((m) => m.class === 'LINE' || m.class === 'INDEPENDENT_LINE')
    .forEach((metric) => {
      if (row[metric.attributeId] > rightYMax) {
        rightYMax = row[metric.attributeId]
      }
    })

  return { yMax, rightYMax }
}

const getRowMaxGrouped = (row, metrics) => {
  let yMax = 0

  metrics
    .filter((m) => m.class === 'STANDARD')
    .forEach((metric) => {
      const value = row[metric.attributeId]
        ? parseInt(row[metric.attributeId])
        : 0

      if (value > yMax) {
        yMax = value
      }
    })

  let rightYMax = 0

  metrics
    .filter((m) => m.class === 'LINE' || m.class === 'INDEPENDENT_LINE')
    .forEach((metric) => {
      if (row[metric.attributeId] > rightYMax) {
        rightYMax = row[metric.attributeId]
      }
    })

  return { yMax, rightYMax }
}

const fillSegmentData = (data, segmentGroup, segmentBy, metrics) => {
  let keysToCheck = []

  switch (segmentGroup) {
  case 'MONTH':
    keysToCheck = Object.keys(MONTH_INDEX)
    break
  case 'DAY_OF_MONTH':
    keysToCheck = Array.from({ length: 31 }, (_, i) => (i + 1).toString())
    break
  case 'DAY_OF_WEEK':
    keysToCheck = Object.keys(DAY_OF_WEEK_INDEX)
    break
  case 'HOUR':
    keysToCheck = Array.from({ length: 24 }, (_, i) =>
      i < 10 ? `0${i}` : i.toString()
    )
    break
  default:
    break
  }

  keysToCheck.forEach((key) => {
    const keyExists = data.find((row) => row[segmentBy] === key)

    if (!keyExists) {
      const fillRow = { [segmentBy]: key }

      metrics.forEach((metric) => {
        fillRow[metric.attributeId] = 0
      })

      data.push(fillRow)
    }
  })
}

export const getMaxValue = (data = [], metrics = [], breakdownType) => {
  let maxValueY = 0
  let maxValueRightY = 0

  data.forEach((row) => {
    let maxValues = { yMax: 0, rightYMax: 0 }

    if (breakdownType === 'stacked') {
      maxValues = getRowMaxStacked(row, metrics)
    } else {
      maxValues = getRowMaxGrouped(row, metrics)
    }

    if (maxValues.yMax > maxValueY) {
      maxValueY = maxValues.yMax
    }
    if (maxValues.rightYMax > maxValueRightY) {
      maxValueRightY = maxValues.rightYMax
    }
  })

  return { y: maxValueY, rightY: maxValueRightY }
}

const getBackgroundColor = (widgetMetric, data, i, segmentBy) => {
  if (
    widgetMetric.widget_metric_formattings &&
    widgetMetric.widget_metric_formattings.length > 0
  ) {
    const formattedValue = widgetMetric.widget_metric_formattings
      // removed format may still be in list with undefined values
      .filter((item) => item.index || item.index === 0)
      .sort((f1, f2) => (f1.index < f2.index ? -1 : 1))
      .map((format) => {
        const valueMatchFormatConditions = format.filter_conditions
          ? matchesFormatConditions(format, data, segmentBy)
          : true

        if (valueMatchFormatConditions) {
          const colorCode =
            FORMAT_COLORS[format.color]?.code ||
            BAR_COLORS[i % BAR_COLORS.length]

          return transparentize(colorCode, 0.75)
        }

        return null
      })
      .filter(Boolean)

    if (formattedValue.length > 0) {
      return formattedValue[0]
    }
  }

  return transparentize(BAR_COLORS[i % BAR_COLORS.length], 0.75)
}

export const showSameAxisMax = (metrics) => {
  const lines = metrics.filter((m) =>
    ['LINE', 'INDEPENDENT_LINE'].includes(m.class)
  )
  const bars = metrics.find(
    (m) => !['LINE', 'INDEPENDENT_LINE'].includes(m.class)
  )

  const sameUnit = lines.every(
    (line) =>
      line.suffix_plural && bars.title && line.suffix_plural === bars.title
  )

  const allLinesAreIndependent = lines.every(
    (line) => line.class === 'INDEPENDENT_LINE'
  )

  return sameUnit && allLinesAreIndependent
}
