import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'

import TabSwitch from 'components_new/molecules/TabSwitch'

import ActivationTimeDashboardTemplate from 'components_new/templates/internal/ActivationTimeDashboard'
import DegreeOfActivationDashboardTemplate from 'components_new/templates/internal/DegreeOfActivationDashboard'
import WeeklyReportingDashboardTemplate from 'components_new/templates/internal/WeeklyReportingDashboard'
import InternalTemplate from 'components_new/templates/InternalTemplate'

enum Tabs {
  Weekly = 'Veckovis uppföljning',
  DegreeOfActivation = 'Aktiveringsgrad',
  ActivationTime = 'Aktiveringstid'
}

const UsersTrackingPage = () => {
  const [tab, setTab] = useState(Tabs.Weekly)

  return (
    <InternalTemplate>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}>
          <TabSwitch
            color="primary"
            maxHeight="40px"
            options={[
              {
                value: Tabs.Weekly,
                label: 'Veckovis uppföljning'
              },
              {
                value: Tabs.DegreeOfActivation,
                label: 'Aktiveringsgrad'
              },
              {
                value: Tabs.ActivationTime,
                label: 'Aktiveringstid'
              }
            ]}
            value={tab}
            onChange={(value) => setTab(value)}
            noGutter={true}
          />
        </Box>
        {tab === Tabs.Weekly ? <WeeklyReportingDashboardTemplate /> : null}
        {tab === Tabs.DegreeOfActivation ? (
          <DegreeOfActivationDashboardTemplate />
        ) : null}
        {tab === Tabs.ActivationTime ? (
          <ActivationTimeDashboardTemplate />
        ) : null}
      </Box>
    </InternalTemplate>
  )
}

export default UsersTrackingPage
