import React, { ReactNode, useState, useMemo } from 'react'
import { useTheme } from '@mui/material'

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'

import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToHorizontalAxis
} from '@dnd-kit/modifiers'

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable'

import Box from 'components_new/atoms/Box'

import DndListItem from './DndListItem'
import DndSortableListItem from './DndSortableListItem'

interface DndListProps {
  items: {
    disabled?: boolean
    id: string
    content: ReactNode
    containerSx?: object
  }[]
  handleDragEnd: (newOrder: string[]) => void
  horizontal?: boolean
  sx?: object
}

const DndList = (props: DndListProps) => {
  const { sx, items, handleDragEnd, horizontal = false } = props

  const [activeId, setActiveId] = useState<string | null>(null)
  const itemIds = useMemo(() => items.map((item) => item.id), [items])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragStart(event: any) {
    const { active } = event

    setActiveId(active.id)
  }

  const theme = useTheme()
  const activeItem = items.find((item) => item.id === activeId)

  return (
    <Box sx={sx}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragCancel={() => setActiveId(null)}
        onDragEnd={(event: any) => {
          const { active, over } = event

          if (active.id !== over.id) {
            const oldIndex = itemIds.indexOf(active.id)
            const newIndex = itemIds.indexOf(over.id)
            const newItemIds = arrayMove(itemIds, oldIndex, newIndex)

            handleDragEnd(newItemIds)

            return newItemIds
          }

          return itemIds
        }}
        onDragStart={handleDragStart}
        modifiers={[
          horizontal ? restrictToHorizontalAxis : restrictToVerticalAxis
        ]}
      >
        <SortableContext
          items={itemIds}
          strategy={
            horizontal
              ? horizontalListSortingStrategy
              : verticalListSortingStrategy
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: horizontal ? 'row' : 'column'
            }}
          >
            {itemIds.map((id) => {
              const item = items.find((item) => item.id === id)

              return (
                <DndSortableListItem
                  disabled={item?.disabled}
                  id={id}
                  key={id}
                  sx={item?.containerSx}
                  variant="outlined"
                >
                  {item?.content}
                </DndSortableListItem>
              )
            })}
          </Box>
        </SortableContext>
        <DragOverlay modifiers={[restrictToParentElement]}>
          {activeId && activeItem ? (
            <DndListItem
              id={activeId}
              sx={{
                boxShadow: theme.shadows[20],
                ...activeItem.containerSx
              }}
              variant="outlined"
            >
              {activeItem.content}
            </DndListItem>
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  )
}

export default DndList
