import axios, { ResponseType } from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import { QueryParams, parseQueryParam } from '../Widgets'
import { FileExtension } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/kpi-options`

export async function create(body: KpiOptionPostAttributes) {
  return axios.post<{ data: KpiOptionObject }>(
    URL,
    body,
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: KpiOptionPatchAttributes) {
  return axios.patch<{ data: KpiOptionObject[] }>(
    `${URL}/${id}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

export async function getAllFilterOptions() {
  return axios.get(`${URL}/filter-options`, parseAuthenticationHeader())
}

export async function getDetails(
  id: string,
  offset: number,
  queryParam: QueryParams | null
) {
  let parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  if (parsedQueryParams) {
    parsedQueryParams += `&offset=${offset}`
  } else {
    parsedQueryParams = `?offset=${offset}`
  }

  return axios.get(
    `${URL}/details/${id}${parsedQueryParams}`,
    parseAuthenticationHeader()
  )
}

export async function exportDetails(
  id: string,
  ext: FileExtension,
  queryParam: QueryParams | null
) {
  const options = {
    ...parseAuthenticationHeader(),
    responseType: 'blob' as ResponseType
  }
  const parsedQueryParams = queryParam ? parseQueryParam(queryParam) : ''

  return axios.get(`${URL}/export/${id}/${ext}${parsedQueryParams}`, options)
}
