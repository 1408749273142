import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v3/api-keys`

export async function create(body) {
  return Axios.post(URL, body, await Functions.parseAuthenticationHeader())
}

export async function update(id, body) {
  return Axios.patch(
    `${URL}/${id}`,
    body,
    await Functions.parseAuthenticationHeader()
  )
}

export async function destroy(id) {
  return Axios.delete(
    `${URL}/${id}`,
    await Functions.parseAuthenticationHeader()
  )
}
