import React from 'react'

import DataTypeIcon from 'components/common/DataTypeIcon'

import Text from 'components_new/atoms/Text'

export const REGEX = {
  NON_VALID_CHARACTERS: /^å|^ä|^ö|^\s|[^A-Za-z_]/,
  SLUG: /^[a-z0-9]+(?:-[a-z0-9]+)*$/
}

export const MATH_REGEX =
  // eslint-disable-next-line max-len
  /(@).*?(?=\+[0-9]|\+$|\+@|\+\(|-[0-9]|-$|-@|-\(|\*[0-9]|\*$|\*@|\*\(|\/[0-9]|\/$|\/@|\/\(|\(|\)|$)/g
export const MATH_OPERATIONS = ['+', '-', '*', '/']

export const BUILD_FORMATS = {
  NUMBER: {
    label: 'NUMMER',
    value: 'NUMBER',
    regex: /^-?[0-9]\d*\.?\d*$/,
    input: null,
    dataType: 'N'
  },
  TEXT: {
    label: 'TEXT',
    value: 'TEXT',
    regex: null,
    input: null,
    dataType: 'S'
  },
  DATE: {
    label: 'DATUM',
    value: 'DATE',
    regex: null,
    input: 'date',
    dataType: 'SD'
  },
  BOOLEAN: {
    label: 'SANT/FALSKT',
    value: 'BOOLEAN',
    regex: null,
    input: 'bool',
    dataType: 'BOOL'
  }
}

export const FORM_VALIDATIONS = {
  REQUIRED: { required: true, message: 'Obligatoriskt fält' },
  DUPLICATE: (data, key, message, id = null) => ({
    validator(_, value) {
      if (!value) {
        return Promise.resolve()
      }

      const exists = Object.values(data).find(
        (item) =>
          item.attributes[key].toLowerCase() === value.toLowerCase() &&
          (id ? item.id !== id : true)
      )

      if (exists) {
        return Promise.reject(message)
      }

      return Promise.resolve()
    }
  }),
  INVALID_CHARACTERS: {
    validator(_, value) {
      if (!value || !REGEX.NON_VALID_CHARACTERS.test(value)) {
        return Promise.resolve()
      }

      return Promise.reject(
        new Error('Attributet innehåller ej giltiga tecken.')
      )
    }
  },
  DUPLICATE_SIMPLE: (data, message = 'Det här namnet finns redan') => {
    return {
      validator: (_, value) => {
        const exists = data.find(
          (item) => item.toLowerCase() === value?.toLowerCase()
        )

        if (exists) {
          return Promise.reject()
        }

        return Promise.resolve()
      },
      message
    }
  },
  MATHEMATICAL_EXPRESSION: (validOptions) => ({
    validator: (_, unTrimmedValue) => {
      const value = unTrimmedValue.trim()

      if (MATH_OPERATIONS.includes(value[value.length - 1])) {
        return Promise.reject()
      }

      const testExpression = value
        .replace(MATH_REGEX, (match) =>
          validOptions.find(
            (opt) => opt.value === match.replace('@', '').trim()
          )
            ? Math.floor(Math.random() * 10000) + 1 // random int from 1-10001, +1 to avoid 0s
            : 'ERROR'
        )
        .replace(/@/g, '')

      try {
        const response = eval(testExpression)

        if (response === Infinity) {
          return Promise.reject()
        } else {
          return Promise.resolve()
        }
      } catch (e) {
        return Promise.reject()
      }
    },
    message: 'Uttryck ej giltigt.'
  })
}

export const DESCRIPTOR = {
  API: 'delning',
  INSIGHT_STUDIO: 'dashboard'
}

export const DATA_TYPES = {
  BIGINT: 'Heltal',
  BOOLEAN: 'Sant/Falskt',
  DATE: 'Datum',
  DOUBLE: 'Decimaltal',
  STRING: 'Text',
  TIMESTAMP: 'Tidsstämpel'
}

export const DATA_TYPES_OPTIONS = Object.keys(DATA_TYPES).map((key) => ({
  value: key,
  label: (
    <div className={'data-type-icon-container'}>
      <DataTypeIcon type={key} />
      <Text variant="body1">{DATA_TYPES[key]}</Text>
    </div>
  )
}))

export const MONTH_TRANSLATOR = {
  0: 'januari',
  1: 'februari',
  2: 'mars',
  3: 'april',
  4: 'maj',
  5: 'juni',
  6: 'juli',
  7: 'augusti',
  8: 'september',
  9: 'oktober',
  10: 'november',
  11: 'december'
}
