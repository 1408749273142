import React from 'react'

import Chip from 'components_new/atoms/Chip'

import * as Functions from 'helpers/Functions'

export const TABLE_HEADERS = [
  {
    label: 'TIDPUNKT',
    key: 'timestamp',
    render: Functions.formatDateString
  },
  {
    label: 'MEDDELANDE',
    key: 'message',
    render: function renderColumn(message) {
      return <Chip color="error" text={message} />
    }
  },
  {
    label: 'ATTRIBUT',
    key: 'attribute'
  },
  {
    label: 'VÄRDE',
    key: 'value'
  },
  {
    label: 'ID',
    key: 'pk_value'
  }
]
