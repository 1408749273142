import React from 'react'

import ListItem from 'components_new/atoms/List/ListItem'
import Skeleton from 'components_new/atoms/Skeleton'

import NavigationItem, {
  TNavigationItem
} from 'components_new/molecules/NavigationItem'

interface NavigationMenuProps {
  expandedFolders?: string[]
  items: TNavigationItem[]
  loading?: boolean
  open: boolean
  toggleFolder?: (folder: string) => void
}

const NavigationMenu = (props: NavigationMenuProps) => {
  const { expandedFolders, items, loading, open, toggleFolder } = props

  return (
    <>
      {loading && !open ? (
        <>
          <ListItem>
            <Skeleton variant="text" width={180} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width={180} />
          </ListItem>
          <ListItem>
            <Skeleton variant="text" width={180} />
          </ListItem>
        </>
      ) : (
        <>
          {items.map((item) => (
            <NavigationItem
              key={item.href}
              collapsed={!open}
              expandedFolders={expandedFolders}
              item={item}
              toggleFolder={toggleFolder}
            />
          ))}
        </>
      )}
    </>
  )
}

export default NavigationMenu
