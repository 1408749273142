import ShortId from 'shortid'

import SQLServer from 'assets/collect/microsoft.svg'
import PostgreSQL from 'assets/collect/postgresql.svg'
import MySQL from 'assets/collect/mysql.svg'
import Aurora from 'assets/collect/aurora.svg'
import Oracle from 'assets/collect/oracle.svg'

export const SOURCES_TO_SKIP = {
  'SCB DeSO': true,
  'SCB RegSO': true,
  Brottsplatskartan: true,
  SMHI: true,
  Skatteverket: true,
  SGU: true,
  Valmyndigheten: true,
  'Boverket klimatdatabas': true
}

export const ON_PREMISE_DIRECT_CARDS = [
  {
    imgSrc: MySQL,
    title: 'MySQL',
    value: 'mysql',
    database: 'mysql',
    key: ShortId.generate()
  },
  {
    imgSrc: Aurora,
    title: 'Aurora',
    value: 'aurora',
    database: 'mysql',
    key: ShortId.generate()
  },
  {
    imgSrc: PostgreSQL,
    title: 'PostgreSQL',
    value: 'postgresql',
    database: 'postgresql',
    key: ShortId.generate()
  },
  {
    imgSrc: Oracle,
    title: 'Oracle',
    value: 'oracle',
    database: 'postgresql',
    key: ShortId.generate()
  },
  {
    imgSrc: SQLServer,
    title: 'SQL Server',
    value: 'sqlserver',
    database: 'sqlserver',
    key: ShortId.generate()
  }
]

export const formatValidateBody = (sourceId, attributes, authParams) => {
  const data = Object.entries(attributes).map(([key, val]) => {
    const ap = authParams.find((a) => a.attributes.field === key)

    return {
      attributes: {
        auth_param_id: ap ? ap.id : null,
        value: val
      }
    }
  })

  return {
    data: {
      source_id: sourceId,
      auth_param_values: data
    }
  }
}

export const formatCreateBody = (
  sourceId,
  authParamValues,
  authParams,
  displayName
) => {
  return {
    data: {
      attributes: {
        ...formatValidateBody(sourceId, authParamValues, authParams).data,
        display_name: displayName
      }
    }
  }
}
