import { Condition, FilterType, KpiOptionObject } from 'types/GlobalKpiOption'
import { ExploreDialogType } from '.'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  CustomPeriodFilter,
  DataType,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { getWidgetQueryParams } from 'components_new/pages/Dashboard/utils'
import { getFilterFromSegmentPaths } from 'utils/functions'

export interface ISegmentData {
  label: string
  id: string
  type: DataType
  technical_name: string
}

export const parseExploreQueryParams = (
  exploreDialog: ExploreDialogType,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  widget: WidgetObject,
  showAs: string | null
) => {
  let queryParams = getWidgetQueryParams(
    widget,
    dashboardFilter,
    periodFilter,
    showAs
  )

  const filters = Object.values(
    getFilterFromSegmentPaths(
      widget,
      exploreDialog.segments,
      exploreDialog.kpiOptionId as string
    )
  )

  if (
    exploreDialog.breakdownFilterValue !== undefined &&
    exploreDialog.breakdownRelationKey !== undefined
  ) {
    filters.push({
      attributeId: exploreDialog.breakdownRelationKey as string,
      condition: Condition.EQ,
      value: exploreDialog.breakdownFilterValue?.toString() as string,
      type: FilterType.INPUT_VALUE
    })
  }

  const filterExists = filters.length > 0

  if (!queryParams && filterExists) {
    queryParams = {
      filter: filters
    }
  } else if (queryParams && !queryParams?.filter && filterExists) {
    queryParams.filter = filters
  } else if (queryParams?.filter && filterExists) {
    queryParams.filter.push(...filters)
  }

  if (exploreDialog.customPeriod) {
    if (!queryParams) {
      queryParams = {}
    }

    queryParams.from_date = exploreDialog.customPeriod.fromDate
    queryParams.to_date = exploreDialog.customPeriod.toDate
  }

  return queryParams
}

/**
 * Get current date span for explore dialog
 * Prioritizes:
 *  1. ExploreDialog CustomPeriod (on click previous period)
 *  3. Widget
 */
export function getDateSpan(
  exploreDialog: ExploreDialogType,
  widget: WidgetObject
) {
  if (exploreDialog.customPeriod) {
    return {
      from: exploreDialog.customPeriod.fromDate,
      to: exploreDialog.customPeriod.toDate
    }
  }

  return {
    from: widget.settings.date_filter.selected.from_date,
    to: widget.settings.date_filter.selected.to_date
  }
}

export function getHierarchyForSegment(
  kpiOption: KpiOptionObject,
  attributeOptionId: string | null
) {
  if (!attributeOptionId) {
    return kpiOption.hierarchy
  }

  const indexOfCurrentSegment = kpiOption.hierarchy.findIndex(
    (item) => item.attribute_option_id === attributeOptionId
  )

  return kpiOption.hierarchy.slice(indexOfCurrentSegment + 1)
}
