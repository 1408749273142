import React from 'react'

import Icon from 'components_new/atoms/Icon'

import Input from 'components/common/Input'

// Local files.
import Styles from './styles.module.css'

const SearchInput = ({
  onInputChange,
  inputValue,
  size = 'large',
  onSearch = () => {},
  placeholder
}) => {
  return (
    <Input
      placeholder={placeholder}
      containerClassName={Styles['input-wrapper-container']}
      className={Styles['input-height']}
      value={inputValue}
      onChange={(value) => onInputChange(value.target.value)}
      size={size}
      allowClear={true}
      addonAfter={
        <div
          className={`${Styles['search-button-container']} ${
            size === 'middle' ? Styles['medium-sized-button'] : ''
          }`}
          onClick={(e) => onSearch(e)}
        >
          <Icon name="SearchOutlined" />
        </div>
      }
      onPressEnter={(e) => onSearch(e)}
    />
  )
}

export default SearchInput
