import React, { useState } from 'react'

import { Dropdown } from 'antd'

import Icon from 'components_new/atoms/Icon'

import * as Conf from './conf.js'
import Styles from './styles.module.css'

const IconDropdown = ({
  items,
  icon,
  header,
  children,
  iconClassName = 'primary',
  className
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <Dropdown
      menu={Conf.menu({ items, header, setVisible })}
      trigger={['click']}
      overlayClassName={Styles['items-container']}
      placement={'bottomRight'}
      open={visible}
      onOpenChange={(flag) => setVisible(flag)}
    >
      <div className={`${Styles['icon-container']} ${className}`}>
        {children}
        <Icon name={icon} className={iconClassName} />
      </div>
    </Dropdown>
  )
}

export default IconDropdown
