import React, { useEffect } from 'react'

import ActionPreviewDivider from 'components/common/ActionPreviewDivider'
import ProgressBar from 'components/common/ProgressBar/v2'

import HeaderBar from './HeaderBar'
import SideBar from './SideBar'

import Styles from './styles.module.css'

const CreateModal = ({
  open,
  steps = [],
  headerTitle,
  children,
  onSave,
  onClose,
  loading,
  currentStep,
  setCurrentStep
}) => {
  useEffect(() => {
    setCurrentStep(0)
  }, [open])

  return (
    <div
      className={`${Styles.container} ${!open && Styles['hidden-container']}`}
    >
      <HeaderBar
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        headerTitle={headerTitle}
        onSave={onSave}
        onClose={onClose}
        loading={loading}
      />
      <div className={Styles.progress}>
        <ProgressBar
          percent={Math.floor((100 * (currentStep + 1)) / steps.length)}
          strokeWidth={4}
        />
      </div>

      <ActionPreviewDivider
        leftContent={
          <SideBar
            title={steps[currentStep]?.title}
            description={steps[currentStep]?.description}
            content={steps[currentStep]?.content}
            actions={steps[currentStep]?.actions}
          />
        }
        rightContent={children}
      ></ActionPreviewDivider>
    </div>
  )
}

export default CreateModal
