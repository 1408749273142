import { Pagination as MUIPagination, PaginationProps } from '@mui/material'
import React from 'react'

/**
 * The Paper component component serves as a wrapper
 * component.
 *
 * In contrast to Box it resembles the flat, opaque
 * texture of a sheet of paper, and an application's
 * behavior mimics paper's ability to be re-sized,
 * shuffled, and bound together in multiple sheets.
 */

const Paper = React.forwardRef((props: PaginationProps, ref: any) => {
  return <MUIPagination {...props} ref={ref} />
})

Paper.displayName = 'Paper'
export default Paper
