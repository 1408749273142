import React, { ReactNode } from 'react'

import { DialogTitle as MUIDialogTitle } from '@mui/material'

interface DialogTitleProps {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  children: ReactNode
  sx?: object
}

/**
 * [INSERT DESCRIPTION]
 */

const DialogTitle = React.forwardRef((props: DialogTitleProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIDialogTitle {...rest} ref={ref}>
      {children}
    </MUIDialogTitle>
  )
})

DialogTitle.displayName = 'DialogTitle'
export default DialogTitle
