import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  paths: {},
  relations: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload, id } = action

  switch (type) {
  case Types.GET_APPS_ONTOLOGY_SUCCESS:
    return {
      ...state,
      data: payload,
      fetched: true
    }

  case Types.GET_PATHS_SUCCESS:
    return {
      ...state,
      paths: { [payload.tableId]: payload.data, ...state.paths }
    }

  case Types.GET_RELATED_TABLES_SUCCESS:
    return {
      ...state,
      relations: {
        ...state.relations,
        [id]: payload
      }
    }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
