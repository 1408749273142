/* Fake feature for sales and review */

import { createAction, createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'

import { arrayToObject } from 'helpers/Functions'

import { WidgetNotification } from 'types/GlobalWidgetNotification'

export type WidgetNotificationObject = {
  [notificationId: string]: WidgetNotification
}

export interface WidgetNotifcationStore {
  [widgetId: string]: {
    fetched: boolean
    notifications: WidgetNotificationObject
  }
}

// Initial state
const INITIAL_STATE: WidgetNotifcationStore = {}

// Actions
const createWidgetNotification = createAction<{
  data: WidgetNotification
  widgetId: string
}>(Types.CREATE_WIDGET_NOTIFICATION_SUCCESS)

const getAllWidgetNotifications = createAction<{
  data: WidgetNotification[]
  widgetId: string
}>(Types.GET_WIDGET_NOTIFICATIONS_SUCCESS)

const updateWidgetNotification = createAction<{
  data: WidgetNotification
  widgetId: string
}>(Types.UPDATE_WIDGET_NOTIFICATION_SUCCESS)

const deleteWidgetNotification = createAction<{ id: string; widgetId: string }>(
  Types.DELETE_WIDGET_NOTIFICATION_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const widgetNotificationReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllWidgetNotifications, (state, action) => {
      const { payload } = action

      return {
        ...state,
        [payload.widgetId]: {
          fetched: true,
          notifications: arrayToObject(payload.data) as WidgetNotificationObject
        }
      }
    })
    .addCase(createWidgetNotification, (state, action) => {
      const { payload } = action

      return {
        ...state,
        [payload.widgetId]: {
          ...state[payload.widgetId],
          notifications: {
            ...state[payload.widgetId].notifications,
            [payload.data.id]: payload.data
          }
        }
      }
    })
    .addCase(updateWidgetNotification, (state, action) => {
      const { payload } = action

      return {
        ...state,
        [payload.widgetId]: {
          ...state[payload.widgetId],
          notifications: {
            ...state[payload.widgetId].notifications,
            [payload.data.id]: payload.data
          }
        }
      }
    })
    .addCase(deleteWidgetNotification, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state)

      delete newData[payload.widgetId].notifications[payload.id]

      return newData
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default widgetNotificationReducer
