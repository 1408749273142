import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import {
  PatchDashboardGroup,
  copy,
  update,
  destroy,
  updateOrder
} from 'redux/api/DashboardGroups'
import { DashboardType } from 'redux/reducers/Dashboards'

export function tryUpdateDashboardOrder(id: string, body: { data: string[] }) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_ORDER
    })

    updateOrder(id, body)
      .then(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_ORDER_SUCCESS,
          payload: {
            id,
            order: body.data
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_ORDER_FAILED
        })
      })
  }
}

export function tryCopyDashboardGroup(
  id: string,
  navigate: (id: string) => void,
  navigateOnSuccess = false
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.COPY_DASHBOARD_GROUP
    })

    copy(id, DashboardType.DEFAULT)
      .then((response) => {
        dispatch({
          type: Types.COPY_DASHBOARD_GROUP_SUCCESS,
          payload: response.data
        })

        const snackbarPayload = {
          message: 'Dashboard duplicerad',
          duration: 10000,
          link: () => {},
          linkText: ''
        }

        const group =
          response.data.data.groups[
            response.data.data.dashboards[0].dashboard_group_id
          ]

        if (navigateOnSuccess) {
          navigate(group.dashboards[0])
        } else {
          snackbarPayload.link = () => navigate(group.dashboards[0])
          snackbarPayload.linkText = 'Gå till dashboard >'
        }

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: snackbarPayload
        })
      })
      .catch(() => {
        dispatch({
          type: Types.COPY_DASHBOARD_GROUP_FAILED
        })
      })
  }
}

export function tryUpdateDashboardGroup(id: string, body: PatchDashboardGroup) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD_GROUP
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_GROUP_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard uppdaterad',
            duration: 5000
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_GROUP_FAILED
        })
      })
  }
}

export function tryDeleteDashboardGroup(id: string, callback: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_DASHBOARD_GROUP
    })

    destroy(id)
      .then(() => {
        callback()

        dispatch({
          type: Types.DELETE_DASHBOARD_GROUP_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard borttagen',
            duration: 5000
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_GROUP_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard kunde inte tas bort',
            duration: 5000
          }
        })
      })
  }
}
