import Box from 'components_new/atoms/Box'
import React from 'react'
import { CustomColors } from 'types/GlobalCustomization'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import {
  InnerWidgetPatchBody,
  ParsedSegmentPath,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import { ExploreDialogType } from '../dialogs/ExploreDialog'
import WidgetMenu from '../WidgetMenu'
import EditableText from './EditableText'

interface WidgetHeaderProps {
  colors?: CustomColors | null
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  deleteWidget?: (arg0: string) => void
  editable: boolean
  exportWidgetData: () => void
  kpiTemplates: KpiTemplateRedux
  loading: boolean
  embedded: boolean
  enableFullscreen?: boolean
  fullSize: boolean
  scaleFactor: number
  setDetailsDialog: (open: boolean, segments: ParsedSegmentPath[]) => void
  setEdit: (value: boolean) => void
  setExploreDialog: React.Dispatch<React.SetStateAction<ExploreDialogType>>
  setFullSize: (arg0: boolean) => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const WidgetHeader = (props: WidgetHeaderProps) => {
  const {
    colors,
    dashboardFilterOptions,
    deleteWidget,
    editable,
    embedded,
    enableFullscreen,
    exportWidgetData,
    fullSize,
    loading,
    kpiTemplates,
    scaleFactor,
    setDetailsDialog,
    setExploreDialog,
    setFullSize,
    setEdit,
    updateWidget,
    widget
  } = props

  const showTitle =
    widget.settings.show_title &&
    widget.settings.type.selected !== WidgetType.COMMENT &&
    widget.settings.type.selected !== WidgetType.IMAGE

  const showSubtitle =
    widget.settings.show_subtitle &&
    widget.settings.type.selected !== WidgetType.COMMENT &&
    widget.settings.type.selected !== WidgetType.IMAGE

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'auto',
        pb: showTitle ? 1 * scaleFactor : undefined
      }}
    >
      {showTitle || showSubtitle ? (
        <Box sx={{ flex: 1, minWidth: 0, width: '100%' }}>
          {showTitle ? (
            <EditableText
              allowTextOverflow={
                widget.settings.type.selected === WidgetType.TEXT
              }
              disabled={!editable}
              onSubmit={(value) => {
                updateWidget(widget.id, { title: value })
              }}
              scaleFactor={scaleFactor}
              value={widget.title}
              variant="title"
            />
          ) : null}
          {showSubtitle ? (
            <Box sx={{ mt: 0.5 * scaleFactor }}>
              <EditableText
                allowTextOverflow={
                  widget.settings.type.selected === WidgetType.TEXT
                }
                disabled={!editable}
                onSubmit={(value) => {
                  updateWidget(widget.id, { subtitle: value })
                }}
                scaleFactor={scaleFactor}
                value={widget.settings.subtitle}
                variant="subtitle"
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box
        className="widget-menu"
        sx={{
          position: 'absolute',
          height: 'auto',
          width: 'auto',
          top: 0,
          right: 0,
          pl: 0.5 * scaleFactor,
          pb: 0.5 * scaleFactor,
          zIndex: 3
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.widget',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            right: 0,
            opacity: 0.9,
            borderBottomLeftRadius: 8 * scaleFactor
          }}
        />
        <WidgetMenu
          colors={colors}
          dashboardFilterOptions={dashboardFilterOptions}
          deleteWidget={deleteWidget}
          editable={editable}
          embedded={embedded}
          enableFullscreen={enableFullscreen}
          exportWidgetData={exportWidgetData}
          fullSize={fullSize}
          loading={loading}
          kpiTemplates={kpiTemplates}
          setDetailsDialog={(open, segments) =>
            setDetailsDialog(open, segments)
          }
          setExploreDialog={setExploreDialog}
          setFullSize={setFullSize}
          setEdit={() => setEdit(true)}
          updateWidget={updateWidget}
          widget={widget}
        />
      </Box>
    </Box>
  )
}

export default WidgetHeader
