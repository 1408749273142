import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const my_homepal_api =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : // eslint-disable-next-line no-undef
    process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/data-products/output-attributes`

export async function create(body) {
  return Axios.post(URL, body, await Functions.parseAuthenticationHeader())
}

export async function update(id, body) {
  return Axios.patch(
    `${URL}/${id}`,
    body,
    await Functions.parseAuthenticationHeader()
  )
}

export async function remove(id) {
  return Axios.delete(
    `${URL}/${id}`,
    await Functions.parseAuthenticationHeader()
  )
}
