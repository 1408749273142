import React from 'react'

import TextField from 'components/common/TextField'

const BaseUrlField = ({ customerSlug, slug, bold = false, className }) => {
  return (
    <TextField
      text={`${process.env.REACT_APP_FLOW_API}${customerSlug}/${slug}`}
      className={className}
      bold={bold}
      label={'Bas-URL'}
    />
  )
}

export default BaseUrlField
