import React from 'react'
import { withRouter } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

// Local files.
import * as Conf from './conf'
import List from 'components_new/atoms/List'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemText from 'components_new/atoms/List/ListItemText'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import Icon from 'components_new/atoms/Icon'

const PageMenu = ({
  page,
  items,
  // From router.
  location,
  history,
  hideMenu = false
}) => {
  const { title } = Conf.DETAILS[page]

  const selected = items.find((item) => location.pathname.includes(item.path))
  let subSelected = null

  if (selected && selected.items) {
    subSelected = selected.items.find((item) =>
      location.pathname.includes(item.subPath)
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      <Box
        sx={{
          maxWidth: 272,
          pl: 1,
          pr: 2,
          display: hideMenu ? 'none' : undefined
        }}
      >
        <Text color="text.primary" variant="h5" gutterBottom>
          {title}
        </Text>
        <List component="nav">
          {items.map((item, i) =>
            item.text === 'divider' ? (
              <br key={`${item.text}-${i}-page-menu`} />
            ) : (
              <MenuItem
                key={`${item.text}-${i}-page-menu`}
                item={item}
                disabled={item.disabled}
                items={item.items || []}
                subSelected={subSelected}
                history={history}
                location={location}
              />
            )
          )}
        </List>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minWidth: '75%'
        }}
      >
        {subSelected && subSelected.children ? (
          subSelected.children
        ) : selected ? (
          selected.children
        ) : (
          // Change this later.
          <Box>Sidan kunde inte hittas.</Box>
        )}
      </Box>
    </Box>
  )
}

const MenuItem = ({ item, items, history, location }) => {
  const selected = location.pathname.includes(item.path)
  const subSelected = items.find((subItem) =>
    location.pathname.includes(subItem.subPath)
  )

  return (
    <Box>
      <ListItemButton
        selected={selected && !subSelected}
        onClick={item.disabled ? undefined : () => history.push(item.path)}
        sx={{ color: selected ? 'primary.main' : undefined }}
      >
        {item.iconName ? (
          <ListItemIcon>
            <Icon
              name={item.iconName}
              color={selected ? 'primary' : undefined}
            />
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={item.text}
          sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
        />
      </ListItemButton>
      {selected
        ? items.map((subItem, i) => (
          <SubMenuItem
            item={subItem}
            key={i}
            onClick={() => history.push(`${item.path}${subItem.subPath}`)}
            selected={location.pathname.includes(subItem.subPath)}
          />
        ))
        : null}
    </Box>
  )
}

const SubMenuItem = ({ item, selected, onClick, disabled }) => {
  return (
    <ListItemButton
      selected={selected}
      onClick={disabled ? undefined : () => onClick()}
      sx={{ color: selected ? 'primary.main' : undefined, pl: 4 }}
    >
      {item.iconName ? (
        <ListItemIcon>
          <Icon name={item.iconName} color={selected ? 'primary' : undefined} />
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={item.text}
        sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
      />
    </ListItemButton>
  )
}

export default withRouter(PageMenu)
