import React, { useState, useMemo } from 'react'

import Icon from 'components_new/atoms/Icon'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Table from 'components_new/atoms/Table'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableBody from 'components_new/atoms/Table/TableBody'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import AddVariableDialog from '../variable_dialogs/AddVariableDialog'
import EditVariableDialog from '../variable_dialogs/EditVariableDialog'

import {
  KpiTemplate,
  KpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

import Row from './Row'
import { KpiVariable } from 'redux/reducers/KpiVariables'

interface VariablesSectionProps {
  allVariables: KpiVariable[]
  kpis: KpiTemplate[]
  level: number
  onCreateVariable: (name: string, level: number, parentId?: string) => void
  onDeleteVariable: (id: string, callback: (err?: boolean) => void) => void
  onUpdateVariable: (
    id: string,
    name: string,
    callback: (err?: boolean) => void
  ) => void
  parentVariable: KpiVariable | null
  variables: KpiVariable[]
}

const variableInUseInBlock = (
  variableId: string,
  block: KpiTemplateCalculationBlock
): boolean => {
  if (block.left_kpi_variable?.id === variableId) {
    return true
  }

  if (block.right_kpi_variable?.id === variableId) {
    return true
  }

  if (
    block.left_kpi_calculation_block &&
    variableInUseInBlock(variableId, block.left_kpi_calculation_block)
  ) {
    return true
  }

  if (block.right_kpi_calculation_block) {
    return variableInUseInBlock(variableId, block.right_kpi_calculation_block)
  }

  return false
}

const variableInUse = (variableId: string, kpis: KpiTemplate[]) => {
  const foundInUse = kpis.find((kpi) => {
    const block = kpi.custom_calculation_block || kpi.default_calculation_block

    return block ? variableInUseInBlock(variableId, block) : false
  })

  return !!foundInUse
}

const N_LEVELS = 3

const VariablesSection = (props: VariablesSectionProps) => {
  const {
    allVariables,
    kpis,
    level,
    onCreateVariable,
    onDeleteVariable,
    onUpdateVariable,
    parentVariable,
    variables
  } = props

  const [addVariable, setAddVariable] = useState<boolean>(false)
  const [editVariable, setEditVariable] = useState<string | null>(null)
  const [deleteVariable, setDeleteVariable] = useState<string | null>(null)

  const variablesInUse: { [id: string]: boolean } = useMemo(() => {
    const next: { [id: string]: boolean } = {}

    variables.forEach((variable) => {
      next[variable.id] = variableInUse(variable.id, kpis)
    })

    return next
  }, [kpis])

  return (
    <>
      <TableContainer>
        <Table size="small">
          {level === 0 ? (
            <TableHead sx={{ color: 'text.secondary' }}>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>{''}</TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {variables
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((variable) => {
                return (
                  <Row
                    inUse={variablesInUse[variable.id]}
                    kpis={kpis}
                    level={variable.level}
                    onCreateVariable={onCreateVariable}
                    onEdit={() => setEditVariable(variable.id)}
                    onDelete={() => setDeleteVariable(variable.id)}
                    onDeleteVariable={onDeleteVariable}
                    onUpdateVariable={onUpdateVariable}
                    variable={variable}
                    allVariables={allVariables}
                    key={variable.id}
                  />
                )
              })}
            {level < N_LEVELS ? (
              <TableRow>
                <TableCell colSpan={3} sx={{ p: 0 }}>
                  <MenuItem
                    component="button"
                    onClick={() => setAddVariable(true)}
                    sx={{
                      height: 48,
                      width: '100%',
                      fontWeight: 500,
                      gap: 0.5,
                      pl: `calc(16px + 30px + 8px + ${level * 4 * 8}px)`
                    }}
                  >
                    <Icon fontSize="small" name="AddCircleOutlineOutlined" />
                    Lägg till
                  </MenuItem>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ----- Dialogs ----- */}
      <AddVariableDialog
        open={addVariable}
        onClose={() => setAddVariable(false)}
        onSave={(name) => {
          onCreateVariable(name, level, parentVariable?.id)
          setAddVariable(false)
        }}
        variables={allVariables}
      />
      {editVariable ? (
        <EditVariableDialog
          name={
            variables.find((variable) => variable.id === editVariable)
              ?.name as string
          }
          open={Boolean(editVariable)}
          onClose={() => setEditVariable(null)}
          onSave={(name, callback) => {
            onUpdateVariable(editVariable, name, (err) => {
              if (!err) {
                setEditVariable(null)
              }
              callback()
            })
          }}
          variableId={editVariable}
          variables={allVariables}
        />
      ) : null}
      {deleteVariable ? (
        <DeleteDialog
          open={Boolean(deleteVariable)}
          handleClose={() => setDeleteVariable(null)}
          handleDelete={(callback) => {
            onDeleteVariable(deleteVariable, (err) => {
              if (!err) {
                setDeleteVariable(null)
              }
              callback()
            })
          }}
          title={
            variables.find((variable) => variable.id === deleteVariable)?.name
          }
          type="variabel"
        />
      ) : null}
    </>
  )
}

export default VariablesSection
