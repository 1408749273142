import React, { useState, useEffect, useRef } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form } from 'antd'

import PageHeader from 'components/common/PageHeader'
import Table from 'components/common/Table'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'

import Content from './Content'
import Styles from './styles.module.css'
import * as Conf from './conf.js'
import * as Functions from 'helpers/Functions'

const SpecificTable = ({
  activeTable,
  editMode,
  setEditMode,
  tryPutColumnsAndFilters,
  resetValues,
  formRef,
  activeSource
}) => {
  const [rows, setRows] = useState(
    Conf.getMappingRows(
      formRef.current ? formRef.current.getFieldsValue() : undefined
    )
  )

  const contentRef = useRef(null)

  const initContent =
    activeTable && activeTable.included.stream_filters.length > 0
      ? {
          attribute:
            activeTable.included.stream_filters[0].attributes.input_attribute,
          condition:
            activeTable.included.stream_filters[0].attributes.condition,
          value: activeTable.included.stream_filters[0].attributes.value
        }
      : {
          attribute: undefined,
          condition: undefined,
          value: undefined
        }

  const initMappings =
    activeTable && activeTable.attributes.columns.length > 2
      ? {
          mappings: activeTable.attributes.columns
            .map((item) => {
              if (item.name !== 'from_date' && item.name !== 'to_date') {
                return {
                  in: item.input_attribute,
                  out: item.name,
                  datatype: item.type,
                  id: item.id
                }
              }
            })
            .filter(Boolean)
        }
      : {
          mappings:
            activeSource.included.stream_data &&
            activeSource.included.stream_data.length > 0
              ? Object.keys(activeSource.included.stream_data[0]).map(
                (item) => ({
                  in: item,
                  out: undefined,
                  datatype: undefined
                })
              )
              : []
        }

  useEffect(() => {
    if (activeTable && activeTable.included.stream_filters.length > 0) {
      contentRef.current.setFieldsValue({
        attribute:
          activeTable.included.stream_filters[0].attributes.input_attribute,
        condition: activeTable.included.stream_filters[0].attributes.condition,
        value: activeTable.included.stream_filters[0].attributes.value
      })
    } else {
      contentRef.current.setFieldsValue(initContent)
    }

    if (activeTable && activeTable.attributes.columns.length > 2) {
      formRef.current.setFieldsValue({
        mappings: activeTable.attributes.columns
          .map((item) => {
            if (item.name !== 'from_date' && item.name !== 'to_date') {
              return {
                in: item.input_attribute,
                out: item.name,
                datatype: item.type,
                id: item.id
              }
            }
          })
          .filter(Boolean)
      })
    } else {
      formRef.current.setFieldsValue(initMappings)
    }

    setRows(
      Conf.getMappingRows(
        formRef.current ? formRef.current.getFieldsValue() : undefined
      )
    )
  }, [activeTable, resetValues])

  return (
    <Box>
      <Form layout="vertical" initialValues={initContent} ref={contentRef}>
        <Content
          stream={activeSource.included.stream_data}
          activeTable={activeTable}
          editMode={editMode}
          formRef={contentRef}
          resetValues={resetValues}
        />
      </Form>
      <PageHeader
        className={Styles['mapping-header']}
        isSubheader
        header={'Mappning'}
        line
      />
      <Form
        ref={formRef}
        initialValues={initMappings}
        onFinish={async () => {
          if (await Functions.validateForm(formRef)) {
            const content = contentRef.current.getFieldsValue()
            const mapping = formRef.current.getFieldsValue()

            if (
              (content.attribute && content.condition && content.value) ||
              (!content.attribute && !content.condition && !content.value)
            ) {
              const data = {
                attributes: {
                  input_attribute: content.attribute,
                  condition: content.condition,
                  value: content.value,
                  source_columns: mapping.mappings.map((item) => ({
                    id: item.id ? item.id : undefined,
                    attributes: {
                      name: item.out,
                      type: item.datatype,
                      tag_primary_key: false,
                      tag_reference: false,
                      input_attribute: item.in
                    }
                  }))
                }
              }

              tryPutColumnsAndFilters(
                activeTable.id,
                { data },
                activeTable.attributes.active_source_id
              )

              setEditMode(false)
            } else {
              Functions.messageWarning(
                'Alla fälten för villkor måste vara ifyllda eller tomma.'
              )
            }
          }
        }}
      >
        <Form.List name="mappings">
          {(fields, { add, remove }) => (
            <>
              <Table
                className={'ignore-locked-mode'}
                headers={Conf.MAPPING_HEADERS(
                  editMode,
                  fields,
                  formRef,
                  remove,
                  setRows,
                  activeSource.included.stream_data
                )}
                rows={rows}
                ellipsis={false}
              />
              <Form.Item>
                <Button
                  disabled={!editMode}
                  className={`ignore-locked-mode ${Styles['add-mapping-button']}`}
                  onClick={() => {
                    add({ in: undefined, out: undefined, datatype: undefined })
                    setRows(
                      Conf.getMappingRows(
                        formRef.current
                          ? formRef.current.getFieldsValue()
                          : undefined
                      )
                    )
                  }}
                >
                  Lägg till attribut
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Box>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SourceTableActions
    },
    dispatch
  )
}

export default connect(() => {}, mapDispatchToProps)(SpecificTable)
