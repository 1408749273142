import React from 'react'

import { Cascader as AntdCascader } from 'antd'

import ProductTag from 'components/common/ProductTag'
import Spin from 'components_new/atoms/Spin'
import OverflowText from 'components_new/molecules/OverflowText'

import Styles from './styles.module.css'
import './styles.css'
/*
USAGE
const [val, setVal] = useState('')

<Cascader
  value={val}
  productTag
  className={'width-100'}
  options={[
    {
      value: 'collect',
      label: <ProductTag product={'collect'} />,
      productTag: true,
      children: [
        {
          value: 'table1',
          label: 'Table1',
          children: [
            {
              value: 'column1',
              label: 'Column1'
            }
          ]
        }
      ]
    },
    {
      value: 'build',
      label: <ProductTag product={'build'} />,
      productTag: true,
      children: [
        {
          value: 'table2',
          label: 'table2',
          children: [
            {
              value: 'column2',
              label: 'Column2'
            }
          ]
        }
      ]
    }
  ]}
  onChange={(v) => setVal(v)}
  placeholder={'Alla'}


  children={<div> // Med additional className grejer
    <p>Exempel</p>
    <Input disabled value={'100'} />
  </div>}
/>

*/

const sort = (options) => {
  return options
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .map((option) => ({
      ...option,
      children: option.children ? sort(option.children) : undefined
    }))
}

const Cascader = ({
  value,
  options,
  onChange,
  placeholder,
  children,
  className,
  hasProductTag,
  disabled = false,
  ignoreLockedMode = false,
  dropdownClassName,
  clearable = true,
  size,
  onlyShowLastValue = false,
  multiple = false,
  tagRender,
  search,
  dropdownRender,
  loading,
  loadData,
  label,
  labelBackground,
  containerClassName
}) => {
  return (
    <div
      className={`${containerClassName} ${
        label ? Styles['label-container'] : ''
      }`}
    >
      {label && (
        <label className={labelBackground ? Styles['colored-label'] : ''}>
          {label}
        </label>
      )}
      <AntdCascader
        loadData={loadData}
        size={size}
        disabled={disabled}
        value={value}
        className={className}
        options={sort(options)}
        onChange={onChange}
        placeholder={placeholder}
        allowClear={clearable}
        multiple={multiple}
        tagRender={tagRender}
        getPopupContainer={(trigger) =>
          onlyShowLastValue ? document.body : trigger.parentNode
        }
        dropdownRender={
          dropdownRender
            ? dropdownRender
            : (menus) =>
                loading ? (
                  <div className={Styles['loader-wrapper']}>
                    <Spin size={'large'} />
                  </div>
                ) : (
                  <div
                    className={`${dropdownClassName} ${
                      ignoreLockedMode ? 'ignore-locked-mode' : ''
                    }`}
                  >
                    {menus}
                    <div className={children ? 'divider' : null} />
                    {children}
                  </div>
                )
        }
        displayRender={
          loading
            ? () => <Spin size={'medium'} />
            : multiple
              ? undefined
              : hasProductTag && !onlyShowLastValue
                ? (labels, selectedOptions) => {
                    const path = []
                    let product = ''

                    labels.map((item, i) => {
                      const option = selectedOptions[i]

                      if (option) {
                        if (option.productTag) {
                          product = option.value
                        } else {
                          path.push(option.label)
                        }
                      }
                    })

                    return <ProductTag product={product} path={path} />
                  }
                : (labels) =>
                    onlyShowLastValue ? (
                      labels[labels.length - 1]
                    ) : (
                      <OverflowText variant="body1">
                        {labels
                          .map((label) => {
                            // current support: <p>-tags
                            if (React.isValidElement(label)) {
                              return label.props.children
                            }

                            return label
                          })
                          .join(' / ')}
                      </OverflowText>
                    )
        }
        showSearch={
          search
            ? {
                filter: (inputValue, path) =>
                  path.some((option) =>
                    typeof option.label === 'string'
                      ? option.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) > -1
                      : null
                  )
              }
            : false
        }
      />
    </div>
  )
}

export default Cascader
