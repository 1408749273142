import React from 'react'

import { KpiStatus } from 'types/GlobalKpiTemplates'
import { TStatus, translateKpiTemplateStatus } from './utils'

import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconTip from 'components_new/molecules/IconTip'
import Text from 'components_new/atoms/Text'

interface StatusIconProps {
  disableTooltip?: boolean
  value: TStatus
  variant: Exclude<keyof KpiStatus, 'perform_template_id'>
}

const StatusIcon = (props: StatusIconProps) => {
  const { disableTooltip, value, variant } = props

  const { iconName, title, body } = translateKpiTemplateStatus[variant][value]

  return (
    <IconTip
      icon={
        <Icon
          color={
            value === 'checked'
              ? 'success'
              : value === 'indeterminate'
                ? 'warning'
                : 'disabled'
          }
          name={iconName}
          sx={{ fontSize: '1em' }}
        />
      }
      title={
        disableTooltip ? (
          ''
        ) : (
          <>
            <Text
              fontWeight="bold"
              variant="body2"
              color="inherit"
              gutterBottom
            >
              {title}
            </Text>

            <Text variant="body2" color="inherit" gutterBottom>
              {body}
            </Text>

            {
              // If not fully checked
              value !== 'checked' && variant !== 'required_target_missing' ? (
                <>
                  <Divider />
                  <Text
                    fontStyle="italic"
                    variant="caption"
                    color="text.secondary"
                  >
                    Kontakta din systemägare för mer information.
                  </Text>
                </>
              ) : null
            }

            {
              // If target is required
              value !== 'checked' && variant === 'required_target_missing' ? (
                <>
                  <Divider />
                  <Button
                    endIcon={<Icon name="ChevronRight" />}
                    fullWidth
                    size="small"
                    to="/admin/settings/config"
                    variant="text"
                    sx={{ my: 1 }}
                  >
                    Konfigurera nyckeltal
                  </Button>
                </>
              ) : null
            }
          </>
        )
      }
      size={'small'}
    />
  )
}

export default StatusIcon
