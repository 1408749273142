import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { Typography as MUITypography } from '@mui/material'

export interface TextProps {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  children: ReactNode
  className?: string
  color?: string
  component?: ElementType<any>
  fontWeight?: string
  fontSize?: number | string
  fontStyle?: 'italic' | 'normal' | 'oblique'
  gutterBottom?: boolean
  lineHeight?: number | string
  noWrap?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  paragraph?: boolean
  sx?: object
  variant?: TextVariantType
}

export type TextVariantType =
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'gigantic'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2'

/**
 * The Text component is used to present textual
 * content as clearly and efficiently as possible.
 *
 * Too many type sizes and styles at once can spoil
 * any layout. A typographic scale has a limited
 * set of type sizes that work well together along
 * with the layout grid.
 *
 * Default variant is 'body1'.
 */

const Text = React.forwardRef((props: TextProps, ref: any) => {
  const { color, ...rest } = props

  return (
    <MUITypography color={color ?? 'text.primary'} {...rest} ref={ref}>
      {props.children}
    </MUITypography>
  )
})

Text.defaultProps = {
  variant: 'body1'
}

Text.displayName = 'Text'
export default Text
