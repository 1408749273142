import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import { useResizeDetector } from 'react-resize-detector'
import { SxProps } from '@mui/material'

interface AspectRatioContainerProps {
  children: ReactNode
  ratio: number
  sx?: SxProps
}

const AspectRatioContainer = (props: AspectRatioContainerProps) => {
  const { children, ratio, sx } = props

  const RATIO = ratio

  const { width, height, ref } = useResizeDetector()

  const CONTAINER_WIDTH = width
  const CONTAINER_HEIGHT = height

  const CHILD_WIDTH = CONTAINER_WIDTH
  const CHILD_MAX_WIDTH = CONTAINER_HEIGHT && CONTAINER_HEIGHT * RATIO
  const CHILD_HEIGHT = CONTAINER_WIDTH && CONTAINER_WIDTH / RATIO
  const CHILD_MAX_HEIGHT = CONTAINER_HEIGHT

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          ...sx
        }}
      >
        <Box
          sx={{
            width: ratio ? CHILD_WIDTH : '100%',
            height: ratio ? CHILD_HEIGHT : '100%',
            maxHeight: ratio ? CHILD_MAX_HEIGHT : undefined,
            maxWidth: ratio ? CHILD_MAX_WIDTH : undefined,
            margin: 'auto',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          {children}
        </Box>
      </Box>
    </div>
  )
}

export default AspectRatioContainer

// Try this? https://github.com/wnr/element-resize-detector
