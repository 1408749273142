import { Theme } from '@mui/material'

export const fillRangeDates = (
  theme: Theme,
  value: { from: string | null; to: string | null }
) => {
  const fromDate = value.from ? new Date(value.from) : null
  const toDate = value.to ? new Date(value.to) : null

  /** From date elements */
  const fromDateElements = document.querySelectorAll(
    '.fromDate > * .MuiPickersDay-root'
  )

  Array.from(fromDateElements).forEach((element) => {
    if (element instanceof HTMLElement) {
      const timestamp = Number(element.dataset.timestamp)

      if (timestamp) {
        const date = new Date(timestamp)

        if (fromDate && toDate) {
          if (fromDate < date && date < toDate) {
            element.style.background =
              theme.palette.primary.transparent ?? '#ffffff'
          } else {
            element.style.background = 'transparent'
          }
        }

        /** To do: Figure this out and change to dayjs. */
        date.setDate(date.getDate() + 1)

        const ISOdate = date.toISOString().substring(0, 10)

        if (ISOdate === value.from) {
          element.style.background = theme.palette.primary.main ?? '#0000ff'
        }
        if (fromDate && toDate) {
          if (fromDate < date && date < toDate) {
            if (ISOdate === value.to) {
              element.style.background =
                theme.palette.primary.transparent ?? '#ffffff'
            }
          }
        }

        if (!fromDate && !toDate) {
          element.style.background = 'transparent'
        } else if (fromDate && !toDate) {
          if (ISOdate !== value.from) {
            element.style.background = 'transparent'
          }
        }
      }
    }
  })

  /** To date elements */
  const toDateElements = document.querySelectorAll(
    '.toDate > * .MuiPickersDay-root'
  )

  Array.from(toDateElements).forEach((element) => {
    if (element instanceof HTMLElement) {
      const timestamp = Number(element.dataset.timestamp)

      if (timestamp) {
        const date = new Date(timestamp)

        if (fromDate && toDate) {
          if (fromDate < date && date < toDate) {
            element.style.background =
              theme.palette.primary.transparent ?? '#ffffff'
          } else {
            element.style.background = 'transparent'
          }
        }

        /** To do: Figure this out and change to dayjs. */
        date.setDate(date.getDate() + 1)

        const ISOdate = date.toISOString().substring(0, 10)

        if (fromDate && toDate) {
          if (fromDate < date && date < toDate) {
            if (ISOdate === value.from) {
              element.style.background =
                theme.palette.primary.transparent ?? '#ffffff'
            }
          }
        }
        if (ISOdate === value.to) {
          element.style.background = theme.palette.primary.main ?? '#ffffff'
        }

        if (!fromDate && !toDate) {
          element.style.background = 'transparent'
        } else if (!fromDate && toDate) {
          if (ISOdate !== value.to) {
            element.style.background = 'transparent'
          }
        }
      }
    }
  })
}
