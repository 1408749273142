import React, { ReactNode } from 'react'

import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

import DndListItem from '../DndListItem'

interface DndSortableListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  disabled?: boolean
  id: string
  sx?: object
  variant?: 'blank' | 'elevation' | 'outlined'
}

const DndSortableListItem = (props: DndSortableListItemProps) => {
  const { children, disabled, id, sx, variant, ...rest } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      disabled: disabled,
      id: id
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <DndListItem
      disabled={disabled}
      listeners={listeners}
      ref={setNodeRef}
      style={style}
      sx={sx}
      variant={variant}
      {...attributes}
      {...rest}
    >
      {children}
    </DndListItem>
  )
}

DndSortableListItem.displayName = 'DndSortableListItem'
export default DndSortableListItem
