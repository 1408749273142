import { OptionProps } from 'components_new/molecules/OptionsMenu'

import {
  KpiTemplateCalculationBlockType,
  KpiTemplateCalculationOperation
} from 'types/GlobalKpiTemplates'

export const operators: KpiTemplateCalculationOperation[] = [
  KpiTemplateCalculationOperation['PLUS'],
  KpiTemplateCalculationOperation['MINUS'],
  KpiTemplateCalculationOperation['MULTIPLICATION'],
  KpiTemplateCalculationOperation['DIVISION']
]

export const translateOperator: Record<
  KpiTemplateCalculationOperation,
  string
> = {
  [KpiTemplateCalculationOperation.PLUS]: '+',
  [KpiTemplateCalculationOperation.MINUS]: '–',
  [KpiTemplateCalculationOperation.MULTIPLICATION]: '×',
  [KpiTemplateCalculationOperation.DIVISION]: '÷'
}

export const getOptions = (
  editType: (value: KpiTemplateCalculationBlockType) => void
): OptionProps[] => {
  return [
    {
      iconName: 'NumbersOutlined',
      onClick: () => editType(KpiTemplateCalculationBlockType.NUMBER),
      title: 'Konstant'
    },
    {
      iconName: 'TrendingUpOutlined',
      onClick: () => editType(KpiTemplateCalculationBlockType.TEMPLATE),
      title: 'Nyckeltal'
    },
    {
      iconName: 'ViewCarouselOutlined',
      onClick: () => editType(KpiTemplateCalculationBlockType.BLOCK),
      title: 'Räkneblock'
    },
    {
      iconName: 'SellOutlined',
      onClick: () => editType(KpiTemplateCalculationBlockType.VARIABLE),
      title: 'Kontonivå'
    }
  ]
}
