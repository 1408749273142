import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import Styles from './styles.module.css'

const TextButton = (props) => {
  const {
    onClick = () => {},
    text,
    className,
    large,
    icon,
    underline,
    disabled,
    tooltip = undefined
  } = props

  let textButtonContent = null

  if (large) {
    textButtonContent = (
      <h2
        className={`${Styles.text} ${className}`}
        onClick={() => (disabled ? {} : onClick())}
      >
        {text}
      </h2>
    )
  } else if (icon) {
    textButtonContent = (
      <div
        className={`${Styles['icon-container']} ${className} ${
          disabled ? Styles.disabled : ''
        }`}
        onClick={() => (disabled ? {} : onClick())}
      >
        <Icon icon={icon} />
        <p className={`inherit ${underline ? Styles.underline : ''}`}>{text}</p>
      </div>
    )
  } else {
    textButtonContent = (
      <p
        className={`${Styles.text} ${className} ${
          underline ? Styles.underline : ''
        } ${disabled ? Styles.disabled : ''}`}
        onClick={() => (disabled ? {} : onClick())}
      >
        {text}
      </p>
    )
  }

  if (tooltip) {
    return <Tooltip title={tooltip}>{textButtonContent}</Tooltip>
  }

  return textButtonContent
}

export default TextButton
