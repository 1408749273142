import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements`

export async function getCustomers() {
  return axios.get(
    `${URL}/mindset-funnel/customers`,
    parseAuthenticationHeader()
  )
}

export async function getUsers() {
  return axios.get(`${URL}/mindset-funnel/users`, parseAuthenticationHeader())
}

export async function getGraphData() {
  return axios.get(
    `${URL}/mindset-funnel/graph-data`,
    parseAuthenticationHeader()
  )
}

export async function getNSMData() {
  return axios.get(`${URL}/nsm`, parseAuthenticationHeader())
}

export async function getApiLogs() {
  return axios.get(`${URL}/api-logs`, parseAuthenticationHeader())
}

export async function getUsersUsage() {
  return axios.get(`${URL}/usage/users`, parseAuthenticationHeader())
}

export async function getOneUserUsage(id: string) {
  return axios.get(`${URL}/usage/users/${id}`, parseAuthenticationHeader())
}
