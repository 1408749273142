import { KeyboardEvent } from 'react'

export const LOGIN = 'LOGIN'
export const LOGIN_WITH_AZURE = 'LOGIN_WITH_AZURE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const VERIFICATION_CODE = 'VERIFICATION_CODE'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SIGNED_IN = 'SIGNED_IN'

export function validateNewPasswordLength(password: string) {
  return password.length >= 8
}

export function validateNewPasswordSmallCharacter(password: string) {
  return /[a-z]/.test(password)
}

export function validateNewPasswordCapsCharacter(password: string) {
  return /[A-Z]/.test(password)
}

export function validateNewPasswordNumber(password: string) {
  return /[0-9]/.test(password)
}

export function validateEmail(email: string) {
  return email.includes('@')
}

export function validateRequired(str: string, required = true) {
  if (required) {
    return str.length > 0
  }

  return true
}

export const getValidateItems = (password: string) => [
  {
    title: 'Minst 8 karaktärer långt',
    valid: validateNewPasswordLength(password)
  },
  {
    title: 'Minst en liten bokstav (a-ö)',
    valid: validateNewPasswordSmallCharacter(password)
  },
  {
    title: 'Minst en stor bokstav (A-Ö)',
    valid: validateNewPasswordCapsCharacter(password)
  },
  {
    title: 'Minst ett nummer (0-9)',
    valid: validateNewPasswordNumber(password)
  }
]

export const handleEnter = (e: KeyboardEvent, f: () => void) => {
  if (e.key === 'Enter') {
    f()
  }
}
