import React, { useState } from 'react'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

const TextField = ({ className, text, label, bold, hidden }) => {
  const [showHidden, setShowHidden] = useState(false)

  return (
    <div className={`${Styles['text-field-container']} ${className}`}>
      <label>{label}</label>
      {hidden ? (
        <div className={Styles['hidden-container']}>
          <Text variant={bold ? 'subtitle2' : 'body1'}>
            {showHidden ? text : '•'.repeat(text.length)}
          </Text>
          <IconButton onClick={() => setShowHidden(!showHidden)}>
            <Icon
              name={showHidden ? 'VisibilityOutlined' : 'VisibilityOffOutlined'}
            />
          </IconButton>
        </div>
      ) : (
        <Text variant={bold ? 'subtitle2' : 'body1'}>{text}</Text>
      )}
    </div>
  )
}

export default TextField
