import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import ShortId from 'shortid'

const PageToggle = ({ items, active }) => {
  const [shortid] = useState(ShortId.generate())

  return (
    <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
      {items.map((item, i) => (
        <Box key={`${shortid}-${i}`} sx={{ cursor: 'pointer' }}>
          <Text
            color={active === item.value ? 'primary.main' : null}
            onClick={() => item.onClick()}
            variant="subtitle1"
          >
            {item.label}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default PageToggle
