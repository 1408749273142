import { MutableRefObject, useRef } from 'react'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { SimRenaming } from 'utils/types'

export const getCategories = (terms: { [key: string]: SimRenaming }) => {
  const categories: { [key: string]: SimRenaming[] } = Object.values(
    terms
  ).reduce((acc, term) => {
    const categoryName = term.category

    if (!acc[categoryName]) {
      acc[categoryName] = []
    }
    acc[categoryName].push(term)

    return acc
  }, {} as { [key: string]: SimRenaming[] })

  return Object.entries(categories)
    .sort(([a], [b]) => sortAlphabeticalAsc(a, b))
    .map(([cat, terms]) => ({
      category: cat,
      terms: terms.sort((a, b) =>
        sortAlphabeticalAsc(a.original_name, b.original_name)
      )
    }))
}

export const getInputRefs = (terms: { [key: string]: SimRenaming }) => {
  const inputRefs: {
    [key: string]: MutableRefObject<{
      value: string | null
    }>
  } = {}

  Object.values(terms).forEach((term) => {
    inputRefs[term.id] = useRef({ value: term.modified_name ?? '' })
  })

  return inputRefs
}
