import * as UserApi from 'redux/api/Applications/users/v1'
import * as Types from 'redux/Types'

export function tryGetAllUsers() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_USERS
    })

    UserApi.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_USERS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_USERS_FAILED
        })
      })
  }
}
