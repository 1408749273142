import { Theme, useTheme } from '@mui/material/styles'
import { ActiveElement, ChartEvent, ChartOptions } from 'chart.js'

import {
  DefaultDatasetData,
  FormattedWidgetData,
  ParsedWidgetDataLabel,
  WidgetObject
} from 'types/GlobalWidget'
import { formatAxisValue } from '../utils'

export type BubbleChartOptions = ChartOptions<'bubble'> & {
  plugins: {
    horizontalLines: { lines: { value: number; color: string }[] }
    verticalLines: { lines: { value: number; color: string }[] }
    statusAreas: {
      xLine: { value: number | null; increaseIsPositive: boolean }
      yLine: { value: number | null; increaseIsPositive: boolean }
      theme: Theme
    }
  }
}

export const getOptions = (
  allowOnClick: boolean,
  bubbles: any,
  formattedData: FormattedWidgetData,
  handleClick: (
    event: PointerEvent,
    dataLabels: ParsedWidgetDataLabel[]
  ) => void,
  theme: Theme,
  widget: WidgetObject,
  scaleFactor: number
): BubbleChartOptions => {
  // Widget options
  const invertYAxis = widget.settings.invert_y_axis

  // X axis options
  const xAxis = formattedData.datasets.find((item) => item.settingIndex === 0)
  const xAxisThreshold = xAxis?.upperBoundThreshold ?? null
  const xAxisIncreaseIsPositive = xAxis?.increaseIsPositive ?? true
  const xAxisUnit = xAxis?.unit || null
  const xAxisLabel = xAxis?.label || ''

  // Y axis options
  const yAxis = formattedData.datasets.find((item) => item.settingIndex === 1)
  const yAxisThreshold = yAxis?.upperBoundThreshold ?? null
  const yAxisIncreaseIsPositive = yAxis?.increaseIsPositive ?? true
  const yAxisUnit = yAxis?.unit || null
  const yAxisLabel = yAxis?.label || ''

  return {
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor =
        elements.length > 0 && allowOnClick ? 'pointer' : 'default'
    },
    onClick: (event, elements) => {
      if (allowOnClick && elements.length > 0) {
        handleClick(
          event.native as PointerEvent,
          elements.map(
            (element) => bubbles.datasets[element.datasetIndex].dataLabel
          )
        )
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (item: any) => getTooltipLabel(item)
        }
      },
      verticalLines: {
        lines:
          xAxisThreshold !== null
            ? [
                {
                  value: xAxisThreshold,
                  color: '#64748b'
                }
              ]
            : []
      },
      horizontalLines: {
        lines:
          yAxisThreshold !== null
            ? [
                {
                  value: yAxisThreshold,
                  color: '#64748b'
                }
              ]
            : []
      },
      statusAreas: {
        xLine: {
          value: xAxisThreshold,
          increaseIsPositive: xAxisIncreaseIsPositive
        },
        yLine: {
          value: yAxisThreshold,
          increaseIsPositive: yAxisIncreaseIsPositive
        },
        theme: theme
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        reverse: invertYAxis,
        title: {
          display: yAxis ? true : false,
          text: yAxisLabel,
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        ticks: {
          callback: function (this, value, index, ticks): string {
            if (index === ticks.length - 1 && yAxisUnit) {
              return yAxisUnit
            } else {
              return formatAxisValue(Number(value), null)
            }
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        grid: {
          color: theme.palette.divider
        }
      },
      x: {
        title: {
          display: xAxis ? true : false,

          text: xAxisLabel,
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        ticks: {
          callback: function (this, value, index, ticks): string {
            if (index === ticks.length - 1 && xAxisUnit) {
              return xAxisUnit
            } else {
              return formatAxisValue(Number(value), null)
            }
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        grid: {
          color: theme.palette.divider
        }
      }
    }
  }
}

/**
 * Parses the data to be displayed on one dimension e.g. x, y, r.
 *
 * @param inputData
 * @param index
 * @param key
 * @returns
 */
const getDimension = (
  datasets: FormattedWidgetData['datasets'],
  valueIndex: number,
  datasetIndex: number
) => {
  const dataset = datasets.find((item) => item.settingIndex === datasetIndex)
  const isRadius = datasetIndex === 2

  if (dataset) {
    return {
      settings: {
        ...dataset,
        prettyValue: dataset?.prettyData[valueIndex] || '0'
      },
      value: dataset?.data[valueIndex] || 0
    }
  }

  return { value: isRadius ? 5 : 0 }
}

/**
 * Returns the colors to be used on the bubbles.
 *
 * @returns
 */
const getColors = () => {
  const theme = useTheme()

  return {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.background.widget
  }
}

/**
 * Parses the data object for bubble chart.
 *
 * @param data
 * @returns
 */
export const getBubbles = (formattedData: FormattedWidgetData) => {
  let r: number[] = []

  if (formattedData.datasets[2]) {
    r = (formattedData.datasets[2].data as DefaultDatasetData).map((value) =>
      value === null ? 0 : value
    )
  } else {
    r = formattedData.labels.map(() => 0)
  }

  const rMin = Math.min(...r)
  const rMax = Math.max(...r)

  return {
    datasets: formattedData.labels.map((label, index) => {
      const x = getDimension(formattedData.datasets, index, 0)
      const y = getDimension(formattedData.datasets, index, 1)
      const r = getDimension(formattedData.datasets, index, 2)

      return {
        dataLabel: label,
        label: label.display_label,
        ...getColors(),
        data: [
          {
            x: x.value,
            xSettings: x.settings || null,
            y: y.value,
            ySettings: y.settings || null,
            size: r.value,
            sizeSettings: r.settings || null
          }
        ],
        radius: function (context: any) {
          const scale = 0.025 * context.chart.width
          const size = context.dataset.data[context.dataIndex].size
          const normalized = rMin === rMax ? 0 : (size - rMin) / (rMax - rMin)

          if (normalized < 0.25) {
            return 0.25 * scale
          }

          return normalized * scale
        }
      }
    })
  }
}

const getTooltipLabel = (item: any) => {
  let tooltip: string[] = [item.label, '']

  tooltip = addTooltipRow(item.raw.xSettings, tooltip)
  tooltip = addTooltipRow(item.raw.ySettings, tooltip)
  tooltip = addTooltipRow(item.raw.sizeSettings, tooltip)

  return [...tooltip, '']
}

const addTooltipRow = (settings: any, tooltip: string[]) => {
  if (settings) {
    const label: string = settings.label
    const prettyValue: string = settings.prettyValue
    const unit: string = settings.unit

    return [...tooltip, `${label}: ${prettyValue} ${unit}`]
  }

  return tooltip
}
