import { getAll, put } from 'redux/api/KpiVariableOptions'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryGetAllKpiVariableOptions() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_KPI_VARIABLE_OPTIONS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_KPI_VARIABLE_OPTIONS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_KPI_VARIABLE_OPTIONS_FAILED
        })
      })
  }
}

export function tryPutKpiVariableOptions(
  economySetId: string,
  kpiVariableId: string,
  accounts: string[],
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_KPI_VARIABLE_OPTIONS
    })

    put(kpiVariableId, {
      data: { accounts, economy_set_id: economySetId }
    })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_KPI_VARIABLE_OPTIONS_SUCCESS,
          payload: response.data.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå har uppdaterats' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_KPI_VARIABLE_OPTIONS_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Kontonivå kunde inte uppdateras' }
        })

        if (callback) {
          callback(true)
        }
      })
  }
}
