import React, { useState } from 'react'

import { useTheme } from '@mui/material'

import InputBase from 'components_new/atoms/InputBase'
import { TextProps } from 'components_new/atoms/Text'
import OverflowText from '../OverflowText'

interface EditableTitleProps extends Omit<TextProps, 'children'> {
  disabled?: boolean
  // eslint-disable-next-line no-unused-vars
  onSubmit: (value: string) => void
  value: any
  variant:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'gigantic'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
}

/**
 * The EditableTitle component is used let the
 * user edit a single line text element
 * without the need of opening a dialog.
 */

const EditableTitle = React.forwardRef(
  (props: EditableTitleProps, ref: any) => {
    const { disabled, onSubmit, value, ...rest } = props

    const theme = useTheme()
    const font = theme.typography[props.variant]

    const [editValue, setEditValue] = useState(value)
    const [isActive, setIsActive] = useState(false)

    const handleSubmit = () => {
      setIsActive(false)
      onSubmit(editValue)
    }

    return !isActive ? (
      <OverflowText
        {...rest}
        onClick={() => {
          if (!disabled) {
            setEditValue(value)
            setIsActive(true)
          }
        }}
        sx={{
          cursor: !disabled ? 'text' : 'default',
          '&:hover': {
            bgcolor: !disabled ? 'action.hover' : null
          }
        }}
      >
        {value || 'Namnlös'}
      </OverflowText>
    ) : (
      <InputBase
        autoFocus
        fullWidth
        value={editValue}
        onChange={(event) => setEditValue(event.target.value)}
        onBlur={() => {
          setEditValue(value)
          setIsActive(false)
        }}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            handleSubmit()
          }
        }}
        sx={{
          ...font,
          bgcolor: 'action.focus',
          '& .MuiInputBase-input': {
            p: 0,
            height: 'auto'
          }
        }}
        ref={ref}
      />
    )
  }
)

export default EditableTitle
