import {
  BreakdownByDisplayType,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

export const hasBreakdown = (widget: WidgetObject) => {
  const breakdownSet = widget.settings.kpi_options.find(
    (ko) => ko.breakdown_by.selected
  )

  return !!(breakdownSet || widget.settings.kpi_options.length > 1)
}

export const breakdownByDisplayTypeOptions = [
  BreakdownByDisplayType.CLOSE_BY,
  BreakdownByDisplayType.STACKED,
  BreakdownByDisplayType.STACKED_100_PERCENT
]

/*-- always displayed --*/
export const DEFAULT_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.LIST,
  WidgetType.PIE_CHART
]

export const ORDER = [
  WidgetType.KEY_FIGURE,
  WidgetType.BAR_CHART,
  WidgetType.LINE_CHART,
  WidgetType.LIST,
  WidgetType.PIE_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.FUNNEL,
  WidgetType.MAP
]
