import React from 'react'

import ShortId from 'shortid'
import Dagre from 'dagre'
import _ from 'lodash'

import OverflowText from 'components_new/molecules/OverflowText'

import { isEdge, isNode } from 'reactflow'

const dagreGraph = new Dagre.graphlib.Graph()

dagreGraph.setDefaultEdgeLabel(() => ({}))

export const onElementsRemove = (elementsToRemove, relations, setRelations) => {
  const edgeIds = elementsToRemove
    .map((el) => (isEdge(el) ? el.id : undefined))
    .filter(Boolean)

  const hasLinkTable = elementsToRemove.find((el) => el.data.isLinkTable)

  if (hasLinkTable) {
    const linkRelations = elementsToRemove.filter(
      (el) => el.target === hasLinkTable.id
    )

    const relationId = linkRelations[0].id.slice(0, -2)

    edgeIds.push(relationId)
  }

  setRelations({
    actualRelations: relations.actualRelations.filter(
      (el) => !edgeIds.includes(el.id)
    ),
    linkRelations: relations.linkRelations.filter(
      (el) => !edgeIds.includes(el.id)
    )
  })
}

export const updatePosition = (node, setNodes) => {
  setNodes((nodes) => {
    return nodes.map((e) => (e.id === node.id ? node : e))
  })
}

const layoutElements = (elements, direction, showDetails = true) => {
  const nodeWidth = 143
  const nodeHeight = (colLen) => 143 + 26 * colLen * showDetails
  const isHorizontal = direction === 'LR'

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 150,
    ranksep: 150,
    ranker: 'tight-tree'
  })

  elements.forEach((e) =>
    isNode(e)
      ? dagreGraph.setNode(e.id, {
        width: nodeWidth,
        height: nodeHeight(e.data.columns.length)
      })
      : dagreGraph.setEdge(e.source, e.target)
  )

  Dagre.layout(dagreGraph)

  return elements.map((e) => {
    if (isNode(e)) {
      const nodeWithPosition = dagreGraph.node(e.id)

      e.targetPosition = isHorizontal ? 'left' : 'top'
      e.sourcePosition = isHorizontal ? 'right' : 'bottom'

      e.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight(e.data.columns.length) / 2
      }
    }

    return e
  })
}

// direction can be TB or LR
export const layout = (setNodes, direction, showDetails) => {
  setNodes((nodes) => layoutElements(nodes, direction, showDetails))
}

export const getInitialElements = (
  ontology,
  history,
  datasetValidCounts,
  onEdgeClick,
  showObject,
  setRelations,
  setNodes,
  setEdges,
  board,
  setLoaded
) => {
  const tableObject = {}
  let hasPositions = true
  const tableTechnicalNames = []
  const edges = []

  const nodes = ontology.attributes.tables.map((ont) => {
    const pk = ont.attributes.output_attributes.find(
      (attr) => attr.id === ont.attributes.primary_key_attribute
    )

    tableObject[ont.id] = ont.attributes.output_attributes

    if (
      ont.attributes.location_x === null ||
      ont.attributes.location_x === undefined
    ) {
      hasPositions = false
    }

    tableTechnicalNames.push(ont.attributes.technical_name)

    return {
      type: 'ontology',
      id: ont.id,
      data: {
        id: ont.id,
        name: ont.attributes.name,
        technicalName: ont.attributes.technical_name,
        plural: ont.attributes.plural,
        primaryKey: pk ? pk.id : null,
        onClick: () =>
          history.push(
            `/data-platform/ontology/datasets/${ont.id}/transformation`
          ),
        columns: ont.attributes.output_attributes.map((oa) => ({
          id: oa.id,
          name: oa.attributes.name,
          realName: oa.attributes.real_name,
          type: oa.attributes.type
        })),
        datasetValidCounts,
        ...showObject,
        isLinkTable: false,
        selectedRelationAttribute: undefined
      },
      position: { x: ont.attributes.location_x, y: ont.attributes.location_y }
    }
  })

  const linkRelations = []
  const actualRelations = []
  const relationHelperObject = {}

  ontology.included.shape_relations.forEach((item) => {
    const edgeId = ShortId.generate()
    const params = {
      source: item.attributes.left_table_id,
      target: item.attributes.right_table_id,
      type: item.attributes.type,
      edgeId,
      leftAttributeId: item.attributes.left_attribute_id,
      rightAttributeId: item.attributes.right_attribute_id,
      isPrimary: item.attributes.is_primary,
      offset: 1,
      sourceAttributes:
        tableObject[item.attributes.left_table_id].output_attributes,
      targetAttributes:
        tableObject[item.attributes.right_table_id].output_attributes
    }

    // Set offset to edges in order for them to not overlap
    if (params.isPrimary) {
      params.offset = 0
    } else {
      const sourceTargetKey = `${params.source}_${params.target}`
      const targetSourceKey = `${params.target}_${params.source}`

      if (sourceTargetKey in relationHelperObject) {
        params.offset = relationHelperObject[sourceTargetKey].length + 1

        relationHelperObject[sourceTargetKey].push(edgeId)
      } else if (`${params.target}_${params.source}` in relationHelperObject) {
        params.offset = relationHelperObject[targetSourceKey].length + 1

        relationHelperObject[targetSourceKey].push(edgeId)
      } else {
        relationHelperObject[sourceTargetKey] = [edgeId]
      }
    }

    const edge = {
      id: edgeId,
      animated: !params.isPrimary,
      type: 'floating',
      source: item.attributes.left_table_id,
      target: item.attributes.right_table_id,
      data: {
        ...params,
        ...showObject,
        onClick:
          ontology.attributes.type === 'STANDARD_ONTOLOGY'
            ? null
            : () => onEdgeClick(params)
      }
    }

    if (item.attributes.type === 'many_to_many') {
      const linkTableNode = nodes.find(
        (el) => el.id === item.attributes.link_table_id
      )

      // Get the correct link column for each 1-* relation
      const srcLinkColumn = getLinkColumn(
        item.attributes.left_table_id,
        item.attributes.left_attribute_id,
        linkTableNode,
        nodes
      )

      const targetLinkColumn = getLinkColumn(
        item.attributes.right_table_id,
        item.attributes.right_attribute_id,
        linkTableNode,
        nodes
      )

      /*
      Here two "fake" relations are set up to the link table. The first
      link edge goes from the source in the *-* relation to the link table.
      And the second goes from the target in the *-* relation to the link table.

      That is why source and leftAttributeId are the entities attributes and
      the target is always the link table.
      */
      const linkEdges = [
        {
          id: `${edgeId}-0`,
          animated: !params.isPrimary,
          type: 'floating',
          source: item.attributes.left_table_id,
          target: item.attributes.link_table_id,
          data: {
            ...params,
            leftAttributeId: item.attributes.left_attribute_id,
            rightAttributeId: srcLinkColumn.id,
            type: 'one_to_many',
            onClick: null
          }
        },
        {
          id: `${edgeId}-1`,
          animated: !params.isPrimary,
          type: 'floating',
          source: item.attributes.right_table_id,
          target: item.attributes.link_table_id,
          data: {
            ...params,
            leftAttributeId: item.attributes.right_attribute_id,
            rightAttributeId: targetLinkColumn.id,
            type: 'one_to_many',
            onClick: null
          }
        }
      ]

      linkTableNode.data = {
        ...linkTableNode.data,
        isLinkTable: true
      }

      edge.data.linkTableId = linkTableNode.id

      actualRelations.push(edge)
      linkRelations.push(...linkEdges)
      edges.push(...linkEdges)
    } else {
      actualRelations.push(edge)
      edges.push(edge)
    }
  })

  setRelations({
    linkRelations,
    actualRelations
  })

  setNodes(hasPositions ? nodes : layoutElements(nodes, 'TB'))
  setEdges(edges)

  board.fitView()

  setLoaded(true)
}

export const RELATION_HEADERS = [
  {
    label: 'FRÅN',
    key: 'from',
    render: function renderColumn(_, row) {
      return (
        <OverflowText variant="body1">
          {row.leftTable} ({row.leftTableAttribute})
        </OverflowText>
      )
    }
  },
  {
    label: 'TILL',
    key: 'to',
    render: function renderColumn(_, row) {
      return (
        <OverflowText variant="body1">
          {row.rightTable} ({row.rightTableAttribute})
        </OverflowText>
      )
    }
  },
  {
    label: 'KARDINALITET',
    key: 'type',
    render: function renderColumn(type) {
      switch (type) {
      case 'one_to_one':
        return 'En till en (1:1)'
      case 'one_to_many':
        return 'En till flera (1:*)'
      case 'many_to_many':
        return 'Flera till flera (*:*)'
      default:
        return null
      }
    }
  },
  {
    label: 'PRIORITET',
    key: 'relation_priority',
    render: function renderColumn(isPrimary) {
      return isPrimary ? 'Primär' : 'Sekundär'
    }
  }
]

export const getRelationRows = (shapeRelations, tables) => {
  return shapeRelations
    .sort((rel) => (rel.data.isPrimary ? -1 : 1))
    .map((sr) => {
      const leftTable = tables.find((t) => t.id === sr.source)
      const leftTableAttribute = leftTable?.attributes.output_attributes.find(
        (attr) => attr.id === sr.data.leftAttributeId
      )
      const rightTable = tables.find((t) => t.id === sr.target)
      const rightTableAttribute = rightTable?.attributes.output_attributes.find(
        (attr) => attr.id === sr.data.rightAttributeId
      )

      return {
        id: sr.id,
        key: sr.id,
        type: sr.data.type,
        leftTable: leftTable?.attributes.name,
        leftTableAttribute: leftTableAttribute?.attributes?.name,
        rightTable: rightTable?.attributes.name,
        rightTableAttribute: rightTableAttribute?.attributes?.name,
        relation_priority: sr.data.isPrimary
      }
    })
}

const parseLinkTableAttributes = (relation, sourceNode, targetNode) => {
  const relationSourceAttribute = relation.sourceAttributes.find(
    (attr) => attr.id === relation.leftAttributeId
  )
  const sourceTargetAttribute = relation.targetAttributes.find(
    (attr) => attr.id === relation.rightAttributeId
  )

  let sourceAttribute = _.cloneDeep(relationSourceAttribute)
  let targetAttribute = _.cloneDeep(sourceTargetAttribute)

  if (sourceAttribute.name === targetAttribute.name) {
    sourceAttribute.attributes.name = `${sourceNode.data.name} ${sourceAttribute.attributes.name}`
    sourceAttribute.attributes.real_name = `${sourceNode.data.technicalName.toLowerCase()}_${
      sourceAttribute.attributes.real_name
    }`

    targetAttribute.attributes.name = `${targetNode.data.name} ${targetAttribute.attributes.name}`
    targetAttribute.attributes.real_name = `${targetNode.data.technicalName.toLowerCase()}_${
      targetAttribute.attributes.real_name
    }`
  }

  return { sourceAttribute, targetAttribute }
}

export const createLinkTable = (
  relation,
  sourceNode,
  targetNode,
  showObject
) => {
  const { sourceAttribute, targetAttribute } = parseLinkTableAttributes(
    relation,
    sourceNode,
    targetNode
  )

  return {
    type: 'ontology',
    id: ShortId.generate(),
    data: {
      name: `${sourceNode.data.name} - ${targetNode.data.name}`,
      plural: `${sourceNode.data.plural} - ${targetNode.data.plural}`,
      technicalName: `${sourceNode.data.technicalName}_${targetNode.data.technicalName}`,
      primaryKey: undefined,
      locked: true,
      onClick: () => {},
      columns: [
        {
          id: sourceAttribute.id,
          name: sourceAttribute.attributes.name,
          realName: sourceAttribute.attributes.real_name,
          type: sourceAttribute.attributes.type
        },
        {
          id: targetAttribute.id,
          name: targetAttribute.attributes.name,
          realName: targetAttribute.attributes.real_name,
          type: targetAttribute.attributes.type
        }
      ],
      isLinkTable: true,
      ...showObject
    },
    position: {
      x: getMiddle(sourceNode.position.x, targetNode.position.x),
      y: getMiddle(sourceNode.position.y, targetNode.position.y)
    }
  }
}

const getMiddle = (n1, n2) => {
  if (n1 < n2) {
    return n1 + Math.abs(n2 - n1) / 2
  } else {
    return n2 + Math.abs(n1 - n2) / 2
  }
}

// Update the board (edit relation or add relation)
export const updateBoard = (
  nodes,
  edges,
  relations,
  edgeParams,
  onEdgeClick,
  showObject
) => {
  const edgeExists = edges.find((el) => el.id === edgeParams.edgeId)

  let linkTable = undefined
  let linkRelations = undefined

  const edge = {
    id: edgeParams.edgeId,
    animated: true,
    type: 'floating',
    source: edgeParams.source,
    target: edgeParams.target,
    data: {
      ...edgeParams,
      onClick: () => onEdgeClick(edgeParams)
    }
  }

  // If many to many relation, set values
  if (edgeParams.type === 'many_to_many') {
    const sourceNode = nodes.find((t) => t.id === edgeParams.source)
    const targetNode = nodes.find((t) => t.id === edgeParams.target)

    linkTable = createLinkTable(edgeParams, sourceNode, targetNode, showObject)

    linkRelations = [
      {
        id: `${edgeParams.edgeId}-0`,
        animated: true,
        type: 'floating',
        source: edgeParams.source,
        target: linkTable.id,
        data: {
          ...edgeParams,
          leftAttributeId: edgeParams.leftAttributeId,
          rightAttributeId: linkTable.data.columns[0].id,
          type: 'one_to_many',
          onClick: null
        }
      },
      {
        id: `${edgeParams.edgeId}-1`,
        animated: true,
        type: 'floating',
        source: edgeParams.target,
        target: linkTable.id,
        data: {
          ...edgeParams,
          leftAttributeId: edgeParams.rightAttributeId,
          rightAttributeId: linkTable.data.columns[1].id,
          type: 'one_to_many',
          onClick: null
        }
      }
    ]

    edge.data.linkTableId = linkTable.id
  }

  let newEdges
  let newNodes
  let newRelations

  // Relation exists and goes from 1-1 or 1-* to 1-1 or 1-*
  if (edgeExists && edge.data.type !== 'many_to_many') {
    /*
      Attribut ändras eller byter typ

      elements - update edge
      relations.actualRelations - update edge
    */
    newEdges = edges.filter((el) => el.id !== edge.id)
    newEdges.push(edge)

    const actualRelations = relations.actualRelations.filter(
      (el) => el.id !== edge.id
    )

    newRelations = {
      ...relations,
      actualRelations: [...actualRelations, edge]
    }

    // Relation exists and goes from 1-1 or 1-* to *-*
  } else if (edgeExists && edge.data.type === 'many_to_many') {
    /*
        Attribut ändras och byter typ

        elements:
          - remove edge
          - add linkTable och linkRelations
        relations.actualRelations - update edge
        relations.linkRelations - add linkRelations
      */
    newEdges = edges.filter((el) => el.id !== edge.id)
    newEdges.push(...linkRelations)
    newNodes = [...nodes, linkTable]

    const actualRelations = relations.actualRelations.filter(
      (el) => el.id !== edge.id
    )

    newRelations = {
      actualRelations: [...actualRelations, edge],
      linkRelations: [...relations.linkRelations, ...linkRelations]
    }

    // Create new edge with 1-1 or 1-* relation
  } else if (edge.data.type !== 'many_to_many') {
    /*
      elements - add edge
      relations.actualRelations - add edge
    */
    newEdges = [...edges, edge]
    newRelations = {
      ...relations,
      actualRelations: [...relations.actualRelations, edge]
    }
    // Create new edge with *-* relation
  } else if (edge.data.type === 'many_to_many') {
    /*
      elements:
        - add linkTable
        - add linkRelations
      relations.actualRelations - add edge
      relations.linkRelations - add linkRelations
    */
    newNodes = [...nodes, linkTable]
    newEdges = [...edges, ...linkRelations]
    newRelations = {
      actualRelations: [...relations.actualRelations, edge],
      linkRelations: [...relations.linkRelations, ...linkRelations]
    }
  }

  return { newEdges, newNodes, newRelations }
}

const getLinkColumn = (tableId, attributeId, linkTableNode, elements) => {
  const linkTable = elements.find((el) => el.id === tableId)

  const linkAttribute = linkTable.data.columns.find(
    (col) => col.id === attributeId
  )

  const linkTechnicalName = `${linkTable.data.technicalName}_${linkAttribute.realName}`

  return linkTableNode.data.columns.find(
    (c) => c.realName === linkTechnicalName
  )
}

export const CARDINALITY_OPTIONS = [
  {
    label: 'En till en (1-1)',
    value: 'one_to_one'
  },
  {
    label: 'En till flera (1:*)',
    value: 'one_to_many'
  },
  {
    label: 'Flera till flera (*:*)',
    value: 'many_to_many'
  }
]

export const RELATION_PRIORITY_OPTIONS = [
  {
    label: 'Primär',
    value: true
  },
  {
    label: 'Sekundär',
    value: false
  }
]

export const updateRelationsToSecondary = (
  sourceTable,
  targetTable,
  edgeId,
  edges,
  setEdges,
  relations,
  setRelations
) => {
  const updatedEdges = edges.map((elem) => {
    // Check if the element is a node and is a relation affected by the update
    if (
      (sourceTable === elem.source && targetTable === elem.target) ||
      (sourceTable === elem.target && targetTable === elem.source)
    ) {
      // Update current relation to be primary
      if (elem.id === edgeId) {
        return updateEdgeWithIsPrimary(elem, true)
      }

      return updateEdgeWithIsPrimary(elem, false)
    }

    return elem
  })

  const updatedRelations = {
    actualRelations: relations.actualRelations.map((rel) => {
      if (
        (sourceTable === rel.source && targetTable === rel.target) ||
        (sourceTable === rel.target && targetTable === rel.source)
      ) {
        if (rel.id === edgeId) {
          return updateEdgeWithIsPrimary(rel, true)
        }

        return updateEdgeWithIsPrimary(rel, false)
      }

      return rel
    }),
    linkRelations: relations.linkRelations
  }

  setEdges(updatedEdges)
  setRelations(updatedRelations)
}

const updateEdgeWithIsPrimary = (edge, isPrimary) => {
  return {
    ...edge,
    animated: !isPrimary,
    data: {
      ...edge.data,
      isPrimary
    }
  }
}

// Checks if two entites has any primary relations
export const hasPrimaryRelations = (entityId1, entityId2, edges) => {
  return edges.find(
    (el) =>
      ((entityId1 === el.source && entityId2 === el.target) ||
        (entityId1 === el.target && entityId2 === el.source)) &&
      el.data.isPrimary
  )
}

export const getOntologyTables = (currentOntology, DataProductStore) => {
  if (currentOntology === 'custom' && DataProductStore.fetchedCustomOntology) {
    return DataProductStore.customOntology.attributes.tables
  } else if (
    currentOntology === 'standard' &&
    DataProductStore.fetchedStandardOntology
  ) {
    return DataProductStore.standardOntology.attributes.tables
  }

  return []
}

export const getOntologyLinkTableIds = (currentOntology, DataProductStore) => {
  if (currentOntology === 'custom' && DataProductStore.fetchedCustomOntology) {
    return DataProductStore.customOntology.included.shape_relations
      .map((sr) => sr.attributes.link_table_id)
      .filter(Boolean)
  } else if (
    currentOntology === 'standard' &&
    DataProductStore.fetchedStandardOntology
  ) {
    return DataProductStore.standardOntology.included.shape_relations
      .map((sr) => sr.attributes.link_table_id)
      .filter(Boolean)
  }

  return []
}
