import React, { ChangeEvent } from 'react'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import TextField from 'components_new/atoms/TextField'

interface SearchFieldProps {
  helperText?: string
  onClear: () => void
  onSearch: (value: string) => void
  placeholder: string
  size?: 'small' | 'medium'
  sx?: object
  value: string
}

const SearchField = (props: SearchFieldProps) => {
  const { helperText, onClear, onSearch, placeholder, size, sx, value } = props

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value)
  }

  return (
    <>
      <TextField
        InputProps={{
          type: 'text',
          startAdornment: (
            <InputAdornment position="start">
              <Icon name="Search" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value ? (
                <IconButton onClick={onClear}>
                  <Icon name="ClearOutlined" />
                </IconButton>
              ) : null}
            </InputAdornment>
          )
        }}
        helperText={helperText}
        onChange={handleSearch}
        placeholder={placeholder}
        size={size}
        sx={{
          my: 0,
          ...sx
        }}
        value={value}
      />
    </>
  )
}

export default SearchField
