import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/configs`

export interface PutTargetValuesBody {
  data: {
    target_values: {
      attribute_value: string
      value: number | null
    }[]
  }
}

export async function getAll() {
  return axios.get(URL, parseAuthenticationHeader())
}

export async function update(body: {
  data: { [attribute: string]: number | null }
}) {
  return axios.patch(URL, body, parseAuthenticationHeader())
}

export async function putTargetValues(id: string, body: PutTargetValuesBody) {
  return axios.put(`${URL}/targets/${id}`, body, parseAuthenticationHeader())
}
