import React, { useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Form } from 'antd'

import Input from 'components/common/Input'
import CreateModal from 'components/common/CreateModal'
import Table from 'components/common/Table'

import * as ShapeRelationActions from 'redux/actions/Applications/ShapeRelations'
import * as AlertActions from 'redux/actions/Alert'
import * as DataProductActions from 'redux/actions/Applications/DataProducts'
import * as DatasetActions from 'redux/actions/Applications/Datasets'

import Attributes from './Attributes'
import Operations from './Operations'
import Columns from './Columns'

import { arrayToObject, validateForm } from 'helpers/Functions'
import { FORM_VALIDATIONS } from 'helpers/Constants'

import * as Conf from './conf.js'

const ManageDataset = ({
  match,
  history,
  OntologyStore,
  AppsDataProductStore,
  AppsDatasetStore,
  tryGetRelatedTables,
  tryCreateDataset,
  tryUpdateDataset,
  setAlert,
  resetAlert,
  type
}) => {
  const { tableId } = match.params
  const [currentStep, setCurrentStep] = useState(0)
  const splittedPath = match.url.split('/')

  const open =
    splittedPath[splittedPath.length - 1] === 'new' ||
    (splittedPath[splittedPath.length - 2] === 'edit' &&
      splittedPath[splittedPath.length - 3] === 'dataset')

  const tables = OntologyStore.data?.attributes?.tables || []
  const [nameForm] = Form.useForm()
  const [columnForm] = Form.useForm()
  const initialValues = useMemo(
    () =>
      Conf.getInitialValues(
        match,
        AppsDataProductStore,
        AppsDatasetStore,
        tables
      ),
    [tableId]
  )
  const [lockedMaintable, setLockedMaintable] = useState(false)
  const [preview, setPreview] = useState(Conf.INITIAL_PREVIEW)
  const [page, setPage] = useState(1)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [indices, setIndices] = useState({})

  // States needed in <Attributes />
  const [checkedKeys, setCheckedKeys] = useState(
    initialValues?.checkedKeys || []
  )
  const [mainTable, setMainTable] = useState(
    initialValues?.main_table_id || undefined
  )

  // States needed in <Operations />
  const [operations, setOperations] = useState(initialValues?.operations || [])
  const [mapping, setMapping] = useState(
    initialValues ? initialValues.mapping : Conf.getOntologyMapping(tables)
  )

  useEffect(() => {
    if (initialValues) {
      setMapping(initialValues.mapping)
    }
  }, [initialValues])

  const attributes = useMemo(() => {
    const tmp = {}

    if (mainTable && OntologyStore.relations[mainTable]) {
      Conf.parseTreeData(
        mainTable,
        arrayToObject(tables),
        OntologyStore.relations[mainTable]
      ).forEach((dp) => {
        dp.children.forEach((child) => {
          tmp[child.key] = {
            type: child.type,
            outputAttributeId: child.key,
            outputAttributeName: child.title,
            tableId: dp.key,
            tableName: dp.title
          }
        })
      })
    }

    return tmp
  }, [mainTable, OntologyStore.relations[mainTable]])

  const chosenAttributes = useMemo(() => {
    return Conf.getAttributes(
      currentStep > 0,
      checkedKeys,
      attributes,
      operations
    )
  }, [checkedKeys, attributes, operations, currentStep])

  const rawData = {
    attributes,
    checkedKeys,
    nameForm,
    match,
    mainTable,
    operations: currentStep > 0 ? operations : [],
    mapping,
    indices
  }

  useEffect(() => {
    if (open) {
      Conf.updateIndices(
        Conf.getAttributes(true, checkedKeys, attributes, operations),
        indices,
        setIndices
      )
    }
  }, [checkedKeys, operations])

  useEffect(() => {
    if (
      checkedKeys.length > 0 &&
      !isEmpty(attributes) &&
      !isEmpty(chosenAttributes)
    ) {
      Conf.updatePreview(
        page,
        preview.meta.sort,
        rawData,
        setPreview,
        setPreviewLoading
      )
    } else {
      setPreview(Conf.INITIAL_PREVIEW)
    }
  }, [checkedKeys, operations, attributes, chosenAttributes])

  useEffect(() => {
    if (open && initialValues) {
      setCheckedKeys(initialValues.checkedKeys)
      setMainTable(initialValues.main_table_id)
      setOperations(initialValues.operations)

      nameForm?.setFieldsValue({ name: initialValues.name })
    }

    if (match.path.includes('new')) {
      setLockedMaintable(false)
    } else {
      setLockedMaintable(true)
    }
  }, [open])

  useEffect(() => {
    if (Object.keys(attributes).length > 0 && initialValues) {
      Conf.updateIndices(
        Conf.getAttributes(
          true,
          initialValues.checkedKeys,
          attributes,
          initialValues.operations
        ),
        Conf.getCurrentIndices(initialValues, match),
        setIndices
      )
    }
  }, [attributes])

  useEffect(() => {
    // Remove operation if an attribute has been unticked.
    const newOperations = operations.filter((operation) => {
      const attributeRemoved = operation.attributes.find((attribute) => {
        if (
          (!checkedKeys.includes(attribute.outputAttributeId) &&
            !mapping[attribute.outputAttributeId]?.isNewAttribute) ||
          (attribute.condition.rightAttribute &&
            !checkedKeys.includes(attribute.condition.rightAttribute) &&
            !mapping[attribute.condition.rightAttribute]?.isNewAttribute)
        ) {
          return true
        }
      })

      return !attributeRemoved
    })

    setOperations(newOperations)
  }, [checkedKeys])

  const reset = () => {
    setOperations([])
    setMainTable(undefined)
    setCheckedKeys([])
    nameForm?.resetFields()
    history.goBack()
  }

  const currentDatasets =
    AppsDataProductStore.data[match.params.id]?.attributes.tables || []

  return tables ? (
    <CreateModal
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      open={open}
      onClose={() => reset()}
      headerTitle={'Forma dataset'}
      loading={
        AppsDataProductStore.isCreating || AppsDataProductStore.isUpdating
      }
      onSave={async () => {
        try {
          // validate dataset name
          await nameForm?.validateFields()
        } catch (e) {
          return
        }

        const data = Conf.getShapeData(rawData)

        if (type === 'INSIGHT_STUDIO') {
          data.name = `${
            AppsDataProductStore?.data[match.params.id]?.attributes
              .technical_name
          }_dataset`
        }

        if (match.params.tableId) {
          tryUpdateDataset(match.params.tableId, data, () => reset())
        } else {
          tryCreateDataset(data, () => reset())
        }
      }}
      steps={[
        {
          title: 'Välj attribut',
          description:
            'Välj den information som du behöver från din informationsmodell.',
          content: (
            <Attributes
              setMapping={setMapping}
              mapping={mapping}
              tables={tables}
              tryGetRelatedTables={tryGetRelatedTables}
              relations={OntologyStore.relations}
              checkedKeys={checkedKeys}
              setCheckedKeys={(newKeys) => {
                if (!lockedMaintable) {
                  setLockedMaintable(true)
                }

                setCheckedKeys(newKeys)
              }}
              lockedMaintable={lockedMaintable}
              setLockedMaintable={() => {
                if (lockedMaintable) {
                  setAlert({
                    header: 'Ändra entitet att utgå från',
                    content: (
                      <p>
                        Att ändra entitet att utgå från kommer återställa alla
                        valda attribut och tillhörande inställningar.
                      </p>
                    ),
                    onCancel: () => resetAlert(),
                    onOk: () => {
                      setCheckedKeys([])
                      setOperations([])

                      setLockedMaintable(false)

                      resetAlert()
                    },
                    cancelText: 'Avbryt',
                    okText: 'Ändra'
                  })
                } else {
                  setLockedMaintable(true)
                }
              }}
              mainTable={mainTable}
              setMainTable={setMainTable}
            />
          ),
          onNext: () => mainTable && checkedKeys.length > 0
        },
        {
          title: 'Välj operationer',
          description:
            // eslint-disable-next-line max-len
            'Lägg till en eller flera operationer för få ut datan så som du vill.',
          content: (
            <Operations
              operations={operations}
              setOperations={setOperations}
              setMapping={setMapping}
              chosenAttributes={chosenAttributes}
              setAlert={setAlert}
              resetAlert={resetAlert}
            />
          ),
          onNext: () => true
        },
        {
          title: 'Välj alternativ',
          description: 'Välj slutgiltiga alternativ för ditt dataset.',
          content: (
            <Columns
              chosenAttributes={chosenAttributes}
              setMapping={setMapping}
              mapping={mapping}
              formRef={columnForm}
            />
          ),
          valid: false,
          onNext: () => validateForm(columnForm)
        },
        type === Conf.TYPES.API
          ? {
              title: 'Välj alternativ',
              description: 'Välj slutgiltiga alternativ for ditt dataset.',
              content: (
                <Form
                  form={nameForm}
                  layout={'vertical'}
                  initialValues={{
                    name: undefined
                  }}
                >
                  <Form.Item
                    name={'name'}
                    rules={[
                      FORM_VALIDATIONS.REQUIRED,
                      FORM_VALIDATIONS.DUPLICATE(
                        currentDatasets,
                        'name',
                        'Ett dataset med följande namn finns redan.',
                        tableId
                      )
                    ]}
                  >
                    <Input
                      label={'Namnge dataset'}
                      disabled={
                        AppsDataProductStore.isCreating ||
                        AppsDataProductStore.isUpdating
                      }
                    />
                  </Form.Item>
                </Form>
              )
            }
          : undefined
      ].filter(Boolean)}
    >
      <Table
        headers={Conf.getHeaders(
          chosenAttributes,
          mapping,
          currentStep,
          indices,
          setIndices
        )}
        rows={preview.data.map((row, i) => ({ key: i, ...row }))}
        size={'middle'}
        loading={previewLoading}
        parsedFormat
        pagination={{
          position: 'bottomRight',
          pageSize: Conf.PAGE_LIMIT,
          onChange: (p) => {
            Conf.updatePreview(
              p,
              preview.meta.sort,
              rawData,
              setPreview,
              setPreviewLoading
            )
            setPage(p)
          },
          total: preview.meta.count,
          showSizeChanger: false,
          showTotal: (total) => <p>Antal rader: {total}</p>
        }}
        onChange={(pagination, filters, sorter) => {
          const sortOrder = sorter.order === 'ascend' ? '' : '-'
          const sortKey = `${sortOrder}${sorter.columnKey}`

          Conf.updatePreview(
            pagination.current,
            sortKey,
            rawData,
            setPreview,
            setPreviewLoading
          )
        }}
        scroll={{ x: checkedKeys.length * 130 }}
        tableLayout={'auto'}
      />
    </CreateModal>
  ) : null
}

function mapStateToProps({
  OntologyStore,
  AppsDataProductStore,
  AppsDatasetStore
}) {
  return { OntologyStore, AppsDataProductStore, AppsDatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ShapeRelationActions,
      ...AlertActions,
      ...DataProductActions,
      ...DatasetActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageDataset))
