import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import ListTemplate from 'components_new/templates/internal/List'

import * as SIMActions from 'redux/actions/SIM'
import { SIMTable } from 'redux/reducers/SIM'
import { FileExtension } from 'utils/types'
import InternalTemplate from 'components_new/templates/InternalTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  SIMStore: state.SIMStore,
  SnackbarStore: state.SnackbarStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(SIMActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ListPageProps = ConnectedProps<typeof connector>

const ListPage: FC<ListPageProps> = (props: ListPageProps) => {
  const { SIMStore, tryExportTable, tryGetSIMData, SnackbarStore } = props
  const [lists, setLists] = useState<SIMTable[]>([])
  const [exportActive, setExportActive] = useState<boolean>(false)
  const params = useParams<{ id: string }>()
  const id = params.id

  useEffect(() => {
    const { tryGetSIMTables } = props

    if (SIMStore.tables.length === 0) {
      tryGetSIMTables()
    }
  }, [])

  useEffect(() => {
    // Close "download list" snackbar upon success/fail
    if (SnackbarStore.open) {
      setExportActive(false)
    }
  }, [SnackbarStore.open])

  useEffect(() => {
    if (SIMStore.tables.length > 0) {
      const tmpTables = cloneDeep(SIMStore.tables)

      setLists(
        tmpTables.sort((t1, t2) => {
          // Sort so that all available tables to export appears first.
          // Then sort alphabetically

          const boolDiff = Number(t2.has_data) - Number(t1.has_data)

          if (boolDiff !== 0) {
            return boolDiff
          }

          return t1.title.toLowerCase() < t2.title.toLowerCase() ? -1 : 1
        })
      )
    }
  }, [SIMStore.tables])

  const list = lists.find((list) => list.id === params.id)

  return (
    <InternalTemplate>
      <ListTemplate
        exportListAsCsv={(queryString: string) => {
          tryExportTable(
            id,
            list?.plural_title as string,
            FileExtension.CSV,
            queryString
          )
          setExportActive(true)
        }}
        exportListAsExcel={(queryString: string) => {
          tryExportTable(
            id,
            list?.plural_title as string,
            FileExtension.XLSX,
            queryString
          )
          setExportActive(true)
        }}
        fetchData={(queryString: string) => tryGetSIMData(id, queryString)}
        preview={SIMStore.preview}
        list={list}
        exporting={exportActive}
      />
    </InternalTemplate>
  )
}

export default connector(ListPage)
