import {
  Condition,
  FilterConditionFormatBody,
  FilterPatchBody,
  FilterType
} from 'types/GlobalKpiOption'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'
import { translateFilterCondition } from 'utils/enumTranslator'
import { FilterOption } from 'utils/types'

export const getFilterValueOptions = (
  filterOptions: KpiTemplateFilterOptionsBody | null,
  filters: FilterConditionFormatBody[]
) => {
  const filterAttributeOptions = filters[0].attribute_option.options

  const filterValueOptions: { [key: string]: FilterOption } = {}

  if (filterOptions) {
    filterAttributeOptions.forEach((option) => {
      const options = filterOptions[option.id]

      filterValueOptions[option.id] = options
    })
  }

  return filterValueOptions
}

export const MISSING_VALUE = 'Saknar värde'

export const parseSingleFilterLabel = (option: string | null) => {
  if (option === '' || option === null) {
    return MISSING_VALUE
  }

  return option
}

export const parseSingleFilterValue = (option: string | null) => {
  if (option === '') {
    return null
  }

  return option
}

export const parseFilterLabelValue = (option: string | null) => {
  if (option === MISSING_VALUE) {
    return null
  }

  return option
}

export const getFilterValue = (filter: FilterConditionFormatBody) => {
  const condition = filter.condition.selected
  const value = filter.value.selected

  if (
    condition &&
    [Condition.IS_NOT_NULL, Condition.IS_NULL].includes(condition)
  ) {
    return translateFilterCondition[condition].title
  }

  if (value === null) {
    return MISSING_VALUE
  } else if (typeof value === 'object' && value.length > 0) {
    return value.map(parseSingleFilterLabel).join(', ')
  } else if (typeof value === 'number') {
    return value.toString()
  } else {
    return MISSING_VALUE
  }
}

export const getFilterTitle = (filter: FilterConditionFormatBody) => {
  const attribute_option = filter.attribute_option.options.find(
    (option) => filter?.attribute_option.selected === option.id
  )

  return attribute_option?.name
}

export const getFilterBody = (filter: FilterConditionFormatBody) => {
  const numericFilter = filter.from_value && filter.to_value

  if (!numericFilter) return null

  return `${filter.from_value?.selected} - ${filter.to_value?.selected}`
}

/*-- Below is copied from old filter functionality: --*/

const onlyLeftCondition = (condition: Condition | null) =>
  condition && [Condition.IS_NULL, Condition.IS_NOT_NULL].includes(condition)

export const formatUpdateFilterBody = (
  filters: FilterConditionFormatBody[],
  attributeOptionId?: string | null,
  condition?: Condition | null,
  values?: string[],
  numericValue?: number | null,
  fromValue?: string | null,
  toValue?: string | null
) => {
  const formattedFilters: FilterPatchBody[] = []

  filters.forEach((filter) => {
    if (
      filter.condition.selected &&
      onlyLeftCondition(filter.condition.selected)
    ) {
      formattedFilters.push({
        filter_group_id: filter.filter_group_id,
        attribute_option_id: filter.attribute_option.selected as string,
        condition: filter.condition.selected as Condition,
        value: null,
        type: FilterType.ONLY_LEFT
      })
    }

    // Edit a currently set filter
    if (
      attributeOptionId &&
      condition &&
      filter.attribute_option.selected === attributeOptionId &&
      filter.condition.selected === condition
    ) {
      if (
        filter.type.selected === FilterType.FROM_TO &&
        (numericValue || numericValue === 0) &&
        filter.from_value?.selected === fromValue &&
        filter.to_value?.selected === toValue
      ) {
        formattedFilters.push({
          filter_group_id: filter.filter_group_id,
          attribute_option_id: filter.attribute_option.selected as string,
          condition: filter.condition.selected as Condition,
          value: numericValue,
          type: FilterType.FROM_TO,
          from_value: fromValue as string,
          to_value: toValue as string
        })
      } else if (values && values?.length > 0) {
        const selectedFilterValues = filter.value.selected as string[]

        selectedFilterValues.forEach((value) => {
          if (!values.includes(value)) {
            formattedFilters.push({
              filter_group_id: filter.filter_group_id,
              attribute_option_id: filter.attribute_option.selected as string,
              condition: filter.condition.selected as Condition,
              value,
              type: FilterType.INPUT_VALUE
            })
          }
        })
      }

      // Translate filters to patch body
    } else {
      if (filter.type.selected === FilterType.FROM_TO) {
        formattedFilters.push({
          filter_group_id: filter.filter_group_id,
          attribute_option_id: filter.attribute_option.selected as string,
          condition: filter.condition.selected as Condition,
          value: filter.value.selected as number,
          type: FilterType.FROM_TO,
          from_value: filter.from_value?.selected as string,
          to_value: filter.to_value?.selected as string
        })
      } else if (
        filter.value.selected &&
        typeof filter.value.selected === 'object' &&
        filter.value.selected.length > 0
      ) {
        filter.value.selected?.forEach((value) =>
          formattedFilters.push({
            filter_group_id: filter.filter_group_id,
            attribute_option_id: filter.attribute_option.selected as string,
            condition: filter.condition.selected as Condition,
            value,
            type: FilterType.INPUT_VALUE
          })
        )
      }
    }
  })

  return formattedFilters
}
