import React, { ReactNode, useEffect, useRef, useState } from 'react'

import Grid from 'components_new/atoms/Grid'
import Box from 'components_new/atoms/Box'

interface ThumbnailListProps {
  children: ReactNode[]
  firstChild?: ReactNode
  lastChild?: ReactNode
  sx?: object
}

/**
 * The ThumbnailList component is used
 * to display several thumbnails on a
 * responsive way.
 */

const ThumbnailList = (props: ThumbnailListProps) => {
  const { children, firstChild, lastChild, sx } = props

  const ref = useRef<HTMLElement>(null)

  const [scrollTop, setScrollTop] = useState(true)

  useEffect(() => {
    const element = ref.current
    const handleScroll = () => {
      if (element && element.scrollTop === 0) {
        setScrollTop(true)
      } else {
        setScrollTop(false)
      }
    }

    if (element) {
      element.addEventListener('scroll', handleScroll)

      // Clean-up
      return () => {
        element.removeEventListener('scroll', handleScroll)
      }
    }

    return
  }, [])

  return (
    <>
      <Box
        ref={ref}
        sx={{
          width: '100%',
          overflowY: 'auto',
          borderTop: scrollTop ? undefined : '1px solid',
          borderTopColor: 'divider',
          pt: 2,
          ...sx
        }}
      >
        <Grid container spacing={2}>
          {firstChild ? (
            <Grid item xs={6} md={4} lg={3} xl={2}>
              {firstChild}
            </Grid>
          ) : null}
          {children.map((thumbnail, i) => (
            <Grid item xs={6} md={4} lg={3} xl={2} key={i}>
              {thumbnail}
            </Grid>
          ))}
          {lastChild ? (
            <Grid item xs={6} md={4} lg={3} xl={2}>
              {lastChild}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  )
}

export default ThumbnailList
