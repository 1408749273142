import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Form, Row, Col } from 'antd'

import { useNodesState, useEdgesState } from 'reactflow'
import { isMoment } from 'moment'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Input from 'components/common/Input'
import Modal from 'components/common/Modal'
import EditModeBar from 'components/common/EditModeBar'

import DrawingBoard from 'components/containers/DataPlatform/shape/DrawingBoard'

import * as AlertActions from 'redux/actions/Alert'
import * as LockedModeActions from 'redux/actions/LockedMode'
import * as DatasetActions from 'redux/actions/DataPlatform/shapes/v2/Datasets'

import * as Conf from './conf.js'
import { setChangedOntology } from 'helpers/Storage/Ontology'

import Styles from './styles.module.css'
import { STANDARD_VALUE, STANDARD_VALUES } from 'components/common/Node/conf.js'

const Transformation = ({
  match,
  setLockedMode,
  AuthStore,
  tryPutDatasetSchema,
  DatasetStore,
  setAlert,
  resetAlert,
  history
}) => {
  const [editName, setEditName] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [resetBoard, setResetBoard] = useState(false)
  const [addNode, setAddNode] = useState(false)
  const [board, setBoard] = useState(false)

  const table = DatasetStore.data[match.params.tableId]
  const fetched =
    table &&
    table.included &&
    table.included.shape_nodes.length > 0 &&
    DatasetStore.sourcesFetched

  // If the a non Homepal user navigates to the ontology
  // transformation - replace the url with /schema.
  useEffect(() => {
    if (!AuthStore.user.is_homepal_user) {
      history.replace(`/data-platform/ontology/datasets/${table.id}/schema`)
    }
  }, [])

  if (
    !fetched ||
    !table ||
    DatasetStore.currentFetchOne !== match.params.tableId
  ) {
    return null
  }

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <EditModeBar
          onOk={() => {
            setResetBoard(true)
          }}
          editMode={editMode}
          setEditMode={setEditMode}
          setAlert={setAlert}
          resetAlert={resetAlert}
          setLockedMode={setLockedMode}
          onAdd={() => setAddNode(true)}
          useCallbackOnSave
          onSave={(callbackOnOk) => {
            let outputData

            let hasError = false
            let errorMessage = []

            const data = {
              attributes: {
                nodes: nodes
                  .map((elem) => {
                    if (elem.type === 'OUTPUT') {
                      // "Splitta upp" output och map
                      outputData = {
                        primary_key: elem.data.primaryKey,
                        reference_key: elem.data.referenceKey
                      }

                      return {
                        filter_conditions: [],
                        filter_type: null,
                        is_output_node: true,
                        left_attribute: null,
                        left_node: elem.data.leftNode,
                        output_attributes: elem.data.outputAttributes.map(
                          (attr) => ({
                            id: attr.id,
                            type: attr.type,
                            real_name: attr.realName,
                            name: attr.name,
                            left_attribute: attr.leftAttribute,
                            right_attribute: attr.rightAttribute,
                            short_id: attr.shortId,
                            prepend: attr.prepend,
                            sensitive_ref_column_id: attr.sensitiveRefColumnId
                          })
                        ),
                        product_tag: null,
                        ref_table_id: null,
                        right_node: null,
                        right_attribute: null,
                        type: 'MAP',
                        short_id: elem.id,
                        location_x: elem.position.x,
                        location_y: elem.position.y
                      }
                    }

                    let value = elem.data.value

                    if (elem.type === 'CALCULATE') {
                      const calculateValidation = Conf.validateCalculateNode(
                        elem,
                        hasError,
                        errorMessage
                      )

                      hasError = calculateValidation.hasError
                      value = calculateValidation.value
                    } else {
                      hasError = Conf.validateNode(elem, hasError, errorMessage)
                    }

                    if (isMoment(elem.data.value)) {
                      value = elem.data.value.format('YYYY-MM-DD')
                    }

                    return {
                      filter_conditions: elem.data.filterConditions.map(
                        (filter, idx) => {
                          let type = filter.type
                          let rightValue = isMoment(filter.rightValue)
                            ? filter.rightValue.format('YYYY-MM-DD')
                            : filter.rightValue

                          if (STANDARD_VALUES.includes(filter.type)) {
                            type = STANDARD_VALUE
                            rightValue = filter.type
                          }

                          return {
                            condition: filter.condition,
                            left_attribute: filter.leftAttribute,
                            right_attribute: filter.rightAttribute,
                            right_value: rightValue,
                            type,
                            conjunctive_operator: filter.conjunctiveOperator,
                            index: idx
                          }
                        }
                      ),
                      is_output_node: elem.data.isOutputNode,
                      left_attribute: elem.data.leftAttribute,
                      left_node: elem.data.leftNode,
                      group_by_attribute: elem.data.groupByAttribute,
                      output_attributes: elem.data.outputAttributes.map(
                        (attr) => ({
                          type: attr.type,
                          real_name: attr.realName,
                          function: attr.function,
                          name: attr.name,
                          left_attribute: attr.leftAttribute,
                          right_attribute: attr.rightAttribute,
                          short_id: attr.shortId,
                          prepend: attr.prepend,
                          delimiter: attr.delimiter,
                          sensitive_ref_column_id: attr.sensitiveRefColumnId
                        })
                      ),
                      product_tag: elem.data.productTag,
                      ref_table_id: elem.data.refTableId,
                      right_node: elem.data.rightNode,
                      right_attribute: elem.data.rightAttribute,
                      new_attribute: elem.data.newAttribute,
                      // check for moment date object
                      value,
                      type: elem.type,
                      unit: elem.data.unit,
                      short_id: elem.id,
                      location_x: elem.position.x,
                      location_y: elem.position.y
                    }
                  })
                  .filter(Boolean)
              }
            }

            data.attributes.primary_key = outputData.primary_key
            data.attributes.reference_key = outputData.reference_key

            if (!hasError) {
              tryPutDatasetSchema(match.params.tableId, {
                data
              })

              if (table.attributes.is_ontology) {
                setChangedOntology()
              }

              callbackOnOk()
            } else {
              setAlert({
                header: 'Transformering felaktig',
                content: (
                  <div>
                    Det har blivit något fel i transformeringen. Följande fel
                    hittades:
                    <ul>
                      {errorMessage.map((msg, i) => (
                        <li key={`err-msg-${i}`}>{msg}</li>
                      ))}
                    </ul>
                    Åtgärda felen och spara igen.
                  </div>
                ),
                onOk: () => resetAlert(),
                okText: 'OK'
              })
            }
          }}
          ignoreId="link-container"
        />

        {fetched ? (
          <Box sx={{ flex: 1 }}>
            <DrawingBoard
              editMode={editMode}
              nodes={nodes}
              edges={edges}
              setNodes={setNodes}
              setEdges={setEdges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              resetBoard={resetBoard}
              setResetBoard={setResetBoard}
              addNode={addNode}
              setAddNode={setAddNode}
              board={board}
              setBoard={setBoard}
            />
          </Box>
        ) : null}
      </Box>
      <EditName
        open={editName}
        onUpdate={() => {
          setEditName(false)
        }}
        DatasetStore={DatasetStore}
        table={table}
        closeModal={() => setEditName(false)}
      />
    </>
  )
}

const EditName = ({ open, closeModal, onUpdate, table, DatasetStore }) => {
  const initialValues = { name: table.attributes.name }

  return (
    <Modal
      visible={open}
      onClose={() => closeModal()}
      header={'Uppdatera namn'}
    >
      <Form
        layout={'vertical'}
        onFinish={(formAttr) => onUpdate(formAttr)}
        initialValues={initialValues}
      >
        <Form.Item
          label={'Namn'}
          rules={[
            { required: true, message: 'Var god fyll i ett namn' },
            () => ({
              validator(rule, value) {
                if (
                  Object.values(DatasetStore.data).find(
                    (item) =>
                      item.attributes.name.toLowerCase() ===
                        value.toLowerCase() && item.id !== table.id
                  )
                ) {
                  return Promise.reject(
                    new Error(
                      `Ett dataset med samma namn '${value}' existerar redan`
                    )
                  )
                }

                return Promise.resolve()
              }
            })
          ]}
          name={'name'}
        >
          <Input placeholder={'Skriv namn...'} />
        </Form.Item>
        <Row className={Styles['button-row']} gutter={8} align={'end'}>
          <Col>
            <Button onClick={() => closeModal()} variant="text">
              Avbryt
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="submit">Spara</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

function mapStateToProps({ AuthStore, DatasetStore }) {
  return {
    AuthStore,
    DatasetStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertActions,
      ...LockedModeActions,
      ...DatasetActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Transformation))
