import React, { useState, useRef, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import Badge from 'components_new/atoms/Badge'
import Button from 'components_new/atoms/Button'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Pagination from 'components_new/atoms/Pagination'
import Stack from 'components_new/atoms/Stack'
import Paper from 'components_new/atoms/Paper'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import LoadingSection from 'components_new/molecules/LoadingSection'

import FilterCascadeMenu from 'components_new/organisms/FilterCascadeMenu'

import { SIMTable, Preview } from 'redux/reducers/SIM'
import { Condition, FilterType } from 'types/GlobalKpiOption'

import { FilterBody, Filters } from 'utils/types'

import {
  parseQueryParams,
  parseQueryParamsExport,
  getOffset,
  Header
} from './utils'

interface ListTemplate {
  preview: Preview | null
  exportListAsCsv: (pluralTitle: string) => void
  exportListAsExcel: (pluralTitle: string) => void
  fetchData: (queryString: string) => void
  list?: SIMTable
  exporting: boolean
}

const parseValue = (val: any) => {
  if (val === true) {
    return 'Ja'
  }
  if (val === false) {
    return 'Nej'
  }

  return val
}

const ListTemplate = (props: ListTemplate) => {
  const { preview, list, fetchData } = props
  const [page, setPage] = useState<number>(1)
  const [filters, setFilters] = useState<Filters>({})
  const [headers, setHeaders] = useState<Header[]>([])
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const offset = getOffset(page)

  useEffect(() => {
    setPage(1)
    fetchData(parseQueryParams(filters, 0))
  }, [filters])

  useEffect(() => {
    if (list && list.attributes.length > 0 && headers.length === 0) {
      setHeaders(
        list.attributes.map((attribute) => ({
          id: attribute.id,
          label: attribute.name,
          hidden: false
        }))
      )
    }
  }, [list])

  useEffect(() => {
    if (preview && !(offset in preview.data)) {
      fetchData(parseQueryParams(filters, offset))
    }
  }, [offset])

  if (!list || !preview) {
    return <LoadingSection titles={['Samlar in data']} loading />
  }

  const data = preview.data[offset]

  return (
    <Box sx={{ width: '100%', height: '85%' }}>
      <Paper>
        <Stack
          sx={{ p: '1rem' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack alignItems="center">
            <Text variant={'h5'}>Förhandsgranskning</Text>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <Button
              startIcon={<Icon name="FileDownloadOutlined" />}
              onClick={() =>
                props.exportListAsExcel(
                  parseQueryParamsExport(filters, headers)
                )
              }
              loading={props.exporting}
              loadingTitle={'Bearbeta data...'}
              variant="text"
            >
              Exportera som .xlsx
            </Button>
            <Button
              startIcon={<Icon name="FileDownloadOutlined" />}
              onClick={() =>
                props.exportListAsCsv(parseQueryParamsExport(filters, headers))
              }
              loading={props.exporting}
              loadingTitle={'Bearbeta data...'}
              variant="text"
            >
              Exportera som .csv
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <Table aria-label="simple table" size={'small'}>
            <TableHead
              ref={(el: any) =>
                el?.clientHeight ? setHeaderHeight(el.clientHeight) : {}
              }
            >
              <TableRow>
                {headers.map((header) => (
                  <ListTemplateHeader
                    key={header.id}
                    hidden={header.hidden}
                    label={header.label}
                    setHidden={(value) => {
                      setHeaders((currentHeaders) =>
                        currentHeaders.map((h) =>
                          h.id === header.id ? { ...h, hidden: value } : h
                        )
                      )
                    }}
                    filter={filters[header.id] || null}
                    setFilter={(newFilter) => {
                      const newFilters = cloneDeep(filters)

                      if (!newFilter) {
                        delete newFilters[header.id]
                      } else {
                        newFilters[header.id] = {
                          attributeId: header.id,
                          ...newFilter
                        }
                      }
                      setFilters(newFilters)
                    }}
                  />
                ))}
              </TableRow>
            </TableHead>
            {!preview.fetching && data ? (
              <TableBody>
                {data.map((row, i) => (
                  <TableRow key={i}>
                    {headers.map((header, i) => (
                      <TableCell scope="row" key={i}>
                        <Text
                          color={
                            header.hidden ? 'text.disabled' : 'text.primary'
                          }
                          variant="body2"
                        >
                          {parseValue(row[header.label])}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <>
                <TableBody
                  sx={{
                    height: 120
                  }}
                ></TableBody>
                {headerHeight ? (
                  <TableBody
                    sx={{
                      position: 'absolute',
                      left: '48%',
                      top: headerHeight + 100
                    }}
                  >
                    <TableRow>
                      <TableCell scope="row">
                        <LoadingSection titles={['Samlar in data']} loading />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        count={Math.ceil(preview.count / 25)}
        onChange={(_, newPage) => setPage(newPage)}
        page={offset / 25 + 1}
        variant="outlined"
        shape="rounded"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 2
        }}
      />
    </Box>
  )
}

interface ListTemplateHeaderProps {
  label: string
  hidden: boolean
  setHidden: (hidden: boolean) => void
  filter: FilterBody | null
  setFilter: (
    body: {
      condition: Condition
      value: string | null | string[]
      type: FilterType
    } | null
  ) => void
}

const ListTemplateHeader = (props: ListTemplateHeaderProps) => {
  const { hidden, setHidden, filter } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const filterCascadeMenuRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableCell align="left">
      <Stack alignItems="center" spacing={0.5}>
        {hidden ? (
          <Tooltip title={'Exkluderas i export'}>
            <Icon fontSize="small" name="VisibilityOff" color="disabled" />
          </Tooltip>
        ) : null}

        <Text
          color={hidden ? 'text.disabled' : 'text.primary'}
          variant="body2"
          fontWeight="600"
        >
          <Badge color="primary" variant="dot" invisible={!filter}>
            {props.label}
          </Badge>
        </Text>

        <IconButton onClick={handleClick}>
          <Icon fontSize="small" name="MoreVert" />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        onClose={handleClose}
        onMouseDown={(e) => e.stopPropagation()}
        open={open}
      >
        <MenuItem
          onClick={() => setFilterOpen(true)}
          disabled={false}
          ref={filterCascadeMenuRef}
        >
          <ListItemIcon>
            <Badge color="primary" variant="dot" invisible={!filter}>
              <Icon name="FilterAltOutlined" />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Filtrera" />
          <ListItemIcon position="end">
            <Icon name="ChevronRightOutlined" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => setHidden(!hidden)} disabled={false}>
          <ListItemIcon>
            <Icon name={hidden ? 'Visibility' : 'VisibilityOff'} />
          </ListItemIcon>
          <ListItemText
            primary={hidden ? 'Inkludera i export' : 'Exkludera i export'}
          />
        </MenuItem>
        <FilterCascadeMenu
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          anchorEl={filterCascadeMenuRef.current}
          filterConditionOptions={[
            Condition.IS_NULL,
            Condition.IS_NOT_NULL,
            Condition.EQ,
            Condition.NE
          ]}
          filter={props.filter}
          setFilter={(f) => props.setFilter(f)}
        />
      </Menu>
    </TableCell>
  )
}

export default ListTemplate
