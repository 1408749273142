import React from 'react'

import {
  KpiStatus,
  KpiTemplate,
  KpiTemplateType
} from 'types/GlobalKpiTemplates'
import { Customer } from 'types/GlobalCustomer'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import SubKpisList from '../SubKpisList'

import About from './About'
import CreatedBy from './CreatedBy'
import Example from './Example'

import Status from '../Status'

import { translateCategory } from 'utils/enumTranslator'

interface DetailsViewProps {
  customer: Customer | null
  kpi: KpiTemplate
  onCreate: (kpiId: string | null, performKpiId?: string) => void
}

const DetailsView = (props: DetailsViewProps) => {
  const { customer, kpi, onCreate } = props

  const isCustom = kpi.type === KpiTemplateType.CUSTOM
  const hasDemo = !isCustom && kpi.demo_url

  const status = kpi.status as KpiStatus

  const disabled =
    status &&
    (!status.modelled ||
      !status.available_in_license ||
      status.required_target_missing)

  return (
    <>
      <Box sx={{ display: 'flex', gap: 4, p: 2 }}>
        <Box sx={{ flex: 1 }}>
          <Text variant="h4">{kpi.title}</Text>
          {kpi.sub_kpis.length === 0 ? (
            <Box sx={{ mt: 4 }}>
              <Status kpi={kpi} variant="full" />
            </Box>
          ) : null}
          <Box sx={{ my: 4 }}>
            {kpi.sub_kpis.length > 0 ? (
              <SubKpisList
                kpiTitle={kpi.title}
                onCreate={(performKpiId) => onCreate(kpi.id, performKpiId)}
                subKpis={kpi.sub_kpis}
              />
            ) : (
              <Button
                disabled={disabled}
                fullWidth={true}
                onClick={() => onCreate(kpi.id, undefined)}
                size="gigantic"
              >
                Lägg till
              </Button>
            )}
          </Box>
          <About kpi={kpi} />
        </Box>
        <Box
          sx={{
            bgcolor: 'action.hover',
            display: 'flex',
            flexDirection: 'column',
            flex: hasDemo ? 1 : '0 320px',
            p: 2
          }}
        >
          <Box sx={{ flex: 1 }}>
            {hasDemo ? <Example kpi={kpi} /> : null}
            <Box sx={{ maxWidth: hasDemo ? undefined : 320 }}>
              <CreatedBy customer={customer} kpi={kpi} />
              <Divider sx={{ my: 4 }} />
              <Box sx={{ mb: 2 }}>
                <Chip label={translateCategory[kpi.category].title} />
                <Chip label={kpi.subcategory} sx={{ ml: 1 }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DetailsView
