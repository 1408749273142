import React, { useEffect, useState } from 'react'

import {
  ElementType,
  InnerWidgetPatchBody,
  WidgetObject
} from 'types/GlobalWidget'
import { KpiOptionObject } from 'types/GlobalKpiOption'

import { getIconName } from '../../utils'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import DndList from 'components_new/molecules/DndList'
import DndListItem from 'components_new/molecules/DndList/DndListItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import DragItem from './DragItem'

import { StandardizedElement, getElements } from './utils'

interface EditOrderDialogProps {
  onClose: () => void
  open: boolean
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const EditOrderDialog = (props: EditOrderDialogProps) => {
  const { onClose, open, updateWidget, widget } = props

  const mainKpi = widget.settings.kpi_options.find(
    (kpi) => kpi.index === 0
  ) as KpiOptionObject

  const [tempElements, setTempElements] = useState<StandardizedElement[]>([])

  useEffect(() => {
    setTempElements(getElements(widget))
  }, [widget.settings.tags, widget.settings.kpi_options])

  const handleSubmit = () => {
    updateWidget(widget.id, {
      element_order: tempElements.map((elem) => ({
        id: elem.id,
        type: elem.type
      }))
    })

    onClose()
  }

  return (
    <SimpleDialog
      actions={
        <>
          <Button onClick={onClose} variant="text">
            Avbryt
          </Button>
          <Button onClick={handleSubmit}>Spara</Button>
        </>
      }
      open={open}
      onClose={onClose}
      title={'Redigera ordning'}
      sx={{ zIndex: 1302 }}
    >
      <Box>
        <DndListItem
          disabled={true}
          sx={{
            mb: 1,
            p: 1
          }}
          variant="outlined"
        >
          <DragItem
            iconName={getIconName(mainKpi.index, widget)}
            index={mainKpi.index}
            showType={true}
            title={mainKpi.title}
            type={ElementType.KPI}
            hidden={mainKpi.hidden}
          />
        </DndListItem>
        <DndList
          handleDragEnd={(newOrder) => {
            setTempElements((prevState) => {
              return newOrder.map((id, index) => {
                const element = prevState.find(
                  (item) => item.id === id
                ) as StandardizedElement

                return {
                  ...element,
                  index
                }
              })
            })
          }}
          items={tempElements.map((item) => {
            return {
              id: item.id,
              content: (
                <DragItem
                  iconName={getIconName(item.index + 1, widget)}
                  index={item.index + 1}
                  showType={true}
                  title={item.title}
                  type={item.type}
                  hidden={item.hidden}
                />
              ),
              containerSx: {
                mb: 1,
                p: 1
              }
            }
          })}
        />
      </Box>
    </SimpleDialog>
  )
}

export default EditOrderDialog
