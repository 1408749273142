import { createAction, createReducer } from '@reduxjs/toolkit'
import Tracker from '@openreplay/tracker'

import { signOut } from 'components/common/Auth/AuthModule'

import { AuthenticationState } from 'types/GlobalAuthentication'

import * as Types from 'redux/Types'

// Initial state
const INITIAL_STATE: AuthenticationState = {
  isAuthenticated: false,
  customer: null,
  user: null,
  isDemo: false,
  validating: false,
  azureAd: {
    fetched: false,
    metadataUrl: null,
    domains: []
  },
  updatingUser: false
}

// Actions
const validateUser = createAction<{ data: any[] }>(Types.VALIDATE_USER)
const signedInSuccess = createAction<{ data: any[] }>(Types.SET_SIGNED_IN)
const updateSelf = createAction<{ data: any[] }>(Types.UPDATE_SELF)
const updateSelfSuccess = createAction<{ data: any[] }>(
  Types.UPDATE_SELF_SUCCESS
)
const updateSelfFailed = createAction(Types.UPDATE_SELF_FAILED)
const getAzureAdSuccess = createAction<{ data: any[] }>(
  Types.GET_AZURE_AD_SUCCESS
)
const putAzureAdSuccess = createAction<{ data: any[] }>(
  Types.PUT_AZURE_AD_SUCCESS
)
const deleteAzureAdSuccess = createAction<{ data: any[] }>(
  Types.DELETE_AZURE_AD_SUCCESS
)
const signedOutSuccess = createAction(Types.SIGN_OUT)

// Move to seperate store in next PR.
const deleteDashboardGroupSuccess = createAction<{ data: any[] }>(
  Types.DELETE_DASHBOARD_GROUP_SUCCESS
)

// Reducer
const AuthenticationReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(validateUser, (state: AuthenticationState) => {
      return {
        ...state,
        validating: true
      }
    })
    .addCase(signedInSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      if (
        !payload.user.is_homepal_user &&
        process.env.NODE_ENV === 'production'
      ) {
        // Safety checks for tracker.
        try {
          const tracker = new Tracker({
            projectKey: 'kBLqF4m0p0GhezlJI8nk'
          })

          tracker.start()

          tracker.setUserID(payload.user.id)
        } catch (e) {
          console.log('Tracker error', e)
        }
      }

      return {
        ...state,
        customer: payload.customer,
        user: payload.user,
        isAuthenticated: true,
        isDemo: payload.is_demo,
        validating: false
      }
    })
    .addCase(updateSelf, (state: AuthenticationState) => {
      return {
        ...state,
        updatingUser: true
      }
    })
    .addCase(updateSelfSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        user: { ...state.user, ...payload.attributes },
        updatingUser: false
      }
    })
    .addCase(updateSelfFailed, (state: AuthenticationState) => {
      return {
        ...state,
        updatingUser: false
      }
    })
    .addCase(getAzureAdSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        azureAd: {
          fetched: true,
          metadataUrl: payload.attributes.metadata_url,
          domains: payload.attributes.domains
        }
      }
    })
    .addCase(putAzureAdSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        azureAd: {
          fetched: true,
          metadataUrl: payload.attributes.metadata_url,
          domains: payload.attributes.domains
        }
      }
    })
    .addCase(deleteAzureAdSuccess, (state: AuthenticationState) => {
      return {
        ...state,
        azureAd: INITIAL_STATE.azureAd
      }
    })
    .addCase(signedOutSuccess, () => {
      signOut()

      return INITIAL_STATE
    })

    // Move to seperate store in next PR.
    .addCase(deleteDashboardGroupSuccess, (state: AuthenticationState) => {
      return {
        ...state,
        customer: {
          ...state.customer,
          number_of_dashboards_in_use: state.customer
            ? state.customer.number_of_dashboards_in_use - 1
            : 0
        }
      }
    })
    .addDefaultCase((state: AuthenticationState) => state)
})

export default AuthenticationReducer
