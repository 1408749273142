import React from 'react'
import ShortId from 'shortid'

import Data from 'components/containers/DataPlatform/build/Specific/Data'
import Classification from 'components/containers/DataPlatform/build/Specific/Classification'
import BlockDeleteContainer from 'components/common/BlockDeleteContainer'

import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'

export const PAGE_HEADER_TOGGLE = (history, id) => {
  return [
    {
      label: 'Data',
      value: 'data',
      onClick: () => {
        history.push(`/data-platform/build/registers/${id}/data`)
      }
    },
    {
      label: 'Klassificering',
      value: 'classification',
      onClick: () => {
        history.push(`/data-platform/build/registers/${id}/classification`)
      }
    }
  ]
}

export const PARSE_CONTENT = (currrentPage) => {
  switch (currrentPage) {
  case 'data':
    return <Data />
  case 'classification':
    return <Classification />
  default:
    return 'Empty'
  }
}

export const INFORMATION_HEADER_ITEMS = (register, createdBy) => [
  {
    header: 'typ',
    icon: (
      <Chip icon={<Icon name="TableView" />} label="Register" size="small" />
    ),
    key: ShortId.generate()
  },
  {
    header: 'senaste uppdatering',
    description: register.last_changed
      ? register.last_changed.replace('T', ' ').split('.')[0]
      : '-',
    key: ShortId.generate()
  },
  {
    header: 'skapades',
    description: register.created_at.split('T')[0],
    key: ShortId.generate()
  },
  {
    header: 'skapades av',
    description: createdBy,
    key: ShortId.generate()
  }
]

export const ACTION_ITEMS = (
  setAlert,
  resetAlert,
  shapes,
  header,
  match,
  tryDeleteCmdbTable,
  history
) => [
  {
    header: 'register',
    key: 'dropdown-actions-2',
    items: [
      {
        label: 'Ta bort register',
        onClick: () => {
          if (shapes && shapes.length !== 0) {
            setAlert({
              isOpen: true,
              header: 'Register används',
              okText: 'OK',
              onOk: () => resetAlert(),
              content: (
                <BlockDeleteContainer
                  deleteType={'Registret'}
                  dependencyType={'modellerna'}
                  names={shapes.map((item) => item.attributes.name)}
                />
              )
            })
          } else {
            setAlert({
              header: 'Ta bort register',
              content: `Är du säker på att du vill ta bort ${header}?`,
              okText: 'Ta bort',
              cancelText: 'Avbryt',
              onOk: () => {
                tryDeleteCmdbTable(match.params.cmdbId)
                resetAlert()
                history.replace('/data-platform/build/registers')
              }
            })
          }
        },
        remove: true
      }
    ]
  }
]
