import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SectionHeader from 'components_new/molecules/SectionHeader'

import GeneralSettingsSection from 'components_new/organisms/GeneralSettingsSection'
import TargetSection from 'components_new/organisms/TargetSection'

import { Category } from 'redux/reducers/Dashboards'
import { General, Target, TargetValue } from 'types/GlobalConfig'

import { translateCategory } from 'utils/enumTranslator'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

interface ConfigTemplateProps {
  sections: TargetSection[]
  onUpdate: (id: string, values: TargetValue[]) => void
  onUpdateGeneral: (
    body: { [attribute: string]: number | null },
    category: Category
  ) => void
}

export interface TargetSection {
  category: Category
  targets: { [id: string]: Target }
  generals: { [attribute: string]: General }
}

const ConfigTemplate = (props: ConfigTemplateProps) => {
  const { onUpdate, onUpdateGeneral, sections } = props

  return (
    <>
      {sections
        .sort((a, b) =>
          sortAlphabeticalAsc(
            translateCategory[a.category]?.title,
            translateCategory[b.category]?.title
          )
        )
        .map((section, i) => {
          const targets = Object.values(section.targets)

          return (
            <CollapsibleSection
              key={i}
              title={translateCategory[section.category].title}
              sx={{ flexGrow: 1, maxWidth: 'section' }}
              icon={<Icon name={translateCategory[section.category].icon} />}
            >
              <Box sx={{ py: 2 }}>
                {Object.keys(section.generals).length > 0 ? (
                  <Box sx={{ pb: 2 }}>
                    <SectionHeader size="medium" title="Generella" />
                    <Box sx={{ maxWidth: 'content' }}>
                      <Text variant="body2" color="text.secondary" gutterBottom>
                        Sätt inställningar som berör{' '}
                        {translateCategory[section.category].title}.
                      </Text>
                    </Box>
                    <GeneralSettingsSection
                      settings={section.generals}
                      onUpdate={(body) =>
                        onUpdateGeneral(body, section.category)
                      }
                    />
                  </Box>
                ) : null}
                {targets.length > 0 ? (
                  <>
                    <SectionHeader size="medium" title="Nyckeltal" />
                    <Box sx={{ pb: 2, maxWidth: 'content' }}>
                      <Text variant="body2" color="text.secondary" gutterBottom>
                        För att kunna använda nedanstående nyckeltal som berör{' '}
                        {translateCategory[section.category].title} behöver du
                        konfigurera dess inställningar nedan.
                      </Text>
                    </Box>
                    {targets
                      .sort((a, b) => sortAlphabeticalAsc(a.title, b.title))
                      .map((target, i) => (
                        <TargetSection
                          title={target.title}
                          description={target.description}
                          condition={target.condition}
                          unit={target.unit}
                          attributeValues={target.attribute_values}
                          attribute={target.attribute}
                          key={i}
                          onUpdate={(values) => onUpdate(target.id, values)}
                        />
                      ))}
                  </>
                ) : null}
              </Box>
            </CollapsibleSection>
          )
        })}
    </>
  )
}

export default ConfigTemplate
