import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
  case Types.GET_QUALITY_OVERVIEW_SUCCESS:
    return {
      ...state,
      data: formatResponse(payload),
      fetched: true
    }
  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}

const formatResponse = (payload) => {
  const response = {}

  payload.forEach((attribute) => {
    response[attribute.attributes.table_id] = {
      ...response[attribute.attributes.table_id],
      [attribute.attributes.attribute_id]: {
        valid: attribute.attributes.valid,
        invalid: attribute.attributes.invalid
      }
    }
  })

  return response
}
