import * as Functions from 'helpers/Functions'
import * as Types from 'redux/Types'

const INITIAL_STATE = {
  data: {},
  fetched: false
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  let data = {}

  switch (type) {
  case Types.CREATE_USER_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.GET_ALL_USERS_SUCCESS:
    return {
      ...state,
      data: Functions.arrayToObject(payload),
      fetched: true
    }

  case Types.UPDATE_USER_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.UPDATE_SELF_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.DELETE_USERS_SUCCESS:
    payload.map((id) => delete state.data[id])

    return {
      ...state,
      data: state.data
    }

  case Types.CREATE_ROLE_SUCCESS:
    return payload.accountId
      ? {
          ...state,
          data: {
            ...state.data,
            [payload.accountId]: {
              ...state.data[payload.accountId],
              included: {
                roles: [
                  ...state.data[payload.accountId].included.roles,
                  payload.data
                ]
              }
            }
          }
        }
      : state

  case Types.PUT_ROLES_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload
      }
    }

  case Types.UPDATE_ROLE_SUCCESS:
    data = Object.values(state.data).map((user) => {
      user.included.roles = user.included.roles.map((r) =>
        r.id === payload.id ? payload : r
      )

      return user
    })

    return { ...state, data: Functions.arrayToObject(data) }

  case Types.DELETE_ROLE_SUCCESS:
    data = Object.values(state.data).map((user) => {
      user.included.roles = user.included.roles.filter(
        (r) => r.id !== payload
      )

      return user
    })

    return { ...state, data: Functions.arrayToObject(data) }

  case Types.DELETE_MANY_ROLES_SUCCESS:
    data = Object.values(state.data).map((user) => {
      user.included.roles = user.included.roles.filter(
        (r) => !payload.includes(r.id)
      )

      return user
    })

    return { ...state, data: Functions.arrayToObject(data) }

  case Types.SIGN_OUT:
    return {
      ...INITIAL_STATE
    }

  default:
    return state
  }
}
