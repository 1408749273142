import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as DashboardTemplateActions from 'redux/actions/DashboardTemplates'

import LoadingSection from 'components_new/molecules/LoadingSection'

import Templates from 'components_new/templates/internal/Templates'

import { useHistory } from 'react-router-dom'
import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import InternalTemplate from 'components_new/templates/InternalTemplate'
import { SortByMethod } from 'components_new/organisms/Finder/utils'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPITemplateStore: state.KPITemplateStore,
  TemplateStore: state.TemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...KPITemplateActions,
      ...DashboardTemplateActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
type DashboardPageProps = ConnectedProps<typeof connector>

const TemplatesPage: FC<DashboardPageProps> = (props: DashboardPageProps) => {
  const history = useHistory()

  const { AuthStore, tryCreateDashboardTemplate, TemplateStore } = props

  useEffect(() => {
    const {
      KPITemplateStore,
      tryGetAllDashboardTemplates,
      tryGetAllFilterOptions,
      tryGetAllKPIs
    } = props

    if (!TemplateStore.fetched) {
      tryGetAllDashboardTemplates()
    }

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      tryGetAllKPIs()
    }
    if (
      !KPITemplateStore.fetchedFilterOptions &&
      !KPITemplateStore.fetchingFilterOptions
    ) {
      tryGetAllFilterOptions()
    }
  }, [])

  // LocalStorage variables
  const [sortBy, setSortByMethod] = useState<SortByMethod>(
    SortByMethod.LAST_VIEWED
  )

  useEffect(() => {
    // List templates
    const listTemplates = localStorage.getItem('listTemplates')

    if (listTemplates) {
      const listTemplatesObject = JSON.parse(listTemplates)

      setSortByMethod(listTemplatesObject.sortBy)
    } else {
      localStorage.setItem(
        'listTemplates',
        JSON.stringify({ sortBy: SortByMethod.LAST_VIEWED as string })
      )
    }
  }, [])

  const groups = Object.values(TemplateStore.groups) || []

  return (
    <InternalTemplate>
      {TemplateStore.fetched ? (
        <Templates
          AuthStore={AuthStore}
          createTemplate={() =>
            tryCreateDashboardTemplate({ data: {} }, (id) =>
              history.push(`/templates/${id}`)
            )
          }
          sortBy={sortBy}
          templates={groups}
          updateSortByMethod={(value) => {
            localStorage.setItem(
              'listTemplates',
              JSON.stringify({ sortBy: value as string })
            )
            setSortByMethod(value)
          }}
        />
      ) : (
        <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
      )}
    </InternalTemplate>
  )
}

export default connector(TemplatesPage)
