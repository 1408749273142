import React from 'react'

import { Steps } from 'antd'

import './styles.css'

const { Step } = Steps

// USAGE
// <Timeline
//   steps={[
//     {
//       title: (
//         <Tag text={'Lyckad'} className={'success'} icon={faCheck} />
//       ),
//       description: 'Data uppdaterad 2021-09-03 06:00'
//     },
//     {
//       title: (
//         <Tag text={'Lyckad'} className={'success'} icon={faCheck} />
//       ),
//       description: 'Data uppdaterad 2021-09-03 06:00'
//     }
//   ]}
// />
const Timeline = ({ steps }) => {
  return (
    <Steps progressDot current={steps.length - 1} direction="vertical">
      {steps.map((step, index) => (
        <Step
          title={step.title}
          description={step.description}
          key={`timeline-step-${index}`}
        />
      ))}
    </Steps>
  )
}

export default Timeline
