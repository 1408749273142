import React from 'react'

import Grid from 'components_new/atoms/Grid'
import Text from 'components_new/atoms/Text'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  ShowPerEnum,
  translateShowPerEnum
} from 'types/GlobalKpiOption'

interface BenchmarkSectionProps {
  allowBenchmarking: boolean
  kpi: KpiOptionObject
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
}

const BenchmarkSection = (props: BenchmarkSectionProps) => {
  const { allowBenchmarking, kpi, updateKpiOption } = props

  if (!allowBenchmarking || kpi.active_show_per.options.length === 0)
    return null

  const handleToggle = (option: ShowPerEnum) => {
    if (kpi.active_show_per.selected === option) {
      updateKpiOption({
        data: {
          active_show_per: null
        }
      })
    } else {
      updateKpiOption({
        data: {
          active_show_per: option
        }
      })
    }
  }

  return (
    <>
      <SettingsGroup title="Benchmarking">
        <SettingsItem>
          <Grid container={true} columns={5}>
            {kpi.active_show_per.options.map((option) => {
              const selected = option === kpi.active_show_per.selected

              return (
                <Grid item={true} key={option} xs={1}>
                  <SettingsToggleOption
                    iconName={translateShowPerEnum[option].iconName}
                    onClick={() => handleToggle(option)}
                    selected={selected}
                    title={translateShowPerEnum[option].abbreviation}
                    tooltip={
                      <>
                        <Text
                          color="text.secondary"
                          component="span"
                          fontSize="inherit"
                        >
                          {kpi.title} per{' '}
                        </Text>
                        <Text
                          component="span"
                          fontSize="inherit"
                          fontWeight="bold"
                        >
                          {translateShowPerEnum[option].label}
                        </Text>
                      </>
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        </SettingsItem>
      </SettingsGroup>
    </>
  )
}

export default BenchmarkSection
