import * as Api from 'redux/api/DataPlatform/shapes/sourceSchemas/v1'
import * as Types from 'redux/Types'

export function tryGetSourceSchemas() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_SOURCE_SCHEMAS
    })

    Api.getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_SOURCE_SCHEMAS_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_SOURCE_SCHEMAS_FAILED
        })
      })
  }
}
