import React from 'react'

import Styles from './styles.module.css'

function ActionPreviewDivider({ leftContent, rightContent, transition, open }) {
  let containerClassName = Styles['container']

  if (transition) {
    containerClassName += ` ${Styles['transition-container']} ${
      !open && Styles['hidden-transation-container']
    }`
  }

  return (
    <div className={containerClassName}>
      <div
        className={`${Styles['left-content']} ${
          transition ? Styles['transition-left-content'] : ''
        }`}
      >
        {leftContent}
      </div>

      <div className={Styles['right-content']}>{rightContent}</div>
    </div>
  )
}

export default ActionPreviewDivider
