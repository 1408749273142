import { createAction, createReducer } from '@reduxjs/toolkit'

import { CustomizationState } from 'types/GlobalCustomization'

import * as Types from 'redux/Types'

// Initial state
const INITIAL_STATE: CustomizationState = {
  colors: null
}

// Actions
const signedInSuccess = createAction<{ data: any[] }>(Types.SET_SIGNED_IN)
const updateColorSchemeSuccess = createAction<{ data: any[] }>(
  Types.UPDATE_COLOR_SCHEME_SUCCESS
)
const signedOutSuccess = createAction(Types.SIGN_OUT)

// Reducer
const CustomizationReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(signedInSuccess, (state: CustomizationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        colors: payload.customer.colors
      }
    })
    .addCase(
      updateColorSchemeSuccess,
      (state: CustomizationState, action: any) => {
        const { payload } = action

        return {
          ...state,
          colors: {
            primary_color: payload.data.primaryColor,
            secondary_color: payload.data.secondaryColor,
            tertiary_color: payload.data.tertiaryColor,
            quaternary_color: payload.data.quaternaryColor,
            quintary_color: payload.data.quintaryColor,
            senary_color: payload.data.senaryColor,
            septenary_color: payload.data.septenaryColor,
            octonary_color: payload.data.octonaryColor,
            nonary_color: payload.data.nonaryColor,
            denary_color: payload.data.denaryColor,
            text_primary_color: payload.data.textPrimaryColor,
            text_secondary_color: payload.data.textSecondaryColor,
            background_color: payload.data.backgroundColor,
            widget_background_color: payload.data.widgetBackgroundColor,
            logotype: payload.data.logotype
          }
        }
      }
    )
    .addCase(signedOutSuccess, () => INITIAL_STATE)
    .addDefaultCase((state: CustomizationState) => state)
})

export default CustomizationReducer
