import React from 'react'

import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import SectionHeader from 'components_new/molecules/SectionHeader'
import Text from 'components_new/atoms/Text'
import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import Alert from 'components_new/atoms/Alert'

interface MissingChipProps {
  noCoordinatesFeatures: number
  onClick: () => void
  totalFeatures: number
}

const MissingChip = (props: MissingChipProps) => {
  const { noCoordinatesFeatures, onClick, totalFeatures } = props

  const isAllMissing = noCoordinatesFeatures === totalFeatures

  return (
    <>
      <Box>
        {isAllMissing ? (
          <Alert
            severity="warning"
            sx={{ p: 2, mb: 1, maxWidth: 340 }}
            variant="filled"
          >
            <strong>Samtliga fastigheter verkar sakna koordinater</strong>
            <br />
            Kontakta oss på{' '}
            <Link color="inherit" href="mailto:support@homepal.se">
              support@homepal.se
            </Link>{' '}
            eller skriv direkt i livechatten så hjälper vi dig med data.
            {false ? (
              <>
                <SectionHeader title="Samtliga fastigheter verkar sakna koordinater" />
                <Text variant="caption">
                  Kontakta oss på{' '}
                  <Link href="mailto:support@homepal.se">
                    support@homepal.se
                  </Link>{' '}
                  eller skriv direkt i vår livechatt så hjälper vi dig med
                  datan.
                </Text>
                <Text variant="caption">
                  * Du hittar vår livechatt nere i högra hörnet.
                </Text>
              </>
            ) : null}
          </Alert>
        ) : null}
        <Chip
          clickable={true}
          icon={<Icon color="inherit" name="ErrorOutlineOutlined" />}
          label={`${noCoordinatesFeatures} ${
            noCoordinatesFeatures === 1 ? 'fastighet' : 'fastigheter'
          } saknar koordinater`}
          onClick={onClick}
        />
      </Box>
    </>
  )
}

export default MissingChip
