import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Chip from 'components_new/atoms/Chip'
import RadioButton from 'components/common/RadioButton'
import Input from 'components/common/Input'

import AddTag from 'components/containers/DataPlatform/AddTag'

import Styles from './styles.module.css'

export const PAGE_LIMIT = 5

export const initData = (sourceTable) => {
  if (!sourceTable) {
    return [null, null, {}]
  }

  let pk = undefined
  let ref = undefined

  const descriptions = {
    [sourceTable.id]: sourceTable.attributes.description
  }

  sourceTable.attributes.columns.forEach((item) => {
    if (item.tag_primary_key) {
      pk = item.id
    }
    if (item.tag_reference) {
      ref = item.id
    }

    descriptions[item.id] = item.description
  })

  return [pk, ref, descriptions]
}

export const getTableRows = (activeTable, tagged) => {
  return [
    {
      id: activeTable.id,
      key: activeTable.id,
      table: activeTable.attributes.name,
      status: tagged
    }
  ]
}

export const getAttributeRows = (columns, page, linkers) => {
  const rows = columns.map((item) => ({
    id: item.id,
    key: item.id,
    attribute: item.name,
    primaryKey: item.id,
    reference: item.id,
    status: item.id in linkers
  }))

  const start = PAGE_LIMIT * (page - 1)

  return rows.slice(start, start + PAGE_LIMIT)
}

export const TABLE_HEADERS = (descriptions, setDescriptions) => [
  {
    label: 'TABELL',
    key: 'table',
    width: '15%',
    render: function renderColumn(table) {
      return <small className="bold">{table}</small>
    }
  },
  {
    label: '',
    key: 'arrow',
    render: function renderColumn() {
      return <Icon fontSize="small" name="ArrowForward" />
    },
    align: 'right',
    width: '5%'
  },
  {
    label: 'TAGG',
    key: 'id',
    render: function renderColumn(id) {
      return <AddTag ignoreLockedMode referenceId={id} />
    }
  },
  {
    label: 'BESKRIVNING',
    render: function renderColumn(row) {
      return (
        <Input
          className={Styles.textarea}
          rows={2}
          type={'textarea'}
          placeholder={'Beskrivning'}
          value={descriptions[row.id]}
          onChange={(e) =>
            setDescriptions({
              ...descriptions,
              [row.id]: e.target.value || null
            })
          }
        />
      )
    }
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
    align: 'right',
    render: function renderColumn(tag) {
      return (
        <Chip
          color={tag ? 'success' : 'error'}
          label={tag ? 'Definierad' : 'Ej definierad'}
          size="small"
        />
      )
    }
  }
]

export const ATTRIBUTE_HEADERS = (
  primaryKey,
  setPrimaryKey,
  reference,
  setReference,
  descriptions,
  setDescriptions
) => [
  {
    label: 'ATTRIBUT',
    key: 'attribute',
    width: '15%',
    render: function renderColumn(attr) {
      return <small className="bold">{attr}</small>
    }
  },
  {
    label: '',
    key: 'arrow',
    render: function renderColumn() {
      return <Icon fontSize="small" name="ArrowForward" />
    },
    align: 'right',
    width: '5%'
  },
  {
    label: 'TAGG',
    key: 'id',
    render: function renderColumn(id) {
      return <AddTag ignoreLockedMode referenceId={id} />
    }
  },
  {
    label: 'BESKRIVNING',
    render: function renderColumn(row) {
      return (
        <Input
          className={Styles.textarea}
          rows={2}
          type={'textarea'}
          placeholder={'Beskrivning'}
          value={descriptions[row.id]}
          onChange={(e) =>
            setDescriptions({
              ...descriptions,
              [row.id]: e.target.value || null
            })
          }
        />
      )
    }
  },
  {
    label: 'PRIMÄR NYCKEL',
    key: 'primaryKey',
    render: function renderColumn(primary) {
      return (
        <div
          onClick={() => {
            if (primary === primaryKey) {
              setPrimaryKey(undefined)
            }
          }}
        >
          <RadioButton
            value={primaryKey}
            options={[{ value: primary, key: 1 }]}
            icon={<Icon name="KeyOutlined" />}
            onChange={(val) => setPrimaryKey(val.target.value)}
          />
        </div>
      )
    }
  },
  {
    label: 'REFERENS',
    key: 'reference',
    render: function renderColumn(ref) {
      return (
        <div
          onClick={() => {
            if (ref === reference) {
              setReference(undefined)
            }
          }}
        >
          <RadioButton
            value={reference}
            options={[{ value: ref, key: 2 }]}
            icon={<Icon name="Fingerprint" />}
            onChange={(val) => setReference(val.target.value)}
          />
        </div>
      )
    }
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
    align: 'right',
    render: function renderColumn(status) {
      return (
        <Chip
          color={status ? 'success' : 'error'}
          label={status ? 'Klassificerad' : 'Ej klassificerad'}
          size="small"
        />
      )
    }
  }
]
