import React from 'react'

import {
  FilterPatchBody,
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'
import { WidgetObject } from 'types/GlobalWidget'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'

import WidgetFilterSection from '../../FilterSection'

interface FilterSectionProps {
  filterOptions: KpiTemplateFilterOptionsBody | null
  kpi: KpiOptionObject
  onClose: () => void
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const FilterSection = (props: FilterSectionProps) => {
  const { filterOptions, kpi, onClose, updateKpiOption, widget } = props

  return (
    // The same filter section is used for both widget and kpi:
    <WidgetFilterSection
      filters={kpi.filters}
      filterOptions={filterOptions}
      onClose={onClose}
      updateFilters={(filters: FilterPatchBody[]) =>
        updateKpiOption({
          data: {
            filters: filters
          }
        })
      }
      widget={widget}
    />
  )
}

export default FilterSection
