import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { KpiVariableOption } from 'types/GlobalCompanyGroups'
import { KpiVariable } from '../KpiVariables'

export interface KpiVariableOptionReducerType {
  data: KpiVariableOption[]
  fetched: boolean
}

// Initial state
const INITIAL_STATE: KpiVariableOptionReducerType = {
  data: [],
  fetched: false
}

// Actions
const getAllAction = createAction<KpiVariableOption[]>(
  Types.GET_ALL_KPI_VARIABLE_OPTIONS_SUCCESS
)
const updateAction = createAction<KpiVariableOption[]>(
  Types.UPDATE_KPI_VARIABLE_OPTIONS_SUCCESS
)
const createVariableAction = createAction<{
  data: KpiVariable
}>(Types.CREATE_KPI_VARIABLE_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const kpiVariableOptionReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: payload,
        fetched: true
      }
    })
    .addCase(createVariableAction, (state, action) => {
      const { payload } = action

      const economySets = new Set<string | null>()

      state.data.forEach((option) => {
        economySets.add(option.economy_set_id)
      })

      const newOptions = Array.from(economySets).map((setId) => ({
        economy_set_id: setId,
        kpi_variable_id: payload.data.id,
        selected: [] as string[],
        parent_kpi_variable_id: payload.data.parent_kpi_variable_id
      }))

      return {
        ...state,
        data: [...state.data, ...newOptions]
      }
    })
    .addCase(updateAction, (state, action) => {
      const { payload } = action

      const newOptions = payload.filter(
        (newOption) =>
          !state.data.find(
            (option) =>
              newOption.economy_set_id === option.economy_set_id &&
              newOption.kpi_variable_id === option.kpi_variable_id
          )
      )

      return {
        ...state,
        data: [
          ...state.data.map((option) => {
            const updatedOption = payload.find(
              (newOption) =>
                newOption.economy_set_id === option.economy_set_id &&
                newOption.kpi_variable_id === option.kpi_variable_id
            )

            return updatedOption ? updatedOption : option
          }),
          ...newOptions
        ]
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default kpiVariableOptionReducer
