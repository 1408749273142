import React, { ChangeEvent, useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { RoleReducerType } from 'redux/reducers/Accounts'

import Button from 'components_new/atoms/Button'
import FormControl from 'components_new/atoms/FormControl'
import InputLabel from 'components_new/atoms/InputLabel'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Select from 'components_new/atoms/Select'
import Stack from 'components_new/atoms/Stack'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'
import { TRANSLATE_ROLES } from 'utils/enumTranslator'

import { AccountRole, PatchUserBody } from 'types/GlobalUser'

interface EditUserDialogProps {
  email: string | null
  firstName: string | null
  isHomepal?: boolean
  lastName: string | null
  onClose: () => void
  open: boolean
  role: AccountRole | null
  roles: RoleReducerType
  updateUser: (body: PatchUserBody) => void
}

const EditUserDialog = (props: EditUserDialogProps) => {
  const {
    email,
    firstName,
    isHomepal,
    lastName,
    onClose,
    open,
    role,
    roles,
    updateUser
  } = props

  const [updatedFirstName, setUpdatedFirstName] = useState<string>('')
  const [updatedLastName, setUpdatedLastName] = useState<string>('')

  const [updatedRole, setUpdatedRole] = useState<string>('')

  useEffect(() => {
    setUpdatedFirstName(firstName ?? '')
    setUpdatedLastName(lastName ?? '')
    if (role) {
      setUpdatedRole(roles[role]?.id ?? '')
    }
  }, [firstName, lastName, role])

  const handleClose = () => {
    onClose()
    setUpdatedFirstName('')
    setUpdatedLastName('')
    setUpdatedRole('')
  }

  const handleSubmit = () => {
    if (updatedFirstName && updatedLastName) {
      updateUser({
        data: {
          first_name: updatedFirstName,
          last_name: updatedLastName,
          role: updatedRole
        }
      })

      onClose()
    }
  }

  const filteredRoles = Object.values(roles).filter(
    (r) => !r.only_visible_to_homepal || role === r.name || isHomepal
  )

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              disabled={!Boolean(updatedFirstName && updatedLastName)}
              variant="contained"
              onClick={handleSubmit}
            >
              Spara
            </Button>
          </>
        }
        onClose={handleClose}
        open={open}
        title="Redigera användare"
      >
        <Stack sx={{ gap: 2 }}>
          <TextField
            error={!Boolean(updatedFirstName)}
            fullWidth
            helperText={
              !Boolean(updatedFirstName)
                ? 'Användare måste ha ett förnamn.'
                : undefined
            }
            label={'Förnamn'}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setUpdatedFirstName(event.target.value)
            }}
            value={updatedFirstName ?? ''}
          />
          <TextField
            error={!Boolean(updatedLastName)}
            fullWidth
            helperText={
              !Boolean(updatedLastName)
                ? 'Användare måste ha ett efternamn.'
                : undefined
            }
            label={'Efternamn'}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setUpdatedLastName(event.target.value)
            }}
            value={updatedLastName ?? ''}
          />
        </Stack>
        <TextField disabled fullWidth label={'Email'} value={email ?? ''} />
        {filteredRoles.length > 1 ? (
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel>Välj roll</InputLabel>
            <Select label={'Välj roll'} value={updatedRole}>
              {filteredRoles.map((option, i) => (
                <MenuItem
                  value={option.id}
                  key={i}
                  onClick={() => setUpdatedRole(option.id)}
                >
                  {TRANSLATE_ROLES[option.name]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default EditUserDialog
