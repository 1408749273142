import React, { ReactNode } from 'react'

import { Formik, FormikProps, FormikValues } from 'formik'

export type FormRefProps<T> = FormikProps<T>

export type FormValues = FormikValues

interface FormProps {
  children: (props: FormikProps<FormValues>) => ReactNode
  initialValues: FormValues
  onCancel?: () => void
  onSubmit: (values: FormValues) => void
}

/**
 * The Form component acts as a form
 * wrapper together with an onSubmit
 * function.
 *
 */
const Form = React.forwardRef<FormRefProps<FormikValues>, FormProps>(
  (props, ref) => {
    return (
      <Formik
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        innerRef={ref}
      >
        {props.children}
      </Formik>
    )
  }
)

Form.displayName = 'Form'
export default Form
