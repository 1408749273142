import React, { ReactElement, ReactNode } from 'react'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem, { MenuItemProps } from 'components_new/atoms/Menu/MenuItem'
import Tooltip from 'components_new/atoms/Tooltip'

import IconTip from 'components_new/molecules/IconTip'

interface OptionsMenuItemProps extends MenuItemProps {
  disabled?: boolean
  iconName?: IconNames
  iconRotation?: string
  infoTip?: ReactNode
  title: string
  tooltip?: string
}

const OptionsMenuItem = React.forwardRef(
  (props: OptionsMenuItemProps, ref: any) => {
    return (
      <TooltipWrapper title={props.tooltip}>
        <MenuItem
          disabled={props.disabled}
          onClick={props.onClick}
          onMouseDown={(event) => event.stopPropagation()}
          ref={ref}
        >
          {props.iconName ? (
            <ListItemIcon>
              <Icon
                fontSize="small"
                name={props.iconName}
                sx={
                  props.iconRotation
                    ? { transform: `rotate(${props.iconRotation})` }
                    : undefined
                }
              />
            </ListItemIcon>
          ) : null}
          <ListItemText primary={props.title} />
          {props.infoTip ? (
            <IconTip
              edge="end"
              placement="right"
              sx={{ ml: 1 }}
              title={props.infoTip}
            />
          ) : null}
        </MenuItem>
      </TooltipWrapper>
    )
  }
)

// Tooltip wrapper for being able to show tooltip on disabled menu items:
const TooltipWrapper = (props: { children: ReactElement; title?: string }) => {
  const { children, title } = props

  return title ? (
    <Tooltip title={title}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  )
}

OptionsMenuItem.displayName = 'OptionsMenuItem'
export default OptionsMenuItem
