import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { KpiTemplate, KpiTemplateType } from 'types/GlobalKpiTemplates'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'

import SectionHeader from 'components_new/molecules/SectionHeader'

interface ExampleProps {
  kpi: KpiTemplate
}

const Example = (props: ExampleProps) => {
  const { kpi } = props

  const isCustom = kpi.type === KpiTemplateType.CUSTOM

  return (
    <>
      <SectionHeader title="Exempel" />
      <Box
        sx={{
          width: '100%',
          height: 300,
          borderRadius: 1,
          borderBottomLeftRadius: isCustom ? undefined : 0,
          borderBottomRightRadius: isCustom ? undefined : 0,
          overflow: 'hidden'
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={kpi.demo_url ?? ''}
          title="Embedded widget by Homepal"
          frameBorder="0"
        ></iframe>
      </Box>
      <ThemeProvider theme={getTheme('dark')}>
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            bgcolor: 'background.paper',
            mb: 4
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <Link
                  external={true}
                  href={kpi.demo_url ?? ''}
                  onClick={(event) => event.stopPropagation()}
                >
                  {kpi.title}
                </Link>
              }
              secondary="homepal.se"
            />
          </ListItem>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Example
