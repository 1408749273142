import * as Functions from 'helpers/Functions'

export const STATUS_TO_TAG = (run) => {
  const timestamp = run.attributes.timestamp
    ? Functions.formatDateString(run.attributes.timestamp)
    : '-'

  switch (run.attributes.status) {
  case 'SUCCEEDED':
    return {
      color: 'success',
      text: 'Lyckad',
      icon: 'CheckOutlined',
      description: `${
        run.attributes.message || 'Data uppdaterad'
      } ${timestamp}`
    }
  case 'RUNNING':
    return {
      color: 'default',
      text: 'Hämtar data',
      icon: 'SyncOutlined',
      description: `Påbörjad ${timestamp}`
    }
  default:
    return {
      color: 'error',
      text: 'Misslyckad',
      icon: 'ClearOutlined',
      description: `${run.attributes.message || 'Misslyckad'} ${timestamp}`
    }
  }
}
