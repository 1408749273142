import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import _ from 'lodash'

import Table from 'components/common/Table'
import ProgressBar from 'components/common/ProgressBar'
import Pagination from 'components/common/Pagination'

import Box from 'components_new/atoms/Box'

import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'
import * as LockedModeActions from 'redux/actions/LockedMode'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Classification = ({
  buttonClick,
  tryUpdateSourceTable,
  match,
  setLockedMode,
  LockedModeStore,
  TagStore,
  SourceTableStore
}) => {
  const activeTableId = window.location.search.split('source_table_id=').pop(-1)
  const sourceTable = SourceTableStore.data[activeTableId]?.data
  const columns = sourceTable?.attributes?.columns || []

  const [pk, ref, desc] = Conf.initData(sourceTable)

  const [page, setPage] = useState(1)
  const [descriptions, setDescriptions] = useState(desc)
  const [primaryKey, setPrimaryKey] = useState(pk)
  const [reference, setReference] = useState(ref)

  useEffect(() => {
    setDescriptions(desc)
    setPrimaryKey(pk)
    setReference(ref)
  }, [sourceTable])

  useEffect(() => {
    const samePk = primaryKey === pk
    const sameRef = reference === ref
    const sameDescription = _.isEqual(descriptions, desc)

    if (samePk && sameRef && sameDescription) {
      if (LockedModeStore.locked) {
        setLockedMode({ locked: false })
      }
    } else {
      if (!LockedModeStore.locked) {
        setLockedMode({ locked: true, ignoreId: 'classification-wrapper' })
      }
    }
  }, [primaryKey, reference, descriptions])

  const hasPrimary = primaryKey ? 1 : 0
  const hasReference = reference ? 1 : 0
  const classifiedAttributes = columns.reduce(
    (a, b) => a + (b.id in TagStore.linkers ? 1 : 0),
    0
  )
  const totalClassified = hasPrimary + hasReference + classifiedAttributes

  let percentage = Math.floor(100 * (totalClassified / (columns.length + 2)))

  useEffect(() => {
    if (buttonClick) {
      const data = {
        attributes: {
          description: descriptions[activeTableId] || null,
          columns: columns.map((col) => ({
            id: col.id,
            tag_primary_key: primaryKey === col.id,
            tag_reference: reference === col.id,
            description: descriptions[col.id] || null
          }))
        }
      }

      tryUpdateSourceTable(activeTableId, { data }, match.params.id)

      setLockedMode({ locked: false })
    }
  }, [buttonClick])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Table
        ellipsis={false}
        scroll={{ x: 200 }}
        headers={Conf.TABLE_HEADERS(descriptions, setDescriptions)}
        rows={
          sourceTable
            ? Conf.getTableRows(sourceTable, activeTableId in TagStore.linkers)
            : []
        }
        rowClassName={Styles['row-height-big']}
        parsedFormat
      />
      <Box>
        <ProgressBar
          percent={percentage}
          hoverTitle={'Ej klassificerade'}
          hoverContent={
            <ul>
              <li>Attribut: {columns.length - classifiedAttributes}</li>
              {hasPrimary ? null : <li>Primär nyckel saknas</li>}
              {hasReference ? null : <li>Referens saknas</li>}
            </ul>
          }
        />
      </Box>
      <Table
        ellipsis={false}
        scroll={{ x: 200 }}
        headers={Conf.ATTRIBUTE_HEADERS(
          primaryKey,
          setPrimaryKey,
          reference,
          setReference,
          descriptions,
          setDescriptions
        )}
        rows={Conf.getAttributeRows(columns, page, TagStore.linkers)}
        rowClassName={Styles['row-height']}
        parsedFormat
      />
      <Pagination
        totalItems={columns.length}
        perPage={Conf.PAGE_LIMIT}
        text={'attribut'}
        onChange={(page) => setPage(page)}
      />
    </Box>
  )
}

function mapStateToProps({ LockedModeStore, TagStore, SourceTableStore }) {
  return { LockedModeStore, TagStore, SourceTableStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...SourceTableActions, ...LockedModeActions },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Classification))
