import React from 'react'

import RadioButton from 'components/common/RadioButton'
import Input from 'components/common/Input'

import Icon from 'components_new/atoms/Icon'
import Chip from 'components_new/atoms/Chip'

import AddTag from 'components/containers/DataPlatform/AddTag'

import Styles from './styles.module.css'

export const PAGE_LIMIT = 5

export const initData = (table) => {
  let pk = undefined
  let ref = undefined

  const descriptions = {
    [table.id]: table.attributes.description
  }

  table.attributes.columns.forEach((item) => {
    if (item.attributes.tag_primary_key) {
      pk = item.id
    }
    if (item.attributes.tag_reference) {
      ref = item.id
    }

    descriptions[item.id] = item.attributes.description
  })

  return [pk, ref, descriptions]
}

export const getTableRows = (table, tagged) => [
  {
    id: table.id,
    key: table.id,
    table: table.attributes.technical_name,
    status: tagged
  }
]

export const getAttributeRows = (columns, page, linkers) => {
  const rows = columns.map((item) => ({
    id: item.id,
    attribute: item.attributes.column_name,
    primaryKey: item.id,
    reference: item.id,
    status: item.id in linkers
  }))

  const start = PAGE_LIMIT * (page - 1)

  return rows.slice(start, start + PAGE_LIMIT)
}

export const TABLE_HEADERS = (descriptions, setDescriptions) => [
  {
    label: 'TABELL',
    key: 'table',
    width: '15%',
    render: function renderColumn(table) {
      return <small className="bold">{table}</small>
    }
  },
  {
    label: '',
    key: 'arrow',
    render: function renderColumn() {
      return <Icon name="ArrowForward" fontSize="small" />
    },
    align: 'right',
    width: '5%'
  },
  {
    label: 'TAGG',
    key: 'id',
    render: function renderColumn(id) {
      return <AddTag ignoreLockedMode referenceId={id} />
    }
  },
  {
    label: 'BESKRIVNING',
    render: function renderColumn(row) {
      return (
        <Input
          className={Styles.textarea}
          rows={2}
          type={'textarea'}
          value={descriptions[row.id]}
          onChange={(e) =>
            setDescriptions({
              ...descriptions,
              [row.id]: e.target.value || null
            })
          }
        />
      )
    }
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
    align: 'right',
    render: function renderColumn(tag) {
      return (
        <Chip
          color={tag ? 'success' : 'error'}
          label={tag ? 'Definierad' : 'Ej definierad'}
        />
      )
    }
  }
]

export const ATTRIBUTE_HEADERS = (
  primaryKey,
  setPrimaryKey,
  reference,
  setReference,
  descriptions,
  setDescriptions
) => [
  {
    label: 'ATTRIBUT',
    key: 'attribute',
    width: '15%',
    render: function renderColumn(attr) {
      return <small className="bold">{attr}</small>
    }
  },
  {
    label: '',
    key: 'arrow',
    render: function renderColumn() {
      return <Icon name="ArrowForward" fontSize="small" />
    },
    align: 'right',
    width: '5%'
  },
  {
    label: 'TAGG',
    key: 'id',
    render: function renderColumn(id) {
      return <AddTag ignoreLockedMode referenceId={id} />
    }
  },
  {
    label: 'BESKRIVNING',
    render: function renderColumn(row) {
      return (
        <Input
          className={Styles.textarea}
          rows={2}
          type={'textarea'}
          placeholder={'Beskrivning'}
          value={descriptions[row.id]}
          onChange={(e) =>
            setDescriptions({
              ...descriptions,
              [row.id]: e.target.value || null
            })
          }
        />
      )
    }
  },
  {
    label: 'PRIMÄR NYCKEL',
    key: 'primaryKey',
    render: function renderColumn(primary) {
      return (
        <div
          onClick={() => {
            if (primary === primaryKey) {
              setPrimaryKey(undefined)
            }
          }}
        >
          <RadioButton
            value={primaryKey}
            options={[{ value: primary, key: 1 }]}
            icon={<Icon name="KeyOutlined" />}
            onChange={(val) => setPrimaryKey(val.target.value)}
          />
        </div>
      )
    }
  },
  {
    label: 'REFERENS',
    key: 'reference',
    render: function renderColumn(ref) {
      return (
        <div
          onClick={() => {
            if (ref === reference) {
              setReference(undefined)
            }
          }}
        >
          <RadioButton
            value={reference}
            options={[{ value: ref, key: 2 }]}
            icon={<Icon name="FingerprintOutlined" />}
            onChange={(val) => setReference(val.target.value)}
          />
        </div>
      )
    }
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
    align: 'right',
    render: function renderColumn(status) {
      return (
        <Chip
          color={status ? 'success' : 'error'}
          label={status ? 'Klassificerad' : 'Ej klassificerad'}
          size="small"
        />
      )
    }
  }
]
