import React from 'react'

import Card from 'components/common/Card'

import Styles from './styles.module.css'

// EXAMPLE
// <CardSelector
//   title={'Välj typ av databas'}
//   items={[
//     {
//       imgSrc:
//         'https://us1-cdn.openchannel.io/5b447960ea5616202ab9c7cc.jpg',
//       title: 'Lime crm',
//       value: '1'
//     },
//     {
//       imgSrc:
//         'https://us1-cdn.openchannel.io/5b447960ea5616202ab9c7cc.jpg',
//       title: 'Lime crm 2',
//       value: '2'
//     },
//     {
//       imgSrc:
//         'https://us1-cdn.openchannel.io/5b447960ea5616202ab9c7cc.jpg',
//       title: 'Lime crm 3',
//       value: '3'
//     },
//     {
//       imgSrc:
//         'https://us1-cdn.openchannel.io/5b447960ea5616202ab9c7cc.jpg',
//       title: 'Lime crm 4',
//       value: '4'
//     },
//     {
//       imgSrc:
//         'https://us1-cdn.openchannel.io/5b447960ea5616202ab9c7cc.jpg',
//       title: 'Lime crm 5',
//       value: '5'
//     }
//   ]}
//   selected={selected}
//   onChange={(val) => this.setState({ selected: val })}
// />

const CardSelector = ({ title, items, selected, onChange, small }) => {
  let itemClassName = Styles['selector-card']
  let imgClassName = Styles['img']

  if (small) {
    itemClassName += ` ${Styles['selector-card-small']}`
    imgClassName = Styles['img-small']
  }

  return (
    <div>
      <h1 className={Styles.title}>{title}</h1>
      <div className={Styles['card-container']}>
        {items.map((item, i) => {
          return (
            <Card
              key={`${title}-${item.title}-${i}`}
              className={`${itemClassName} ${
                item.value === selected ? Styles['active-selector-card'] : ''
              }`}
              onClick={() => onChange(item.value)}
            >
              <div className={Styles['content-container']}>
                {item.imgSrc ? (
                  <img src={item.imgSrc} className={imgClassName} />
                ) : null}
                {item.faIcon}
                <p className={Styles['card-title']}>{item.title}</p>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default CardSelector
