import React from 'react'

import { Category } from 'redux/reducers/Dashboards'
import { KpiTemplate } from 'types/GlobalKpiTemplates'
import { WidgetType } from 'types/GlobalWidget'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import Text from 'components_new/atoms/Text'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import LoadingSection from 'components_new/molecules/LoadingSection'
import SearchField from 'components_new/molecules/SearchField'
import StandardThumbnail from 'components_new/molecules/StandardThumbnail'

import KpiThumbnail from 'components_new/organisms/KpiThumbnail'

import KpiRow from '../KpiRow'
import SubcategoryTitleRow from './SubcategoryTitleRow'

import { translateCategory } from 'utils/enumTranslator'
import { other } from '../utils'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import { LOADING_KPI_TITLES } from 'utils/loadingTitles'

interface ListViewProps {
  kpis: KpiTemplate[]
  kpisFetched: boolean
  onCreate: (
    kpiId: string | null,
    performKpiId?: string,
    type?: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
  ) => void
  query: string
  requestKpi?: () => void
  setQuery: (value: string) => void
  setShowDetails?: (kpi: KpiTemplate) => void
  subcategorizedKpis: {
    [category: string]: Record<string, KpiTemplate[]>
  }
  tab: 'ALL' | Category | 'OTHER'
  variant: 'add' | 'create'
}

const ListView = (props: ListViewProps) => {
  const {
    kpis,
    kpisFetched,
    onCreate,
    query,
    requestKpi,
    setQuery,
    setShowDetails,
    subcategorizedKpis,
    tab,
    variant
  } = props

  const isEmpty =
    (tab === 'ALL' && kpis.length === 0) ||
    (tab !== 'ALL' &&
      tab !== 'OTHER' &&
      Object.keys(subcategorizedKpis[tab]).length === 0)

  return (
    <>
      {/** ----- HERO ----- */}
      {tab !== 'OTHER' ? (
        <Box sx={{ px: 7, py: 2 }}>
          <SearchField
            helperText={
              query ? `Visar ${kpis.length} resultat för "${query}"` : undefined
            }
            onClear={() => setQuery('')}
            onSearch={(value) => setQuery(value)}
            placeholder={'Sök nyckeltal efter namn'}
            value={query}
            sx={{ mb: 3 }}
          />

          <Text variant="h4">
            {tab === 'ALL' ? 'Alla' : translateCategory[tab].title}
          </Text>

          {variant === 'add' ? (
            <Alert severity="info" sx={{ mt: 1 }}>
              Visar endast de nyckeltal som går att kombinera med ditt
              huvudnyckeltal i vald visualisering.
            </Alert>
          ) : null}
        </Box>
      ) : null}

      {/** ----- CONTENT ----- */}
      {kpisFetched ? (
        <>
          {tab !== 'OTHER' ? (
            /*-- KPIs --*/
            <TableContainer>
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell>Namn</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>{''}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tab === 'ALL'
                    ? kpis.map((kpi, i) => (
                      <KpiRow
                        key={i}
                        kpi={kpi}
                        onCreate={onCreate}
                        query={query}
                        setShowDetails={setShowDetails}
                      />
                    ))
                    : Object.keys(subcategorizedKpis[tab]).map(
                      (subcategory) => (
                        <>
                          <SubcategoryTitleRow title={subcategory} />
                          {subcategorizedKpis[tab][subcategory].map(
                            (kpi, i) => (
                              <KpiRow
                                key={i}
                                kpi={kpi}
                                hideCategory={true}
                                onCreate={onCreate}
                                query={query}
                                setShowDetails={setShowDetails}
                              />
                            )
                          )}
                        </>
                      )
                    )}
                  {!isEmpty ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Box sx={{ my: 2 }}>
                          <KpiThumbnail
                            title={'Saknar du ett specifikt nyckeltal?'}
                            summary={
                              'Skicka en förfrågan till oss så ser vi vad vi kan göra!'
                            }
                            variant={'custom'}
                            onClick={requestKpi ?? (() => {})}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            /*-- Other --*/
            <Box
              sx={{
                p: 2,
                width: '100%',
                display: 'flex',
                gap: 2
              }}
            >
              {other
                .sort((a, b) => sortAlphabeticalAsc(a.title, b.title))
                .map((widget, i) => (
                  <StandardThumbnail
                    key={i}
                    actions={<Button>Lägg till</Button>}
                    body={widget.summary}
                    onClick={() => {
                      onCreate(null, undefined, widget.type)
                    }}
                    title={widget.title}
                  />
                ))}
            </Box>
          )}

          {/*-- If empty --*/}
          <EmptyPlaceholder
            background={true}
            empty={isEmpty}
            icon="InboxTwoTone"
            title={
              variant === 'create'
                ? 'Hittar inte några nyckeltal.'
                : 'Hittar inga nyckeltal som går att kombinera med det huvudnyckeltal och den visualisering du valt.'
            }
            sx={{ flex: 1 }}
          />
        </>
      ) : (
        <LoadingSection titles={LOADING_KPI_TITLES} loading={true} />
      )}
    </>
  )
}

export default ListView
