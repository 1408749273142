import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

interface TitleProps {
  kpiTitle: string
  onBack: () => void
  showDetails: boolean
  variant: 'add' | 'create'
}

const Title = (props: TitleProps) => {
  const { kpiTitle, onBack, showDetails, variant } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {showDetails ? (
        <Box
          component="span"
          onClick={onBack}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary'
            },
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <Icon name="ArrowBackOutlined" sx={{ mr: 0.5 }} />
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            Lägg till {kpiTitle}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {variant === 'create' ? 'Lägg till widget' : 'Lägg till nyckeltal'}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Title
