import React, { ReactNode } from 'react'

import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import { getTheme } from 'themes'

import Logotype from 'assets/logotypes/Logotype'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'
import Text from 'components_new/atoms/Text'

import { EmbeddedWidget, widgetIds } from './utils'

import './index.css'

interface AuthTemplateProps {
  children: ReactNode
}

const AuthTemplate = (props: AuthTemplateProps) => {
  const { children } = props

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <ThemeProvider theme={getTheme('dark')}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          bgcolor: 'background.default'
        }}
      >
        <Grid container={true} sx={{ height: '100%', width: '100%' }}>
          <Grid item={true} xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <a href="https://homepal.se">
                <Box
                  sx={{
                    position: 'absolute',
                    top: 24,
                    left: 24,
                    height: 32,
                    color: 'white.main'
                  }}
                >
                  <Logotype />
                </Box>
              </a>
              <Box sx={{ maxWidth: 600, p: 2 }}>{children}</Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 24,
                  left: 24
                }}
              >
                <Text color="text.secondary" variant="body2">
                  Copyright © Homepal AB 2024.
                  <br />
                  Org. nr: 559201-1521 (SE).
                </Text>
              </Box>
            </Box>
          </Grid>
          {md ? (
            <Grid
              item={true}
              xs={12}
              md={5}
              sx={{
                height: '100%',
                position: 'relative',
                bgcolor: 'brand.main'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  WebkitMaskImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 25%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 75%, rgb(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 100%)'
                }}
              >
                <div className="slide-wrapper">
                  {widgetIds.map((id) => (
                    <div className="slide" key={id}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          p: 2,
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <EmbeddedWidget id={id} />
                      </Box>
                    </div>
                  ))}
                </div>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default AuthTemplate
