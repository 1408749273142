import React, { ReactElement } from 'react'
import { Fade as MUIFade } from '@mui/material'
import Box from 'components_new/atoms/Box'

interface FadeProps {
  children: ReactElement
  in?: boolean
  sx?: object
  timeout?: { enter: number; exit: number }
}

/**
 * The Fade component acts as a controlled
 * wrapper for content that should be shown and
 * hidden in a fading way.
 */

const Fade = React.forwardRef((props: FadeProps, ref: any) => {
  return (
    <MUIFade {...props} ref={ref}>
      <Box sx={{ display: props.in ? 'auto' : 'none' }}>{props.children}</Box>
    </MUIFade>
  )
})

Fade.displayName = 'Fade'
export default Fade
