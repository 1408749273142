import React from 'react'

import { getKpiFormulaString } from '../utils'

import Box from 'components_new/atoms/Box'
import FormControl from 'components_new/atoms/FormControl'
import InputLabel from 'components_new/atoms/InputLabel'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'
import Select from 'components_new/atoms/Select'
import Tooltip from 'components_new/atoms/Tooltip'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SectionHeader from 'components_new/molecules/SectionHeader'

import KpiFormulaBlock from './KpiFormulaBlock'

import {
  KpiVariable,
  KpiVariableWithChildren
} from 'redux/reducers/KpiVariables'
import { KPI_UNIT } from 'types/Enums'

import { handleDeleteBlock, handleEditType, handleEditValue } from './utils'
import {
  KpiTemplate,
  KpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

interface EditKpiSectionProps {
  formattedVariables: KpiVariableWithChildren[]
  editFormula: KpiTemplateCalculationBlock
  editName: string
  editUnit: KPI_UNIT
  homepalKpi?: boolean
  setEditFormula: (value: any) => void
  setEditName: (value: string) => void
  setEditUnit?: (value: KPI_UNIT) => void
  variables: KpiVariable[]
  templates: KpiTemplate[]
}

const EditKpiSection = (props: EditKpiSectionProps) => {
  const {
    formattedVariables,
    editFormula,
    editName,
    editUnit,
    homepalKpi = false,
    setEditFormula,
    setEditName,
    setEditUnit,
    variables,
    templates
  } = props

  return (
    <>
      <Tooltip
        title={
          homepalKpi
            ? 'Nyckeltalet är en del av Homepals standard och därför går varken namn eller enhet att redigera.'
            : ''
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <TextField
            label="Namn"
            disabled={homepalKpi}
            value={editName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEditName(event.target.value)
            }}
          />
          <FormControl
            sx={{ mb: 1, mt: 2, width: 96 }}
            disabled={homepalKpi || !setEditUnit}
          >
            <InputLabel>Enhet</InputLabel>
            <Select label={'Enhet'} value={editUnit}>
              {Object.values(KPI_UNIT).map((unitOption) => (
                <MenuItem
                  value={unitOption}
                  key={unitOption}
                  onClick={() => (setEditUnit ? setEditUnit(unitOption) : {})}
                  disabled={homepalKpi}
                >
                  {unitOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Tooltip>
      <Box sx={{ mt: 2 }}>
        <SectionHeader title="Formel" />
        <KpiFormulaBlock
          block={editFormula}
          formattedVariables={formattedVariables}
          editType={(id, side, value) => {
            const edited = handleEditType(editFormula, id, side, value)

            setEditFormula(edited)
          }}
          editValue={(id, key, value) => {
            const edited = handleEditValue(editFormula, id, key, value)

            setEditFormula(edited)
          }}
          deleteBlock={(id, side) => {
            const edited = handleDeleteBlock(editFormula, id, side)

            setEditFormula(edited)
          }}
          variables={variables}
          templates={templates}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <SectionHeader title="Förhandsvisning" />
        {editFormula ? (
          <code>{getKpiFormulaString(editFormula, variables, templates)}</code>
        ) : (
          <EmptyPlaceholder empty={true} title="Formel saknas" />
        )}
      </Box>
    </>
  )
}

export default EditKpiSection
