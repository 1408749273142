import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'

import * as Types from 'redux/Types'
import { SimRenaming } from 'utils/types'

export interface SimRenamingReducerType {
  data: { [id: string]: SimRenaming }
  fetched: boolean
}

const INITIAL_STATE: SimRenamingReducerType = {
  data: {},
  fetched: false
}

const getSimRenamings = createAction<{ data: SimRenaming[] }>(
  Types.GET_SIM_RENAMINGS_SUCCESS
)
const putSimRenamings = createAction<{ data: SimRenaming[] }>(
  Types.PUT_SIM_RENAMINGS_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

const simReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getSimRenamings, (state, { payload }) => {
      return {
        ...state,
        data: arrayToObject(payload.data) as { [id: string]: SimRenaming },
        fetched: true
      }
    })
    .addCase(putSimRenamings, (state, { payload }) => {
      return {
        ...state,
        data: arrayToObject(payload.data) as { [id: string]: SimRenaming }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default simReducer
