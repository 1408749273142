import React, { useState } from 'react'

import TextField from 'components_new/atoms/TextField'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import Button from 'components_new/atoms/Button'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (body: { view_name: string; output_name: string }) => void
}

const AddViewDialog = (props: Props) => {
  const { onClose, onSubmit, open } = props
  const [view, setView] = useState<string>('')
  const [name, setName] = useState<string>('')

  const resetFields = () => {
    setView('')
    setName('')
  }

  const close = () => {
    resetFields()
    onClose()
  }

  return (
    <SimpleDialog
      actions={
        <>
          <Button variant="text" onClick={close}>
            Avbryt
          </Button>
          <Button
            disabled={!view || !name}
            variant="contained"
            onClick={() => {
              onSubmit({ view_name: view, output_name: name })
              close()
            }}
          >
            Skapa
          </Button>
        </>
      }
      onClose={close}
      open={open}
      title="Lägg till vy"
    >
      <TextField
        fullWidth
        label={'Vy'}
        onChange={(event) => {
          setView(event.target.value)
        }}
        value={view}
      />
      <TextField
        fullWidth
        label={'Tabell'}
        onChange={(event) => {
          setName(event.target.value)
        }}
        value={name}
      />
    </SimpleDialog>
  )
}

export default AddViewDialog
