import React, { Dispatch, SetStateAction } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import LoadingSection from 'components_new/molecules/LoadingSection'
import SectionHeader from 'components_new/molecules/SectionHeader'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { TEmbedType } from './'

export type TFilter = { [key: string]: string[] }

interface FilterProps {
  embedType: TEmbedType
  filter: TFilter
  filterOptions: ParsedDashboardFilterOptions[]
  setFilter: Dispatch<SetStateAction<TFilter>>
}

const Filter = (props: FilterProps) => {
  const { embedType, filter, filterOptions, setFilter } = props

  const updateFilter = (relation_key: string, value: string[]) => {
    if (value.length === 0) {
      setFilter((prevState) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [relation_key]: _, ...rest } = prevState

        return rest
      })
    } else {
      setFilter((prevState) => ({
        ...prevState,
        [relation_key]: value
      }))
    }
  }

  return (
    <>
      <SectionHeader title="Filter" />
      <Text variant="body2">
        Lägg till parametrar i din länk för att dynamiskt filtrera din inbäddade{' '}
        {embedType}.
      </Text>
      <Box sx={{ mt: 2 }}>
        {filterOptions.every((filter) => filter.options !== undefined) ? (
          filterOptions
            .sort((a, b) => a.index - b.index)
            .map((filterOption) => (
              <SelectSearch
                key={filterOption.relation_key}
                color="primary"
                label={filterOption.title}
                onChange={(options: Option[]) => {
                  updateFilter(filterOption.relation_key, [
                    ...options.map((opt) => opt.id as string)
                  ])
                }}
                multiple={true}
                options={(filterOption.options as string[]).map((opt) => ({
                  id: opt,
                  label: opt
                }))}
                selected={
                  filter[filterOption.relation_key]
                    ? filter[filterOption.relation_key].map((item) => ({
                      id: item,
                      label: item
                    }))
                    : []
                }
                sort={true}
                sx={{ mb: 2 }}
              />
            ))
        ) : (
          <LoadingSection loading={true} titles={['Laddar']} />
        )}
      </Box>
      <Alert severity="info" icon={<Icon name="HelpOutlineOutlined" />}>
        <Text
          fontSize="inherit"
          fontWeight="bold"
          color="inherit"
          gutterBottom={true}
        >
          Kan jag filtrera på fler saker?
        </Text>
        <Text fontSize="inherit" color="inherit" gutterBottom={true}>
          Du har endast tillgång till samma filter som dashboarden har i sin
          högra sidomeny.
        </Text>
        <Text fontSize="inherit" color="inherit" gutterBottom={true}>
          Du behöver alltså lägga till ett filter där för att det ska gå att
          använda här.
        </Text>
      </Alert>
    </>
  )
}

export default Filter
