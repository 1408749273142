import React, { ElementType, ReactNode } from 'react'
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material'

interface BreadcrumbsProps {
  children?: ReactNode
  className?: string
  component?: ElementType<any>
  expandText?: string
  itemsAfterCollapse?: number
  itemsBeforeCollapse?: number
  maxItems?: number
  separator?: ReactNode
  slotProps?: { collapsedIcon?: () => void | object }
  slots?: { CollapsedIcon?: ElementType<any> }
  sx?: object
}

const Breadcrumbs = React.forwardRef((props: BreadcrumbsProps, ref: any) => (
  <MUIBreadcrumbs {...props} ref={ref}>
    {props.children}
  </MUIBreadcrumbs>
))

Breadcrumbs.displayName = 'Breadcrumbs'
export default Breadcrumbs
