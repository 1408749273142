import React, { FC } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import * as SnackbarActions from 'redux/actions/Snackbar'

import Snackbar from 'components_new/atoms/Snackbar'
import Link from 'components_new/atoms/Link'

const mapStateToProps = (state: ApplicationState) => ({
  SnackbarStore: state.SnackbarStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...SnackbarActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type SnackbarWrapperProps = ConnectedProps<typeof connector>

const SnackbarWrapper: FC<SnackbarWrapperProps> = (
  props: SnackbarWrapperProps
) => {
  const { SnackbarStore, toggleSnackbarClosed } = props

  return (
    <Snackbar
      open={SnackbarStore.open}
      message={SnackbarStore.message}
      onClose={() => toggleSnackbarClosed()}
      autoHideDuration={SnackbarStore.duration ?? 3000}
      action={
        SnackbarStore.link ? (
          <Link color="brand.light" onClick={SnackbarStore.link}>
            {SnackbarStore.linkText}
          </Link>
        ) : undefined
      }
    />
  )
}

export default connector(SnackbarWrapper)
