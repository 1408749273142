import { Theme } from '@mui/material'
import { getColor } from 'components_new/organisms/Widget/types/utils'
import { ParsedWidgetDataLabel } from 'types/GlobalWidget'

/**
 * Adds a predefined color to the enum values of has responded to tenant.
 */
export const getColorPieChart = (
  value: number | null,
  index: number,
  theme: Theme,
  labels: ParsedWidgetDataLabel[]
) => {
  if (labels[index].display_label === 'Svarat hyresgäst') {
    return theme.palette.primary.main
  }

  if (labels[index].display_label === 'Ej svarat hyresgäst') {
    return theme.palette.secondary.main
  }

  return getColor(value, index, theme, false, null, null)
}
