import React from 'react'

import {
  ComparativeDisplayType,
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'
import { WidgetObject } from 'types/GlobalWidget'

import { translateComparativeDisplayType } from 'utils/enumTranslator'

import Grid from 'components_new/atoms/Grid'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import { getOptions } from './utils'

interface ComparativeDisplayTypeSectionProps {
  kpi: KpiOptionObject
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const ComparativeDisplayTypeSection = (
  props: ComparativeDisplayTypeSectionProps
) => {
  const { kpi, updateKpiOption, widget } = props

  const comparativeDisplayTypeOptions = kpi.comparative_display_type.options
  const comparativePeriod = widget.settings.comparative_period.selected?.value

  if (!Boolean(comparativePeriod) || comparativeDisplayTypeOptions.length <= 1)
    return null

  const handleToggle = (option: ComparativeDisplayType) => {
    if (kpi.comparative_display_type.selected === option) {
      updateKpiOption({
        data: {
          comparative_display_type: ComparativeDisplayType.HIDDEN
        }
      })
    } else {
      updateKpiOption({
        data: {
          comparative_display_type: option
        }
      })
    }
  }

  return (
    <>
      <SettingsGroup title="Förändring">
        <SettingsItem>
          <Grid container={true} columns={5}>
            {getOptions(kpi).map((option) => {
              const selected = option === kpi.comparative_display_type.selected

              return (
                <Grid item={true} key={option} xs={1}>
                  <SettingsToggleOption
                    iconName={translateComparativeDisplayType[option].iconName}
                    onClick={() => handleToggle(option)}
                    selected={selected}
                    title={translateComparativeDisplayType[option].title}
                    tooltip={translateComparativeDisplayType[option].tooltip}
                  />
                </Grid>
              )
            })}
          </Grid>
        </SettingsItem>
      </SettingsGroup>
    </>
  )
}

export default ComparativeDisplayTypeSection
