import React, { MouseEventHandler, ReactNode } from 'react'

import Button from 'components_new/atoms/Button'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import { useTheme } from '@mui/material'
import { Color } from 'themes'

interface ThumbnailButtonProps {
  children: ReactNode
  color?:
    | Color
    | 'inherit'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
    | 'neutral'
  disabled?: boolean
  iconName?: IconNames
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  sx?: object
}

const ThumbnailButton = (props: ThumbnailButtonProps) => {
  const { children, color, disabled, iconName, onClick, onMouseDown, sx } =
    props

  const theme = useTheme()

  return (
    <Button
      color={color}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      sx={{
        height: '100%',
        width: '100%',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        fontSize: theme.typography.h6.fontSize,
        '& .MuiButton-startIcon': {
          m: 0
        },
        ...sx
      }}
    >
      {iconName ? <Icon fontSize="large" name={iconName} /> : null}
      {children}
    </Button>
  )
}

export default ThumbnailButton
