import React from 'react'
import { Modal as AntdModal } from 'antd'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import Styles from './styles.module.css'

// Overwrite antd modal styles.
import './styles.css'

const Modal = (props) => {
  const {
    header,
    className,
    visible,
    children,
    actions,
    size,
    onClose,
    subHeader,
    contentClassName = '',
    footerClassName = '',
    customHeader,
    zIndex = 1400
  } = props

  // medium
  let width = '50%'

  if (size === 'large') {
    width = '80%'
  } else if (size === 'small') {
    width = '30%'
  } else if (size === 'auto') {
    width = 'fit-content'
  }

  return (
    <AntdModal
      className={`${Styles['container']} ${className}`}
      width={width}
      open={visible}
      closable={false}
      footer={null}
      onCancel={onClose}
      wrapClassName={'ignore-locked-mode'}
      zIndex={zIndex}
      title={
        customHeader ? (
          customHeader
        ) : header ? (
          <>
            <Text variant="body1">{header}</Text>
            {subHeader ? <Text variant="body2">{subHeader}</Text> : null}
          </>
        ) : null
      }
    >
      <Box sx={{ p: 2 }} className={contentClassName}>
        {children}
      </Box>
      {actions && (
        <>
          <Divider sx={{ mt: 2 }} />
          <Box
            sx={{
              p: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1
            }}
            className={footerClassName}
          >
            {actions}
          </Box>
        </>
      )}
    </AntdModal>
  )
}

export default Modal
