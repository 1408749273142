import React from 'react'

import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import Text from 'components_new/atoms/Text'

import {
  ComparativePeriod,
  DataType,
  DateFilterType,
  ForecastPeriod,
  PeriodFilter,
  WidgetObject,
  translateForecastPeriod
} from 'types/GlobalWidget'

import {
  translateComparativePeriod,
  translateDateFilter
} from 'utils/enumTranslator'

export const getDateFilterOptions = (
  dateFilterOptions: PeriodFilter[]
): { label: string; value: string }[] => {
  const newOptions = dateFilterOptions.map((option) => {
    return {
      label: translateDateFilter[option] ?? '',
      value: option
    }
  })

  return newOptions
}

export const getDisabledComparative = (widget: WidgetObject) => {
  const comparativeAvailable = widget.settings.comparative_period.available
  const tooManyKpiOptions = widget.settings.kpi_options.length > 1
  const hasBreakdownBy = !!widget.settings.kpi_options.find(
    (opt) => opt.breakdown_by.selected
  )
  const lastYearAndRollingOnCalendar =
    widget.settings.date_filter.selected.value === PeriodFilter.LAST_YEAR &&
    widget.settings.segment_by.type === DataType.DATE &&
    widget.settings.kpi_options.some((opt) => opt.rolling_active)

  const disabled =
    !comparativeAvailable || hasBreakdownBy || lastYearAndRollingOnCalendar
  let disabledText = ''

  if (tooManyKpiOptions) {
    disabledText = 'Det går inte att jämföra när mer än ett nyckeltal är valt.'
  } else if (hasBreakdownBy) {
    disabledText = 'Det går inte att jämföra när ett nyckeltal har delats upp.'
  } else if (!comparativeAvailable) {
    disabledText = 'Det går inte att jämföra det här nyckeltalet.'
  } else if (lastYearAndRollingOnCalendar) {
    disabledText =
      'Det går inte att jämföra en rullande beräkning på förra året.'
  }

  return {
    disabledComparative: disabled,
    disabledComparativeText: disabledText
  }
}

export const getComparativePeriodOptions = (
  comparativePeriodOptions: ComparativePeriod[],
  dateFilter: PeriodFilter | null
): { label: string; value: string }[] => {
  if (!dateFilter) {
    return []
  }

  const newOptions = comparativePeriodOptions.map((option) => {
    return {
      label:
        translateComparativePeriod[DateFilterType.PERIOD][dateFilter][option] ??
        '',
      value: option
    }
  })

  return [{ label: 'Ingen', value: 'none' }, ...newOptions]

  // @TODO: Implement info tip on this:

  //     if (comparativePeriod === ComparativePeriod.AVERAGE_LAST_180_DAYS) {
  //       return (
  //         <MenuItem key={i} value={comparativePeriod}>
  //           {
  //             translateComparativePeriod[DateFilterType.PERIOD][dateFilter][
  //               comparativePeriod
  //             ]
  //           }
  //           <IconTip title={'Använder data från de senaste 180 dagarna'} />
  //         </MenuItem>
  //       )
  //     }
}

/**
 * Forecast only supports some period filters
 * and only LAST_YEAR comparison.
 */
export const DATE_FITLERS_WITH_FORECAST = [
  PeriodFilter.TODAY,
  PeriodFilter.THIS_WEEK_TO_TODAY,
  PeriodFilter.THIS_MONTH_TO_TODAY,
  PeriodFilter.THIS_QUARTER_TO_TODAY,
  PeriodFilter.THIS_YEAR_TO_TODAY,
  PeriodFilter.LAST_7_DAYS,
  PeriodFilter.LAST_30_DAYS,
  PeriodFilter.LAST_90_DAYS,
  PeriodFilter.LAST_365_DAYS,
  PeriodFilter.LAST_3_MONTHS_TO_TODAY,
  PeriodFilter.LAST_6_MONTHS_TO_TODAY,
  PeriodFilter.LAST_12_MONTHS_TO_TODAY
]

export const getDisabledForecast = (widget: WidgetObject) => {
  let disabled = false
  const disabledText = (
    <>
      Det går endast att visa prognos tillsammans med:
      <List sx={{ mt: 1 }}>
        <Text color="text.secondary" component="li" variant="body2">
          Historiska tidsperioder
        </Text>
        {DATE_FITLERS_WITH_FORECAST.map((period) => (
          // eslint-disable-next-line max-len
          <ListItem dense={true} key={period}>
            {translateDateFilter[period]}
          </ListItem>
        ))}
        <Text
          color="text.secondary"
          component="li"
          variant="body2"
          sx={{ mt: 1 }}
        >
          Jämförelser
        </Text>
        <ListItem dense={true}>
          {
            translateComparativePeriod[DateFilterType.PERIOD][
              widget.settings.date_filter.selected.value
            ][ComparativePeriod.LAST_YEAR]
          }
        </ListItem>
      </List>
    </>
  )

  if (
    widget.settings.comparative_period.selected.value &&
    [
      ComparativePeriod.AVERAGE_LAST_180_DAYS,
      ComparativePeriod.LAST_PERIOD
    ].includes(widget.settings.comparative_period.selected.value)
  ) {
    disabled = true
  } else {
    disabled = !DATE_FITLERS_WITH_FORECAST.includes(
      widget.settings.date_filter.selected.value
    )
  }

  return {
    disabledForecast: disabled,
    disabledForecastText: disabledText
  }
}

export const getForecastPeriodOptions = (): {
  label: string
  value: string
}[] => {
  const newOptions = Object.values(ForecastPeriod).map((option) => {
    return {
      label: translateForecastPeriod[option],
      value: option
    }
  })

  return [{ label: 'Ingen', value: 'none' }, ...newOptions]
}
