import React, { ElementType, ReactNode } from 'react'
import { Dialog as MUIDialog } from '@mui/material'

interface DialogProps {
  children: ReactNode
  component?: ElementType<any>
  disablePortal?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose?: () => void
  open: boolean
  PaperProps?: object
  sx?: object
}

/**
 * The Dialog component inform users about
 * a task and can contain critical information,
 * require decisions, or involve multiple tasks.
 */

const Dialog = React.forwardRef((props: DialogProps, ref: any) => {
  const { children, ...rest } = props

  return (
    <MUIDialog {...rest} ref={ref}>
      {children}
    </MUIDialog>
  )
})

Dialog.displayName = 'Dialog'
export default Dialog
