import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/dashboards`

export async function getCoreAction() {
  return Axios.get(`${URL}/core-actions`, parseAuthenticationHeader())
}

export async function getUserActivity() {
  return Axios.get(`${URL}/user-activity`, parseAuthenticationHeader())
}

export async function getUsers() {
  return Axios.get(`${URL}/users`, parseAuthenticationHeader())
}

export async function getActivatedUsers() {
  return Axios.get(`${URL}/activated-users`, parseAuthenticationHeader())
}
