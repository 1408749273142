import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/data-quality/flaws`

export async function exportFlaws(tableId, type, attribute) {
  const options = await Functions.parseAuthenticationHeader()

  options.responseType = 'blob'

  return Axios.get(
    `${URL}/${tableId}/export?type=${type}&attribute=${attribute}`,
    options
  )
}

export async function getAll(tableId, offset, attribute, sort) {
  return Axios.get(
    `${URL}/${tableId}?offset=${offset}&attribute=${attribute}&sort=${sort}`,
    await Functions.parseAuthenticationHeader()
  )
}
