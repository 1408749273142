// Todo: Move this later?
export const formatValue = (value: number, maxDecimals = 0) => {
  return value.toLocaleString('sv-SE', { maximumFractionDigits: maxDecimals })
}

export const hasValue = (value: number | null) => {
  return !!value || value === 0
}

export const getPercentage = (value: number, comparativeValue: number) => {
  const difference = getDifference(value, comparativeValue)

  return (difference / Math.abs(comparativeValue)) * 100
}

export const getColor = (difference: number, increaseIsPositive: boolean) => {
  if (difference === 0) {
    return 'warning'
  }

  return (increaseIsPositive && difference >= 0) ||
    (!increaseIsPositive && difference <= 0)
    ? 'success'
    : 'error'
}

export const getDifference = (value: number, comparativeValue: number) => {
  return value - comparativeValue
}

export const getStatus = (
  value: number | null,
  upperThresholdValue: number | null = null,
  lowerThresholdValue: number | null,
  increaseIsPositive: boolean
) => {
  if (upperThresholdValue === null || value == null) {
    return null
  }

  const success = increaseIsPositive
    ? value >= upperThresholdValue
    : value <= upperThresholdValue

  if (success) {
    return 'success'
  }

  const warning =
    lowerThresholdValue !== null &&
    (increaseIsPositive
      ? value > lowerThresholdValue
      : value < lowerThresholdValue)

  if (warning) {
    return 'warning'
  }

  return 'error'
}
