import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import SearchWrapper from 'components/common/SearchWrapper'

import PageHeader from 'components/common/PageHeader'
import DropdownButton from 'components/common/DropdownButton'
import InformationHeader from 'components/common/InformationHeader'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import List from 'components/common/List'

import * as AlertActions from 'redux/actions/Alert'
import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'
import * as SourceActions from 'redux/actions/DataPlatform/connections/Sources'
import * as LockedModeActions from 'redux/actions/LockedMode'
import * as SourceTableActions from 'redux/actions/DataPlatform/connections/SourceTables'

import EditNameModal from './EditNameModal'
import EditAuthModal from './EditAuthModal'
import EditEndpointsModal from './EditEndpointsModal'

import * as Functions from 'helpers/Functions'

import Styles from './styles.module.css'
import * as Conf from './conf.js'

const Specific = ({
  SourceStore,
  TagStore,
  tryGetOneSource,
  tryUpdateActiveSource,
  tryDeleteActiveSource,
  tryActivateActiveSource,
  tryCreateSourceTable,
  tryGetSourceTablePreview,
  tryScanActiveSource,
  setAlert,
  resetAlert,
  match,
  activeSource,
  source,
  history,
  setLockedMode,
  SourceTableStore
}) => {
  const [editName, setEditName] = useState(false)
  const [editAuth, setEditAuth] = useState(false)
  const [editEndpoints, setEditEndpoints] = useState(false)
  const [activeToggle, setActiveToggle] = useState('data')
  const [activeTableId, setActiveTableId] = useState(null)
  const [activeTable, setActiveTable] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const [buttonClick, setButtonClick] = useState(false)
  const [resetValues, setResetValues] = useState(false)
  const [editMode, setEdit] = useState(false)

  const formMappingRef = useRef(null)

  const isStream = source.attributes.type === 'Stream'
  const isFile = source.attributes.type === 'File'
  const isVitec = source.attributes.is_vitec

  const getPreview = (limit, offset, sourceTableId = activeTable.id) => {
    if (sourceTableId && !isStream) {
      tryGetSourceTablePreview(sourceTableId, limit, offset)
    }
  }

  const setTable = (id) => {
    const newSearch = `source_table_id=${id}`

    if (!window.location.search.includes(newSearch)) {
      history.push({ search: newSearch })
    }

    setActiveTableId(id)

    if (!(id in SourceTableStore.data)) {
      getPreview(10, 0, id)
    }

    const table = activeSource.included.source_tables.find((t) => t.id === id)

    setActiveTable(table)
  }

  useEffect(() => {
    const pathSplit = window.location.pathname.split('/')

    setActiveToggle(pathSplit[pathSplit.length - 1])
  }, [window.location.pathname])

  useEffect(() => {
    tryGetOneSource(match.params.id)
  }, [])

  useEffect(() => {
    const searchSplit = window.location.search.split('source_table_id=')

    let newTableId = activeTableId

    if (searchSplit.length > 1) {
      newTableId = searchSplit[1]
    }

    if (
      !newTableId &&
      activeSource &&
      activeSource.included &&
      activeSource.included.source_tables &&
      activeSource.included.source_tables.length > 0
    ) {
      // INIT first table
      setTable(activeSource.included.source_tables[0].id)
      setInitialized(true)
    } else {
      if (
        activeSource &&
        activeSource.included &&
        activeSource.included.source_tables
      ) {
        const table = activeSource.included.source_tables.find(
          (t) => t.id === newTableId
        )

        if (table) {
          setTable(newTableId)
        }
        setInitialized(true)
      }
    }
  }, [activeSource])

  useEffect(() => {
    if (buttonClick) {
      setButtonClick(!buttonClick)
    }
  }, [buttonClick])

  useEffect(() => {
    if (resetValues) {
      setResetValues(!resetValues)
    }
  }, [resetValues])

  if (!initialized) {
    return null
  }

  // const installed = activeSource.attributes.installed
  const connectedSystems = activeSource.included.active_shapes || []

  const user = activeSource.included.user

  const updatedBy = user
    ? `${user.attributes.first_name} ${user.attributes.last_name}`
    : 'Homepal'

  const fullPage =
    (source.attributes.type === 'File' &&
      !activeSource.attributes.multiple_files) ||
    ['history', 'views'].includes(activeToggle)

  const toggleItems = Functions.arrayToObject(
    Conf.PAGE_HEADER_TOGGLE(
      history,
      match.params.id,
      isStream,
      isFile,
      isVitec
    ),
    'value'
  )

  const setEditMode = (bool) => {
    setEdit(bool)

    setLockedMode({
      locked: bool,
      ignoreId: bool ? 'link-container' : null,
      alert: bool
        ? {
            onOk: () => {
              setResetValues(!resetValues)
              setEdit(false)
            }
          }
        : {}
    })
  }

  return (
    <Box sx={{ maxWidth: '80vw' }}>
      <Paper sx={{ p: 2 }}>
        <PageHeader
          header={activeSource.attributes.display_name}
          customOnBack={() => {
            let pathSplit = window.location.pathname.split('/')

            pathSplit.splice(-2)

            history.push(pathSplit.join('/'))
          }}
          actions={
            <DropdownButton
              filled
              ignoreLockedMode
              items={Conf.DROP_DOWN_ACTIONS(
                setEditName,
                setAlert,
                resetAlert,
                connectedSystems,
                tryDeleteActiveSource,
                activeSource,
                tryActivateActiveSource,
                tryScanActiveSource,
                history,
                source,
                match,
                setEditAuth,
                setEditEndpoints,
                isFile,
                tryUpdateActiveSource
              )}
              className={Styles['button-container']}
            >
              Åtgärder
            </DropdownButton>
          }
          toggle
          items={Conf.PAGE_HEADER_TOGGLE(
            history,
            match.params.id,
            isStream,
            isFile,
            isVitec
          )}
          activeToggle={activeToggle}
          className={Styles['page-header']}
        />
        <InformationHeader
          details={Conf.INFORMATION_HEADER_ITEMS(
            source,
            activeSource,
            updatedBy
          )}
        />
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <PageHeader
          header={toggleItems[activeToggle].label}
          actions={
            activeToggle === 'classification' ? (
              <Button
                className={'ignore-locked-mode'}
                disabled={!activeTable}
                onClick={() => setButtonClick(!buttonClick)}
              >
                Uppdatera
              </Button>
            ) : activeToggle === 'table' ? (
              !editMode ? (
                <Button
                  className={'ignore-locked-mode'}
                  onClick={() => setEditMode(!editMode)}
                  disabled={activeSource.included.source_tables.length === 0}
                >
                  Redigeringsläge
                </Button>
              ) : (
                <>
                  <Button
                    className={'ignore-locked-mode'}
                    onClick={() => {
                      setEditMode(!editMode)
                      setResetValues(!resetValues)
                    }}
                    variant="text"
                  >
                    Avbryt
                  </Button>
                  <Button
                    className={`ignore-locked-mode ${Styles['save-settings']}`}
                    onClick={() => formMappingRef.current.submit()}
                  >
                    Spara ändringar
                  </Button>
                </>
              )
            ) : null
          }
          line
          isSubheader
        />
        <Row gutter={[16, 16]}>
          {fullPage ? null : (
            <Box sx={{ display: 'flex', minWidth: '240px', width: '20%' }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ p: 2 }}>
                  <Text variant="subtitle2">
                    Tabeller{' '}
                    <span className={'secondary'}>
                      ({activeSource.included.source_tables.length})
                    </span>
                  </Text>
                </Box>
                <Divider />
                <SearchWrapper
                  inputSize={'small'}
                  dataContainerClassName={Styles['search-data']}
                  placeholder={'Sök bland tabeller...'}
                  data={activeSource.included.source_tables
                    .map((table) => ({
                      value: table.id,
                      label: table.attributes.name,
                      tag: TagStore.linkers[table.id],
                      key: table.id
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  DataComponent={({ data }) => (
                    <List
                      items={data}
                      active={activeTableId}
                      setActive={setTable}
                    />
                  )}
                  filterKey={'label'}
                />
                {isStream ? (
                  <>
                    <Divider />
                    <Box sx={{ p: 1 }}>
                      <Button
                        loading={SourceStore.creating}
                        onClick={() =>
                          !SourceStore.creating
                            ? tryCreateSourceTable({
                              data: {
                                attributes: {
                                  active_source_id: match.params.id
                                }
                              }
                            })
                            : {}
                        }
                        startIcon={<Icon name="AddCircleOutlineOutlined" />}
                        variant="text"
                      >
                        Lägg till tabell
                      </Button>
                    </Box>
                  </>
                ) : null}
              </Box>
              <Divider flexItem orientation="vertical" />
            </Box>
          )}
          <Col span={fullPage ? 24 : 18}>
            {Conf.PARSE_CONTENT(
              activeToggle,
              activeTable,
              buttonClick,
              activeSource,
              source,
              editMode,
              setEditMode,
              resetValues,
              formMappingRef
            )}
          </Col>
        </Row>
      </Paper>

      <EditNameModal
        open={editName}
        closeModal={() => setEditName(false)}
        onUpdate={(attributes) => {
          tryUpdateActiveSource(match.params.id, {
            data: { attributes }
          })
          setEditName(false)
        }}
        activeSourceId={activeSource.id}
        SourceStore={SourceStore}
        name={activeSource.attributes.display_name}
      />

      <EditAuthModal
        open={editAuth}
        closeModal={() => setEditAuth(false)}
        onUpdate={(attributes) => {
          tryUpdateActiveSource(match.params.id, {
            data: { attributes }
          })
          setEditAuth(false)
        }}
        source={source}
      />

      <EditEndpointsModal
        open={editEndpoints}
        closeModal={() => setEditEndpoints(false)}
        onUpdate={(attributes) => {
          tryUpdateActiveSource(match.params.id, {
            data: { attributes }
          })
          setEditEndpoints(false)
        }}
        source={source}
      />
    </Box>
  )
}

function mapStateToProps({
  SourceStore,
  AuthStore,
  ShapeStore,
  TagStore,
  SourceTableStore
}) {
  return {
    SourceStore,
    AuthStore,
    ShapeStore,
    TagStore,
    SourceTableStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertActions,
      ...ActiveSourceActions,
      ...SourceActions,
      ...LockedModeActions,
      ...SourceTableActions
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Specific))
