import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Dashboard from 'components_new/pages/Dashboard'
import Dashboards from 'components_new/pages/Dashboards'
import Templates from 'components_new/pages/internal/Templates'
import Template from 'components_new/pages/internal/Template'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user?.is_homepal_user
        }
        Component={() => <Template />}
        path={'/templates/:id'}
      />
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated && AuthStore.user?.is_homepal_user
        }
        Component={() => <Templates />}
        path={'/templates'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Dashboards />}
        path={'/dashboards/favorites'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Dashboards />}
        path={'/dashboards/folders/:folderId'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Dashboard />}
        path={'/dashboards/:id'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Dashboards />}
        path={'/dashboards'}
      />
      <Route path={'/dashboards/*'}>
        <Redirect to="/dashboards" />
      </Route>
    </Switch>
  )
}

export default Routes
