import { createAction, createReducer } from '@reduxjs/toolkit'
import { arrayToObject } from 'helpers/Functions'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'
import { StatusBannerData } from 'utils/types'

export interface StatusBannerReducerType {
  data: { [id: string]: StatusBannerData }
  fetched: boolean
}

// Initial state
const INITIAL_STATE: StatusBannerReducerType = {
  data: {},
  fetched: false
}

// Actions
const getStatusBannerSuccess = createAction<{ data: StatusBannerData[] }>(
  Types.INTERNAL_GET_STATUS_BANNER_SUCCESS
)
const createStatusBannerSuccess = createAction<{ data: StatusBannerData }>(
  Types.INTERNAL_CREATE_STATUS_BANNER_SUCCESS
)
const updateStatusBannerSuccess = createAction<{ data: StatusBannerData }>(
  Types.INTERNAL_UPDATE_STATUS_BANNER_SUCCESS
)
const deleteStatusBannerSuccess = createAction<{ id: string }>(
  Types.INTERNAL_DELETE_STATUS_BANNER_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const StatusBannerReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(
      getStatusBannerSuccess,
      (state: any, action: { payload: { data: StatusBannerData[] } }) => {
        const { payload } = action

        return {
          ...state,
          data: arrayToObject(payload.data),
          fetched: true
        }
      }
    )
    .addCase(
      createStatusBannerSuccess,
      (state: any, action: { payload: { data: StatusBannerData } }) => {
        const { payload } = action

        return {
          ...state,
          data: arrayToObject(payload.data)
        }
      }
    )
    .addCase(
      updateStatusBannerSuccess,
      (state: any, action: { payload: { data: StatusBannerData } }) => {
        const { payload } = action

        return {
          ...state,
          data: arrayToObject(payload.data)
        }
      }
    )
    .addCase(
      deleteStatusBannerSuccess,
      (state: any, action: { payload: { id: string } }) => {
        const { payload } = action

        const newData = cloneDeep(state.data)

        delete newData[payload.id]

        return {
          ...state,
          data: newData
        }
      }
    )
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default StatusBannerReducer
