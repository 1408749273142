import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'

import InternalDashboardWidget from './InternalDashboardWidget'

// This will only be used in this file, no need to store in Redux.
import * as InternalDashboardApi from 'redux/api/internal/Dashboard'

import { SxProps } from '@mui/material'

interface InternalDashboardApiResponse {
  kpi: number
  list: string[][]
  bars: {
    labels: string[]
    data: number[]
  }
}

interface InternalDashboardState extends InternalDashboardApiResponse {
  loading: boolean
}

interface InternalDashboardTemplateProps {
  sx?: SxProps
}

const INITIAL_DASHBOARD_STATE = {
  kpi: 0,
  list: [],
  bars: { labels: [], data: [] },
  loading: true
}

const InternalDashboardTemplate = (props: InternalDashboardTemplateProps) => {
  const { sx } = props

  const [coreActions, setCoreActions] = useState<InternalDashboardState>(
    INITIAL_DASHBOARD_STATE
  )
  const [userActivity, setUserActivity] = useState<InternalDashboardState>(
    INITIAL_DASHBOARD_STATE
  )
  const [activatedUsers, setActivatedUsers] = useState<InternalDashboardState>(
    INITIAL_DASHBOARD_STATE
  )
  const [users, setUsers] = useState<InternalDashboardState>(
    INITIAL_DASHBOARD_STATE
  )

  useEffect(() => {
    InternalDashboardApi.getCoreAction()
      .then((response: { data: InternalDashboardApiResponse }) =>
        setCoreActions({ ...response.data, loading: false })
      )
      .catch(() => {})

    InternalDashboardApi.getUserActivity()
      .then((response: { data: InternalDashboardApiResponse }) =>
        setUserActivity({ ...response.data, loading: false })
      )
      .catch(() => {})

    InternalDashboardApi.getActivatedUsers()
      .then((response: { data: InternalDashboardApiResponse }) =>
        setActivatedUsers({ ...response.data, loading: false })
      )
      .catch(() => {})

    InternalDashboardApi.getUsers()
      .then((response: { data: InternalDashboardApiResponse }) =>
        setUsers({ ...response.data, loading: false })
      )
      .catch(() => {})
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 2,
        bgcolor: 'default.main',
        ...sx
      }}
    >
      <Grid container={true} spacing={2} sx={{ height: '100%' }}>
        <Grid item={true} xs={3} sx={{ height: '100%' }}>
          <InternalDashboardWidget
            kpi={{
              title: 'Öppningar av dashboards (R7)',
              value: coreActions.kpi,
              loading: coreActions.loading
            }}
            list={{
              title: 'Öppningar av dashboards senaste 7 dagarna',
              headers: ['Användare', 'Datum'],
              rows: coreActions.list,
              loading: coreActions.loading
            }}
            bar={{
              labels: coreActions.bars.labels,
              data: coreActions.bars.data,
              label: 'Öppningar av dashboards',
              title: 'Öppningar av dashboards per vecka',
              loading: coreActions.loading
            }}
          />
        </Grid>
        <Grid item={true} xs={3} sx={{ height: '100%' }}>
          <InternalDashboardWidget
            kpi={{
              title: 'Användare som gjort minst en CA (R7)',
              value: userActivity.kpi,
              loading: userActivity.loading
            }}
            list={{
              title: 'Användare som gjort minst en CA senaste 7 dagarna',
              headers: ['Användare', 'Senaste CA'],
              rows: userActivity.list,
              loading: userActivity.loading
            }}
            bar={{
              labels: userActivity.bars.labels,
              data: userActivity.bars.data,
              label: 'Användare som gjort minst en CA',
              title: 'Användare som gjort minst en CA per vecka',
              loading: userActivity.loading
            }}
          />
        </Grid>
        <Grid item={true} xs={3} sx={{ height: '100%' }}>
          <InternalDashboardWidget
            kpi={{
              title: 'Ackumelerat antal aktiverade användare',
              value: activatedUsers.kpi,
              loading: activatedUsers.loading
            }}
            list={{
              title: 'Aktiverade användare',
              headers: ['Användare', 'Användaren aktiverades'],
              rows: activatedUsers.list,
              loading: activatedUsers.loading
            }}
            bar={{
              labels: activatedUsers.bars.labels,
              data: activatedUsers.bars.data,
              label: 'Aktiverade användare',
              title: 'Ackumelerat antal aktiverade användare per vecka',
              loading: activatedUsers.loading
            }}
            timeType={'line'}
          />
        </Grid>
        <Grid item={true} xs={3} sx={{ height: '100%' }}>
          <InternalDashboardWidget
            kpi={{
              title: 'Ackumelerat antal användare',
              value: users.kpi,
              loading: users.loading
            }}
            list={{
              title: 'Användare',
              headers: ['Användare', 'Användaren skapades'],
              rows: users.list,
              loading: users.loading
            }}
            bar={{
              labels: users.bars.labels,
              data: users.bars.data,
              label: 'Användare',
              title: 'Ackumelerat antal användare per vecka',
              loading: users.loading
            }}
            timeType={'line'}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InternalDashboardTemplate
