import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/activation`

export async function getActivationDashboard() {
  return Axios.get(`${URL}/dashboard`, parseAuthenticationHeader())
}
