import React from 'react'

import { Alert } from 'antd'

import Styles from './styles.module.css'
import './styles.css'

const StatusBanner = ({ title, message, showIcon, type, banner, icon }) => {
  return type ? (
    <Alert
      message={
        <div className={Styles.container}>
          <b className={`${Styles[type]} ${Styles.title}`}>{title}</b>
          <div className={Styles[type]}>{message}</div>
        </div>
      }
      showIcon={showIcon}
      icon={icon}
      type={type}
      banner={banner}
    />
  ) : null
}

export default StatusBanner
