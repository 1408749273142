import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { Form } from 'antd'

import Modal from 'components/common/Modal'
import Input from 'components/common/Input'
import DataTypeIcon from 'components/common/DataTypeIcon'
import StatusBanner from 'components/common/StatusBanner'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Text from 'components_new/atoms/Text'

import { FORM_VALIDATIONS } from 'helpers/Constants'

import Styles from './styles.module.css'

const EditEndpoint = ({ endpoint, onClose, setAlert, resetAlert, onSave }) => {
  const [form] = Form.useForm()
  const [slugLock, setSlugLock] = useState(true)
  const [attributeLocks, setAttributeLocks] = useState([])

  useEffect(() => {
    form.setFieldsValue({
      slug: endpoint?.slug,
      output_attributes:
        endpoint?.output_attributes?.map((attr) => ({
          id: attr.id,
          name: attr.attributes.name
        })) || []
    })

    if (endpoint?.output_attributes) {
      setAttributeLocks(
        _.range(endpoint.output_attributes.length).map(() => true)
      )
    }

    setSlugLock(true)
  }, [endpoint])

  return (
    <Modal
      header={'Redigera objekt'}
      visible={!!endpoint}
      onClose={() => onClose()}
      actions={
        <>
          <Button onClick={() => onClose()} variant="text">
            Avbryt
          </Button>
          <Button onClick={() => form.submit()}>Spara</Button>
        </>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          onSave(values)
          onClose()
        }}
      >
        <Form.Item name={'slug'}>
          <Input
            label={'Slug'}
            withLock
            lockStyle={Styles.lock}
            locked={slugLock}
            setLocked={() =>
              setAlert({
                header: 'Redigera slug',
                content: (
                  <Text variant="body1">
                    Att redigera slug för detta objekt påverkar direkt alla
                    parter som för närvarande använder ditt API. Det kan orsaka
                    problem.
                  </Text>
                ),
                onCancel: () => resetAlert(),
                onOk: () => {
                  setSlugLock(false)
                  resetAlert()
                },
                cancelText: 'Avbryt',
                okText: 'Lås upp'
              })
            }
            size="large"
          />
        </Form.Item>

        <Box>
          <Form.List name={'output_attributes'}>
            {(fields) => (
              <Box
                sx={{
                  bgcolor: 'grey.100',
                  p: 2,
                  mb: 1
                }}
              >
                {fields.map(({ name, restField }) => {
                  const outputAttribute = endpoint?.output_attributes[name]
                  const mappedAttribute =
                    endpoint?.mapped_output_attributes.find(
                      (ma) => ma.output_attribute_id === outputAttribute.id
                    )

                  return (
                    <Box
                      key={`attribute-field-${name}`}
                      sx={{ display: 'flex' }}
                    >
                      <Box
                        sx={{
                          width: '200px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <DataTypeIcon type={outputAttribute?.attributes.type} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <small className={'secondary tiny'}>
                            {mappedAttribute?.origin_table_name}
                          </small>
                          <small>
                            {mappedAttribute?.origin_output_attribute_name}
                          </small>
                        </Box>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Form.Item
                          name={[name, 'name']}
                          {...restField}
                          rules={[FORM_VALIDATIONS.REQUIRED]}
                        >
                          <Input
                            size={'large'}
                            withLock
                            lockStyle={Styles.lock}
                            locked={attributeLocks[name]}
                            setLocked={() =>
                              setAlert({
                                header: 'Redigera attribut ID',
                                content: (
                                  <Text variant="body1">
                                    Att redigera ID:t för detta attribut
                                    påverkar direkt alla parter som för
                                    närvarande använder ditt API. Det kan orsaka
                                    problem.
                                  </Text>
                                ),
                                onCancel: () => resetAlert(),
                                onOk: () => {
                                  const tmpLocks = _.cloneDeep(attributeLocks)

                                  tmpLocks[name] = false

                                  setAttributeLocks(tmpLocks)
                                  resetAlert()
                                },
                                cancelText: 'Avbryt',
                                okText: 'Lås upp'
                              })
                            }
                          />
                        </Form.Item>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )}
          </Form.List>

          <StatusBanner
            title={'Tänk på!'}
            message={
              // eslint-disable-next-line max-len
              'Alla tjänster som använder ditt API kan sluta fungera då du ändrar ett objekt i ditt API.'
            }
            type={'warning'}
            showIcon
          />
        </Box>
      </Form>
    </Modal>
  )
}

export default EditEndpoint
