/* Fake feature for sales and review */

import { Dispatch } from 'redux'

import { WidgetNotification } from 'types/GlobalWidgetNotification'

import * as Types from 'redux/Types'

export function tryCreateWidgetNotification(
  widgetId: string,
  body: WidgetNotification
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_WIDGET_NOTIFICATION
    })

    dispatch({
      type: Types.CREATE_WIDGET_NOTIFICATION_SUCCESS,
      payload: {
        widgetId,
        data: body
      }
    })

    dispatch({
      type: Types.TOGGLE_SNACKBAR_OPEN,
      payload: { message: 'Notifikation skapad' }
    })

    if (Boolean(null)) {
      dispatch({
        type: Types.CREATE_WIDGET_NOTIFICATION_FAILED
      })
    }
  }
}

export function tryGetWidgetNotifications(widgetId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_WIDGET_NOTIFICATIONS
    })

    dispatch({
      type: Types.GET_WIDGET_NOTIFICATIONS_SUCCESS,
      payload: { widgetId, data: [] }
    })

    if (Boolean(null)) {
      dispatch({
        type: Types.GET_WIDGET_NOTIFICATIONS_FAILED
      })
    }
  }
}

export function tryUpdateWidgetNotifications(
  id: string,
  widgetId: string,
  body: WidgetNotification
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET_NOTIFICATION
    })

    dispatch({
      type: Types.UPDATE_WIDGET_NOTIFICATION_SUCCESS,
      payload: {
        id,
        widgetId,
        data: body
      }
    })

    dispatch({
      type: Types.TOGGLE_SNACKBAR_OPEN,
      payload: { message: 'Notifikation uppdaterad' }
    })

    if (Boolean(null)) {
      dispatch({
        type: Types.UPDATE_WIDGET_NOTIFICATION_FAILED
      })
    }
  }
}

export function tryDeleteWidgetNotifications(id: string, widgetId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_WIDGET_NOTIFICATION
    })

    dispatch({
      type: Types.DELETE_WIDGET_NOTIFICATION_SUCCESS,
      payload: { id, widgetId }
    })

    dispatch({
      type: Types.TOGGLE_SNACKBAR_OPEN,
      payload: { message: 'Notifikation borttagen' }
    })

    if (Boolean(null)) {
      dispatch({
        type: Types.DELETE_WIDGET_NOTIFICATION_FAILED
      })
    }
  }
}
