import React from 'react'

import Button from 'components_new/atoms/Button'
import Link from 'components_new/atoms/Link'

interface ActionsProps {
  disabled: boolean
  isSubItem?: boolean
  onCreate: () => void
  onShowDetails: () => void
}

const Actions = (props: ActionsProps) => {
  const { disabled, isSubItem, onCreate, onShowDetails } = props

  return (
    <>
      <Button disabled={disabled} onClick={onCreate} size="small">
        Lägg till
      </Button>
      {!isSubItem ? (
        <Link onClick={onShowDetails} sx={{ display: 'block', mt: 0.5 }}>
          Mer information
        </Link>
      ) : null}
    </>
  )
}

export default Actions
