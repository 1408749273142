import React, { ElementType, ReactNode } from 'react'
import { Divider as MUIDivider } from '@mui/material'

interface DividerProps {
  children?: ReactNode
  component?: ElementType<any>
  flexItem?: boolean
  gutter?: boolean
  light?: boolean
  orientation?: 'horizontal' | 'vertical'
  sx?: object
  textAlign?: 'center' | 'left' | 'right'
  variant?: 'fullWidth' | 'inset' | 'middle'
}

/**
 * The Divider component is a thin line
 * that groups content in lists and layouts.
 */

const Divider = React.forwardRef((props: DividerProps, ref: any) => {
  const { gutter, sx, ...rest } = props

  return (
    <MUIDivider
      {...rest}
      ref={ref}
      sx={{ my: gutter ? 2 : undefined, ...sx }}
    />
  )
})

Divider.displayName = 'Divider'
export default Divider
