import { Option } from 'components_new/molecules/SelectSearch'
import {
  translateTitle,
  translateUserTag
} from 'components_new/pages/internal/UsersTracking/utils'
import { TrackingUser, UserTag, UserTitle } from 'types/GlobalTracking'

export type BasicExpression = {
  key: string
  operation: 'equal'
  value: string | number
}

export type LogicalExpression = {
  condition: 'and' | 'or'
  expressions: Expression[]
}

export type Expression = BasicExpression | LogicalExpression

export const evaluateExpression = (
  expression: BasicExpression,
  obj: any
): boolean => {
  const { key, operation, value } = expression
  const propValue = getValueFromPropString(obj, key)

  switch (operation) {
  // case 'greater_than':
  //   return propValue > value
  // case 'less_than':
  //   return propValue < value
  case 'equal':
  default:
    return propValue === value
  }
}

export const evaluateLogicalExpression = (
  expression: LogicalExpression,
  object: object
): boolean => {
  const { condition, expressions } = expression
  const method = condition == 'and' ? expressions.every : expressions.some

  return method.call(expressions, (expression) => {
    const isQuery = 'condition' in expression

    if (isQuery) {
      return evaluateLogicalExpression(expression, object)
    } else {
      return evaluateExpression(expression, object)
    }
  })
}

function getValueFromPropString<ObjectType>(object: ObjectType, path: string) {
  const keys = path.split('.')
  let result: any = object

  for (const key of keys) {
    result = result[key]
  }

  return result
}

export const buildQuery = (filters: any): LogicalExpression => {
  const keys = Object.keys(filters)

  const query: any = {
    condition: 'and',
    expressions: []
  }

  keys.forEach((key) => {
    if (filters[key].length > 1) {
      /** OR */
      query.expressions.push({
        condition: 'or',
        expressions: filters[key].map((value: Option) => {
          return {
            key: key,
            operation: 'equal',
            value: value.id
          }
        })
      })
    }
    if (filters[key].length === 1) {
      /** AND */
      query.expressions.push({
        key: key,
        operation: 'equal',
        value: filters[key][0].id
      })
    }
  })

  return query
}

export const getCustomers = (users: TrackingUser[]): Option[] => {
  return [
    ...users
      .map((user) => {
        return { id: user.customer.id, label: user.customer.name }
      })
      .filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )
  ]
}

export const getLicenses = (users: TrackingUser[]): Option[] => {
  return [
    ...users
      .map((user) => {
        return { id: user.customer.license, label: user.customer.license }
      })
      .filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )
  ]
}

export const getAccountOptions = (users: TrackingUser[]) => {
  return users.map((user) => ({
    id: user.id,
    label: user.full_name || user.email
  }))
}

const getUserTagOption = (tag: UserTag) => {
  const translatedTag = translateUserTag[tag]

  return {
    id: tag,
    label: `${translatedTag.icon} ${translatedTag.title}`
  }
}

export const getUserTags = (): Option[] => {
  return [
    getUserTagOption(UserTag.DASHBOARD_VIEWS),
    getUserTagOption(UserTag.ACTIVE_WEEKS_STREAK),
    getUserTagOption(UserTag.INACTIVE_WEEKS_STREAK),
    getUserTagOption(UserTag.INVITED_USERS),
    getUserTagOption(UserTag.NO_ACTIVITY),
    getUserTagOption(UserTag.NOT_ACCEPTED),
    getUserTagOption(UserTag.CREATED_DASHBOARDS)
  ]
}

const getTitleOption = (title: UserTitle) => {
  const translatedTitle = translateTitle[title]

  return {
    id: title,
    label: translatedTitle
  }
}

export const getTitleOptions = (): Option[] => {
  return [
    getTitleOption(UserTitle.CEO),
    getTitleOption(UserTitle.CFO),
    getTitleOption(UserTitle.IT),
    getTitleOption(UserTitle.LESSOR),
    getTitleOption(UserTitle.TECHINCAL_MANAGER),
    getTitleOption(UserTitle.REGION_MANAGER),
    getTitleOption(UserTitle.FINANCE_CONTROLLER),
    getTitleOption(UserTitle.CO_WORKER_TECHINCAL_MANAGEMENT),
    getTitleOption(UserTitle.MANAGEMENT_MANAGER),
    getTitleOption(UserTitle.CEO_ASSISTANT),
    getTitleOption(UserTitle.LESSOR_MANAGER)
  ]
}
