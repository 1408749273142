import React from 'react'
import { Form } from 'antd'

import Filters from './Filters'

const FilterOperation = ({ chosenAttributes, formRef }) => {
  return (
    <Form layout="vertical" form={formRef}>
      <Filters chosenAttributes={chosenAttributes} formRef={formRef} />
    </Form>
  )
}

export default FilterOperation
