import React from 'react'

import Modal from 'components/common/Modal'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import Styles from './styles.module.css'

function SelectOptionModal({ visible, onClose, options, onSelect }) {
  return (
    <Modal visible={visible} onClose={() => onClose()} size="auto">
      <IconButton onClick={() => onClose()}>
        <Icon name="Close" />
      </IconButton>
      <div
        className={`${Styles['add-operation-modal']} ${
          options.length < 3 ? Styles['auto-fit'] : ''
        }`}
      >
        {options.map((option, i) => (
          <OperationOption
            key={`option-${i}`}
            icon={option.icon}
            title={option.title}
            description={option.description}
            onClick={() => {
              onSelect(option.type)

              onClose()
            }}
          />
        ))}
      </div>
    </Modal>
  )
}

const OperationOption = ({ icon, title, description, onClick }) => {
  return (
    <div className={Styles['operation-option-container']} onClick={onClick}>
      <div className={Styles['operation-option-header-container']}>
        <Icon icon={icon} className={Styles['operation-icon']} />
        <p>{title}</p>
      </div>

      <p className={'secondary'}>{description}</p>
    </div>
  )
}

export default SelectOptionModal
