import React from 'react'

import ShortId from 'shortid'

import Transformation from '../Transformation'
import Quality from '../Quality'
import Flaws from '../Flaws'
import Schema from '../Schema'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

export const PARSE_CONTENT = (activeToggle) => {
  switch (activeToggle) {
  case 'transformation':
    return <Transformation />
  case 'schema':
    return <Schema />
  case 'quality':
    return <Quality />
  case 'flaws':
    return <Flaws />
  default:
    return null
  }
}

export const PAGE_HEADER_TOGGLE = (history, id, isHomepalUser) => {
  const headers = [
    {
      label: 'Transformation',
      value: 'transformation',
      onClick: () => {
        history.replace(`/data-platform/ontology/datasets/${id}/transformation`)
      },

      // Not visible for non Homepal users.
      hidden: !isHomepalUser
    },
    {
      label: 'Schema',
      value: 'schema',
      onClick: () => {
        history.replace(`/data-platform/ontology/datasets/${id}/schema`)
      }
    },
    {
      label: 'Kvalitet',
      value: 'quality',
      onClick: () => {
        history.replace(`/data-platform/ontology/datasets/${id}/quality`)
      }
    },
    {
      label: 'Brister',
      value: 'flaws',
      onClick: () => {
        history.replace(`/data-platform/ontology/datasets/${id}/flaws`)
      }
    }
  ]

  return headers.filter((header) => !header.hidden)
}

export const getMetaInformation = (table, user) => {
  return [
    {
      header: 'Skapades',
      label: table.attributes.created_at.split('T')[0],
      key: ShortId.generate()
    },
    {
      header: 'Skapades av',
      label: user
        ? `${user.attributes.first_name || ''} ${
          user.attributes.last_name || ''
        }`
        : 'Homepal',
      key: ShortId.generate()
    }
  ]
}

export const getActions = (
  isHomepalUser,
  table,
  onEdit,
  onDelete,
  setAlert,
  resetAlert,
  history
) => {
  // Only Homepal users can edit a dataset.
  if (isHomepalUser) {
    return [
      {
        header: 'dataset',
        key: 'dropdown-actions-1',
        items: [
          {
            label: 'Redigera dataset',
            onClick: () => onEdit()
          },
          {
            onClick: () => {
              if (table.attributes.used_in.length > 0) {
                setAlert({
                  isOpen: true,
                  header: 'Det går inte att ta bort detta dataset',
                  contentHeader: 'Dataset används',
                  faIcon: 'faExclamationTriangle',
                  faIconWarning: true,
                  onOk: () => {
                    resetAlert()
                  },
                  content: (
                    <Box>
                      <Text variant="body1">
                        {/* eslint-disable-next-line max-len */}
                        Datasetet används för tillfället i följande dataset:{' '}
                      </Text>
                      <ul>
                        {table.attributes.used_in.map((t) => (
                          <li key={t.id}>{t.attributes.name}</li>
                        ))}
                      </ul>
                      <Text variant="body1">
                        För att ta bort detta datasetet ({table.attributes.name}
                        ) behöver du ta bort referensen från ovan listade
                        dataset.
                      </Text>
                    </Box>
                  )
                })
              } else {
                setAlert({
                  isOpen: true,
                  header: 'Bekräfta borttagning',
                  okText: 'Ta bort',
                  cancelText: 'Avbryt',
                  contentHeader: 'Ta bort dataset',
                  faIcon: 'faTrash',
                  onOk: () => {
                    onDelete()
                    resetAlert()
                    history.push('/data-platform/ontology')
                  },
                  content: (
                    <Text variant="body1">
                      Du kommer att förlora ditt dataset{' '}
                      <b>{table.attributes.name}</b>
                      .
                      <br />
                      Tänk på att detta kan påverka de modeller som använder
                      datasetet.
                    </Text>
                  )
                })
              }
            },
            label: 'Ta bort dataset',
            remove: true
          }
        ]
      }
    ]
  }

  return null
}
