import React, { ChangeEvent, useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import ValuePreview from './ValuePreview'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'

interface ThresholdSectionProps {
  kpi: KpiOptionObject
  onClose: () => void
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const ThresholdSection = (props: ThresholdSectionProps) => {
  const { kpi, onClose, updateKpiOption, widget } = props

  const increaseIsPositive = kpi.increase_is_positive
  const type = widget.settings.type.selected

  const isComboChartLine =
    widget.settings.type.selected === WidgetType.COMBO_CHART && kpi.index === 1
  const isBubble = type === WidgetType.BUBBLE_CHART && kpi.index === 2

  // legal types
  if (type === WidgetType.FUNNEL && isComboChartLine && isBubble) return null

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
  }>({
    open: false,
    title: ''
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = () => {
    setDrawer({
      open: true,
      title: 'Status'
    })
  }

  // handleUpdateDrawer
  useEffect(() => {
    if (drawer.open) {
      handleOpenDrawer()
    }
  }, [kpi.lower_bound_threshold, kpi.upper_bound_threshold])

  const intermediateMode = kpi.lower_bound_threshold !== null

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    data: {
      options: { label: string; value: any }[]
      type: TInputType
      value: string
    }
  }>({
    open: false,
    property: 'lower_bound_threshold',
    title: '',
    data: {
      options: [],
      type: 'text',
      value: ''
    }
  })

  // Properties edited in this component.
  type TProperty = 'lower_bound_threshold' | 'upper_bound_threshold'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'lower_bound_threshold':
      return increaseIsPositive ? 'Undre gräns' : 'Övre gräns'
    case 'upper_bound_threshold':
      return !intermediateMode
        ? 'Gräns'
        : increaseIsPositive
          ? 'Övre gräns'
          : 'Undre gräns'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''
    let inputType: TInputType = 'text'
    const inputOptions: { label: string; value: any }[] = []

    switch (property) {
    case 'lower_bound_threshold':
      inputValue = kpi.lower_bound_threshold?.toString() ?? ''
      inputType = 'decimal'
      break
    case 'upper_bound_threshold':
      inputValue = kpi.upper_bound_threshold?.toString() ?? ''
      inputType = 'decimal'
      break
    }

    setDialog({
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (event: ChangeEvent<HTMLInputElement>) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value: event.target.value }
    }))
  }

  const handleSubmitDialog = () => {
    let body: KpiOptionPatchAttributes = { data: {} }

    switch (dialog.property) {
    case 'lower_bound_threshold':
      body = {
        data: {
          lower_bound_threshold: dialog.data.value
            ? parseFloat(dialog.data.value)
            : null
        }
      }
      break
    case 'upper_bound_threshold':
      body = {
        data: {
          upper_bound_threshold: dialog.data.value
            ? parseFloat(dialog.data.value)
            : null
        }
      }
      break
    }

    updateKpiOption(body)
  }

  const handleReset = () => {
    updateKpiOption({
      data: {
        lower_bound_threshold: null,
        upper_bound_threshold: null
      }
    })
  }

  const handleToggle = () => {
    if (intermediateMode) {
      updateKpiOption({
        data: {
          lower_bound_threshold: null
        }
      })
    } else {
      updateKpiOption({
        data: {
          lower_bound_threshold: 0
        }
      })
    }
  }

  return (
    <>
      <SettingsItem
        actions={
          kpi.upper_bound_threshold !== null ? <ValuePreview kpi={kpi} /> : null
        }
        onClick={() => handleOpenDrawer()}
        title="Status"
        value={kpi.upper_bound_threshold !== null ? undefined : null}
      />

      {/*-- drawer --*/}
      <SettingsDrawer
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        open={drawer.open}
        title="Status"
      >
        <SettingsGroup helperText="Lägg till mellanläge för att ge din data en status som ligger mellan positiv och negativ.">
          {!intermediateMode ? (
            // if standard mode
            <>
              <SettingsItem>
                <Box
                  sx={{
                    borderRadius: 1,
                    width: '100%',
                    height: 21,
                    bgcolor: increaseIsPositive ? 'success.main' : 'error.main'
                  }}
                />
              </SettingsItem>
              <SettingsItem
                onClick={() => handleOpenDialog('upper_bound_threshold')}
                title={getPropertyTitle('upper_bound_threshold')}
                value={kpi.upper_bound_threshold}
                variant="edit"
              />
              <SettingsItem>
                <Box
                  sx={{
                    borderRadius: 1,
                    width: '100%',
                    height: 21,
                    bgcolor: increaseIsPositive ? 'error.main' : 'success.main'
                  }}
                />
              </SettingsItem>
            </>
          ) : (
            // if intermediate mode
            <>
              {increaseIsPositive ? (
                // if increase is positive
                <>
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'success.main'
                      }}
                    />
                  </SettingsItem>
                  <SettingsItem
                    onClick={() => handleOpenDialog('upper_bound_threshold')}
                    title={getPropertyTitle('upper_bound_threshold')}
                    value={kpi.upper_bound_threshold}
                    variant="edit"
                  />
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'warning.main'
                      }}
                    />
                  </SettingsItem>
                  <SettingsItem
                    onClick={() => handleOpenDialog('lower_bound_threshold')}
                    title={getPropertyTitle('lower_bound_threshold')}
                    value={kpi.lower_bound_threshold}
                    variant="edit"
                  />
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'error.main'
                      }}
                    />
                  </SettingsItem>
                </>
              ) : (
                // if increase is negative
                <>
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'error.main'
                      }}
                    />
                  </SettingsItem>
                  <SettingsItem
                    onClick={() => handleOpenDialog('lower_bound_threshold')}
                    title={getPropertyTitle('lower_bound_threshold')}
                    value={kpi.lower_bound_threshold}
                    variant="edit"
                  />
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'warning.main'
                      }}
                    />
                  </SettingsItem>
                  <SettingsItem
                    onClick={() => handleOpenDialog('upper_bound_threshold')}
                    title={getPropertyTitle('upper_bound_threshold')}
                    value={kpi.upper_bound_threshold}
                    variant="edit"
                  />
                  <SettingsItem>
                    <Box
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        height: 21,
                        bgcolor: 'success.main'
                      }}
                    />
                  </SettingsItem>
                </>
              )}
            </>
          )}

          {type !== WidgetType.BUBBLE_CHART ? (
            <SettingsItem
              onClick={handleToggle}
              title={
                intermediateMode ? 'Ta bort mellanläge' : 'Lägg till mellanläge'
              }
              variant="action"
            />
          ) : null}
        </SettingsGroup>

        <SettingsGroup>
          <SettingsItem
            color="info"
            onClick={handleReset}
            title="Återställ"
            variant="action"
          />
        </SettingsGroup>
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default ThresholdSection
