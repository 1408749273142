import React, { useState } from 'react'
import { InputNumber as AntdInput } from 'antd'

import ShortId from 'shortid'

import './styles.css'

import Styles from './styles.module.css'

const InputNumber = (props) => {
  const {
    onChange,
    className = '',
    value = '',
    disabled,
    size = 'large',
    addonBefore,
    prefix,
    min,
    max,
    label,
    labelBackground,
    placeholder,
    precision
  } = props

  const [shortid] = useState(ShortId.generate())

  let id = `ant-input-${shortid}`

  if (addonBefore) {
    id = `ant-input-group-addon-input-${shortid}`
  }

  return (
    <div className={`${label ? Styles['label-container'] : ''}`}>
      {label && (
        <label
          className={`${disabled ? Styles['disabled-label'] : ''} ${
            labelBackground ? Styles['colored-label'] : ''
          }`}
        >
          {label}
        </label>
      )}
      <AntdInput
        id={id}
        className={`${Styles['container']} ${className}`}
        size={size}
        disabled={disabled}
        value={value}
        onChange={onChange}
        addonBefore={addonBefore}
        min={min}
        max={max}
        prefix={prefix}
        placeholder={placeholder}
        precision={precision}
      />
    </div>
  )
}

export default InputNumber
