import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'
import Tooltip from 'components_new/atoms/Tooltip'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { TEmbedType } from '.'

interface PublicToggleProps {
  embedType: TEmbedType
  isPublic: boolean
  setIsPublic: (value: boolean) => void
  editable: boolean
}

const PublicToggle = (props: PublicToggleProps) => {
  const { editable, embedType, isPublic, setIsPublic } = props

  return (
    <SettingsGroup>
      <SettingsItem
        actions={
          <Tooltip
            title={
              'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
            }
            disableHoverListener={editable}
          >
            <span>
              <Switch
                color="success"
                checked={isPublic}
                disabled={!editable}
                onChange={() => setIsPublic(!isPublic)}
                size="medium"
              />
            </span>
          </Tooltip>
        }
        body={`Alla som har tillgång till den publika länken kan se ${embedType}en.`}
        icon={<Icon name={'PublicOutlined'} />}
        title={'Publik åtkomst'}
      />
    </SettingsGroup>
  )
}

export default PublicToggle
