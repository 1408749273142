import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { cloneDeep } from 'lodash'

import Select from 'components/common/Select'
import Input from 'components/common/Input'
import InputNumber from 'components/common/InputNumber'
import DatePicker from 'components/common/DatePicker'
import DataTypeIcon from 'components/common/DataTypeIcon'
import IconHeaderFlag from 'components/common/IconHeaderFlag'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Tooltip from 'components_new/atoms/Tooltip'

import Filters from './Filters'

import * as Constants from 'helpers/Constants'

import {
  getSelectOptions,
  getDuplicateColumnValidation,
  isNumber,
  isDate,
  InfoMessage
} from './conf'

import { OPERATION_TYPES } from '../conf'

import Styles from './styles.module.css'

const ReplaceOperation = ({ chosenAttributes, formRef, type }) => {
  const [newColumn, setNewColumn] = useState(true)
  const [valueToggle, setValueToggle] = useState(false)

  const [stateChosenAttributes, setStateChosenAttributes] = useState(
    cloneDeep(chosenAttributes)
  )
  const newAttributeName = formRef.getFieldValue('newAttribute')

  useEffect(() => {
    if (newAttributeName in stateChosenAttributes) {
      const copyState = cloneDeep(stateChosenAttributes)

      delete copyState[newAttributeName]
      setStateChosenAttributes(copyState)
    }
  }, [stateChosenAttributes])

  useEffect(() => {
    const values = formRef.getFieldsValue()

    setNewColumn(values.newAttribute ? true : false)
    setValueToggle(values.rightAttribute ? false : true)
  }, [])

  return (
    <>
      <Form layout="vertical" form={formRef}>
        <IconHeaderFlag
          icon={OPERATION_TYPES[type].icon}
          header={OPERATION_TYPES[type].label}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <Form.Item name={'newAttributeInit'} noStyle />
          <Form.Item
            name={'leftAttribute'}
            rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
            className={Styles['form-item']}
            getValueFromEvent={(leftAttribute) => {
              const formValues = formRef.getFieldsValue()
              const prevDataType =
                chosenAttributes[formValues.leftAttribute]?.type
              const newDataType = chosenAttributes[leftAttribute]?.type

              // reset value if data type is changed
              if (prevDataType !== newDataType) {
                formValues.value = null
                formRef.setFieldsValue(formValues)
              }

              return leftAttribute
            }}
          >
            <Select
              label={'Kolumn'}
              options={getSelectOptions(stateChosenAttributes)}
            />
          </Form.Item>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
          <InfoMessage
            infoText={`Bestäm om värdet i kolumnen ska ersättas av
              ett nytt värde, eller om det ska ersättas av ett värde från en
              annan kolumn i datasetet.`}
          />
          <ToggleButton
            items={[
              { title: 'Värde', value: true },
              { title: 'Kolumn', value: false }
            ]}
            onChange={(event, value) => {
              setValueToggle(value)
              if (value) {
                // Goes from column -> value
                formRef.setFieldValue('rightAttribute', null)
              } else {
                // Goes from value -> column
                formRef.setFieldValue('value', null)
              }
            }}
            size="small"
            value={valueToggle}
          />
          <Form.Item shouldUpdate noStyle>
            {(form) => {
              const leftAttribute = form.getFieldValue('leftAttribute')
              const dataType = chosenAttributes[leftAttribute]?.type

              if (valueToggle) {
                return <ReplaceValueFormItem dataType={dataType} />
              } else {
                return (
                  <Form.Item
                    name={'rightAttribute'}
                    rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                    className={Styles['form-item']}
                  >
                    <Select
                      size="middle"
                      placeholder={'Kolumn'}
                      options={getSelectOptions(
                        stateChosenAttributes,
                        dataType
                      )}
                    />
                  </Form.Item>
                )
              }
            }}
          </Form.Item>
          <ToggleButton
            items={[
              { title: 'Samma kolumn', value: false },
              { title: 'Ny kolumn', value: true }
            ]}
            onChange={(event, value) => setNewColumn(value)}
            size="small"
            value={newColumn}
          />
          {newColumn ? (
            <>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  return (
                    <Form.Item
                      name={'newAttribute'}
                      rules={[
                        Constants.FORM_VALIDATIONS.REQUIRED,
                        getDuplicateColumnValidation(stateChosenAttributes)
                      ]}
                      className={Styles['form-item']}
                    >
                      <Input
                        prefix={
                          <DataTypeIcon
                            type={
                              stateChosenAttributes[
                                formRef.getFieldsValue().leftAttribute
                              ]?.type
                            }
                          />
                        }
                        suffix={
                          // eslint-disable-next-line max-len
                          <Tooltip title="Resultatet av din ersättning kommer att lagras i en ny kolumn. Döp denna kolumn till ett lämpligt namn.">
                            <Icon name="HelpOutline" fontSize="small" />
                          </Tooltip>
                        }
                        label={'Kolumn'}
                      />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </>
          ) : null}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Filters chosenAttributes={stateChosenAttributes} formRef={formRef} />
      </Form>
    </>
  )
}

const ReplaceValueFormItem = ({ dataType }) => {
  let inputField = <Input size={'middle'} placeholder={'Värde'} />

  if (isNumber(dataType)) {
    inputField = (
      <InputNumber
        size={'middle'}
        placeholder={'Värde'}
        precision={dataType === 'BIGINT' ? 0 : undefined}
      />
    )
  } else if (isDate(dataType)) {
    inputField = (
      <DatePicker
        size={'middle'}
        className={Styles['filter-operation-date-picker']}
      />
    )
  }

  return (
    <Form.Item
      name={'value'}
      rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
      className={Styles['form-item']}
    >
      {inputField}
    </Form.Item>
  )
}

export default ReplaceOperation
