import React, { useMemo } from 'react'
import ShortId from 'shortid'

import Lodash from 'lodash'

import Select from 'components/common/Select'

import Node from './index.js'

import * as Conf from './conf.js'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

const AggregateNode = ({ id, selected, data, setData }) => {
  const leftNode = useMemo(
    () => data?.globalNodeData?.[data.leftNode],
    [data?.globalNodeData]
  )

  const leftAttributes = useMemo(() => {
    return leftNode?.data?.outputAttributes || []
  }, [leftNode])

  return (
    <Node
      icon="TuneOutlined"
      title={'Aggregate'}
      selected={selected}
      nodeNumber={data.nodeNumberMapper[id]}
    >
      <Box
        sx={{
          width: 'auto',
          minWidth: 240,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.25
        }}
      >
        <Select
          className={'nodrag'}
          search
          ignoreLockedMode
          inNode
          size={'small'}
          placeholder={'Gruppera på'}
          value={data.groupByAttribute}
          onChange={(groupByAttribute) => {
            const groupByOutputAttribute = leftAttributes.find(
              (attr) => attr.shortId === groupByAttribute
            )

            setData(id, {
              groupByAttribute,
              outputAttributes: groupByAttribute
                ? [
                    {
                      name: groupByOutputAttribute.name,
                      realName: groupByOutputAttribute.realName,
                      shortId: ShortId.generate(),
                      type: groupByOutputAttribute.type,
                      prepend: id,
                      isGroupBy: true,
                      sensitiveRefColumnId:
                        groupByAttribute.sensitiveRefColumnId
                    }
                  ]
                : []
            })
          }}
          options={Conf.parseAttributeOptions(
            leftAttributes,
            data.nodeNumberMapper
          )}
        />
        <Divider sx={{ my: 1 }} />
        {data.outputAttributes.map((oa, i) =>
          !oa.isGroupBy ? (
            <Box
              key={`oa-${i}`}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }}
              className={'nodrag'}
            >
              <Box sx={{ flex: 1 }}>
                <Select
                  search
                  ignoreLockedMode
                  inNode
                  dropdownMatchSelectWidth={false}
                  size={'small'}
                  placeholder={'Attribut'}
                  value={oa.leftAttribute}
                  onChange={(value) => {
                    const tmpOutput = Lodash.cloneDeep(data.outputAttributes)
                    const leftAttribute = leftAttributes.find(
                      (leftAttr) => leftAttr.shortId === value
                    )

                    tmpOutput[i] = {
                      ...tmpOutput[i],
                      leftAttribute: value,
                      shortId: leftAttribute?.id,
                      name: leftAttribute?.name,
                      realName: leftAttribute?.realName,
                      type: leftAttribute?.type,
                      sensitiveRefColumnId: leftAttribute?.sensitiveRefColumnId
                    }

                    setData(id, { outputAttributes: tmpOutput })
                  }}
                  options={Conf.parseAttributeOptions(
                    leftAttributes,
                    data.nodeNumberMapper
                  ).filter((item) => item.value !== data.groupByAttribute)}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Select
                  ignoreLockedMode
                  inNode
                  size={'small'}
                  placeholder={'Funktion'}
                  value={oa.function}
                  onChange={(value) => {
                    const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

                    tmpOutput[i].function = value

                    if (value === 'sum') {
                      tmpOutput[i].type = 'DOUBLE'
                    } else {
                      const leftAttribute = leftAttributes.find(
                        (attr) => attr.shortId === tmpOutput[i].leftAttribute
                      )

                      tmpOutput[i].type = leftAttribute.type
                    }

                    setData(id, { outputAttributes: tmpOutput })
                  }}
                  options={Conf.AGGREGATE_FUNCTIONS}
                />
              </Box>
              <IconButton
                edge="end"
                onClick={() => {
                  const tmpOutput = Lodash.cloneDeep(data.outputAttributes)
                    .map((out, j) => (j !== i ? out : null))
                    .filter(Boolean)

                  setData(id, {
                    outputAttributes: tmpOutput
                  })
                }}
                size="small"
              >
                <Icon fontSize="small" name="Close" />
              </IconButton>
            </Box>
          ) : null
        )}

        {data.outputAttributes.length !== leftAttributes.length && (
          <Button
            className={'nodrag'}
            fullWidth
            onClick={() => {
              const tmpOutput = Lodash.cloneDeep(data.outputAttributes)

              tmpOutput.push({
                leftAttribute: null,
                function: null,
                name: null,
                realName: null,
                shortId: ShortId.generate(),
                type: null,
                prepend: id
              })
              setData(id, { outputAttributes: tmpOutput })
            }}
            size="small"
            startIcon={<Icon name="AddCircleOutlineOutlined" />}
            variant="text"
          >
            Lägg till
          </Button>
        )}
      </Box>
    </Node>
  )
}

export default AggregateNode
