import { getNullLabel } from '../conf'

const PIE_COLORS = [
  '#3b82f6', // Blue 6
  '#93c5fd', // Blue 4
  '#dbeafe', // Blue 2,
  '#6366f1', // Indigo 6
  '#a5b4fc', // Indigo 4
  '#e0e7ff', // Indigo 2
  '#ec4899', // Pink 6
  '#f9a8d4', // Pink 4
  '#fce7f3', // Pink 2
  '#06b6d4', // Cyan 6
  '#67e8f9', // Cyan 4
  '#cffafe' // Cyan 2
]

export const getLabels = (data, segmentByName, segmentByDataType) =>
  data.map((d) => d[segmentByName]) || getNullLabel(segmentByDataType)

export const getDatasets = (data, metrics) => {
  return [
    {
      data:
        metrics.length > 0 ? data.map((d) => d[metrics[0]?.attributeId]) : [],
      backgroundColor: data.map((_, i) => PIE_COLORS[i % PIE_COLORS.length]),
      borderColor: data.map((_, i) => PIE_COLORS[i % PIE_COLORS.length])
    }
  ]
}
