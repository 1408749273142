import * as Api from 'redux/api/Admin/authentication/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryGetAzureAd() {
  return (dispatch) => {
    dispatch({
      type: Types.GET_AZURE_AD
    })

    Api.getAzureAd()
      .then((response) => {
        dispatch({
          type: Types.GET_AZURE_AD_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_AZURE_AD_FAILED
        })
      })
  }
}

export function tryPutAzureAd(body) {
  return (dispatch) => {
    dispatch({
      type: Types.PUT_AZURE_AD
    })

    Api.putAzureAd(body)
      .then((response) => {
        dispatch({
          type: Types.PUT_AZURE_AD_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Kopplingen har skapats')
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_AZURE_AD_FAILED
        })

        messageWarning('Kopplingen kunde inte skapas')
      })
  }
}

export function tryDeleteAzureAd() {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_AZURE_AD
    })

    Api.deleteAzureAd()
      .then((response) => {
        dispatch({
          type: Types.DELETE_AZURE_AD_SUCCESS,
          payload: response.data.data
        })

        messageSuccess('Kopplingen har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_AZURE_AD_FAILED
        })

        messageWarning('Kopplingen kunde inte tas bort')
      })
  }
}
