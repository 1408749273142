import { Dashboard } from 'redux/reducers/Dashboards'
import { PublicDashboardPageProps } from '.'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { parseFilterQueryParams } from '../Dashboard/utils'
import { CustomPeriodFilter } from 'types/GlobalWidget'

export const getDataForWidgets = (
  props: PublicDashboardPageProps,
  dashboard: Dashboard,
  dashboardFilter: DashboardFilter,
  periodFilter: CustomPeriodFilter | null,
  rawQueryParams = ''
) => {
  const { tryGetOnePublicWidget } = props

  Object.values(dashboard.widgets).forEach((widget) => {
    let queryParams = parseFilterQueryParams(dashboardFilter, widget, null)

    if (periodFilter && periodFilter.from && periodFilter.to) {
      queryParams = {
        ...(queryParams || {}),
        from_date: periodFilter.from,
        to_date: periodFilter.to
      }
    }

    if (dashboardFilter.length === 0 || queryParams)
      tryGetOnePublicWidget(
        widget.id,
        widget.settings.type.selected,
        widget.settings.show_total,
        widget.settings.segment_by.additional,
        dashboard.id,
        queryParams,
        rawQueryParams
      )
  })
}
