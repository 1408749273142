import React, { useMemo, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Snackbar from 'components_new/atoms/Snackbar'
import TextField from 'components_new/atoms/TextField'
import ToggleButton from 'components_new/atoms/ToggleButton'
import Tooltip from 'components_new/atoms/Tooltip'

import SectionHeader from 'components_new/molecules/SectionHeader'

import { TDimensions } from './Dimensions'
import { TEmbedType } from '.'
import Text from 'components_new/atoms/Text'

export type TOutputMode = 'iframe' | 'link'

interface OutputProps {
  dimensions: TDimensions
  embedType: TEmbedType
  filter: { [key: string]: string[] }
  isPublic: boolean
  link: string
  outputMode: TOutputMode
  setOutputMode: (value: TOutputMode) => void
}

const Output = (props: OutputProps) => {
  const {
    dimensions,
    embedType,
    filter,
    isPublic,
    link,
    outputMode,
    setOutputMode
  } = props

  // Output link
  const outputLink = useMemo(() => {
    let tempLink = link

    if (Object.keys(filter).length > 0) {
      Object.keys(filter).forEach((relation_key, i) => {
        filter[relation_key].forEach((value, valueIndex) => {
          tempLink += `${
            i + valueIndex === 0 ? '?' : '&'
          }filter[${relation_key}][eq]=${value}`
        })
      })
    }

    return tempLink
  }, [filter, link, isPublic])

  // iframes
  const outputIframe = `<iframe width="${dimensions.width}" height="${dimensions.height}" src="${outputLink}" title="Embedded ${embedType} by Homepal" frameborder="0"></iframe>`
  const previewIframe = `<iframe width="100%" height="100%" src="${outputLink}" title="Embedded ${embedType} by Homepal" frameborder="0"></iframe>`

  // Generic output
  const output = useMemo(() => {
    if (outputMode === 'iframe') {
      return outputIframe
    }

    return outputLink
  }, [outputMode, outputLink, outputIframe])

  // Handle copy
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(output)
    setShowCopiedMessage(true)
  }

  const handleCloseCopiedMessage = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    setShowCopiedMessage(false)
  }

  return (
    <>
      <SectionHeader title="Förhandsvisning" />
      <Box
        sx={{
          p: 2,
          mb: 2,
          bgcolor: 'action.hover',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: '64vh',
            maxWidth: 640,
            height: '36vh',
            maxHeight: 640,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'background.paper'
          }}
        >
          {isPublic ? (
            <div
              dangerouslySetInnerHTML={{ __html: previewIframe }}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Icon name={'PublicOffOutlined'} />
              <Text fontWeight="medium">Privat {embedType}</Text>
            </Box>
          )}
        </Box>
      </Box>
      <ToggleButton
        items={[
          {
            title: 'Kod',
            iconName: 'CodeOutlined',
            value: 'iframe'
          },
          {
            title: 'Länk',
            iconName: 'LinkOutlined',
            value: 'link'
          }
        ]}
        value={outputMode}
        onChange={(event, value) => setOutputMode(value)}
        size="small"
      />
      <TextField
        multiline={outputMode === 'iframe'}
        rows={5}
        value={output}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={
                  outputMode === 'iframe'
                    ? 'Kopiera inbäddningskod'
                    : 'Kopiera inbäddningslänk'
                }
              >
                <IconButton color="primary" onClick={copyLinkToClipboard}>
                  <Icon name="ContentCopy" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          readOnly: true
        }}
        sx={{ mt: 1 }}
      />

      {/* ----- Snackbars ----- */}
      <Snackbar
        open={showCopiedMessage}
        autoHideDuration={3000}
        onClose={handleCloseCopiedMessage}
        message="Kopierat till urklipp"
      />
    </>
  )
}

export default Output
