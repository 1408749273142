import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/connections/auth-param-values`

export async function put(body, params = '') {
  const url = `${URL}${params}`
  const header = await Functions.parseAuthenticationHeader()

  return Axios.put(url, body, header)
}

export async function validate(body) {
  const url = `${URL}/validate-source`
  const header = await Functions.parseAuthenticationHeader()

  return Axios.put(url, body, header)
}

export async function validateDatabase(body) {
  const url = `${URL}/validate-database`
  const header = await Functions.parseAuthenticationHeader()

  return Axios.put(url, body, header)
}

export async function validateSFTP(body) {
  const url = `${URL}/validate-sftp`
  const header = await Functions.parseAuthenticationHeader()

  return Axios.put(url, body, header)
}
