import React from 'react'

import { Pie } from 'react-chartjs-2'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Text from 'components_new/atoms/Text'

import Broken from 'assets/insight_studio/widget/broken_grey.svg'
import NoData from 'assets/insight_studio/widget/no_data_bar_chart.svg'

import { ErrorComponent } from '../conf'
import { getLabels, getDatasets } from './conf'

import { useTheme } from '@mui/material'

const PieChart = ({ widgetData, segmentByAttribute }) => {
  const { title, data, metrics, broken } = widgetData
  const tooManyMetrics = data.length > 30
  const error = data.length === 0 || broken || tooManyMetrics

  const errorComponent = (
    <ErrorComponent
      img={broken || tooManyMetrics ? Broken : NoData}
      text={
        broken
          ? 'Sektionen är trasig'
          : tooManyMetrics
            ? 'Dina val genererar för många fördelningar'
            : 'Ingen data att visa'
      }
      className={'secondary'}
    />
  )

  const labels = getLabels(
    data,
    segmentByAttribute?.attributes.name,
    segmentByAttribute?.attributes.type
  )
  const datasets = getDatasets(data, metrics)

  const theme = useTheme()

  const chart = (
    <Box sx={{ width: '95%', height: '95%' }}>
      <Pie
        options={{
          onResize: (chart) => {
            const dimensions = chart.canvas.parentNode?.getBoundingClientRect()

            if (dimensions) {
              chart.config._config.options.aspectRatio =
                dimensions.width / dimensions.height

              chart.update()
            }
          },
          animation: {
            duration: 0
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
                font: { family: 'IBM Plex Sans', weight: 500, size: 10 },
                color: theme.palette.text.secondary
              }
            }
          }
        }}
        data={{
          labels,
          datasets
        }}
      />
    </Box>
  )

  return (
    <>
      {title ? (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              p: 2,
              fontWeight: '500'
            }}
          >
            <Text color="text.primary" variant="subtitle2">
              {title}
            </Text>
          </Box>
          <Divider />
        </Box>
      ) : null}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        {error ? errorComponent : chart}
      </Box>
    </>
  )
}

export default PieChart
