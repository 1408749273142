import React, { useRef, useState } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { getDatasetFromClick, horizontalLinePlugin } from '../utils'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import NoValue from '../../errors/NoValue'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import { getLines, getOptions } from './utils'
import { translateWidgetType } from 'utils/enumTranslator'
import { EXPLORE_DISABLED_TOOLTIP, getExploreLabel } from 'utils/texts'

interface LineChartProps {
  formattedData: FormattedWidgetData
  onClick: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  onClickValue: {
    [kpiOptionId: string]:
      | ((
          segmentLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  }
  scaleFactor: number
  widget: WidgetObject
}

const LineChart = (props: LineChartProps) => {
  const { formattedData, onClick, onClickValue, scaleFactor, widget } = props

  const theme = useTheme()
  const chartRef = useRef<any>(null)
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<{
    breakdownLabel: string | null
    dataLabel: ParsedWidgetDataLabel
    kpiOptionId: string
  } | null>(null)
  const open = Boolean(anchorPosition)

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.LINE_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }
  const allowOnClick = onClick.length > 0
  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    dataLabel: ParsedWidgetDataLabel,
    breakdownLabel: string | null,
    kpiOptionId: string
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedOptions({
      breakdownLabel,
      dataLabel,
      kpiOptionId: kpiOptionId
    })
  }
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedOptions(null)
  }

  const hasBreakdown =
    formattedData.datasets.length > 1 ||
    !!widget.settings.kpi_options.find(
      (option) => !!option.breakdown_by.selected
    )

  const lines = getLines(theme, formattedData, scaleFactor)

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          ref={chartRef}
          type="line"
          data={lines}
          options={getOptions(
            widget,
            hasBreakdown,
            theme,
            formattedData,
            scaleFactor,
            allowOnClick
          )}
          plugins={[horizontalLinePlugin]}
          onClick={
            allowOnClick
              ? (event) => {
                  if (chartRef.current) {
                    const { top } = chartRef.current.chartArea

                    if (event.nativeEvent.offsetY <= top) {
                      // If click is above legend
                      return
                    }

                    const index = chartRef.current.scales.x.getValueForPixel(
                      event.nativeEvent.offsetX
                    )

                    const segment = lines.dataLabels[index]
                    const { breakdownLabel, kpiOptionId } = getDatasetFromClick(
                      chartRef,
                      event,
                      formattedData,
                      widget
                    )

                    if (segment && kpiOptionId) {
                      handleClick(
                        event,
                        segment,
                        breakdownLabel || null,
                        kpiOptionId
                      )
                    }
                  }
                }
              : undefined
          }
        />
      </Box>
      {allowOnClick && selectedOptions && (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            anchorPosition
              ? { top: anchorPosition.top, left: anchorPosition.left }
              : undefined
          }
          open={open}
          onClose={handleClose}
          disableScrollLock
        >
          {onClick.map((option, i) => {
            return (
              <MenuItem
                key={i}
                onClick={(event) => {
                  handleClose(event)

                  option.onClick([selectedOptions.dataLabel])
                }}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <ListItemIcon>
                  <Icon name={option.iconName} fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={option.title([selectedOptions.dataLabel])}
                />
              </MenuItem>
            )
          })}
          <Tooltip
            title={
              !onClickValue[selectedOptions.kpiOptionId]
                ? EXPLORE_DISABLED_TOOLTIP
                : null
            }
          >
            <Box>
              <MenuItem
                disabled={!onClickValue[selectedOptions.kpiOptionId]}
                onClick={(event) => {
                  handleClose(event)
                  const func =
                    onClickValue[selectedOptions.kpiOptionId as string]

                  if (func) {
                    func(
                      selectedOptions.dataLabel,
                      [],
                      selectedOptions.breakdownLabel
                    )
                  }
                }}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <ListItemIcon>
                  <Icon name={'SearchOutlined'} fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={getExploreLabel(
                    selectedOptions.dataLabel.display_label
                  )}
                />
                <Chip
                  label={'Beta'}
                  color={'info'}
                  size={'small'}
                  sx={{ ml: 1 }}
                />
              </MenuItem>
            </Box>
          </Tooltip>
        </Menu>
      )}
    </>
  )
}

export default LineChart
