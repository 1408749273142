import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Input from 'components/common/Input'
import Select from 'components/common/Select'
import OverlayStepper from 'components/common/OverlayStepper'
import CardSelector from 'components/common/CardSelector'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Text from 'components_new/atoms/Text'

import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'
import * as AuthParamValuesApi from 'redux/api/DataPlatform/connections/authParamValues/v1'

import * as Conf from './conf'

import * as Functions from 'helpers/Functions'
import * as Constants from 'helpers/Constants'

const NewSource = ({ SourceStore, tryCreateActiveSource, match, history }) => {
  const nameForm = useRef(null)
  const authForm = useRef(null)
  const endpointForm = useRef(null)

  const [selectedSource, setSelectedSource] = useState(null)
  const open =
    match.params.createType === 'systems' ||
    match.params.createType === 'databases' ||
    match.params.createType === 'streams'
  const onPremiseDirect = match.params.createType === 'databases'
  const sourceType = match.params.createType === 'systems' ? 'API' : 'Stream'
  const title = onPremiseDirect
    ? 'Databas'
    : sourceType === 'Stream'
      ? 'Ström'
      : 'System'

  const [authValidated, setAuthValidated] = useState({
    success: false,
    error: null,
    validating: false
  })

  const [authParamValues, setAuthParamValues] = useState({})
  const [initialFormValues, setInitialFormValues] = useState({})

  let cards, source

  if (onPremiseDirect) {
    cards = Conf.ON_PREMISE_DIRECT_CARDS.sort((a, b) =>
      a.title.localeCompare(b.title)
    )
    source = Object.values(SourceStore.data).find(
      (item) => item.attributes.type === 'OnPremiseDirect'
    )
  } else {
    cards = Object.values(SourceStore.data)
      .filter(
        (source) =>
          source.attributes.type === sourceType &&
          !(source.attributes.name in Conf.SOURCES_TO_SKIP)
      )
      .map((source) => ({
        imgSrc: source.attributes.logo_url,
        title: source.attributes.name,
        value: source.id
      }))
      .sort((a, b) => a.title.localeCompare(b.title))

    source = SourceStore.data[selectedSource]
  }

  const steps = [
    {
      title: `Välj ${title.toLowerCase()}`,
      description: 'Välj en i listan',
      valid: selectedSource !== null,
      content: (
        <CardSelector
          title={title}
          selected={selectedSource}
          onChange={(val) => {
            setSelectedSource(val)

            if (onPremiseDirect) {
              setAuthParamValues({
                ...authParamValues,
                database_type: Conf.ON_PREMISE_DIRECT_CARDS.find(
                  (item) => item.value === val
                ).database
              })
            } else {
              setInitialFormValues({
                ...initialFormValues,
                display_name: SourceStore.data[val].attributes.name
              })
            }
          }}
          items={cards}
        />
      )
    },
    {
      title: 'Namnge datakälla',
      description: 'Ge din datakälla ett namn',
      valid: true,
      content: (
        <Form
          layout={'vertical'}
          initialValues={initialFormValues}
          ref={nameForm}
        >
          <NameSettings SourceStore={SourceStore} />
        </Form>
      ),
      onNext: () => Functions.validateForm(nameForm)
    }
  ]

  // add authentication if mandatory
  if (
    !source ||
    source.attributes.auth_type ||
    source.attributes.type !== 'API'
  ) {
    if (source && source.attributes.name === 'Custom API') {
      steps.splice(
        1,
        0,
        {
          title: 'Autentisering',
          description: 'Fyll i uppgifter',
          valid: true,
          onNext: () => Functions.validateForm(authForm),
          content: (
            <CustomAuthSettings
              form={authForm}
              initialValues={initialFormValues}
            />
          )
        },
        {
          title: 'Endpoints',
          description: 'Lägg till endpoints',
          valid: true,
          onNext: () => Functions.validateForm(endpointForm),
          content: (
            <CustomEndpointSettings
              form={endpointForm}
              initialValues={{
                endpoints: [
                  {
                    url: undefined,
                    content_field: undefined,
                    name: undefined
                  }
                ]
              }}
            />
          )
        }
      )
    } else {
      steps.splice(1, 0, {
        title: 'Autentisering',
        description: 'Fyll i uppgifter',
        valid: authValidated.success,
        content: (
          <AuthSettings
            form={authForm}
            initialValues={initialFormValues}
            authParams={source ? source.included.auth_params : []}
            instructions={source ? source.attributes.instructions : ''}
            validating={authValidated.validating}
            error={authValidated.error}
            success={authValidated.success}
            onFieldsChange={() =>
              setAuthValidated({
                success: false,
                error: null,
                validating: false
              })
            }
            onFinish={(attributes) => {
              setAuthValidated({
                success: false,
                error: false,
                validating: true
              })

              const values = { ...authParamValues, ...attributes }

              AuthParamValuesApi.validate(
                Conf.formatValidateBody(
                  source.id,
                  values,
                  source.included.auth_params
                )
              )
                .then(() => {
                  setAuthValidated({
                    error: false,
                    validating: false,
                    success: true
                  })
                  setAuthParamValues(values)
                })
                .catch(() =>
                  setAuthValidated({
                    validating: false,
                    success: false,
                    error: true
                  })
                )
            }}
          />
        )
      })
    }
  }

  return (
    <OverlayStepper
      open={open}
      onClose={() => {
        history.goBack()

        setInitialFormValues({})
        setSelectedSource({})
        setAuthParamValues({})
        setAuthValidated({})

        if (authForm.current) {
          authForm.current.resetFields()
        }
        if (endpointForm.current) {
          endpointForm.current.resetFields()
        }
        nameForm.current.resetFields()
      }}
      onFinish={() => {
        if (source.attributes.name === 'Custom API') {
          tryCreateActiveSource({
            data: {
              attributes: {
                source_id: source.id,
                auth_params: [authForm.current.getFieldsValue()],
                endpoints: endpointForm.current.getFieldValue('endpoints'),
                display_name: nameForm.current.getFieldValue('display_name')
              }
            }
          })
        } else {
          tryCreateActiveSource(
            Conf.formatCreateBody(
              source.id,
              authParamValues,
              source.included.auth_params,
              nameForm.current.getFieldValue('display_name')
            )
          )
        }

        history.push(`/data-platform/collect/${match.params.createType}`)

        if (authForm.current) {
          authForm.current.resetFields()
        }
        if (endpointForm.current) {
          endpointForm.current.resetFields()
        }
        nameForm.current.resetFields()
        setInitialFormValues({})
        setSelectedSource({})
        setAuthParamValues({})
        setAuthValidated({})
      }}
      title={'Collect'}
      service={'collect'}
      onChangePage={() => {
        setAuthValidated({
          success: false,
          error: null,
          validating: false
        })

        setInitialFormValues({
          ...initialFormValues,
          ...(authForm.current ? authForm.current.getFieldsValue() : {}),
          ...(nameForm.current ? nameForm.current.getFieldsValue() : {}),
          ...(endpointForm.current ? endpointForm.current.getFieldsValue() : {})
        })
      }}
      steps={steps}
    />
  )
}

const AuthSettings = ({
  form,
  initialValues,
  onFinish,
  authParams = [],
  validating,
  error,
  onFieldsChange,
  success
}) => {
  return (
    <Row className={'width-100'} gutter={48} justify={'start'}>
      <Col span={12}>
        <Text gutterBottom variant="h6">
          Autentisering
        </Text>
        <Form
          layout={'vertical'}
          onFinish={(formAttr) => onFinish(formAttr)}
          initialValues={initialValues}
          ref={form}
          onFieldsChange={() => onFieldsChange()}
        >
          {authParams
            .filter((param) => param.attributes.field !== 'database_type')
            .map((param, i) => (
              <Form.Item
                key={`${param.attributes.field}-${i}`}
                rules={[
                  {
                    required: true,
                    message: 'Obligatoriskt fält'
                  }
                ]}
                name={param.attributes.field}
                label={param.attributes.field}
              >
                <Input />
              </Form.Item>
            ))}
          <Form.Item>
            <Button loading={validating} type="submit">
              Validera autentisering
            </Button>
            <Text
              variant="body1"
              color={error ? 'error.main' : success ? 'success.main' : null}
              sx={{ display: !error && !success ? 'hidden' : null }}
            >
              {error
                ? 'Autentisering kunde ej valideras'
                : 'Autentisering validerad'}
            </Text>
          </Form.Item>
        </Form>
      </Col>
      <Col span={12}>
        <Text gutterBottom variant="h6">
          Var hittar jag uppgifterna?
        </Text>
      </Col>
    </Row>
  )
}

const CustomAuthSettings = ({ form, initialValues }) => (
  <Row className={'width-100'} gutter={48} justify={'start'}>
    <Col span={12}>
      <Text gutterBottom variant="h6">
        Autentisering
      </Text>
      <Form layout={'vertical'} initialValues={initialValues} ref={form}>
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'location'}
          label={'Plats'}
        >
          <Select
            options={[
              { label: 'Query', value: 'query' },
              { label: 'Headers', value: 'headers' }
            ]}
          />
        </Form.Item>
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'field'}
          label={'Fält'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
          name={'value'}
          label={'Nyckel'}
        >
          <Input />
        </Form.Item>
      </Form>
    </Col>
  </Row>
)

const CustomEndpointSettings = ({ form, initialValues }) => (
  <Row className={'width-100'} gutter={48} justify={'start'}>
    <Col span={18}>
      <Text gutterBottom variant="h6">
        Endpoints
      </Text>
      <Form layout={'vertical'} initialValues={initialValues} ref={form}>
        <Form.List name={'endpoints'}>
          {(fields, { add, remove }) => (
            <Row gutter={[16, 16]}>
              {fields.map(({ name, fieldKey, restField }, index) => {
                return (
                  <Box
                    key={`col-${fieldKey}-${index}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Col span={10}>
                      <Form.Item
                        name={[name, 'url']}
                        fieldKey={[fieldKey, 'url']}
                        {...restField}
                        rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                      >
                        <Input placeholder={'Url'} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[name, 'content_field']}
                        fieldKey={[fieldKey, 'content_field']}
                        {...restField}
                      >
                        <Input placeholder={'Plats'} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[name, 'name']}
                        fieldKey={[fieldKey, 'name']}
                        {...restField}
                        rules={[Constants.FORM_VALIDATIONS.REQUIRED]}
                      >
                        <Input placeholder={'Namn'} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item>
                        <IconButton onClick={() => remove(name)}>
                          <Icon name="Close" />
                        </IconButton>
                      </Form.Item>
                    </Col>
                  </Box>
                )
              })}
              <Box>
                <Form.Item>
                  <Button
                    onClick={() =>
                      add({
                        url: undefined,
                        content_field: undefined,
                        name: undefined
                      })
                    }
                  >
                    Lägg till endpoint
                  </Button>
                </Form.Item>
              </Box>
            </Row>
          )}
        </Form.List>
      </Form>
    </Col>
  </Row>
)

const NameSettings = ({ SourceStore }) => {
  return (
    <Box sx={{ width: '31rem' }}>
      <Text gutterBottom variant="h6">
        Lägg till som
      </Text>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Obligatoriskt fält'
          },
          () => ({
            validator(_, value) {
              if (
                value &&
                Object.values(SourceStore.data)
                  .filter(
                    (item) => item.included && item.included.active_sources
                  )
                  .some((item) =>
                    item.included.active_sources.some(
                      (activeSource) =>
                        activeSource.attributes.display_name &&
                        activeSource.attributes.display_name.toLowerCase() ===
                          value.toLowerCase()
                    )
                  )
              ) {
                return Promise.reject(
                  new Error(
                    `Ett system med samma namn '${value}' existerar redan`
                  )
                )
              }

              return Promise.resolve()
            }
          })
        ]}
        name={'display_name'}
        label={'Namn'}
      >
        <Input />
      </Form.Item>

      <Text color="text.secondary" variant="body1">
        Du kan välja att namnge ditt system efter dina preferenser. Att namnge
        ett system i portalen påverkar inte dess funktionalitet.
      </Text>
    </Box>
  )
}

function mapStateToProps({ SourceStore }) {
  return {
    SourceStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActiveSourceActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewSource))
