import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { CardActionArea as MuiCardActionArea } from '@mui/material'

interface CardActionAreaProps {
  children: ReactNode
  component?: ElementType<any>
  disabled?: boolean
  disableRipple?: boolean
  onContextMenu?: (() => void) | MouseEventHandler<HTMLElement>
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  sx?: object
  to?: string
}

/**
 * The action area (read: Button) of a Card can
 * be specified by wrapping its contents in a
 * CardActionArea component.
 *
 * This should be used for the main action
 * action of a Card making the whole (or part of
 * the-) surface clickable.
 *
 * @requires Card component.
 */

const CardActionArea = React.forwardRef(
  (props: CardActionAreaProps, ref: any) => {
    const { children, ...rest } = props

    return (
      <MuiCardActionArea {...rest} ref={ref}>
        {children}
      </MuiCardActionArea>
    )
  }
)

CardActionArea.displayName = 'CardActionArea'
export default CardActionArea
