import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { getAll, getOne, put } from 'redux/api/DashboardFilterOptions'
import { DashboardFilterOptionsPutBody } from 'types/GlobalDashboardFilter'

export function tryGetDashboardFilterOptions() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DASHBOARD_FILTER_OPTIONS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_DASHBOARD_FILTER_OPTIONS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DASHBOARD_FILTER_OPTIONS_FAILED
        })
      })
  }
}

export function tryGetDashboardFilterData(relationKey: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ONE_DASHBOARD_FILTER_DATA,
      payload: relationKey
    })

    getOne(relationKey)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_FILTER_DATA_SUCCESS,
          payload: { options: response.data.data, relationKey }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_FILTER_DATA_FAILED,
          payload: relationKey
        })
      })
  }
}

export function tryPutDashboardFilterOptions(
  dashboardId: string,
  body: DashboardFilterOptionsPutBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.PUT_DASHBOARD_FILTER_OPTIONS
    })

    put(dashboardId, body)
      .then((response) => {
        dispatch({
          type: Types.PUT_DASHBOARD_FILTER_OPTIONS_SUCCESS,
          payload: { filters: response.data.data, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.PUT_DASHBOARD_FILTER_OPTIONS_FAILED
        })
      })
  }
}
