import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import DashboardPage from 'components_new/pages/internal/Dashboard'
import UserTrackingKanbanPage from 'components_new/pages/internal/UsersTracking'
import UserTrackingPage from 'components_new/pages/internal/UserTracking'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <DashboardPage />}
        path={'/tracking/dashboard'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <UserTrackingPage />}
        path={'/tracking/users/:id'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <UserTrackingKanbanPage />}
        path={'/tracking/users'}
      />
      <Route path={'/tracking/users/*'}>
        <Redirect to="/tracking/users" />
      </Route>
    </Switch>
  )
}

export default Routes
