import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import Input from 'components/common/Input'

const Domain = ({ onChange, value, isNew, add, remove }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Input onChange={onChange} value={value} label={'Domän'} />
    <Tooltip title={isNew ? 'Lägg till domän' : 'Radera domän'}>
      <IconButton onClick={isNew ? add : remove}>
        <Icon name={isNew ? 'AddCircleOutlineOutlined' : 'DeleteOutlined'} />
      </IconButton>
    </Tooltip>
  </Box>
)

export default Domain
