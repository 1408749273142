import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import TrendText from 'components_new/molecules/TrendText'

import { TFeature } from './utils'

interface MissingDialogProps {
  features: TFeature[]
  increaseIsPositives: boolean[]
  kpis: string[]
  numberOfDecimals: number[]
  onClose: () => void
  open: boolean
  units: (string | null)[]
}

const MissingDialog = (props: MissingDialogProps) => {
  const {
    features,
    increaseIsPositives,
    kpis,
    numberOfDecimals,
    onClose,
    open,
    units
  } = props

  let showComparative = false

  if (features.length > 0) {
    showComparative = features[0].properties.percentages.some(
      (value) => value !== null
    )
  }

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <AdvancedDialog
          actions={<Button onClick={onClose}>Stäng</Button>}
          disableOverflow={true}
          fullHeight={true}
          noGutter={true}
          onClose={onClose}
          open={open}
          title={`${features.length} ${
            features.length === 1 ? 'fastighet' : 'fastigheter'
          } saknar koordinater`}
        >
          <TableContainer
            sx={{
              maxHeight: '100%'
            }}
          >
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Fastighet</TableCell>
                  {kpis.map((kpi) => (
                    <>
                      <TableCell align="right">{kpi}</TableCell>
                      {showComparative ? <TableCell>{''}</TableCell> : null}
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map((feature, i) => (
                  <TableRow key={i}>
                    <TableCell>{feature.properties.title}</TableCell>
                    {feature.properties.values.map((value, i) => (
                      <>
                        <TableCell align="right">
                          {value}
                          {units[i] ? ` ${units[i]}` : ''}
                        </TableCell>
                        {showComparative ? (
                          <TableCell>
                            {feature.properties.percentages[i] !== null ? (
                              <TrendText
                                difference={
                                  feature.properties.differences[i] as number
                                }
                                increaseIsPositive={increaseIsPositives[i]}
                                numberOfDecimals={numberOfDecimals[i]}
                                percentage={
                                  feature.properties.percentages[i] as number
                                }
                              />
                            ) : (
                              ''
                            )}
                          </TableCell>
                        ) : null}
                      </>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AdvancedDialog>
      </ThemeProvider>
    </>
  )
}

export default MissingDialog
