import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as DataProductsActions from 'redux/actions/Applications/DataProducts'
import * as DatasetActions from 'redux/actions/Applications/Datasets'
import * as AlertActions from 'redux/actions/Alert'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import Table from 'components/common/Table'

import { usePrevious } from 'helpers/Functions'
import {
  getHeaders,
  parseBanner,
  onDeleteDataset,
  getDatasets
} from 'components/containers/Applications/DataProducts/dataset/conf'

const PAGE_LIMIT = 10

const Dataset = ({
  AuthStore,
  AppsDataProductStore,
  AppsDatasetStore,
  history,
  match,
  setAlert,
  resetAlert,
  tryGetDatasetPreview,
  tryDeleteDataset,
  tryGetDatasetTransformation,
  tryStartJobRun,
  tryPollJobRun
}) => {
  // store offset for preview in query params
  const params = new URLSearchParams(history.location.search)
  const offset = params.get('offset') || 0

  const dataProduct = AppsDataProductStore.data[match.params.id]
  const datasets = getDatasets(dataProduct ? dataProduct.attributes.tables : [])
  const [selected, setSelected] = useState(datasets?.[0]?.id)
  const [pollingIntervalId, setPollingIntervalId] = useState(null)

  const dataset = datasets.find((d) => d.id === selected)
  // delete dataset function
  const deleteDataset = () => {
    tryDeleteDataset(selected, dataProduct.id)
    const nextDataset = datasets.find((item) => item.id !== selected)

    setSelected(nextDataset)
  }

  const headers = getHeaders(
    dataset,
    AppsDatasetStore.transformations[selected]
  )

  const endpoint = `${AuthStore.customer.slug}/${dataProduct.attributes.slug}/${dataset?.slug}`
  const query = `?offset=${offset}&limit=${PAGE_LIMIT}`
  const endpointKey = `${endpoint}${query}`
  const rows = AppsDatasetStore.data[endpointKey]?.data || []
  const count = AppsDatasetStore.data[endpointKey]?.meta?.total_count || 0
  const previousDatasets = usePrevious(datasets)

  useEffect(() => {
    if (!AppsDatasetStore.fetching && selected) {
      tryGetDatasetPreview(endpoint, query)
    }
  }, [endpointKey])

  useEffect(() => {
    if (dataset && !(dataset.id in AppsDatasetStore.transformations)) {
      tryGetDatasetTransformation(dataset.id)
    }
  }, [selected, AppsDatasetStore.transformations[selected]])

  useEffect(() => {
    // Land on first dataset when newly created with PUSH
    // Land on last dataset when remove dataset with POP
    if (
      datasets.length > 0 &&
      history.action === 'POP' &&
      previousDatasets &&
      previousDatasets?.length !== datasets.length
    ) {
      setSelected(datasets[datasets.length - 1].id)
    } else if (datasets.length > 0 && history.action === 'PUSH') {
      setSelected(datasets[0].id)
    }

    const jobState = dataProduct.attributes?.latest_run?.state

    if (jobState === 'RUNNING' || jobState === 'STARTING') {
      if (!pollingIntervalId) {
        let intervalId = null

        intervalId = setInterval(() => {
          tryPollJobRun(dataProduct.id)
        }, 7000)

        setPollingIntervalId(intervalId)
      }
    } else {
      // fetch preview on job succeeded
      if (jobState === 'SUCCEEDED' && selected && !AppsDatasetStore.fetching) {
        tryGetDatasetPreview(endpoint, query)
      }

      clearInterval(pollingIntervalId)

      setPollingIntervalId(null)
    }
  }, [AppsDataProductStore])

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2 }}>
        {parseBanner(dataProduct, tryStartJobRun, datasets)}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {datasets.map((item) => (
              <Box
                key={item.id}
                onClick={() => {
                  // reset pagination on switch dataset
                  history.push({ search: '?offset=0' })
                  setSelected(item.id)
                }}
                sx={{
                  bgcolor: selected === item.id ? 'primary.transparent' : null,
                  color:
                    selected === item.id ? 'primary.main' : 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  px: 2
                }}
              >
                <Text color="inherit" variant="subtitle2">
                  {item.name}
                </Text>
              </Box>
            ))}
            <Button
              onClick={() => history.push(`${match.url}/new`)}
              startIcon={<Icon name="AddCircleOutlineOutlined" />}
              variant="text"
            >
              Lägg till dataset
            </Button>
          </Box>
          {selected ? (
            <Box sx={{ display: 'flex' }}>
              <Button
                onClick={() => history.push(`${match.url}/edit/${selected}`)}
                variant="text"
              >
                Redigera
              </Button>
              <Button
                onClick={() =>
                  onDeleteDataset(setAlert, resetAlert, deleteDataset)
                }
                variant="text"
              >
                Ta bort
              </Button>
            </Box>
          ) : null}
        </Box>
        <Table
          tableLayout={'auto'}
          headers={headers}
          rows={rows}
          size={'middle'}
          loading={AppsDatasetStore.fetching}
          parsedFormat
          pagination={{
            position: 'bottomRight',
            pageSize: PAGE_LIMIT,
            onChange: (p) =>
              history.push({ search: `?offset=${PAGE_LIMIT * (p - 1)}` }),
            total: count,
            showSizeChanger: false
          }}
          scroll={
            dataset ? { x: dataset.outputAttributes.length * 130 } : undefined
          }
        />
      </Paper>
    </Box>
  )
}

function mapStateToProps({
  AuthStore,
  AppsDataProductStore,
  AppsDatasetStore
}) {
  return { AuthStore, AppsDataProductStore, AppsDatasetStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...DatasetActions,
      ...AlertActions,
      ...DataProductsActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dataset))
