/**
 * Sort strings alphabetical ascending and put null last
 * Use it as '.sort((a, b) => sortAlphabeticalAsc(a, b))'
 */
export const sortAlphabeticalAsc = (a, b) => {
  if (a === null && b === null) {
    return 0
  }

  if (a === null) {
    return 1
  }

  if (b === null) {
    return -1
  }

  return a.localeCompare(b, 'sv', { sensitivity: 'base' })
}

/**
 * Sort strings alphabetical descending and put null last
 * Use it as '.sort((a, b) => sortAlphabeticalDesc(a, b))'
 */
export const sortAlphabeticalDesc = (a, b) => {
  if (a === null && b === null) {
    return 0
  }

  if (a === null) {
    return 1
  }

  if (b === null) {
    return -1
  }

  return b.localeCompare(a, 'sv', { sensitivity: 'base' })
}

/**
 * Sort dates descending and put null last
 * Use it as '.sort((a, b) => sortDateDesc(a, b))'
 */
export const sortDateDesc = (a, b) => {
  if (a === null) {
    return 1
  }
  if (b === null) {
    return -1
  }

  if (a < b) {
    return 1
  }
  if (a > b) {
    return -1
  }

  return 0
}
