import { Category } from 'redux/reducers/Dashboards'

import {
  AllowAcess,
  FrequencyOfDataSynchronization,
  SupportLevel
} from 'types/GlobalCustomer'
import { KpiStatus, KpiTemplate } from 'types/GlobalKpiTemplates'

import { translateCategory } from 'utils/enumTranslator'

const KEY_FIGURE_HEADERS = [
  {
    label: 'Nyckeltal'
  },
  {
    label: 'I licens',
    align: 'center' as const
  },
  {
    label: 'Beställd',
    align: 'center' as const
  },
  {
    label: 'Integrerad',
    align: 'center' as const
  },
  {
    label: 'Modellerad',
    align: 'center' as const
  },
  {
    label: 'Kvalitetssäkrad',
    align: 'center' as const
  }
]

const LICENSE_HEADERS = [
  {
    label: 'Feature'
  },
  {
    label: 'I licens',
    align: 'center' as const
  }
]

const LICENSE_ROWS = [
  {
    label: 'Datasynkronisering',
    trueIf: [
      FrequencyOfDataSynchronization.ONCE_PER_DAY,
      FrequencyOfDataSynchronization.SEVERAL_TIMES_PER_DAY
    ],
    fields: ['frequency_of_data_synchronization'],
    isHeader: true
  },
  {
    label: '1 gång per dygn',
    trueIf: [
      FrequencyOfDataSynchronization.ONCE_PER_DAY,
      FrequencyOfDataSynchronization.SEVERAL_TIMES_PER_DAY
    ],
    fields: ['frequency_of_data_synchronization'],
    isHeader: false
  },
  {
    label: 'Flera gånger per dag',
    trueIf: [FrequencyOfDataSynchronization.SEVERAL_TIMES_PER_DAY],
    fields: ['frequency_of_data_synchronization'],
    isHeader: false
  },
  {
    label: 'Nyckeltal',
    trueIf: [],
    fields: ['ALWAYS_TRUE'],
    isHeader: true
  },
  {
    label: 'Freemium',
    trueIf: [],
    fields: ['ALWAYS_TRUE'],
    isHeader: false
  },
  {
    label: 'Premium',
    trueIf: ['true'],
    fields: ['premium_kpis'],
    isHeader: false
  },
  {
    label: 'Benchmarking',
    trueIf: ['true'],
    fields: ['benchmarking'],
    isHeader: true
  },
  {
    label: 'Antal användare',
    trueIf: [],
    fields: ['ALWAYS_TRUE'],
    isHeader: true
  },
  {
    label: 'SSO',
    trueIf: ['true'],
    fields: ['sso'],
    isHeader: false
  },
  { label: 'Inbäddning', trueIf: ['true'], fields: ['embed'], isHeader: true },
  {
    label: 'Teams, Sharepoint etc.',
    trueIf: ['true'],
    fields: ['embed'],
    isHeader: false
  },
  { label: 'TV-skärm', trueIf: ['true'], fields: ['embed'], isHeader: false },
  {
    label: 'Antal dashboards',
    trueIf: [],
    fields: ['ALWAYS_TRUE'],
    isHeader: true
  },
  {
    label: 'Färgsättning och anpassning',
    trueIf: ['true'],
    fields: ['white_label_colors', 'white_label_logotype'],
    isHeader: true
  },
  {
    label: 'Sätt era färger',
    trueIf: ['true'],
    fields: ['white_label_colors'],
    isHeader: false
  },
  {
    label: 'Sätt er logotyp',
    trueIf: ['true'],
    fields: ['white_label_logotype'],
    isHeader: false
  },
  {
    label: 'Support',
    trueIf: [
      SupportLevel.CHAT_AND_EMAIL,
      SupportLevel.CUSTOMER_SUCCESS,
      SupportLevel.SLA
    ],
    fields: ['support_level'],
    isHeader: true
  },
  {
    label: 'Chatt och email',
    trueIf: [
      SupportLevel.CHAT_AND_EMAIL,
      SupportLevel.CUSTOMER_SUCCESS,
      SupportLevel.SLA
    ],
    fields: ['support_level'],
    isHeader: false
  },
  {
    label: 'Customer success',
    trueIf: [SupportLevel.CUSTOMER_SUCCESS, SupportLevel.SLA],
    fields: ['support_level'],
    isHeader: false
  },
  {
    label: 'SLA',
    trueIf: [SupportLevel.SLA],
    fields: ['support_level'],
    isHeader: false
  }
]

export const parseLicenseData = (allowAccess: AllowAcess | undefined) => {
  if (!allowAccess) {
    return { rows: [], headers: [] }
  }

  const rows = LICENSE_ROWS.map((row) => {
    const { fields, isHeader, label, trueIf } = row
    let value = false

    fields.forEach((field) => {
      if (field === 'ALWAYS_TRUE') {
        value = true
      } else if (allowAccess[field as keyof AllowAcess]) {
        const dbValue = allowAccess[field as keyof AllowAcess]
        const isPresent = trueIf.find(
          (trueValue) => trueValue === dbValue.toString()
        )

        if (isPresent) {
          value = true
        }
      }
    })

    return {
      label,
      isHeader,
      values: [value]
    }
  })

  return {
    rows,
    headers: LICENSE_HEADERS
  }
}

const addCategoryRows = (templates: KpiTemplate[], category: Category) => {
  return [
    {
      label: translateCategory[category].title,
      isHeader: true,
      colSpan: 6,
      values: []
    },
    ...templates
      .filter((template) => template.category === category)
      .map((template) => {
        const status = template.status as KpiStatus

        return {
          label: template.title,
          values: [
            status.available_in_license,
            status.ordered,
            status.integrated,
            status.modelled,
            status.quality_assured
          ]
        }
      })
  ]
}

export const parseKeyFigureData = (kpiTemplates: KpiTemplate[]) => {
  kpiTemplates.sort((a, b) => (a.title > b.title ? 1 : -1))

  const rows = [
    ...addCategoryRows(kpiTemplates, Category.ECONOMY),
    ...addCategoryRows(kpiTemplates, Category.REAL_ESTATE_PORTFOLIO),
    ...addCategoryRows(kpiTemplates, Category.RENTAL),
    ...addCategoryRows(kpiTemplates, Category.TECHNICAL_MANAGEMENT),
    ...addCategoryRows(kpiTemplates, Category.ENERGY)
  ]

  return {
    rows,
    headers: KEY_FIGURE_HEADERS
  }
}

export const getKeyFigureProgress = (kpiTemplates: KpiTemplate[]) => {
  const availableInLicense = kpiTemplates.filter(
    (item) => item.status?.available_in_license
  )

  const available = availableInLicense.length
  const ordered = availableInLicense.filter(
    (item) => item.status?.ordered
  ).length
  const integrated = availableInLicense.filter(
    (item) => item.status?.integrated
  ).length
  const modelled = availableInLicense.filter(
    (item) => item.status?.modelled
  ).length
  const qualityAssured = availableInLicense.filter(
    (item) => item.status?.quality_assured
  ).length

  return {
    ordered,
    available,
    integratedProgress: ordered === 0 ? 1 : integrated / ordered,
    modelledProgress: ordered === 0 ? 1 : modelled / ordered,
    qualityAssuredProgress: ordered === 0 ? 1 : qualityAssured / ordered,
    totalProgress:
      ordered === 0
        ? 1
        : (integrated + modelled + qualityAssured) / (ordered * 3)
  }
}
