import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { IconButton as MUIIconButton } from '@mui/material'
import { Color } from 'themes'

interface IconButtonProps {
  children?: ReactNode
  color?: Color | 'default' | 'disabled' | 'inherit' | 'neutral'
  component?: ElementType<any>
  disabled?: boolean
  disableRipple?: boolean
  edge?: 'end' | 'start' | false
  href?: string
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large'
  sx?: object
  to?: string
  variant?: 'contained' | 'outlined' | 'transparent'
}

/**
 * The IconButton component allow users to take
 * actions, and make choices, with a single tap
 * – without the need of text.
 *
 * Is recommended to combine with a Tooltip.
 *
 * Icon buttons are commonly found in app bars
 * and toolbars. Icons are also appropriate for
 * toggle buttons that allow a single choice to
 * be selected or deselected, such as adding or
 * removing a star to an item.
 */

const IconButton = React.forwardRef((props: IconButtonProps, ref: any) => {
  const { variant, sx, ...rest } = props

  return (
    <MUIIconButton
      {...rest}
      ref={ref}
      sx={{
        bgcolor: variant === 'contained' ? 'white.main' : undefined,
        borderStyle: variant === 'contained' ? 'solid' : undefined,
        borderWidth: variant === 'contained' ? '1px' : undefined,
        borderColor: variant === 'contained' ? 'action.focus' : undefined,
        '&:hover': {
          bgcolor: variant === 'contained' ? 'white.dark' : undefined
        },
        ...sx
      }}
    >
      {props.children}
    </MUIIconButton>
  )
})

IconButton.displayName = 'IconButton'
export default IconButton
