import React, { useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'

import Button from 'components_new/atoms/Button'
import Link from 'components_new/atoms/Link'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import {
  FilterConditionFormatBody,
  FilterPatchBody
} from 'types/GlobalKpiOption'

import FilterGroup from './FilterGroup'
import FilterItem from './FilterItem'
import FilterGroupDialog from './FilterGroupDialog'
import NativeFilterSection from './NativeFilterSection'

interface FilterSectionProps {
  filters: FilterConditionFormatBody[]
  filterOptions: KpiTemplateFilterOptionsBody | null
  onClose: () => void
  updateFilters: (filters: FilterPatchBody[]) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.MAP,
  WidgetType.LIST,
  WidgetType.PIE_CHART
]

const FilterSection = (props: FilterSectionProps) => {
  const { filters, filterOptions, onClose, updateFilters, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  const filterGroups = useMemo(() => {
    const groups: {
      [groupId: string]: {
        filterGroupId?: string | null
        filters: FilterConditionFormatBody[]
      }
    } = {}

    filters
      .filter((filter) => filter.condition.selected)
      .forEach((filter, i) => {
        const group = filter.filter_group_id || i

        if (group in groups) {
          groups[group].filters.push(filter)
        } else {
          groups[group] = {
            filterGroupId: filter.filter_group_id,
            filters: [filter]
          }
        }
      })

    return Object.values(groups).sort((a, b) =>
      (a.filters[0].attribute_option.selected as string).localeCompare(
        b.filters[0].attribute_option.selected as string
      )
    )
  }, [filters])

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [groupDialogOpen, setGroupDialogOpen] = useState<boolean>(false)

  return (
    <>
      <SettingsGroup
        helperText={
          <>
            Visar data där <b>alla</b> av ovanstående kriterier uppfylls.
            <br />
            Vill du visa data där endast <b>ett av</b> kriterierna uppfylls?{' '}
            <Link onClick={() => setGroupDialogOpen(true)}>
              Lägg till en filtergrupp
            </Link>
            .
          </>
        }
        title={'Filter'}
      >
        {filterGroups.length === 0 ? (
          <SettingsItem>
            <EmptyPlaceholder
              background={true}
              empty={true}
              sx={{ width: '100%' }}
              title="Det finns inga aktiva filter."
            />
          </SettingsItem>
        ) : (
          filterGroups.map((group, i) => {
            const groupFilters = group.filters
            const isGrouped = !!group.filterGroupId && groupFilters.length > 1

            if (isGrouped) {
              return (
                <FilterGroup
                  allFilters={filters}
                  filterOptions={filterOptions}
                  filters={groupFilters}
                  groupId={group.filterGroupId as string}
                  key={`filter-group-${i}`}
                  onClose={onClose}
                  updateFilters={updateFilters}
                />
              )
            } else {
              return (
                <FilterItem
                  allFilters={filters}
                  filter={groupFilters[0]}
                  filterOptions={filterOptions}
                  key={`filter-${i}`}
                  onClose={onClose}
                  updateFilters={updateFilters}
                />
              )
            }
          })
        )}

        <SettingsItem
          color="success"
          onClick={() => setAddDialogOpen(true)}
          title="Lägg till filter"
          variant="add"
        />
      </SettingsGroup>

      {/*-- dialogs --*/}
      <ThemeProvider theme={getTheme('light')}>
        <SimpleDialog
          actions={
            <Button onClick={() => setAddDialogOpen(false)} variant="text">
              Avbryt
            </Button>
          }
          onClose={() => setAddDialogOpen(false)}
          open={addDialogOpen}
          sx={{ zIndex: 1302 }}
        >
          {/* @TODO: Should be replaced in the future: */}
          <NativeFilterSection
            filterOptions={filterOptions}
            filters={filters}
            id={widget.id}
            onSubmit={() => setAddDialogOpen(false)}
            updateFilters={(_, body) => updateFilters(body.filters)}
          />
        </SimpleDialog>
      </ThemeProvider>

      <FilterGroupDialog
        allFilters={widget.settings.filters}
        filterOptions={filterOptions}
        open={groupDialogOpen}
        onClose={() => setGroupDialogOpen(false)}
        updateFilters={updateFilters}
      />
    </>
  )
}

export default FilterSection
