import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Table from 'components/common/Table'
import Dropzone from 'components/common/Dropzone'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import FileIcon from 'assets/collect/file.svg'

import * as Functions from 'helpers/Functions'

import * as ActiveSourceActions from 'redux/actions/DataPlatform/connections/ActiveSources'
import * as AlertActions from 'redux/actions/Alert'

import ResetPassword from './ResetPassword.js'
import * as Conf from './conf.js'

import Styles from './styles.module.css'

const Upload = ({
  activeSource,
  AuthStore,
  tryUploadActiveSourceFile,
  setAlert,
  resetAlert,
  SourceStore,
  tryResetPassword,
  tryUpdateActiveSource,
  tryCreateSFTPSettings
}) => {
  const [files, setFiles] = useState([])
  const [writeMode, setWriteMode] = useState([
    activeSource.attributes.write_mode
  ])
  const [locked, setLocked] = useState(true)
  const prevCreating = Functions.usePrevious(SourceStore.creating)
  const [openPwd, setOpenPwd] = useState(false)
  const [showPw, setShowPw] = useState(false)

  const [sftpSettings, setSftpSettings] = useState(
    activeSource.included.sftp_settings.length > 0
      ? {
          host:
            process.env.NODE_ENV === 'development'
              ? 'localhost'
              : 'sftp.homepal.se',
          port: process.env.NODE_ENV === 'development' ? 2222 : 22,
          username: activeSource.included.sftp_settings[0].attributes.username,
          password: activeSource.included.sftp_settings[0].attributes.password
        }
      : null
  )

  useEffect(() => {
    if (prevCreating && !SourceStore.creating) {
      if (!SourceStore.error) {
        setFiles([])

        setAlert({
          isOpen: true,
          header: 'Systemdata uppdaterad',
          okText: 'Stäng',
          onOk: () => resetAlert(),
          onCancel: () => resetAlert(),
          content: <Success />
        })
      } else {
        setAlert({
          isOpen: true,
          header: 'Systemdata uppdaterad',
          okText: 'Stäng',
          onOk: () => resetAlert(),
          onCancel: () => resetAlert(),
          content: <Failed />
        })
      }
    }
  }, [SourceStore.creating])

  useEffect(() => {
    if (activeSource.included.sftp_settings.length > 0) {
      setSftpSettings({
        host:
          process.env.NODE_ENV === 'development'
            ? 'localhost'
            : 'sftp.homepal.se',
        port: process.env.NODE_ENV === 'development' ? 2222 : 22,
        username: activeSource.included.sftp_settings[0].attributes.username,
        password: activeSource.included.sftp_settings[0].attributes.password
      })
    }
  }, [activeSource])

  const hiddenPw = '•'.repeat(
    activeSource.included.sftp_settings.length > 0
      ? activeSource.included.sftp_settings[0].attributes.password.length
      : 10
  )

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Table
            hidden
            rowSelectionType={'radio'}
            selectedRows={writeMode}
            setSelectedRows={(value) => {
              if (!locked) {
                setWriteMode(value)
              }
            }}
            headers={Conf.FILE_TABLE_HEADERS(locked)}
            rows={Conf.FILE_ROWS}
          />
          <IconButton onClick={() => setLocked(!locked)}>
            <Icon
              color={locked ? 'inherit' : 'primary'}
              name={locked ? 'LockOutlined' : 'LockOpenOutlined'}
            />
          </IconButton>
        </Box>
        <Button
          disabled={locked}
          onClick={() => {
            tryUpdateActiveSource(activeSource.id, {
              data: { attributes: { write_mode: writeMode[0] } }
            })
            setLocked(!locked)
          }}
          sx={{ mt: 1 }}
        >
          Uppdatera
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <Text gutterBottom variant="h6">
            Manuell
          </Text>
          {activeSource.attributes.multiple_files ? (
            'Det går inte att ladda upp filer manuellt om man har aktiverat stöd för flertalet filer.'
          ) : (
            <>
              <Dropzone
                size={'m'}
                files={files}
                removeFile={() => setFiles([])}
                icon={FileIcon}
                accept={['.csv', '.txt']}
                maxFiles={1}
                appendFile={(value) => setFiles(value)}
              />
              <Button
                className={Styles['file-button']}
                colored
                loading={SourceStore.creating}
                onClick={() => {
                  const formData = new FormData()

                  formData.append('file', files[0])
                  formData.append('writeMode', writeMode)

                  tryUploadActiveSourceFile(activeSource.id, formData)
                }}
                disabled={files.length === 0}
              >
                Ladda in data
              </Button>
            </>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Text variant="h6">SFTP</Text>
          <Text color="text.secondary" gutterBottom variant="body1">
            Du kan ladda upp filer via SFTP-anslutning. Använd uppgifterna
            nedan.
          </Text>
          {sftpSettings ? (
            <>
              <SFTPField title="Host" content={sftpSettings.host} />
              <SFTPField title="Port" content={sftpSettings.port} />
              <SFTPField title="Username" content={sftpSettings.username} />
              <SFTPField
                title="Password"
                content={
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    {showPw ? sftpSettings.password : hiddenPw}
                    <IconButton onClick={() => setShowPw(!showPw)} size="small">
                      <Icon
                        name={
                          showPw
                            ? 'VisibilityOffOutlined'
                            : 'VisibilityOutlined'
                        }
                      />
                    </IconButton>
                  </Box>
                }
              />
              <Button
                disabled={activeSource.included.sftp_settings.length === 0}
                className={'form-submit'}
                onClick={() => setOpenPwd(!openPwd)}
              >
                Generera nytt lösenord
              </Button>
            </>
          ) : (
            <Button
              disabled={sftpSettings || SourceStore.creatingSFTP}
              onClick={() => tryCreateSFTPSettings(activeSource.id)}
            >
              Skapa SFTP inställningar
            </Button>
          )}
        </Box>
      </Box>

      <ResetPassword
        visible={openPwd}
        onClose={() => {
          setOpenPwd(!openPwd)
          setShowPw(false)
        }}
        sftpSetting={
          activeSource.included.sftp_settings.length > 0
            ? activeSource.included.sftp_settings[0]
            : undefined
        }
        tryResetPassword={tryResetPassword}
        AuthStore={AuthStore}
      />
    </Box>
  )
}

const SFTPField = ({ title, content }) => (
  <Paper
    variant="outlined"
    sx={{ display: 'flex', alignItems: 'center', p: 1, mb: 1 }}
  >
    <b>{title}</b>
    <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
    {content}
  </Paper>
)

const Failed = () => (
  <Box
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      gap: 1,
      textAlign: 'center'
    }}
  >
    <Text variant="h6">Fel struktur</Text>
    <Text variant="body1">
      Strukturen på din fil skiljer sig från din förra. <br />
      Systemdatan kan därför inte uppdateras.
    </Text>
    <Icon color="error" name="ErrorOutlineOutlined" />
  </Box>
)

const Success = () => (
  <Box
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      gap: 1,
      textAlign: 'center'
    }}
  >
    <Text variant="h6">Uppdaterad!</Text>
    <Text variant="body1">Din systemdata har uppdaterats.</Text>
    <Icon color="success" name="CheckCircleOutlined" />
  </Box>
)

function mapStateToProps({ AuthStore, SourceStore }) {
  return {
    AuthStore,
    SourceStore
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...ActiveSourceActions, ...AlertActions },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
