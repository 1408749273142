import React, { useMemo } from 'react'

import ReactFlow, { Background, Controls } from 'reactflow'
import OntologyNode from 'components/common/Node/OntologyNode'
import FloatingEdge from 'components/common/Edge/FloatingEdge'
import Box from 'components_new/atoms/Box'

// default styling
import 'reactflow/dist/style.css'

// or if you just want basic styles
import 'reactflow/dist/base.css'

import Styles from './styles.module.css'
import './style.css'

const EDGE_TYPES = {
  floating: FloatingEdge
}

const NODE_TYPES = {
  ontology: OntologyNode,
  default: function d() {
    return null
  }
}

const DrawingBoard = ({
  nodes,
  edges,
  nodeTypes = NODE_TYPES,
  edgeTypes = EDGE_TYPES,
  onConnect,
  className,
  editMode,
  ignoreLockedMode,
  onLoad,
  onNodeDragStop,
  onNodesChange,
  onEdgesChange
}) => {
  const nTypes = useMemo(() => nodeTypes, [nodeTypes])
  const eTypes = useMemo(() => edgeTypes, [edgeTypes])

  return (
    <Box
      className={ignoreLockedMode ? 'ignore-locked-mode' : null}
      sx={{ bgcolor: 'background.default', height: '100%', width: '100%' }}
    >
      <ReactFlow
        onInit={onLoad}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        className={className}
        nodes={nodes}
        edges={edges}
        nodeTypes={nTypes}
        edgeTypes={eTypes}
        onConnect={onConnect}
        elementsSelectable={editMode}
        nodesConnectable={editMode}
        nodesDraggable={editMode}
        selectNodesOnDrag={editMode}
        edgesFocusable={editMode}
        edgesUpdatable={editMode}
        onNodeDragStop={onNodeDragStop}
        fitView
        minZoom={0}
      >
        <Background />
        <Controls showInteractive={false} className={Styles['control-btns']} />
      </ReactFlow>
    </Box>
  )
}

export default DrawingBoard
