const MS_PER_DAY = 1000 * 60 * 60 * 24

export const parseDateString = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString('sv-SE')
  const time = new Date(dateString).toLocaleTimeString('sv-SE').split(':')

  time.pop()

  return `${date} kl. ${time.join(':')}`
}

/**
 * Check if data type is of DATE or TIMESTAMP
 * @param {object} dtype - data type to check
 * @returns {boolean} - Data type is date/timestamp.
 */
export const isDateType = (dtype: string) =>
  ['DATE', 'TIMESTAMP'].includes(dtype)

/**
 * Calculates the elapsed time since @param dateString in a formatted
 * response.
 */
export const parseElapsedTimeSince = (inputDate: Date | string) => {
  let date: Date = new Date(0)

  if (typeof inputDate === 'string') {
    date = new Date(inputDate)
  } else {
    date = inputDate
  }

  const now = new Date()

  const timeDiff = Math.abs(now.valueOf() - date.valueOf())
  const timeDiffInDays = Math.round(timeDiff / MS_PER_DAY)

  // Parse out seconds, minutes or hours
  if (timeDiffInDays < 1) {
    const diffInHours = Math.round(timeDiff / (1000 * 60 * 60))
    const diffInMinutes = Math.round(timeDiff / (1000 * 60))
    const diffInSeconds = Math.round(timeDiff / 1000)

    if (diffInHours < 1 && diffInMinutes < 1) {
      return `${diffInSeconds} ${diffInSeconds === 1 ? 'sekund' : 'sekunder'}`
    } else if (diffInHours < 1) {
      return `${diffInMinutes} ${diffInMinutes === 1 ? 'minut' : 'minuter'}`
    } else {
      return `${diffInHours} ${diffInHours === 1 ? 'timme' : 'timmar'}`
    }
  }

  // Parse out weeks, months or years
  if (timeDiffInDays > 6) {
    if (timeDiffInDays >= 365) {
      return `${Math.floor(timeDiffInDays / 365)} år`
    } else if (timeDiffInDays >= 28) {
      const months = Math.floor(timeDiffInDays / 28)

      return `${months} ${months === 1 ? 'månad' : 'månader'}`
    } else {
      const weeks = Math.floor(timeDiffInDays / 7)

      return `${weeks} ${weeks === 1 ? 'vecka' : 'veckor'}`
    }
  }

  return `${timeDiffInDays} ${timeDiffInDays === 1 ? 'dag' : 'dagar'}`
}

export function isValidDate(toValidate: Date) {
  return toValidate instanceof Date && !isNaN(toValidate.getTime())
}
