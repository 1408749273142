import React from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Link from 'components_new/atoms/Link'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import UserListItem from 'components_new/molecules/UserListItem'

import { formatValue } from 'components_new/organisms/Widget/types/utils'
import {
  translateTitle,
  translateUserStatus,
  translateUserTag
} from 'components_new/pages/internal/UsersTracking/utils'
import {
  ActivationType,
  TrackingUser,
  translateActivationType
} from 'types/GlobalTracking'
import Divider from 'components_new/atoms/Divider'

interface NewTrackingProps {
  user: TrackingUser
}

const UserTrackingCard = (props: NewTrackingProps) => {
  const { user } = props

  return (
    <>
      <Paper
        key={user.id}
        variant="outlined"
        sx={{
          p: 1,
          mb: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <UserListItem
            body={user.customer.name}
            component="div"
            customTitle={
              <Link
                color="primary"
                href={'/tracking/users/' + user.id}
                underline="hover"
                sx={{ fontWeight: 500 }}
              >
                {user.full_name}
              </Link>
            }
            title={user.full_name ?? user.email}
            titleAdornment={
              user.title === null ? null : (
                <>
                  <Text
                    component="span"
                    color="text.secondary"
                    variant="body2"
                    sx={{ mx: 1 }}
                  >
                    /
                  </Text>
                  <Text component="span" color="text.secondary" variant="body2">
                    {translateTitle[user.title]}
                  </Text>
                </>
              )
            }
            status={translateUserStatus[user.swimlane].severity}
            sx={{ p: 0, width: 'auto' }}
          />
          {user.is_admin ? <Chip label={'Admin'} size="small" /> : null}
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            py: 0.5
          }}
        >
          {user.additional_information ? (
            <Tooltip title={'Användaren har svarat på välkomstformuläret'}>
              <Text sx={{ cursor: 'default' }}>💡</Text>
            </Tooltip>
          ) : null}
          {user.data.activated_at ? (
            <Tooltip
              title={
                <>
                  <b>
                    {
                      translateActivationType[
                        user.data.activation_type as ActivationType
                      ].icon
                    }{' '}
                    {
                      translateActivationType[
                        user.data.activation_type as ActivationType
                      ].title
                    }
                  </b>
                  <br />
                  {
                    translateActivationType[
                      user.data.activation_type as ActivationType
                    ].tooltip
                  }{' '}
                  {user.data.activated_at.split('T')[0]}
                </>
              }
            >
              <Text sx={{ cursor: 'default' }}>
                {
                  translateActivationType[
                    user.data.activation_type as ActivationType
                  ].icon
                }
              </Text>
            </Tooltip>
          ) : null}
          {user.tags.map((tag) => {
            if (tag.value) {
              return (
                <Tooltip
                  title={
                    <>
                      <b>
                        {translateUserTag[tag.id].icon}{' '}
                        {translateUserTag[tag.id].title}
                      </b>
                      <br />
                      {formatValue(tag.count, null, 0, true, null)}
                      {tag.count
                        ? ` ${translateUserTag[tag.id].body.toLowerCase()}`
                        : translateUserTag[tag.id].body}
                    </>
                  }
                  key={tag.id}
                >
                  <Text sx={{ cursor: 'default' }}>
                    {translateUserTag[tag.id].icon}{' '}
                    {formatValue(tag.count, null, 0, true, null)}
                  </Text>
                </Tooltip>
              )
            }

            return null
          })}
        </Box>
      </Paper>
    </>
  )
}

export default UserTrackingCard
