import React, { ChangeEvent, useEffect, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Button from 'components_new/atoms/Button'
import TextField from 'components_new/atoms/TextField'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface EditTitleDialogProps {
  onClose: () => void
  onSubmit: (param: string) => void
  open: boolean
  title?: string
}

const EditTitleDialog = (props: EditTitleDialogProps) => {
  const { onClose, onSubmit, open, title = '' } = props

  const [updatedTitle, setUpdatedTitle] = useState<string | null>(null)

  useEffect(() => {
    setUpdatedTitle(title)
  }, [title])

  const handleClose = () => {
    onClose()
    setUpdatedTitle(title)
  }

  const handleSubmit = () => {
    if (updatedTitle) {
      onSubmit(updatedTitle)
      onClose()
    }
  }

  return (
    <ThemeProvider theme={getTheme('light')}>
      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              disabled={!Boolean(updatedTitle)}
              variant="contained"
              onClick={handleSubmit}
            >
              Spara
            </Button>
          </>
        }
        onClose={handleClose}
        open={open}
        title="Redigera namn"
      >
        <TextField
          error={!Boolean(updatedTitle)}
          fullWidth
          helperText={
            !Boolean(updatedTitle)
              ? 'Din dashboard måste ha ett namn.'
              : undefined
          }
          label={'Namn'}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            setUpdatedTitle(event.target.value)
          }}
          value={updatedTitle}
        />
      </SimpleDialog>
    </ThemeProvider>
  )
}

export default EditTitleDialog
