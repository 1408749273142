import * as ActiveSourceApi from 'redux/api/DataPlatform/connections/activeSources/v1'
import * as Types from 'redux/Types'

import { messageSuccess, messageWarning } from 'helpers/Functions'

export function tryCreateSFTPSettings(id) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_SFTP_SETTINGS
    })

    ActiveSourceApi.createSFTPSettings(id)
      .then((response) => {
        dispatch({
          type: Types.CREATE_SFTP_SETTINGS_SUCCESS,
          payload: { ...response.data, activeSourceId: id }
        })

        messageSuccess('SFTP-inställningar har lagts till')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_SFTP_SETTINGS_FAILED
        })

        messageWarning('SFTP-inställningar kunde inte läggas till')
      })
  }
}

export function tryCreateActiveSource(body) {
  return (dispatch) => {
    dispatch({
      type: Types.CREATE_ACTIVE_SOURCE
    })

    ActiveSourceApi.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_ACTIVE_SOURCE_SUCCESS,
          payload: {
            ...response.data,
            activeSourceId: response.data.meta.active_source_id
          }
        })

        messageSuccess('Systemet har lagts till')
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ACTIVE_SOURCE_FAILED
        })

        messageWarning('Systemet kunde inte läggas till')
      })
  }
}

export function tryUpdateActiveSource(sourceId, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_ACTIVE_SOURCE
    })

    ActiveSourceApi.update(sourceId, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ACTIVE_SOURCE_SUCCESS,
          payload: { ...response.data, activeSourceId: sourceId }
        })

        messageSuccess('Systemet har uppdaterats')
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ACTIVE_SOURCE_FAILED
        })

        messageWarning('Systemet kunde inte uppdateras')
      })
  }
}

export function tryUploadActiveSourceFile(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.UPLOAD_FILE_ACTIVE_SOURCE
    })

    ActiveSourceApi.upload(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPLOAD_FILE_ACTIVE_SOURCE_SUCCESS,
          payload: { ...response.data, activeSourceId: id }
        })

        messageSuccess('Fil har laddats upp')
      })
      .catch(() => {
        dispatch({
          type: Types.UPLOAD_FILE_ACTIVE_SOURCE_FAILED
        })

        messageWarning('Fil kunde inte laddas upp')
      })
  }
}

export function tryActivateActiveSource(id) {
  return (dispatch) => {
    dispatch({
      type: Types.ACTIVATE_ACTIVE_SOURCE
    })

    ActiveSourceApi.activate(id)
      .then(() => {
        dispatch({
          type: Types.ACTIVATE_ACTIVE_SOURCE_SUCCESS,
          payload: { id }
        })

        messageSuccess('Systemet har aktiverats')
      })
      .catch(() => {
        dispatch({
          type: Types.ACTIVATE_ACTIVE_SOURCE_FAILED
        })

        messageWarning('Systemet kunde inte aktiveras')
      })
  }
}

export function tryDeleteActiveSource(sourceId, id) {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_ACTIVE_SOURCE
    })

    ActiveSourceApi.remove(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ACTIVE_SOURCE_SUCCESS,
          payload: { sourceId, id }
        })

        messageSuccess('Systemet har tagits bort')
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ACTIVE_SOURCE_FAILED
        })

        messageWarning('Systemet kunde inte tas bort')
      })
  }
}

export function tryResetPassword(id, body) {
  return (dispatch) => {
    dispatch({
      type: Types.RESET_PASSWORD
    })

    ActiveSourceApi.resetPassword(id, body)
      .then((response) => {
        dispatch({
          type: Types.RESET_PASSWORD_SUCCESS,
          payload: { ...response.data, activeSourceId: id }
        })

        messageSuccess('SFTP-lösenordet har ändrats')
      })
      .catch(() => {
        dispatch({
          type: Types.RESET_PASSWORD_FAILED
        })

        messageWarning('SFTP-lösenordet kunde inte ändras')
      })
  }
}

export function tryScanActiveSource(id) {
  return () => {
    ActiveSourceApi.scan(id)
      .then(() => {
        messageSuccess('Skanning av schema har startat')
      })
      .catch(() => {
        messageWarning('Skanning av schema kunde inte startas')
      })
  }
}
