import Axios from 'axios'

import {
  login,
  setNewPasswordChallenge,
  forgotPassword,
  confirmPassword,
  saveSamlSession,
  sendLogToSlack,
  sendMessageToSlack
} from './AuthModule'

import {
  VERIFICATION_CODE,
  SET_PASSWORD,
  SIGNED_IN,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_WITH_AZURE
} from './utils'

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

export const handleErrors = (errors, formRef) => {
  if (errors.length > 0) {
    const values = formRef.getFieldsValue()
    const parsedErrors = Object.keys(values).map((key) => {
      return {
        name: key,
        value: values[key],
        errors: errors
          .filter((e) => !e.field || e.field === key)
          .map((e) => e.message)
      }
    })

    formRef.setFields(parsedErrors)
  } else {
    formRef.resetFields()
  }
}

export const handleIdp = async (setState, setBlank) => {
  const code = window.location.search.split('?code=')[1].split('&')[0]
  const errors = []

  if (code) {
    try {
      const response = await Axios.post(
        `https://homepal.auth.eu-north-1.amazoncognito.com/oauth2/token?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&grant_type=authorization_code&code=${code}&redirect_uri=https://portal.homepal.se/callback`,
        {},
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )

      await saveSamlSession(response.data)
      setState(SIGNED_IN)
      setBlank(false)
    } catch (e) {
      sendLogToSlack('', e.message, LOGIN_WITH_AZURE)
      errors.push({ message: 'Något gick fel, var god försök igen.' })
    }
  }

  return errors
}

export const handleLoginWithAzure = async (values) => {
  const errors = []

  try {
    const idpName = (await Axios.get(`${API_URL}idp?email=${values.email}`))
      .data.idp_name

    window.location = `https://homepal.auth.eu-north-1.amazoncognito.com/oauth2/authorize?identity_provider=${idpName}&client_id=680hu3i7m3up6a8lib9obrto0q&response_type=code&scope=openid&redirect_uri=https://portal.homepal.se/callback`
  } catch (e) {
    sendLogToSlack(values.email, e.message, LOGIN_WITH_AZURE)
    errors.push({
      message: 'Kunde inte hitta domänen du försöker logga in med.'
    })
  }

  return errors
}

export const handleLogin = async (
  values,
  setState,
  setUser,
  redirectOnError = null
) => {
  const response = await login({
    email: values.email,
    password: values.password
  })

  const errors = []

  if (response.type === SIGNED_IN) {
    setState(SIGNED_IN)
  } else if (response.type === SET_PASSWORD) {
    setState(SET_PASSWORD)
    setUser(response.user)
  } else if (response.type === 'ERROR') {
    sendLogToSlack(values.email, response.error, LOGIN)
    errors.push({ message: response.error })

    if (redirectOnError) {
      redirectOnError()
    }
  } else if (response.type === 'INVALID_EMAIL') {
    sendLogToSlack(values.email, response.error, LOGIN)
    errors.push({ message: response.error, field: 'email' })

    if (redirectOnError) {
      redirectOnError()
    }
  }

  return errors
}

export const handleSetPassword = async (values, setState, user, email) => {
  const errors = []

  try {
    const clientMetadata =
      values.first_name && values.last_name
        ? {
            first_name: values.first_name,
            last_name: values.last_name
          }
        : undefined

    await setNewPasswordChallenge(values.password, user, clientMetadata)

    sendMessageToSlack(
      ':kevin-feelgood::crazy-lahti::calle-king: Första inlogg! :calle-king::crazy-lahti::kevin-feelgood:',
      `Detta är första gången ${email} loggar in. Kontakta för att fråga om dennes upplevelse.`,
      email
    )
    setState(SIGNED_IN)
  } catch (e) {
    sendLogToSlack(email, e.message, SET_PASSWORD)
    errors.push({ message: 'Något gick fel...' })
  }

  // Don't wanna throw error to user if logging fails.
  if (errors.length === 0) {
    logInviteAccepted(email)
  }

  return errors
}

export const handleForgotPassword = async (values, setPasswordHasBeenReset) => {
  const response = await forgotPassword(values.email)
  const errors = []

  if (response.type === VERIFICATION_CODE) {
    setPasswordHasBeenReset(true)
  } else if (response.type === 'ERROR') {
    sendLogToSlack(values.email, response.error, FORGOT_PASSWORD)
    errors.push({ message: response.error })
  }

  return errors
}

export const handleVerificationCode = async (values, setState, user) => {
  const response = await confirmPassword(values.code, values.password, user)
  const errors = []

  if (response.type === SIGNED_IN) {
    setState(SIGNED_IN)
  } else if (response.type === 'ERROR') {
    sendLogToSlack(user.username, response.error, VERIFICATION_CODE)
    errors.push({ field: 'code', message: response.error })
  }

  return errors
}

const logInviteAccepted = async (email) => {
  try {
    await Axios.patch(
      `${API_URL}v1/public/measurements/invite-accepted-log/${email}`
    )
  } catch (e) {}
}
